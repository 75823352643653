angular.module("app", [

    // Angular modules
    'ngRoute',
    'ngAnimate',
    'ngCookies',

    // External libs
    'pascalprecht.translate',
    'ui-notification',
    'ui.bootstrap',
    'chart.js',
    'textAngular',
    'uz.mailto',
    'ui.bootstrap.datetimepicker',
    'chart.js',
    'ui.validate',
    'ui.select',
    'ngSanitize',
    'lr.upload',
    'ui.mask',
    '720kb.tooltips',
    'ngTagsInput',
    'froala',
    'dndLists',
    'angularFileUpload',
    'colorpicker.module',
    'wysiwyg.module',

    // Services
    'requestManager',
    'cookieManager',
    'util',
    'sharedMemory',
    'emailTemplateUtil',
    'workflowUtil',
    'logOutTimer',
    'paginationManager',
    'bookstackApi',

    // Routes
    'login',
    'dashboard',
    'allAdmonishedClients',
    'workflows',
    'settings',
    'profile',
    'userDetails',
    'errorPage',
    'tasks',
    'editWorkflow',
    'register',
    'emailCheck',
    'analysis',
    'unclearPayments',
    'emailCenter',
    'emailCenterIncoming',
    'emailCenterOutgoing',
    'supportTicket',
    'supportTicketCreate',
    'supportTicketList',
    'clientManagement',
    'setupWizard',
    'branding',
    'clientSettings',
    'resetCustomContent',

    // Components
    'loginPanel',
    'wrapper',
    'prognosticatedPayments',
    'problematicClients',
    'favouriteClients',
    'admonishedClientsList',
    'customers',
    'emailCenterList',
    'errorPanel',
    'userDetailsActions',
    'userInformations',
    'userRecords',
    'userRecordsV2',
    'generalSettings',
    'pdfSettings',
    'systemSettings',
    'importSettings',
    'translationSettings',
    'userSettings',
    'paymentAgreements',
    'unpaidPaymentAgreements',
    'overviewStatistics',
    'reminder',
    'vacantPosOfCustomer',
    'customerStatistics',
    'userEmails',
    'userProfileSettings',
    'generalHint',
    'generalHintEditor',
    'taskList',
    'taskListV2',
    'workflowCreator',
    'workflowList',
    'customerTaskList',
    'moduleSettings', 
    'userPaymentAgreements', 
    'emailTemplateEditor',
    'workflowEditor',
    'workflowPriority',
    'registerWizard',
    'emailList',
    'physicalMailApprovalList',
    'emailListV2',
    'physicalMailApprovalListV2',
    'debitCollection', 
    'createPaymentAgreementButton', 
    'paidInvoicesList', 
    'sendPhysicalMailButton',
    'pausedInvoices',
    'pdfTemplateCanvas',
    'pdfTemplateSelector',
    'analysisComp',
    'userDetailsFileUpload',
    'emailCenterListIncoming',
    'emailCenterListOutgoing',
    'emailCenterListIncomingV2',
    'emailCenterListOutgoingV2',
    'createSupportTicket',
    'supportTicketOverview',
    'listSupportTicket',
    'bookstack',
    'emailSettings',
    'inkassoSettings',
    'newPassword',
    'customerAdvisor'
]);

angular.module("app").run([
    "cookieManager",
    "requestManager",
    "$rootScope",
    "$location",
    "sharedMemory",
    "$interval",
    "logOutTimer",
    "paginationManager",
    function(cookieManager, requestManager, $rootScope, $location, sharedMemory, $interval, logOutTimer, paginationManager){
        $rootScope.debug = true;

        var init = function(){
            $rootScope.readyToRender = false;
            initVariables();
            initIntervals();
            initScope();
            readCookies();
            getAmountOfUnapprovedDunnings();
            getAmountOfOpenTasks();
            loadUserSettings(() => {
                $rootScope.readyToRender = true;
            });
        };

        // Guard for /unclear-payments route
        // TODO unclear payments
        // $rootScope.$on('$routeChangeStart', function () {
        //     if ($location.url() === '/unclear-payments') {
        //         let collectiveAccount = $rootScope.settings !== undefined ?
        //             $rootScope.settings.find(setting => setting.settingCode === 'collectiveAccount') : undefined;
        //         if (collectiveAccount === undefined || collectiveAccount.settingValue1 === '0') {
        //             $location.path("/dashboard");
        //         }
        //     }
        // });

        // Guard for /clients route
        $rootScope.$on('$routeChangeStart', function () {
            if ($location.url() === '/clients') {
                if ($rootScope.authData !== undefined && $rootScope.authData.isAdmin !== '1') {
                    $location.path("/dashboard");
                }
                let isTaxFirm = $rootScope.settings !== undefined ?
                    $rootScope.settings.find(setting => setting.settingCode === 'isTaxFirm') : undefined;
                if (isTaxFirm === undefined || isTaxFirm.settingValue1 === '0') {
                    $location.path("/dashboard");
                }
            }
        });

        $rootScope.$on('$routeChangeStart', function() {
            if ($location.url() === '/email-check') {
                if ($rootScope.authData !== undefined && $rootScope.authData.isAdmin !== '1' && $rootScope.authData.userData.approvalStatus !== '1') {
                    $location.path("/dashboard");
                }
            }
            if ($location.url() === '/workflows' || $location.url() === '/settings') {
                if ($rootScope.authData !== undefined && $rootScope.authData.isAdmin !== '1') {
                    $location.path("/dashboard");
                }
            }
        });

        $rootScope.$on('$routeChangeStart', function () {
            if ($location.url() === '/branding' || $location.url() === '/reset' || $location.url() === '/client-settings') {
                if ($rootScope.authData !== undefined && $rootScope.authData.isAdmin !== '1') {
                    $location.path("/dashboard");
                }
            }
        });

        // Guard for /setup-wizard route
        $rootScope.$on('$routeChangeStart', function (url, next) {
            if (next !== undefined) {
                if (next.$$route.originalPath !== '/login') {
                    if ($rootScope.setupWizardCompleted !== undefined && +$rootScope.setupWizardCompleted === 0) {
                        $location.path("/setup-wizard");
                    }
                }
                if (next.$$route.originalPath === '/setup-wizard') {
                    if ($rootScope.setupWizardCompleted !== undefined && +$rootScope.setupWizardCompleted === 1) {
                        $location.path("/dashboard");
                    }
                }
            }
        });

        // Guard for /setup-wizard route
        $rootScope.$watch('setupWizardCompleted', function () {
            if ($rootScope.setupWizardCompleted !== undefined && +$rootScope.setupWizardCompleted === 0) {
                $location.path("/setup-wizard");
            } else {
                if ($location.url() === '/setup-wizard') {
                    $location.path("/dashboard");
                }
            }
        });

        function initVariables(){
            $rootScope.debug = true;
            $rootScope.authData = {
                sessionId: "",
                isLoggedIn: false,
                sessionExpire: undefined
            };
            $rootScope.userSettings = {
                content: undefined,
                currentlyLoading: false
            };
            $rootScope.lastVisitedPage = undefined;
        }

        function initIntervals(){
            $interval(function(){
                if($rootScope.authData.isLoggedIn){
                    var now = moment();
                    var expireDate = moment($rootScope.authData.sessionExpire);

                    if(now.isAfter(expireDate)){
                        $rootScope.logout();
                    }
                }
            }, 1000);
        }

        function initScope(){
            $rootScope.logout = logout;
        }

        function readCookies(){
            var object = cookieManager.readCookie();

            if(angular.isDefined(object) && moment().isBefore(moment(object.sessionExpire))){
                $rootScope.authData.sessionId = object.sessionId;
                $rootScope.authData.sessionExpire = object.sessionExpire;
                $rootScope.authData.isLoggedIn = true;
                $rootScope.authData.userData = object.userData;
                $rootScope.authData.isAdmin = object.isAdmin;
                $rootScope.authData.userId = object.userId;
                $rootScope.userSettings = object.userSettings;

                requestManager.routes.settings.getSettings($rootScope.authData.sessionId).then(
                    function(response){
                        $rootScope.settings = response.data;
                        $rootScope.settings.find(setting => {
                            if (setting.settingCode === 'europeanNumberFormat') {
                                $rootScope.europeanNumberFormat = setting.settingValue1;
                            }
                            if (setting.settingCode === 'companyCountry') {
                                $rootScope.companyCountry = setting.settingValue1;
                            }
                            if (setting.settingCode === 'setupWizardCompleted') {
                                $rootScope.setupWizardCompleted = setting.settingValue1;
                            }
                            if (setting.settingCode === 'pricingModel') {
                                $rootScope.pricingModel = setting.settingValue1;
                            }
                            if (setting.settingCode === 'isTaxFirm') {
                                $rootScope.isTaxFirm = setting.settingValue1;
                            }
                            if (setting.settingCode === 'accountLengthImpersonal') {
                                if (setting.settingValue1) {
                                    $rootScope.accountLengthImpersonal = +setting.settingValue1;
                                } else {
                                    $rootScope.accountLengthImpersonal = 8;
                                }
                            }
                        });
                        $rootScope.readyToRender = true;
                    },
                    function(response){
                        //todo catch error
                    }
                )
            }
            else{
                if($location.path() === "/register"){
                    return;
                }
                else{
                    window.localStorage.removeItem("data");
                    $rootScope.readyToRender = true;
                    if (!($location && $location.$$url && $location.$$url.startsWith('/new-password'))) {
                        $location.path("/login");
                    }
                }
            }
        }

        function getAmountOfOpenTasks() {
            requestManager.routes.task.getNumberOfOpenTasks($rootScope.authData.sessionId).then(
                function(response) {
                    $rootScope.amountOfOpenTasks = response.data.count;
                },
                function() {
                }
            )
        }

        function getAmountOfUnapprovedDunnings() {
            requestManager.routes.record.getAmountsUnapproved($rootScope.authData.sessionId).then(
                function(response) {
                    $rootScope.amountUnapprovedDunnings = response.data.count;
                },
                function() {
                }
            )
        }

        function logout(){
            jQuery('.modal').modal('hide');

            $rootScope.authData.sessionId = "";
            $rootScope.authData.isLoggedIn = false;
            $rootScope.authData.sessionExpire = undefined;
            $rootScope.lastVisitedPage = $location.path();

            cookieManager.deleteCookie();
            logOutTimer.deleteDate();
            paginationManager.deleteIndex();
            sharedMemory.reset();
            window.localStorage.removeItem("data");
            //$window.localStorage.clear();
            $location.path("/login");
            window.location.reload();
        }

        function loadUserSettings(onFinished){
            if(!$rootScope.authData.isLoggedIn){
                return;
            }

            var expireDate = moment($rootScope.authData.sessionExpire);
            logOutTimer.saveDate(expireDate._i);

            var hasBeenStarted = false;
            if(!hasBeenStarted) {
                paginationManager.initiateIndex();
                hasBeenStarted = true;
            }

            requestManager.routes.user.getSettingsOfUser($rootScope.authData.sessionId, $rootScope.authData.userId).then(
                (response) => {
                    $rootScope.userSettings = response.data;
                    onFinished();
                },
                (response) => {
                    onFinished();
                }
            );
        }

        init();
    }]);

angular.module("app").config([
    "$locationProvider",
    "$routeProvider",
    "$translateProvider",
    "$httpProvider",
    function($locationProvider, $routeProvider, $translateProvider, $httpProvider){
        $locationProvider.hashPrefix('!');

        $httpProvider.defaults.headers.common = {};
        $httpProvider.defaults.headers.post = {};
        $httpProvider.defaults.headers.put = {};
        $httpProvider.defaults.headers.patch = {};

        $routeProvider.
        when('/login', {
            template: '<login></login>'
        }).
        when('/dashboard', {
            template: '<dashboard></dashboard>'
        }).
        when('/all-admonished-clients', {
            template: '<all-admonished-clients></all-admonished-clients>',
            reloadOnSearch: false
        }).
        when('/customers', {
            template: '<all-admonished-clients></all-admonished-clients>',
            reloadOnSearch: false
        }).
        when('/email-center', {
            template: '<email-center></email-center>'
        }).
        when('/email-center-incoming', {
            template: '<email-center-incoming></email-center-incoming>'
        }).
        when('/email-center-outgoing', {
            template: '<email-center-outgoing></email-center-outgoing>'
        }).
        when('/workflows', {
            template: '<workflows></workflows>'
        }).
        when('/settings', {
            template: '<settings></settings>'
        }).
        when('/profile', {
            template: '<profile></profile>'
        }).
        when('/user-details/:id', {
            template: '<user-details></user-details>'
        }).
        when('/error-page', {
            template: '<error-page></error-page>'
        }).
        when('/tasks', {
            template: '<tasks></tasks>'
        }).
        when('/editWorkflow/:id', { 
          template: '<edit-workflow></edit-workflow>'
        }).
        when('/register', { 
          template: '<register></register>'
        }).
        when('/email-check', { 
          template: '<email-check></email-check>'
        }).
        when('/analysis', {
            template: '<analysis></analysis>'
        }).
        when('/clients', {
            template: '<client-management></client-management>'
        }).
        when('/setup-wizard', {
            template: '<setup-wizard></setup-wizard>'
        }).
        when('/branding', {
            template: '<branding></branding>'
        }).
        when('/client-settings', {
            template: '<client-settings></client-settings>'
        }).
        when('/reset', {
            template: '<reset-custom-content></reset-custom-content>'
        }).
        when('/new-password/:id', {
            template: '<new-password></new-password>'
        }).
        // TODO unclear payments
        // when('/unclear-payments', {
        //     template: '<unclear-payments></unclear-payments>'
        // }).
        when('/support-ticket', {
            template: '<support-ticket></support-ticket>'
        }).
        when('/support-ticket/create', {
            template: '<support-ticket-create></support-ticket-create>'
        }).
        when('/support-ticket/list', {
            template: '<support-ticket-list></support-ticket-list>'
        }).
        when('/support-ticket/list/:id', {
            template: '<support-ticket-list></support-ticket-list>'
        }).
        otherwise('/login');

        $translateProvider.translations('de', translations.de);
        $translateProvider.useSanitizeValueStrategy('escape');
        $translateProvider.preferredLanguage('de');
    }]
);

angular.module('loginPanel', []);
angular.module('loginPanel').component('loginPanel', {
    templateUrl: 'app/components/loginPanel/loginPanel.template.html',
    controller: [
        "$scope",
        "requestManager",
        "$rootScope",
        "cookieManager",
        "Notification",
        "$filter",
        "$location",
        "sharedMemory",
        "logOutTimer",
        function ($scope, requestManager, $rootScope, cookieManager, Notification, $filter, $location, sharedMemory, logOutTimer)
        {
            this.$onInit = function(){
                $location.search({});
                initVariables();
                $scope.brandingLogo = undefined;
                $scope.imageLandscape = undefined;
                if ($rootScope.brandingLogo) {
                    $scope.brandingLogo = $rootScope.brandingLogo;
                    let img = new Image();
                    img.src = $scope.brandingLogo;
                    img.onload = function () {
                        $scope.imageLandscape = img.naturalWidth > img.naturalHeight;
                    }
                }
            };

            function initVariables(){
                $scope.loginFormModel = {
                    email: "",
                    password: ""
                };
                $scope.loginLoading = false;
                $scope.IEfound = false;
                $scope.resetPasswordFormModel = {
                    resetPasswordEmail: ""
                }
                $scope.forgotPassword = false;
                $scope.requestSent = false;
                $scope.submitLoading = false;
                let ua = window.navigator.userAgent;
                let msie = ua.indexOf("MSIE ");

                if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./))  // If Internet Explorer
                {
                    $scope.IEfound = true;
                }
            }

            function saveCookies(sessionId, userData, userSettings, settings){

                cookieManager.saveCookie({
                    sessionId: sessionId,
                    isAdmin: userData.administrator,
                    userId: userData.id,
                    sessionExpire: moment().add(10,"h").valueOf(),
                    userData: {
                        name: userData.name,
                        surname: userData.surname,
                        email: userData.email,
                        administrator: userData.administrator,
                        approvalStatus: userData.approvalStatus,
                        canCreateDunningNotes: userData.canCreateDunningNotes,
                        canCreateComments: userData.canCreateComments,
                        canDeleteComments: userData.canDeleteComments,
                        canCreatePhoneCalls: userData.canCreatePhoneCalls,
                        canEditReceivableInformation: userData.canEditReceivableInformation,
                        canEditTasks: userData.canEditTasks,
                        canMakeCustomersProblematic: userData.canMakeCustomersProblematic,
                    }
                });
            }

            function setAuthData(sessionId, userData, userSettings){
                $rootScope.authData.sessionId = sessionId;
                $rootScope.authData.userId = userData.id;
                $rootScope.authData.isAdmin = userData.administrator;
                $rootScope.authData.sessionExpire = moment().add(10, "h").valueOf();
                $rootScope.authData.isLoggedIn = true;
                $rootScope.authData.userData = {
                    name: userData.name,
                    surname: userData.surname,
                    email: userData.email,
                    approvalStatus: userData.approvalStatus
                };
                $rootScope.userSettings = userSettings;
                logOutTimer.saveDate($rootScope.authData.sessionExpire);
            }

            $scope.login = function(){
                $scope.loginLoading = true;
                if($scope.loginForm.$invalid){
                    $scope.loginLoading = false;
                    return;
                }

                requestManager.routes.auth.auth($scope.loginFormModel.password, $scope.loginFormModel.email).then(
                    function(response){
                        if(!response.data.userInfo[0]){
                            throw new Exception("Error while loading userdata at login");
                        }

                        let userSettingsArray = response.data.userInfo.map( setting => {
                                return {
                                    id: setting.settingId,
                                    settingCode: setting.settingCode,
                                    value1: setting.value1,
                                    value2: setting.value2,
                                    value3: setting.value3
                                }
                            }
                        );

                        let settingsArray = response.data.settings.map( setting => {
                                return {
                                    id: setting.id,
                                    settingCode: setting.settingCode,
                                    value1: setting.settingValue1,
                                    value2: setting.settingValue2,
                                    value3: setting.settingValue3
                                }
                            }
                        );

                        setAuthData(response.data.session, response.data.userInfo[0]);
                        saveCookies(response.data.session, response.data.userInfo[0], userSettingsArray, settingsArray);
                        setLocalStorage(response.data.userInfo[0], settingsArray);
                        $rootScope.userSettings = userSettingsArray;
                        $rootScope.settings = settingsArray;
                        $rootScope.settings.find(setting => {
                            if (setting.settingCode === 'europeanNumberFormat') {
                                $rootScope.europeanNumberFormat =
                                    setting.settingValue1 !== undefined ? setting.settingValue1 : setting.value1;
                            }
                            // TODO check if required
                            if (setting.settingCode === 'setupWizardCompleted') {
                                $rootScope.setupWizardCompleted = setting.settingValue1 !== undefined ? setting.settingValue1 : setting.value1;
                                if ($rootScope.setupWizardCompleted !== undefined && +$rootScope.setupWizardCompleted === 0) {
                                    // $location.path("/setup-wizard");
                                    window.location.reload();
                                }
                            }
                        });

                        if($rootScope.lastVisitedPage){
                            // $location.path($rootScope.lastVisitedPage);
                            window.location.reload();
                        }
                        else{
                            // $location.path("/dashboard");
                            window.location.reload();
                        }
                    },
                    function(response){
                        $scope.loginLoading = false;
                        Notification.error($filter("translate")("loginPanel.CREDENTIALS_FALSE_MSG"))
                    }
                )
            }

            $scope.onForgotPassword = function() {
                $scope.resetPasswordFormModel.resetPasswordEmail = $scope.loginFormModel.email;
                $scope.forgotPassword = true;
            }

            $scope.resetPassword = function() {
                if($scope.resetPasswordForm.$invalid) {
                    return;
                }

                $scope.submitLoading = true;
                requestManager.routes.auth.sendResetPasswordLink($scope.resetPasswordFormModel.resetPasswordEmail).then(
                    function () {
                        $scope.submitLoading = false;
                        Notification.success($filter("translate")("loginPanel.RESET_PASSWORD_SUCCESSFUL_MSG"));
                        $scope.requestSent = true;
                    },
                    function () {
                        $scope.submitLoading = false;
                        Notification.error("Bei der Verarbeitung der Anfrage ist es zu einem Fehler gekommen.");
                    }
                )
            }

            $scope.goToLogin = function() {
                $scope.resetPasswordFormModel.resetPasswordEmail = "";
                $scope.forgotPassword = false;
                $scope.requestSent = false;
            }

            function setLocalStorage(userData, settingsArray) {
                window.localStorage.removeItem("data");

                let isTaxFirm = undefined;
                let isCompany = undefined;
                settingsArray.find(setting => {
                    if (setting.settingCode === 'isTaxFirm') {
                        isTaxFirm =
                            setting.settingValue1 !== undefined ? setting.settingValue1 : setting.value1;
                    }
                    if (setting.settingCode === 'isCompany') {
                        isCompany = setting.settingValue1 !== undefined ? setting.settingValue1 : setting.value1;
                    }
                });
                let visible = 0;
                if (isTaxFirm !== undefined) {
                    visible = isTaxFirm;
                }
                if (isCompany !== undefined) {
                    visible = isCompany;
                }
                window.localStorage.setItem("data", JSON.stringify({
                    visible: visible,
                    isAdmin: userData.administrator,
                    name: userData.name + " " + userData.surname,
                    email: userData.email,
                    instance: $location.absUrl().replace("https://", "").replace("http://", "").split(".")[0]
                }));
            }
        }
    ]
}); 

angular.module('wrapper', []);

angular.module('wrapper').component('wrapper', {
    templateUrl: 'app/components/wrapper/wrapper.template.html',
    transclude: true,
    bindings: {
        title: "<",
        subtitle: "<",
        type: "@"
    },
    controller: [
        "$scope",
        "$location",
        "$rootScope",
        "cookieManager",
        "$route",
        "sharedMemory",
        "requestManager",
        "$routeParams",
        "$sce",
        "$window",
        "Notification",
        "$filter",
        "util",
        "logOutTimer",
        function ($scope, $location, $rootScope, cookieManager, $route, sharedMemory, requestManager, $routeParams, $sce, $window, Notification, $filter, util, logOutTimer) {
            this.$onInit = function () {
                $scope.title = this.title;
                $scope.subtitle = this.subtitle;
                $scope.type = this.type;
                initVariables();
                initScope();
                getCompanyName();
                getCustomer();
                $scope.instance = location.host.substring(0, location.host.indexOf(".debitorcloud"));
                if ($scope.type === "CUSTOMER_TOOLBAR") {
                    loadCustomerData($rootScope.authData.sessionId, $routeParams["id"], function (requestDetails) {
                        if (requestDetails.successful) {
                            $scope.customer.content = requestDetails.response.data[0];
                            $scope.customer.currentlyLoading = false;
                        } else {
                            sharedMemory.error.setError(
                                requestDetails,
                                "COULD_NOT_LOAD_DATA",
                                "Fehler beim laden der Kundendaten (beim initiieren der Komponente)",
                                $location.path(),
                                "wrapper"
                            );
                        }
                    });
                }

                if ($scope.type === "CUSTOMER_TOOLBAR") {
                    loadFavouriteStatus($rootScope.authData.sessionId, $rootScope.authData.userId, function (requestDetails) {
                        if (requestDetails.successful) {
                            $scope.favourite.content = requestDetails.response.data;
                            $scope.favourite.isFavourite = checkIfCustomerIsInFavouriteList($routeParams["id"], requestDetails.response.data);
                            $scope.favourite.isFavouriteString = $scope.favourite.isFavourite ? $filter("translate")("wrapper.IS_FAVOURITE") : $filter("translate")("wrapper.IS_NOT_FAVOURITE");
                            $scope.favourite.currentlyLoading = false;
                        } else {
                            sharedMemory.error.setError(
                                userRequestDetails,
                                "COULD_NOT_LOAD_DATA",
                                "Fehler beim laden des Kundenstatus (Favorit oder nicht)(beim initiieren der Komponente)",
                                $location.path(),
                                "wrapper"
                            );
                            $location.path("/error-page");
                        }
                    });
                    loadAccountsReceivableSummary($rootScope.authData.sessionId, $routeParams["id"], function (requestDetails) {
                        if (requestDetails.successful) {
                            $scope.customerStatistics.content = requestDetails.response.data.sumAmount;
                            $scope.customerStatistics.currentlyLoading = false;
                        } else {
                            sharedMemory.error.setError(
                                userRequestDetails,
                                "COULD_NOT_LOAD_DATA",
                                "Fehler beim laden der Kundenstatistiken (beim initiieren der Komponente)",
                                $location.path(),
                                "wrapper"
                            );
                            $location.path("/error-page");
                        }
                    });
                }

                requestManager.routes.settings.getSettings($rootScope.authData.sessionId).then(
                    function (response) {
                        $scope.settings.currentlyLoading = false;
                        $scope.settings.content = response.data;
                        let mailApprovalSetting = $scope.settings.content.find((setting) => {
                            return setting.settingCode === "mailApprovalActivated"
                        });
                        if (mailApprovalSetting.settingValue1 === "1") {
                            $scope.showEmailApprovalButton = true;
                        }
                    },
                    function (response) {
                        //Todo catch error
                    }
                );

            };

            function initVariables() {
                $scope.user = {
                    content: undefined,
                    currentlyLoading: true
                };
                $scope.favourite = {
                    currentlyLoading: true,
                    content: undefined,
                    isFavourite: undefined,
                    isFavouriteString: undefined
                };
                $scope.customer = {
                    currentlyLoading: true,
                    content: undefined
                };
                $scope.customerStatistics = {
                    currentlyLoading: true,
                    content: undefined
                };
                $scope.customerAdvisorModal = {
                    subject: undefined,
                    text: undefined,
                    currentlyLoading: false
                };
                $scope.settings = {
                    currentlyLoading: true,
                    content: undefined
                };
                $scope.showEmailApprovalButton = false;
                $scope.companyName = undefined;
                $scope.instance = undefined;
            }

            function initScope() {
                $scope.showAdmonition = showAdmonition;
                $scope.toggleFavourite = toggleFavourite;
                $scope.backToFilteredResults = backToFilteredResults;
                $scope.showCustomerAdvisorModal = showCustomerAdvisorModal;
                $scope.onCustomerAdvisorTaskFinished = onCustomerAdvisorTaskFinished;
                $scope.printCustomerReport = printCustomerReport;
                $scope.printCustomerReport2 = printCustomerReport2;
                $scope.printCustomerReport3 = printCustomerReport3;
                $scope.getCompanyName = getCompanyName;
            }

            function getCompanyName() {
                if ($location.path() === '/dashboard') {
                    if ($rootScope.settings === undefined) {
                        const interval = setInterval(() => {
                            if ($rootScope.settings !== undefined) {
                                $scope.companyName = $rootScope.settings.find(function (setting) {
                                    return setting.settingCode === 'companyName';
                                });
                                if ($scope.companyName !== undefined) {
                                    $scope.title = 'Dashboard - ';
                                    $scope.title += $scope.companyName.value1 === undefined ? $scope.companyName.settingValue1 : $scope.companyName.value1;
                                }
                                clearInterval(interval);
                            }
                        }, 100);
                    } else {
                        $scope.companyName = $rootScope.settings.find(function (setting) {
                            return setting.settingCode === 'companyName';
                        });
                        if ($scope.companyName !== undefined) {
                            $scope.title = 'Dashboard - ';
                            $scope.title += $scope.companyName.value1 === undefined ? $scope.companyName.settingValue1 : $scope.companyName.value1;
                        }
                    }
                }
            }

            function getCustomer() {
                if ($location.path().startsWith('/user-details')) {
                    if ($rootScope.settings === undefined) {
                        const interval = setInterval(() => {
                            if ($rootScope.settings !== undefined) {
                                const accountLengthImpersonal = $rootScope.settings.find(function (setting) {
                                    return setting.settingCode === 'accountLengthImpersonal';
                                });
                                if (accountLengthImpersonal !== undefined) {
                                    $scope.accountLengthImpersonal = +accountLengthImpersonal.settingValue1;
                                } else {
                                    $scope.accountLengthImpersonal = 8;
                                }
                                $scope.title = '<u>Kunde</u><br>' + $scope.customer.content.id.substring(0, +$scope.accountLengthImpersonal + 1) + ' - ' + ($scope.customer.content.name ? $scope.customer.content.name + ' ' : '') + ($scope.customer.content.surname ? $scope.customer.content.surname : '');
                                clearInterval(interval);
                            }
                        }, 100);
                    } else {
                        const interval = setInterval(() => {
                            if ($scope.customer.content !== undefined && $scope.customer.content.id !== undefined) {
                                const accountLengthImpersonal = $rootScope.settings.find(function (setting) {
                                    return setting.settingCode === 'accountLengthImpersonal';
                                });
                                if (accountLengthImpersonal !== undefined) {
                                    $scope.accountLengthImpersonal = +accountLengthImpersonal.settingValue1;
                                } else {
                                    $scope.accountLengthImpersonal = 8;
                                }
                                $scope.title = '<u>Kunde</u><br>' + $scope.customer.content.id.substring(0, +$scope.accountLengthImpersonal + 1) + ' - ' + ($scope.customer.content.name ? $scope.customer.content.name + ' ' : '') + ($scope.customer.content.surname ? $scope.customer.content.surname : '');
                                clearInterval(interval);
                            }
                        }, 100)

                    }
                }
            }
            
            function backToFilteredResults() {
                $window.history.back();
            }

            function loadFavouriteStatus(sessionId, userId, callback) {
                var requestDetails = {
                    successful: true,
                    response: undefined
                };

                var filter = [["id", "=", $routeParams["id"]]];

                requestManager.routes.user.getFavouriteCustomer(sessionId, userId, JSON.stringify(filter)).then(
                    function (response) {
                        requestDetails.response = response;
                        callback(requestDetails);
                    },
                    function (response) {
                        requestDetails.response = response;
                        requestDetails.successful = false;
                        callback(requestDetails);
                    }
                )
            }

            function checkIfCustomerIsInFavouriteList(customerId, customerList) {
                customerList = customerList.filter(item => item !== null);
                for (var i = 0; i < customerList.length; i++) {
                    if (customerList[i].id == customerId) {
                        return true;
                    }
                }

                return false;
            }

            function loadStatistics(sessionId, customerId, callback) {
                var requestDetails = {
                    successful: true,
                    response: undefined
                };

                requestManager.routes.customer.getStatisticsOfCustomer().then(
                    function (response) {
                        requestDetails.response = response;
                        callback(requestDetails);
                    },
                    function (response) {
                        requestDetails.response = response;
                        requestDetails.successful = false;
                        callback(requestDetails);
                    }
                )
            }

            function loadAccountsReceivableSummary(sessionId, customerId, callback) {
                var requestDetails = {
                    successful: true,
                    response: undefined
                };

                requestManager.routes.customer.virtual.getAccountsReceivableSummaryOfCustomer(sessionId, customerId).then(
                    (response) => {
                        requestDetails.response = response;
                        callback(requestDetails);
                    },
                    (response) => {
                        requestDetails.response = response;
                        requestDetails.successful = false;
                        callback(requestDetails);
                    },
                );
            }

            function showAdmonition() {
                //check what browser the user is surfing on
                var ua = window.navigator.userAgent;
                var msie = ua.indexOf("MSIE ");

                var IEfound = false;
                var EdgeFound = /Edge/.test(window.navigator.userAgent);

                if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./))  // If Internet Explorer
                {
                    IEfound = true;
                }

                if (!IEfound && !EdgeFound) {
                    var tabWindow = $window.open('about:blank', '_blank');
                    tabWindow.document.write('loading...');
                }

                var notificationReference = Notification.warning({
                    message: $filter("translate")("wrapper.LOADING_PDF"),
                    delay: null,
                    closeOnClick: false,
                });
                requestManager.routes.record.getDemandNoteOfCustomer($rootScope.authData.sessionId, $routeParams.id).then(
                    function (response) {
                        var file = new Blob([response.data], {type: 'application/pdf'});

                        if (!IEfound && !EdgeFound) {
                            var fileURL = URL.createObjectURL(file);
                            tabWindow.location.href = fileURL;
                        }
                        if (IEfound || EdgeFound) {
                            let r = "";
                            for (let i = 0; i < 4; i++) {
                                if (i % 4 == 0)
                                    r += Math.random().toString(36).substring(8);
                                else {
                                    r += "-";
                                    r += Math.random().toString(36).substring(4);
                                }
                            }
                            $window.navigator.msSaveOrOpenBlob(file, r + ".pdf");
                        }

                        //$window.open($sce.trustAsResourceUrl(fileURL), "_self");
                        notificationReference.then(function (notification) {
                            notification.kill(false);
                        });

                    },
                    function (response) {
                        Notification.error($filter("translate")("wrapper.NOTIFICATION_ERROR"));

                        notificationReference.then(function (notification) {
                            notification.kill(false);
                        });
                    }
                )
            }

            function printCustomerReport() {
                //check what browser the user is surfing on
                var ua = window.navigator.userAgent;
                var msie = ua.indexOf("MSIE ");

                var IEfound = false;
                var EdgeFound = /Edge/.test(window.navigator.userAgent);

                if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./))  // If Internet Explorer
                {
                    IEfound = true;
                }

                if (!IEfound && !EdgeFound) {
                    var tabWindow = $window.open('about:blank', '_blank');
                    tabWindow.document.write('loading...');
                }

                var notificationReference = Notification.warning({
                    message: $filter("translate")("wrapper.LOADING_PDF"),
                    delay: null,
                    closeOnClick: false,
                });
                requestManager.routes.record.getCustomerReport($rootScope.authData.sessionId, $routeParams.id).then(
                    function (response) {
                        var file = new Blob([response.data], {type: 'application/pdf'});

                        if (!IEfound && !EdgeFound) {
                            var fileURL = URL.createObjectURL(file);
                            tabWindow.location.href = fileURL;
                        }
                        if (IEfound || EdgeFound) {
                            let r = "";
                            for (let i = 0; i < 4; i++) {
                                if (i % 4 == 0)
                                    r += Math.random().toString(36).substring(8);
                                else {
                                    r += "-";
                                    r += Math.random().toString(36).substring(4);
                                }
                            }
                            $window.navigator.msSaveOrOpenBlob(file, r + ".pdf");
                        }

                        //$window.open($sce.trustAsResourceUrl(fileURL), "_self");
                        notificationReference.then(function (notification) {
                            notification.kill(false);
                        });

                    },
                    function (response) {
                        Notification.error($filter("translate")("wrapper.NOTIFICATION_ERROR"));

                        notificationReference.then(function (notification) {
                            notification.kill(false);
                        });
                    }
                )
            }

            function printCustomerReport2() {
                //check what browser the user is surfing on
                var ua = window.navigator.userAgent;
                var msie = ua.indexOf("MSIE ");

                var IEfound = false;
                var EdgeFound = /Edge/.test(window.navigator.userAgent);

                if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./))  // If Internet Explorer
                {
                    IEfound = true;
                }

                if (!IEfound && !EdgeFound) {
                    var tabWindow = $window.open('about:blank', '_blank');
                    tabWindow.document.write('loading...');
                }

                var notificationReference = Notification.warning({
                    message: $filter("translate")("wrapper.LOADING_PDF"),
                    delay: null,
                    closeOnClick: false,
                });
                requestManager.routes.record.getCustomerReport2($rootScope.authData.sessionId, $routeParams.id).then(
                    function (response) {
                        var file = new Blob([response.data], {type: 'application/pdf'});

                        if (!IEfound && !EdgeFound) {
                            var fileURL = URL.createObjectURL(file);
                            tabWindow.location.href = fileURL;
                        }
                        if (IEfound || EdgeFound) {
                            let r = "";
                            for (let i = 0; i < 4; i++) {
                                if (i % 4 == 0)
                                    r += Math.random().toString(36).substring(8);
                                else {
                                    r += "-";
                                    r += Math.random().toString(36).substring(4);
                                }
                            }
                            $window.navigator.msSaveOrOpenBlob(file, r + ".pdf");
                        }

                        //$window.open($sce.trustAsResourceUrl(fileURL), "_self");
                        notificationReference.then(function (notification) {
                            notification.kill(false);
                        });

                    },
                    function (response) {
                        Notification.error($filter("translate")("wrapper.NOTIFICATION_ERROR"));

                        notificationReference.then(function (notification) {
                            notification.kill(false);
                        });
                    }
                )
            }

            function printCustomerReport3() {
                //check what browser the user is surfing on
                var ua = window.navigator.userAgent;
                var msie = ua.indexOf("MSIE ");

                var IEfound = false;
                var EdgeFound = /Edge/.test(window.navigator.userAgent);

                if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./))  // If Internet Explorer
                {
                    IEfound = true;
                }

                if (!IEfound && !EdgeFound) {
                    var tabWindow = $window.open('about:blank', '_blank');
                    tabWindow.document.write('loading...');
                }

                var notificationReference = Notification.warning({
                    message: $filter("translate")("wrapper.LOADING_PDF"),
                    delay: null,
                    closeOnClick: false,
                });
                requestManager.routes.record.getCustomerReport3($rootScope.authData.sessionId, $routeParams.id).then(
                    function (response) {
                        var file = new Blob([response.data], {type: 'application/pdf'});

                        if (!IEfound && !EdgeFound) {
                            var fileURL = URL.createObjectURL(file);
                            tabWindow.location.href = fileURL;
                        }
                        if (IEfound || EdgeFound) {
                            let r = "";
                            for (let i = 0; i < 4; i++) {
                                if (i % 4 == 0)
                                    r += Math.random().toString(36).substring(8);
                                else {
                                    r += "-";
                                    r += Math.random().toString(36).substring(4);
                                }
                            }
                            $window.navigator.msSaveOrOpenBlob(file, r + ".pdf");
                        }

                        //$window.open($sce.trustAsResourceUrl(fileURL), "_self");
                        notificationReference.then(function (notification) {
                            notification.kill(false);
                        });

                    },
                    function (response) {
                        Notification.error($filter("translate")("wrapper.NOTIFICATION_ERROR"));

                        notificationReference.then(function (notification) {
                            notification.kill(false);
                        });
                    }
                )
            }

            function toggleFavourite() {
                if ($scope.favourite.isFavourite) {
                    sendRemoveFavouriteRequest($rootScope.authData.sessionId, $rootScope.authData.userId, $routeParams["id"], function (requestDetails) {
                        if (requestDetails.successful) {
                            Notification.error($filter("translate")("wrapper.REMOVE_FAVOURITE_SUCCESS"));
                            $route.reload();
                        } else {
                            sharedMemory.error.setError(
                                requestDetails,
                                "COULD_NOT_LOAD_DATA",
                                "Fehler beim entfernen eines Kunden als Favorit)",
                                $location.path(),
                                "wrapper"
                            );
                            $location.path("/error-page");
                        }
                    });
                } else {
                    sendSetFavouriteRequest($rootScope.authData.sessionId, $rootScope.authData.userId, $routeParams["id"], function (requestDetails) {
                        if (requestDetails.successful) {
                            Notification.success($filter("translate")("wrapper.SET_FAVOURITE_SUCCESS"));
                            $route.reload();
                        } else {
                            sharedMemory.error.setError(
                                requestDetails,
                                "COULD_NOT_LOAD_DATA",
                                "Fehler beim setzen eines Kunden als Favorit)",
                                $location.path(),
                                "wrapper"
                            );
                            $location.path("/error-page");
                        }
                    });
                }
            }

            function sendSetFavouriteRequest(sessionId, userId, customerId, callback) {
                var requestDetails = {
                    successful: true,
                    response: undefined
                };

                requestManager.routes.user.setFavouriteCustomer(sessionId, userId, customerId).then(
                    function (response) {
                        requestDetails.response = response;
                        callback(requestDetails);
                    },
                    function (response) {
                        requestDetails.response = response;
                        requestDetails.successful = false;
                        callback(requestDetails);
                    }
                )
            }

            function sendRemoveFavouriteRequest(sessionId, userId, customerId, callback) {
                var requestDetails = {
                    successful: true,
                    response: undefined
                };

                requestManager.routes.user.removeFavouriteCustomer(sessionId, userId, customerId).then(
                    function (response) {
                        requestDetails.response = response;
                        callback(requestDetails);
                    },
                    function (response) {
                        requestDetails.response = response;
                        requestDetails.successful = false;
                        callback(requestDetails);
                    }
                )
            }

            function loadCustomerData(sessionId, customerId, callback) {
                var requestDetails = {
                    successful: true,
                    response: undefined
                };

                requestManager.routes.customer.getCustomerWithId(sessionId, customerId).then(
                    function (response) {
                        requestDetails.response = response;
                        callback(requestDetails);
                    },
                    function (response) {
                        requestDetails.response = response;
                        requestDetails.successful = false;
                        callback(requestDetails);
                    }
                )
            }

            function showCustomerAdvisorModal() {
                if (!($rootScope.pricingModel === 'v2' || $rootScope.pricingModel === 'v2+')) {
                    jQuery("#customerAdvisorModal").modal("show");
                }
            }

            function onCustomerAdvisorTaskFinished() {
                if ($scope.customerAdvisorTaskFinishedForm.$invalid) {
                    return;
                }

                $scope.customerAdvisorModal.currentlyLoading = true;

                let editCustomerParams = {
                    id: $routeParams.id,
                    isAtCustomerAdvisor: 0,
                    customerAdvisor: null,
                }

                requestManager.routes.customer.editCustomer($rootScope.authData.sessionId, editCustomerParams.id, editCustomerParams).then(
                    onEditCustomerRequestFinished,
                    onRequestError
                )

                function onEditCustomerRequestFinished(response) {
                    requestManager.routes.customer.createRecordOfCustomer($rootScope.authData.sessionId, $routeParams.id, "record_customer_advisor").then(
                        onCreateRecordOfCustomerRequestFinished,
                        onRequestError
                    )
                }

                function onCreateRecordOfCustomerRequestFinished(response) {
                    let recordId = response.data;
                    let params = {
                        content: $scope.customerAdvisorModal.text,
                        subject: $scope.customerAdvisorModal.subject,
                        attachReport: 0
                    }

                    requestManager.routes.record.createEmailRecordOfRecord($rootScope.authData.sessionId, recordId, params).then(
                        onCreateEmailRecordOfRecordRequestFinished,
                        onRequestError
                    )
                }

                function onCreateEmailRecordOfRecordRequestFinished(response) {
                    let recordEmailId = response.data;
                    let params = {
                        type: "to",
                        mail: $rootScope.authData.userData.email
                    }

                    requestManager.routes.record.createRecordEmailRecipientOfRecordEmail($rootScope.authData.sessionId, recordEmailId, params).then(
                        onCreateRecordEmailRecipientOfRecordEmailFinished,
                        onRequestError
                    )
                }

                function onCreateRecordEmailRecipientOfRecordEmailFinished(response) {
                    jQuery("#customerAdvisorModal").one("hidden.bs.modal", function () {
                        $route.reload();
                        Notification.success($filter("translate")("wrapper.CUSTOMER_ADVISOR_TASK_FINISHED_MESSAGE"));
                    }).modal("hide");
                }

                function onRequestError(response) {
                    sharedMemory.error.setError(
                        response,
                        "COULD_NOT_LOAD_DATA",
                        "Fehler beim entfernen der verlinkung von Kundenbetreuer und Kunden",
                        $location.path(),
                        "wrapper"
                    );
                    $location.path("/error-page");
                }
            }

        }
    ]
}); 

angular.module('prognosticatedPayments', []);
angular.module('prognosticatedPayments').component('prognosticatedPayments', { 
	templateUrl: 'app/components/prognosticatedPayments/prognosticatedPayments.template.v1.html',
	bindings: {
		emailListAmount: "<",
		physicalMailListAmount: "<"
	},
	controller: [
		"$scope",
		"$rootScope",
		"requestManager",
		"sharedMemory",
		"$location",
		function ($scope, $rootScope, requestManager, sharedMemory, $location) {
			init();

			this.$onChanges = function set(item) {
				if (item && item.emailListAmount && item.emailListAmount.currentValue !== undefined) {
					$scope.toBeApproved.emailListAmount = +item.emailListAmount.currentValue;
				}
				if (item && item.physicalMailListAmount && item.physicalMailListAmount.currentValue !== undefined) {
					$scope.toBeApproved.physicalMailListAmount = +item.physicalMailListAmount.currentValue;
				}
			};

			/*
			* Functions
			* */

			function init() {
				$scope.europeanNumberFormat = 'en-US';
				if ($rootScope.europeanNumberFormat !== undefined) {
					if ($rootScope.europeanNumberFormat === '1' || $rootScope.europeanNumberFormat === 1) {
						$scope.europeanNumberFormat = 'de-AT';
					}
					else {
						$scope.europeanNumberFormat = 'en-US';
					}
				}
				initVariables();
				initScope();

				getNumberOfOpenTasks($rootScope.authData.sessionId, function (requestDetails) {
					if (requestDetails.successful) {
						$scope.openTasks = requestDetails.response.data.count;
						$scope.openTasksLoading = false;
					}
				});

				loadStatistics($rootScope.authData.sessionId, function (requestDetails) {
					if (requestDetails.successful) {
						$scope.prognosticatedPayments.opActual = requestDetails.response.data.saldo;
						$scope.prognosticatedPayments.opActualOverdue90 = requestDetails.response.data.saldoOverdueBetween90and61Days + requestDetails.response.data.saldoOverdueMoreThan90Days;
						$scope.prognosticatedPayments.opActualOverdue60 = requestDetails.response.data.saldoOverdueBetween60and31Days;
						$scope.prognosticatedPayments.opActualOverdue30 = requestDetails.response.data.saldoOverdueBetween30and1Days;
					} else {
						$scope.prognosticatedPayments.opActualOverdue = 0;
						$scope.prognosticatedPayments.opActual = 0;
						$scope.prognosticatedPayments.opActualOverdue30 = 0;
						$scope.prognosticatedPayments.opActualOverdue60 = 0;
						$scope.prognosticatedPayments.opActualOverdue90 = 0;
						$scope.prognosticatedPayments.prognosisPaymentTerm = 0;
					}
				});
			}

			function initVariables(){
				$scope.currentTab = 0; 
				
				$scope.prognosticatedPayments = {
					currentlyLoading: true,
					opActualOverdue: undefined,
					opActual: undefined,
					opActualOverdue30: undefined,
					opActualOverdue60: undefined,
					opActualOverdue90: undefined,
					prognosisPaymentTerm: undefined,
				}

				$scope.toBeApproved = {
					emailListAmount: undefined,
					physicalMailListAmount: undefined
				}

				$scope.openTasksLoading = true;
			}

			function initScope() {
				$scope.forwardToEmailCheck = forwardToEmailCheck;
			}

			function forwardToTasks() {
				$scope.location = $location.path('/tasks');
			}


			function forwardToEmailCheck() {
				$scope.location = $location.path('/email-check');
			}

			function getNumberOfOpenTasks(sessionId, callback) {
				var requestDetails = {
					response: undefined,
					successful: true,
				};
				requestManager.routes.task.getNumberOfOpenTasks(sessionId).then(
					function(response) {
						requestDetails.response = response;
						callback(requestDetails);
					},
					function () {
						requestDetails.successful = false;
						callback(requestDetails);
					}
				)
			}


			function loadStatistics(sessionId, callback) {
				let requestDetails = {
					successful: true,
					response: undefined
				};

				requestManager.routes.system.statisticsV2(sessionId).then(
					function (response) {
						requestDetails.response = response;
						callback(requestDetails);
					},
					function () {
						requestDetails.successful = false;
						callback(requestDetails);
					}
				)
			}
		} 
	] 
}); 

angular.module('problematicClients', []);
angular.module('problematicClients').component('problematicClients', {
	templateUrl: 'app/components/problematicClients/problematicClients.template.html',
	bindings: {
		accountLengthImpersonal: "<"
	},
	controller: [
		"requestManager",
		"$scope",
		"$rootScope",
		"$location",
		"sharedMemory",
		"util", 
		function (requestManager, $scope, $rootScope, $location, sharedMemory, util) {
			this.$onInit = function(){
				$scope.europeanNumberFormat = 'en-US';
				if ($rootScope.europeanNumberFormat !== undefined) {
					if ($rootScope.europeanNumberFormat === '1' || $rootScope.europeanNumberFormat === 1) {
						$scope.europeanNumberFormat = 'de-AT';
					}
					else {
						$scope.europeanNumberFormat = 'en-US';
					}
				}
				initVariables();
				initScope();
				loadProblematicClientsList(
					function(requestDetails){
						if(requestDetails.allSuccessful){
							$scope.customerList.content = requestDetails.response.data;
							$scope.customerList.contentChunked = util.divideArrayIntoChunks($scope.customerList.content, 10); 
							$scope.customerList.isEmpty = requestDetails.response.data.length == 0;
							$scope.customerList.currentlyLoading = false;
						}
						else{
							if (!requestDetails.canceledRequest) {
								sharedMemory.error.setError(
									requestDetails,
									"COULD_NOT_LOAD_DATA",
									"Fehler beim laden der problematischen Kunden (beim initiieren der Komponente)",
									$location.path(),
									"problematicClients"
								);
								$location.path("/error-page");
							}
						}
					}
				);
			};

			this.$onChanges = function (item) {
				$scope.accountLengthImpersonal = item.accountLengthImpersonal.currentValue;
			}

			function initScope(){
				$scope.changePaginationIndex = changePaginationIndex;
				$scope.forwardToCustomer = forwardToCustomer;
				$scope.openInfoModal = openInfoModal; 
			}
			
			function loadProblematicClientsList(callback){
				var requestDetails = {
					allSuccessful: true,
					response: undefined, 
					canceledRequest: false
				};

				requestManager.routes.customer.virtual.getProblematicCustomer($rootScope.authData.sessionId).then(
					function(response){
						requestDetails.response = response;
						requestDetails.response.data.map(val => {
							val.sumSaldo = +val.sumSaldo;
						});
						callback(requestDetails);
					},
					function(response){
						requestDetails.response = response;
						requestDetails.allSuccessful = false;
						requestDetails.canceledRequest = response.status == -1;
						callback(requestDetails);
					}
				)
			}

			function initVariables(){
				$scope.customerList = {
					currentlyLoading: true,
					content: [],
					isEmpty: true
				};
				$scope.pagination =  {
					currentIndex: 0, 
					paginationButtonsArray: undefined
				};
			}

			function forwardToCustomer(customerId){
                $location.path("/user-details/" + customerId);
			}
	
			function changePaginationIndex(value){
				if(value < 0){
					if($scope.pagination.currentIndex > 0){
						$scope.pagination.currentIndex += value;
					}
				}
				else{
					if($scope.pagination.currentIndex < $scope.customerList.contentChunked.length-1){
						$scope.pagination.currentIndex += value;
					}
				}
			}
			
			function openInfoModal(){
				jQuery("#problematicClientsInfoModal").modal("show"); 
			}
		} 
	] 
}); 

angular.module('favouriteClients', []);
angular.module('favouriteClients').component('favouriteClients', { 
	templateUrl: 'app/components/favouriteClients/favouriteClients.template.html',
	bindings: {
		accountLengthImpersonal: "<"
	},
	controller: [
		"$scope",
		"$rootScope",
		"requestManager",
		"sharedMemory",
		"$location",
		"util",
		function ($scope, $rootScope, requestManager, sharedMemory, $location, util) {
			this.$onInit = function(){
				initVariables();
				initScope();
				loadFavouriteClients($rootScope.authData.sessionId, $rootScope.authData.userId, function(favouriteClientsRequestDetails){
					if(favouriteClientsRequestDetails.successful){
						$scope.favouriteClientsList.content = favouriteClientsRequestDetails.response.data;
						$scope.favouriteClientsList.contentChunked = util.divideArrayIntoChunks(favouriteClientsRequestDetails.response.data);
						$scope.favouriteClientsList.currentlyLoading = false;
						$scope.favouriteClientsList.isEmpty = $scope.favouriteClientsList.content.length == 0;
					}
					else{
						if (!favouriteClientsRequestDetails.canceledRequest) {
							sharedMemory.error.setError(
								favouriteClientsRequestDetails,
								"COULD_NOT_LOAD_DATA",
								"Fehler beim laden der Favoriten (beim initiieren der Komponente)",
								$location.path(),
								"favouriteClients"
							);
							$location.path("/error-page");
						}
					}
				});
			};

			this.$onChanges = function (item) {
				$scope.accountLengthImpersonal = item.accountLengthImpersonal.currentValue;
			}
			
			function initVariables(){
				$scope.favouriteClientsList = {
					currentlyLoading: true,
					content: [],
					isEmpty: true
				};
				$scope.user = {
					currentlyLoading: true,
					userDetails: undefined
				};
				$scope.pagination =  {
					currentIndex: 0, 
					paginationButtonsArray: undefined
				};
			}
			
			function initScope(){
				$scope.changePaginationIndex = changePaginationIndex;
				$scope.goToUserDetails = goToUserDetails;
				$scope.openInfoModal = openInfoModal; 
			}

			function loadFavouriteClients(sessionId, userId, callback) {
				var requestDetails = {
						successful: true,
						response: undefined, 
						canceledRequest: false
				};
					
				requestManager.routes.user.getFavouriteCustomer(sessionId, userId).then(
					function(response){
						requestDetails.response = response;
						callback(requestDetails);
					},
					function(response){
						requestDetails.response = response;
						requestDetails.successful = false;
						requestDetails.canceledRequest = response.status == -1;
						callback(requestDetails);
					}
				)
			}

			function goToUserDetails(customerId){
				$location.path("/user-details/" + customerId);
			}
						
			function changePaginationIndex(value){
				if(value < 0){
					if($scope.pagination.currentIndex > 0){
						$scope.pagination.currentIndex += value;
					}
				}
				else{
					if($scope.pagination.currentIndex < $scope.favouriteClientsList.contentChunked.length-1){
						$scope.pagination.currentIndex += value;
					}
				}
			}
			
			function openInfoModal(){
				jQuery("#favouriteClientsInfoModal").modal("show"); 
			}
		} 
	] 
}); 

angular.module('admonishedClientsList', []);
angular.module('admonishedClientsList').component('admonishedClientsList', {
    templateUrl: 'app/components/admonishedClientsList/admonishedClientsList.template.html',
    controller: [
        "$scope",
        "requestManager",
        "$rootScope",
        "$location",
        "util",
        "sharedMemory",
        "$filter",
        "Notification",
        "$route",
        "cookieManager",
        "paginationManager",
        function($scope, requestManager, $rootScope, $location, util, sharedMemory, $filter, Notification, $route, cookieManager, paginationManager) {
            this.$onInit = function() {
                $scope.europeanNumberFormat = 'en-US';
                if ($rootScope.europeanNumberFormat !== undefined) {
                    if ($rootScope.europeanNumberFormat === '1' || $rootScope.europeanNumberFormat === 1) {
                        $scope.europeanNumberFormat = 'de-AT';
                    }
                    else {
                        $scope.europeanNumberFormat = 'en-US';
                    }
                }
                initVariables();
                initScope();

                $scope.requestParams = $location.search();
                if (Object.values($scope.requestParams).length > 0) {
                    if ($scope.requestParams.page) {
                        if (parseInt($scope.requestParams.page, 10)) {
                            $scope.requestParamPage = parseInt($scope.requestParams.page, 10);
                            $scope.pagination.paginationIndex = $scope.requestParamPage - 1;
                            paginationManager.saveIndex($scope.pagination.paginationIndex);
                        }
                    }
                    if ($scope.requestParams.search) {
                        $scope.requestParamSearch = $scope.requestParams.search;
                        $scope.filter.searchTerm = $scope.requestParamSearch;
                    }
                }

                $scope.instance = location.host.substring(0, location.host.indexOf(".debitorcloud"));

                getUserSettings($rootScope.authData.sessionId, $rootScope.authData.userId, function(userSettingsRequestDetails){
                    if(userSettingsRequestDetails.successful){
                        $scope.userSettings.content = userSettingsRequestDetails.content;
                        $scope.userSettings.contentFiltered = getListSettings($rootScope.userSettings);
                        if ($scope.userSettings.contentFiltered.defaultNumRowsInList.value1 === 'ALL') {
                            $scope.userSettings.contentFiltered.defaultNumRowsInList.value1 = 100;
                        }
                        $scope.filter.numShowEntriesSelected = +$scope.userSettings.contentFiltered.defaultNumRowsInList.value1;
                        if ($scope.userSettings.contentFiltered.showAllCustomers !== undefined) {
                            $scope.filter.onlyCustomerVacantPos = +$scope.userSettings.contentFiltered.showAllCustomers.value1 === 0 ? true : false;
                        } else {
                            $scope.filter.onlyCustomerVacantPos = true;
                        }
                        $scope.userSettings.currentlyLoading = false;
                        let filter;
                        filter = $scope.userSettings.content.find((setting) => {
                            return setting.settingCode === "filterChoice";
                        });
                        if (filter !== undefined) {
                            $scope.filterSettingId = filter.id;
                            $scope.filter.sortAscending = filter.value2 !== undefined && filter.value2 === 'DESC' ? 'DESC' : 'ASC';
                            applyFilterToList(filter.value1);
                        } else {
                            loadUserList(function(requestDetails) {
                                loadUserListCallback(requestDetails);
                            });
                        }
                    }
                    else{
                        sharedMemory.error.setError(
                            userSettingsRequestDetails,
                            "COULD_NOT_LOAD_DATA",
                            "Fehler beim laden der User-Settings (beim initiieren der Komponente)",
                            $location.path(),
                            "userProfileSettings"
                        );
                        $location.path("/error-page");
                    }
                });

                requestManager.routes.settings.getSettings($rootScope.authData.sessionId).then(
                    (response) => {
                        const mailApprovalSetting = response.data.find((setting) => {
                            return setting.settingCode === "mailApprovalActivated";
                        });
                        const accountLengthImpersonal = response.data.find((setting) => {
                            return setting.settingCode === "accountLengthImpersonal";
                        });
                        $scope.mailApprovalActivated = mailApprovalSetting.settingValue1 === "1";
                        if (accountLengthImpersonal !== undefined) {
                            $scope.accountLengthImpersonal = +accountLengthImpersonal.settingValue1;
                        } else {
                            $scope.accountLengthImpersonal = 8;
                        }
                        $scope.settings.content = response.data;
                        $scope.settings.currentlyLoading = false;
                    },
                    () => {
                        $scope.settings.currentlyLoading = false;
                    }
                );

                requestManager.routes.user.getAllUser($rootScope.authData.sessionId).then(
                    function (response) {
                        $scope.allUsers.content = response.data;
                        $scope.allUsers.currentlyLoading = false;
                    },
                    function (response) {
                        sharedMemory.error.setError(
                            response,
                            "COULD_NOT_LOAD_DATA",
                            "Fehler beim laden der User",
                            $location.path(),
                            "settings"
                        );
                        $location.path("/error-page");
                    }
                );
            };

            $scope.$watchGroup(["userList.currentlyLoading", "userSettings.currentlyLoading", "settings.currentlyLoading"], function() {
                if (!$scope.userList.currentlyLoading && !$scope.userSettings.currentlyLoading && !$scope.settings.currentlyLoading) {
                    $scope.allDependenciesLoaded = true;
                }
            });

            function initVariables() {
                $scope.allDependenciesLoaded = false;
                $scope.userList = {
                    content: undefined,
                    totalRows: undefined,
                    currentlyLoading: true,
                };
                $scope.pagination = {
                    paginationIndex: paginationManager.readIndex() === undefined ? 0 : paginationManager.readIndex(),
                    paginationArray: []
                };
                $scope.filter = {
                    sortBy: undefined,
                    sortAscending: 'ASC',
                    sortType: undefined,
                    menuOpened: true,
                    numShowEntriesOptions: [10, 25, 50, 75, 100],
                    numShowEntriesSelected: undefined,
                    searchTerm: undefined,
                    onlyCustomerVacantPos: true,
                    onlyProblematic: false,
                    onlyFavorites: false,
                    onlyWithPaymentAgreement: false,
                    onlyWithUpcomingReminder: false,
                    onlyCustomerAssignedToMe: false,
                    onlyCustomerAssignedToExternalPartner: false,
                    autoBrandOptions: [
                        {value: '01', name: 'MB PKW'},
                        {value: '02', name: 'Smart'},
                        {value: '03', name: 'MB LKW'},
                        {value: '04', name: 'MB Trapo'},
                        {value: '06', name: 'FF PKW'},
                        {value: '07', name: 'FF LKW'},
                        {value: '08', name: 'FF Trapo'},
                        {value: '09', name: 'FF FF NFZ'},
                        {value: '10', name: 'Hyundai'},
                        {value: '11', name: 'Aston Martin'},
                        {value: '20', name: 'alle Marken'},
                    ],
                    selectedAutoBrand: '20',
                    autoHouseOptions: [
                        {value: '01', name: 'Husum'},
                        {value: '02', name: 'HH-Alstertal'},
                        {value: '03', name: 'Hyundai Heide'},
                        {value: '04', name: 'Busdorf'},
                        {value: '05', name: 'Eckernförde'},
                        {value: '06', name: 'Heide'},
                        {value: '07', name: 'Marne'},
                        {value: '08', name: 'HH-Elbe'},
                        {value: '09', name: 'HH-Rothenbaum'},
                        {value: '10', name: 'SL-Hyundai'},
                        {value: '11', name: 'HH-Bergedorf'},
                        {value: '12', name: 'Reinbek'},
                        {value: '13', name: 'Ahrensburg'},
                        {value: '14', name: 'Trittau'},
                        {value: '16', name: 'Hyundai Bergedorf'},
                        {value: '17', name: 'Hyundai Schwarzenbek'},
                        {value: '18', name: 'Hyundai Harburg'},
                        {value: '19', name: 'Hyundai Itzehoe'},
                        {value: '20', name: 'Alt Alle Center'},
                        {value: '21', name: 'Brandenburg'},
                        {value: '22', name: 'Wittstock'},
                        {value: '23', name: 'Bad Belzig'},
                        {value: '24', name: 'Neuruppin'},
                        {value: '25', name: 'Hyundai Neumünster'},
                        {value: '26', name: 'Hyundai Flensburg'},
                        {value: '27', name: 'Hannover'},
                        {value: '99', name: 'Neu Alle Center'},
                        {value: '100', name: 'alle Standorte'}
                    ],
                    selectedAutoHouse: '100',
                    hasPartsInvoice: false,
                    hasNewCarInvoice: false,
                    hasUsedCarInvoice: false
                };
                $scope.userSettings = {
                    currentlyLoading: true,
                    content: undefined,
                    contentFiltered: undefined,
                };
                $scope.filterSettingId = undefined;
                $scope.floatTheadOptions = {
                    scrollContainer: true,
                    position: 'absolute'
                };
                $scope.instance = undefined;
                $scope.allUsers = {
                    content: undefined,
                    currentlyLoading: true
                };
                $scope.settings = {
                    content: undefined,
                    currentlyLoading: true
                };
                $scope.exportFileLoading = false;
            }

            function initScope() {
                $scope.$on('$routeChangeStart', function() {
                    paginationManager.initiateIndex();
                    requestManager.stopPendingRequests();
                    saveChangedSettings();
                });

                $scope.forwardToUserPage = forwardToUserPage;
                $scope.increasePaginationIndex = increasePaginationIndex;
                $scope.decreasePaginationIndex = decreasePaginationIndex;
                $scope.goToPage = goToPage;
                $scope.applyFilterToList = applyFilterToList;
                $scope.resetFilter = resetFilter;
                $scope.changeNumShowEntries = changeNumShowEntries;
                $scope.toggleFilterOptions = toggleFilterOptions;
                $scope.selectAutoHouse = selectAutoHouse;
                $scope.selectAutoBrand = selectAutoBrand;
                $scope.toggleShowAll = toggleShowAll;
                $scope.downloadExcelFile = downloadExcelFile;
            }

            function downloadExcelFile() {
                $scope.exportFileLoading = true;
                requestManager.routes.customer.getCustomerExcelFile($rootScope.authData.sessionId).then(
                    function(response){
                        $scope.exportFileLoading = false;
                        let blob = new Blob([response.data], { type: 'application/octet-stream' });
                        saveFile(blob, 'Debitorcloud_Kundenuebersicht_Export.xlsx');
                    },
                    function(){
                        $scope.exportFileLoading = false;
                        Notification.warning({
                            message: 'Die Dateierstellung ist fehlgeschlagen',
                            delay: null,
                            closeOnClick: true,
                        });
                    }
                )
            }

            function saveFile(blob, fileName) {
                const downloadLink = document.createElement('a');
                downloadLink.href = window.URL.createObjectURL(blob);
                downloadLink.download = fileName;

                document.body.appendChild(downloadLink);
                downloadLink.click();
                document.body.removeChild(downloadLink);
            }

            function loadUserListCallback(requestDetails) {
                if (requestDetails.successful) {
                    $scope.userList.content = requestDetails.response.data.customers;
                    $scope.userList.totalRows = +requestDetails.response.data.totalRows;
                    $scope.userList.currentlyLoading = false;
                    createPaginationArray();
                } else {
                    if (!requestDetails.canceledRequest) {
                        sharedMemory.error.setError(
                            requestDetails,
                            "COULD_NOT_LOAD_DATA",
                            "Fehler beim laden der Userliste (beim initiieren der Komponente)",
                            $location.path(),
                            "admonishedClientsList"
                        );
                        $location.path("/error-page");
                    }
                }
            }

            function getUserSettings(sessionId, userId, callback){
                var requestDetails = {
                    fromCache: false,
                    response: undefined,
                    successful: true,
                    content: undefined
                };

                if(sharedMemory.userSettings.isSet){
                    requestDetails.content = sharedMemory.userSettings.getUserSettings();
                    requestDetails.fromCache = true;
                    callback(requestDetails);
                }
                else{
                    requestManager.routes.user.getSettingsOfUser(sessionId, userId).then(
                        function(response){
                            requestDetails.response = response;
                            requestDetails.content = response.data;
                            callback(requestDetails);
                        },
                        function(response){
                            requestDetails.response = response;
                            requestDetails.content = response.data;
                            requestDetails.successful = false;
                            callback(requestDetails);
                        }
                    )
                }
            }

            function toggleFilterOptions(){
                if($scope.filter.menuOpened){
                    jQuery("#filterOptions").hide(500)
                }
                else{
                    jQuery("#filterOptions").show(500);
                }
                $scope.filter.menuOpened = !$scope.filter.menuOpened;
            }

            function toggleShowAll() {
                loadUserList(function(requestDetails) {
                    loadUserListCallback(requestDetails);
                });
            }

            function loadUserList(callback) {
                $scope.userList.currentlyLoading = true;
                var requestDetails = {
                    response: undefined,
                    successful: true,
                    canceledRequest: false
                };

                requestManager.routes.customer.virtual.getCustomerListExtended(
                    $rootScope.authData,
                    $scope.filter.numShowEntriesSelected,
                    $scope.filter.numShowEntriesSelected * paginationManager.readIndex(),
                    $scope.filter.sortBy,
                    $scope.filter.sortAscending === 'ASC' ? 'ASC' : 'DESC',
                    $scope.filter.searchTerm,
                    $scope.filter.onlyProblematic,
                    $scope.filter.onlyCustomerAssignedToMe,
                    $scope.filter.onlyCustomerAssignedToExternalPartner,
                    $scope.filter.selectedAutoBrand,
                    $scope.filter.selectedAutoHouse,
                    $scope.filter.hasPartsInvoice,
                    $scope.filter.hasNewCarInvoice,
                    $scope.filter.hasUsedCarInvoice,
                    $scope.filter.onlyCustomerVacantPos
                ).then(
                    function(response) {
                        requestDetails.response = response;
                        callback(requestDetails);
                    },
                    function(response) {
                        requestDetails.response = response;
                        requestDetails.successful = false;
                        requestDetails.canceledRequest = response.status === -1;
                        callback(requestDetails);

                    }
                );
            }

            function createPaginationArray() {
                if ($scope.userList.content.length === 0) {
                    return;
                }
                $scope.pagination.paginationArray = [];

                if ($scope.userList.totalRows / $scope.filter.numShowEntriesSelected < 9) {
                    for (var i = 0; i < $scope.userList.totalRows / $scope.filter.numShowEntriesSelected; i++) {
                        $scope.pagination.paginationArray.push({
                            text: i + 1,
                            clickable: true,
                            isActive: false
                        })
                    }
                    $scope.pagination.paginationArray[$scope.pagination.paginationIndex].isActive = true;
                } else {
                    $scope.pagination.paginationArray.push({
                        text: 1,
                        clickable: true,
                        isActive: false
                    }, {
                        text: 2,
                        clickable: true,
                        isActive: false
                    }, {
                        text: 3,
                        clickable: true,
                        isActive: false
                    }, {
                        text: 4,
                        clickable: true,
                        isActive: false
                    }, {
                        text: "...",
                        clickable: false,
                        isActive: false
                    }, {
                        text: Math.ceil(+$scope.userList.totalRows / $scope.filter.numShowEntriesSelected) - 3,
                        clickable: true,
                        isActive: false
                    }, {
                        text: Math.ceil(+$scope.userList.totalRows / $scope.filter.numShowEntriesSelected) - 2,
                        clickable: true,
                        isActive: false
                    }, {
                        text: Math.ceil(+$scope.userList.totalRows / $scope.filter.numShowEntriesSelected) - 1,
                        clickable: true,
                        isActive: false
                    }, {
                        text: Math.ceil(+$scope.userList.totalRows / $scope.filter.numShowEntriesSelected),
                        clickable: true,
                        isActive: false
                    });

                    switch ($scope.pagination.paginationIndex) {
                        case 0:
                            $scope.pagination.paginationArray[0].isActive = true;
                            break;
                        case 1:
                            $scope.pagination.paginationArray[1].isActive = true;
                            break;
                        case 2:
                            $scope.pagination.paginationArray[2].isActive = true;
                            break;
                        case 3:
                            $scope.pagination.paginationArray[3].isActive = true;
                            break;
                        case Math.ceil(+$scope.userList.totalRows / $scope.filter.numShowEntriesSelected) - 4:
                            $scope.pagination.paginationArray[5].isActive = true;
                            break;
                        case Math.ceil(+$scope.userList.totalRows / $scope.filter.numShowEntriesSelected) - 3:
                            $scope.pagination.paginationArray[6].isActive = true;
                            break;
                        case Math.ceil(+$scope.userList.totalRows / $scope.filter.numShowEntriesSelected) - 2:
                            $scope.pagination.paginationArray[7].isActive = true;
                            break;
                        case Math.ceil(+$scope.userList.totalRows / $scope.filter.numShowEntriesSelected) - 1:
                            $scope.pagination.paginationArray[8].isActive = true;
                            break;
                        default:
                            $scope.pagination.paginationArray[4].isActive = true;
                    }
                }
            }

            function forwardToUserPage(userId) {
                $location.url("/user-details/" + userId);
            }

            function increasePaginationIndex() {
                if ($scope.pagination.paginationIndex < ($scope.userList.totalRows / $scope.filter.numShowEntriesSelected - 1)) {
                    $scope.pagination.paginationIndex++;
                    paginationManager.saveIndex($scope.pagination.paginationIndex);
                    $location.search('page', paginationManager.readIndex() + 1);
                    loadUserList(function(requestDetails) {
                        loadUserListCallback(requestDetails);
                    });
                }
            }

            function decreasePaginationIndex() {
                if ($scope.pagination.paginationIndex > 0) {
                    $scope.pagination.paginationIndex--;
                    paginationManager.saveIndex($scope.pagination.paginationIndex);
                    $location.search('page', paginationManager.readIndex() + 1);
                    loadUserList(function(requestDetails) {
                        loadUserListCallback(requestDetails);
                    });
                }
            }

            function applyFilterToList(property) {
                if (property !== undefined) {
                    if (property === 'searchTerm') {
                        if ($scope.filter.searchTerm !== undefined) {
                            paginationManager.paginationIndex = 0;
                            $scope.pagination.paginationIndex = 0;
                            $scope.requestParams['search'] = $scope.filter.searchTerm;
                            $scope.requestParams['page'] = 1;
                            $location.search($scope.requestParams);
                        }
                    } else {
                        if (property === $scope.filter.sortBy) {
                            $scope.filter.sortAscending = $scope.filter.sortAscending === 'DESC' ? 'ASC' : 'DESC';
                        } else {
                            if ($scope.filter.sortBy !== undefined) {
                                $scope.filter.sortAscending = 'ASC';
                            }
                            $scope.filter.sortBy = property;
                        }
                    }
                }

                if ($scope.filter.searchTerm === '') {
                    $scope.filter.searchTerm = undefined;
                }

                if ($scope.filterSettingId !== undefined && property !== 'searchTerm'){
                    var paramsObject = {
                        value1: property,
                        value2: $scope.filter.sortAscending === 'DESC' ? 'DESC' : 'ASC',
                        value3: null
                    };

                    requestManager.routes.userSettings.editUserSetting($rootScope.authData.sessionId, $scope.filterSettingId, paramsObject);
                }

                const showAll = $scope.filter.onlyCustomerVacantPos ? '0' : '1';
                if ($scope.userSettings.contentFiltered.showAllCustomers !== undefined
                    && $scope.userSettings.contentFiltered.showAllCustomers.value1 !== showAll) {
                    $scope.userSettings.contentFiltered.showAllCustomers.value1 = showAll;
                    $scope.userSettings.contentFiltered.showAllCustomers.edited = true
                }

                loadUserList(function(requestDetails) {
                    loadUserListCallback(requestDetails);
                });
            }

            function resetFilter() {
                $scope.filter.onlyProblematic = false;
                $scope.filter.onlyCustomerAssignedToMe = false;
                $scope.filter.onlyCustomerAssignedToExternalPartner = false;
                $scope.filter.hasPartsInvoice = false;
                $scope.filter.hasNewCarInvoice = false;
                $scope.filter.hasUsedCarInvoice = false;
                $scope.filter.searchTerm = undefined;
                $scope.filter.selectedAutoBrand = '20';
                $scope.filter.selectedAutoHouse = '100';

                loadUserList(function(requestDetails) {
                    loadUserListCallback(requestDetails);
                });
            }

            function changeNumShowEntries() {
                $scope.userSettings.contentFiltered.defaultNumRowsInList.value1 = '' + $scope.filter.numShowEntriesSelected;
                $scope.userSettings.contentFiltered.defaultNumRowsInList.edited = true
                loadUserList(function(requestDetails) {
                    loadUserListCallback(requestDetails);
                });
            }

            function selectAutoBrand() {
                loadUserList(function(requestDetails) {
                    loadUserListCallback(requestDetails);
                });
            }

            function selectAutoHouse() {
                loadUserList(function(requestDetails) {
                    loadUserListCallback(requestDetails);
                });
            }

            function goToPage(pageObject) {
                if (pageObject.clickable) {
                    $scope.pagination.paginationIndex = pageObject.text - 1;
                    paginationManager.saveIndex($scope.pagination.paginationIndex);
                    $location.search('page', paginationManager.readIndex() + 1);
                    loadUserList(function(requestDetails) {
                        loadUserListCallback(requestDetails);
                    });
                }
            }

            function getListSettings(settings) {
                var listSettings = {};
                settings.forEach(function(setting) {
                    setting.edited = false;
                    listSettings[setting.settingCode] = setting;
                });

                return listSettings;
            }

            function getUpdatedSettings(userSettings) {
                var updatedSettings = [];

                angular.forEach(userSettings, function(setting) {
                    if (setting.edited) {
                        updatedSettings.push(setting);
                    }
                });
                return updatedSettings;
            }

            function saveChangedSettings() {
                var updatedSettings = getUpdatedSettings($scope.userSettings.contentFiltered);
                sendEditSettingRequest($rootScope.authData.sessionId, updatedSettings, function (requestDetails){
                    if(requestDetails.allSuccessful){
                        Notification.success($filter("translate")("admonishedClientsList.SAVED_FILTER"));
                    }
                    else{
                        sharedMemory.error.setError(
                            requestDetails,
                            "COULD_NOT_LOAD_DATA",
                            "Fehler beim automatischen speichern der userSettings (speichern von Filter im Kundenstamm)",
                            $location.path(),
                            "admonishedClientsList"
                        );
                        $location.path("/error-page");
                    }
                });
            }

            function sendEditSettingRequest(sessionId, paramsArray, callback) {
                var requestDetails = {
                    numRequestsPlanned: paramsArray.length,
                    allSuccessful: true,
                    responses: [],
                    successfulResponses: [],
                    errorResponses: []
                };

                paramsArray.forEach(function(params) {
                    requestManager.routes.userSettings.editUserSetting(sessionId, params.id, params).then(
                        function(response) {
                            requestDetails.successfulResponses.push(response);
                            requestDetails.responses.push(response);
                            if (requestDetails.responses.length === requestDetails.numRequestsPlanned) {
                                callback(requestDetails);
                            }
                        },
                        function(response) {
                            requestDetails.successfulResponses.push(response);
                            requestDetails.responses.push(response);
                            requestDetails.allSuccessful = false;
                            if (requestDetails.responses.length === requestDetails.numRequestsPlanned) {
                                callback(requestDetails);
                            }
                        }
                    )
                });
            }

        }
    ]
});

angular.module('customers', []);

angular.module('customers').component('customers', {
    templateUrl: 'app/components/customers/customers.template.html',
    controller: [
        "$scope",
        "requestManager",
        "$rootScope",
        "$location",
        "util",
        "sharedMemory",
        "$filter",
        "Notification",
        function($scope, requestManager, $rootScope, $location, util, sharedMemory, $filter, Notification) {
            this.$onInit = function() {
                $scope.europeanNumberFormat = 'en-US';
                if ($rootScope.europeanNumberFormat !== undefined) {
                    if ($rootScope.europeanNumberFormat === '1' || $rootScope.europeanNumberFormat === 1) {
                        $scope.europeanNumberFormat = 'de-AT';
                    }
                    else {
                        $scope.europeanNumberFormat = 'en-US';
                    }
                }
                initVariables();
                initScope();

                $scope.requestParams = $location.search();
                if (Object.values($scope.requestParams).length > 0) {
                    if ($scope.requestParams.page) {
                        if (parseInt($scope.requestParams.page, 10)) {
                            $scope.filters.page = parseInt($scope.requestParams.page, 10);
                        }
                    }
                    if ($scope.requestParams.search) {
                        $scope.filters.searchTerm = $scope.requestParams.search;
                    }
                    if ($scope.requestParams.filter) {
                        const filters = $scope.requestParams.filter.split(' and ');
                        const index = filters.indexOf('accountsReceivableSum gt 0');
                        if (index > -1) {
                            filters.splice(index, 1);
                        }
                        // console.log(filters);
                        filters.forEach(filter => {
                            const parts = filter.split(' ');
                            const key = parts[0];
                            const op = parts[1];
                            const value = parts[2];
                            if (key && op && value && $scope[key]) {
                                // console.log(key, op, value, 'type = ' + $scope[key].type);
                                $scope[key].op = op === 'eq' ? 'equals' : op;
                                if ($scope[key].type === 'float') {
                                    if (!isNaN(parseFloat(value))) {
                                        $scope[key].value = parseFloat(value);
                                    }
                                } else if ($scope[key].type === 'number') {
                                    if (!isNaN(parseInt(value))) {
                                        $scope[key].value = parseInt(value);
                                    }
                                } else if ($scope[key].type === 'text-boolean') {
                                    // console.log(value);
                                    if (value === '1') {
                                        $scope[key].value = 1;
                                    }
                                    if (value === '0') {
                                        $scope[key].value = 0;
                                    }
                                } else if ($scope[key].type === 'date') {
                                    if (value) {
                                        $scope[key].value = new Date(value);
                                    }

                                } else {
                                    if (key === 'customerAdvisorId' && value) {
                                        $scope.filterCustomerAdvisorId = true;
                                        $scope.customerAdvisorId.value = value;
                                    }
                                    if (key === 'partnerId') {
                                        $scope.filterPartnerId = true;
                                        $scope.partnerId.value = value;
                                    }
                                    $scope[key].value = value;
                                }
                                $scope.filterArray.push(`${key} ${op} ${value}`);
                            } else {
                                if (key === 'isProblematic' && value === '1') {
                                    $scope.filterIsProblematic = true;
                                    $scope.filterArray.push(`${key} ${op} ${value}`);
                                }
                            }
                        });
                        $scope.filters.filter = $scope.requestParams.filter;
                    }
                }

                getUsers();
                v2getUserSettings(() => callAfterGettingUserSettings());
            };

            function initVariables() {
                $scope.customers = [];

                $scope.users = [];
                $scope.usersLoading = true;
                $scope.userSettings = [];
                $scope.userSettingsJson = {};

                $scope.paginator = {
                    items: [],
                    itemsPerPage: null,
                    itemsTotal: null,
                    pageCurrent: null,
                    pagesTotal: null,
                };
                $scope.possiblyFilterColumns = [
                    'accountNumber',
                    'legalEntityType',
                    'name',
                    'address',
                    'zip',
                    'city',
                    'country',
                    'email',
                    'accountsReceivableOverdueSum',
                    'accountsReceivableSum',
                    'oldestInvoice',
                    'lastAction',
                    'isAtCustomerAdvisor',
                    'customerAdvisorName',
                    'partnerName',
                    'businessPartnerNumber',
                    'automaticDunning',
                    'amountEmails',
                    'amountPhysicalMails',
                    'sumAmountEmailsAmountPhysicalMails',
                ];
                $scope.totalDisplayedColumns = 1;
                $scope.possiblyColumnsUserSettings = [
                    'showCustomerNumberInCustomerList',
                    'showCustomerNameInCustomerList',
                    'showCustomerStreetInCustomerList',
                    'showCustomerZipInCustomerList',
                    'showCustomerCityInCustomerList',
                    'showCustomerCountryInCustomerList',
                    'showCustomerEmailInCustomerList',
                    'showCustomerVacantPosInCustomerList',
                    'showCustomerVacantPosWithoutOverdueInCustomerList',
                    'showCustomerOldestInvoiceInCustomerList',
                    'showCustomerLastActionInCustomerList',
                    'showCustomerAdvisorInCustomerList',
                    'showExternalPartnerNameInCustomerList',
                    'showCustomerBusinessPartnerNrInCustomerList',
                    'showCustomerAutomaticDunningInCustomerList',
                    'showAmountDunningsApprovedEmailInCustomerList',
                    'showAmountDunningsApprovedPhysicalMailInCustomerList',
                    'showCustomerAmountDunningsInCustomerList'
                ];
                $scope.allColumnsHidden = false;

                $scope.menuOpened = true;
                toggleFilterOptions();

                $scope.filters = {
                    page: 1,
                    pageSize: 10,
                    order: "accountNumber",
                    direction: "asc",
                    searchTerm: null,
                    filter: null,
                };
                $scope.filterArray= [];
                $scope.filterAccountsReceivableSum = false;
                $scope.filterIsProblematic = false;
                $scope.filterCustomerAdvisorId = false;
                $scope.filterIsAtCustomerAdvisor = false;
                $scope.filterPartnerId = false;

                // ! same names as the property names in the server response
                $scope.accountsReceivableOverdueSum = {
                    op: 'ge',
                    value: null,
                    type: 'float',
                    possibleOps: ['lt', 'le', 'gt', 'ge', 'equals', 'ne', 'like'],
                };
                $scope.accountsReceivableSum = {
                    op: 'ge',
                    value: null,
                    type: 'float',
                    possibleOps: ['lt', 'le', 'gt', 'ge', 'equals', 'ne', 'like'],
                };
                $scope.oldestInvoice = {
                    op: 'le',
                    value: null,
                    type: 'date',
                    possibleOps: ['le', 'ge', 'like'],
                };
                $scope.lastAction = {
                    op: 'le',
                    value: null,
                    type: 'date',
                    possibleOps: ['le', 'ge', 'like'],
                };
                $scope.isAtCustomerAdvisor = {
                    op: 'equals',
                    value: null,
                    type: 'text-boolean',
                    possibleOps: ['equals'],
                    possibleValues: [{text: '', val: null}, {text: 'ja', val: 1}, {text: 'nein', val: false}],
                };
                $scope.customerAdvisorId = {
                    op: 'equals',
                    value: null,
                    possibleOps: ['equals'],
                };
                $scope.partnerId = {
                    op: 'equals',
                    value: null,
                    possibleOps: ['equals'],
                };
                $scope.businessPartnerNumber = {
                    op: 'equals',
                    value: null,
                    possibleOps: ['equals', 'ne', 'like'],
                };
                $scope.automaticDunning = {
                    op: 'equals',
                    value: null,
                    type: 'text-boolean',
                    possibleOps: ['equals'],
                    possibleValues: [{text: '', val: null}, {text: 'ja', val: 1}, {text: 'nein', val: 0}],
                };
                $scope.amountEmails = {
                    op: 'ge',
                    value: null,
                    type: 'number',
                    possibleOps: ['lt', 'le', 'gt', 'ge', 'equals', 'ne', 'like'],
                };
                $scope.amountPhysicalMails = {
                    op: 'ge',
                    value: null,
                    type: 'number',
                    possibleOps: ['lt', 'le', 'gt', 'ge', 'equals', 'ne', 'like'],
                };
                $scope.sumAmountEmailsAmountPhysicalMails = {
                    op: 'ge',
                    value: null,
                    type: 'number',
                    possibleOps: ['lt', 'le', 'gt', 'ge', 'equals', 'ne', 'like'],
                };
                $scope.numShowEntriesOptions = [10, 20];
                $scope.numShowEntriesSelected = undefined;

                $scope.loading = true;
                $scope.exportFileLoading = false;
            }

            function initScope() {
                $scope.changeNumShowEntries = changeNumShowEntries;
                $scope.downloadExcelFile = downloadExcelFile;
                $scope.showColumn = showColumn;
                $scope.onSearch = onSearch;
                $scope.toggleMenuOpened = toggleMenuOpened;
                $scope.toggleFilterOptions = toggleFilterOptions;
                $scope.filter1 = filter1;
                $scope.filter2 = filter2;
                $scope.filter3 = filter3;
                $scope.filter4 = filter4;
                $scope.filter5 = filter5;
                $scope.changeOp = changeOp;
                $scope.changeValue = changeValue;
                $scope.sortBy = sortBy;
                $scope.firstPage = firstPage;
                $scope.lastPage = lastPage;
                $scope.nextPage = nextPage;
                $scope.previousPage = previousPage;
                $scope.resetFilter = resetFilter;
                $scope.forwardToUserPage = forwardToUserPage;
                $scope.$on('$routeChangeStart', function() {
                    requestManager.stopPendingRequests();
                });
            }

            /* ---------- http requests ---------- */

            function getUsers() {
                requestManager.routes.user.getAllUser($rootScope.authData.sessionId).then(
                    function (response) {
                        $scope.users = response.data.filter(user => user.id !== '0');
                        $scope.usersLoading = false;
                    },
                    function () {
                    }
                )
            }

            function v2getUserSettings(callback) {
                $scope.userSettingsLoading = true;
                requestManager.routes.user.getSettingsOfUser($rootScope.authData.sessionId, $rootScope.authData.userId).then(
                    function(response){
                        $scope.userSettings = response.data;
                        $scope.userSettingsJson = getUserSettingsJson($scope.userSettings);
                        $scope.totalDisplayedColumns = checkAllDisplayedColumns();
                        // apply the necessary user settings
                        if ($scope.userSettingsJson.defaultNumRowsInList) {
                            if ($scope.userSettingsJson.defaultNumRowsInList.value1 !== '10' && $scope.userSettingsJson.defaultNumRowsInList.value1 !== '20') {
                                updateUserSetting($scope.userSettingsJson.defaultNumRowsInList.id, {value1: '10'});
                                $scope.userSettingsJson.defaultNumRowsInList.value1 = '10';
                            }
                            $scope.filters.pageSize = +$scope.userSettingsJson.defaultNumRowsInList.value1;
                        }
                        if ($scope.userSettingsJson.showAllCustomers) {
                            if ($scope.userSettingsJson.showAllCustomers.value1 !== '1') {
                                $scope.filterAccountsReceivableSum = true;
                                $scope.filterArray.push('accountsReceivableSum gt 0');
                            } else {
                                $scope.filterAccountsReceivableSum = false;
                            }
                        }
                        if ($scope.userSettingsJson.filterChoice) {
                            if ($scope.userSettingsJson.filterChoice.value1) {
                                if ($scope.possiblyFilterColumns.includes($scope.userSettingsJson.filterChoice.value1)) {
                                    $scope.filters.order = $scope.userSettingsJson.filterChoice.value1;
                                } else {
                                    $scope.filters.order = 'accountNumber';
                                    updateUserSetting($scope.userSettingsJson.filterChoice.id, {value1: 'accountNumber'});
                                }
                            }
                            if ($scope.userSettingsJson.filterChoice.value2) {
                                $scope.filters.direction = $scope.userSettingsJson.filterChoice.value2.toLowerCase();
                            } else {
                                $scope.filters.direction = 'asc';
                            }
                        }
                        $scope.userSettingsLoading = false;
                        callback();
                    },
                    function(){
                    }
                )

                function getUserSettingsJson(settings) {
                    const listSettings = {};
                    settings.forEach(function(setting) {
                        setting.edited = false;
                        listSettings[setting.settingCode] = setting;
                    });
                    return listSettings;
                }
            }

            function callAfterGettingUserSettings() {
                getCustomers();
                checkIfAllColumnsAreHidden();
            }

            function getCustomers() {
                $scope.loading = true;
                // checkbox onlyCustomerVacantPos
                if ($scope.filterAccountsReceivableSum) {
                    if (!$scope.filterArray.includes('accountsReceivableSum gt 0')) {
                        $scope.filterArray.push('accountsReceivableSum gt 0');
                    }
                } else {
                    removeElementFromArray('accountsReceivableSum gt 0');
                }
                // apply the filter array
                $scope.filters.filter = $scope.filterArray.join(' and ');
                appendRequestParam('filter', $scope.filters.filter ? $scope.filters.filter : null);
                requestManager.routes.customers.getCustomers($rootScope.authData.sessionId, $scope.filters).then(
                    function (response) {
                        $scope.paginator = response.data;
                        $scope.customers = $scope.paginator.items;
                        // console.log($scope.paginator);
                        // console.log($scope.customers);
                        $scope.loading = false;
                    },
                    function (response) {
                        if (response.data && response.data.error === 500 && response.data.internalMessage.includes('Unknown column')) {
                            Notification.error({
                                message: 'Der Filter kann nicht angewendet werden', delay: null, closeOnClick: true,
                            });
                            $scope.loading = false;
                        }
                    }
                );
            }

            function updateUserSetting(id, values) {
                if (!id || !values) {
                    return;
                }
                const params = {
                    value1: values.value1,
                    value2: values.value2,
                    value3: values.value3
                };
                requestManager.routes.userSettings.editUserSetting($rootScope.authData.sessionId, id, params).then(
                    function () {
                    },
                    function () {
                    }
                );
            }

            /* ---------- top bar ---------- */

            function changeNumShowEntries() {
                $scope.filters.page = 1;
                getCustomers();
                updateUserSetting($scope.userSettingsJson.defaultNumRowsInList.id, {value1: $scope.filters.pageSize});
            }

            function downloadExcelFile() {
                $scope.exportFileLoading = true;
                requestManager.routes.customer.getCustomerExcelFile($rootScope.authData.sessionId).then(
                    function(response){
                        $scope.exportFileLoading = false;
                        let blob = new Blob([response.data], { type: 'application/octet-stream' });
                        saveFile(blob, 'Debitorcloud_Kundenuebersicht_Export.xlsx');
                    },
                    function(){
                        $scope.exportFileLoading = false;
                        Notification.warning({
                            message: 'Die Dateierstellung ist fehlgeschlagen',
                            delay: null,
                            closeOnClick: true,
                        });
                    }
                )
            }

            function saveFile(blob, fileName) {
                const downloadLink = document.createElement('a');
                downloadLink.href = window.URL.createObjectURL(blob);
                downloadLink.download = fileName;

                document.body.appendChild(downloadLink);
                downloadLink.click();
                document.body.removeChild(downloadLink);
            }

            function showColumn(value) {
                updateUserSetting($scope.userSettingsJson[value].id, {value1: $scope.userSettingsJson[value].value1});
                if ($scope.userSettingsJson[value].value1 === '1') {
                    $scope.totalDisplayedColumns++;
                } else {
                    $scope.totalDisplayedColumns--;
                }
                checkIfAllColumnsAreHidden();
            }

            /* ---------- first row ---------- */

            function onSearch() {
                $scope.filters.page = 1;
                appendRequestParam('page', 1);
                appendRequestParam('search', $scope.filters.searchTerm);
                getCustomers();
            }

            function toggleMenuOpened() {
                $scope.menuOpened = !$scope.menuOpened;
                $scope.toggleFilterOptions();
            }

            function toggleFilterOptions(){
                if($scope.menuOpened){
                    jQuery("#filterOptions").show(500);
                    jQuery("#filterOnlyCustomerVacantPos").hide(250);
                }
                else{
                    jQuery("#filterOptions").hide(500);
                    jQuery("#filterOnlyCustomerVacantPos").show(750);
                }
                toggleColumnFilterOptions();
            }

            function toggleColumnFilterOptions() {
                $scope.possiblyFilterColumns.forEach(column => {
                    if ($scope.menuOpened) {
                        jQuery("#" + column).show(500);
                    } else {
                        jQuery("#" + column).hide(500);
                    }
                });
            }


            /* ---------- filters ---------- */

            function filter1() {
                $scope.filters.page = 1;
                appendRequestParam('page', 1);
                getCustomers();
                updateUserSetting($scope.userSettingsJson.showAllCustomers.id, {value1: $scope.filterAccountsReceivableSum ? '0' : '1'});
            }

            function filter2() {
                $scope.filters.page = 1;
                appendRequestParam('page', 1);
                if ($scope.filterIsProblematic) {
                    $scope.filterArray.push('isProblematic eq 1');
                } else {
                    removeElementFromArray('isProblematic eq 1');
                }
                getCustomers();
            }

            function filter3() {
                $scope.filters.page = 1;
                appendRequestParam('page', 1);
                const myId = $rootScope.authData.userId;
                if ($scope.filterCustomerAdvisorId) {
                    $scope.filterArray.push(`customerAdvisorId eq ${myId}`);
                    $scope.customerAdvisorId.value = myId;
                } else {
                    removeElementFromArray(`customerAdvisorId eq ${myId}`);
                    $scope.customerAdvisorId.value = null;
                }
                getCustomers();
            }

            function filter4() {
                $scope.filters.page = 1;
                appendRequestParam('page', 1);
                if ($scope.filterIsAtCustomerAdvisor) {
                    $scope.filterArray.push(`isAtCustomerAdvisor eq 1`);
                    $scope.isAtCustomerAdvisor.value = 1;
                } else {
                    removeElementFromArray(`isAtCustomerAdvisor eq 1`);
                    $scope.isAtCustomerAdvisor.value = null;
                }
                getCustomers();
            }

            function filter5() {
                $scope.filters.page = 1;
                appendRequestParam('page', 1);
                const myId = $rootScope.authData.userId;
                if ($scope.filterPartnerId) {
                    $scope.filterArray.push(`partnerId eq ${myId}`);
                    $scope.partnerId.value = myId;
                } else {
                    removeElementFromArray(`partnerId eq ${myId}`);
                    $scope.partnerId.value = null;
                }
                getCustomers();

            }

            function removeElementFromArray(element) {
                const index = $scope.filterArray.indexOf(element);
                if (index > -1) {
                    $scope.filterArray.splice(index, 1);
                }
            }

            function changeOp(ev, filter, value) {
                ev.preventDefault();
                if ($scope[filter].op !== value) {
                    $scope[filter].op = value;
                    if ($scope[filter].value || $scope[filter].value === 0) {
                        changeValue(filter);
                    }
                }
            }

            function changeValue(filter) {
                // console.log(filter);
                // console.log($scope.filters.filter);

                // handle values
                let value = $scope[filter].value;
                if (filter === 'lastAction' || filter === 'oldestInvoice') {
                    if (value !== null) {
                        if (!isValidDate(value)) {
                            return;
                        }
                        const date = $scope[filter].value;
                        value = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();
                    }
                }
                if (filter === 'customerAdvisorId') {
                    $scope.filterCustomerAdvisorId = !!(value && value === $rootScope.authData.userId);
                }
                if (filter === 'partnerId') {
                    $scope.filterPartnerId = !!(value && value === $rootScope.authData.userId);
                }
                if (filter === 'isAtCustomerAdvisor') {
                    $scope.filterIsAtCustomerAdvisor = !!value;
                }

                // handle operators
                let operator = $scope[filter].op;
                if (operator === 'equals') {
                    operator = 'eq';
                }

                if (value || value === 0) {
                    $scope.filterArray = $scope.filterArray.filter(val => !val.startsWith(filter));
                    $scope.filterArray.push(`${filter} ${operator} ${value}`);
                } else {
                    $scope.filterArray = $scope.filterArray.filter(val => !val.startsWith(filter));
                    if (filter === 'automaticDunning' && value !== null) {
                        $scope.filterArray.push(`${filter} ${operator} ${value}`);
                    }
                }
                getCustomers();
            }

            function isValidDate(dateString) {
                let date = new Date(dateString);
                return !isNaN(date.getTime());
            }

            function sortBy(value) {
                if (value === $scope.filters.order) {
                    $scope.filters.direction = $scope.filters.direction === 'desc' ? 'asc' : 'desc';
                } else {
                    $scope.filters.order = value;
                    $scope.filters.direction = 'asc';
                }
                getCustomers();
                updateUserSetting($scope.userSettingsJson.filterChoice.id, {value1: $scope.filters.order, value2: $scope.filters.direction});
            }

            function checkAllDisplayedColumns() {
                let count = 0;
                for (const column of $scope.possiblyColumnsUserSettings) {
                    if ($scope.userSettingsJson[column].value1 === '1') {
                        count++;
                    }
                }
                return count;
            }

            function checkIfAllColumnsAreHidden() {
                for (const column of $scope.possiblyColumnsUserSettings) {
                    if ($scope.userSettingsJson[column].value1 === '1') {
                        $scope.allColumnsHidden = false;
                        return;
                    }
                }
                $scope.allColumnsHidden = true;
            }

            function firstPage() {
                $scope.filters.page = 1;
                appendRequestParam('page', $scope.filters.page);
                getCustomers();
            }

            function lastPage() {
                $scope.filters.page = $scope.paginator.pagesTotal;
                appendRequestParam('page', $scope.filters.page);
                getCustomers();
            }

            function nextPage() {
                $scope.filters.page++;
                appendRequestParam('page', $scope.filters.page);
                getCustomers();
            }

            function previousPage() {
                $scope.filters.page--;
                appendRequestParam('page', $scope.filters.page);
                getCustomers();
            }

            function resetFilter() {
                $scope.filters.page = 1;
                $scope.filters.searchTerm = null;
                $scope.filters.filter = null;
                $scope.filterArray = [];
                $scope.oldestInvoice.value = null;
                $scope.accountsReceivableOverdueSum.value = null;
                $scope.accountsReceivableSum.value = null;
                $scope.lastAction.value = null;
                $scope.customerAdvisorId.value = null;
                $scope.partnerId.value = null;
                $scope.sumAmountEmailsAmountPhysicalMails.value = null;
                $scope.businessPartnerNumber.value = null;
                $scope.automaticDunning.value = null;
                $scope.amountEmails.value = null;
                $scope.amountPhysicalMails.value = null;
                $scope.filterIsProblematic = false;
                $scope.filterCustomerAdvisorId = false;
                $scope.filterIsAtCustomerAdvisor = false;
                $scope.filterPartnerId = false;
                getCustomers();
            }

            function forwardToUserPage(customerId) {
                $location.url("/user-details/" + customerId);
            }

            function appendRequestParam(param, value) {
                $location.search(param, value);
            }

        }
    ]
});

angular.module('emailCenterList', []);
angular.module('emailCenterList').component('emailCenterList', {
    templateUrl: 'app/components/emailCenterList/emailCenterList.template.html',
    controller: [
        "$scope",
        "$location",

        function ($scope, $location) {
            this.$onInit = function () {
                initScope();
            };

            function initScope() {
                $scope.forwardTo = forwardTo;
            }

            function forwardTo(path) {
                $scope.location = $location.path(path);
            }
        }
    ]
}); 

angular.module('errorPanel', []);
angular.module('errorPanel').component('errorPanel', { 
	templateUrl: 'app/components/errorPanel/errorPanel.template.html', 
	controller: [
		"sharedMemory",
		"$scope",
		"requestManager",
		"$window",
		"Mailto",
		"$location",
		"$filter",
		"$sanitize",
		function (sharedMemory, $scope, requestManager, $window, Mailto, $location, $filter, $sanitize) {
			this.$onInit = function(){
				$scope.errorDetails =  sharedMemory.error.errorDetails;
				$scope.supportEmail = requestManager.supportEmailAddress;

				if(!sharedMemory.error.isSet){
					$location.path("/dashboard");
				}

			};

			$scope.sendEmail = function(){
				var json = JSON.stringify($scope.errorDetails);

				if(json.length > 1500){
					var errorDetailsWithoutErrorSource = angular.copy($scope.errorDetails);
					delete errorDetailsWithoutErrorSource.errorSource;
                    json = JSON.stringify(errorDetailsWithoutErrorSource);
				}

				var options = {
					subject: "Debitorcloud - automatically generated error message",
					body: "---Automatisch generiere Supportemail--- \n\n" +
					"Liebe Kundin/lieber Kunde, \n\n" +
					"Falls sie noch Anmerkungen hinzufügen möchten können Sie das hier machen: \n\n\n" +
					"---------------------------------------------------------------------------------------------------------------------------\n " +
					"Bitte ändern Sie diesen Bereich der E-Mail nicht ab, damit wir eine möglichst rasche Analyse des Fehlers durchführen können. \n\n" +
					json
				};

				$window.location.href = Mailto.url(requestManager.supportEmailAddress, options);
			};
		} 
	] 
}); 

angular.module('userDetailsActions', []);
angular.module('userDetailsActions').component('userDetailsActions', {
    templateUrl: 'app/components/userDetailsActions/userDetailsActions.template.html',
    controller: [
        "$scope",
        "$routeParams",
        "requestManager",
        "$rootScope",
        "$route",
        "Notification",
        "$filter",
        "sharedMemory",
        "$window",
        "$location",
        "$sce",
        "util",
        function ($scope, $routeParams, requestManager, $rootScope, $route, Notification, $filter, sharedMemory, $window, $location, $sce, util) {
            this.$onInit = function () {
                $scope.europeanNumberFormat = 'en-US';
                if ($rootScope.europeanNumberFormat !== undefined) {
                    if ($rootScope.europeanNumberFormat === '1' || $rootScope.europeanNumberFormat === 1) {
                        $scope.europeanNumberFormat = 'de-AT';
                    } else {
                        $scope.europeanNumberFormat = 'en-US';
                    }
                }
                initVariables();
                initScope();
                loadCustomerData($rootScope.authData.sessionId, $routeParams.id, function (requestDetails) {
                    if (requestDetails.successful) {
                        $scope.customerData.content = requestDetails.response.data[0];
                        $scope.customerData.currentlyLoading = false;
                        /*
                        deposit default e-mail
                         */
                        if ($scope.customerData.content.email) {
                            $scope.defaultEMail = {email: $scope.customerData.content.email};
                        }
                        $scope.emailModal.attachReport = $scope.customerData.content.getsDunningNote == '1';
                        $scope.emailModal.attachOpList = $scope.customerData.content.getsOPList == '1';
                        $scope.emailModal.attachOPopenList = $scope.customerData.content.getsOPopenList == '1';
                    } else {
                        sharedMemory.error.setError(
                            requestDetails,
                            "COULD_NOT_LOAD_DATA",
                            "Fehler beim laden von Kundendaten (beim initiieren der Komponente)",
                            $location.path(),
                            "userDetailsAction"
                        );
                        $location.path("/error-page");
                    }
                });
                loadEmailTemplates($rootScope.authData.sessionId, function (requestDetails) {
                    if (requestDetails.successful) {
                        $scope.emailModal.emailTemplates.content = requestDetails.content;
                        $scope.emailModal.emailTemplates.currentlyLoading = false;

                    } else {
                        sharedMemory.error.setError(
                            requestDetails,
                            "COULD_NOT_LOAD_DATA",
                            "Fehler beim laden der Email-Templates (beim initiieren der Komponente)",
                            $location.path(),
                            "userDetailsAction"
                        );
                        $location.path("/error-page");
                    }
                });
                loadUserId($rootScope.authData.sessionId, function (requestDetails) {
                    if (requestDetails.successful) {
                        $scope.userId = requestDetails.response.data;
                    } else {
                        sharedMemory.error.setError(
                            requestDetails,
                            "COULD_NOT_LOAD_DATA",
                            "Fehler beim laden der UserId (beim initiieren der Komponente)",
                            $location.path(),
                            "userDetailsAction"
                        );
                        $location.path("/error-page");
                    }
                });
                getVacantPosOfUser($rootScope.authData.sessionId, $routeParams.id, function (requestDetails) {
                    if (requestDetails.successful) {
                        $scope.vacantPosOfCustomer.currentlyLoading = false;
                        $scope.vacantPosOfCustomer.content = requestDetails.response.data;
                        $scope.vacantPosOfCustomer.content.allInvoices = addPropertiesToVacantPosList($scope.vacantPosOfCustomer.content.invoices);
                        $scope.handOverToLawyerModal.vacantPosOfCustomer = angular.copy($scope.vacantPosOfCustomer.content.allInvoices);
                        $scope.vacantPosOfCustomer.content.invoices = getInvoicesNotInPaymentAgreement($scope.vacantPosOfCustomer.content.allInvoices);
                    } else {
                        sharedMemory.error.setError(
                            requestDetails,
                            "COULD_NOT_LOAD_DATA",
                            "Fehler beim laden der offenen Posten vom Kunden  (beim initiieren der Komponente)",
                            $location.path(),
                            "userDetailsAction"
                        );
                        $location.path("/error-page");
                    }
                });
                loadUserList($rootScope.authData.sessionId, function (requestDetails) {
                    if (requestDetails.successful) {
                        $scope.userList.content = requestDetails.response.data.filter((user) => {
                            return user.id !== '0' && user.disabled !== '1'
                        });
                        $scope.userList.currentlyLoading = false;
                    } else {
                        sharedMemory.error.setError(
                            requestDetails,
                            "COULD_NOT_LOAD_DATA",
                            "Fehler beim laden der Userliste (beim initiieren der Komponente)",
                            $location.path(),
                            "userDetailsAction"
                        );
                        $location.path("/error-page");
                    }
                });

                loadSettings($rootScope.authData.sessionId, function (requestDetails) {

                    if (requestDetails.successful) {

                        $scope.settings.content = requestDetails.response.data;
                        $scope.settings.currentlyLoading = false;
                        $scope.settings.companyCountry = util.getSettingByName($scope.settings.content, "companyCountry");
                        if ($scope.settings.content !== undefined) {
                            $scope.settings.companyLogo = $scope.settings.content.find(setting => {
                                return setting.settingCode === "companyLogo";
                            });
                        }
                        $scope.settings.paymentAgreementInterestRate = $scope.settings.content.find(setting => {
                            return setting.settingCode === "paymentAgreementInterestRate"
                        });

                        $scope.view.showSendPhysicalMailButtons = $scope.settings.companyCountry.settingValue1 == "AT";

                    } else {
                        sharedMemory.error.setError(
                            requestDetails,
                            "COULD_NOT_LOAD_DATA",
                            "Fehler beim Settings (beim initiieren der Komponente)",
                            $location.path(),
                            "userDetailsAction"
                        );
                        $location.path("/error-page");
                    }
                });

                loadCustomerMailCommunications($rootScope.authData.sessionId, $routeParams.id, function (requestDetails) {
                    if (requestDetails.successful) {
                        $scope.vacantPosOfCustomer.currentlyLoading = false;
                        requestDetails.content.forEach((mail) => {
                            if (mail.isActiveForDunning == 1) {
                                $scope.emailRecipientTags.push({email: mail.content});
                            }
                        });
                        /*
                        if no e-mails are activated, take default customer e-mail
                         */
                        if ($scope.emailRecipientTags
                            && $scope.emailRecipientTags.length === 0
                            && $scope.defaultEMail) {
                            $scope.emailRecipientTags.push($scope.defaultEMail)
                        }
                    } else {
                        sharedMemory.error.setError(
                            requestDetails,
                            "COULD_NOT_LOAD_DATA",
                            "Fehler beim Laden der Kundenkommunikation vom Kunden  (beim initiieren der Komponente)",
                            $location.path(),
                            "userDetailsAction"
                        );
                        $location.path("/error-page");
                    }
                });
            };

            $scope.$watch("createReminderModal.selectedDate", function (newValue, oldValue) {
                if (newValue != oldValue && angular.isDefined($scope.createReminderModal.selectedDate)) {
                    $scope.createReminderModal.isDateSelected = true;
                }
            });

            $scope.$watchGroup(["customerData.currentlyLoading", "userList.currentlyLoading"], function () {
                if (!$scope.customerData.currentlyLoading && !$scope.userList.currentlyLoading) {
                    $scope.informCustomerAdvisorModal.currentlyLoading = false;
                    if (angular.isDefined($scope.customerData.content.customerAdvisor)) {
                        var index = $scope.userList.content.findIndex(function (user) {
                            return $scope.customerData.content.customerAdvisor == user.id
                        });
                        $scope.informCustomerAdvisorModal.selectedCustomerAdvisor = $scope.userList.content[index];
                    }
                }
            });

            function initVariables() {
                $scope.customerId = $routeParams.id;
                $scope.userId = undefined;
                $scope.createRecord = {
                    createdRecordId: undefined,
                    recordType: undefined
                };
                $scope.telephoneCallNoteModal = {
                    status: "1",
                    text: undefined,
                    number: undefined,
                    createRecordPhoneId: undefined
                };
                $scope.commentaryModal = {
                    createdRecordCommentaryId: undefined
                };
                $scope.customerData = {
                    currentlyLoading: true,
                    content: {}
                };
                $scope.emailModal = {
                    content: undefined,
                    subject: undefined,
                    emailTemplates: {
                        content: undefined,
                        currentlyLoading: true
                    },
                    selectedTemplate: undefined,
                    attachReport: undefined,
                    attachOpList: undefined,
                    attachOpOpenList: undefined,
                    buttonsDisabled: false,
                    addsToDunningLevel: false,
                    currentlyProcessing: false,
                    fileLines: [1, 0, 1, 1],
                    fileUploads: []
                };
                $scope.markProblematic = {
                    currentlyLoading: true
                };
                $scope.vacantPosOfCustomer = {
                    currentlyLoading: true,
                    content: [],
                    isEmpty: false
                };
                $scope.paymentAgreementModal = {
                    paymentPeriod: undefined,
                    paymentPeriodInMonths: undefined,
                    saveButtonDisabled: true,
                    calculationMethod: undefined,
                    amountInterestOfSelectedInvoices: -1,
                    calculatedPayments: undefined,
                    calculationCreated: false,
                    paymentTermId: undefined,
                    numPaymentAppointmentsToCreate: undefined,
                    numPaymentAppointmentsCreated: undefined,
                    currentlyLoading: false
                };
                $scope.createReminderModal = {
                    currentlyLoading: false,
                    selectedDate: undefined,
                    selectedTime: new Date(2000, 1, 1, 12, 0),
                    isDateSelected: false,
                    notificationText: null,
                };
                $scope.userList = {
                    currentlyLoading: true,
                    content: undefined
                };
                $scope.informCustomerAdvisorModal = {
                    currentlyLoading: true,
                    selectedCustomerAdvisor: undefined,
                    emailContent: undefined,
                    emailSubject: undefined
                };
                $scope.handOverToLawyerModal = {
                    vacantPosOfCustomer: undefined
                };
                $scope.emailRecipientTags = [];
                $scope.defaultEMail = undefined;
                $scope.settings = {
                    content: undefined,
                    currentlyLoading: true
                };
                $scope.sendPhysicalMailModal = {
                    currentlyLoading: false,
                    sendEnrolled: false
                }
                $scope.view = {
                    showSendPhysicalMailButtons: false
                }
            }

            function initScope() {
                $scope.sendEmailToCustomerAdvisor = sendEmailToCustomerAdvisor;
                $scope.toggleMarkProblematic = toggleMarkProblematic;
                $scope.openModalWithId = openModalWithId;
                $scope.openModalCustomerAdvisor = openModalCustomerAdvisor;
                $scope.onCreateNewCustomerAdvisorCase = onCreateNewCustomerAdvisorCase;
                $scope.saveTelephoneCallNote = saveTelephoneCallNote;
                $scope.saveComment = saveComment;
                $scope.sendEmail = sendEmail;
                $scope.togglePaymentMethod = togglePaymentMethod;
                $scope.toggleAppointmentBox = toggleAppointmentBox;
                $scope.toggleCalculationMethodField = toggleCalculationMethodField;
                $scope.createCalculation = createCalculation;
                $scope.calculateAmountInterestOfCheckedInvoices = calculateAmountInterestOfCheckedInvoices;
                $scope.checkAllInvoices = checkAllInvoices;
                $scope.saveReminder = saveReminder;
                $scope.onEmailTemplateChanged = onEmailTemplateChanged;
                $scope.createReport = createReport;
                $scope.checkAllInvoicesForReport = checkAllInvoicesForReport;
                $scope.loadCustomerMailCommunications = loadCustomerMailCommunications;
                $scope.addFileLine = addFileLine;
                $scope.deleteFileLine = deleteFileLine;
                $scope.getAttachments = getAttachments;
                $scope.getTotalMbSize = getTotalMbSize;
            }

            function loadCustomerMailCommunications(sessionId, customerId, callback) {
                var requestDetails = {
                    successful: true,
                    content: undefined,
                    request: undefined,
                    loadedFromCache: undefined
                };

                requestManager.routes.customer.getCustomerMailCommunications(sessionId, customerId).then(
                    function (response) {
                        requestDetails.content = response.data;
                        requestDetails.loadedFromCache = false;
                        requestDetails.request = response;
                        callback(requestDetails);
                    },
                    function (response) {
                        requestDetails.content = response.data;
                        requestDetails.loadedFromCache = false;
                        requestDetails.request = response;
                        requestDetails.successful = false;
                        callback(requestDetails);
                    }
                )
            }

            function loadEmailTemplates(sessionId, callback) {
                var requestDetails = {
                    successful: true,
                    content: undefined,
                    request: undefined,
                    loadedFromCache: undefined
                };

                if (sharedMemory.emailTemplates.isSet) {
                    requestDetails.content = sharedMemory.emailTemplates.getEmailTemplates();
                    requestDetails.loadedFromCache = true;
                    callback(requestDetails);
                    return;
                }

                requestManager.routes.emailTemplates.getEmailTemplates(sessionId).then(
                    function (response) {
                        requestDetails.content = response.data;
                        requestDetails.loadedFromCache = false;
                        requestDetails.request = response;
                        sharedMemory.emailTemplates.setEmailTemplates(response.data);
                        callback(requestDetails);
                    },
                    function (response) {
                        requestDetails.content = response.data;
                        requestDetails.loadedFromCache = false;
                        requestDetails.request = response;
                        requestDetails.successful = false;
                        callback(requestDetails);
                    }
                )
            }

            function loadSettings(sessionId, callback) {
                var requestDetails = {
                    successful: true,
                    response: undefined
                };

                requestManager.routes.settings.getSettings(sessionId).then(
                    function (response) {
                        requestDetails.response = response;
                        callback(requestDetails);
                    },
                    function (response) {
                        requestDetails.response = response;
                        requestDetails.successful = false;
                        callback(requestDetails);
                    }
                )
            }

            function loadCustomerData(sessionId, customerId, callback) {
                var requestDetails = {
                    successful: true,
                    response: undefined
                };

                requestManager.routes.customer.getCustomerWithId(sessionId, customerId).then(
                    function (response) {
                        requestDetails.response = response;
                        callback(requestDetails);
                    },
                    function (response) {
                        requestDetails.response = response;
                        requestDetails.successful = false;
                        callback(requestDetails);
                    }
                )
            }

            function addPropertiesToVacantPosList(list) {
                angular.forEach(list, function (invoice) {
                    invoice.checked = false;
                });
                return list;
            }

            function getVacantPosOfUser(sessionId, customerId, callback) {
                var requestDetails = {
                    successful: true,
                    response: undefined
                };

                requestManager.routes.customer.virtual.getAccountsReceivableSummaryOfCustomer(sessionId, customerId).then(
                    function (response) {
                        requestDetails.response = response;
                        callback(requestDetails);
                    },
                    function (response) {
                        requestDetails.response = response;
                        requestDetails.successful = false;
                        callback(requestDetails);
                    }
                )
            }

            function createCalculationWithAmountPerMonth() {
                var amountToPay = angular.copy($scope.paymentAgreementModal.amountInterestOfSelectedInvoices);
                var amountPerMonth = angular.copy($scope.paymentAgreementModal.amountPerMonth);

                createCalculatedPayments(amountPerMonth, amountToPay);
            }

            function createCalculatedPayments(amountPerMonth, amountToPay) {
                var calculatedPayments = [];
                var paymentDateObject = getFirstPaymentDate();
                var incrementByMonths = 0;
                var cumulatedInterest = 0;

                switch ($scope.paymentAgreementModal.paymentPeriod) {
                    case "MONTHLY":
                        $scope.paymentAgreementModal.paymentPeriodInMonths = 1;
                        incrementByMonths = 1;
                        break;
                    case "QUARTER":
                        $scope.paymentAgreementModal.paymentPeriodInMonths = 3;
                        incrementByMonths = 3;
                        break;
                    case "HALF_YEAR":
                        $scope.paymentAgreementModal.paymentPeriodInMonths = 6;
                        incrementByMonths = 6;
                        break;
                    case "YEAR":
                        $scope.paymentAgreementModal.paymentPeriodInMonths = 12;
                        incrementByMonths = 12;
                        break;
                }

                while (amountToPay != 0) {
                    if (amountToPay >= amountPerMonth) {
                        amountToPay -= amountPerMonth;

                        cumulatedInterest += calculateInterest(paymentDateObject, amountToPay, 0.08);
                        calculatedPayments.push({
                            paymentDateString: $filter("date")(paymentDateObject, "dd.MM.yyyy"),
                            paymentDate: paymentDateObject,
                            amountToPay: amountPerMonth,
                            restValue: amountToPay,
                            interest: calculateInterest(paymentDateObject, amountToPay, 0.08),
                            cumulatedInterest: cumulatedInterest
                        })
                    } else {
                        cumulatedInterest += calculateInterest(paymentDateObject, amountToPay, 0.08);
                        calculatedPayments.push({
                            paymentDateString: $filter("date")(paymentDateObject, "dd.MM.yyyy"),
                            paymentDate: paymentDateObject,
                            amountToPay: amountToPay,
                            restValue: 0,
                            interest: calculateInterest(paymentDateObject, amountToPay, 0.08),
                            cumulatedInterest: cumulatedInterest

                        });
                        amountToPay = 0;
                    }

                    paymentDateObject = incrementDateByMonths(paymentDateObject, incrementByMonths);
                }

                $scope.paymentAgreementModal.calculatedPayments = calculatedPayments;
                $scope.paymentAgreementModal.calculationCreated = true;
            }

            function getFirstPaymentDate() {
                var paymentDateObject = new Date();
                paymentDateObject.setDate(1);
                paymentDateObject.setMonth(paymentDateObject.getMonth() + 1);
                paymentDateObject.setFullYear(paymentDateObject.getFullYear());
                paymentDateObject.setHours(12);
                paymentDateObject.setMinutes(0);
                paymentDateObject.setSeconds(0);

                return paymentDateObject;
            }

            function createCalculationWithAmountOfMonths() {
                var amountToPay = angular.copy($scope.paymentAgreementModal.amountInterestOfSelectedInvoices);
                var amountPerMonth = angular.copy(parseFloat($scope.paymentAgreementModal.amountInterestOfSelectedInvoices / $scope.paymentAgreementModal.amountOfMonths));

                createCalculatedPayments(amountPerMonth, amountToPay);
            }

            function calculateInterest(date, amount, interest) {
                return (amount * interest / 360) * daysInMonth(date.getMonth() + 1, date.getFullYear());
            }

            function incrementDateByMonths(paymentDate, numMonths) {
                var paymentDateTimeStamp = paymentDate.getTime();
                var paymentMonth = paymentDate.getMonth() + 1;
                var paymentYear = paymentDate.getFullYear();

                for (var i = 0; i < numMonths; i++) {
                    paymentDateTimeStamp += 1000 * 60 * 60 * 24 * daysInMonth(paymentMonth, paymentYear);
                    if (paymentMonth == 12) {
                        paymentMonth = 1;
                        paymentYear++;
                    } else {
                        paymentMonth++;
                    }
                }

                return new Date(paymentDateTimeStamp);
            }

            function daysInMonth(month, year) {
                return new Date(year, month, 0).getDate();
            }

            function resetAppointmentBox() {
                $scope.paymentAgreementModal.calculationCreated = false;
                $scope.paymentAgreementModal.calculatedPayments = undefined;
                $scope.paymentAgreementModal.amountOfMonths = undefined;
                $scope.paymentAgreementModal.amountPerMonth = undefined;
            }

            function loadUserId(sessionId, callback) {
                var requestDetails = {
                    successful: true,
                    response: undefined
                };
                requestManager.routes.auth.getUserIdBySessionId(sessionId).then(
                    function (response) {
                        requestDetails.response = response;
                        callback(requestDetails);
                    },
                    function (response) {
                        requestDetails.response = response;
                        requestDetails.successful = false;
                        callback(requestDetails);
                    }
                )
            }

            function createRecord(sessionId, customerId, recordType, callback, addsToDunningLevel = 0) {
                var requestDetails = {
                    successful: true,
                    response: undefined
                };

                requestManager.routes.customer.createRecordOfCustomer(sessionId, customerId, recordType, addsToDunningLevel).then(
                    function (response) {
                        requestDetails.response = response;
                        callback(requestDetails);
                    },
                    function (response) {
                        requestDetails.response = response;
                        requestDetails.successful = false;
                        callback(requestDetails);
                    }
                );
            }

            function createRecordEmail(sessionId, recordId, body, callback) {
                var requestDetails = {
                    successful: true,
                    response: undefined,
                    data: undefined
                };

                requestManager.routes.record.createEmailRecordOfRecord(sessionId, recordId, body).then(
                    function (response) {
                        requestDetails.response = response;
                        requestDetails.data = response.data;
                        callback(requestDetails);
                    },
                    function (response) {
                        requestDetails.response = response;
                        requestDetails.data = response.data;
                        requestDetails.successful = false;
                        callback(requestDetails);
                    }
                );
            }

            function createRecordEmailRecipient(sessionId, recordEmailId, body, callback) {
                var requestDetails = {
                    successful: true,
                    response: undefined,
                    data: undefined
                };

                console.log('create record email recipient');

                requestManager.routes.record.createRecordEmailRecipientOfRecordEmail(sessionId, recordEmailId, body).then(
                    function (response) {
                        requestDetails.response = response;
                        requestDetails.data = response.data;
                        callback(requestDetails);
                    },
                    function (response) {
                        requestDetails.response = response;
                        requestDetails.data = response.data;
                        requestDetails.successful = false;
                        callback(requestDetails);
                    }
                );
            }

            function createRecordEmailAttachment(sessionId, recordEmailId, attachmentList, callback) {
                var requestDetails = {
                    numRequestPlanned: attachmentList.length,
                    successResponses: [],
                    errorResponses: [],
                    responses: [],
                    allSuccessful: true
                };

                attachmentList.forEach(function (attachment) {
                    requestManager.routes.record.createRecordEmailAttachmentOfRecordEmail(sessionId, recordEmailId, attachment).then(
                        function (response) {
                            requestDetails.responses.push(response);
                            requestDetails.successResponses.push(response);

                            if (requestDetails.numRequestPlanned == requestDetails.responses.length) {
                                callback(requestDetails);
                            }
                        },
                        function (response) {
                            requestDetails.responses.push(response);
                            requestDetails.errorResponses.push(response);
                            requestDetails.allSuccessful = false;

                            if (requestDetails.numRequestPlanned == requestDetails.responses.length) {
                                callback(requestDetails);
                            }
                        }
                    )
                });

            }

            function createRecordComment(sessionId, recordId, commentText, callback) {
                var requestDetails = {
                    successful: true,
                    response: undefined
                };

                requestManager.routes.record.createCommentRecordOfRecord(sessionId, recordId, commentText).then(
                    function (response) {
                        requestDetails.response = response;
                        callback(requestDetails);
                    },
                    function (response) {
                        requestDetails.response = response;
                        requestDetails.successful = false;
                        callback(requestDetails);
                    }
                );
            }

            function createRecordPhone(sessionId, recordId, params, callback) {
                var requestDetails = {
                    successful: true,
                    response: undefined
                };

                requestManager.routes.record.createPhoneRecordOfRecord(sessionId, recordId, params).then(
                    function (response) {
                        requestDetails.response = response;
                        callback(requestDetails);
                    },
                    function (response) {
                        requestDetails.response = response;
                        requestDetails.successful = false;
                        callback(requestDetails);
                    }
                );
            }

            function sendEmailRequests(sessionId, attachmentList, emailContent, emailAddressList, recordType, callback, addsToDunningLevel = 0) {
                var requestDetails = {
                    numRequestPlanned: 2 + emailAddressList.length + attachmentList.length,
                    successfulResponses: [],
                    errorResponses: [],
                    responses: [],
                    allSuccessful: true
                };
                createRecord(sessionId, $routeParams.id, recordType, function (createRecordRequestDetails) {
                    requestDetails.responses.push(createRecordRequestDetails.response);
                    if (createRecordRequestDetails.successful) {
                        requestDetails.successfulResponses.push(createRecordRequestDetails.response);

                        createRecordEmail(sessionId, createRecordRequestDetails.response.data, emailContent, function (createRecordEmailDetails) {
                            requestDetails.responses.push(createRecordEmailDetails.response);
                            if (createRecordEmailDetails.successful) {
                                requestDetails.successfulResponses.push(createRecordEmailDetails.response);

                                emailAddressList.forEach((mail) => {
                                    createRecordEmailRecipient(sessionId, createRecordEmailDetails.response.data, {
                                        type: "to",
                                        mail: mail
                                    }, function (createRecordEmailRecipientRequestDetails) {
                                        requestDetails.responses.push(createRecordEmailRecipientRequestDetails.response);
                                        if (createRecordEmailRecipientRequestDetails.successful) {

                                            requestDetails.successfulResponses.push(createRecordEmailRecipientRequestDetails.response);
                                            if (requestDetails.numRequestPlanned == requestDetails.responses.length) {
                                                callback(requestDetails);
                                            }
                                        } else {
                                            requestDetails.allSuccessful = false;
                                            requestDetails.errorResponses.push(createRecordEmailRecipientRequestDetails.response);

                                            if (requestDetails.numRequestPlanned == requestDetails.responses.length) {
                                                callback(requestDetails);
                                            }
                                        }
                                    })
                                });
                                createRecordEmailAttachment(sessionId, createRecordEmailDetails.response.data, attachmentList, function (createRecordEmailAttachmentRequestDetails) {
                                    requestDetails.numResponsesReceived += createRecordEmailAttachmentRequestDetails.responses.length;
                                    Array.prototype.push.apply(requestDetails.responses, createRecordEmailAttachmentRequestDetails.responses);

                                    // RECORD EMAIL ATTACHMENTS CREATED SUCCESSFULLY
                                    if (createRecordEmailAttachmentRequestDetails.allSuccessful) {
                                        Array.prototype.push.apply(requestDetails.successfulResponses, createRecordEmailAttachmentRequestDetails.successResponses);

                                        if (requestDetails.numRequestPlanned == requestDetails.responses.length) {
                                            callback(requestDetails);
                                        }
                                    }

                                    //CREATING RECORD ATTACHMENTS NOT SUCCESSFUL
                                    else {
                                        requestDetails.allSuccessful = false;
                                        Array.prototype.push.apply(requestDetails.errorResponses, createRecordEmailAttachmentRequestDetails.errorResponses);

                                        if (requestDetails.numRequestPlanned == requestDetails.responses.length) {
                                            callback(requestDetails);
                                        }
                                    }
                                });
                            } else {
                                requestDetails.allSuccessful = false;
                                requestDetails.errorResponses.push(createRecordEmailDetails.response);
                                callback(requestDetails);
                            }
                        })
                    } else {
                        requestDetails.allSuccessful = false;
                        requestDetails.errorResponses.push(createRecordRequestDetails.response);
                        callback(requestDetails);
                    }
                }, addsToDunningLevel)
            }

            function sendReplaceEmailTemplateRequest(sessionId, userId, customerId, templateId, callback) {
                var requestDetails = {
                    successful: true,
                    response: undefined
                };

                requestManager.routes.emailTemplates.virtual.replaceEmailTemplate(sessionId, userId, customerId, templateId).then(
                    function (response) {
                        requestDetails.response = response;
                        callback(requestDetails);
                    },
                    function (response) {
                        requestDetails.response = response;
                        requestDetails.successful = false;
                        callback(requestDetails);
                    }
                )
            }

            function getAttachments() {
                var attachmentList = []
                $scope.emailModal.fileUploads.forEach(
                    (value) => {
                        if (value !== 'null' && value.index !== 'false') {
                            var attachment = jQuery('#attachment' + value.index).prop('files')[0];
                            if (attachment) {
                                attachmentList.push(attachment);
                            }
                        }
                    }
                );
                return attachmentList;
            }

            function loadUserList(sessionId, callback) {
                var requestDetails = {
                    successful: true,
                    response: undefined
                };

                requestManager.routes.user.getAllUser(sessionId).then(
                    function (response) {
                        requestDetails.response = response;
                        callback(requestDetails);
                    },
                    function (response) {
                        requestDetails.response = response;
                        requestDetails.successful = false;
                        callback(requestDetails);
                    }
                )
            }

            function sendEmailToCustomerAdvisor() {
                var emailProcessed = addContentToEmail(angular.copy($scope.informCustomerAdvisorModal.email));

                $scope.informCustomerAdvisorModal.currentlyLoading = true;
                var emailContent = {
                    content: emailProcessed,
                    subject: $scope.informCustomerAdvisorModal.subject,
                    attachReport: "1"
                };

                if ($window.confirm($filter("translate")("userDetailsActions.CONFIRM_SEND_EMAIL"))) {
                    editCustomer(
                        $rootScope.authData.sessionId,
                        $routeParams["id"],
                        {
                            isAtCustomerAdvisor: "1",
                            customerAdvisor: $scope.informCustomerAdvisorModal.selectedCustomerAdvisor.id
                        },
                        function (requestDetails) {
                            if (requestDetails.successful) {
                                sendEmailRequests($rootScope.authData.sessionId,
                                    [],
                                    emailContent,
                                    [$scope.informCustomerAdvisorModal.selectedCustomerAdvisor.email],
                                    "record_customer_advisor",
                                    function (details) {
                                        if (details.allSuccessful) {
                                            jQuery('#informCustomerAdvisorModal').on('hidden.bs.modal', function () {
                                                Notification.success($filter("translate")("userDetailsActions.EMAIL_SENT"));
                                                $route.reload();
                                            }).modal("hide");
                                        } else {
                                            sharedMemory.error.setError(
                                                details,
                                                "COULD_NOT_LOAD_DATA",
                                                "Fehler beim senden einer Email an den customer-advisor",
                                                $location.path(),
                                                "userDetailsAction"
                                            );
                                            $location.path("/error-page");
                                        }
                                    });
                            } else {
                                sharedMemory.error.setError(
                                    requestDetails,
                                    "COULD_NOT_LOAD_DATA",
                                    "Fehler beim editieren des Customers ",
                                    $location.path(),
                                    "userDetailsAction"
                                );

                                $location.path("/error-page");
                            }
                        });
                }
            }

            function addContentToEmail(email) {
                var emailHead = `
					<p>
						Das ist eine automatisch generierte Email. Ihnen wurde ein neuer Kunde als Kundenbetreuer zugewiesen. 
						Bitte bearbeiten Sie die Aufgabe und markieren Sie sie anschließend als "erledigt".  
						Eine Detailierte Beschreibung finden Sie hier: 
					</p> 
					<hr>
				`;
                email = emailHead + email;

                return email;
            }

            function onCreateNewCustomerAdvisorCase() {
                $scope.informCustomerAdvisorModal.currentlyLoading = true;
                if ($window.confirm($filter("translate")("userDetailsActions.CREATE_NEW_CUSTOMER_ADVISOR_CASE"))) {
                    startCustomerAdvisor(
                        $rootScope.authData.sessionId,
                        $routeParams["id"],
                        {
                            userId: +$scope.informCustomerAdvisorModal.selectedCustomerAdvisor.id,
                            comment: $scope.informCustomerAdvisorModal.email
                        },
                        function (requestDetails) {
                            if (requestDetails.successful) {
                                jQuery('#createNewCustomerAdvisorCaseModal').on('hidden.bs.modal', function () {
                                    $route.reload();
                                }).modal("hide");
                            } else {
                                sharedMemory.error.setError(
                                    requestDetails,
                                    "COULD_NOT_LOAD_DATA",
                                    "Fehler beim editieren des Customers ",
                                    $location.path(),
                                    "userDetailsAction"
                                );

                                $location.path("/error-page");
                            }
                        });
                }
            }

            function startCustomerAdvisor(sessionId, customerId, params, callback) {
                var requestDetails = {
                    successful: true,
                    response: undefined
                };

                requestManager.routes.customer.startCustomerAdvisor(sessionId, customerId, params).then(
                    function (response) {
                        requestDetails.response = response;
                        callback(requestDetails);
                    },
                    function (response) {
                        requestDetails.response = response;
                        requestDetails.successful = false;
                        callback(requestDetails);
                    }
                )
            }

            function editCustomer(sessionId, customerId, params, callback) {
                var requestDetails = {
                    successful: true,
                    response: undefined
                };

                requestManager.routes.customer.editCustomer(sessionId, customerId, params).then(
                    function (response) {
                        requestDetails.response = response;
                        callback(requestDetails);
                    },
                    function (response) {
                        requestDetails.response = response;
                        requestDetails.successful = false;
                        callback(requestDetails);
                    }
                )
            }

            function toggleMarkProblematic() {
                var isProblematic;

                if ($scope.customerData.currentlyLoading) {
                    Notification.error($filter("translate")("userDetailsActions.DATA_NOT_LOADED_YET"));
                    return;
                }

                if ($scope.customerData.content.isProblematic == null) {
                    isProblematic = "1";
                } else {
                    isProblematic = ($scope.customerData.content.isProblematic == "0") ? "1" : "0";
                }

                sendSetProblematicRequest($rootScope.authData.sessionId, $scope.customerData.content.id, isProblematic, function (requestDetails) {
                    if (requestDetails.successful) {
                        $route.reload();
                    } else {
                        if (requestDetails && requestDetails.response && requestDetails.response.status === 403) {
                            Notification.error($filter("translate")("userSettings.ERROR_403_USER_NEED_APPROPRIATE_PERMISSION"));
                        }
                    }
                });
            }

            function sendSetProblematicRequest(sessionId, customerId, isProblematic, callback) {
                var requestDetails = {
                    response: undefined,
                    successful: true
                };

                requestManager.routes.customer.markCustomerProblematic(sessionId, customerId, isProblematic).then(
                    function (response) {
                        requestDetails.response = response;
                        callback(requestDetails);
                    },
                    function (response) {
                        requestDetails.response = response;
                        requestDetails.successful = false;
                        callback(requestDetails);
                    }
                )
            }

            function openModalCustomerAdvisor() {
                console.log($rootScope.pricingModel);
                console.log($scope.informCustomerAdvisorModal);
                if ($rootScope.pricingModel === 'v2' || $rootScope.pricingModel === 'v2+') {
                    jQuery("#createNewCustomerAdvisorCaseModal").modal("show");
                } else {
                    jQuery("#informCustomerAdvisorModal").modal("show");
                }
            }

            function openModalWithId(id) {
                if (id === 'emailModal') {
                    $scope.emailRecipientTags = Array.from(new Set($scope.emailRecipientTags.map(a => a.email)))
                        .map(email => {
                            return $scope.emailRecipientTags.find(a => a.email === email)
                        })
                }
                jQuery("#" + id).modal("show");
                if (id === 'emailModal') {
                    if ($scope.settings.companyLogo.file === null) {
                        $scope.emailModal.buttonsDisabled = true;
                        Notification.error($filter("translate")("userDetailsActions.NO_LOGO"));
                    } else {
                        $scope.emailModal.buttonsDisabled = false;
                    }
                }
            }

            function saveTelephoneCallNote() {
                if (this.telephoneCallNoteForm.$invalid) {
                    return;
                }

                var params = {
                    phoneNumber: $scope.telephoneCallNoteModal.number,
                    text: $scope.telephoneCallNoteModal.text,
                    success: $scope.telephoneCallNoteModal.status
                };

                if ($window.confirm($filter("translate")("userDetailsActions.CONFIRM_CREATE_TEL_CALL_NOTE"))) {
                    createRecord($rootScope.authData.sessionId, $routeParams["id"], "record_phone", function (requestDetails) {
                        if (requestDetails.successful) {
                            createRecordPhone($rootScope.authData.sessionId, requestDetails.response.data, params, function (createRecordPhoneRequestDetails) {
                                if (createRecordPhoneRequestDetails.successful) {
                                    Notification.success($filter("translate")("userDetailsActions.TELEPHONE_NOTE_CREATED"));
                                    jQuery('#telephoneCallNoteModal').on('hidden.bs.modal', function () {
                                        $route.reload();
                                    }).modal("hide");
                                } else {
                                    jQuery('#telephoneCallNoteModal').on('hidden.bs.modal', function () {
                                        sharedMemory.error.setError(
                                            createRecordPhoneRequestDetails,
                                            "COULD_NOT_LOAD_DATA",
                                            "Fehler beim erstellen eines phone_ecord",
                                            $location.path(),
                                            "userDetailsAction"
                                        );
                                        $location.path("/error-page");
                                    }).modal("hide");
                                }
                            })
                        } else {
                            if (requestDetails && requestDetails.response && requestDetails.response.status === 403) {
                                Notification.error($filter("translate")("userSettings.ERROR_403_USER_NEED_APPROPRIATE_PERMISSION"));
                            } else {
                                jQuery('#telephoneCallNoteModal').on('hidden.bs.modal', function () {
                                    sharedMemory.error.setError(
                                        requestDetails,
                                        "COULD_NOT_LOAD_DATA",
                                        "Fehler beim erstellen eines records",
                                        $location.path(),
                                        "userDetailsAction"
                                    );
                                    $location.path("/error-page");
                                }).modal("hide");
                            }
                        }
                    });
                }
            }

            function saveComment() {
                if ($scope.commentForm.$invalid) {
                    return;
                }

                createRecord($rootScope.authData.sessionId, $routeParams["id"], "record_comment", function (requestDetails) {
                    if (requestDetails.successful) {
                        createRecordComment($rootScope.authData.sessionId, requestDetails.response.data, $scope.commentaryModal.text, function (createRecordCommentRequestDetails) {
                            if (createRecordCommentRequestDetails.successful) {
                                Notification.success($filter("translate")("userDetailsActions.COMMENT_CREATED"));
                                jQuery('#createCommentModal').one('hidden.bs.modal', function () {
                                }).modal("hide");
                                window.location.reload();
                            } else {
                                jQuery('#createCommentModal').one('hidden.bs.modal', function () {
                                    sharedMemory.error.setError(
                                        createRecordCommentRequestDetails,
                                        "COULD_NOT_LOAD_DATA",
                                        "Fehler beim erstellen eines recordComment",
                                        $location.path(),
                                        "userDetailsAction"
                                    );
                                    $location.path("/error-page");
                                }).modal("hide");
                            }
                        })
                    } else {
                        if (requestDetails && requestDetails.response && requestDetails.response.status === 403) {
                            Notification.error($filter("translate")("userSettings.ERROR_403_USER_NEED_APPROPRIATE_PERMISSION"));
                        } else {
                            jQuery('#createCommentModal').one('hidden.bs.modal', function () {
                                sharedMemory.error.setError(
                                    requestDetails,
                                    "COULD_NOT_LOAD_DATA",
                                    "Fehler beim erstellen eines record",
                                    $location.path(),
                                    "userDetailsAction"
                                );
                                $location.path("/error-page");
                            }).modal("hide");
                        }
                    }
                });
            }

            function areAllEmailsValid(emailList) {
                for (var i = 0; i < emailList.length; i++) {
                    if (!approve.value(emailList[i], {required: true, email: true}).approved) {
                        return false;
                    }
                }

                return true;
            }

            function createEmailListFromTags(emailTags) {
                console.log(emailTags);
                console.log('=====================');
                var emailList = [];
                emailTags.forEach(function (tag) {
                    emailList.push(tag.email);
                });
                return emailList;
            }

            function sendEmail() {
                if ($scope.emailForm.$invalid) {
                    return;
                }

                // check size of attachments
                var isSizeAllowed = getTotalMbSize();
                if (isSizeAllowed === 'error') {
                    Notification.error($filter("translate")("userDetailsActions.MORE_THAN_15MB"));
                    return;
                } else if (isSizeAllowed === 'warning') {
                    // Notification.warning($filter("translate")("userDetailsActions.MORE_THAN_10MB"));
                    if (!confirm($filter("translate")("userDetailsActions.MORE_THAN_10MB")))
                        return;
                }

                var emailContent = {
                    content: $scope.emailModal.content,
                    subject: $scope.emailModal.subject,
                    attachReport: $scope.emailModal.attachReport ? 1 : 0,
                    userId: $rootScope.authData.userId,
                    attachOPList: $scope.emailModal.attachOpList ? 1 : 0,
                    attachOPopenList: $scope.emailModal.attachOPopenList ? 1 : 0,
                };

                var addsToDunningLevel = $scope.emailModal.addsToDunningLevel ? 1 : 0;
                var attachmentList = getAttachments();
                var emailList = createEmailListFromTags($scope.emailRecipientTags);

                if (!areAllEmailsValid(emailList)) {
                    Notification.error($filter("translate")("userDetailsActions.INVALID_EMAIL_FORMAT"));
                    return;
                }

                if (emailList.length == 0) {
                    Notification.error($filter("translate")("userDetailsActions.EMAIL_LIST_EMTPY"));
                    return;
                }
                if ($scope.settings.companyLogo.file === null) {
                    Notification.error($filter("translate")("userDetailsActions.NO_LOGO"));
                    return;
                }

                $scope.emailModal.currentlyProcessing = true;
                sendEmailRequests($rootScope.authData.sessionId, attachmentList, emailContent, emailList, "record_email", function (details) {
                    $scope.emailModal.currentlyProcessing = false;
                    if (details.allSuccessful) {
                        jQuery('#emailModal').one('hidden.bs.modal', function () {
                            Notification.success($filter("translate")("userDetailsActions.EMAIL_SENT"));
                            $rootScope.amountUnapprovedDunnings += 1;
                            $route.reload();
                        }).modal("hide");
                    } else {
                        console.log(details);
                        if (details && details.responses && details.responses[0] && details.responses[0].status === 403) {
                            Notification.error($filter("translate")("userSettings.ERROR_403_USER_NEED_APPROPRIATE_PERMISSION"));
                        } else {
                            jQuery('#emailModal').one('hidden.bs.modal', function () {
                                sharedMemory.error.setError(
                                    details,
                                    "COULD_NOT_LOAD_DATA",
                                    "Fehler beim senden der Email",
                                    $location.path(),
                                    "userDetailsAction"
                                );
                                $location.path("/error-page");
                            }).modal("hide");
                        }
                    }
                }, addsToDunningLevel);
            }

            function setAccountsReceivableToIsInPaymentTerm(sessionId, accountsReceivableIdList, paymentTermId, callback) {
                var requestDetails = {
                    numRequestPlanned: accountsReceivableIdList.length,
                    successResponses: [],
                    errorResponses: [],
                    responses: [],
                    allSuccessful: true
                };

                accountsReceivableIdList.forEach(function (accountsReceivableId) {
                    requestManager.routes.accountsReceivable.editAccountsReceivable(sessionId, accountsReceivableId, {isInPaymentTerm: paymentTermId}).then(
                        function (response) {
                            requestDetails.responses.push(response);
                            requestDetails.successResponses.push(response);

                            if (requestDetails.numRequestPlanned == requestDetails.responses.length) {
                                callback(requestDetails);
                            }
                        },
                        function (response) {
                            requestDetails.responses.push(response);
                            requestDetails.errorResponses.push(response);
                            requestDetails.allSuccessful = false;

                            if (requestDetails.numRequestPlanned == requestDetails.responses.length) {
                                callback(requestDetails);
                            }
                        }
                    )
                });
            }

            function preparePaymentTermsAppointmentsListForRequest(list) {
                var preparedList = [];

                angular.forEach(list, function (payment) {
                    preparedList.push({
                        dateAppointment: payment.paymentDate.getTime(),
                        amountTotal: payment.amountToPay,
                        amountInterest: payment.interest
                    });
                });

                return preparedList;
            }

            function createPaymentTerm(sessionId, customerId, params, callback) {
                var requestDetails = {
                    successful: true,
                    response: undefined
                };


                requestManager.routes.customer.createPaymentTermOfCustomer(sessionId, customerId, params).then(
                    function (response) {
                        requestDetails.response = response;
                        callback(requestDetails);
                    },
                    function (response) {
                        requestDetails.response = response;
                        requestDetails.successful = false;
                        callback(requestDetails);
                    }
                )
            }

            function createPaymentTermAppointment(sessionId, paymentTermId, paramsList, callback) {
                var requestDetails = {
                    numRequestsPlanned: paramsList.length,
                    allSuccessful: true,
                    responses: [],
                    successfulResponses: [],
                    errorResponses: []
                };

                paramsList.forEach(function (params) {
                    requestManager.routes.paymentTerm.createPaymentTermAppointmentOfPaymentTerm(sessionId, paymentTermId, params).then(
                        function (response) {
                            requestDetails.responses.push(response);
                            requestDetails.successfulResponses.push(response);
                            if (requestDetails.responses.length == requestDetails.numRequestsPlanned) {
                                callback(requestDetails);
                            }
                        },
                        function (response) {
                            requestDetails.responses.push(response);
                            requestDetails.errorResponses.push(response);
                            requestDetails.allSuccessful = false;
                            if (requestDetails.responses.length == requestDetails.numRequestsPlanned) {
                                callback(requestDetails);
                            }
                        }
                    )
                });
            }

            function togglePaymentMethod() {
                var isOneCheckboxChecked = false;

                angular.forEach($scope.vacantPosOfCustomer.content.invoices, function (invoice) {
                    if (invoice.checked && !isOneCheckboxChecked) {
                        isOneCheckboxChecked = true;
                    }
                });

                if (isOneCheckboxChecked) {
                    jQuery("#paymentMethodBox").show(500);
                } else {
                    jQuery("#paymentMethodBox").hide(500);
                    jQuery("#appointmentBox").hide(500);
                    $scope.paymentAgreementModal.paymentPeriod = undefined;
                    $scope.paymentAgreementModal.calculationMethod = undefined;
                    jQuery("#amountPerMonthInput").hide(500);
                    jQuery("#amountOfMonthsInput").hide(500);
                }
            }

            function toggleAppointmentBox() {
                jQuery("#appointmentBox").show(500);
                $scope.paymentAgreementModal.calculationMethod = undefined;
                $scope.paymentAgreementModal.calculationCreated = false;
                $scope.paymentAgreementModal.amountPerMonth = undefined;
            }

            function toggleCalculationMethodField() {
                resetAppointmentBox();

                switch ($scope.paymentAgreementModal.calculationMethod) {
                    case "AMOUNT_PER_INTERVAL":
                        jQuery("#amountPerMonthInput").show(500);
                        jQuery("#amountOfMonthsInput").hide(500);
                        break;
                    case "AMOUNT_OF_INTERVALS":
                        jQuery("#amountOfMonthsInput").show(500);
                        jQuery("#amountPerMonthInput").hide(500);
                        break;
                    case undefined:
                        jQuery("#amountPerMonthInput").hide(500);
                        jQuery("#amountOfMonthsInput").hide(500);
                        break;
                }
            }

            function createCalculation() {
                if ($scope.paymentAgreementModal.calculationMethod == "" || $scope.paymentAgreementModal.calculationMethod == undefined || $scope.paymentAgreementModal.calculationMethod == 0) {
                    $scope.paymentAgreementModal.calculationCreated = false;
                    $scope.paymentAgreementModal.calculatedPayments = undefined;
                    return;
                }

                switch ($scope.paymentAgreementModal.calculationMethod) {
                    case "AMOUNT_PER_INTERVAL":
                        createCalculationWithAmountPerMonth();
                        break;
                    case "AMOUNT_OF_INTERVALS":
                        createCalculationWithAmountOfMonths();
                        break;
                }
            }

            function calculateAmountInterestOfCheckedInvoices() {
                var sum = 0;
                angular.forEach($scope.vacantPosOfCustomer.content.invoices, function (invoice) {
                    if (invoice.checked) {
                        sum += parseFloat(invoice.invoiceSum);
                    }
                });
                $scope.paymentAgreementModal.amountInterestOfSelectedInvoices = sum;
            }

            function checkAllInvoices() {
                angular.forEach($scope.vacantPosOfCustomer.content.invoices, function (invoice, key) {
                    $scope.vacantPosOfCustomer.content.invoices[key].checked = $scope.paymentAgreementModal.checkAll;
                });
            }

            function saveReminder() {
                $scope.createReminderModal.currentlyLoading = true;

                var reminderDate = new Date($scope.createReminderModal.selectedDate.getFullYear(),
                    $scope.createReminderModal.selectedDate.getMonth(),
                    $scope.createReminderModal.selectedDate.getDate(),
                    $scope.createReminderModal.selectedTime.getHours(),
                    $scope.createReminderModal.selectedTime.getMinutes());

                reminderDate.setSeconds(0);

                reminderDate = moment(reminderDate);

                var params = {
                    customerForReminder: $routeParams.id,
                    dateReminder: reminderDate.format("YYYY-MM-DD HH:mm:ss"),
                    userId: $rootScope.authData.userId,
                    notificationText: $scope.createReminderModal.notificationText
                };

                sendSaveReminderRequest($rootScope.authData.sessionId, params, function (requestDetails) {
                    if (requestDetails.successful) {
                        jQuery('#createReminderModal').one('hidden.bs.modal', function () {
                            Notification.success($filter("translate")("userDetailsActions.CREATED_REMINDER_SUCCESSFULLY"));
                            $scope.createReminderModal.selectedDate = undefined;
                            $scope.createReminderModal.selectedTime = new Date(2000, 1, 1, 12, 0);
                            $scope.createReminderModal.currentlyLoading = false;
                        }).modal("hide");
                    } else {
                        jQuery('#createReminderModal').one('hidden.bs.modal', function () {
                            sharedMemory.error.setError(
                                requestDetails,
                                "COULD_NOT_LOAD_DATA",
                                "Fehler speichern vom reminder",
                                $location.path(),
                                "userDetailsAction"
                            );
                            $location.path("/error-page");
                        }).modal("hide");
                    }
                });
            }

            function sendSaveReminderRequest(sessionId, params, callback) {
                var requestDetails = {
                    successful: true,
                    response: undefined
                }

                requestManager.routes.user.createNotificationOfUser(sessionId, params).then(
                    function (response) {
                        requestDetails.response = response;
                        callback(requestDetails);

                    },
                    function (response) {
                        requestDetails.response = response;
                        callback(requestDetails);
                    }
                );
            }

            function onEmailTemplateChanged() {
                $scope.emailModal.buttonsDisabled = true;
                sendReplaceEmailTemplateRequest($rootScope.authData.sessionId, $scope.userId, $scope.customerId, $scope.emailModal.selectedTemplate.id, function (requestDetails) {
                    $scope.emailModal.buttonsDisabled = false;
                    if (requestDetails.successful) {
                        $scope.emailModal.content = requestDetails.response.data.text;
                        $scope.emailModal.subject = requestDetails.response.data.subject;
                    } else {
                        sharedMemory.error.setError(
                            requestDetails,
                            "COULD_NOT_LOAD_DATA",
                            "Fehler beim emailTemplateReplace",
                            $location.path(),
                            "userDetailsAction"
                        );
                        $location.path("/error-page");
                    }
                });
            }

            function getCheckedInvoiceIds() {
                var checkedInvoiceIds = [];

                angular.forEach($scope.vacantPosOfCustomer.content.invoices, function (invoice) {
                    if (invoice.checked) {
                        checkedInvoiceIds.push(invoice["0"].id);
                    }
                });
                return checkedInvoiceIds;
            }

            function getInvoicesNotInPaymentAgreement(invoiceList) {
                var list = [];
                angular.forEach(invoiceList, function (invoice) {
                    if (invoice["0"].isInPaymentTerm == null) {
                        list.push(invoice);
                    }
                });

                return list;
            }

            function getInvoiceIdsForReport(vacantPos) {
                var checkedInvoices = [];

                angular.forEach(vacantPos, function (pos) {
                    if (pos.checked) {
                        checkedInvoices.push(pos["0"].openItemNumber);
                    }
                });

                return checkedInvoices;
            }

            function createReport() {
                var invoicesForReport = getInvoiceIdsForReport($scope.handOverToLawyerModal.vacantPosOfCustomer);

                //check what browser the user is surfing on
                var ua = window.navigator.userAgent;
                var msie = ua.indexOf("MSIE ");

                var IEfound = false;
                var EdgeFound = /Edge/.test(window.navigator.userAgent);

                if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./))  // If Internet Explorer
                {
                    IEfound = true;
                }

                if (!IEfound && !EdgeFound) {
                    var tabWindow = $window.open('about:blank', '_blank');
                    tabWindow.document.write('loading...');
                }

                var notificationReference = Notification.warning({
                    message: $filter("translate")("wrapper.LOADING_PDF"),
                    delay: null,
                    closeOnClick: false,
                });

                requestManager.routes.record.getLawyerReport($rootScope.authData.sessionId, $routeParams["id"], invoicesForReport).then(
                    function (response) {
                        var file = new Blob([response.data], {type: 'application/pdf'});

                        if (!IEfound && !EdgeFound) {
                            var fileURL = URL.createObjectURL(file);
                            tabWindow.location.href = fileURL;
                        }
                        if (IEfound || EdgeFound) {
                            let r = "";
                            for (let i = 0; i < 4; i++) {
                                if (i % 4 == 0)
                                    r += Math.random().toString(36).substring(8);
                                else {
                                    r += "-";
                                    r += Math.random().toString(36).substring(4);
                                }
                            }
                            $window.navigator.msSaveOrOpenBlob(file, r + ".pdf");
                        }

                        jQuery("#handOverToLawyerModal").modal("hide");

                        //$window.open($sce.trustAsResourceUrl(fileURL), "_self");
                        notificationReference.then(function (notification) {
                            notification.kill(false);
                        });

                    },
                    function (response) {
                        if (response && response.status === 403) {
                            Notification.error($filter("translate")("userSettings.ERROR_403_USER_NEED_APPROPRIATE_PERMISSION"));
                        } else {
                            Notification.error("Vorlage konnte nicht gefunden werden! Wenden Sie sich an den Support.");
                        }
                        notificationReference.then(function (notification) {
                            notification.kill(false);
                        });
                    }
                )


                // requestManager.routes.record.getLawyerReport($rootScope.authData.sessionId, $routeParams["id"], invoicesForReport).then(
                //     function(response){
                //         var file = new Blob([response.data], {type: 'application/pdf'});
                //         var fileURL = URL.createObjectURL(file);
                //         $window.open($sce.trustAsResourceUrl(fileURL));
                //     },
                //     function(response){
                //         //todo
                //     }
                // )
            }

            function checkAllInvoicesForReport() {
                angular.forEach($scope.handOverToLawyerModal.vacantPosOfCustomer, function (pos) {
                    pos.checked = $scope.paymentAgreementModal.checkAll;
                });
            }

            function addFileLine() {

                $scope.emailModal.fileUploads.push({
                    "index": $scope.emailModal.fileUploads.length
                });

            }

            function deleteFileLine(index) {
                $scope.emailModal.fileUploads[index] = {
                    index: 'false'
                };
            }

            function getTotalMbSize() {
                var attachmentList = []
                var bytes = 0;
                $scope.emailModal.fileUploads.forEach(
                    (value) => {
                        if (value.index !== 'false') {
                            var attachment = jQuery('#attachment' + value.index).prop('files')[0];
                            if (attachment) {
                                bytes += attachment.size;
                                attachmentList.push(attachment);
                            }
                        }
                    }
                );

                if (bytes > 15000000) // more than 15MB
                    return 'error';
                else if (bytes >= 10000000) // more than 10MB
                    return 'warning';
                return 'success';
            }
        }
    ]
}); 

angular.module('userInformations', []);
angular.module('userInformations').component('userInformations', {
    templateUrl: 'app/components/userInformations/userInformations.template.html',
    bindings: {
        customer: "<"
    },
    controller: [
        "requestManager",
        "$rootScope",
        "$routeParams",
        "$scope",
        "$location",
        "sharedMemory",
        "$window",
        "$sce",
        "$filter",
        "Notification",
        function (requestManager, $rootScope, $routeParams, $scope, $location, sharedMemory, $window, $sce, $filter, Notification) {
            init();

            function init(){
                initVariables();
                initScope();
                getCustomer();
                if ($rootScope.settings) {
                    $rootScope.settings.find(setting => {
                        if (setting.settingCode === 'deactivateBusinessPartnersByDATEV') {
                            $scope.deactivateBusinessPartnersByDATEV = setting.settingValue1;
                        }
                    });
                    $scope.settingsLoading = false;
                } else {
                    requestManager.routes.settings.getSettings($rootScope.authData.sessionId).then(
                        function (response) {
                            $scope.settings = response.data;
                            $scope.settings.find(setting => {
                                if (setting.settingCode === 'deactivateBusinessPartnersByDATEV') {
                                    $scope.deactivateBusinessPartnersByDATEV = setting.settingValue1;
                                }
                            });
                            $scope.settingsLoading = false;
                        },
                        function () {
                        }
                    )
                }

                loadCustomerContactInfo($rootScope.authData.sessionId, $routeParams.id, function(requestDetails){
                    if(requestDetails.successful){
                        let customerMailCommunications = [];
                        let customerPhoneCommunications = [];
                        requestDetails.response.data.forEach((customerComm) => {
                           if(customerComm.type === 'mail'){
                               customerMailCommunications.push(customerComm);
                           }
                           else {
                               customerPhoneCommunications.push(customerComm);
                           }
                        });
                        $scope.customerContactData.email = customerMailCommunications;
                        $scope.customerContactData.phone = customerPhoneCommunications;
                    }
                    else{
                        sharedMemory.error.setError(
                            requestDetails,
                            "COULD_NOT_LOAD_DATA",
                            "Fehler beim laden der Kundenkommunikationsdaten (beim initiieren der Komponente)",
                            $location.path(),
                            "userInformations"
                        );
                        $location.path("/error-page");
                    }
                });
                loadUserList($rootScope.authData.sessionId, function (requestDetails) {
                    if (requestDetails.successful) {
                        $scope.userList.content = requestDetails.response.data.filter((user) => {
                            return user.id !== '0' && user.disabled !== '1'
                        });
                        const customerAdvisor = $scope.userList.content.find(user => user.id === $scope.customer.content.customerAdvisor);
                        if (customerAdvisor) {
                            $scope.customerAdvisor = customerAdvisor.name + ' ' + customerAdvisor.surname;
                        }
                        $scope.userList.currentlyLoading = false;
                    } else {
                        sharedMemory.error.setError(
                            requestDetails,
                            "COULD_NOT_LOAD_DATA",
                            "Fehler beim laden der Userliste (beim initiieren der Komponente)",
                            $location.path(),
                            "userDetailsAction"
                        );
                        $location.path("/error-page");
                    }
                });
            }

            this.$onChanges = function(item) {
                if (item.customer && item.customer.currentValue) {
                    $scope.customer.content = angular.copy(item.customer.currentValue);
                    $scope.creditLimit = Number($scope.customer.content.creditLimit);
                    $scope.customer.currentlyLoading = false;
                    $scope.customer.content.updated = false;
                    if($scope.inputCreditLimit === undefined) {
                        $scope.inputCreditLimit = $scope.creditLimit;
                    }

                    $scope.individualFields = Object.keys($scope.customer.content)
                        .filter((key) => key.includes('individualField'))
                        .reduce((cur, key) => { return Object.assign(cur, { [key]: $scope.customer.content[key] })}, {});
                    var fields = [];
                    for(var i in $scope.individualFields) {
                        var object = {};
                        object['' + i] = $scope.individualFields[i];
                        fields.push(object);
                    }
                    $scope.individualFields = fields;
                }

                if (!$scope.customer.currentlyLoading) {
                    $scope.view.allDependenciesLoaded = true;
                    if($scope.customer.content.country !== "AT"){
                        $scope.userCrawledInformation.content = [];
                        $scope.userCrawledInformation.currentlyLoading = false;
                        return;
                    }
                }
            };

            function initVariables(){
                $scope.userInformations = {
                    currentlyLoading: true,
                    content: undefined
                };
                $scope.userCrawledInformation = {
                    currentlyLoading: true,
                    content: undefined
                };
                $scope.customer = {
                    content: undefined,
                    currentlyLoading: true
                };
                $scope.view = {
                    allDependenciesLoaded: false
                };
                $scope.infoModal = {
                    title: undefined,
                    body: undefined
                };
                $scope.customerContactData = {
                    email: undefined,
                    phone: undefined
                };
                $scope.userList = {
                    content: [],
                    currentlyLoading: true
                };
                $scope.isEditingCreditLimit = false;
                $scope.inputCreditLimit = undefined;
                $scope.individualFields = undefined;
                $scope.accountLengthImpersonal = undefined;
                $scope.settingsLoading = true;
                $scope.deactivateBusinessPartnersByDATEV = undefined;
            }

            function initScope(){
                $scope.isStatement = false;
                $scope.createSepa = createSepa;
                $scope.onSwitchClick = onSwitchClick;
                $scope.onSwitchIndividual = onSwitchIndividual;
                $scope.openInfoModal = openInfoModal;
                $scope.onChangePreferredContactMethodClick = onChangePreferredContactMethodClick;
                $scope.onToggleContactEntryPreference = onToggleContactEntryPreference;
                $scope.loadCustomerContactInfo = loadCustomerContactInfo;
                $scope.onSubmitCreditLimit = onSubmitCreditLimit;
                $scope.toggleEditCreditLimit = toggleEditCreditLimit;

                if( requestManager.getSubDomain() == 'zugvogel') {
                    $scope.isStatement = true;

                }
            }

            function getCustomer() {
                if ($location.path().startsWith('/user-details')) {
                    if ($rootScope.settings === undefined) {
                        const interval = setInterval(() => {
                            if ($rootScope.settings !== undefined) {
                                const accountLengthImpersonal = $rootScope.settings.find(function (setting) {
                                    return setting.settingCode === 'accountLengthImpersonal';
                                });
                                if (accountLengthImpersonal !== undefined) {
                                    $scope.accountLengthImpersonal = +accountLengthImpersonal.settingValue1;
                                } else {
                                    $scope.accountLengthImpersonal = 8;
                                }
                                clearInterval(interval);
                            }
                        }, 100);
                    } else {
                        const interval = setInterval(() => {
                            if ($scope.customer.content !== undefined && $scope.customer.content.id !== undefined) {
                                const accountLengthImpersonal = $rootScope.settings.find(function (setting) {
                                    return setting.settingCode === 'accountLengthImpersonal';
                                });
                                if (accountLengthImpersonal !== undefined) {
                                    $scope.accountLengthImpersonal = +accountLengthImpersonal.settingValue1;
                                } else {
                                    $scope.accountLengthImpersonal = 8;
                                }
                                clearInterval(interval);
                            }
                        }, 100);
                    }
                }
            }

            function loadCustomerContactInfo(sessionId, customerId, callback){
                var requestDetails = {
                    successful: true,
                    response: undefined
                };

                requestManager.routes.customer.getCustomerCommunications(sessionId, customerId).then(
                    function(response){
                        requestDetails.response = response;
                        callback(requestDetails);
                    },
                    function(response){
                        requestDetails.response = response;
                        requestDetails.successful = false;
                        callback(requestDetails);
                    }
                )
            }

            function loadUserList(sessionId, callback) {
                var requestDetails = {
                    successful: true,
                    response: undefined
                };

                requestManager.routes.user.getAllUser(sessionId).then(
                    function (response) {
                        requestDetails.response = response;
                        callback(requestDetails);
                    },
                    function (response) {
                        requestDetails.response = response;
                        requestDetails.successful = false;
                        callback(requestDetails);
                    }
                )
            }

            function onToggleContactEntryPreference(commObject){
                let params = {
                    id: commObject.id,
                    isActiveForDunning: commObject.isActiveForDunning
,                };
                requestManager.routes.customer.editCustomerCommunications($rootScope.authData.sessionId, params.id, params).then(
                    (response) => {
                        Notification.success("Kontakt erfolgreich bearbeitet!");
                        setTimeout(() => {
                            $window.location.reload();
                        }, 500);
                    },
                    (error) => {
                        console.error("Error trying to edit customer communications object");
                    }
                )
            }

            function onSwitchIndividual(switchType) {
                var params = {
                    individualField6: 1,
                    individualField7: $scope.customer.content.individualField7,
                }


                sendEditCustomerRequest($rootScope.authData.sessionId, $scope.customer.content.id, params, function(requestDetails){
                    if(requestDetails.successful){
                        Notification.success($filter("translate")("userInformations.SUCCESSFULLY_CHANGED") + "! Seite lädt neu...");
                        setTimeout(() => {
                            $window.location.reload();
                        }, 2000);
                    }
                })
            }

            function onSwitchClick(switchType){
                var params = {
                    automaticDunning: $scope.customer.content.automaticDunning,
                    getsDunningNote: $scope.customer.content.getsDunningNote,
                    getsOPList: $scope.customer.content.getsOPList,
                    getsOPopenList: $scope.customer.content.getsOPopenList,
                }

                sendEditCustomerRequest($rootScope.authData.sessionId, $scope.customer.content.id, params, function(requestDetails){
                    if(requestDetails.successful){
                        Notification.success($filter("translate")("userInformations.SUCCESSFULLY_CHANGED") + "! Seite lädt neu...");
                        setTimeout(() => {
                            $window.location.reload();
                        }, 2000);
                    }
                })
            }

            function createSepa(){
                //check what browser the user is surfing on
                var ua = window.navigator.userAgent;
                var msie = ua.indexOf("MSIE ");

                var IEfound = false;
                var EdgeFound = /Edge/.test(window.navigator.userAgent);

                if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./))  // If Internet Explorer
                {
                    IEfound = true;
                }

                if(!IEfound && !EdgeFound){
                    var tabWindow = $window.open('about:blank', '_blank');
                    tabWindow.document.write('loading...');
                }

                var notificationReference = Notification.warning({
                    message: $filter("translate")("wrapper.LOADING_PDF"),
                    delay: null,
                    closeOnClick: false,
                });
                requestManager.routes.record.getSepaOfCustomer($rootScope.authData.sessionId, $routeParams["id"]).then(
                    function(response){
                        var file = new Blob([response.data], {type: 'application/pdf'});

                        if(!IEfound && !EdgeFound){
                            var fileURL = URL.createObjectURL(file);
                            tabWindow.location.href = fileURL;
                        }
                        if(IEfound || EdgeFound){
                            let r =  "";
                            for(let i = 0; i < 4; i++){
                                if(i % 4 == 0)
                                    r += Math.random().toString(36).substring(8);
                                else{
                                    r += "-";
                                    r += Math.random().toString(36).substring(4);
                                }
                            }
                            $window.navigator.msSaveOrOpenBlob(file, r+".pdf");
                        }

                        //$window.open($sce.trustAsResourceUrl(fileURL), "_self");
                        notificationReference.then(function(notification){
                            notification.kill(false);
                        });

                    },
                    function(response){
                        Notification.error("Vorlage konnte nicht gefunden werden! Wenden Sie sich an den Support.");

                        notificationReference.then(function(notification){
                            notification.kill(false);
                        });
                    }
                )
            }

            function sendEditCustomerRequest(sessionId, id, params, callback) {
                var requestDetails = {
                    successful: true,
                    response: undefined
                };

                requestManager.routes.customer.editCustomer(sessionId, id, params).then(
                    function(response) {
                        requestDetails.successful = true;
                        requestDetails.response = response;
                        callback(requestDetails);
                    },
                    function(response) {
                        requestDetails.successful = false;
                        requestDetails.response = undefined;
                        callback(requestDetails);
                    }
                )
            }

            function openInfoModal(key){
                switch(key){
                    case "automaticDunning":
                        $scope.infoModal.title = $filter("translate")("userInformations.AUTOMATIC_DUNNING_INFO_TITLE");
                        $scope.infoModal.body = $filter("translate")("userInformations.AUTOMATIC_DUNNING_INFO_BODY");
                        break;
                    case "getsDunningNote":
                        $scope.infoModal.title = $filter("translate")("userInformations.DUNNING_INFO_TITLE");
                        $scope.infoModal.body = $filter("translate")("userInformations.DUNNING_INFO_BODY");
                        break;
                    case "getsOPList":
                        $scope.infoModal.title = $filter("translate")("userInformations.OP_LIST_INFO");
                        $scope.infoModal.body = $filter("translate")("userInformations.OP_LIST_BODY");
                        break;
                    case "emailContactInfo":
                        $scope.infoModal.title = $filter("translate")("userInformations.EMAIL_CONTACT_INFO");
                        $scope.infoModal.body = $filter("translate")("userInformations.EMAIL_CONTACT_INFO_BODY");
                        break;
                    case "phoneContactInfo":
                        $scope.infoModal.title = $filter("translate")("userInformations.PHONE_CONTACT_INFO");
                        $scope.infoModal.body = $filter("translate")("userInformations.PHONE_CONTACT_INFO_BODY");
                        break;
                    case "businessPartnerInactive":
                        $scope.infoModal.title = $filter("translate")("userInformations.AUTOMATIC_DUNNING");
                        $scope.infoModal.body = $filter("translate")("userInformations.BUSINESS_PARTNER_INACTIVE_INFO_BODY");
                        break;
                }

                jQuery("#userInformationInfoModal").modal("show");
            }

            function onChangePreferredContactMethodClick(method){
                if(angular.isUndefined(method)){
                    method = null;
                }

                let params = {
                    preferredContactMethod: method,
                };

                sendEditCustomerRequest($rootScope.authData.sessionId, $scope.customer.content.id, params, (requestDetails) => {
                    if(requestDetails.successful){
                        $scope.customer.content.preferredContactMethod = method;
                        Notification.success("Bevorzugter Kontaktweg wurde geändert");
                    }
                });

            }

            function onSubmitCreditLimit(inputCreditLimit){
                if(inputCreditLimit === $scope.creditLimit)
                    return;

                $scope.creditLimit = inputCreditLimit;
                let params = {
                    creditLimit : Number($scope.creditLimit)
                };

                sendEditCustomerRequest($rootScope.authData.sessionId, $scope.customer.content.id, params, (requestDetails) => {
                    if(requestDetails.successful){
                        $scope.customer.content.creditLimit = $scope.creditLimit;
                        Notification.success("Kreditlimit wurde geändert");
                    }
                });
            }

            function toggleEditCreditLimit(){
                $scope.isEditingCreditLimit = !$scope.isEditingCreditLimit;
            }
        }
    ]
}); 

angular.module('userRecords', []);
angular.module('userRecords').component('userRecords', {
    templateUrl: 'app/components/userRecords/userRecords.template.html',
    controller: [
        "$scope",
        "requestManager",
        "$rootScope",
        "$route",
        "$location",
        "util",
        "$routeParams",
        "$filter",
        "sharedMemory",
        "$window",
        "$sce",
        "Notification",
        "paginationManager",
        "$timeout",
        function ($scope, requestManager, $rootScope, $route, $location, util, $routeParams, $filter, sharedMemory, $window, $sce, Notification, paginationManager, $timeout) {
            this.$onInit = function () {
                initVariables();
                initScope();

                fetchRecords();
            };

            function initVariables() {
                $scope.allDependenciesLoaded = false;
                $scope.loadingInProgress = false;
                $timeout(() => {
                    if (!$scope.allDependenciesLoaded) {
                        $scope.loadingInProgress = true;
                    }
                }, 5000);
                $scope.recordList = {
                    currentlyLoading: true,
                    content: []
                };
                $scope.chunkedCombinedRecordsCopy = [];
                $scope.filteredList = undefined;
                $scope.userListOfRecords = {
                    currentlyLoading: true,
                    content: undefined,
                    contentAssignedToId: undefined
                };
                $scope.combinedRecordInformations = {};
                $scope.modalContent = undefined;
                $scope.pinnedComments = undefined;
                $scope.pageSize = 15;
                $scope.isAdmin = !!$rootScope.authData.isAdmin;
                $scope.filter = {
                    sortBy: undefined,
                    sortAscending: true,
                    sortType: undefined,
                    searchTerm: undefined,
                };
                $scope.isEditing = false;
                $scope.recordToEdit = {
                    id: undefined,
                    text: undefined
                };
                $scope.existsRecordToApprove = false;

                $scope.pagination = {
                    paginationIndex: paginationManager.readIndex() === undefined ? 0 : paginationManager.readIndex(),
                    paginationArray: []
                };
            }

            function initScope() {
                $scope.openRecordModal = openRecordModal;
                $scope.closeModal = closeModal;
                $scope.getEmailAttachmentAsPdf = getEmailAttachmentAsPdf;
                $scope.getPhysicalMailAsPdf = getPhysicalMailAsPdf;
                $scope.convertTimestampToDate = convertTimestampToDate;
                $scope.convertTimestampToTime = convertTimestampToTime;
                $scope.pinComment = pinComment;
                $scope.unpinComment = unpinComment;
                $scope.deleteComment = deleteComment;
                $scope.deletePinnedComment = deletePinnedComment;
                $scope.increasePaginationIndex = increasePaginationIndex;
                $scope.decreasePaginationIndex = decreasePaginationIndex;
                $scope.goToPage = goToPage;
                $scope.filterList = filterList;
                $scope.editCommentText = editCommentText;
                $scope.onEditRecordComment = onEditRecordComment;
                $scope.onEditPinnedRecordComment = onEditPinnedRecordComment;
                $scope.stopEditingRecordComment = stopEditingRecordComment;
                $scope.updateApprovalStatus = updateApprovalStatus;

                $scope.$watch("recordList.currentlyLoading", function () {
                    if (!$scope.recordList.currentlyLoading) {
                        $scope.combinedRecordInformations = angular.copy($scope.recordList.content);
                        for (let record of $scope.combinedRecordInformations) {
                            if ((record.recordType === 'record_email'
                                    && (record.recordEmail.sent === '0' || record.recordEmail.sent === null))
                                || (record.recordType === 'record_physical_mail'
                                    && (record.recordPhysicalMail.sent === '0' || record.recordPhysicalMail.sent === null))) {
                                $scope.existsRecordToApprove = true;
                                break;
                            }
                        }
                        $scope.chunkedCombinedRecords = chunkCombinedRecordInformations();
                        $scope.chunkedCombinedRecordsCopy = chunkCombinedRecordInformations();

                        $scope.pagination.paginationIndex = 0;
                        paginationManager.saveIndex($scope.pagination.paginationIndex);
                        createPaginationArray();

                        $scope.allDependenciesLoaded = true;
                    }
                })
            }

            function updateApprovalStatus(status, recordType, recordId, recordRecordId) {
                toggleProcessingModal(true);

                let params = {
                    approved: '' + status,
                    id: recordRecordId,
                    approvedBy: $rootScope.authData.userId
                };

                if (status === 0) {
                    requestManager.routes.record.deleteWholeRecord($rootScope.authData.sessionId, recordId).then(
                        function () {
                            toggleProcessingModal(false, function () {
                                $rootScope.amountUnapprovedDunnings -= 1;
                                $route.reload();
                                Notification.success($filter("translate")("emailList.SUCCESSFUL_UPDATED"));
                            });
                        },
                        function (response) {

                        }
                    )

                } else {
                    if (recordType === 'record_email') {
                        requestManager.routes.recordEmail.updateRecordEmail($rootScope.authData.sessionId, params).then(
                            function () {
                                toggleProcessingModal(false, function () {
                                    $rootScope.amountUnapprovedDunnings -= 1;
                                    $route.reload();
                                    Notification.success($filter("translate")("emailList.SUCCESSFUL_UPDATED"));
                                });
                            },
                            function (response) {
                            }
                        );
                    } else {
                        requestManager.routes.recordPhysicalMail.updatePhysicalMail($rootScope.authData.sessionId, params).then(
                            function () {
                                toggleProcessingModal(false, function () {
                                    $route.reload();
                                    Notification.success($filter("translate")("physicalMailApprovalList.SUCCESSFUL_UPDATED"));
                                });
                            },
                            function (response) {
                            }
                        );
                    }
                }
            }

            function toggleProcessingModal(showModal, callback) {
                if (showModal) {
                    jQuery("#processingModal").modal("show");
                } else {
                    if (callback) {
                        jQuery("#processingModal").one("hidden.bs.modal", callback).modal("hide");
                    } else {
                        jQuery("#processingModal").modal("hide");
                    }
                }
            }

            function fetchRecords() {
                getRecordsOfCustomer($rootScope.authData.sessionId, $routeParams['id'], function (recordRequestDetails) {
                    if (recordRequestDetails.successful) {
                        if (!angular.isArray(recordRequestDetails.response.data) || (angular.isArray(recordRequestDetails.response.data) && recordRequestDetails.response.data.length === 0)) {
                            $scope.recordList.content = [];
                            $scope.allDependenciesLoaded = true;
                            return;
                        } else {
                            $scope.recordList.content = recordRequestDetails.response.data;
                        }

                        $scope.recordList.content = $scope.recordList.content.filter(el => el !== null && el.recordType !== 'record_customer_advisor_v2');
                        // SHIT CODE START HERE
                        $scope.recordList.content = $scope.recordList.content.map(record => {
                            if (record.recordComment === null) {
                                return record;
                            } else {
                                if (record.recordComment.text) {
                                    record.recordComment.text = record.recordComment.text.replace('&#65279;', '');
                                }
                                return record;
                            }
                        });
                        // SHIT CODE END HERE
                        $scope.recordList.content = $scope.recordList.content.sort((objA, objB) => Number(new Date(objB.entryCreated)) - Number(new Date(objA.entryCreated)))
                        $scope.recordList.content = addPropertiesToRecordList($scope.recordList.content);

                        $scope.recordList.currentlyLoading = false;
                    } else {
                        sharedMemory.error.setError(
                            recordRequestDetails,
                            "COULD_NOT_LOAD_DATA",
                            "Fehler beim laden der generischen Records (beim initiieren der Komponente)",
                            $location.path(),
                            "userRecords"
                        );
                        $location.path("/error-page");
                    }
                });

                getPinnedComments();
            }

            function filterList(property) {
                if (property !== undefined) {
                    if (property === $scope.filter.sortBy) {
                        $scope.filter.sortAscending = !$scope.filter.sortAscending;
                    } else {
                        $scope.filter.sortBy = property;
                        $scope.filter.sortAscending = true;
                    }
                }
                processUserListForFrontend();
            }

            function processUserListForFrontend() {
                $scope.chunkedCombinedRecords = angular.copy($scope.chunkedCombinedRecordsCopy);
                $scope.pagination.paginationIndex = paginationManager.readIndex() === undefined ? 0 : paginationManager.readIndex();
                applyTextFilter();
                applyOrder();
                $scope.chunkedCombinedRecords = chunkCombinedRecordInformations();
                $scope.pagination.paginationIndex = 0;
                paginationManager.saveIndex($scope.pagination.paginationIndex);
                createPaginationArray();
            }

            function applyTextFilter() {
                if (angular.isUndefined($scope.filter.searchTerm) || $scope.filter.searchTerm === '') {
                    $scope.filteredList = undefined;
                    return;
                }

                $scope.filteredList = [];
                for (let i = 0; i < $scope.chunkedCombinedRecords.length; i++) {
                    angular.forEach($scope.chunkedCombinedRecords[i], function (record) {
                        if (!_.isNull(record.recordComment) && !_.isNull(record.recordComment.text) && record.recordComment.text.toLowerCase().indexOf($scope.filter.searchTerm.toLowerCase()) !== -1) {
                            $scope.filteredList.push(record);
                            return;
                        }
                        if (!_.isNull(record.recordCustomerAdvisor) && !_.isNull(record.recordCustomerAdvisor.subject) && record.recordCustomerAdvisor.subject.toLowerCase().indexOf($scope.filter.searchTerm.toLowerCase()) !== -1) {
                            $scope.filteredList.push(record);
                            return;
                        }
                        if (!_.isNull(record.recordCustomerAdvisor) && !_.isNull(record.recordCustomerAdvisor.content) && record.recordCustomerAdvisor.content.toLowerCase().indexOf($scope.filter.searchTerm.toLowerCase()) !== -1) {
                            $scope.filteredList.push(record);
                            return;
                        }
                        if (!_.isNull(record.recordEmail) && !_.isNull(record.recordEmail.subject) && record.recordEmail.subject.toLowerCase().indexOf($scope.filter.searchTerm.toLowerCase()) !== -1) {
                            $scope.filteredList.push(record);
                            return;
                        }
                        if (!_.isNull(record.recordEmail) && !_.isNull(record.recordEmail.content) && record.recordEmail.content.toLowerCase().indexOf($scope.filter.searchTerm.toLowerCase()) !== -1) {
                            $scope.filteredList.push(record);
                            return;
                        }
                        if (!_.isNull(record.recordEmailReply) && !_.isNull(record.recordEmailReply.content) && record.recordEmailReply.content.toLowerCase().indexOf($scope.filter.searchTerm.toLowerCase()) !== -1) {
                            $scope.filteredList.push(record);
                            return;
                        }
                        if (record.recordType === 'record_inkasso' && ('Übergabe an Inkassounternehmen').toLowerCase().indexOf($scope.filter.searchTerm.toLowerCase()) !== -1) {
                            $scope.filteredList.push(record);
                            return;
                        }
                        if (record.recordType === 'record_phone' && !_.isNull(record.recordPhone) && record.recordPhone.success === '1' && record.recordPhone.text.toLowerCase().indexOf($scope.filter.searchTerm.toLowerCase()) !== -1) {
                            $scope.filteredList.push(record);
                            return;
                        }
                        if (record.recordType === 'record_phone' && !_.isNull(record.recordPhone) && record.recordPhone.success === '0' && ('Kunde nicht erreicht').toLowerCase().indexOf($scope.filter.searchTerm.toLowerCase()) !== -1) {
                            $scope.filteredList.push(record);
                            return;
                        }
                        if (record.recordType === 'record_physical_mail' && ('Mahnung verschickt').toLowerCase().indexOf($scope.filter.searchTerm.toLowerCase()) !== -1) {
                            $scope.filteredList.push(record);
                        }
                        if (!_.isNull(record.userDidEntry) && !_.isNull(record.userDidEntry.name) && record.userDidEntry.name.toLowerCase().indexOf($scope.filter.searchTerm.toLowerCase()) !== -1) {
                            $scope.filteredList.push(record);
                            return;
                        }
                        if (!_.isNull(record.userDidEntry) && !_.isNull(record.userDidEntry.surname) && record.userDidEntry.surname.toLowerCase().indexOf($scope.filter.searchTerm.toLowerCase()) !== -1) {
                            $scope.filteredList.push(record);
                            return;
                        }
                        if (!_.isNull(record.userDidEntry) && !_.isNull(record.userDidEntry.name) && !_.isNull(record.userDidEntry.surname)) {
                            const ns = record.userDidEntry.name + ' ' + record.userDidEntry.surname;
                            const sn = record.userDidEntry.surname + ' ' + record.userDidEntry.name;
                            if (ns.toLowerCase().indexOf($scope.filter.searchTerm.toLowerCase()) !== -1 || sn.toLowerCase().indexOf($scope.filter.searchTerm.toLowerCase()) !== -1)
                                $scope.filteredList.push(record);
                        }
                    });
                }

            }

            function applyOrder() {
                let array;
                if ($scope.filteredList !== undefined) {
                    array = angular.copy($scope.filteredList);
                } else {
                    array = angular.copy($scope.combinedRecordInformations);
                }
                if (angular.isUndefined($scope.filter.sortBy)) {
                    return;
                }

                if ($scope.filter.sortAscending) {
                    switch ($scope.filter.sortBy) {
                        case "typ":
                            array.sort(function (a, b) {
                                return a.recordType.localeCompare(b.recordType);
                            });
                            break;
                        case "record":
                            array.sort(function (a, b) {
                                let value1;
                                let value2;
                                switch (a.recordType) {
                                    case 'record_email':
                                        value1 = a.recordEmail.subject;
                                        break;
                                    case 'record_customer_advisor':
                                        value1 = a.recordCustomerAdvisor.subject;
                                        break;
                                    case 'record_email_reply':
                                        value1 = a.recordEmailReply.subject;
                                        break;
                                    case 'record_comment':
                                        if (!a.recordComment.text.startsWith('<')) {
                                            value1 = a.recordComment.text;
                                        } else {
                                            let div = document.createElement('div');
                                            div.innerHTML = a.recordComment.text;
                                            value1 = div.textContent || div.innerText || '';
                                        }
                                        break;
                                    case 'record_phone':
                                        if (a.recordPhone.success === '1') {
                                            if (!a.recordPhone.text.startsWith('<')) {
                                                value1 = a.recordPhone.text;
                                            } else {
                                                let div = document.createElement('div');
                                                div.innerHTML = a.recordPhone.text;
                                                value1 = div.textContent || div.innerText || '';
                                            }
                                        } else {
                                            value1 = 'Kunde nicht erreicht';
                                        }
                                        break;
                                    case 'record_physical_mail':
                                        value1 = "Mahnung verschickt";
                                        break;
                                    case 'record_inkasso':
                                        value1 = 'Übergabe an Inkassounternehmen';
                                        break;
                                    default:
                                        value1 = '';
                                }
                                switch (b.recordType) {
                                    case 'record_email':
                                        value2 = b.recordEmail.subject;
                                        break;
                                    case 'record_customer_advisor':
                                        value2 = b.recordCustomerAdvisor.subject;
                                        break;
                                    case 'record_email_reply':
                                        value2 = b.recordEmailReply.subject;
                                        break;
                                    case 'record_comment':
                                        if (!b.recordComment.text.startsWith('<')) {
                                            value2 = b.recordComment.text;
                                        } else {
                                            let div = document.createElement('div');
                                            div.innerHTML = b.recordComment.text;
                                            value2 = div.textContent || div.innerText || '';
                                        }
                                        break;
                                    case 'record_phone':
                                        if (b.recordPhone.success === '1') {
                                            if (!b.recordPhone.text.startsWith('<')) {
                                                value2 = b.recordPhone.text;
                                            } else {
                                                let div = document.createElement('div');
                                                div.innerHTML = b.recordPhone.text;
                                                value2 = div.textContent || div.innerText || '';
                                            }
                                        } else {
                                            value2 = 'Kunde nicht erreicht';
                                        }
                                        break;
                                    case 'record_physical_mail':
                                        value2 = "Mahnung verschickt";
                                        break;
                                    case 'record_inkasso':
                                        value2 = 'Übergabe an Inkassounternehmen';
                                        break;
                                    default:
                                        value1 = '';
                                }
                                return value1.localeCompare(value2);
                            });
                            break;
                        case "user":
                            array.sort(function (a, b) {
                                return a.userDidEntry.surname.localeCompare(b.userDidEntry.surname);
                            });
                            break;
                        case "time":
                            array.sort(function (a, b) {
                                return a.entryCreated.localeCompare(b.entryCreated);
                            });
                            break;
                    }
                } else {
                    switch ($scope.filter.sortBy) {
                        case "typ":
                            array.sort(function (b, a) {
                                return a.recordType.localeCompare(b.recordType);
                            });
                            break;
                        case "record":
                            array.sort(function (b, a) {
                                let value1;
                                let value2;
                                switch (a.recordType) {
                                    case 'record_email':
                                        value1 = a.recordEmail.subject;
                                        break;
                                    case 'record_customer_advisor':
                                        value1 = a.recordCustomerAdvisor.subject;
                                        break;
                                    case 'record_email_reply':
                                        value1 = a.recordEmailReply.subject;
                                        break;
                                    case 'record_comment':
                                        if (!a.recordComment.text.startsWith('<')) {
                                            value1 = a.recordComment.text;
                                        } else {
                                            let div = document.createElement('div');
                                            div.innerHTML = a.recordComment.text;
                                            value1 = div.textContent || div.innerText || '';
                                        }
                                        break;
                                    case 'record_phone':
                                        if (a.recordPhone.success === '1') {
                                            if (!a.recordPhone.text.startsWith('<')) {
                                                value1 = a.recordPhone.text;
                                            } else {
                                                let div = document.createElement('div');
                                                div.innerHTML = a.recordPhone.text;
                                                value1 = div.textContent || div.innerText || '';
                                            }
                                        } else {
                                            value1 = 'Kunde nicht erreicht';
                                        }
                                        break;
                                    case 'record_physical_mail':
                                        value1 = "Mahnung verschickt";
                                        break;
                                    case 'record_inkasso':
                                        value1 = 'Übergabe an Inkassounternehmen';
                                        break;
                                    default:
                                        value1 = '';
                                }
                                switch (b.recordType) {
                                    case 'record_email':
                                        value2 = b.recordEmail.subject;
                                        break;
                                    case 'record_customer_advisor':
                                        value2 = b.recordCustomerAdvisor.subject;
                                        break;
                                    case 'record_email_reply':
                                        value2 = b.recordEmailReply.subject;
                                        break;
                                    case 'record_comment':
                                        if (!b.recordComment.text.startsWith('<')) {
                                            value2 = b.recordComment.text;
                                        } else {
                                            let div = document.createElement('div');
                                            div.innerHTML = b.recordComment.text;
                                            value2 = div.textContent || div.innerText || '';
                                        }
                                        break;
                                    case 'record_phone':
                                        if (b.recordPhone.success === '1') {
                                            if (!b.recordPhone.text.startsWith('<')) {
                                                value2 = b.recordPhone.text;
                                            } else {
                                                let div = document.createElement('div');
                                                div.innerHTML = b.recordPhone.text;
                                                value2 = div.textContent || div.innerText || '';
                                            }
                                        } else {
                                            value2 = 'Kunde nicht erreicht';
                                        }
                                        break;
                                    case 'record_physical_mail':
                                        value2 = "Mahnung verschickt";
                                        break;
                                    case 'record_inkasso':
                                        value2 = 'Übergabe an Inkassounternehmen';
                                        break;
                                    default:
                                        value1 = '';
                                }
                                return value1.localeCompare(value2);
                            });
                            break;
                        case "user":
                            array.sort(function (b, a) {
                                return a.userDidEntry.surname.localeCompare(b.userDidEntry.surname);
                            });
                            break;
                        case "time":
                            array.sort(function (b, a) {
                                return a.entryCreated.localeCompare(b.entryCreated);
                            });
                            break;
                    }
                }
                if ($scope.filteredList !== undefined) {
                    $scope.filteredList = array;
                } else {
                    $scope.combinedRecordInformations = array;
                }
            }

            function chunkCombinedRecordInformations() {
                let chunkedRecords = [];
                let chunk = [];
                if ($scope.filteredList !== undefined) {
                    for (let i = 1; i <= $scope.filteredList.length; i++) {
                        chunk.push($scope.filteredList[i - 1]);
                        if (i % $scope.pageSize === 0) {
                            chunkedRecords.push(chunk);
                            chunk = [];
                        }
                    }
                    if ($scope.filteredList.length % $scope.pageSize !== 0) {
                        chunkedRecords.push(chunk);
                    }
                    return chunkedRecords;
                } else {
                    for (let i = 1; i <= $scope.combinedRecordInformations.length; i++) {
                        chunk.push($scope.combinedRecordInformations[i - 1]);
                        if (i % $scope.pageSize === 0) {
                            chunkedRecords.push(chunk);
                            chunk = [];
                        }
                    }
                    if ($scope.combinedRecordInformations.length % $scope.pageSize !== 0) {
                        chunkedRecords.push(chunk);
                    }
                    return chunkedRecords;
                }
            }

            function createPaginationArray() {
                if ($scope.filteredList !== undefined) {
                    if ($scope.filteredList.length === 0) {
                        return;
                    }
                } else {
                    if ($scope.combinedRecordInformations.length === 0) {
                        return;
                    }
                }
                $scope.pagination.paginationArray = [];
                if ($scope.chunkedCombinedRecords.length < 9) {
                    for (let i = 0; i < $scope.chunkedCombinedRecords.length; i++) {
                        $scope.pagination.paginationArray.push({
                            text: i + 1,
                            clickable: true,
                            isActive: false
                        })
                    }
                    $scope.pagination.paginationArray[$scope.pagination.paginationIndex].isActive = true;
                } else {
                    $scope.pagination.paginationArray.push({
                        text: 1,
                        clickable: true,
                        isActive: false
                    }, {
                        text: 2,
                        clickable: true,
                        isActive: false
                    }, {
                        text: 3,
                        clickable: true,
                        isActive: false
                    }, {
                        text: 4,
                        clickable: true,
                        isActive: false
                    }, {
                        text: "...",
                        clickable: false,
                        isActive: false
                    }, {
                        text: $scope.chunkedCombinedRecords.length - 3,
                        clickable: true,
                        isActive: false
                    }, {
                        text: $scope.chunkedCombinedRecords.length - 2,
                        clickable: true,
                        isActive: false
                    }, {
                        text: $scope.chunkedCombinedRecords.length - 1,
                        clickable: true,
                        isActive: false
                    }, {
                        text: $scope.chunkedCombinedRecords.length,
                        clickable: true,
                        isActive: false
                    });

                    switch ($scope.pagination.paginationIndex) {
                        case 0:
                            $scope.pagination.paginationArray[0].isActive = true;
                            break;
                        case 1:
                            $scope.pagination.paginationArray[1].isActive = true;
                            break;
                        case 2:
                            $scope.pagination.paginationArray[2].isActive = true;
                            break;
                        case 3:
                            $scope.pagination.paginationArray[3].isActive = true;
                            break;
                        case $scope.chunkedCombinedRecords.length - 4:
                            $scope.pagination.paginationArray[5].isActive = true;
                            break;
                        case $scope.chunkedCombinedRecords.length - 3:
                            $scope.pagination.paginationArray[6].isActive = true;
                            break;
                        case $scope.chunkedCombinedRecords.length - 2:
                            $scope.pagination.paginationArray[7].isActive = true;
                            break;
                        case $scope.chunkedCombinedRecords.length - 1:
                            $scope.pagination.paginationArray[8].isActive = true;
                            break;
                        default:
                            $scope.pagination.paginationArray[4].isActive = true;
                    }
                }
            }

            function increasePaginationIndex() {
                if ($scope.pagination.paginationIndex < $scope.chunkedCombinedRecords.length - 1) {
                    $scope.pagination.paginationIndex++;
                    paginationManager.saveIndex($scope.pagination.paginationIndex);
                    createPaginationArray();
                }
            }

            function decreasePaginationIndex() {
                if ($scope.pagination.paginationIndex > 0) {
                    $scope.pagination.paginationIndex--;
                    paginationManager.saveIndex($scope.pagination.paginationIndex);
                    createPaginationArray();
                }
            }

            function goToPage(pageObject) {
                if (pageObject.clickable) {
                    $scope.pagination.paginationIndex = pageObject.text - 1;
                    paginationManager.saveIndex($scope.pagination.paginationIndex);
                    createPaginationArray();
                }
            }

            function getRecordsOfCustomer(sessionId, customerId, callback) {
                var requestDetails = {
                    successful: true,
                    response: undefined
                };

                requestManager.routes.customer.getAllRecordsOfCustomer(sessionId, customerId).then(
                    function (response) {
                        requestDetails.response = response;
                        callback(requestDetails);
                    },
                    function (response) {
                        requestDetails.response = response;
                        requestDetails.successful = false;
                        callback(requestDetails);
                    }
                )
            }

            function getPinnedComments() {
                requestManager.routes.record.getPinnedComment($routeParams["id"], $rootScope.authData.sessionId).then(
                    response => {
                        $scope.pinnedComments = response.data;
                    }
                );
            }

            function onEditRecordComment() {
                $scope.recordToEdit.id = $scope.modalContent.recordComment.id;
                $scope.recordToEdit.text = $scope.modalContent.recordComment.text;
                $scope.isEditing = true;
            }

            function onEditPinnedRecordComment() {
                $scope.recordToEdit.id = $scope.modalContent.id_record_comment;
                $scope.recordToEdit.text = $scope.modalContent.text;
                $scope.isEditing = true;
            }

            function stopEditingRecordComment() {
                $scope.recordToEdit.id = undefined;
                $scope.recordToEdit.text = undefined;
                $scope.isEditing = false;
            }

            function editCommentText() {
                requestManager.routes.record.putCommentRecord($rootScope.authData.sessionId, $scope.recordToEdit.id, {text: $scope.recordToEdit.text}).then(
                    function () {
                        window.location.reload();
                    },
                    function () {
                        Notification.error("Änderung konnte nicht gespeichert werden.");
                    }
                );
            }

            function openRecordModal(combinedRecord) {
                $scope.modalContent = combinedRecord;
                $scope.isEditing = false;
                if (combinedRecord.recordType === 'record_comment' && combinedRecord.pinned === '1') {
                    $scope.recordToEdit.id = combinedRecord.id_record_comment;
                    $scope.recordToEdit.text = combinedRecord.text;
                    jQuery("#recordPinnedCommentModal").modal("show");
                } else {
                    switch (combinedRecord.recordType) {
                        case "record_phone":
                            jQuery("#recordPhoneModal").modal("show");
                            break;
                        case "record_customer_advisor":
                            jQuery("#recordCustomerAdvisorModal").modal("show");
                            break;
                        case "record_email":
                            jQuery("#recordEmailModal").modal("show");
                            break;
                        case "record_comment":
                            $scope.recordToEdit.id = combinedRecord.recordComment.id;
                            $scope.recordToEdit.text = combinedRecord.recordComment.text;
                            jQuery("#recordCommentModal").modal("show");
                            break;
                        case "record_email_reply":
                            jQuery('#recordEmailReplyModal').modal("show");
                            break;
                        case "record_physical_mail":
                            jQuery('#recordPhysicalMailModal').modal("show");
                            break;
                        case "record_inkasso":
                            jQuery('#recordInkassoModal').modal("show");
                            break;
                    }
                }
            }

            function deleteComment(comment) {
                const recordId = comment.id;
                let recordCommentId = undefined;
                if (comment.recordComment !== undefined && comment.recordComment !== null) {
                    recordCommentId = comment.recordComment.id;
                }
                if (recordCommentId !== undefined) {
                    requestManager.routes.record.deleteWholeRecord($rootScope.authData.sessionId, recordId);
                    requestManager.routes.record.deleteCommentRecord($rootScope.authData.sessionId, recordCommentId).then(
                        function () {
                            window.location.reload();
                        },
                        function () {
                            Notification.error($filter("translate")("userSettings.ERROR_403_USER_NEED_APPROPRIATE_PERMISSION"));
                        }
                    );
                } else {
                    requestManager.routes.record.deleteWholeRecord($rootScope.authData.sessionId, recordId).then(
                        function () {
                            window.location.reload();
                        }
                    )
                }
            }

            function deletePinnedComment(comment) {
                const recordId = comment.id_record;
                const recordCommentId = comment.id_record_comment;
                requestManager.routes.record.deleteWholeRecord($rootScope.authData.sessionId, recordId);
                requestManager.routes.record.deleteCommentRecord($rootScope.authData.sessionId, recordCommentId).then(
                    function () {
                        window.location.reload();
                    },
                    function () {
                        Notification.error($filter("translate")("userSettings.ERROR_403_USER_NEED_APPROPRIATE_PERMISSION"));
                    }
                );
            }

            function closeModal(modalId) {
                $scope.modalContent = undefined;
                jQuery("#" + modalId).modal("hide");
            }

            function addPropertiesToRecordList(recordList) {
                recordList.forEach(function (record) {
                    record.created = moment(record.entryCreated, "YYYY-MM-DD HH:mm:ss").valueOf();
                });
                return recordList;
            }

            function getEmailAttachmentAsPdf(attachmentId) {
                //check what browser the user is surfing on
                var ua = window.navigator.userAgent;
                var msie = ua.indexOf("MSIE ");
                var IEfound = false;
                var EdgeFound = /Edge/.test(window.navigator.userAgent);

                if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./))  // If Internet Explorer
                {
                    IEfound = true;
                }

                if (!IEfound && !EdgeFound) {
                    var tabWindow = $window.open('about:blank', '_blank');
                    tabWindow.document.write('loading...');
                }
                requestManager.routes.record.getEmailAttachementAsPDF($rootScope.authData.sessionId, attachmentId).then(
                    function (response) {
                        var file = new Blob([response.data], {type: 'application/pdf'});

                        if (!IEfound && !EdgeFound) {
                            var fileURL = URL.createObjectURL(file);
                            tabWindow.location.href = fileURL;
                        }
                        if (IEfound || EdgeFound) {
                            let r = "";
                            for (let i = 0; i < 4; i++) {
                                if (i % 4 === 0)
                                    r += Math.random().toString(36).substring(8);
                                else {
                                    r += "-";
                                    r += Math.random().toString(36).substring(4);
                                }
                            }
                            $window.navigator.msSaveOrOpenBlob(file, r + ".pdf");
                        }
                    },
                    function () {
                        Notification.error("Fehler beim Laden des Anhangs! Bitte wenden Sie sich an den Support.");
                    }
                )
            }

            function getPhysicalMailAsPdf(recordDetailedId) {
                //check what browser the user is surfing on
                var ua = window.navigator.userAgent;
                var msie = ua.indexOf("MSIE ");
                var IEfound = false;

                if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./))  // If Internet Explorer
                {
                    IEfound = true;
                }

                if (!IEfound) {
                    var tabWindow = $window.open('about:blank', '_blank');
                    tabWindow.document.write('loading...');
                }

                requestManager.routes.recordPhysicalMail.getPhysicalMailPdf($rootScope.authData.sessionId, recordDetailedId).then(
                    function (response) {
                        var file = new Blob([response.data], {type: 'application/pdf'});
                        var fileURL = URL.createObjectURL(file);


                        if (!IEfound) {
                            tabWindow.location.href = fileURL;
                        }
                        if (IEfound) {
                            $window.navigator.msSaveOrOpenBlob(file);
                        }
                    },
                    function () {
                        Notification.error("Fehler beim Laden des Anhangs! Bitte wenden Sie sich an den Support.");
                    }
                );
            }

            function convertTimestampToDate(timestamp) {
                if (timestamp === 0 || timestamp == null) {
                    return "-";
                }
                var formated = moment.unix(timestamp);
                var outputDateFormat = "DD.MM.YYYY"
                return formated.format(outputDateFormat);
            }

            function convertTimestampToTime(timestamp) {
                var formated = moment.unix(timestamp);
                var outputDateFormat = "HH:mm:ss"
                return formated.format(outputDateFormat);
            }

            function pinComment(modalContent) {
                requestManager.routes.record.putCommentRecord($rootScope.authData.sessionId, modalContent.recordComment.id, {pinned: 1}).then(
                    () => {
                        Notification.success("Kommentar wurde angepinnt!");
                        $window.location.reload();
                    },
                    () => {
                        Notification.error("Fehler beim anpinnen dieses Kommentars");
                    }
                )
            }

            function unpinComment(modalContent) {
                requestManager.routes.record.putCommentRecord($rootScope.authData.sessionId, modalContent.id_record_comment, {pinned: 0}).then(
                    () => {
                        $window.location.reload();
                        Notification.success("Kommentar wurde entpinnt!");
                    },
                    () => {
                        Notification.error("Fehler beim entpinnen dieses Kommentars");
                    }
                )
            }
        }
    ]
});

angular.module('userRecordsV2', []);

angular.module('userRecordsV2').component('userRecordsV2', {
    templateUrl: 'app/components/userRecordsV2/userRecordsV2.template.html',
    controller: [
        "$scope",
        "requestManager",
        "$rootScope",
        "$route",
        "$location",
        "util",
        "$routeParams",
        "$filter",
        "sharedMemory",
        "$window",
        "$sce",
        "Notification",
        function ($scope, requestManager, $rootScope, $route, $location, util, $routeParams, $filter, sharedMemory, $window, $sce, Notification) {
            this.$onInit = function () {
                initVariables();
                initScope();

                getRecords();
                getPinnedComments();
            };

            function initVariables() {
                $scope.customerId = null;

                $scope.records = [];
                $scope.loading = true;
                $scope.paginator = {
                    items: [],
                    itemsPerPage: null,
                    itemsTotal: null,
                    pageCurrent: null,
                    pagesTotal: null,
                };
                $scope.filters = {
                    page: 1,
                    pageSize: 10,
                    order: "entryCreated",
                    direction: "desc",
                    searchTerm: null,
                    filter: `accountNumber eq ${$routeParams["id"]}`,
                };
                $scope.existsRecordToApprove = false;
                $scope.modalContent = undefined;
                $scope.isEditing = false;
                $scope.recordToEdit = {
                    id: undefined,
                    text: undefined
                };

                $scope.pinnedComments = [];
                $scope.pinnedCommentsLoading = true;
                $scope.pinnedCommentsPaginator = {
                    items: [],
                    itemsPerPage: null,
                    itemsTotal: null,
                    pageCurrent: null,
                    pagesTotal: null,
                };
                $scope.pinnedCommentsFilters = {
                    page: 1,
                    pageSize: 100,
                    order: "entryCreated",
                    direction: "desc",
                    searchTerm: null,
                    filter: `accountNumber eq ${$routeParams["id"]} and recordType eq record_comment and commentPinned eq 1`,
                };
            }

            function initScope() {
                $scope.onSearch = onSearch;
                $scope.firstPage = firstPage;
                $scope.lastPage = lastPage;
                $scope.nextPage = nextPage;
                $scope.previousPage = previousPage;
                $scope.updateApprovalStatus = updateApprovalStatus;
                $scope.openRecordModal = openRecordModal;
                $scope.closeModal = closeModal;
                $scope.onEditRecordComment = onEditRecordComment;
                $scope.stopEditingRecordComment = stopEditingRecordComment;
                $scope.editCommentText = editCommentText;
                $scope.deleteComment = deleteComment;
                $scope.pinComment = pinComment;
                $scope.getEmailAttachmentAsPdf = getEmailAttachmentAsPdf;
                $scope.getPhysicalMailAsPdf = getPhysicalMailAsPdf;
                $scope.convertTimestampToDate = convertTimestampToDate;
                $scope.convertTimestampToTime = convertTimestampToTime;
            }

            function getRecords() {
                $scope.loading = true;
                requestManager.routes.record.getRecords($rootScope.authData.sessionId, $scope.filters).then(
                    function (response) {
                        response.data.items = response.data.items.filter(d => {
                            if (d.recordType === 'record_customer_advisor_v2') {
                                if (d.userDidEntry) {
                                    return d;
                                }
                            } else {
                                return d;
                            }
                        });
                        $scope.paginator = response.data;
                        $scope.records = $scope.paginator.items;
                        $scope.existsRecordToApprove = false;
                        for (const record of $scope.records) {
                            if ((record.recordType === 'record_email' && !record.emailSent) || (record.recordType === 'record_physical_mail' && !record.physicalMailSent)) {
                                $scope.existsRecordToApprove = true;
                                break;
                            }
                        }
                        $scope.loading = false;
                    },
                    function () {
                        $scope.loading = false;
                    }
                );
            }

            function getPinnedComments() {
                $scope.pinnedCommentsLoading = true;
                requestManager.routes.record.getRecords($rootScope.authData.sessionId, $scope.pinnedCommentsFilters).then(
                    function (response) {
                        $scope.pinnedCommentsPaginator = response.data;
                        $scope.pinnedComments = $scope.pinnedCommentsPaginator.items;
                        $scope.pinnedCommentsLoading = false;
                    },
                    function () {
                        $scope.pinnedCommentsLoading = false;
                    }
                );
            }

            function onSearch() {
                $scope.filters.page = 1;
                getRecords();
            }

            function firstPage() {
                $scope.filters.page = 1;
                getRecords();
            }

            function lastPage() {
                $scope.filters.page = $scope.paginator.pagesTotal;
                getRecords();
            }

            function nextPage() {
                $scope.filters.page++;
                getRecords();
            }

            function previousPage() {
                $scope.filters.page--;
                getRecords();
            }

            function updateApprovalStatus(status, recordType, recordId, recordRecordId) {
                toggleProcessingModal(true);

                let params = {
                    approved: '' + status,
                    id: recordRecordId,
                    approvedBy: $rootScope.authData.userId
                };

                if (status === 0) {
                    requestManager.routes.record.deleteWholeRecord($rootScope.authData.sessionId, recordId).then(
                        function () {
                            toggleProcessingModal(false, function () {
                                $rootScope.amountUnapprovedDunnings -= 1;
                                $route.reload();
                                Notification.success($filter("translate")("emailList.SUCCESSFUL_UPDATED"));
                            });
                        },
                        function (response) {

                        }
                    )

                } else {
                    if (recordType === 'record_email') {
                        requestManager.routes.recordEmail.updateRecordEmail($rootScope.authData.sessionId, params).then(
                            function () {
                                toggleProcessingModal(false, function () {
                                    $rootScope.amountUnapprovedDunnings -= 1;
                                    $route.reload();
                                    Notification.success($filter("translate")("emailList.SUCCESSFUL_UPDATED"));
                                });
                            },
                            function (response) {
                            }
                        );
                    } else {
                        requestManager.routes.recordPhysicalMail.updatePhysicalMail($rootScope.authData.sessionId, params).then(
                            function () {
                                toggleProcessingModal(false, function () {
                                    $route.reload();
                                    Notification.success($filter("translate")("physicalMailApprovalList.SUCCESSFUL_UPDATED"));
                                });
                            },
                            function (response) {
                            }
                        );
                    }
                }
            }

            function toggleProcessingModal(showModal, callback) {
                if (showModal) {
                    jQuery("#processingModalV2").modal("show");
                } else {
                    if (callback) {
                        jQuery("#processingModalV2").one("hidden.bs.modal", callback).modal("hide");
                    } else {
                        jQuery("#processingModalV2").modal("hide");
                    }
                }
            }

            function onEditRecordComment() {
                $scope.recordToEdit.id = $scope.modalContent.commentId;
                $scope.recordToEdit.text = $scope.modalContent.commentText;
                $scope.isEditing = true;
            }

            function stopEditingRecordComment() {
                $scope.recordToEdit.id = undefined;
                $scope.recordToEdit.text = undefined;
                $scope.isEditing = false;
            }

            function editCommentText() {
                requestManager.routes.record.putCommentRecord($rootScope.authData.sessionId, $scope.recordToEdit.id, {text: $scope.recordToEdit.text}).then(
                    function () {
                        window.location.reload();
                    },
                    function () {
                        Notification.error("Änderung konnte nicht gespeichert werden.");
                    }
                );
            }

            function openRecordModal(combinedRecord) {
                $scope.modalContent = combinedRecord;
                $scope.isEditing = false;
                switch (combinedRecord.recordType) {
                    case "record_phone":
                        jQuery("#recordPhoneModalV2").modal("show");
                        break;
                    case "record_customer_advisor":
                    case "record_customer_advisor_v2":
                        jQuery("#recordCustomerAdvisorModalV2").modal("show");
                        break;
                    case "record_email":
                        jQuery("#recordEmailModalV2").modal("show");
                        break;
                    case "record_comment":
                        jQuery("#recordCommentModalV2").modal("show");
                        break;
                    case "record_email_reply":
                        jQuery("#recordEmailReplyModalV2").modal("show");
                        break;
                    case "record_physical_mail":
                        jQuery("#recordPhysicalMailModalV2").modal("show");
                        break;
                    case "record_inkasso":
                        jQuery("#recordInkassoModalV2").modal("show");
                        break;
                }
            }

            function deleteComment(record) {
                if (record.id && record.commentId) {
                    requestManager.routes.record.deleteWholeRecord($rootScope.authData.sessionId, record.id);
                    requestManager.routes.record.deleteCommentRecord($rootScope.authData.sessionId, record.commentId).then(
                        function () {
                            window.location.reload();
                        },
                        function () {
                            Notification.error($filter("translate")("userSettings.ERROR_403_USER_NEED_APPROPRIATE_PERMISSION"));
                        }
                    );
                } else {
                    requestManager.routes.record.deleteWholeRecord($rootScope.authData.sessionId, record.id).then(
                        function () {
                            window.location.reload();
                        }
                    )
                }
            }

            function closeModal(modalId) {
                $scope.modalContent = undefined;
                jQuery("#" + modalId).modal("hide");
            }

            function getEmailAttachmentAsPdf(attachmentId) {
                //check what browser the user is surfing on
                var ua = window.navigator.userAgent;
                var msie = ua.indexOf("MSIE ");
                var IEfound = false;
                var EdgeFound = /Edge/.test(window.navigator.userAgent);

                if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./))  // If Internet Explorer
                {
                    IEfound = true;
                }

                if (!IEfound && !EdgeFound) {
                    var tabWindow = $window.open('about:blank', '_blank');
                    tabWindow.document.write('loading...');
                }
                requestManager.routes.record.getEmailAttachementAsPDF($rootScope.authData.sessionId, attachmentId).then(
                    function (response) {
                        var file = new Blob([response.data], {type: 'application/pdf'});

                        if (!IEfound && !EdgeFound) {
                            var fileURL = URL.createObjectURL(file);
                            tabWindow.location.href = fileURL;
                        }
                        if (IEfound || EdgeFound) {
                            let r = "";
                            for (let i = 0; i < 4; i++) {
                                if (i % 4 === 0)
                                    r += Math.random().toString(36).substring(8);
                                else {
                                    r += "-";
                                    r += Math.random().toString(36).substring(4);
                                }
                            }
                            $window.navigator.msSaveOrOpenBlob(file, r + ".pdf");
                        }
                    },
                    function () {
                        Notification.error("Fehler beim Laden des Anhangs! Bitte wenden Sie sich an den Support.");
                    }
                )
            }

            function getPhysicalMailAsPdf(recordDetailedId) {
                //check what browser the user is surfing on
                var ua = window.navigator.userAgent;
                var msie = ua.indexOf("MSIE ");
                var IEfound = false;

                if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./))  // If Internet Explorer
                {
                    IEfound = true;
                }

                if (!IEfound) {
                    var tabWindow = $window.open('about:blank', '_blank');
                    tabWindow.document.write('loading...');
                }

                requestManager.routes.recordPhysicalMail.getPhysicalMailPdf($rootScope.authData.sessionId, recordDetailedId).then(
                    function (response) {
                        var file = new Blob([response.data], {type: 'application/pdf'});
                        var fileURL = URL.createObjectURL(file);


                        if (!IEfound) {
                            tabWindow.location.href = fileURL;
                        }
                        if (IEfound) {
                            $window.navigator.msSaveOrOpenBlob(file);
                        }
                    },
                    function () {
                        Notification.error("Fehler beim Laden des Anhangs! Bitte wenden Sie sich an den Support.");
                    }
                );
            }

            function convertTimestampToDate(timestamp) {
                if (timestamp === 0 || timestamp == null) {
                    return "-";
                }
                var formated = moment.unix(timestamp);
                var outputDateFormat = "DD.MM.YYYY"
                return formated.format(outputDateFormat);
            }

            function convertTimestampToTime(timestamp) {
                var formated = moment.unix(timestamp);
                var outputDateFormat = "HH:mm:ss"
                return formated.format(outputDateFormat);
            }

            function pinComment(modalContent) {
                const body = {
                    pinned: modalContent.commentPinned ? 0 : 1
                };
                requestManager.routes.record.putCommentRecord($rootScope.authData.sessionId, modalContent.commentId, body).then(
                    () => {
                        Notification.success("Kommentar wurde angepinnt!");
                        $window.location.reload();
                    },
                    () => {
                        Notification.error("Fehler beim anpinnen dieses Kommentars");
                    }
                )
            }

        }
    ]
});

angular.module('generalSettings', []);
angular.module('generalSettings').component('generalSettings', {
    templateUrl: 'app/components/generalSettings/generalSettings.template.html',
    bindings: {
        settings: "<"
    },
    controller: [
        "requestManager",
        "$scope",
        "$rootScope",
        "$location",
        "util",
        "Notification",
        "$filter",
        "$window",
        "sharedMemory",
        "bookstackApi",
        function (requestManager, $scope, $rootScope, $location, util, Notification, $filter, $window, sharedMemory, bookstackApi) {
            init();

            function init(){
                initVariables();
                initScope();
                getBookstackText(15, 'bkmrk-wenn-du-den-erweiter', 'advancedSettings');
            }

            this.$onChanges = function set(item){
                if(item.settings.currentValue){
                    $scope.settings.content = item.settings.currentValue;
                    $scope.isTaxFirm = util.getSettingByName($scope.settings.content, "isTaxFirm");
                    $scope.isCompany = util.getSettingByName($scope.settings.content, "isCompany");
                    $scope.settings.currentlyLoading = false;
                }
                if(!$scope.settings.currentlyLoading){
                    $scope.view.allDependenciesLoaded = true;
                    initSettingsForm();
                }
            };

            function initScope(){
                $scope.saveChanges = onSaveChangesClick;
            }

            function initSettingsForm(){
                if (($scope.isTaxFirm !== undefined && $scope.isTaxFirm.settingValue1 == '1') || ($scope.isCompany !== undefined && $scope.isCompany.settingValue1 == '1')) {
                    $scope.settingsFormModel = {
                        companyName: util.getSettingByName($scope.settings.content, "companyName"),
                        companyAddress: util.getSettingByName($scope.settings.content, "companyAddress"),
                        companyZip: util.getSettingByName($scope.settings.content, "companyZip"),
                        companyCity: util.getSettingByName($scope.settings.content, "companyCity"),
                        companyCountry: util.getSettingByName($scope.settings.content, "companyCountry"),
                        companyPhone: util.getSettingByName($scope.settings.content, "companyPhone"),
                        companyMail: util.getSettingByName($scope.settings.content, "companyMail"),
                        companyLogo: util.getSettingByName($scope.settings.content, "companyLogo"),
                        advancedSettings: util.getSettingByName($scope.settings.content, "advancedSettings"),
                        companyVat: util.getSettingByName($scope.settings.content, "companyVat"),
                        companyMailInvoices: util.getSettingByName($scope.settings.content, "companyMailInvoices"),
                    }
                } else {
                    $scope.settingsFormModel = {
                        companyName: util.getSettingByName($scope.settings.content, "companyName"),
                        companyAddress: util.getSettingByName($scope.settings.content, "companyAddress"),
                        companyZip: util.getSettingByName($scope.settings.content, "companyZip"),
                        companyCity: util.getSettingByName($scope.settings.content, "companyCity"),
                        companyCountry: util.getSettingByName($scope.settings.content, "companyCountry"),
                        companyPhone: util.getSettingByName($scope.settings.content, "companyPhone"),
                        companyMail: util.getSettingByName($scope.settings.content, "companyMail"),
                        companyLogo: util.getSettingByName($scope.settings.content, "companyLogo"),
                        advancedSettings: util.getSettingByName($scope.settings.content, "advancedSettings"),
                    }
                }
            }

            function initVariables(){
                $scope.settings = {
                    loaded: false,
                    content: {}
                };
                $scope.isTaxFirm = undefined;
                $scope.isCompany = undefined;
                $scope.settingsFormModel = {
                    companyName: undefined,
                    address: undefined,
                    zip: undefined,
                    city: undefined,
                    country: undefined,
                    phone: undefined,
                    email: undefined,
                    logo: undefined,
                    advancedSettings: undefined
                };
                $scope.view = {
                    allDependenciesLoaded: false
                };
                $scope.advancedSettingsInfoText = {
                    error: false,
                    errorMessage: undefined,
                    currentlyLoading: false,
                    content: undefined
                };
            }

            function saveChanges(callback){
                var params;
                var requestDetails = {
                    numRequests: Object.keys($scope.settingsFormModel).length,
                    numRequestsLoaded: 0,
                    numErrors: 0,
                    numSuccess: 0,
                    responses: [],
                    allSuccessful: true,
                    canceledRequest: false
                };

                angular.forEach($scope.settingsFormModel, function(setting, key){

                    if(key === "companyLogo"){
                        params = {
                            id: setting.id,
                            settingCode: setting.settingCode,
                            file: setting.file
                        };
                    }
                    else{
                        params = {
                            id: setting.id,
                            settingCode: setting.settingCode,
                            settingValue1: setting.settingValue1
                        };
                    }


                    requestManager.routes.settings.editSettingWithKey($rootScope.authData.sessionId, params).then(
                        function(response){
                            requestDetails.responses = response;
                            requestDetails.numRequestsLoaded++;
                            requestDetails.numSuccess++;
                            if(requestDetails.numRequestsLoaded == requestDetails.numRequests){
                                callback(requestDetails);
                            }
                        },
                        function(response){
                            requestDetails.responses = response;
                            requestDetails.numRequestsLoaded++;
                            requestDetails.numErrors++;
                            requestDetails.allSuccessful = false;
                            if(!requestDetails.canceledRequest){
                                requestDetails.canceledRequest = response.status == -1;
                            }
                            if(requestDetails.numRequestsLoaded == requestDetails.numRequests){
                                callback(requestDetails);
                            }
                        }
                    )
                });
            }

            function onSaveChangesClick(){
                if($scope.settingsForm.$invalid){
                    return;
                }

                var logo = jQuery('#logo').prop('files')[0];
                if(logo) {
                    util.convertFileToBase64(logo).then(
                        onLogoConverted,
                        onLogoConversionFailed
                    );
                }
                else{
                    onLogoConverted(undefined);
                }

                function onLogoConversionFailed(error){
                    console.log("conversion failed", error);
                }

                function onLogoConverted(base64Logo){
                    if(base64Logo){
                        $scope.settingsFormModel.companyLogo.file = base64Logo;
                    }

                    if($window.confirm($filter("translate")("generalSettings.SAVE_CHANGES_CONFIRM"))){
                        saveChanges(function(details){
                            if(details.allSuccessful){
                                Notification.success($filter("translate")("generalSettings.SAVE_SUCCESSFUL"));
                                $window.location.reload();// required elsewhere in the program; added to properly display PIN AG for users from Germany
                            }
                            else{
                                if (!details.canceledRequest) {
                                    sharedMemory.error.setError(
                                        details,
                                        "COULD_NOT_LOAD_DATA",
                                        "Fehler beim editieren der settings (pro setting ein Request)",
                                        $location.path(),
                                        "generalHintEditor"
                                    );
                                    $location.path("/error-page");
                                }
                            }
                        });
                    }
                }
            }


            function getBookstackText(pageId, elementId, type) {
                if (type === 'advancedSettings') {
                    $scope.advancedSettingsInfoText.currentlyLoading = true;
                    bookstackApi.fetchBookstack(pageId, elementId).then(
                        function (response) {
                            // if the response contains some property then the request was unsuccessful
                            if (response.hasOwnProperty('status')) {
                                $scope.advancedSettingsInfoText.error = true;
                                $scope.advancedSettingsInfoText.currentlyLoading = false;
                                $scope.advancedSettingsInfoText.errorMessage = response.data.error.message;
                            } else {
                                if ($scope.advancedSettingsInfoText.content === undefined) {
                                    $scope.advancedSettingsInfoText.content = response;
                                } else {
                                    $scope.advancedSettingsInfoText.content += ' ';
                                    $scope.advancedSettingsInfoText.content += response;
                                }
                                $scope.advancedSettingsInfoText.currentlyLoading = false;
                                $scope.advancedSettingsInfoText.error = false;
                            }
                        }
                    );
                }
            }
        }
    ]
}); 

angular.module('pdfSettings', []);
angular.module('pdfSettings').component('pdfSettings', {
    templateUrl: 'app/components/pdfSettings/pdfSettings.template.html',
    bindings: {
        settings: "<"
    },
    controller: [
        "$scope",
        "Notification",
        "$rootScope",
        "$location",
        "$window",
        "requestManager",
        "util",
        "$filter",
        "sharedMemory",
        function ($scope, Notification, $rootScope, $location, $window, requestManager, util, $filter, sharedMemory) {
            init();

            function init() {
                initVariables();
                initScope();
            }

            this.$onChanges = function set(item) {
                if (item.settings.currentValue) {
                    $scope.settings.content = angular.copy(item.settings.currentValue);
                    $scope.settings.currentlyLoading = false;
                }
                if (!$scope.settings.currentlyLoading) {
                    $scope.view.allDependenciesLoaded = true;
                    setInputFields();
                }
            };

            function initScope() {
                $scope.saveChanges = saveChanges;
                $scope.toggleIsCalculateInterestOnLawyerReportOn = toggleIsCalculateInterestOnLawyerReportOn;
                $scope.toggleIsNonOverdueOpShow = toggleIsNonOverdueOpShow;
            }

            function initVariables() {
                $scope.settings = {
                    content: undefined,
                    currentlyLoading: true
                };
                $scope.inputFields = {
                    pdfMarginTop: undefined,
                    pdfMarginBottom: undefined,
                    pdfMarginLeft: undefined,
                    pdfMarginRight: undefined,
                    pdfMarginTopSecondPage: undefined,
                    admonitionPrintProperties: undefined,
                    nonOverdueOpShow: undefined,
                    nonOverdueOpNewPage: undefined,
                    calculateInterestOnLawyerReport: undefined
                };
                $scope.view = {
                    allDependenciesLoaded: false,
                }
            }

            function toggleIsNonOverdueOpShow() {
                // console.log($scope.inputFields.nonOverdueOpShow);
            }

            function loadAllSettings(callback) {
                var requestDetails = {
                    allSuccessful: true,
                    response: undefined
                };

                requestManager.routes.settings.getSettings($rootScope.authData.sessionId).then(
                    function (response) {
                        requestDetails.response = response;
                        callback(requestDetails);
                    },
                    function (response) {
                        requestDetails.allSuccessful = false;
                        requestDetails.response = response;
                        callback(requestDetails);
                    }
                )
            }

            function setInputFields() {
                $scope.inputFields.pdfMarginTop = util.getSettingByName($scope.settings.content, "pdfMarginTop");
                $scope.inputFields.pdfMarginBottom = util.getSettingByName($scope.settings.content, "pdfMarginBottom");
                $scope.inputFields.pdfMarginLeft = util.getSettingByName($scope.settings.content, "pdfMarginLeft");
                $scope.inputFields.pdfMarginRight = util.getSettingByName($scope.settings.content, "pdfMarginRight");
                $scope.inputFields.pdfMarginTopSecondPage = util.getSettingByName($scope.settings.content, "pdfMarginTopSecondPage");
                $scope.inputFields.admonitionPrintProperties = util.getSettingByName($scope.settings.content, "admonitionPrintProperties");
                $scope.inputFields.nonOverdueOpShow = util.getSettingByName($scope.settings.content, "nonOverdueOpShow");
                $scope.inputFields.nonOverdueOpNewPage = util.getSettingByName($scope.settings.content, "nonOverdueOpNewPage");
                $scope.inputFields.calculateInterestOnLawyerReport = util.getSettingByName($scope.settings.content, "calculateInterestOnLawyerReport");
            }

            function saveChanges() {
                console.log($scope.pdfSettingsForm);
                if ($scope.pdfSettingsForm.$invalid) {
                    Notification.error('Nicht alle Felder ausgefüllt!');
                    return;
                }

                var pdfTemplate = jQuery('#pdfTemplate').prop('files')[0];
                if (pdfTemplate !== undefined && pdfTemplate.size > 1048576) {
                    Notification.error($filter("translate")("pdfSettings.UPLOAD_NOT_POSSIBLE"));
                    return;
                }

                if ($window.confirm($filter("translate")("pdfSettings.CONFIRM_SAVE"))) {
                    sendAllRequests(function (requestDetails) {
                        if (requestDetails.allSuccessful) {
                            if (angular.isDefined(pdfTemplate)) {
                                sendUploadPdfTemplateRequest($rootScope.authData.sessionId, pdfTemplate, function (fileUploadRequestDetails) {
                                    if (fileUploadRequestDetails.successful) {
                                        Notification.success($filter("translate")("pdfSettings.CHANGED_SUCCESSFULLY"));
                                    }
                                    else {
                                        if (!requestDetails.canceledRequest) {
                                            sharedMemory.error.setError(
                                                fileUploadRequestDetails,
                                                "COULD_NOT_LOAD_DATA",
                                                "Fehler beim Upload des PDF-Templates",
                                                $location.path(),
                                                "pdfSettings"
                                            );
                                            $location.path("/error-page");
                                        }
                                    }
                                });
                            }
                            else {
                                Notification.success($filter("translate")("pdfSettings.CHANGED_SUCCESSFULLY"));
                                $window.location.reload();
                            }
                        }
                        else {
                            if (!requestDetails.canceledRequest) {
                                sharedMemory.error.setError(
                                    requestDetails,
                                    "COULD_NOT_LOAD_DATA",
                                    "Fehler beim Editieren der Settings ",
                                    $location.path(),
                                    "pdfSettings"
                                );
                                $location.path("/error-page");
                            }
                        }
                    });
                }
            }

            function sendAllRequests(callback) {
                var requestDetails = {
                    numRequests: Object.keys($scope.inputFields).length,
                    numRequestsLoaded: 0,
                    numErrors: 0,
                    numSuccess: 0,
                    allSuccessful: true,
                    responses: [],
                    canceledRequest: false
                };

                angular.forEach($scope.inputFields, function (setting) {
                    var params = {
                        settingCode: setting.settingCode,
                        settingValue1: setting.settingValue1,
                        id: setting.id
                    };
                    sendEditSettingRequest(params, function (editSettingRequestDetails) {
                        if (editSettingRequestDetails.allSuccessful) {
                            requestDetails.numRequestsLoaded++;
                            requestDetails.numSuccess++;
                            requestDetails.responses.push(editSettingRequestDetails.response);
                            if (requestDetails.numRequestsLoaded == requestDetails.numRequests) {
                                callback(requestDetails);
                            }
                        }
                        else {
                            requestDetails.numRequestsLoaded++;
                            requestDetails.numErrors++;
                            requestDetails.allSuccessful = false;
                            requestDetails.responses.push(editSettingRequestDetails.response);
                            if (!requestDetails.canceledRequest) {
                                requestDetails.canceledRequest = response.status == -1;
                            }
                            if (requestDetails.numRequestsLoaded == requestDetails.numRequests) {
                                callback(requestDetails);
                            }
                        }
                    });
                });
            }

            function sendEditSettingRequest(params, callback) {
                var requestDetails = {
                    numRequests: 0, //TODO change this if error happens
                    numRequestsLoaded: 0,
                    numErrors: 0,
                    numSuccess: 0,
                    allSuccessful: true,
                    response: undefined
                };

                requestManager.routes.settings.editSettingWithKey($rootScope.authData.sessionId, params).then(
                    function (response) {
                        requestDetails.numRequestsLoaded++;
                        requestDetails.numSuccess++;
                        requestDetails.response = response;
                        callback(requestDetails);
                    },
                    function (response) {
                        requestDetails.numRequestsLoaded++;
                        requestDetails.numErrors++;
                        requestDetails.allSuccessful = false;
                        requestDetails.response = response;
                        callback(requestDetails);
                    }
                )
            }

            function sendUploadPdfTemplateRequest(sessionId, file, callback) {
                var requestDetails = {
                    response: undefined,
                    successful: true,
                    canceledRequest: false
                };

                requestManager.routes.record.uploadPdfTemplate(sessionId, file).then(
                    function (response) {
                        requestDetails.response = response;
                        callback(requestDetails);
                    },
                    function (response) {
                        requestDetails.response = response;
                        requestDetails.successful = false;
                        requestDetails.canceledRequest = response.status == -1;
                        callback(requestDetails);
                    }
                )
            }

            function toggleIsCalculateInterestOnLawyerReportOn(){
                console.log($scope.inputFields);
            }
        }
    ]
}); 

angular.module('systemSettings', []);
angular.module('systemSettings').component('systemSettings', {
    templateUrl: 'app/components/systemSettings/systemSettings.template.html',
    bindings: {
        settings: "<",
        userList: "<"
    },
    controller: [
        "$scope",
        "Notification",
        "$rootScope",
        "$location",
        "$window",
        "requestManager",
        "util",
        "$filter",
        "sharedMemory",
        "$route",
        "bookstackApi",
        function ($scope, Notification, $rootScope, $location, $window, requestManager, util, $filter, sharedMemory, $route, bookstackApi) {
            init();

            function init() {
                initVariables();
                initScope();
                getBookstackText(22, 'bkmrk-wenn-diese-funktion-', 'directMailApprove');
                getBookstackText(22, 'bkmrk-wenn-diese-funktion--0', 'mailApprove');
                getBookstackText(22, 'bkmrk-achtung%3A-wenn-du-die', 'mailApprove');
            }

            this.$onChanges = function set(item) {
                if (item && item.settings && item.settings.currentValue) {
                    $scope.settings.content = angular.copy(item.settings.currentValue);
                    $scope.settings.currentlyLoading = false;
                    $scope.settings.systemSettingsSortedByDunningLevel = getFilteredAndSortedSettings($scope.settings.content);
                }
                if (item && item.userList && item.userList.currentValue) {
                    $scope.userList.content = angular.copy(item.userList.currentValue).filter(function (user) {
                        return user.id !== "0" && user.disabled !== "1"
                    });
                    let list = angular.copy($scope.userList.content);
                    list.forEach(function (user) {
                        if (user.administrator === "1") {
                            $scope.approvalStatusList.admin.push(user);
                            return;
                        }
                        if (user.approvalStatus === "0" || !user.approvalStatus) {
                            $scope.approvalStatusList.disabled.push(user);
                            $scope.approvalStatusList.all.push(user);
                        }
                        if (user.approvalStatus === "1") {
                            $scope.approvalStatusList.enabled.push(user);
                            $scope.approvalStatusList.all.push(user);
                        }
                    });
                    $scope.userList.currentlyLoading = false;
                }
                if (!$scope.settings.currentlyLoading && !$scope.userList.currentlyLoading) {
                    $scope.view.allDependenciesLoaded = true;
                }
            };

            function sendCreateSettingRequest(sessionId, params, callback) {
                var requestDetails = {
                    successful: true,
                    response: undefined
                };

                requestManager.routes.settings.createSettingWithKey(sessionId, params).then(
                    function(response) {
                        requestDetails.response = response;
                        callback(requestDetails);
                    },
                    function(response) {
                        requestDetails.response = response;
                        requestDetails.successful = false;
                        callback(requestDetails);
                    }
                )
            }

            function getFilteredAndSortedSettings(settings) {
                var systemSettings = [];

                systemSettings = systemSettings.concat(util.getSettingsWithMatchingRegex(settings, /dunningCharges/));
                systemSettings = systemSettings.concat(util.getSettingsWithMatchingRegex(settings, /dunningInterest/));
                systemSettings = systemSettings.concat(util.getSettingsWithMatchingRegex(settings, /numDaysUntilAdmonition/));
                systemSettings = systemSettings.concat(util.getSettingsWithMatchingRegex(settings, /mailApprovalActivated/));
                systemSettings = systemSettings.concat(util.getSettingsWithMatchingRegex(settings, /paymentAgreementInterestRate/));
                systemSettings = systemSettings.concat(util.getSettingsWithMatchingRegex(settings, /accountLengthImpersonal/));
                systemSettings = systemSettings.concat(util.getSettingsWithMatchingRegex(settings, /unclearedPaymentsAsPositionList/));
                systemSettings = systemSettings.concat(util.getSettingsWithMatchingRegex(settings, /amountRemindersOnDemandNote/));
                systemSettings = systemSettings.concat(util.getSettingsWithMatchingRegex(settings, /showCurrencyCodes/));
                systemSettings = systemSettings.concat(util.getSettingsWithMatchingRegex(settings, /europeanNumberFormat/));
                systemSettings = systemSettings.concat(util.getSettingsWithMatchingRegex(settings, /reasonForPauseOfDemands/));
                systemSettings = systemSettings.concat(util.getSettingsWithMatchingRegex(settings, /wysiwygEditorActivated/));
                systemSettings = systemSettings.concat(util.getSettingsWithMatchingRegex(settings, /bookInDatev/));
                systemSettings = systemSettings.concat(util.getSettingsWithMatchingRegex(settings, /bookDueDateChangesInDatev/));
                systemSettings = systemSettings.concat(util.getSettingsWithMatchingRegex(settings, /transferOfUnallocatedCredits/));
                systemSettings = systemSettings.concat(util.getSettingsWithMatchingRegex(settings, /accountForBookings/));
                systemSettings = systemSettings.concat(util.getSettingsWithMatchingRegex(settings, /pdfMarginLeft/));
                systemSettings = systemSettings.concat(util.getSettingsWithMatchingRegex(settings, /pdfMarginRight/));
                systemSettings = systemSettings.concat(util.getSettingsWithMatchingRegex(settings, /pdfMarginTopSecondPage/));
                systemSettings = systemSettings.concat(util.getSettingsWithMatchingRegex(settings, /advancedSettings/));
                systemSettings = systemSettings.concat(util.getSettingsWithMatchingRegex(settings, /nonOverdueOpShow/));
                systemSettings = systemSettings.concat(util.getSettingsWithMatchingRegex(settings, /invoiceFilterDate/));
                // TODO unclear payments
                // systemSettings = systemSettings.concat(util.getSettingsWithMatchingRegex(settings, /collectiveAccount/));

                if (util.getSettingsWithMatchingRegex(settings, /europeanNumberFormat/).length === 0) {
                    sendCreateSettingRequest($rootScope.authData.sessionId, {
                        settingCode: 'europeanNumberFormat',
                        settingValue1: '0'
                    }, function(requestDetails) {
                        if (requestDetails.successful) {
                            $route.reload();
                        } else {
                            sharedMemory.error.setError(
                                requestDetails,
                                "COULD_NOT_LOAD_DATA",
                                "Fehler beim erstellen eines neuen Settings (Europäisches Zahlenformat)",
                                $location.path(),
                                "systemSettings"
                            );
                            $location.path("/error-page");
                        }
                    });
                }

                if (util.getSettingsWithMatchingRegex(settings, /reasonForPauseOfDemands/).length === 0) {
                    sendCreateSettingRequest($rootScope.authData.sessionId, {
                        settingCode: 'reasonForPauseOfDemands',
                        settingValue1: '0'
                    }, function(requestDetails) {
                        if (requestDetails.successful) {
                            window.location.reload();
                        } else {
                            sharedMemory.error.setError(
                                requestDetails,
                                "COULD_NOT_LOAD_DATA",
                                "Fehler beim erstellen eines neuen Settings (Bei Forderungspausierung einen Grund abfragen)",
                                $location.path(),
                                "systemSettings"
                            );
                            $location.path("/error-page");
                        }
                    });
                }

                if (util.getSettingsWithMatchingRegex(settings, /wysiwygEditorActivated/).length === 0) {
                    sendCreateSettingRequest($rootScope.authData.sessionId, {
                        settingCode: 'wysiwygEditorActivated',
                        settingValue1: '0'
                    }, function(requestDetails) {
                        if (requestDetails.successful) {
                            $route.reload();
                        } else {
                            sharedMemory.error.setError(
                                requestDetails,
                                "COULD_NOT_LOAD_DATA",
                                "Fehler beim erstellen eines neuen Settings (Allgemeines Feld mit Schriftgröße und Schriftart auswählen)",
                                $location.path(),
                                "systemSettings"
                            );
                            $location.path("/error-page");
                        }
                    });
                }

                if (util.getSettingsWithMatchingRegex(settings, /bookInDatev/).length === 0) {
                    sendCreateSettingRequest($rootScope.authData.sessionId, {
                        settingCode: 'bookInDatev',
                        settingValue1: '0'
                    }, function(requestDetails) {
                        if (requestDetails.successful) {
                            window.location.reload();
                        } else {
                            sharedMemory.error.setError(
                                requestDetails,
                                "COULD_NOT_LOAD_DATA",
                                "Fehler beim erstellen eines neuen Settings (In DATEV übertragen)",
                                $location.path(),
                                "systemSettings"
                            );
                            $location.path("/error-page");
                        }
                    });
                }

                if (util.getSettingsWithMatchingRegex(settings, /bookDueDateChangesInDatev/).length === 0) {
                    sendCreateSettingRequest($rootScope.authData.sessionId, {
                        settingCode: 'bookDueDateChangesInDatev',
                        settingValue1: '0'
                    }, function(requestDetails) {
                        if (requestDetails.successful) {
                            window.location.reload();
                        } else {
                            sharedMemory.error.setError(
                                requestDetails,
                                "COULD_NOT_LOAD_DATA",
                                "Fehler beim erstellen eines neuen Settings (Änderung von Fälligkeiten in DATEV buchen)",
                                $location.path(),
                                "systemSettings"
                            );
                            $location.path("/error-page");
                        }
                    });
                }

                if (util.getSettingsWithMatchingRegex(settings, /transferOfUnallocatedCredits/).length === 0) {
                    sendCreateSettingRequest($rootScope.authData.sessionId, {
                        settingCode: 'transferOfUnallocatedCredits',
                        settingValue1: '0'
                    }, function(requestDetails) {
                        if (requestDetails.successful) {
                            window.location.reload();
                        } else {
                            sharedMemory.error.setError(
                                requestDetails,
                                "COULD_NOT_LOAD_DATA",
                                "Fehler beim erstellen eines neuen Settings (Umbuchung von Unzugeordneten Gutschriften)",
                                $location.path(),
                                "systemSettings"
                            );
                            $location.path("/error-page");
                        }
                    });
                }

                if (util.getSettingsWithMatchingRegex(settings, /accountForBookings/).length === 0) {
                    sendCreateSettingRequest($rootScope.authData.sessionId, {
                        settingCode: 'accountForBookings'
                    }, function(requestDetails) {
                        if (requestDetails.successful) {
                            window.location.reload();
                        } else {
                            sharedMemory.error.setError(
                                requestDetails,
                                "COULD_NOT_LOAD_DATA",
                                "Fehler beim erstellen eines neuen Settings (Konto für Buchungen)",
                                $location.path(),
                                "systemSettings"
                            );
                            $location.path("/error-page");
                        }
                    });
                }

                if (util.getSettingsWithMatchingRegex(settings, /companyVat/).length === 0) {
                    sendCreateSettingRequest($rootScope.authData.sessionId, {
                        settingCode: 'companyVat'
                    }, function(requestDetails) {
                        if (requestDetails.successful) {
                            window.location.reload();
                        } else {
                            sharedMemory.error.setError(
                                requestDetails,
                                "COULD_NOT_LOAD_DATA",
                                "Fehler beim erstellen eines neuen Settings (Konto für Buchungen)",
                                $location.path(),
                                "systemSettings"
                            );
                            $location.path("/error-page");
                        }
                    });
                }

                if (util.getSettingsWithMatchingRegex(settings, /companyMailInvoices/).length === 0) {
                    sendCreateSettingRequest($rootScope.authData.sessionId, {
                        settingCode: 'companyMailInvoices'
                    }, function(requestDetails) {
                        if (requestDetails.successful) {
                            window.location.reload();
                        } else {
                            sharedMemory.error.setError(
                                requestDetails,
                                "COULD_NOT_LOAD_DATA",
                                "Fehler beim erstellen eines neuen Settings (Konto für Buchungen)",
                                $location.path(),
                                "systemSettings"
                            );
                            $location.path("/error-page");
                        }
                    });
                }

                if (util.getSettingsWithMatchingRegex(settings, /pdfMarginLeft/).length === 0) {
                    sendCreateSettingRequest($rootScope.authData.sessionId, {
                        settingCode: 'pdfMarginLeft',
                        settingValue1: '0'
                    }, function(requestDetails) {
                        if (requestDetails.successful) {
                            window.location.reload();
                        } else {
                            sharedMemory.error.setError(
                                requestDetails,
                                "COULD_NOT_LOAD_DATA",
                                "Fehler beim erstellen eines neuen Settings (Seitenabstand Links)",
                                $location.path(),
                                "systemSettings"
                            );
                            $location.path("/error-page");
                        }
                    });
                }

                if (util.getSettingsWithMatchingRegex(settings, /pdfMarginRight/).length === 0) {
                    sendCreateSettingRequest($rootScope.authData.sessionId, {
                        settingCode: 'pdfMarginRight',
                        settingValue1: '0'
                    }, function(requestDetails) {
                        if (requestDetails.successful) {
                            window.location.reload();
                        } else {
                            sharedMemory.error.setError(
                                requestDetails,
                                "COULD_NOT_LOAD_DATA",
                                "Fehler beim erstellen eines neuen Settings (Seitenabstand Rechts)",
                                $location.path(),
                                "systemSettings"
                            );
                            $location.path("/error-page");
                        }
                    });
                }

                if (util.getSettingsWithMatchingRegex(settings, /pdfMarginTopSecondPage/).length === 0) {
                    sendCreateSettingRequest($rootScope.authData.sessionId, {
                        settingCode: 'pdfMarginTopSecondPage',
                        settingValue1: '0'
                    }, function(requestDetails) {
                        if (requestDetails.successful) {
                            window.location.reload();
                        } else {
                            sharedMemory.error.setError(
                                requestDetails,
                                "COULD_NOT_LOAD_DATA",
                                "Fehler beim erstellen eines neuen Settings (Seitenabstand Rechts)",
                                $location.path(),
                                "systemSettings"
                            );
                            $location.path("/error-page");
                        }
                    });
                }

                if (util.getSettingsWithMatchingRegex(settings, /advancedSettings/).length === 0) {
                    sendCreateSettingRequest($rootScope.authData.sessionId, {
                        settingCode: 'advancedSettings',
                        settingValue1: '0'
                    }, function(requestDetails) {
                        if (requestDetails.successful) {
                            window.location.reload();
                        } else {
                            sharedMemory.error.setError(
                                requestDetails,
                                "COULD_NOT_LOAD_DATA",
                                "Fehler beim erstellen eines neuen Settings (erweiterte Einstellungen)",
                                $location.path(),
                                "systemSettings"
                            );
                            $location.path("/error-page");
                        }
                    });
                }

                if (util.getSettingsWithMatchingRegex(settings, /nonOverdueOpShow/).length === 0) {
                    sendCreateSettingRequest($rootScope.authData.sessionId, {
                        settingCode: 'nonOverdueOpShow',
                        settingValue1: '1'
                    }, function(requestDetails) {
                        if (requestDetails.successful) {
                            window.location.reload();
                        } else {
                            sharedMemory.error.setError(
                                requestDetails,
                                "COULD_NOT_LOAD_DATA",
                                "Fehler beim erstellen eines neuen Settings (nicht überällige, aber offene Posten auf Mahnung ausweisen)",
                                $location.path(),
                                "systemSettings"
                            );
                            $location.path("/error-page");
                        }
                    });
                }

                if (util.getSettingsWithMatchingRegex(settings, /invoiceFilterDate/).length === 0) {
                    sendCreateSettingRequest($rootScope.authData.sessionId, {
                        settingCode: 'invoiceFilterDate',
                        settingValue1: '0'
                    }, function(requestDetails) {
                        if (requestDetails.successful) {
                            window.location.reload();
                        } else {
                            sharedMemory.error.setError(
                                requestDetails,
                                "COULD_NOT_LOAD_DATA",
                                "Fehler beim erstellen eines neuen Settings (nRechnungen vor einem bestimmten Datum bei Workflows und auf Mahnungen ausschließen)",
                                $location.path(),
                                "systemSettings"
                            );
                            $location.path("/error-page");
                        }
                    });
                }

                // TODO unclear payments
                // if (util.getSettingsWithMatchingRegex(settings, /collectiveAccount/).length === 0) {
                //     sendCreateSettingRequest($rootScope.authData.sessionId, {
                //         settingCode: 'collectiveAccount',
                //         settingValue1: '0'
                //     }, function(requestDetails) {
                //         if (requestDetails.successful) {
                //             window.location.reload();
                //         } else {
                //             sharedMemory.error.setError(
                //                 requestDetails,
                //                 "COULD_NOT_LOAD_DATA",
                //                 "Fehler beim erstellen eines neuen Settings (Unzugeordnete Zahlungen über Sammelkonto zuordnen)",
                //                 $location.path(),
                //                 "systemSettings"
                //             );
                //             $location.path("/error-page");
                //         }
                //     });
                // }

                var sortedSystemSettings = {
                    "dunningLevelOne": {
                        dunningCharges: undefined,
                        dunningInterest: undefined,
                    },
                    "dunningLevelTwo": {
                        dunningCharges: undefined,
                        dunningInterest: undefined
                    },
                    "dunningLevelThree": {
                        dunningCharges: undefined,
                        dunningInterest: undefined
                    },
                    "numDaysUntilAdmonition": undefined,
                    "mailApprovalActivated": undefined,
                    "paymentAgreementInterestRate": undefined,
                    "directEmailApprovalActivated": undefined,
                    "accountLengthImpersonal": undefined,
                    "unclearedPaymentsAsPositionList": undefined,
                    "amountRemindersOnDemandNote": undefined,
                    "showCurrencyCodes": undefined,
                    "europeanNumberFormat": undefined,
                    "reasonForPauseOfDemands": undefined,
                    "wysiwygEditorActivated": undefined,
                    "bookInDatev": undefined,
                    "bookDueDateChangesInDatev": undefined,
                    "transferOfUnallocatedCredits": undefined,
                    "accountForBookings": undefined,
                    "pdfMarginLeft": undefined,
                    "pdfMarginRight": undefined,
                    "pdfMarginTopSecondPage": undefined,
                    "advancedSettings": undefined,
                    "nonOverdueOpShow": undefined,
                    "invoiceFilterDate": undefined,
                    // TODO unclear payments
                    // "collectiveAccount": undefined,
                };

                systemSettings.forEach(function (setting) {
                    setting.updated = false;

                    if (setting.settingValue2 == "1") {
                        sortedSystemSettings.dunningLevelOne[setting.settingCode] = setting;
                    } else if (setting.settingValue2 == "2") {
                        sortedSystemSettings.dunningLevelTwo[setting.settingCode] = setting;
                    } else if (setting.settingValue2 == "3") {
                        sortedSystemSettings.dunningLevelThree[setting.settingCode] = setting;
                    } else {
                        switch (setting.settingCode) {
                            case "mailApprovalActivated":
                                sortedSystemSettings.mailApprovalActivated = setting;
                                break;
                            case "numDaysUntilAdmonition":
                                sortedSystemSettings.numDaysUntilAdmonition = setting;
                                break;
                            case "directEmailApprovalActivated":
                                sortedSystemSettings.directEmailApprovalActivated = setting;
                                break;
                            case "paymentAgreementInterestRate":
                                sortedSystemSettings.paymentAgreementInterestRate = setting;
                                sortedSystemSettings.paymentAgreementInterestRate['settingValue1'] = sortedSystemSettings.paymentAgreementInterestRate['settingValue1'].replace('.', ',');
                                break;
                            case "accountLengthImpersonal":
                                sortedSystemSettings.accountLengthImpersonal = setting;
                                break;
                            case "unclearedPaymentsAsPositionList":
                                sortedSystemSettings.unclearedPaymentsAsPositionList = setting;
                                break;
                            case "amountRemindersOnDemandNote":
                                sortedSystemSettings.amountRemindersOnDemandNote = setting;
                                break;
                            case "showCurrencyCodes":
                                sortedSystemSettings.showCurrencyCodes = setting;
                                break;
                            case "europeanNumberFormat":
                                sortedSystemSettings.europeanNumberFormat = setting;
                                break;
                            case "reasonForPauseOfDemands":
                                sortedSystemSettings.reasonForPauseOfDemands = setting;
                                break;
                            case "wysiwygEditorActivated":
                                sortedSystemSettings.wysiwygEditorActivated = setting;
                                break;
                            case "bookInDatev":
                                sortedSystemSettings.bookInDatev = setting;
                                break;
                            case "bookDueDateChangesInDatev":
                                sortedSystemSettings.bookDueDateChangesInDatev = setting;
                                break;
                            case "transferOfUnallocatedCredits":
                                sortedSystemSettings.transferOfUnallocatedCredits = setting;
                                break;
                            case "accountForBookings":
                                sortedSystemSettings.accountForBookings = setting;
                                break;
                            case "pdfMarginLeft":
                                sortedSystemSettings.pdfMarginLeft = setting;
                                break;
                            case "pdfMarginRight":
                                sortedSystemSettings.pdfMarginRight = setting;
                                break;
                            case "pdfMarginTopSecondPage":
                                sortedSystemSettings.pdfMarginTopSecondPage = setting;
                                break;
                            case "advancedSettings":
                                sortedSystemSettings.advancedSettings = setting;
                                break;
                            case "nonOverdueOpShow":
                                sortedSystemSettings.nonOverdueOpShow = setting;
                                break;
                            case "invoiceFilterDate":
                                const set = setting;
                                set.settingValue2 = new Date(set.settingValue2);
                                sortedSystemSettings.invoiceFilterDate = set;
                                break;
                            // TODO unclear payments
                            // case "collectiveAccount":
                            //     sortedSystemSettings.collectiveAccount = setting;
                            //     break;
                            default:
                                throw "settingCode not valid - Sorting settings not possible";
                        }
                    }
                });

                return sortedSystemSettings;
            }

            function initScope() {
                $scope.saveChanges = saveChanges;
                $scope.actualSettings = actualSettings;
                $scope.getBookstackText = getBookstackText;
                $scope.onReset = onReset;
            }

            function initVariables() {
                $scope.settings = {
                    loaded: false,
                    content: {}
                };
                $scope.userList = {
                    content: undefined,
                    currentlyLoading: true
                }
                $scope.systemSettingsFormModel = {
                    expenses: undefined,
                    numDaysUntilAdmonition: undefined,
                    accountLengthImpersonal: undefined,
                    expensesType: "PERCENT"
                };
                $scope.view = {
                    allDependenciesLoaded: false
                };
                $scope.approvalStatusList = {
                    enabled: [],
                    disabled: [],
                    admin: [],
                    all: []
                };
                $scope.directMailApproveInfoText = {
                    error: false,
                    errorMessage: undefined,
                    currentlyLoading: false,
                    content: undefined
                };
                $scope.mailApproveInfoText = {
                    error: false,
                    errorMessage: undefined,
                    currentlyLoading: false,
                    content: undefined
                };
            }

            function getBookstackText(pageId, elementId, type) {
                if (type === 'directMailApprove') {
                    $scope.directMailApproveInfoText.currentlyLoading = true;
                    bookstackApi.fetchBookstack(pageId, elementId).then(
                        function (response) {
                            // if the response contains some property then the request was unsuccessful
                            if (response.hasOwnProperty('status')) {
                                $scope.directMailApproveInfoText.error = true;
                                $scope.directMailApproveInfoText.currentlyLoading = false;
                                $scope.directMailApproveInfoText.errorMessage = response.data.error.message;
                            } else {
                                $scope.directMailApproveInfoText.content = response;
                                $scope.directMailApproveInfoText.currentlyLoading = false;
                                $scope.directMailApproveInfoText.error = false;
                            }
                        }
                    );
                } else if (type === 'mailApprove') {
                    $scope.mailApproveInfoText.currentlyLoading = true;
                    bookstackApi.fetchBookstack(pageId, elementId).then(
                        function (response) {
                            // if the response contains some property then the request was unsuccessful
                            if (response.hasOwnProperty('status')) {
                                $scope.mailApproveInfoText.error = true;
                                $scope.mailApproveInfoText.currentlyLoading = false;
                                $scope.mailApproveInfoText.errorMessage = response.data.error.message;
                            } else {
                                if ($scope.mailApproveInfoText.content === undefined) {
                                    $scope.mailApproveInfoText.content = response;
                                } else {
                                    $scope.mailApproveInfoText.content += ' ';
                                    $scope.mailApproveInfoText.content += response;
                                }
                                $scope.mailApproveInfoText.currentlyLoading = false;
                                $scope.mailApproveInfoText.error = false;
                            }
                        }
                    );
                }
            }

            function sendSaveChangesRequest(sessionId, paramsArray, callback) {
                var requestDetails = {
                    numRequestsPlanned: paramsArray.length,
                    successResponses: [],
                    errorResponses: [],
                    responses: [],
                    allSuccessful: true
                }

                if (paramsArray.length === 0) {
                    callback(requestDetails);
                    return;
                }

                angular.forEach(paramsArray, function (params) {
                    requestManager.routes.settings.editSettingWithKey(sessionId, params).then(
                        function (response) {
                            requestDetails.successResponses.push(response);
                            requestDetails.responses.push(response);
                            if (requestDetails.responses.length == requestDetails.numRequestsPlanned) {
                                callback(requestDetails);
                            }
                        },
                        function (response) {
                            requestDetails.errorResponses.push(response);
                            requestDetails.allSuccessful = false;
                            requestDetails.responses.push(response);
                            if (requestDetails.responses.length == requestDetails.numRequestsPlanned) {
                                callback(requestDetails);
                            }
                        }
                    )
                });
            }

            function sendUpdateUserRequests(sessionId, paramsArray, callback) {
                var requestDetails = {
                    numRequestsPlanned: paramsArray.length,
                    successResponses: [],
                    errorResponses: [],
                    responses: [],
                    allSuccessful: true
                }

                if (paramsArray.length === 0) {
                    callback(requestDetails);
                    return;
                }

                angular.forEach(paramsArray, function (params) {
                    requestManager.routes.user.editUser(sessionId, params).then(
                        function (response) {
                            requestDetails.successResponses.push(response);
                            requestDetails.responses.push(response);
                            if (requestDetails.responses.length == requestDetails.numRequestsPlanned) {
                                callback(requestDetails);
                            }
                        },
                        function (response) {
                            requestDetails.errorResponses.push(response);
                            requestDetails.allSuccessful = false;
                            requestDetails.responses.push(response);
                            if (requestDetails.responses.length == requestDetails.numRequestsPlanned) {
                                callback(requestDetails);
                            }
                        }
                    )
                });
            }

            function saveChanges() {
                if ($scope.systemSettingsForm.$invalid) {
                    return;
                }

                var updatedSettings = getUpdatedSettings($scope.settings.systemSettingsSortedByDunningLevel);
                var mailApprovalActivatedSetting = $scope.settings.content.find(function (setting) {
                    return setting.settingCode === "mailApprovalActivated"
                });
                var updatedUser = mailApprovalActivatedSetting.settingValue1 === "1" ? $scope.approvalStatusList.all.filter(function (user) {
                    return user.updated;
                }) : [];
                console.log('UPDATED:', updatedSettings);
                updatedSettings.forEach(setting => {
                    if (setting.settingCode === 'invoiceFilterDate') {
                        if (setting.settingValue1 === '0') {
                            setting.settingValue2 = null;
                        } else {
                            setting.settingValue2 = $filter('date')(setting.settingValue2, 'yyyy-MM-dd');
                        }
                    }
                });

                var userRequestParams = updatedUser.map(function (user) {
                    return {id: user.id, approvalStatus: user.approvalStatus}
                });
                var finishedRequests = 0;

                if ($window.confirm($filter("translate")("systemSettings.SAVE_CHANGES_CONFIRM"))) {
                    sendSaveChangesRequest($rootScope.authData.sessionId, updatedSettings, onEditSettingsRequestFinished);
                    sendUpdateUserRequests($rootScope.authData.sessionId, userRequestParams, onUpdateUserRequestFinished);
                }

                function onEditSettingsRequestFinished(details) {
                    if (details.allSuccessful) {
                        finishedRequests++;
                        checkFinishedStatus();
                    } else {
                        sharedMemory.error.setError(
                            details,
                            "COULD_NOT_LOAD_DATA",
                            "Fehler beim editieren der Settings (ein Request pro Setting)",
                            $location.path(),
                            "systemSettings"
                        );
                        $location.path("/error-page");
                    }
                }

                function onUpdateUserRequestFinished(details) {
                    if (details.allSuccessful) {
                        finishedRequests++;
                        checkFinishedStatus();
                    } else {
                        sharedMemory.error.setError(
                            details,
                            "COULD_NOT_LOAD_DATA",
                            "Fehler beim editieren der Settings (ein Request pro Setting)",
                            $location.path(),
                            "systemSettings"
                        );
                        $location.path("/error-page");
                    }
                }

                function checkFinishedStatus() {
                    if (finishedRequests === 2) {
                        Notification.success($filter("translate")("systemSettings.SAVE_SUCCESSFUL"));
                        $window.location.reload();
                    }
                }
            }

            function getUpdatedSettings(sortedSettings) {
                var updatedSettings = [];

                angular.forEach(sortedSettings.dunningLevelOne, function (setting) {
                    if (setting.updated) {
                        setting.settingValue1 = setting.settingValue1.replace(/\,/g, '.');
                        updatedSettings.push(setting);
                    }
                });
                angular.forEach(sortedSettings.dunningLevelTwo, function (setting) {
                    if (setting.updated) {
                        setting.settingValue1 = setting.settingValue1.replace(/\,/g, '.');
                        updatedSettings.push(setting);
                    }
                });
                angular.forEach(sortedSettings.dunningLevelThree, function (setting) {
                    if (setting.updated) {
                        setting.settingValue1 = setting.settingValue1.replace(/\,/g, '.');
                        updatedSettings.push(setting);
                    }
                });

                if (sortedSettings.numDaysUntilAdmonition.updated) {
                    updatedSettings.push(sortedSettings.numDaysUntilAdmonition);
                }

                if (sortedSettings.mailApprovalActivated.updated) {
                    updatedSettings.push(sortedSettings.mailApprovalActivated);
                }

                if (sortedSettings.directEmailApprovalActivated.updated) {
                    updatedSettings.push(sortedSettings.directEmailApprovalActivated);
                }

                if (sortedSettings.paymentAgreementInterestRate.updated) {
                    sortedSettings.paymentAgreementInterestRate['settingValue1'] = sortedSettings.paymentAgreementInterestRate['settingValue1'].replace(',', '.');
                    updatedSettings.push(sortedSettings.paymentAgreementInterestRate);
                }

                if (sortedSettings.accountLengthImpersonal.updated) {
                    updatedSettings.push(sortedSettings.accountLengthImpersonal);
                }
                if (sortedSettings.unclearedPaymentsAsPositionList.updated) {
                    updatedSettings.push(sortedSettings.unclearedPaymentsAsPositionList);
                }
                if (sortedSettings.amountRemindersOnDemandNote.updated) {
                    updatedSettings.push(sortedSettings.amountRemindersOnDemandNote);
                }

                if (sortedSettings.showCurrencyCodes.updated) {
                    updatedSettings.push(sortedSettings.showCurrencyCodes);
                }
                if (sortedSettings.europeanNumberFormat.updated) {
                    updatedSettings.push(sortedSettings.europeanNumberFormat);
                }
                if (sortedSettings.reasonForPauseOfDemands.updated) {
                    updatedSettings.push(sortedSettings.reasonForPauseOfDemands);
                }
                if (sortedSettings.wysiwygEditorActivated.updated) {
                    updatedSettings.push(sortedSettings.wysiwygEditorActivated);
                }

                if (sortedSettings.bookInDatev.updated) {
                    updatedSettings.push(sortedSettings.bookInDatev);
                }
                if (sortedSettings.bookDueDateChangesInDatev.updated) {
                    updatedSettings.push(sortedSettings.bookDueDateChangesInDatev);
                }
                if (sortedSettings.transferOfUnallocatedCredits.updated) {
                    updatedSettings.push(sortedSettings.transferOfUnallocatedCredits);
                }
                if (sortedSettings.accountForBookings.updated) {
                    updatedSettings.push(sortedSettings.accountForBookings);
                }
                if (sortedSettings.invoiceFilterDate.updated) {
                    updatedSettings.push(sortedSettings.invoiceFilterDate);
                }

                // TODO unclear payments
                // if (sortedSettings.collectiveAccount.updated) {
                //     if (sortedSettings.collectiveAccount.updated && sortedSettings.collectiveAccount['settingValue3'] !== null) {
                //         updatedSettings.push(sortedSettings.collectiveAccount);
                //     }
                // }

                if (sortedSettings)
                    return updatedSettings;
            }

            function actualSettings() {
                console.log($scope);
            }

            function onReset() {
                $location.path("/reset");
            }
        }
    ]
}); 

angular.module('importSettings', []);
angular.module('importSettings').component('importSettings', {
    templateUrl: 'app/components/importSettings/importSettings.template.html',
    bindings: {
        settings: "<"
    },
    controller: [
        "$scope",
        "$rootScope",
        "requestManager",
        "util",
        "$window",
        "$filter",
        "Notification",
        "sharedMemory",
        "$location",
        function ($scope, $rootScope, requestManager, util, $window, $filter, Notification, sharedMemory, $location) {
            init();

            function init() {
                initVariables();
                initScope();
            }

            this.$onChanges = function set(item) {
                if ($scope.settings.datevSettingInitial === undefined && item.settings.currentValue) {
                    setDatevSettingsInitial(item);
                    setDatevSettingsActual(item)
                } else if (item.settings.currentValue) {
                    setDatevSettingsActual(item);
                }
                if (!$scope.settings.currentlyLoading) {
                    $scope.view.allDependenciesLoaded = true;
                }
            };

            function initVariables() {
                $scope.settings = {
                    currentlyLoading: true,
                    content: undefined,
                    datevSettingsActual: {},
                    datevSettingInitial: undefined,
                };
                $scope.view = {
                    allDependenciesLoaded: false
                };
            }

            function initScope() {
                $scope.saveChanges = saveChanges;
                $scope.openInfoModal = openInfoModal;
            }

            function getSettings(sessionId, callback) {
                var requestDetails = {
                    allSuccessful: true,
                    response: undefined
                };

                requestManager.routes.settings.getSettings(sessionId).then(
                    function (response) {
                        requestDetails.response = response;
                        callback(requestDetails);
                    },
                    function (response) {
                        requestDetails.allSuccessful = false;
                        requestDetails.response = response;
                        callback(requestDetails);
                    }
                )
            }

            function sendEditSettingRequest(sessionId, paramsArray, callback) {
                var requestDetails = {
                    successfulResponses: [],
                    errorResponses: [],
                    responses: [],
                    numRequestsPlanned: paramsArray.length,
                    allSuccessful: true,
                    canceledRequest: false
                };

                paramsArray.forEach(function (params) {
                    requestManager.routes.settings.editSettingWithKey(sessionId, params).then(
                        function (response) {
                            requestDetails.successfulResponses.push(response);
                            requestDetails.responses.push(response);
                            if (requestDetails.numRequestsPlanned == requestDetails.responses.length) {
                                callback(requestDetails);
                            }
                        },
                        function (response) {
                            requestDetails.errorResponses.push(response);
                            requestDetails.allSuccessful = false;
                            requestDetails.responses.push(response);
                            if (!requestDetails.canceledRequest) {
                                requestDetails.canceledRequest = response.status == -1;
                            }
                            if (requestDetails.numRequestsPlanned == requestDetails.responses.length) {
                                callback(requestDetails);
                            }
                        }
                    )
                });
            }

            function compareInitialActualSettings(init, act) {
                return (init.datevConnectCredentials.settingValue1 === act.datevConnectCredentials.settingValue1 &&
                    init.datevConnectCredentials.settingValue2 === act.datevConnectCredentials.settingValue2 &&
                    init.datevConnectSource.settingValue1 === act.datevConnectSource.settingValue1 &&
                    init.datevConnectSource.settingValue2 === act.datevConnectSource.settingValue2 &&
                    init.datevConnectUrl.settingValue1 === act.datevConnectUrl.settingValue1 &&
                    init.datevConnectUrl.settingValue2 === act.datevConnectUrl.settingValue2);
            }

            function saveChanges() {
                const init = $scope.settings.datevSettingInitial;
                const act = $scope.settings.datevSettingsActual;

                if (!compareInitialActualSettings(init, act)) {
                    if ($scope.importSettingsForm.$invalid) {
                        return;
                    }

                    if ($window.confirm($filter("translate")("importSettings.CONFIRM_CHANGES"))) {
                        var paramsArray = [];

                        angular.forEach($scope.settings.datevSettings, function (setting) {
                            paramsArray.push({
                                id: setting.id,
                                settingCode: setting.settingCode,
                                settingValue1: setting.settingValue1,
                                settingValue2: setting.settingValue2
                            });
                        });

                        sendEditSettingRequest($rootScope.authData.sessionId, paramsArray, function (requestDetails) {
                            if (requestDetails.allSuccessful) {
                                Notification.success($filter("translate")("importSettings.CHANGE_SUCCESSFUL"))
                            }
                            else {
                                if (!requestDetails.canceledRequest) {
                                    sharedMemory.error.setError(
                                        requestDetails,
                                        "COULD_NOT_LOAD_DATA",
                                        "Fehler beim editieren der settings (pro setting ein Request)",
                                        $location.path(),
                                        "importSettings"
                                    );
                                    $location.path("/error-page");
                                }
                            }
                        });
                    }
                }


            }

            function openInfoModal() {
                jQuery("#importInfoModal").appendTo("body").modal("show");
            }

            function setDatevSettingsActual(item) {
                $scope.settings.content = angular.copy(item.settings.currentValue);
                $scope.settings.currentlyLoading = false;
                $scope.settings.datevSettingsActual.datevConnectUrl = util.getSettingByName($scope.settings.content, "datevConnectUrl");
                $scope.settings.datevSettingsActual.datevConnectCredentials = util.getSettingByName($scope.settings.content, "datevConnectCredentials");
                $scope.settings.datevSettingsActual.datevConnectSource = util.getSettingByName($scope.settings.content, "datevConnectSource");
            }

            function setDatevSettingsInitial(item) {
                $scope.settings.datevSettingInitial = {};
                $scope.settings.content = angular.copy(item.settings.currentValue);
                $scope.settings.currentlyLoading = false;
                $scope.settings.datevSettingInitial.datevConnectUrl = util.getSettingByName($scope.settings.content, "datevConnectUrl");
                $scope.settings.datevSettingInitial.datevConnectCredentials = util.getSettingByName($scope.settings.content, "datevConnectCredentials");
                $scope.settings.datevSettingInitial.datevConnectSource = util.getSettingByName($scope.settings.content, "datevConnectSource");
            }
        }
    ]
}); 

angular.module('translationSettings', []);
angular.module('translationSettings').component('translationSettings', {
	templateUrl: 'app/components/translationSettings/translationSettings.template.html',
	bindings: {
		settings: "<",
		translations: "<"
	},
	controller: [
		"$scope",
		"$rootScope",
		"requestManager",
		"$location",
		"sharedMemory",
		"util",
		"$filter",
		"Notification",
		"$route",
		"$window",
		function($scope, $rootScope, requestManager, $location, sharedMemory, util, $filter, Notification, $route, $window) {
			init();

			function init() {
				initVariables();
				initScope();
			};

			this.$onChanges = function set(item) {
				if (item.settings && item.settings.currentValue) {
					$scope.settings.content = angular.copy(item.settings.currentValue);
					$scope.settings.currentlyLoading = false;
				}
				if (item.translations && item.translations.currentValue) {
					if (angular.isArray(item.translations.currentValue)) {
						$scope.translations.content = groupByKey($scope.translations.content);
						$scope.translations.currentlyLoading = false;
					}
					else {
						$scope.translations.content = [];
						$scope.translations.currentlyLoading = false;
					}
				}
				if (!$scope.settings.currentlyLoading && !$scope.translations.currentlyLoading) {
					$scope.view.allDependenciesLoaded = true;
				}
			};

			function initVariables() {
				$scope.dunningLevelOptions = [
					{key: "1", text: $filter("translate")("translationSettings.DUNNING_LEVEL_ONE")},
					{key: "2", text: $filter("translate")("translationSettings.DUNNING_LEVEL_TWO")},
					{key: "3", text: $filter("translate")("translationSettings.DUNNING_LEVEL_THREE")}
				];
				$scope.translations = {
					content: undefined,
					currentlyLoading: true
				};
				$scope.settings = {
					content: undefined,
					currentlyLoading: true,
					sepa: {
						settings: undefined,
						languages: undefined,
						selectedLanguage: "Default",
						selectedTemplate: undefined
					},
					demandNote: {
						settings: undefined,
						languages: undefined,
						selectedLanguage: "Default",
						selectedTemplate: undefined, 
						selectedDunningLevel: $scope.dunningLevelOptions[0]
					},
					emailTemplate: {
						settings: undefined,
						languages: undefined,
						selectedLanguage: "Default",
						selectedTemplate: undefined
					},
					lawyerReport: {
						settings: undefined,
						languages: undefined,
						selectedLanguage: "Default",
						selectedTemplate: undefined
					}
				};
				$scope.newLanguageModal = {
					selectedLanguage: undefined,
					templateKey: undefined
				};
				$scope.languageCodes = languageCodesV1;
				$scope.view = {
					allDependenciesLoaded: false
				}; 
			}

			function initScope() {
				$scope.showCollapsedArea = showCollapsedArea;
				$scope.changeTemplateLanguage = changeTemplateLanguage;
				$scope.saveSettings = saveSettings;
				$scope.openNewLanguageModal = openNewLanguageModal;
				$scope.createNewLanguage = createNewLanguage;
				$scope.removeLanguage = removeLanguage;
				$scope.saveTranslation = saveTranslation;
			}

			function createSepaProperties(settings, templateName) {
				$scope.settings.sepa.settings = util.getSettingsWithMatchingRegex(settings, /sepaMandatTemplate/);
				$scope.settings.sepa.settings = decodeTemplateValues($scope.settings.sepa.settings);
				$scope.settings.sepa.defaultTemplate = getDefaultTemplate($scope.settings.sepa.settings);
				$scope.settings.sepa.languages = getLanguages($scope.settings.sepa.settings);
				$scope.settings.sepa.selectedTemplate = getDefaultTemplate($scope.settings.sepa.settings);
			}

			function createDemandNoteProperties(settings) {
				$scope.settings.demandNote.settings = util.getSettingsWithMatchingRegex(settings, /demandNoteTemplate/);
				$scope.settings.demandNote.settings = decodeTemplateValues($scope.settings.demandNote.settings);
				$scope.settings.demandNote.defaultTemplate = getDefaultTemplate($scope.settings.demandNote.settings);
				$scope.settings.demandNote.languages = getLanguages($scope.settings.demandNote.settings);
				$scope.settings.demandNote.selectedTemplate = getDefaultTemplate($scope.settings.demandNote.settings);
			}

			function createEmailTemplateProperties(settings) {
				$scope.settings.emailTemplate.settings = util.getSettingsWithMatchingRegex(settings, /emailTemplate/);
				$scope.settings.emailTemplate.settings = decodeTemplateValues($scope.settings.emailTemplate.settings);
				$scope.settings.emailTemplate.defaultTemplate = getDefaultTemplate($scope.settings.emailTemplate.settings);
				$scope.settings.emailTemplate.languages = getLanguages($scope.settings.emailTemplate.settings);
				$scope.settings.emailTemplate.selectedTemplate = getDefaultTemplate($scope.settings.emailTemplate.settings);
			}

			function createLawyerReportProperties(settings) {
				$scope.settings.lawyerReport.settings = util.getSettingsWithMatchingRegex(settings, /lawyerReportTemplate/);
				$scope.settings.lawyerReport.settings = decodeTemplateValues($scope.settings.lawyerReport.settings);
				$scope.settings.lawyerReport.defaultTemplate = getDefaultTemplate($scope.settings.lawyerReport.settings);
				$scope.settings.lawyerReport.languages = getLanguages($scope.settings.lawyerReport.settings);
				$scope.settings.lawyerReport.selectedTemplate = getDefaultTemplate($scope.settings.lawyerReport.settings);
			}

			function showCollapsedArea(id) {
				jQuery("#" + id).toggle(500);
			}

			function groupByKey(translationArray) {
				var translationArrayGrouped = {};

				translationArray.forEach(function(translation) {
					if (translation.blockCode in translationArrayGrouped) {
						translationArrayGrouped[translation.blockCode][translation.language] = translation;
					} else {
						translationArrayGrouped[translation.blockCode] = {};
						translationArrayGrouped[translation.blockCode][translation.language] = translation;
					}
				});
				return translationArrayGrouped;
			}

			function getLanguages(settingList) {
				var languageList = [];
				settingList.forEach(function(setting) {
					languageList.push(setting.settingValue2);
				});
				return languageList;
			}

			function getDefaultTemplate(settingList) {
				var defaultSetting = undefined;
				settingList.forEach(function(setting) {
					if (setting.settingValue2 == 'Default') {
						defaultSetting = setting;
					}
				});

				return defaultSetting;
			}

			function decodeTemplateValues(settingList) {
				settingList.forEach(function(setting) {
					setting.decodedTemplate = atob(setting.settingValue1);
				});
				return settingList;
			}

			function encodeTemplateValues(settingList) {
				settingList.forEach(function(setting) {
					setting.settingValue1 = btoa(setting.decodedTemplate);
				});
				return settingList;
			}

			function changeTemplateLanguage(key, language) {
				switch (key) {
					case "SEPA":
						$scope.settings.sepa.selectedTemplate = getSettingWithLanguage($scope.settings.sepa.settings, language);
						return;
					case "DEMAND_NOTE":
						$scope.settings.demandNote.selectedTemplate = getSettingWithLanguage($scope.settings.demandNote.settings, language);
						return;
					case "EMAIL_TEMPLATE":
						$scope.settings.emailTemplate.selectedTemplate = getSettingWithLanguage($scope.settings.emailTemplate.settings, language);
						return;
					case "LAWYER_REPORT":
						$scope.settings.lawyerReport.selectedTemplate = getSettingWithLanguage($scope.settings.lawyerReport.settings, language);
						return;
				}
			}

			function getSettingWithLanguage(settingList, languageKey) {
				var correctSetting = undefined;
				settingList.forEach(function(setting) {
					if (setting.settingValue2 == languageKey) {
						correctSetting = setting;
					}
				});
				return correctSetting;
			}

			function saveSettings(settingKey) {
				switch (settingKey) {
					case "SEPA":
						encodeTemplateValues($scope.settings.sepa.settings);
						sendSaveSettingRequest($rootScope.authData.sessionId, $scope.settings.sepa.settings, function(requestDetails) {
							if (requestDetails.allSuccessful) {
								Notification.success($filter("translate")("translationSettings.SUCCESSFULLY_SAVED"))
							} else {
								sharedMemory.error.setError(
									requestDetails,
									"COULD_NOT_LOAD_DATA",
									"Fehler beim speichern eines settings -> speichern eines templates (SEPA)",
									$location.path(),
									"translationSettings"
								);
								$location.path("/error-page");
							}
						});
						return;
					case "DEMAND_NOTE":
						encodeTemplateValues($scope.settings.demandNote.settings);
						sendSaveSettingRequest($rootScope.authData.sessionId, $scope.settings.demandNote.settings, function(requestDetails) {
							if (requestDetails.allSuccessful) {
								Notification.success($filter("translate")("translationSettings.SUCCESSFULLY_SAVED"))
							} else {
								sharedMemory.error.setError(
									requestDetails,
									"COULD_NOT_LOAD_DATA",
									"Fehler beim speichern eines settings -> speichern eines templates (DEMAND_NOTE)",
									$location.path(),
									"translationSettings"
								);
								$location.path("/error-page");
							}
						});
						return;
					case "EMAIL_TEMPLATE":
						encodeTemplateValues($scope.settings.emailTemplate.settings);
						sendSaveSettingRequest($rootScope.authData.sessionId, $scope.settings.emailTemplate.settings, function(requestDetails) {
							if (requestDetails.allSuccessful) {
								Notification.success($filter("translate")("translationSettings.SUCCESSFULLY_SAVED"))
							} else {
								sharedMemory.error.setError(
									requestDetails,
									"COULD_NOT_LOAD_DATA",
									"Fehler beim speichern eines settings -> speichern eines templates (EMAIL_TEMPLATE)",
									$location.path(),
									"translationSettings"
								);
								$location.path("/error-page");
							}
						});
						return;
					case "LAWYER_REPORT":
						encodeTemplateValues($scope.settings.lawyerReport.settings);
						sendSaveSettingRequest($rootScope.authData.sessionId, $scope.settings.lawyerReport.settings, function(requestDetails) {
							if (requestDetails.allSuccessful) {
								Notification.success($filter("translate")("translationSettings.SUCCESSFULLY_SAVED"))
							} else {
								sharedMemory.error.setError(
									requestDetails,
									"COULD_NOT_LOAD_DATA",
									"Fehler beim speichern eines settings -> speichern eines templates (LAWYER_REPORT)",
									$location.path(),
									"translationSettings"
								);
								$location.path("/error-page");
							}
						});
						return;
				}
			}

			function sendSaveSettingRequest(sessionId, settingArray, callback) {
				var requestDetails = {
					allSuccessful: true,
					numRequestsPlanned: settingArray.length,
					errorResponses: [],
					successfulResponses: [],
					responses: []
				};

				settingArray.forEach(function(setting) {
					requestManager.routes.settings.editSettingWithKey(sessionId, setting).then(
						function(response) {
							requestDetails.responses.push(response);
							requestDetails.successfulResponses.push(response);
							if (requestDetails.responses.length == requestDetails.numRequestsPlanned) {
								callback(requestDetails);
							}
						},
						function(response) {
							requestDetails.responses.push(response);
							requestDetails.errorResponses.push(response);
							requestDetails.allSuccessful = false;
							if (requestDetails.responses.length == requestDetails.numRequestsPlanned) {
								callback(requestDetails);
							}
						}
					)
				});
			}

			function sendRemoveSettingRequest(sessionId, settingId, callback) {
				var requestDetails = {
					successful: true,
					response: undefined
				};

				requestManager.routes.settings.deleteSetting(sessionId, settingId).then(
					function(response) {
						requestDetails.response = response;
						callback(requestDetails);
					},
					function(response) {
						requestDetails.response = response;
						requestDetails.successful = false;
						callback(requestDetails);
					}
				)
			}

			function openNewLanguageModal(templateKey) {
				$scope.newLanguageModal.templateKey = templateKey;
				jQuery("#newLanguageModal").appendTo("body").modal("show");
			}

			function sendCreateSettingRequest(sessionId, params, callback) {
				var requestDetails = {
					successful: true,
					response: undefined
				};

				requestManager.routes.settings.createSettingWithKey(sessionId, params).then(
					function(response) {
						requestDetails.response = response;
						callback(requestDetails);
					},
					function(response) {
						requestDetails.response = response;
						requestDetails.successful = false;
						callback(requestDetails);
					}
				)
			}

			function createNewLanguage() {
				switch ($scope.newLanguageModal.templateKey) {
					case "SEPA":
						sendCreateSettingRequest($rootScope.authData.sessionId, {
							settingCode: "sepaMandatTemplate",
							settingValue1: $scope.settings.sepa.defaultTemplate.settingValue1,
							settingValue2: $scope.newLanguageModal.selectedLanguage.code
						}, function(requestDetails) {
							if (requestDetails.successful) {
								Notification.success($filter("translate")("translationSettings.CREATED_LANGUAGE_SUCCESSFULLY"));
								jQuery("#newLanguageModal").one("hidden.bs.modal", function() {
									$route.reload();
								}).modal("hide");
							} else {
								sharedMemory.error.setError(
									requestDetails,
									"COULD_NOT_LOAD_DATA",
									"Fehler beim erstellen eines neuen Settings (Neues Template -> neue Übersetzung)",
									$location.path(),
									"translationSettings"
								);
								$location.path("/error-page");
							}
						});
						break;
					case "DEMAND_NOTE":
						sendCreateSettingRequest($rootScope.authData.sessionId, {
							settingCode: "demandNoteTemplate",
							settingValue1: $scope.settings.demandNote.defaultTemplate.settingValue1,
							settingValue2: $scope.newLanguageModal.selectedLanguage.code
						}, function(requestDetails) {
							if (requestDetails.successful) {
								Notification.success($filter("translate")("translationSettings.CREATED_LANGUAGE_SUCCESSFULLY"));
								jQuery("#newLanguageModal").one("hidden.bs.modal", function() {
									$route.reload();
								}).modal("hide");
							} else {
								sharedMemory.error.setError(
									requestDetails,
									"COULD_NOT_LOAD_DATA",
									"Fehler beim erstellen eines neuen Settings (Neues Template -> neue Übersetzung)",
									$location.path(),
									"translationSettings"
								);
								$location.path("/error-page");
							}
						});
						break;
					case "EMAIL_TEMPLATE":
						sendCreateSettingRequest($rootScope.authData.sessionId, {
							settingCode: "emailTemplate",
							settingValue1: $scope.settings.emailTemplate.defaultTemplate.settingValue1,
							settingValue2: $scope.newLanguageModal.selectedLanguage.code
						}, function(requestDetails) {
							if (requestDetails.successful) {
								Notification.success($filter("translate")("translationSettings.CREATED_LANGUAGE_SUCCESSFULLY"));
								jQuery("#newLanguageModal").one("hidden.bs.modal", function() {
									$route.reload();
								}).modal("hide");
							} else {
								sharedMemory.error.setError(
									requestDetails,
									"COULD_NOT_LOAD_DATA",
									"Fehler beim erstellen eines neuen Settings (Neues Template -> neue Übersetzung)",
									$location.path(),
									"translationSettings"
								);
								$location.path("/error-page");
							}
						});
						break;
					case "LAWYER_REPORT":
						sendCreateSettingRequest($rootScope.authData.sessionId, {
							settingCode: "lawyerReportTemplate",
							settingValue1: $scope.settings.lawyerReport.defaultTemplate.settingValue1,
							settingValue2: $scope.newLanguageModal.selectedLanguage.code
						}, function(requestDetails) {
							if (requestDetails.successful) {
								Notification.success($filter("translate")("translationSettings.CREATED_LANGUAGE_SUCCESSFULLY"));
								jQuery("#newLanguageModal").one("hidden.bs.modal", function() {
									$route.reload();
								}).modal("hide");
							} else {
								sharedMemory.error.setError(
									requestDetails,
									"COULD_NOT_LOAD_DATA",
									"Fehler beim erstellen eines neuen Settings (Neues Template -> neue Übersetzung)",
									$location.path(),
									"translationSettings"
								);
								$location.path("/error-page");
							}
						});
						break;
				}
			}

			function removeLanguage(template) {
				if (template.settingValue2 == "Default") {
					Notification.error($filter("translate")("translationSettings.DELETE_DEFAULT_NOT_POSSIBLE"));
					return;
				}

				if ($window.confirm($filter("translate")("translationSettings.CONFIRM_TEMPLATE_DELETE"))) {
					sendRemoveSettingRequest($rootScope.authData.sessionId, template.id, function(requestDetails) {
						if (requestDetails.successful) {
							Notification.success($filter("translate")("translationSettings.CONFIRM_TEMPLATE_DELETE"));
							$route.reload();
						} else {
							sharedMemory.error.setError(
								requestDetails,
								"COULD_NOT_LOAD_DATA",
								"Fehler beim löschen eines Settings -> löschen eines templates",
								$location.path(),
								"translationSettings"
							);
							$location.path("/error-page");
						}
					});
				}
			}

			function saveTranslation(translation) {
				if (!translation.translation) {
					Notification.error($filter("translate")("translationSettings.EMPTY_TRANSLATION_NOT_ALLOWED"));
					return;
				}
				sendSaveTranslationRequest($rootScope.authData.sessionId, translation, function(requestDetails) {
					if (requestDetails.successful) {
						Notification.success($filter("translate")("translationSettings.TRANSLATION_SAVED"));
					} else {
						sharedMemory.error.setError(
							requestDetails,
							"COULD_NOT_LOAD_DATA",
							"Fehler beim speichern der Übersetzung",
							$location.path(),
							"translationSettings"
						);
						$location.path("/error-page");
					}
				});
			}

			function sendSaveTranslationRequest(sessionId, params, callback) {
				var requestDetails = {
					successful: true,
					response: undefined
				};

				requestManager.routes.language.editTranslations(sessionId, params).then(
					function(response) {
						requestDetails.response = response;
						callback(requestDetails);
					},
					function(response) {
						requestDetails.response = response;
						callback(requestDetails);
					}
				)
			}
		}
	]
});
angular.module('userSettings', []);
angular.module('userSettings').component('userSettings', {
    templateUrl: 'app/components/userSettings/userSettings.template.html',
    bindings: {
        settings: "<"
    },
    controller: [
        "$scope",
        "$rootScope",
        "requestManager",
        "$location",
        "sharedMemory",
        "$route",
        "Notification",
        "$filter",
        "$window",
        "cookieManager",
        function ($scope, $rootScope, requestManager, $location, sharedMemory, $route, Notification, $filter, $window, cookieManager) {
            var ctrl = this;
            init();

            ctrl.$onChanges = function (change) {
                if (change.settings && change.settings.currentValue) {
                    $scope.settings.content = change.settings.currentValue;
                }

                if ($scope.settings.content) {
                    $scope.settings.externalAdviserKeySetting = $scope.settings.content.find((setting) => {
                        return setting.settingCode === "externalPartnerKey"
                    });
                    $scope.settings.externalAdviserKey = parseInt($scope.settings.content.find((setting) => {
                        return setting.settingCode === "externalPartnerKey"
                    })["settingValue1"]);

                    $scope.settings.useCustomerAdvisorForMailsIfPossibleSetting = $scope.settings.content.find((setting) => {
                        return setting.settingCode === "sendMailAsCustomerAdvisorIfPossible"
                    });
                }
            };

            function init() {
                initVariables();
                initScope();
                getUserList($rootScope.authData.sessionId, function (requestDetails) {
                    if (requestDetails.successful) {
                        var data = requestDetails.response.data.filter(function (user) {
                            return user.disabled !== "1";
                        });
                        $scope.userList.content = angular.copy(data);
                        $scope.userList.contentEditMode = angular.copy(data);
                        $scope.userList.loaded = true;
                    } else {
                        if (!requestDetails.canceledRequest) {
                            sharedMemory.error.setError(
                                requestDetails,
                                "COULD_NOT_LOAD_DATA",
                                "Fehler beim laden der Userliste (beim initiieren der Komponente)",
                                $location.path(),
                                "userSettings"
                            );
                            $location.path("/error-page");
                        }
                    }
                });
            }

            function initVariables() {
                $scope.userList = {
                    loaded: false,
                    content: undefined,
                    contentEditMode: undefined
                };
                $scope.changePasswordModal = {
                    userSelected: undefined
                };
                $scope.infoModal = {
                    title: undefined,
                    body: undefined
                };
                $scope.settings = {
                    content: undefined
                };
                $scope.bookstack = {
                    pageId: undefined,
                    elementId: undefined
                };
                $scope.selectedUser = undefined;
            }

            function initScope() {
                $scope.createUser = createUser;
                $scope.updateUser = updateUser;
                $scope.enableEditMode = enableEditMode;
                $scope.closeEditMode = closeEditMode;
                $scope.saveChanges = saveChanges;
                $scope.openChangePasswordModal = openChangePasswordModal;
                $scope.disableUser = disableUser;
                $scope.setApprovalStatus = setApprovalStatus;
                $scope.openInfoModal = openInfoModal;
                $scope.openRightsModal = openRightsModal;
                $scope.onSaveExternalAdviserNumberButtonClick = onSaveExternalAdviserNumberButtonClick;
            }

            function openRightsModal(index) {
                $scope.selectedUser = $scope.userList.content[index];
                jQuery("#userRightsModal").appendTo("body").modal("show");
            }

            function getUserList(sessionId, callback) {
                var requestDetails = {
                    successful: true,
                    response: undefined,
                    canceledRequest: false
                };

                requestManager.routes.user.getAllUser(sessionId).then(
                    function (response) {
                        requestDetails.response = response;
                        callback(requestDetails);
                    },
                    function (response) {
                        requestDetails.response = response;
                        requestDetails.successful = false;
                        requestDetails.canceledRequest = response.status === -1;
                        callback(requestDetails);
                    }
                )
            }

            function sendCreateUserRequest(sessionId, params, callback) {
                var requestDetails = {
                    successful: true,
                    response: undefined,
                    canceledRequest: false
                };

                requestManager.routes.user.createUser(sessionId, params).then(
                    function (response) {
                        requestDetails.response = response;
                        callback(requestDetails);

                    },
                    function (response) {
                        requestDetails.response = response;
                        requestDetails.successful = false;
                        requestDetails.canceledRequest = response.status === -1;
                        callback(requestDetails);
                    }
                )
            }

            function createUser() {
                if ($scope.createUserForm.$invalid) {
                    return;
                }

                sendCreateUserRequest($rootScope.authData.sessionId, $scope.createUserFormModel, function (requestDetails) {
                    if (requestDetails.successful) {
                        Notification.success($filter("translate")("userSettings.USER_CREATED"));
                        $window.location.reload();
                    } else {
                        if (!requestDetails.canceledRequest) {
                            sharedMemory.error.setError(
                                requestDetails,
                                "COULD_NOT_LOAD_DATA",
                                "Fehler beim erstellen eines Users",
                                $location.path(),
                                "userSettings"
                            );
                            $location.path("/error-page");
                        }
                    }
                })
            }

            function updateUser() {
                if ($scope.editUserForm.$invalid) {
                    return;
                }
                const body = {
                    id: $scope.selectedUser.id,
                    name: $scope.selectedUser.name,
                    surname: $scope.selectedUser.surname,
                    administrator: $scope.selectedUser.administrator,
                    approvalStatus: $scope.selectedUser.approvalStatus,
                    canCreateDunningNotes: $scope.selectedUser.canCreateDunningNotes,
                    canCreateComments: $scope.selectedUser.canCreateComments,
                    canDeleteComments: $scope.selectedUser.canDeleteComments,
                    canCreatePhoneCalls: $scope.selectedUser.canCreatePhoneCalls,
                    canEditReceivableInformation: $scope.selectedUser.canEditReceivableInformation,
                    canEditTasks: $scope.selectedUser.canEditTasks,
                    canMakeCustomersProblematic: $scope.selectedUser.canMakeCustomersProblematic,
                }

                sendEditUserRequest($rootScope.authData.sessionId, body, function (requestDetails) {
                    if (requestDetails.successful) {
                        Notification.success($filter("translate")("userSettings.USER_UPDATED"));
                        const actualData = cookieManager.readCookie();
                        if (actualData.userId === body.id) {
                            actualData.userData = body;
                            cookieManager.saveCookie(actualData);
                        }
                        $window.location.reload();
                    } else {
                        Notification.error($filter("translate")("userSettings.USER_NOT_UPDATED"));
                    }
                });
            }

            function sendCreateDefaultSettingsRequests(sessionId, settingsArray, userId, callback) {
                var requestDetails = {
                    allSuccessful: true,
                    numRequestsPlanned: settingsArray.lenght,
                    responses: [],
                    successfulResponses: [],
                    errorResponses: []
                };

                settingsArray.forEach(function (setting) {
                    requestManager.routes.user.createSettingsOfUser(sessionId, setting, userId).then(
                        function (response) {
                            requestDetails.responses.push(response);
                            requestDetails.successfulResponses.push(response);
                            if (requestDetails.responses.length === requestDetails.numRequestsPlanned) {
                                callback(requestDetails);
                            }
                        },
                        function (response) {
                            requestDetails.responses.push(response);
                            requestDetails.errorResponses.push(response);
                            requestDetails.allSuccessful = false;
                            if (requestDetails.responses.length === requestDetails.numRequestsPlanned) {
                                callback(requestDetails);
                            }
                        }
                    )
                });
            }

            function createDefaultSettingsArray() {
                var defaultSettingKeys = [
                    "showCustomerNumberInCustomerList",
                    "showCustomerNameInCustomerList",
                    "showCustomerStreetInCustomerList",
                    "showCustomerZipInCustomerList",
                    "showCustomerCityInCustomerList",
                    "showCustomerEmailInCustomerList",
                    "showCustomerOldestInvoiceInCustomerList",
                    "showCustomerVacantPosInCustomerList",
                    "showCustomerLastActionInCustomerList",
                    "showCustomerCountryInCustomerList",
                    "showCustomerAdvisorInCustomerList",
                    "showCustomerAmountDunningsInCustomerList"
                ];
                var defaultSettingArray = [];

                defaultSettingKeys.forEach(function (key) {
                    defaultSettingArray.push({
                        settingCode: key,
                        value1: "1"
                    });
                });

                defaultSettingArray.push({
                    settingCode: "defaultNumRowsInList",
                    value1: "10"
                });

                return defaultSettingArray;
            }

            function enableEditMode(row) {
                jQuery('#' + row).fadeOut(250, function () {
                    jQuery('#edit' + row).fadeIn(250);
                });
            }

            function closeEditMode(row) {
                jQuery('#edit' + row).fadeOut(250, function () {
                    jQuery('#' + row).fadeIn(250);
                });
            }

            function isValidEmail(email) {
                var emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
                return emailRegex.test(email);
            }

            function saveChanges(row) {
                if (!isValidEmail($scope.userList.contentEditMode[row].email)) {
                    Notification.error("Die E-Mail-Adresse ist nicht gültig.");
                    return;
                }

                var params = {
                    name: $scope.userList.contentEditMode[row].name ? $scope.userList.contentEditMode[row].name : "",
                    id: $scope.userList.contentEditMode[row].id,
                    surname: $scope.userList.contentEditMode[row].surname ? $scope.userList.contentEditMode[row].surname : "",
                    email: $scope.userList.contentEditMode[row].email ? $scope.userList.contentEditMode[row].email : "",
                    foreignPersonalKey: $scope.userList.contentEditMode[row].foreignPersonalKey ? $scope.userList.contentEditMode[row].foreignPersonalKey : "",
                    administrator: $scope.userList.contentEditMode[row].administrator ? $scope.userList.contentEditMode[row].administrator : undefined,
                    approvalStatus: $scope.userList.contentEditMode[row].approvalStatus ? $scope.userList.contentEditMode[row].approvalStatus : undefined,
                    approvalNotification: $scope.userList.contentEditMode[row].approvalNotification ? $scope.userList.contentEditMode[row].approvalNotification : undefined,
                    wantsNewsletter: $scope.userList.contentEditMode[row].wantsNewsletter ? $scope.userList.contentEditMode[row].wantsNewsletter : undefined
                };
                console.log(params);

                sendEditUserRequest($rootScope.authData.sessionId, params, function (requestDetails) {
                    if (requestDetails.successful) {
                        Notification.success($filter("translate")("userSettings.CHANGED_SUCCESSFULLY"));
                        $window.location.reload();
                    } else {
                        if (!requestDetails.canceledRequest) {
                            sharedMemory.error.setError(
                                requestDetails,
                                "COULD_NOT_LOAD_DATA",
                                "Fehler beim ändern der Userdaten ",
                                $location.path(),
                                "userSettings"
                            );
                            $location.path("/error-page");
                        }
                    }
                });
            }

            function onSaveExternalAdviserNumberButtonClick() {
                if ($scope.externalAdviserNumberForm.$valid) {
                    let params = angular.copy($scope.settings.externalAdviserKeySetting);
                    params.settingValue1 = ($scope.settings.externalAdviserKey).toString();

                    requestManager.routes.settings.editSettingWithKey($rootScope.authData.sessionId, params).then(
                        function (response) {
                            Notification.success("Externe Beraternr. erfolgreich geändert!  Seite lädt neu...");
                            window.setTimeout(function () {
                                $window.location.reload();
                            }, 2500);
                        },
                        function (response) {
                            Notification.error("Fehler beim Ändern der externen Beraternr. Wenden Sie sich bitte an den Support.");
                        }
                    )
                }
            }

            function sendEditUserRequest(sessionId, params, callback) {
                var requestDetails = {
                    successful: true,
                    response: undefined,
                    canceledRequest: false
                };
                if (params.administrator === null) {
                    params.administrator = '0';
                }
                if (params.approvalStatus === null) {
                    params.approvalStatus = '0';
                }
                if (params.canCreateDunningNotes === null) {
                    params.canCreateDunningNotes = '0';
                }
                if (params.canCreateComments === null) {
                    params.canCreateComments = '0';
                }
                if (params.canDeleteComments === null) {
                    params.canDeleteComments = '0';
                }
                if (params.canCreatePhoneCalls === null) {
                    params.canCreatePhoneCalls = '0';
                }
                if (params.canEditReceivableInformation === null) {
                    params.canEditReceivableInformation = '0';
                }
                if (params.canEditTasks === null) {
                    params.canEditTasks = '0';
                }
                if (params.canMakeCustomersProblematic === null) {
                    params.canMakeCustomersProblematic = '0';
                }

                requestManager.routes.user.editUser(sessionId, params).then(
                    function (response) {
                        requestDetails.response = response;
                        callback(requestDetails);
                    },
                    function (response) {
                        requestDetails.response = response;
                        requestDetails.successful = false;
                        requestDetails.canceledRequest = response.status == -1;
                        callback(requestDetails);
                    }
                )
            }

            function openChangePasswordModal(row) {
                jQuery("#changePasswordModal").modal("show");
            }

            function disableUser(user) {
                if (user.id === $rootScope.authData.userId) {
                    Notification.error($filter("translate")("userSettings.DELETE_OWN_USER_NOT_ALLOWED"));
                    return;
                }

                const params = {
                    administrator: "0",
                    approvalStatus: "0",
                    approvalNotification: "0",
                    disabled: "1",
                    id: user.id
                };
                console.log(params);

                if ($window.confirm($filter("translate")("userSettings.CONFIRM_DISABLE"))) {
                    sendEditUserRequest($rootScope.authData.sessionId, params, function (requestDetails) {
                        if (requestDetails.successful) {
                            Notification.success($filter("translate")("userSettings.SUCCESSFULLY_DISABLED"));
                            $window.location.reload();
                        } else {
                            if (!requestDetails.canceledRequest) {
                                sharedMemory.error.setError(
                                    requestDetails,
                                    "COULD_NOT_LOAD_DATA",
                                    "Fehler beim deaktivieren vom User",
                                    $location.path(),
                                    "userSettings"
                                );
                                $location.path("/error-page");
                            }
                        }
                    });
                }
            }

            function setApprovalStatus($index) {
                if ($scope.userList.contentEditMode[$index].administrator == '1') {
                    $scope.userList.contentEditMode[$index].approvalStatus = "1";
                }
            }

            function openInfoModal(id) {
                switch (id) {
                    case "administrator":
                        jQuery("#administratorInfoModal").appendTo("body").modal("show");
                        break;
                    case "approval":
                        jQuery("#approvalInfoModal").appendTo("body").modal("show");
                        break;
                    case "approvalNotification":
                        jQuery("#approvalNotificationInfoModal").appendTo("body").modal("show");
                        break;
                }
            }
        }
    ]
}); 

angular.module('userSettings').component('externalPersonalNumberKeyEditor', {
    bindings: {
        settings: "<",
    },
    templateUrl: 'app/components/userSettings/components/externalPersonalNumberKeyEditor/externalPersonalNumberKeyEditor.template.html',
    controller: [
        "$scope",
        "$rootScope",
        "requestManager",
        "$location",
        "sharedMemory",
        "$route",
        "Notification",
        "$filter",
        "$window",
        function ($scope, $rootScope, requestManager, $location, sharedMemory, $route, Notification, $filter, $window) {
            var ctrl = this;
            onInit();

            ctrl.$onChanges = function(change){
                if(change.settings && change.settings.currentValue){
                    $scope.settings.content = change.settings.currentValue;
                }

                if($scope.settings.content){
                    $scope.settings.externalPersonalKeySetting = $scope.settings.content.find((setting) => {return setting.settingCode === "externalPersonalKey"});
                    $scope.settings.externalPersonalKey = parseInt($scope.settings.content.find((setting) => {return setting.settingCode === "externalPersonalKey"})["settingValue1"]);
                }
            };

            function onInit(){
                initVariables();
                initScope();
            }

            function initVariables(){
                $scope.userList = {
                    content: undefined,
                    selected: undefined
                };
                $scope.componentStatus = {
                    errorOccurred:  false,
                    allDependenciesLoaded: false
                };
                $scope.settings = {
                    content: undefined,
                    defaultCustomerAdvisorId: undefined
                }
            }

            function initScope(){
                $scope.onSaveButtonClick = onSaveButtonClick;
            }

            function onSaveButtonClick(){
                if($scope.externalPersonalNumberForm.$valid){
                    let params = angular.copy($scope.settings.externalPersonalKeySetting);
                    params.settingValue1 = ($scope.settings.externalPersonalKey).toString();


                    requestManager.routes.settings.editSettingWithKey($rootScope.authData.sessionId, params).then(
                        function(response){
                            Notification.success($filter("translate")("externalPersonalNumberKeyEditor.SAVED_SUCCESSFULLY"));
                            $window.location.reload();
                        },
                        function(response){
                            Notification.success($filter("translate")("externalPersonalNumberKeyEditor.ERROR_DURING_PROCESSING"));
                        }
                    )
                }
            }
        }
    ]
}); 

angular.module('paymentAgreements', []);
angular.module('paymentAgreements').component('paymentAgreements', { 
	templateUrl: 'app/components/paymentAgreements/paymentAgreements.template.html',
	bindings: {
		accountLengthImpersonal: "<"
	},
	controller: [
		"requestManager",
		"$scope",
		"$rootScope",
		"$location",
		"sharedMemory",
		"util",
		function (requestManager, $scope, $rootScope, $location, sharedMemory, util) {
			this.$onInit = function(){
				$scope.europeanNumberFormat = 'en-US';
				if ($rootScope.europeanNumberFormat !== undefined) {
					if ($rootScope.europeanNumberFormat === '1' || $rootScope.europeanNumberFormat === 1) {
						$scope.europeanNumberFormat = 'de-AT';
					}
					else {
						$scope.europeanNumberFormat = 'en-US';
					}
				}
				initScope();
				initVariables();
				loadPaymentAgreementList($rootScope.authData.sessionId, function(requestDetails){
					if(requestDetails.allSuccessful){
						$scope.paymentTerms.content = requestDetails.response.data;
						$scope.paymentTerms.content.forEach(value => {
							value.amountInterval = +value.amountInterval;
							value.amountTotal = +value.amountTotal;
						});
						$scope.paymentTerms.isEmpty = $scope.paymentTerms.content.length == 0;
						loadCorrespondingCustomer($scope.paymentTerms.content, $rootScope.authData.sessionId, function(correspondingCustomerRequestDetails){
						if(correspondingCustomerRequestDetails.allSuccessful){
							$scope.paymentTerms.content.forEach(function(paymentTerm){
								paymentTerm.customerDetails = correspondingCustomerRequestDetails.relation[paymentTerm.id];
							});

							$scope.paymentTerms.contentChunked = util.divideArrayIntoChunks($scope.paymentTerms.content, 10);
							$scope.paymentTerms.currentlyLoading = false;
						}
						else{
							if (!correspondingCustomerRequestDetails.canceledRequest) {
								sharedMemory.error.setError(
										correspondingCustomerRequestDetails,
										"COULD_NOT_LOAD_DATA",
										"Fehler beim laden der User zur jeder Zahlungsvereinbarung (beim initiieren der Komponente)",
										$location.path(),
										"paymentAgreements"
								);
								$location.path("/error-page"); 
							}
						}
						});
					}
					else{
						if (!requestDetails.canceledRequest) {
							sharedMemory.error.setError(
									requestDetails,
									"COULD_NOT_LOAD_DATA",
									"Fehler beim laden Zahlungsvereinbarungen  (beim initiieren der Komponente)",
									$location.path(),
									"paymentAgreements"
							);
							$location.path("/error-page"); 
						}
					}
				});
			};

			this.$onChanges = function (item) {
				$scope.accountLengthImpersonal = item.accountLengthImpersonal.currentValue;
			}
			
			function initScope(){
				$scope.changePaginationIndex = changePaginationIndex;
				$scope.forwardToCustomer = forwardToCustomer;
				$scope.openInfoModal = openInfoModal; 
			}

			function loadPaymentAgreementList(sessionId, callback){
				var requestDetails = {
					allSuccessful: true,
					response: undefined, 
					canceledRequest: false
				};

				requestManager.routes.paymentTerm.getAllUnfinishedPaymentTerms(sessionId).then(
					function(response){
						requestDetails.response = response;
						callback(requestDetails);
					},
					function(response){
						requestDetails.response = response;
						requestDetails.allSuccessful = false;
						requestDetails.canceledRequest = response.status == -1;
						callback(requestDetails);
					}
				)
			}

			function initVariables(){
				$scope.paymentTerms = {
					currentlyLoading: true,
					content: [],
					isEmpty: false
				};
				$scope.pagination =  {
					currentIndex: 0, 
					paginationButtonsArray: undefined
				};
			}

			function loadCorrespondingCustomer(paymentTermList, sessionId, callback){
				var requestDetails = {
					numRequestsPlanned: paymentTermList.length,
					successfulRequests: [],
					errorRequests: [],
					responses: [],
					allSuccessful: true,
					relation: {}, 
					canceledRequest: false
				};

				if(paymentTermList.length == 0){
						callback(requestDetails);
					return;
				}

				angular.forEach(paymentTermList, function(paymentTerm){
					requestManager.routes.paymentTerm.getCustomerOfPaymentTerm(sessionId, paymentTerm.id).then(
						function(response){
							requestDetails.responses.push(response);
							requestDetails.successfulRequests.push(response);
							requestDetails.relation[paymentTerm.id] = response.data;
							if(requestDetails.responses.length == requestDetails.numRequestsPlanned){
								callback(requestDetails);
							}
						},
						function(response){
							requestDetails.responses.push(response);
							requestDetails.errorRequests.push(response);
							requestDetails.allSuccessful = false;
							if(!requestDetails.canceledRequest){
								requestDetails.canceledRequest = response.status == -1;
							}
							if(requestDetails.responses.length == requestDetails.numRequestsPlanned){
								callback(requestDetails);
							}
						}
					)
				})
			}
			
			function changePaginationIndex(value){
				if(value < 0){
					if($scope.pagination.currentIndex > 0){
						$scope.pagination.currentIndex += value;
					}
				}
				else{
					if($scope.pagination.currentIndex < $scope.paymentTerms.contentChunked.length-1){
						$scope.pagination.currentIndex += value;
					}
				}
			}

			function forwardToCustomer(customerId){
				$location.path("/user-details/" + customerId);
			}
			
			function openInfoModal(){
				jQuery("#paymentAgreementListInfoModal").modal("show"); 
			}
		} 
	] 
}); 

angular.module('unpaidPaymentAgreements', []);
angular.module('unpaidPaymentAgreements').component('unpaidPaymentAgreements', { 
	templateUrl: 'app/components/unpaidPaymentAgreements/unpaidPaymentAgreements.template.html',
	bindings: {
		accountLengthImpersonal: "<"
	},
	controller: [
		"requestManager",
		"$rootScope",
		"$scope",
		"$location",
		"util", 
		"sharedMemory",
		function (requestManager, $rootScope, $scope, $location, util, sharedMemory) {
			this.$onInit = function(){
				$scope.europeanNumberFormat = 'en-US';
				if ($rootScope.europeanNumberFormat !== undefined) {
					if ($rootScope.europeanNumberFormat === '1' || $rootScope.europeanNumberFormat === 1) {
						$scope.europeanNumberFormat = 'de-AT';
					}
					else {
						$scope.europeanNumberFormat = 'en-US';
					}
				}
				initVariables();
				initScope(); 
				loadUnpaidPaymentAgreements($rootScope.authData.sessionId, function(requestDetails){
					if(requestDetails.successful){
						$scope.unpaidPaymentAgreements.content =  requestDetails.response.data == "false" ? [] : requestDetails.response.data;
						$scope.unpaidPaymentAgreements.content.forEach(value => {
							value.amountInterval = +value.amountInterval;
							value.amountTotal = +value.amountTotal;
						});
						$scope.unpaidPaymentAgreements.contentChunked = util.divideArrayIntoChunks($scope.unpaidPaymentAgreements.content);
						$scope.unpaidPaymentAgreements.currentlyLoading = false;
					}
					else{
					
					}
				});
			};

			this.$onChanges = function (item) {
				$scope.accountLengthImpersonal = item.accountLengthImpersonal.currentValue;
			}
			
			function initScope(){
				$scope.openInfoModal = openInfoModal; 
			}

			function initVariables(){
				$scope.unpaidPaymentAgreements = {
					contentChuncked: undefined, 
					content: [],
					currentlyLoading: true
				};
				$scope.pagination =  {
					currentIndex: 0, 
					paginationButtonsArray: undefined
				}
			}

			function loadUnpaidPaymentAgreements(sessionId, callback){
				var requestDetails = {
					successful: true,
					response: undefined
				};

				requestManager.routes.paymentTerm.virtual.getPaymentTermsOverdue(sessionId).then(
					function(response){
						requestDetails.response = response;
						callback(requestDetails);
					},
					function(response){
						requestDetails.response = response;
						requestDetails.successful = false;
						callback(requestDetails);
					}
				)
			}

			$scope.forwardToCustomer = function(customerId){
				$location.path("/user-details/" + customerId)
			}
			
			function changePaginationIndex(value){
				if(value < 0){
					if($scope.pagination.currentIndex > 0){
						$scope.pagination.currentIndex += value;
					}
				}
				else{
					if($scope.pagination.currentIndex < $scope.favouriteClientsList.contentChunked.length-1){
						$scope.pagination.currentIndex += value;
					}
				}
			}
			
			function openInfoModal(){
				jQuery("#unpaidPaymentAgreementsInfoModal").modal("show"); 
			}

		} 
	] 
}); 

angular.module('vacantPosOfCustomer', []);
angular.module('vacantPosOfCustomer').component('vacantPosOfCustomer', {
    templateUrl: 'app/components/vacantPosOfCustomer/vacantPosOfCustomer.template.html',
    controller: [
        "$scope",
        "$rootScope",
        "requestManager",
        "$location",
        "$routeParams",
        "$filter",
        "Notification",
        "$route",
        "$window",
        function ($scope, $rootScope, requestManager, $location, $routeParams, $filter, Notification, $route, $window) {
            const ctrl = this;

            ctrl.onModalClosed = function () {
                $scope.dueDateModal.command = undefined;
                $scope.dueDateModal.invoice = undefined;

                $scope.dueDateOnMoreThanOneModal.command = undefined;
                $scope.dueDateOnMoreThanOneModal.invoice = undefined;
            };

            ctrl.onClose = function () {
                jQuery('#unattributableBookingsModal').one("hidden.bs.modal", () => {
                    $route.reload();
                }).modal("hide");
            }

            ctrl.$onInit = function () {
                $scope.europeanNumberFormat = 'en-US';
                if ($rootScope.europeanNumberFormat !== undefined) {
                    if ($rootScope.europeanNumberFormat === '1' || $rootScope.europeanNumberFormat === 1) {
                        $scope.europeanNumberFormat = 'de-AT';
                    } else {
                        $scope.europeanNumberFormat = 'en-US';
                    }
                }
                initVariables();
                initScope();
                // TODO check when undefined
                if ($rootScope.settings) {
                    $rootScope.settings.find(setting => {
                        if (setting.settingCode === 'transferOfUnallocatedCredits') {
                            $scope.transferOfUnallocatedCredits = setting.settingValue1;
                        }
                    });
                    $rootScope.settings.find(setting => {
                        if (setting.settingCode === 'accountForBookings') {
                            $scope.accountForBookings = setting.settingValue1;
                        }
                    });
                }

                requestManager.routes.customer.virtual.getAccountsReceivableSummaryOfCustomer($rootScope.authData.sessionId, $routeParams.id).then(onSummaryLoaded, onRequestError);

                function onSummaryLoaded(response) {
                    //reformat Data from response
                    createFinancialDataScopeObject(response);

                    //create object with references witch sub-tables are open - the invoiceNumber is going to be the key
                    $scope.financialData.content.invoices.forEach((invoice) => {
                        const isDueDateUnchanged = invoice.invoicePositions.every((invoicePosition) => {
                            const virtualDueDate = moment(invoicePosition.virtualDueDate, "YYYY-MM-DD HH:mm:ss");
                            const dueDate = moment(invoicePosition.dueDate, "YYYY-MM-DD HH:mm:ss");
                            if (invoicePosition.dueDate !== undefined) {
                                return virtualDueDate.isSame(dueDate, "day"); // When including a second parameter, it will match all units equal or larger -> Quote: moment.js documentation
                            } else {
                                return true;
                            }
                        });


                        const isInPaymentAgreement = invoice.invoicePositions.some((invoicePosition) => {
                            return invoicePosition.isInPaymentTerm;
                        });

                        $scope.invoiceViewProperties[invoice.invoiceNumber] = {
                            invoice: invoice,
                            isOpened: false,
                            isDueDateUpdated: !isDueDateUnchanged,
                            isInPaymentAgreement: isInPaymentAgreement
                        };
                    });
                }

                function onRequestError(response) {
                    if (!requestDetails.canceledRequest) {
                        sharedMemory.error.setError(
                            response,
                            "COULD_NOT_LOAD_DATA",
                            "Fehler beim erstellen eines Users",
                            $location.path(),
                            "userSettings"
                        );
                        $location.path("/error-page");
                    }
                }
            };

            function initVariables() {
                $scope.financialData = {
                    currentlyLoading: false,
                    content: {
                        amountInclInterest: undefined,
                        amountInterest: undefined,
                        interestPercentage: undefined,
                        amountExclInterest: undefined,
                        sumUndefinedCredits: undefined,
                        invoices: []
                    }
                };
                $scope.invoiceViewProperties = {};
                $scope.updateDueDateModal = {};
                $scope.dueDateModal = {
                    command: undefined,
                    invoice: undefined
                };
                $scope.dueDateOnMoreThanOneModal = {
                    command: undefined,
                    invoices: undefined
                };
                $scope.commentaryModal = {
                    createdRecordCommentaryId: undefined
                };
                $scope.invoice = undefined;
                $scope.hasDunningBlock = undefined;
                $scope.customerId = undefined;
                $scope.sameUser = undefined;
                $scope.bookDueDateChangesInDatev = undefined;
                $scope.transferOfUnallocatedCredits = undefined;
                $scope.accountForBookings = undefined;
                $scope.isEditing = false;
                $scope.selectedInvoiceIsEditing = undefined;
            }

            function initScope() {
                $scope.toggleSubtable = toggleSubtable;
                $scope.openDefermentOfPaymentModal = openDefermentOfPaymentModal;
                $scope.onChangeDueDateButtonClick = onChangeDueDateButtonClick;
                $scope.getTooltipMessage = getTooltipMessage;
                $scope.getRowStyle = getRowStyle;
                $scope.setDunningBlock = setDunningBlock;
                $scope.onChangeMoreThanOneDueDateClick = onChangeMoreThanOneDueDateClick;
                $scope.invoiceDownload = invoiceDownload;
                $scope.openCommentModal = openCommentModal;
                $scope.saveComment = saveComment;
                $scope.transfer = transfer;
                $scope.onSameUser = onSameUser;
                $scope.onDifferentUser = onDifferentUser;
                $scope.isEditingSwitch = isEditingSwitch;
                $scope.saveAmountReminders = saveAmountReminders;
            }

            function isEditingSwitch(invoice) {
                if ($scope.isEditing) {
                    $scope.isEditing = false;
                    $scope.selectedInvoiceIsEditing = undefined;
                } else {
                    $scope.isEditing = true;
                    $scope.selectedInvoiceIsEditing = invoice;
                }
            }

            function saveAmountReminders() {
                if (!$scope.selectedInvoiceIsEditing.invoicePositions[0].accountNumber) {
                    $scope.isEditing = false;
                    $scope.selectedInvoiceIsEditing = undefined;
                    return;
                }
                requestManager.routes.accountsReceivable.editAmountReminders(
                    $rootScope.authData.sessionId,
                    $scope.selectedInvoiceIsEditing.invoicePositions[0].accountNumber,
                    $scope.selectedInvoiceIsEditing.invoiceNumber,
                    $scope.selectedInvoiceIsEditing.invoiceReminders
                ).then(
                    function () {
                        $scope.isEditing = false;
                        $scope.selectedInvoiceIsEditing = false;
                        Notification.success({message: "Änderung gespeichert", delay: 5000});
                    },
                    function () {
                        Notification.warning({message: "Änderung konnte nicht gespeichert werden", delay: 5000});
                    }
                );
            }

            function onSameUser() {
                $scope.sameUser = true;
                jQuery('#transferModal').modal("hide");
                jQuery('#unattributableBookingsModal').modal("show");
            }

            function onDifferentUser() {
                $scope.sameUser = false;
                jQuery('#transferModal').modal("hide");
                jQuery('#unattributableBookingsModal').modal("show");
            }

            function transfer() {
                jQuery("#transferModal").modal("show");
            }

            function invoiceDownload(invoice) {
                const invoiceGuid = invoice.invoiceGuid.replace(/-/g, "");
                const url = 'https://webapps.datev.de/viewer-now/documents/' + invoiceGuid + '/pdf';
                $window.open(url, '_blank');
            }

            function createFinancialDataScopeObject(response) {
                $scope.financialData.content.amountInclInterest = +currency(response.data.amountInclInterest).value;
                $scope.financialData.content.amountInterest = +currency(response.data.amountInterest).value;
                $scope.financialData.content.interestPercentage = response.data.interestPercentage;
                if (response.data.sumAmount === undefined) {
                    $scope.financialData.content.amountExclInterest = 0;
                } else {
                    $scope.financialData.content.amountExclInterest = +response.data.sumAmount;
                }
                if (response.data.sumUndefinedCredits === undefined) {
                    $scope.financialData.content.sumUndefinedCredits = 0;
                } else {
                    $scope.financialData.content.sumUndefinedCredits = +response.data.sumUndefinedCredits;
                }
                angular.forEach(response.data.invoices, (invoice, key) => {
                    let invoiceFormatted = {
                        invoiceDate: angular.copy(invoice.invoiceDate),
                        invoiceDueDate: angular.copy(invoice.invoiceDueDate),
                        invoiceSum: +angular.copy(invoice.invoiceSum),
                        invoiceCurrencyCode: angular.copy(invoice.invoiceCurrencyCode),
                        invoiceGuid: angular.copy(invoice.invoiceGuid),
                        invoiceReminders: angular.copy(invoice.invoiceReminders),
                        invoiceNumber: key,
                        invoicePositions: []
                    };
                    delete invoice.invoiceDate;
                    delete invoice.invoiceDueDate;
                    delete invoice.invoiceSum;
                    delete invoice.invoiceCurrencyCode;
                    delete invoice.invoiceGuid;
                    delete invoice.invoiceReminders;

                    //this step is not necessary but prints a warning to the console when not all invoice-positions have the same
                    //invoice date - this case should not happen.
                    const isVirtualDueDateInEveryPositionSame = invoiceFormatted.invoicePositions.every((invoicePosition) => {
                        return invoicePosition.virtualDueDate === invoiceFormatted.invoicePositions[0].virtualDueDate;
                    });
                    if (!isVirtualDueDateInEveryPositionSame) {
                        console.warn("Some of the invoice-positions in one or more invoices have different virtual due dates.");
                    }
                    invoiceFormatted.invoicePositions = Object.keys(invoice).map(key => invoice[key]);
                    invoiceFormatted.virtualDueDate = moment(invoiceFormatted.invoicePositions[0].virtualDueDate, "YYYY-MM-DD HH:mm:ss").unix();
                    invoiceFormatted.invoicePositions.forEach(value => {
                        value.amountDebit = +value.amountDebit;
                    });

                    $scope.financialData.content.invoices.push(invoiceFormatted);
                });
                /*Logic used for sorting the open invoices by invoiceDate or virtualDueDate -- simply change the respective parameter below*/
                var sortedArray = $scope.financialData.content.invoices.sort(function (x, y) {
                    return x.invoiceDate - y.invoiceDate;
                })
                $scope.financialData.content.invoices = sortedArray;
            }

            function toggleSubtable(invoiceNumber) {
                $scope.invoiceViewProperties[invoiceNumber].isOpened = !$scope.invoiceViewProperties[invoiceNumber].isOpened;
            }

            function openDefermentOfPaymentModal(invoice) {
                jQuery("#defermentOfPaymentModal").modal("show");
            }

            function onChangeDueDateButtonClick(invoice) {
                $scope.bookDueDateChangesInDatev = $rootScope.settings.find(val => val.settingCode === 'bookDueDateChangesInDatev');
                // if ($scope.bookDueDateChangesInDatev && $scope.bookDueDateChangesInDatev.settingValue1 === '1') {
                //     if (new Date(invoice.invoiceDate * 1000).getFullYear() !== new Date(Date.now()).getFullYear()) {
                //         Notification.warning(
                //             {message: "Das Rechnungsdatum liegt nicht im laufenden Jahr.", delay: 5000});
                //     } else {
                //         $scope.dueDateModal.command = "open";
                //         $scope.dueDateModal.invoice = invoice;
                //     }
                // } else {
                //     $scope.dueDateModal.command = "open";
                //     $scope.dueDateModal.invoice = invoice;
                // }
                $scope.dueDateModal.command = "open";
                $scope.dueDateModal.invoice = invoice;
            }

            function onChangeMoreThanOneDueDateClick(listOfInvoices) {
                $scope.bookDueDateChangesInDatev = $rootScope.settings.find(val => val.settingCode === 'bookDueDateChangesInDatev');
                // if ($scope.bookDueDateChangesInDatev && $scope.bookDueDateChangesInDatev.settingValue1 === '1') {
                //     let currentYear = true;
                //     listOfInvoices.forEach(invoice => {
                //         if (new Date(invoice.invoiceDate * 1000).getFullYear() !== new Date(Date.now()).getFullYear()) {
                //             currentYear = false;
                //         }
                //     });
                //     if (!currentYear) {
                //         Notification.warning(
                //             {message: "Das Rechnungsdatum liegt nicht im laufenden Jahr.", delay: 5000});
                //     } else {
                //         $scope.dueDateOnMoreThanOneModal.command = "open";
                //         $scope.dueDateOnMoreThanOneModal.invoice = listOfInvoices;
                //     }
                // } else {
                //     $scope.dueDateOnMoreThanOneModal.command = "open";
                //     $scope.dueDateOnMoreThanOneModal.invoice = listOfInvoices;
                // }
                $scope.dueDateOnMoreThanOneModal.command = "open";
                $scope.dueDateOnMoreThanOneModal.invoice = listOfInvoices;
            }

            function getTooltipMessage(invoiceProperties, invoice) {
                // console.log('INVOICE!: ', invoice);
                if (invoiceProperties.isInPaymentAgreement && invoiceProperties.isDueDateUpdated) {
                    return "Ist in Zahlungsvereinbarung und Fälligkeitsdatum wurde verändert! Ursprüngliches Fälligkeitsdatum: " + moment(invoice.invoiceDueDate * 1000).format("DD.MM.YYYY");
                }
                if (invoiceProperties.isInPaymentAgreement) {
                    return "Ist in Zahlungsvereinbarung";
                }
                if (invoiceProperties.isDueDateUpdated) {
                    return "Fälligkeitsdatum wurde verändert! Altes Fälligkeitsdatum: " + moment(invoice.invoicePositions[0].dueDate, "YYYY-MM-DD HH:mm:ss").format("DD.MM.YYYY");
                }
                return undefined;
            }

            function getRowStyle(invoiceProperties) {
                if (invoiceProperties.isInPaymentAgreement && invoiceProperties.isDueDateUpdated) {
                    return {
                        "background-color": "#ff9999",
                    };
                }
                if (invoiceProperties.isInPaymentAgreement) {
                    return {
                        "background-color": "#a0c5ff",
                    };
                }
                if (invoiceProperties.isDueDateUpdated) {
                    return {
                        "background-color": "#ffcfa0",
                    };
                }
                return {};
            }

            function setDunningBlock(invoice, hasDunningBlock) {
                const requestSummary = {
                    numRequestsPlanned: invoice.invoicePositions.length,
                    numRequestsFinished: 0,
                    withErrors: false,
                    errorResponses: []
                };

                const isValueInAllInvoicePositionsSame = invoice.invoicePositions.every((invoicePosition) => {
                    return invoicePosition.hasDunningBlock === invoice.invoicePositions[0].hasDunningBlock;
                });

                if (!isValueInAllInvoicePositionsSame) {
                    console.warn("The hasDunningBlock property differs in invoice-positions in one or more invoices");
                }

                if ($window.confirm($filter("translate")("vacantPosOfCustomer.CONFIRM_DUNNING_BLOCK"))) {
                    console.log(invoice);
                    invoice.invoicePositions.forEach((invoicePosition) => {
                        if (invoice.invoicePositions[0].hasDunningBlock === "1") {
                            // deactivate
                            requestManager.routes.accountsReceivable.deactivateAccountsReceivableDunningBlock(
                                $rootScope.authData.sessionId,
                                invoicePosition.accountNumber,
                                invoicePosition.openItemNumber
                            ).then(
                                onEditAccountsReceivableRequestSuccess,
                                onRequestError
                            );
                        } else {
                            // activate
                            requestManager.routes.accountsReceivable.activateAccountsReceivableDunningBlock(
                                $rootScope.authData.sessionId,
                                invoicePosition.accountNumber,
                                invoicePosition.openItemNumber
                            ).then(
                                onEditAccountsReceivableRequestSuccess,
                                onRequestError
                            );
                        }
                    });
                }

                function onEditAccountsReceivableRequestSuccess() {
                    requestSummary.numRequestsFinished++;
                    checkRequestStatus();
                }

                function onRequestError(response) {
                    if (response && response.status === 403) {
                        Notification.error($filter("translate")("userSettings.ERROR_403_USER_NEED_APPROPRIATE_PERMISSION"));
                        $scope.updateDueDateModal.currentlyLoading = false;
                        jQuery("#defermentOfMultiplePaymentModal").modal("hide");
                    } else {
                        requestSummary.numRequestsFinished++;
                        requestSummary.withErrors = true;
                        requestSummary.errorResponses.push(response);
                        checkRequestStatus();
                    }
                }

                function checkRequestStatus() {
                    if (requestSummary.numRequestsFinished === requestSummary.numRequestsPlanned) {
                        if (requestSummary.withErrors) {
                            Notification.error($filter("translate")("vacantPosOfCustomer.CHANGED_SUCCESSFULLY"));
                        } else {
                            const reasonForPauseOfDemands = $rootScope.settings.find(setting => setting.settingCode === 'reasonForPauseOfDemands');
                            if (reasonForPauseOfDemands !== undefined) {
                                if (hasDunningBlock !== '1' && (reasonForPauseOfDemands.settingValue1 === '1' || reasonForPauseOfDemands.value1 === '1')) {
                                    createRecord($rootScope.authData.sessionId, $routeParams["id"], "record_comment", function (requestDetails) {
                                        if (requestDetails.successful) {
                                            createRecordComment($rootScope.authData.sessionId, requestDetails.response.data, $scope.commentaryModal.text, function (createRecordCommentRequestDetails) {
                                                if (createRecordCommentRequestDetails.successful) {
                                                    Notification.success($filter("translate")("userDetailsActions.COMMENT_CREATED"));
                                                    Notification.success($filter("translate")("vacantPosOfCustomer.CHANGED_SUCCESSFULLY"));
                                                    jQuery('#createCommentModal').modal("hide");
                                                    $window.location.reload();
                                                } else {
                                                    jQuery('#createCommentModal').one('hidden.bs.modal', function () {
                                                        sharedMemory.error.setError(
                                                            createRecordCommentRequestDetails,
                                                            "COULD_NOT_LOAD_DATA",
                                                            "Fehler beim erstellen eines recordComment",
                                                            $location.path(),
                                                            "userDetailsAction"
                                                        );
                                                        $location.path("/error-page");
                                                    }).modal("hide");
                                                }
                                            })
                                        } else {
                                            jQuery('#createCommentModal').one('hidden.bs.modal', function () {
                                                sharedMemory.error.setError(
                                                    requestDetails,
                                                    "COULD_NOT_LOAD_DATA",
                                                    "Fehler beim erstellen eines record",
                                                    $location.path(),
                                                    "userDetailsAction"
                                                );
                                                $location.path("/error-page");
                                            }).modal("hide");
                                        }
                                    });
                                }
                            } else {
                                Notification.success($filter("translate")("vacantPosOfCustomer.CHANGED_SUCCESSFULLY"));
                                $route.reload();
                            }
                        }
                    }
                }
            }

            function openCommentModal(invoice, hasDunningBlock) {
                $scope.invoice = undefined;
                $scope.invoice = invoice;
                $scope.hasDunningBlock = hasDunningBlock;
                console.log($rootScope.settings.find(setting => setting.settingCode === 'reasonForPauseOfDemands'));
                const reasonForPauseOfDemands = $rootScope.settings.find(setting => setting.settingCode === 'reasonForPauseOfDemands');
                if (reasonForPauseOfDemands === undefined) {
                    setDunningBlock($scope.invoice, $scope.hasDunningBlock);
                } else {
                    if (hasDunningBlock === '1') {
                        setDunningBlock($scope.invoice, $scope.hasDunningBlock);
                    } else {
                        if (reasonForPauseOfDemands.settingValue1 === '1' || reasonForPauseOfDemands.value1 === '1') {
                            jQuery("#commentModal").modal("show");
                        } else {
                            setDunningBlock($scope.invoice, $scope.hasDunningBlock);
                        }
                    }
                }
            }

            function saveComment() {
                if ($scope.commentForm.$invalid) {
                    return;
                }
                setDunningBlock($scope.invoice, $scope.hasDunningBlock);
            }

            function createRecord(sessionId, customerId, recordType, callback, addsToDunningLevel = 0) {
                var requestDetails = {
                    successful: true,
                    response: undefined
                };

                requestManager.routes.customer.createRecordOfCustomer(sessionId, customerId, recordType, addsToDunningLevel).then(
                    function (response) {
                        requestDetails.response = response;
                        callback(requestDetails);
                    },
                    function (response) {
                        requestDetails.response = response;
                        requestDetails.successful = false;
                        callback(requestDetails);
                    }
                );
            }

            function createRecordComment(sessionId, recordId, commText, callback) {
                var requestDetails = {
                    successful: true,
                    response: undefined
                };

                requestManager.routes.record.createCommentRecordOfRecord(sessionId, recordId, commText).then(
                    function (response) {
                        requestDetails.response = response;
                        callback(requestDetails);
                    },
                    function (response) {
                        requestDetails.response = response;
                        requestDetails.successful = false;
                        callback(requestDetails);
                    }
                );
            }
        }
    ]
}); 

angular.module('vacantPosOfCustomer').component('changeDueDateModal', {
    templateUrl: 'app/components/vacantPosOfCustomer/components/changeDueDateModal/changeDueDateModal.template.html',
    bindings: {
        command: "<",
        invoice: "<",
        onModalClosed: "&",
        bookDueDateChangesInDatev: "<",
        accountForBookings: "<"
    },
    controller: [
        "$scope",
        "$rootScope",
        "requestManager",
        "$location",
        "$routeParams",
        "$route",
        "Notification",
        "$window",
        "$filter",
        function ($scope, $rootScope, requestManager, $location, $routeParams, $route, Notification, $window, $filter) {
            const ctrl = this;

            ctrl.$onChanges = function (change) {
                if (change.command && change.command.currentValue === "open") {
                    jQuery("#defermentOfPaymentModal").modal("show");
                }

                if (change.invoice) {
                    $scope.invoice = change.invoice.currentValue;
                }

                if (change.bookDueDateChangesInDatev) {
                    $scope.bookDueDateChangesInDatev = change.bookDueDateChangesInDatev.currentValue;
                }
                if (change.accountForBookings && change.accountForBookings.currentValue !== undefined) {
                    $scope.accountForBookings = change.accountForBookings.currentValue;
                    if ($scope.accountForBookings.length !== 8) {
                        $scope.accountForBookings += '0'.repeat(8 - $scope.accountForBookings.length);
                    }
                }
            };

            ctrl.$onInit = function () {
                $scope.updateDueDateModal = {
                    date: undefined,
                    text: undefined,
                    currentlyLoading: false,
                };
                $scope.invoice = undefined;

                $scope.closeModal = closeModal;
                $scope.onSaveDueDateClick = onSaveDueDateClick;
            };

            function closeModal() {
                jQuery("#defermentOfPaymentModal").modal("hide");
                ctrl.onModalClosed();
            }

            function onSaveDueDateClick() {
                if ($scope.dueDateForm.$invalid) {
                    return;
                }

                const newDueDate = moment($scope.updateDueDateModal.date, "DDMMYYYY");
                let commentText = $scope.updateDueDateModal.text + "<br><br>Neues Fälligkeitsdatum: " + newDueDate.format("DD.MM.YYYY") + "<br>Rechnung: " + $scope.invoice.invoiceNumber + "<br>";

                $scope.updateDueDateModal.currentlyLoading = true;

                $scope.requestSummary = {
                    invoicePositionPlanned: $scope.invoice.invoicePositions.length,
                    invoicePositionFinished: 0,
                    numRequestsPlanned: 2,
                    numRequestsFinished: 0,
                    withErrors: false,
                    errorResponses: []
                };

                if ($scope.bookDueDateChangesInDatev && $scope.bookDueDateChangesInDatev.settingValue1 === '1') {
                    if ($window.confirm($filter("translate")("vacantPosOfCustomer.CONFIRMATION_DIALOG"))) {
                        commentText += "Änderung wurde in DATEV gebucht.";
                        let accounting_records = [];
                        let amount = 0;
                        let record1;
                        let record2;
                        $scope.invoice.invoicePositions.forEach((invoicePosition) => {
                            let date = invoicePosition.date;
                            if (new Date().getFullYear() !== new Date(date).getFullYear()) {
                                date = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
                            }
                            console.log(date);
                            record1 = {
                                account_number: invoicePosition.accountNumber,
                                contra_account_number: $scope.accountForBookings,
                                date: date,
                                debit_credit_identifier: "H",
                                posting_description: "Änderung Fälligkeit Ausbuchen",
                            };
                            record2 = {
                                account_number: invoicePosition.accountNumber,
                                contra_account_number: $scope.accountForBookings,
                                date: date,
                                document_field2: newDueDate.format("DDMMYY"),
                                debit_credit_identifier: "S",
                                posting_description: "Änderung Fälligkeit Neues Datum",
                            };
                            if (invoicePosition.documentField1) {
                                record1.document_field1 = invoicePosition.documentField1;
                                record2.document_field1 = invoicePosition.documentField1;
                            }
                            if (invoicePosition.documentLink) {
                                record1.document_link = invoicePosition.documentLink;
                                record1.document_system = "DORG";
                                record2.document_link = invoicePosition.documentLink;
                                record2.document_system = "DORG";
                            }
                            if (invoicePosition.costCenterId) {
                                record1.kost1_cost_center_id = invoicePosition.costCenterId;
                                record2.kost1_cost_center_id = invoicePosition.costCenterId;
                            }
                            amount += invoicePosition.amountDebit;
                        });
                        record1.amount = amount;
                        record2.amount = amount;
                        accounting_records.push(record1);
                        accounting_records.push(record2);
                        const params = {
                            accounting_reason: "independent_from_accounting_reason",
                            application_information: "Debitorcloud",
                            date_from: convertEpochToDateTime($scope.invoice.virtualDueDate),
                            date_to: newDueDate.format("YYYY-MM-DD HH:mm:ss"),
                            initials: "DC",
                            description: "DC Änderung Fälligkeitsdatum",
                            is_committed: false,
                            record_type: "financial_accounting",
                            accounting_records: accounting_records,
                        };
                        requestManager.routes.accountsReceivable.postDATEV($rootScope.authData.sessionId, params).then(function () {
                            $scope.invoice.invoicePositions.forEach((invoicePosition) => {
                                const params = {
                                    dueDate: invoicePosition.virtualDueDate,
                                    virtualDueDate: newDueDate.format("YYYY-MM-DD HH:mm:ss"),
                                    virtualDueDateEntry: moment().format("YYYY-MM-DD HH:mm:ss")
                                };
                                requestManager.routes.accountsReceivable.editAccountsReceivable($rootScope.authData.sessionId, invoicePosition.id, params).then(onAccountsReceivableRequestSuccess, onRequestError);
                            });
                        }, onRequestError);
                    } else {
                        jQuery("#defermentOfPaymentModal").modal("hide");
                        ctrl.onModalClosed();
                        $scope.updateDueDateModal.currentlyLoading = false;
                    }
                } else {
                    $scope.invoice.invoicePositions.forEach((invoicePosition) => {
                        const params = {
                            dueDate: invoicePosition.virtualDueDate,
                            virtualDueDate: newDueDate.format("YYYY-MM-DD HH:mm:ss"),
                            virtualDueDateEntry: moment().format("YYYY-MM-DD HH:mm:ss")
                        };
                        requestManager.routes.accountsReceivable.editAccountsReceivable($rootScope.authData.sessionId, invoicePosition.id, params).then(onAccountsReceivableRequestSuccess, onRequestError);
                    });
                }

                function onAccountsReceivableRequestSuccess() {
                    $scope.requestSummary.invoicePositionFinished++;
                    if ($scope.requestSummary.invoicePositionPlanned !== $scope.requestSummary.invoicePositionFinished) {
                        return;
                    }
                    requestManager.routes.customer.createRecordOfCustomer($rootScope.authData.sessionId, $routeParams.id, "record_comment").then(onCreateRecordRequestSuccess, onRequestError);
                    // updateRequestStatus();
                }

                function onCreateRecordRequestSuccess(response) {
                    updateRequestStatus();
                    requestManager.routes.record.createCommentRecordOfRecord($rootScope.authData.sessionId, response.data, commentText).then(onCreateRecordCommentRequestSuccess, onRequestError);
                }

                function onCreateRecordCommentRequestSuccess() {
                    updateRequestStatus();
                }

                function onRequestError(response) {
                    if (response && response.status === 403) {
                        Notification.error($filter("translate")("userSettings.ERROR_403_USER_NEED_APPROPRIATE_PERMISSION"));
                        $scope.updateDueDateModal.currentlyLoading = false;
                        jQuery("#defermentOfPaymentModal").modal("hide");
                    } else {
                        updateRequestStatus(response);
                    }
                }

                function updateRequestStatus(errorResponse) {
                    $scope.requestSummary.numRequestsFinished++;
                    if (errorResponse) {
                        $scope.requestSummary.withErrors = true;
                        $scope.requestSummary.errorResponses.push(errorResponse);
                    }

                    if ($scope.requestSummary.withErrors) {
                        jQuery("#defermentOfPaymentModal").one("hidden.bs.modal",()=>{
                            Notification.error("Bei der Änderung des Fälligkeitsdatums ist es zu einem Fehler gekommen.");
                            $route.reload();
                        }).modal("hide");
                    }
                    if ($scope.requestSummary.numRequestsFinished === $scope.requestSummary.numRequestsPlanned) {
                        jQuery("#defermentOfPaymentModal").one("hidden.bs.modal",()=>{
                            if($scope.requestSummary.withErrors){
                                Notification.error("Bei der Änderung des Fälligkeitsdatums ist es zu einem Fehler gekommen."); //
                            } else {
                                if ($scope.bookDueDateChangesInDatev && $scope.bookDueDateChangesInDatev.settingValue1 === '1') {
                                    Notification.warning(
                                        {message: "Lieber Anwender, deine Buchungen wurden erfolgreich an die DATEV Buchhaltung übermittelt. " +
                                                "Dein Steuerberater prüft diese Buchungen nun. Danach werden die Buchungen in die Buchhaltung " +
                                                "effektiv übernommen und in die Debitorcloud übermittelt.<br><strong>Bitte beachte, dass du erst danach " +
                                                "deine gerade verbuchten Änderungen sehen kannst</strong>", delay: 20000});
                                }
                            }
                            $route.reload();
                        }).modal("hide");
                    }
                }

                function convertEpochToDateTime(epochTime) {
                    let date = new Date(epochTime * 1000);

                    let year = date.getFullYear();
                    let month = ("0" + (date.getMonth() + 1)).slice(-2);
                    let day = ("0" + date.getDate()).slice(-2);

                    let hours = ("0" + date.getHours()).slice(-2);
                    let minutes = ("0" + date.getMinutes()).slice(-2);
                    let seconds = ("0" + date.getSeconds()).slice(-2);

                    return year + "-" + month + "-" + day + " " + hours + ":" + minutes + ":" + seconds;
                }
            }
        }
    ]
});

angular.module('vacantPosOfCustomer').component('changeDueDateOnMoreThanOneModal', {
    templateUrl: 'app/components/vacantPosOfCustomer/components/changeDueDateOnMoreThanOneModal/changeDueDateOnMoreThanOneModal.template.html',
    bindings: {
        command: "<",
        invoice: "<",
        onModalClosed: "&",
        bookDueDateChangesInDatev: "<",
        accountForBookings: "<"
    },
    controller: [
        "$scope",
        "$rootScope",
        "requestManager",
        "$location",
        "$routeParams",
        "$route",
        "Notification",
        "$filter",
        "$window",
        function ($scope, $rootScope, requestManager, $location, $routeParams, $route, Notification, $filter, $window) {
            const ctrl = this;

            ctrl.$onChanges = function (change) {
                if (change.command && change.command.currentValue === "open") {
                    jQuery("#defermentOfMultiplePaymentModal").modal("show");
                }

                if (change.invoice) {
                    $scope.invoice = change.invoice.currentValue;
                    if($scope.invoice){
                        $scope.invoice.forEach(function (inv) {
                            inv.selected = false;
                        });

                    }
                }

                if (change.bookDueDateChangesInDatev) {
                    $scope.bookDueDateChangesInDatev = change.bookDueDateChangesInDatev.currentValue;
                }
                if (change.accountForBookings && change.accountForBookings.currentValue !== undefined) {
                    $scope.accountForBookings = change.accountForBookings.currentValue;
                    if ($scope.accountForBookings.length !== 8) {
                        $scope.accountForBookings += '0'.repeat(8 - $scope.accountForBookings.length);
                    }
                }
            };

            ctrl.$onInit = function () {
                $scope.updateDueDateModal = {
                    date: undefined,
                    text: undefined,
                    currentlyLoading: false,
                };
                $scope.infoModal = {
                    title: undefined,
                    body: undefined
                };

                $scope.invoice = undefined;
                $scope.checkedInvoices = [];
                $scope.commentText = undefined;

                $scope.closeModal = closeModal;
                $scope.onSaveDueDateClick = onSaveDueDateClick;
                $scope.parseUnixTimeStampToDate = parseUnixTimeStampToDate;
                $scope.parseNewDueDateToReadableForm = parseNewDueDateToReadableForm;
                $scope.openInfoModal = openInfoModal;
            };

            function closeModal() {
                $scope.checkedInvoices = [];
                jQuery("#defermentOfMultiplePaymentModal").modal("hide");
                ctrl.onModalClosed();
            }

            function parseUnixTimeStampToDate(timestamp){
                var formatted = moment.unix(timestamp);
                formatted = formatted.format("DD.MM.YYYY");
                return formatted;
            }

            function parseNewDueDateToReadableForm(){
                if (!$scope.updateDueDateModal.date){
                    return;
                }
                var readableDueDate = moment($scope.updateDueDateModal.date, "DDMMYYYY");
                readableDueDate = readableDueDate.format("DD.MM.YYYY");
                return readableDueDate;
            }

            function openInfoModal(key){
                switch(key){
                    case "tableInformation":
                        $scope.infoModal.title = "Information bzgl Rechnungsspeicherung";
                        $scope.infoModal.body = "Eine Tabelle mit der Liste von gewählten Rechnungen mit ursprünglichem und neuem Datum wird an das Ende Ihres Kommentars angehängt.";
                        break;
                }

                jQuery("#tableInfoModal").modal("show");
                $(document).find('.child-modal').on('hidden.bs.modal', function () {
                    $('body').addClass('modal-open');
                });
            }


            function onSaveDueDateClick() {
                if ($scope.dueDateForm.$invalid) {
                    return;
                }
                $scope.commentText = $scope.updateDueDateModal.text + "<table class=\\'table table-striped\\'><thead><tr><th>Rechnungsnr.</th><th>Ursprüngliches Fälligkeitsdatum</th><th>Neues Fälligkeitsdatum</th></tr></thead><tbody>";

                $scope.requestSummary = {
                    numRequestsPlanned: 2,
                    numRequestsFinished: 0,
                    withErrors: false,
                    errorResponses: []
                };

                if ($scope.bookDueDateChangesInDatev && $scope.bookDueDateChangesInDatev.settingValue1 === '1') {
                    if ($window.confirm($filter("translate")("vacantPosOfCustomer.CONFIRMATION_DIALOG"))) {
                        let date_from = undefined;
                        let date_to = undefined;

                        let accounting_records = [];
                        angular.forEach($scope.invoice, function (inv) {
                            if (inv.selected) {
                                const newDueDate = moment($scope.updateDueDateModal.date, "DDMMYYYY");

                                $scope.updateDueDateModal.currentlyLoading = true;

                                let amount = 0;
                                let record1;
                                let record2;
                                inv.invoicePositions.forEach((invoicePosition) => {
                                    let date = invoicePosition.date;
                                    if (new Date().getFullYear() !== new Date(date).getFullYear()) {
                                        date = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
                                    }
                                    console.log(date);
                                    record1 = {
                                        account_number: invoicePosition.accountNumber,
                                        amount: invoicePosition.amountDebit,
                                        contra_account_number: $scope.accountForBookings,
                                        date: date,
                                        debit_credit_identifier: "H",
                                        posting_description: "Änderung Fälligkeit Ausbuchen",
                                    };
                                    record2 = {
                                        account_number: invoicePosition.accountNumber,
                                        amount: invoicePosition.amountDebit,
                                        contra_account_number: $scope.accountForBookings,
                                        date: date,
                                        document_field2: newDueDate.format("DDMMYY"),
                                        debit_credit_identifier: "S",
                                        posting_description: "Änderung Fälligkeit Neues Datum",
                                    };
                                    if (invoicePosition.documentField1) {
                                        record1.document_field1 = invoicePosition.documentField1;
                                        record2.document_field1 = invoicePosition.documentField1;
                                    }
                                    if (invoicePosition.documentLink) {
                                        record1.document_link = invoicePosition.documentLink;
                                        record1.document_system = "DORG";
                                        record2.document_link = invoicePosition.documentLink;
                                        record2.document_system = "DORG";
                                    }
                                    if (invoicePosition.costCenterId) {
                                        record1.kost1_cost_center_id = invoicePosition.costCenterId;
                                        record2.kost1_cost_center_id = invoicePosition.costCenterId;
                                    }
                                    amount += invoicePosition.amountDebit;
                                    if (date_from === undefined || new Date(date_from).getTime() > new Date(date).getTime()) {
                                        date_from = date;
                                    }
                                    if (date_to === undefined || new Date(date_to).getTime() < new Date(date).getTime()) {
                                        date_to = date;
                                    }
                                });
                                record1.amount = amount;
                                record2.amount = amount;
                                accounting_records.push(record1);
                                accounting_records.push(record2);
                            }
                        });
                        const params = {
                            accounting_reason: "independent_from_accounting_reason",
                            application_information: "Debitorcloud",
                            date_from: date_from,
                            date_to: date_to,
                            initials: "DC",
                            description: "DC Änderung Fälligkeitsdatum",
                            is_committed: false,
                            record_type: "financial_accounting",
                            accounting_records: accounting_records
                        };
                        requestManager.routes.accountsReceivable.postDATEV($rootScope.authData.sessionId, params).then(function () {
                            angular.forEach($scope.invoice, function (inv) {
                                if (inv.selected) {
                                    const newDueDate = moment($scope.updateDueDateModal.date, "DDMMYYYY");
                                    const oldDueDate = moment(inv.invoicePositions[0].virtualDueDate, "YYYY-MM-DD HH:mm:ss");
                                    $scope.commentText = $scope.commentText + "<tr><td>" + inv.invoiceNumber + "</td><td>" + oldDueDate.format("DD.MM.YYYY") + "</td><td>" + newDueDate.format("DD.MM.YYYY") + "</td></tr>";
                                    $scope.updateDueDateModal.currentlyLoading = true;

                                    inv.invoicePositions.forEach((invoicePosition) => {
                                        $scope.requestSummary.numRequestsPlanned += 1;
                                        const params = {
                                            dueDate: invoicePosition.virtualDueDate,
                                            virtualDueDate: newDueDate.format("YYYY-MM-DD HH:mm:ss"),
                                            virtualDueDateEntry: moment().format("YYYY-MM-DD HH:mm:ss")
                                        };
                                        requestManager.routes.accountsReceivable.editAccountsReceivable($rootScope.authData.sessionId, invoicePosition.id, params).then(onAccountsReceivableRequestSuccess, onRequestError);
                                    });
                                }
                            });
                        }, onRequestError);
                    }
                } else {
                    angular.forEach($scope.invoice, function (inv) {
                        if (inv.selected) {
                            const newDueDate = moment($scope.updateDueDateModal.date, "DDMMYYYY");
                            const oldDueDate = moment(inv.invoicePositions[0].virtualDueDate, "YYYY-MM-DD HH:mm:ss");
                            $scope.commentText = $scope.commentText + "<tr><td>" + inv.invoiceNumber + "</td><td>" + oldDueDate.format("DD.MM.YYYY") + "</td><td>" + newDueDate.format("DD.MM.YYYY") + "</td></tr>";
                            $scope.updateDueDateModal.currentlyLoading = true;

                            $scope.requestSummary.numRequestsPlanned += inv.invoicePositions.length;
                            inv.invoicePositions.forEach((invoicePosition) => {
                                const params = {
                                    dueDate: invoicePosition.virtualDueDate,
                                    virtualDueDate: newDueDate.format("YYYY-MM-DD HH:mm:ss"),
                                    virtualDueDateEntry: moment().format("YYYY-MM-DD HH:mm:ss")
                                };
                                requestManager.routes.accountsReceivable.editAccountsReceivable($rootScope.authData.sessionId, invoicePosition.id, params).then(onAccountsReceivableRequestSuccess, onRequestError);
                            });
                        }
                    });
                }

                function onAccountsReceivableRequestSuccess() {
                    updateRequestStatus();
                }

                function onCreateRecordRequestSuccess(response) {
                    $scope.requestSummary.numRequestsFinished++;
                    requestManager.routes.record.createCommentRecordOfRecord($rootScope.authData.sessionId, response.data, $scope.commentText).then(onCreateRecordCommentRequestSuccess, onRequestError);
                }

                function onCreateRecordCommentRequestSuccess() {
                    updateRequestStatus();
                }

                function onRequestError(response) {
                    if (response && response.status === 403) {
                        Notification.error($filter("translate")("userSettings.ERROR_403_USER_NEED_APPROPRIATE_PERMISSION"));
                        $scope.updateDueDateModal.currentlyLoading = false;
                        jQuery("#defermentOfMultiplePaymentModal").modal("hide");
                    } else {
                        updateRequestStatus(response);
                    }
                }

                function updateRequestStatus(errorResponse) {
                    $scope.requestSummary.numRequestsFinished++;
                    if (errorResponse) {
                        $scope.requestSummary.withErrors = true;
                        $scope.requestSummary.errorResponses.push(errorResponse);
                    }

                    if ($scope.requestSummary.withErrors) {
                        jQuery("#defermentOfMultiplePaymentModal").one("hidden.bs.modal",()=>{
                            Notification.error("Bei der Änderung des Fälligkeitsdatums ist es zu einem Fehler gekommen.");
                            $route.reload();
                        }).modal("hide");
                    }
                    if ($scope.requestSummary.numRequestsPlanned === $scope.requestSummary.numRequestsFinished + 2) {
                        $scope.commentText = $scope.commentText + "</tbody></table>" + "<br>";
                        if ($scope.bookDueDateChangesInDatev && $scope.bookDueDateChangesInDatev.settingValue1 === '1') {
                            $scope.commentText += "Änderung wurde in DATEV gebucht.";
                        }
                        requestManager.routes.customer.createRecordOfCustomer($rootScope.authData.sessionId, $routeParams.id, "record_comment").then(onCreateRecordRequestSuccess, onRequestError);
                        return;
                    }
                    if ($scope.requestSummary.numRequestsFinished === $scope.requestSummary.numRequestsPlanned) {
                        jQuery("#defermentOfMultiplePaymentModal").one("hidden.bs.modal",()=>{
                            if($scope.requestSummary.withErrors){
                                Notification.error("Bei der Änderung des Fälligkeitsdatums ist es zu einem Fehler gekommen."); //
                            } else {
                                if ($scope.bookDueDateChangesInDatev && $scope.bookDueDateChangesInDatev.settingValue1 === '1') {
                                    Notification.warning(
                                        {message: "Lieber Anwender, deine Buchungen wurden erfolgreich an die DATEV Buchhaltung übermittelt. " +
                                                "Dein Steuerberater prüft diese Buchungen nun. Danach werden die Buchungen in die Buchhaltung " +
                                                "effektiv übernommen und in die Debitorcloud übermittelt.<br><strong>Bitte beachte, dass du erst danach " +
                                                "deine gerade verbuchten Änderungen sehen kannst</strong>", delay: 20000});
                                }
                            }
                        $route.reload();
                        }).modal("hide");
                    }
                }
            }
        }
    ]
});

angular.module("vacantPosOfCustomer").directive('dueDateChangedTooltip', [
    function(){
        return {
            restrict: 'A',
            scope: {
                tooltipMessage: "="
            },
            link: function(scope, element, attrs){
                jQuery(element).tooltip({
                    title: scope.tooltipMessage,
                });


                jQuery(element).hover(
                    function(){
                        if(this.tooltipMessage){
                            jQuery(element).tooltip("show");
                        }
                    },
                    function(){
                        jQuery(element).tooltip("hide");
                    }
                );
            }
        };
    }
]);
angular.module('vacantPosOfCustomer').component('unattributableBookings', {
    templateUrl: 'app/components/vacantPosOfCustomer/components/unattributableBookings/unattributableBookings.template.html',
    bindings: {
        sameUser: '<',
        onClose: "&",
        sumOfOtherCredits: '<'
    },
    controller: [
        "$scope",
        "$rootScope",
        "requestManager",
        "$location",
        "util",
        "$routeParams",
        "$route",
        "paginationManager",
        "Notification",
        "$window",
        "$filter",
        function ($scope, $rootScope, requestManager, $location, util, $routeParams, $route, paginationManager, Notification, $window, $filter) {
            const ctrl = this;

            ctrl.$onChanges = function (value) {
                if (value.sameUser && value.sameUser.currentValue !== undefined) {
                    $scope.sameUser = value.sameUser.currentValue;
                    getData();
                }
                if (value.sumOfOtherCredits && value.sumOfOtherCredits.currentValue !== undefined) {
                    $scope.sumOfOtherCredits = value.sumOfOtherCredits.currentValue;
                }
            }

            ctrl.$onInit = function() {
                initVariables();
                initScope();
                getData();
            }

            function initVariables(){
                $scope.userId = undefined;
                $scope.accountsReceivable = {
                    content: undefined,
                    loading: true
                };
                $scope.sumOfOtherCredits = undefined;
                $scope.sumOfAllEnteredValues = undefined;
                $scope.diff = undefined;
                $scope.sameUser = undefined;
                $scope.filter = {
                    sortBy: undefined,
                    sortAscending: true,
                    searchTerm: undefined
                };
                $scope.userList = {
                    currentlyLoading: true,
                    userList: undefined
                };
                $scope.userChunkedList = [];
                $scope.pagination = {
                    paginationIndex: paginationManager.readIndex() === undefined ? 0 : paginationManager.readIndex(),
                    paginationArray: []
                };
                $scope.hideBackButton = true;
                $scope.checkAllCheckbox = false;
                $scope.commentText = undefined;
                $scope.toCustomer = undefined;
                $scope.bookLoading = false;
            }

            function initScope(){
                $scope.allCheckedClicked = allCheckedClicked;
                $scope.getData = getData;
                $scope.applyFilterToList = applyFilterToList;
                $scope.forwardToUser = forwardToUser;
                $scope.resetFilter = resetFilter;
                $scope.decreasePaginationIndex = decreasePaginationIndex;
                $scope.goToPage = goToPage;
                $scope.increasePaginationIndex = increasePaginationIndex;
                $scope.back = back;
                $scope.cancel = cancel;
                $scope.assign = assign;
                $scope.inputClicked = inputClicked;
                $scope.numberToFixed = numberToFixed;
                $scope.isAllChecked = isAllChecked;
            }

            function getData() {
                if ($scope.accountsReceivable === undefined) {
                    $scope.accountsReceivable = {}
                }
                $scope.accountsReceivable.loading = true;

                if ($scope.sameUser === undefined) {
                    return;
                }

                if ($scope.sameUser) {
                    let id = $routeParams.id;
                    if ($scope.userId !== undefined) {
                        id = $scope.userId;
                        $scope.hideBackButton = false;
                    } else {
                        $scope.hideBackButton = true;
                    }
                    requestManager.routes.customer.virtual.getAccountsReceivableSummaryOfCustomer($rootScope.authData.sessionId, id).then(
                        onAccountsReceivableRequestFinished,
                        onRequestError
                    );

                    function onAccountsReceivableRequestFinished(response){
                        const filteredInvoices = {};
                        if ($scope.sumOfOtherCredits !== 0) {
                            $scope.sumOfOtherCredits = -1 * $scope.sumOfOtherCredits;
                        }
                        $scope.sumOfAllEnteredValues = 0;
                        $scope.diff = - $scope.sumOfAllEnteredValues + $scope.sumOfOtherCredits;
                        angular.forEach(response.data.invoices, (invoice, key) => {
                            delete invoice.invoiceDate;
                            delete invoice.invoiceDueDate;
                            delete invoice.invoiceSum;
                            delete invoice.invoiceCurrencyCode;
                            delete invoice.invoiceGuid;
                            delete invoice.invoiceReminders;
                            response.data.invoices[key] = Object.values(invoice);

                            if(!invoice[0].isInPaymentTerm){
                                filteredInvoices[key] = Object.values(invoice);
                            }
                        });
                        $scope.accountsReceivable.content = filteredInvoices;
                        angular.forEach($scope.accountsReceivable.content, invoice => {
                            let invoiceSum = 0;
                            invoice.forEach((invoicePosition) => {
                                if(invoicePosition.amountDebit){
                                    invoiceSum += parseFloat(invoicePosition.amountDebit);
                                }
                            });
                            invoice.selected = false;
                            invoice.sumOfAllPositions = invoiceSum;
                            invoice.enteredValue = invoiceSum;
                        });
                    }
                } else {
                    loadUserList($rootScope.authData, function(requestDetails) {
                        if (requestDetails.successful) {
                            $scope.userList.content = requestDetails.response.data.customers;
                            $scope.userList.currentlyLoading = false;

                            var filter = undefined;
                            filter = $rootScope.userSettings.find((setting) => {
                                return setting.settingCode === "filterChoice";
                            });
                            if (filter !== undefined) {
                                $scope.filterSettingId = filter.id;
                                applyFilterToList(filter.value1);
                            }

                        } else {
                            if (!requestDetails.canceledRequest) {
                                sharedMemory.error.setError(
                                    requestDetails,
                                    "COULD_NOT_LOAD_DATA",
                                    "Fehler beim laden der Userliste (beim initiieren der Komponente)",
                                    $location.path(),
                                    "admonishedClientsList"
                                );
                                $location.path("/error-page");
                            }
                        }
                    });
                }
                $scope.accountsReceivable.loading = false;
            }

            function back() {
                $scope.sameUser = false;
                $scope.userId = undefined;
            }

            function cancel () {
                ctrl.onClose();
            }

            function assign () {
                if ($routeParams.id === undefined) {
                    return;
                }
                if ($window.confirm($filter("translate")("vacantPosOfCustomer.CONFIRMATION_DIALOG"))) {
                    let selected = [];
                    angular.forEach($scope.accountsReceivable.content, invoice => {
                        if (invoice.selected) {
                            selected.push(invoice);
                        }
                    });

                    $rootScope.settings.find(setting => {
                        if (setting.settingCode === 'accountForBookings') {
                            $scope.accountForBookings = setting.settingValue1;
                            if ($scope.accountForBookings.length !== 8) {
                                $scope.accountForBookings += '0'.repeat(8 - $scope.accountForBookings.length);
                            }
                        }
                    });

                    $scope.bookLoading = true;
                    let accounting_records = [];
                    let date_from = undefined;
                    let date_to = undefined;
                    $scope.commentText = '';
                    $scope.toCustomer = undefined;
                    selected.forEach(invoice => {
                        const now = moment(new Date());
                        let record1 = {
                            account_number: $routeParams.id,
                            amount: '' + invoice.enteredValue,
                            contra_account_number: $scope.accountForBookings,
                            date: now.format("YYYY-MM-DD HH:mm:ss"),
                            document_field2: now.format("DDMMYY"),
                            debit_credit_identifier: "S",
                            document_field1: "0",
                            posting_description: "Umbuchung Unzugeordnete Buchung",
                        };
                        let record2 = {
                            account_number: invoice[0].accountNumber,
                            amount: '' + invoice.enteredValue,
                            contra_account_number: $scope.accountForBookings,
                            date: now.format("YYYY-MM-DD HH:mm:ss"),
                            document_field2: now.format("DDMMYY"),
                            debit_credit_identifier: "H",
                            posting_description: "Umbuchung Unzugeordnete Buchung",
                        };
                        if (invoice[0].documentField1) {
                            record2.document_field1 = invoice[0].documentField1;
                        }
                        accounting_records.push(record1);
                        accounting_records.push(record2);
                        if (date_from === undefined || new Date(date_from).getTime() > new Date(invoice[0].date).getTime()) {
                            date_from = invoice[0].date;
                        }
                        if (date_to === undefined || new Date(date_to).getTime() < new Date(invoice[0].date).getTime()) {
                            date_to = invoice[0].date;
                        }

                        if ($routeParams.id === invoice[0].accountNumber) {
                            $scope.commentText += "Es wurde eine unzugeordnete Gutschrift in Höhe von " + invoice.enteredValue +
                                "€ auf die Rechnung " + invoice[0].documentField1 + " zugeteilt. \n";
                        } else {
                            $scope.commentText += 'Es wurde eine unzugeordnete Gutschrift von Kunde ' + $routeParams.id +
                                ' in Höhe von ' + invoice.enteredValue + '€ auf die Rechnung ' + invoice[0].documentField1 +
                                ' auf dem Kunden ' + invoice[0].accountNumber + ' zugeordnet. \n';
                            $scope.toCustomer = invoice[0].accountNumber;
                        }
                    });

                    const params = {
                        accounting_reason: "independent_from_accounting_reason",
                        application_information: "Debitorcloud",
                        date_from: date_from,
                        date_to: date_to,
                        initials: "DC",
                        description: "DC Umbuchung Zahlungen",
                        is_committed: false,
                        record_type: "financial_accounting",
                        accounting_records: accounting_records
                    };

                    requestManager.routes.accountsReceivable.postDATEV($rootScope.authData.sessionId, params)
                        .then(onAccountsReceivableRequestSuccess, onRequestError);
                }
            }

            function onAccountsReceivableRequestSuccess(response) {
                // console.log(response.data[0].http_code);
                if (response.data[0].http_code === 200 || response.data[0].http_code === 201 || response.data[0].http_code === 204) {
                    Notification.warning(
                        {message: "Lieber Anwender, deine Buchungen wurden erfolgreich an die DATEV Buchhaltung übermittelt. " +
                                "Dein Steuerberater prüft diese Buchungen nun. Danach werden die Buchungen in die Buchhaltung " +
                                "effektiv übernommen und in die Debitorcloud übermittelt.<br><strong>Bitte beachte, dass du erst danach " +
                                "deine gerade verbuchten Änderungen sehen kannst</strong>", delay: 20000});
                    // ein Kommentar erstellen
                    if ($scope.toCustomer !== undefined && $routeParams.id !== $scope.toCustomer) {
                        requestManager.routes.customer.createRecordOfCustomer($rootScope.authData.sessionId, $routeParams.id, "record_comment").then(
                            function (response) {
                                requestManager.routes.record.createCommentRecordOfRecord($rootScope.authData.sessionId, response.data, $scope.commentText)
                                    .then(
                                        function () {
                                            requestManager.routes.customer.createRecordOfCustomer($rootScope.authData.sessionId, $scope.toCustomer, "record_comment")
                                                .then(
                                                    function (response) {
                                                        requestManager.routes.record.createCommentRecordOfRecord($rootScope.authData.sessionId, response.data, $scope.commentText)
                                                            .then(
                                                                function () {
                                                                    // success
                                                                    window.location.reload();
                                                                }
                                                            )
                                                    },
                                                )
                                        },
                                        function () {
                                            // error
                                            window.location.reload();
                                        }
                                    );
                            }
                        );
                    } else {
                        requestManager.routes.customer.createRecordOfCustomer($rootScope.authData.sessionId, $routeParams.id, "record_comment").then(
                            function (response) {
                                requestManager.routes.record.createCommentRecordOfRecord($rootScope.authData.sessionId, response.data, $scope.commentText)
                                    .then(
                                        function () {
                                            // success
                                            window.location.reload();
                                        },
                                        function () {
                                            // error
                                            window.location.reload();
                                        }
                                    );
                            }
                        );
                    }
                    // $scope.userId = undefined;
                    // $route.reload();
                } else {
                    Notification.error("Bei der Umbuchung ist es zu einem Fehler gekommen.");
                    $scope.bookLoading = false;
                    // $scope.userId = undefined;
                    // $route.reload();
                }
            }

            function inputClicked(invoice) {
                // console.log(invoice);
                invoice.enteredValue = '';
                invoice.selected = true;
                isAllChecked();
            }

            function allCheckedClicked(bool){
                angular.forEach($scope.accountsReceivable.content, invoice => invoice.selected = bool);
                updateSummaryValues();
            }

            function isAllChecked() {
                let allChecked = true;
                angular.forEach($scope.accountsReceivable.content, invoice => {
                    if (isNaN(invoice.enteredValue)) {
                        invoice.enteredValue = '';
                    }
                    if(!invoice.selected) {
                        allChecked = false;
                    }
                });
                updateSummaryValues();
                $scope.checkAllCheckbox = allChecked;
            }

            function updateSummaryValues() {
                $scope.sumOfAllEnteredValues = 0;
                angular.forEach($scope.accountsReceivable.content, invoice => {
                    // if (invoice.enteredValue === '') {
                    //     invoice.enteredValue = 0;
                    // }
                    if(invoice.selected) {
                        if (invoice.enteredValue === '') {
                        }
                        // const x = parseFloat(invoice.enteredValue);
                        // if (x < 0) {
                        //     invoice.enteredValue *= -1;
                        // }
                        if (invoice.enteredValue !== '') {
                            $scope.sumOfAllEnteredValues += parseFloat(invoice.enteredValue);
                        }
                    }
                });
                $scope.diff = - $scope.sumOfAllEnteredValues + $scope.sumOfOtherCredits;
                if ($scope.diff.toFixed(2) === '0.00' || $scope.diff.toFixed(2) === '-0.00') {
                    $scope.diff = 0;
                }
            }

            function numberToFixed(invoice) {
                if (invoice.enteredValue === '' || invoice.enteredValue === '0' || parseFloat('' + invoice.enteredValue) === 0) {
                    invoice.selected = false;
                } else {
                    invoice.enteredValue = parseFloat('' + invoice.enteredValue).toFixed(2);
                }
            }

            function loadUserList(sessionId, callback) {
                var requestDetails = {
                    response: undefined,
                    successful: true,
                    canceledRequest: false
                };

                requestManager.routes.customer.virtual.getCustomerListExtended(sessionId).then(
                    function(response) {
                        requestDetails.response = response;
                        callback(requestDetails);
                    },
                    function(response) {
                        requestDetails.response = response;
                        requestDetails.successful = false;
                        requestDetails.canceledRequest = response.status === -1;
                        callback(requestDetails);

                    }
                );
            }

            function applyFilterToList(property) {
                if (property === $scope.filter.sortBy) {
                    $scope.filter.sortAscending = !$scope.filter.sortAscending;
                } else {
                    $scope.filter.sortBy = property;
                    $scope.filter.sortAscending = true;
                }
                processUserListForFrontend();

                if ($scope.filterSettingId !== undefined){
                    var paramsObject = {
                        value1: property,
                        value2: null,
                        value3: null
                    };

                    requestManager.routes.userSettings.editUserSetting($rootScope.authData.sessionId, $scope.filterSettingId, paramsObject);
                }
            }

            function processUserListForFrontend() {
                $scope.userListModified = angular.copy($scope.userList.content);
                $scope.pagination.paginationIndex = paginationManager.readIndex() === undefined ? 0 : paginationManager.readIndex();
                addPropertiesToUserList();
                applyCheckboxFilter();
                applyTextFilter();
                applyOrder();
                $scope.userListChunked = util.divideArrayIntoChunks($scope.userListModified, 10);
                createPaginationArray();
            }

            function addPropertiesToUserList() {
                for (var i = 0; i < $scope.userListModified.length; i++) {
                    $scope.userListModified[i].checked = false;
                }
            }

            function applyOrder() {
                if (angular.isUndefined($scope.filter.sortBy)) {
                    return;
                }

                var sortType;

                switch ($scope.filter.sortBy) {
                    case "name":
                    case "address":
                    case "city":
                    case "country":
                    case "email":
                    case "automaticDunning":
                    case "customerAdvisor":
                    case "partnerSurname":
                        sortType = "STRING";
                        break;
                    case "amountDunnings":
                    case "accountNumber":
                    case "zip":
                    case "sumSaldo":
                    case "opOverdue":
                    case "businessPartnerNr":
                    case "amountDunningLevel":
                    case "amountDunningsApproved":
                    case "amountDunningsApprovedEmail":
                    case "amountDunningsApprovedPhysicalMail":
                    case "amountDunningsUnapproved":
                    case "amountDunningsUnapprovedEmail":
                    case "amountDunningsUnapprovedPhysicalMail":
                        sortType = "NUMBER";
                        break;
                    case "invoiceOldestUnpaid":
                    case "latestAction":
                        sortType = "DATE";
                        break;
                }
                if ($scope.filter.sortAscending) {
                    switch (sortType) {
                        case "STRING":
                            $scope.userListModified.sort(function(a, b) {
                                if(!a[$scope.filter.sortBy]){
                                    a[$scope.filter.sortBy] = "";
                                }
                                if(!b[$scope.filter.sortBy]){
                                    b[$scope.filter.sortBy] = "";
                                }
                                return a[$scope.filter.sortBy].localeCompare(b[$scope.filter.sortBy]);
                            });
                            break;
                        case "NUMBER":
                            $scope.userListModified.sort(function(a, b) {
                                if(!a[$scope.filter.sortBy]){
                                    a[$scope.filter.sortBy] = 0;
                                }
                                if(!b[$scope.filter.sortBy]){
                                    b[$scope.filter.sortBy] = 0;
                                }
                                return parseFloat(a[$scope.filter.sortBy]) - parseFloat(b[$scope.filter.sortBy]);
                            });
                            break;
                        case "DATE":
                            $scope.userListModified.sort(function(a, b){
                                if(!a[$scope.filter.sortBy]){
                                    return 1;
                                }
                                if(!b[$scope.filter.sortBy]){
                                    return -1;
                                }
                                return (a[$scope.filter.sortBy]).localeCompare(b[$scope.filter.sortBy]);
                            });
                            break;
                    }
                } else {
                    switch (sortType) {
                        case "STRING":
                            $scope.userListModified.sort(function(b, a) {
                                if(!a[$scope.filter.sortBy]){
                                    a[$scope.filter.sortBy] = "";
                                }
                                if(!b[$scope.filter.sortBy]){
                                    b[$scope.filter.sortBy] = "";
                                }

                                return a[$scope.filter.sortBy].localeCompare(b[$scope.filter.sortBy]);
                            });
                            break;
                        case "NUMBER":
                            $scope.userListModified.sort(function(b, a) {
                                if(!a[$scope.filter.sortBy]){
                                    a[$scope.filter.sortBy] = 0;
                                }
                                if(!b[$scope.filter.sortBy]){
                                    b[$scope.filter.sortBy] = 0;
                                }
                                return parseFloat(a[$scope.filter.sortBy]) - parseFloat(b[$scope.filter.sortBy]);
                            });
                            break;
                        case "DATE":
                            $scope.userListModified.sort(function(b, a) {
                                if(!a[$scope.filter.sortBy]){
                                    return -1;
                                }
                                if(!b[$scope.filter.sortBy]){
                                    return 1;
                                }
                                return (a[$scope.filter.sortBy]).localeCompare(b[$scope.filter.sortBy]);
                            });
                            break;
                    }
                }

            }

            function applyCheckboxFilter() {
                var filteredList = [];

                angular.forEach($scope.userListModified, function(user) {
                    if ($scope.filter.onlyFavorites) {
                        if (user.isFavorite) {
                            filteredList.push(user);
                            return;
                        } else {
                            return;
                        }
                    }

                    if ($scope.filter.onlyProblematic) {
                        if (user.isProblematic === "1") {
                            filteredList.push(user);
                            return;
                        } else {
                            return;
                        }
                    }

                    if($scope.filter.onlyCustomerAssignedToMe){
                        if(user.customerAdvisor === $rootScope.authData.userId){
                            filteredList.push(user);
                            return;
                        } else {
                            return;
                        }
                    }
                    if($scope.filter.onlyCustomerAssignedToExternalPartner) {
                        if(user.partnerId === $rootScope.authData.userId){
                            filteredList.push(user);
                            return;
                        } else {
                            return;
                        }
                    }

                    filteredList.push(user);

                });

                $scope.userListModified = filteredList;
            }

            function applyTextFilter() {
                if (angular.isUndefined($scope.filter.searchTerm)) {
                    return;
                }

                var filteredList = [];

                angular.forEach($scope.userListModified, function(user) {
                    if ($scope.filter.onlyFavorites && !user.isFavourite) {
                        return;
                    }
                    if (angular.isDefined(user.accountNumber) && !_.isNull(user.accountNumber) && user.accountNumber.indexOf($scope.filter.searchTerm) !== -1) {
                        filteredList.push(user);
                        return;
                    }
                    if (angular.isDefined(user.name) && !_.isNull(user.name) && user.name.toLowerCase().indexOf($scope.filter.searchTerm.toLowerCase()) !== -1) {
                        filteredList.push(user);
                        return;
                    }
                    if (angular.isDefined(user.zip) && !_.isNull(user.zip) && user.zip.toLowerCase().indexOf($scope.filter.searchTerm.toLowerCase()) !== -1) {
                        filteredList.push(user);
                        return;
                    }
                    if (angular.isDefined(user.city) && !_.isNull(user.city) && user.city.toLowerCase().indexOf($scope.filter.searchTerm.toLowerCase()) !== -1) {
                        filteredList.push(user);
                        return;
                    }
                    if (angular.isDefined(user.country) && !_.isNull(user.country) && user.country.toLowerCase().indexOf($scope.filter.searchTerm.toLowerCase()) !== -1) {
                        filteredList.push(user);
                        return;
                    }
                    if (angular.isDefined(user.email) && !_.isNull(user.email)  && user.email.toLowerCase().indexOf($scope.filter.searchTerm.toLowerCase()) !== -1) {
                        filteredList.push(user);
                        return;
                    }
                    if (angular.isDefined(user.address) && !_.isNull(user.address)  && user.address.toLowerCase().indexOf($scope.filter.searchTerm.toLowerCase()) !== -1) {
                        filteredList.push(user);
                        return;
                    }
                });

                $scope.userListModified = filteredList;
            }

            function createPaginationArray() {
                if ($scope.userListModified.length === 0) {
                    return;
                }
                $scope.pagination.paginationArray = [];

                if ($scope.userListChunked.length < 9) {
                    for (var i = 0; i < $scope.userListChunked.length; i++) {
                        $scope.pagination.paginationArray.push({
                            text: i + 1,
                            clickable: true,
                            isActive: false
                        })
                    }
                    $scope.pagination.paginationArray[$scope.pagination.paginationIndex].isActive = true;
                } else {
                    $scope.pagination.paginationArray.push({
                        text: 1,
                        clickable: true,
                        isActive: false
                    }, {
                        text: 2,
                        clickable: true,
                        isActive: false
                    }, {
                        text: 3,
                        clickable: true,
                        isActive: false
                    }, {
                        text: 4,
                        clickable: true,
                        isActive: false
                    }, {
                        text: "...",
                        clickable: false,
                        isActive: false
                    }, {
                        text: $scope.userListChunked.length - 3,
                        clickable: true,
                        isActive: false
                    }, {
                        text: $scope.userListChunked.length - 2,
                        clickable: true,
                        isActive: false
                    }, {
                        text: $scope.userListChunked.length - 1,
                        clickable: true,
                        isActive: false
                    }, {
                        text: $scope.userListChunked.length,
                        clickable: true,
                        isActive: false
                    });

                    switch ($scope.pagination.paginationIndex) {
                        case 0:
                            $scope.pagination.paginationArray[0].isActive = true;
                            break;
                        case 1:
                            $scope.pagination.paginationArray[1].isActive = true;
                            break;
                        case 2:
                            $scope.pagination.paginationArray[2].isActive = true;
                            break;
                        case 3:
                            $scope.pagination.paginationArray[3].isActive = true;
                            break;
                        case $scope.userListChunked.length - 4:
                            $scope.pagination.paginationArray[5].isActive = true;
                            break;
                        case $scope.userListChunked.length - 3:
                            $scope.pagination.paginationArray[6].isActive = true;
                            break;
                        case $scope.userListChunked.length - 2:
                            $scope.pagination.paginationArray[7].isActive = true;
                            break;
                        case $scope.userListChunked.length - 1:
                            $scope.pagination.paginationArray[8].isActive = true;
                            break;
                        default:
                            $scope.pagination.paginationArray[4].isActive = true;
                    }
                }
            }

            function forwardToUser(userId) {
                $scope.userId = userId;
                $scope.accountsReceivable.content = undefined;
                $scope.sameUser = true;
                getData();
            }

            function resetFilter() {
                $scope.filter.searchTerm = undefined;
                processUserListForFrontend();
            }

            function decreasePaginationIndex() {
                if ($scope.pagination.paginationIndex > 0) {
                    $scope.pagination.paginationIndex--;
                    paginationManager.saveIndex($scope.pagination.paginationIndex);
                    createPaginationArray();
                }
            }

            function goToPage(pageObject) {
                if (pageObject.clickable) {
                    $scope.pagination.paginationIndex = pageObject.text - 1;
                    paginationManager.saveIndex($scope.pagination.paginationIndex);
                    createPaginationArray();
                }
            }

            function increasePaginationIndex() {
                if ($scope.pagination.paginationIndex < $scope.userListChunked.length - 1) {
                    $scope.pagination.paginationIndex++;
                    paginationManager.saveIndex($scope.pagination.paginationIndex);
                    createPaginationArray();
                }
            }

            function onRequestError(response){
                if (response && response.status === 403) {
                    $scope.bookLoading = false;
                    $scope.updateDueDateModal.currentlyLoading = false;
                    Notification.error($filter("translate")("userSettings.ERROR_403_USER_NEED_APPROPRIATE_PERMISSION"));
                }
            }
        }
    ]
});

angular.module('customerStatistics', []);
angular.module('customerStatistics').component('customerStatistics', { 
	templateUrl: 'app/components/customerStatistics/customerStatistics.template.html',
    controller: [
        "$scope",
        "requestManager",
        "$routeParams",
        "$rootScope",
        "$location",
        "util",
        function ($scope, requestManager, $routeParams, $rootScope, $location, util) {
            this.$onInit = function(){
                loadStatistics($rootScope.authData.sessionId, $routeParams["id"], function(requestDetails){
                    if(requestDetails.successful){
                        $scope.statistics = requestDetails.response.data;
                    }
                    else{
											if (!requestDetails.canceledRequest) {
                        sharedMemory.error.setError(
                            requestDetails,
                            "COULD_NOT_LOAD_DATA",
                            "Fehler beim laden der User-Statistiken",
                            $location.path(),
                            "customerStatistics"
                        );
                        $location.path("/error-page");
											}
                    }
                });
                initVariables();
            };

            $scope.$watch("statistics", function(newValue, oldValue){
                if(newValue != oldValue){
                    setValues();
                }
            });

            $scope.$watch("currentTab", function(newValue, oldValue){
                if(newValue != oldValue){
                    setValues();
                }
            });

			/*
			 * functions
			 * */

            function setValues(){
                switch($scope.currentTab){
                    case 0:
                        setVacantPos();
                        break;
                    case 1:
                        setAccountsReceivableTurnover();
                        break;
                    case 2:
                        setDaysSalesOutstanding();
                        break;
                }
            }

            function setAccountsReceivableTurnover(){
                $scope.chart.labels = [];
                $scope.chart.data = [[]];
                angular.forEach($scope.statistics.debitorStatistics.debitorenumschlag, function(value, key){
                    $scope.chart.labels.push(key);
                    $scope.chart.data[0].push(value);
                })
            }

            function setDaysSalesOutstanding(){
                $scope.chart.labels = [];
                $scope.chart.data = [[]];
                angular.forEach($scope.statistics.debitorStatistics.debitorenlaufzeit, function(value, key){
                    $scope.chart.labels.push(key);
                    $scope.chart.data[0].push(value);
                })
            }

            function setVacantPos(){
                $scope.chart.series = ['Offene Posten','Umsatz', 'Kreditlimit'];
                $scope.chart.colors = ['#45b7cd', '#EEEE00', '#77b82a'];
                $scope.chart.labels = [];
                $scope.chart.options = {
                    legend: {
                        display: true,
                        labels: {
                            fontColor: '#000'
                        }
                    }
                };
                $scope.chart.data = [[],[],[]]; // 3 nested arrays for painting 3 graphs
                requestManager.routes.customer.getCustomerWithId($rootScope.authData.sessionId, $routeParams["id"]).then(
                    response => {
                        $scope.creditLimit = response.data[0].creditLimit;

                        angular.forEach($scope.statistics.turnover, function(value, key){
                            if(value != null)
                                $scope.chart.data[1].push(value);
                            else
                                $scope.chart.data[1].push(0);
                        });

                        angular.forEach($scope.statistics.opHistorical, function(value, key){
                            $scope.chart.labels.push(key);
                            $scope.chart.data[0].push(value);
                            if($scope.creditLimit != null)
                                $scope.chart.data[2].push($scope.creditLimit );
                        });
                    }
                )
            }

            function initVariables(){
                $scope.currentTab = 0;
                $scope.statistics = {
                    currentlyLoading: true,
                    content: {}
                };
                $scope.chart = {
                    labels: [],
                    data: [[]]
                };
            }


            function loadStatistics(sessionId, customerId, callback){
                var requestDetails = {
                    successful: true,
                    response: undefined, 
										canceledRequest: false
                };

                requestManager.routes.customerStatistics.getCustomerStatistics(sessionId, customerId).then(
                    function (response){
                        requestDetails.response = response;
                        callback(requestDetails);
                    },
                    function(response){
                        if(response.status == 401){
                            $rootScope.logout();
                        }

                        requestDetails.response = response;
                        requestDetails.successful = false;
												requestDetails.canceledRequest = response.status == -1;
                        callback(requestDetails);
                    }
                )
            }
        }
    ]
}); 

angular.module('userEmails', []);
angular.module('userEmails').component('userEmails', { 
	templateUrl: 'app/components/userEmails/userEmails.template.html', 
	controller: [
		"$scope",
		"$rootScope",
		"$location",
		"requestManager",
		"sharedMemory",
		function ($scope, $rootScope, $location, requestManager, sharedMemory) {
			this.$onInit = function(){
				initVariables();
				initScope();
				getEmails($rootScope.authData.sessionId, function(requestDetails){
					if(requestDetails.successful){
						$scope.emailList.content = requestDetails.response.data;
						$scope.emailList.currentlyLoading = false;
					}
					else{

					}
				})
			};

            function initVariables(){
				$scope.emailList = {
					content: undefined,
					currentlyLoading: true
				};
				$scope.emailSelected = undefined;
			}

			function initScope(){
                $scope.openEmailModal = openEmailModal;
            }

			function openEmailModal(emailDetails){
            	$scope.emailSelected = emailDetails;
				jQuery("#emailModal").modal("show");
			}

			function getEmails(sessionId, callback){
				var requestDetails  = {
					successful: true,
					response: undefined
				};

                requestManager.routes.recordEmail.virtual.getEmails(sessionId).then(
                	function(response){
                		requestDetails.response = response;
                		callback(requestDetails);
					},
					function(response){
                        requestDetails.response = response;
                        requestDetails.successful = response;
                        callback(requestDetails);
					}
				)
			}
		} 
	] 
}); 

angular.module('overviewStatistics', []);
angular.module('overviewStatistics').component('overviewStatistics', {
    templateUrl: 'app/components/overviewStatistics/overviewStatistics.template.html',
    controller: [
        "$scope",
        "requestManager",
        "$routeParams",
        "$rootScope",
        "sharedMemory",
        "$location",
        function ($scope, requestManager, $routeParams, $rootScope, sharedMemory, $location) {
            this.$onInit = function () {
                loadStatistics($rootScope.authData.sessionId, function (requestDetails) {
                    if (requestDetails.allSuccessful) {
                        $scope.statistics.content = requestDetails.response.data;
                        $scope.statistics.currentlyLoading = false;
                        setValues();

                    }
                    else {
                        if (!requestDetails.canceledRequest) {
                            sharedMemory.error.setError(
                                requestDetails,
                                "COULD_NOT_LOAD_DATA",
                                "Fehler beim laden der Statistiken (beim initiieren der Komponente)",
                                $location.path(),
                                "overviewStatistics"
                            );
                            $location.path("/error-page");
                        }
                    }
                });
                initVariables();
                initScope();
            };

            $scope.$watch("currentTab", function (newValue, oldValue) {
                if (newValue != oldValue) {
                    setValues();
                }
            });

            /*
             * functions
             * */

            function setValues() {
                switch ($scope.currentTab) {
                    case 0:
                        setVacantPos();
                        break;
                    case 1:
                        setAccountsReceivableTurnover();
                        break;
                    case 2:
                        setDaysSalesOutstanding();
                        break;
                }
            }

            function setAccountsReceivableTurnover() {
                $scope.chart.labels = [];
                $scope.chart.data = [[]];
                angular.forEach($scope.statistics.content.debitorStatistics.debitorenumschlag, function (value, key) {
                    $scope.chart.labels.push(key);
                    $scope.chart.data[0].push(parseFloat(value).toFixed(2));
                })
            }

            function setDaysSalesOutstanding() {
                $scope.chart.labels = [];
                $scope.chart.data = [[]];
                angular.forEach($scope.statistics.content.debitorStatistics.debitorenlaufzeit, function (value, key) {
                    $scope.chart.labels.push(key);
                    $scope.chart.data[0].push(parseFloat(value).toFixed(2));
                })
            }

            function setVacantPos() {
                $scope.chart.labels = [];
                $scope.chart.data = [[]];
                angular.forEach($scope.statistics.content.opHistorical, function (value, key) {
                    $scope.chart.labels.push(key);
                    $scope.chart.data[0].push(parseFloat(value).toFixed(2));
                })
            }

            function initVariables() {
                $scope.currentTab = 0;
                $scope.statistics = {
                    currentlyLoading: true,
                    content: {}
                };
                $scope.chart = {
                    labels: [],
                    data: [[]]
                };
            }

            function initScope() {
                $scope.openInfoModal = openInfoModal;
            }


            function loadStatistics(sessionId, callback) {
                var requestDetails = {
                    allSuccessful: true,
                    response: undefined,
                    canceledRequest: false
                };

                requestManager.routes.system.getHistoricalSystemStatistics(sessionId).then(
                    function (response) {
                        requestDetails.response = response;
                        callback(requestDetails);
                    },
                    function (response) {
                        requestDetails.response = response;
                        requestDetails.allSuccessful = false;
                        requestDetails.canceledRequest = response.status == -1;
                        callback(requestDetails);
                    }
                )
            }

            function openInfoModal() {
                jQuery("#overviewStatisticsInfoModal").modal("show");
            }
        }
    ]
}); 

angular.module('reminder', []);
angular.module('reminder').component('reminder', { 
	templateUrl: 'app/components/reminder/reminder.template.html', 
	controller: [
		"$scope",
		"$location",
		"requestManager",
		"$rootScope",
		"sharedMemory",
		"util",
		"Notification",
		"$filter", 
		"$route",
		function ($scope, $location, requestManager, $rootScope, sharedMemory, util, Notification, $filter, $route) {
			this.$onInit = function(){
				initVariables();
				initScope();
				getNotificationList($rootScope.authData.sessionId, $rootScope.authData.userId, function(notificationListRequestDetails){
					if(notificationListRequestDetails.allSuccessful){
						$scope.notificationList.content = notificationListRequestDetails.response.data.filter(function(notification){
							if(!notification){
								return false;
							}
							
							if(!notification.mailSent || notification.mailSent == "0"){
								return true; 
							}
							else{
								return false;
							}
						});
						
						$scope.notificationList.currentlyLoading = false;
					}
				});
						
				getCustomerList($rootScope.authData.sessionId, function(customerListRequestDetails){
					if(customerListRequestDetails.allSuccessful){
						$scope.customerList.content = customerListRequestDetails.response.data;
						$scope.customerList.currentlyLoading = false;
					}
					else{
						if (!customerListRequestDetails.canceledRequest) {
							sharedMemory.error.setError(
								customerListRequestDetails,
								"COULD_NOT_LOAD_DATA",
								"Fehler beim laden der Kundenliste (beim initiieren der Komponente)",
								$location.path(),
								"reminder"
							);
							$location.path("/error-page");
						}
					}
				});
			};

			function initScope(){
				$scope.$watchGroup(["customerList.currentlyLoading", "notificationList.currentlyLoading"], onCustomerListUserListAndNotificationListLoad);
				$scope.openReminderDetailsModal = openReminderDetailsModal; 
				$scope.forward = forward; 
				$scope.deleteReminder = deleteReminder; 
				$scope.editReminder = editReminder;
				$scope.onEditReminder = onEditReminder;
				$scope.openInfoModal = openInfoModal; 
			}

			function initVariables() {
				$scope.user = undefined;
				$scope.notificationList = {
					currentlyLoading: true,
					content: undefined
				};
				$scope.customerList = {
					currentlyLoading: true,
					content: undefined
				}; 
				$scope.reminderModal = {
					selectedReminder: undefined
				}
				$scope.editReminderModal = {
					currentlyLoading: false,
					reminder: undefined,
					date: undefined
					
				}
			}

			function getNotificationList(sessionId, userId, callback){
				var requestDetails = {
						allSuccessful: true,
						response: undefined, 
						canceledRequest: false
				};

				requestManager.routes.notifications.getNotificationsOfUser(sessionId, userId, [["mailSent", "!=", "1"]]).then(
					function(response){
						requestDetails.allSuccessful = true;
						requestDetails.response = response;
						callback(requestDetails);
					},
					function(response){
						requestDetails.allSuccessful = false;
						requestDetails.response = response;
						requestDetails.canceledRequest = response.status == -1;
						callback(requestDetails);
					}
				);
			}

			function getCustomerList(sessionId, callback){
				var requestDetails = {
					allSuccessful: true,
					response: undefined, 
					canceledRequest: false
				};

				requestManager.routes.customer.getAllCustomer(sessionId).then(
						function(response){
								requestDetails.allSuccessful = true;
								requestDetails.response = response;
								callback(requestDetails);
						},
						function(response){
								requestDetails.allSuccessful = true;
								requestDetails.response = response;
								requestDetails.canceledRequest = response.status == -1;
								callback(requestDetails);
						}
				)
			}

			function getUserIdBySessionId(sessionId, callback){
				var requestDetails = {
					allSuccessful: true,
					response: undefined
				};

				requestManager.routes.auth.getUserIdBySessionId(sessionId).then(
						function(response){
								requestDetails.response = response;
								callback(requestDetails);
						},
						function(response){
								requestDetails.allSuccessful = false;
								requestDetails.response = response;
								callback(requestDetails);
						}
				)
			}

			function getUserById(userId, sessionId,  callback){
					var requestDetails = {
							allSuccessful: true,
							response: undefined
					};

					requestManager.routes.user.getUserWithId(sessionId, userId).then(
							function(response){
									requestDetails.response = response;
									callback(requestDetails);
							},
							function(response){
									requestDetails.response = response;
									requestDetails.allSuccessful = false;
									callback(requestDetails);
							}
					)
			}

			function onCustomerListUserListAndNotificationListLoad(){
				if(!$scope.customerList.currentlyLoading && !$scope.notificationList.currentlyLoading){
					$scope.notificationList.content.forEach(function(notification){
						notification.user = util.getCustomerDetailsByCustomerId($scope.customerList.content, notification.customerForReminder);
					});
				}
			}
			
			function sendDeleteReminderRequest(sessionId, reminderId, callback){
				var requestDetails = {
					successful:true, 
					response: undefined
				}
				
				requestManager.routes.reminder.deleteReminderWithId(sessionId, reminderId).then(
					function(response){
						requestDetails.response = response; 
						callback(requestDetails); 
					}, 
					function(response){
						requestDetails.response = response; 
						requestDetails.successful = false; 
						callback(requestDetails); 
						
					}
				)
			}
			
			function openReminderDetailsModal(notification){
				$scope.reminderModal.selectedReminder = notification; 
				jQuery("#reminderModal").modal("show"); 
			}
			
			function deleteReminder(reminderId){
				sendDeleteReminderRequest($rootScope.authData.sessionId, reminderId, function(requestDetails){
					if(requestDetails.successful){
						jQuery('.modal').on('hidden.bs.modal', function () {
							Notification.success($filter("translate")("reminder.SUCCESSFULLY_DELETED")); 
							$route.reload();
						}).modal("hide");
					}
					else{
						sharedMemory.error.setError(
							requestDetails,
							"COULD_NOT_LOAD_DATA",
							"Fehler beim löschen einer notification",
							$location.path(),
							"reminder"
						);
						$location.path("/error-page");
					}
				})
			}
			
			function editReminder(reminder){
				$scope.editReminderModal.reminder = reminder;
				$scope.editReminderModal.date = moment(reminder.dateReminder, "YYYY-MM-DD HH:mm:ss").toDate();
				
				jQuery("#reminderModal").one("hidden.bs.modal", function(){
					jQuery("#editReminderModal").modal("show");
				}).modal("hide");
			}
			
			function forward(path){
				jQuery('.modal').on('hidden.bs.modal', function () {
					$location.path(path);
				}).modal("hide");
			}
			
			function onEditReminder(){
				var reminderDate = moment($scope.editReminderModal.date);
				$scope.editReminderModal.currentlyLoading = true; 
				
				let params = {
					userDidEntry: $scope.editReminderModal.reminder.userDidEntry, 
					customerForReminder: $scope.editReminderModal.reminder.customerForReminder,
					dateReminder: reminderDate.format("YYYY-MM-DD HH:mm:ss"),
					id: $scope.editReminderModal.reminder.id
				}
				
				requestManager.routes.reminder.editReminderWithId($rootScope.authData.sessionId, params).then(
					function(response){
						jQuery("#editReminderModal").one("hidden.bs.modal", function(){
							Notification.success($filter("translate")("reminder.REMINDER_UPDATED"));
							$scope.editReminderModal.currentlyLoading = false; 
							$route.reload();
						}).modal("hide");
					}, 
					function(response){
						sharedMemory.error.setError(
							response,
							"COULD_NOT_LOAD_DATA",
							"Fehler beim ändern einer Notification",
							$location.path(),
							"reminder"
						);
						$location.path("/error-page");
					}
				)
			}
			
			function openInfoModal(){
				jQuery("#reminderInfoModal").modal("show");
			}
		}
	] 
}); 

angular.module('userProfileSettings', []);
angular.module('userProfileSettings').component('userProfileSettings', {
    templateUrl: 'app/components/userProfileSettings/userProfileSettings.template.html',
    controller: [
        "$scope",
        "$rootScope",
        "requestManager",
        "sharedMemory",
        "$filter",
        "Notification",
        "$route",
        "util",
        "$location",
        "$window",
        function ($scope, $rootScope, requestManager, sharedMemory, $filter, Notification, $route, util, $location, $window) {
            this.$onInit = function(){
                initVariables();
                initScope();
                getUser($rootScope.authData.sessionId, function(userRequestDetails){
                    if(userRequestDetails.successful){
                        $scope.user = angular.copy(userRequestDetails.data);
                        getUserSettings($rootScope.authData.sessionId, $scope.user.id, function(userSettingsRequestDetails){
                            if(userSettingsRequestDetails.successful){
                                $scope.userSettings.allSettings = userSettingsRequestDetails.content;
                                $scope.userSettings.defaultNumRows = util.getSettingByName(userSettingsRequestDetails.content, "defaultNumRowsInList");
                                $scope.userSettings.defaultNumRows.edited = false;
                                $scope.userSettings.visibleColumnsInCustomerList = getVisibleColumnsInCustomerList($scope.userSettings.allSettings);
                                $scope.userSettings.visibleModulesInDashboard = getVisibleModulesInDashboard($scope.userSettings.allSettings);
                                angular.forEach($scope.userSettings.visibleColumnsInCustomerList, function(object){object.edited = false});
                                angular.forEach($scope.userSettings.visibleModulesInDashboard, function(object){object.edited = false});
                            }
                            else{
                                sharedMemory.error.setError(
                                    userSettingsRequestDetails,
                                    "COULD_NOT_LOAD_DATA",
                                    "Fehler beim laden der User-Settings (beim initiieren der Komponente)",
                                    $location.path(),
                                    "userProfileSettings"
                                );
                                $location.path("/error-page");
                            }
                        });
                    }
                    else{
                        sharedMemory.error.setError(
                            userRequestDetails,
                            "COULD_NOT_LOAD_DATA",
                            "Fehler beim laden der User-Daten (beim initiieren der Komponente)",
                            $location.path(),
                            "userProfileSettings"
                        );
                        $location.path("/error-page");
                    }
                });
            };

            function initScope(){
                $scope.setTabIndexTo = setTabIndexTo;
                $scope.editUser = editUser;
                $scope.changePassword = changePassword;
                $scope.saveViewSettings = saveViewSettings;
            }

            function initVariables(){
                $scope.currentTab = 0;
                $scope.editUserRequest = {
                    pending: false,
                    lastResponse: undefined
                };
                $scope.user = {
                    userIdLoaded: false,
                    userDetailsLoaded: false,
                    userDetails: {},
                    userId: undefined
                };
                $scope.editUserFormModel = {
                    name: undefined,
                    email: undefined,
                    surname: undefined
                };
                $scope.editUserRequest = {
                    pending: false,
                    lastResponse: undefined
                };
                $scope.viewSettingsFormModel = {
                    numRowsOptions: ["10","25","50","75","100", "ALL"],
                    numRowsSelected: undefined
                };
                $scope.userSettings = {
                    allSettings: undefined,
                    defaultNumRows: undefined
                }
            }

            function setTabIndexTo(index){
                $scope.currentTab = index;
            }

            function editUser(){
                if($scope.editUserForm.$invalid) {
                    return;
                }

                var params =  {
                    name: $scope.user.name,
                    surname: $scope.user.surname,
                    email: $scope.user.email,
                    id: $scope.user.id
                };

                sendEditUserRequest($rootScope.authData.sessionId, params, function(requestDetails){
                    if(requestDetails.successful){
                        Notification.success($filter("translate")("userProfileSettings.CHANGE_PROFILE_SUCCESSFUL"));
                    }
                    else{
                        sharedMemory.error.setError(
                            requestDetails,
                            "COULD_NOT_LOAD_DATA",
                            "Fehler beim editieren der Userdaten ",
                            $location.path(),
                            "userProfileSettings"
                        );
                        $location.path("/error-page");
                    }
                });

            }

            function sendEditUserRequest(sessionId, params, callback){
                var requestDetails = {
                    successful: true,
                    response: undefined
                };

                requestManager.routes.user.editUser(sessionId, params).then(
                    function(response){
                        requestDetails.response = response;
                        callback(requestDetails);
                    },
                    function(response){
                        requestDetails.response = response;
                        requestDetails.successful = false;
                        callback(requestDetails);
                    }
                )
            }

            function getUser(sessionId, callback){
                var requestDetails = {
                    successful: true,
                    fromCache: true,
                    response: undefined,
                    data: undefined
                };

                if(sharedMemory.user.isSet){
                    requestDetails.data = sharedMemory.user.getUserDetails();
                    callback(requestDetails);
                }
                else{
                    getUserIdBySessionId(sessionId, function(userIdRequestDetails){
                        if(userIdRequestDetails.allSuccessful){
                            getUserById(userIdRequestDetails.response.data, sessionId, function(userRequestDetails){
                                if(userRequestDetails.allSuccessful){
                                    requestDetails.response = userRequestDetails.response;
                                    requestDetails.data = userRequestDetails.response.data;
                                    requestDetails.fromCache = false;
                                    callback(requestDetails);
                                }
                                else{
                                    requestDetails.response = userRequestDetails.response;
                                    requestDetails.data = userRequestDetails.response.data;
                                    requestDetails.fromCache = false;
                                    requestDetails.successful = false;
                                    callback(requestDetails);
                                }
                            })
                        }
                        else{
                            requestDetails.response = userIdRequestDetails.response;
                            requestDetails.data = userIdRequestDetails.response.data;
                            requestDetails.fromCache = false;
                            requestDetails.successful = false;
                            callback(requestDetails);
                        }
                    });
                }

            }

            function changePassword(){
                if($scope.changePasswordForm.$invalid){
                    return;
                }

                var params = {
                    oldPassword: $scope.changePasswordFormModel.oldPassword,
                    newPassword: $scope.changePasswordFormModel.newPassword,
                    id: $scope.user.id
                };

                requestManager.routes.user.changeUserPassword($rootScope.authData.sessionId, params).then(
                    function(response){
                        Notification.success($filter("translate")("userProfileSettings.CHANGE_PASSWORD_SUCCESSFUL"));
                        $route.reload();
                    },
                    function(response){
                        Notification.error($filter("translate")("userProfileSettings.CHANGE_PASSWORD_NOT_SUCCESSFUL"));
                    }
                );
            }

            function getUserSettings(sessionId, userId, callback){
                var requestDetails = {
                    fromCache: false,
                    response: undefined,
                    successful: true,
                    content: undefined
                };

                if(sharedMemory.userSettings.isSet){
                    requestDetails.content = sharedMemory.userSettings.getUserSettings();
                    requestDetails.fromCache = true;
                    callback(requestDetails);
                    return;
                }
                else{
                    requestManager.routes.user.getSettingsOfUser(sessionId, userId).then(
                        function(response){
                            requestDetails.response = response;
                            requestDetails.content = response.data;
                            callback(requestDetails);
                        },
                        function(response){
                            requestDetails.response = response;
                            requestDetails.content = response.data;
                            requestDetails.successful = false;
                            callback(requestDetails);
                        }
                    )
                }
            }

            function saveViewSettings(){
                var updatedSettings = getUpdatedSettings($scope.userSettings);
                sendEditSettingRequest($rootScope.authData.sessionId, updatedSettings, function(requestDetails){
                    if(requestDetails.allSuccessful){
                        Notification.success($filter("translate")("userProfileSettings.SETTINGS_SAVED"));
                        $window.location.reload();
                    }
                    else{
                        Notification.error($filter("translate")("userProfileSettings.SETTINGS_NOT_SAVED"))
                    }
                })
            }

            function sendEditSettingRequest(sessionId, paramsArray, callback){
                var requestDetails = {
                    numRequestsPlanned: paramsArray.length,
                    allSuccessful: true,
                    responses: [],
                    successfulResponses: [],
                    errorResponses: []
                };

                paramsArray.forEach(function(params){
                    requestManager.routes.userSettings.editUserSetting(sessionId, params.id, params).then(
                        function(response){
                            requestDetails.successfulResponses.push(response);
                            requestDetails.responses.push(response);
                            if(requestDetails.responses.length == requestDetails.numRequestsPlanned){
                                callback(requestDetails);
                            }
                        },
                        function(response){
                            requestDetails.successfulResponses.push(response);
                            requestDetails.responses.push(response);
                            requestDetails.allSuccessful = false;
                            if(requestDetails.responses.length == requestDetails.numRequestsPlanned){
                                callback(requestDetails);
                            }
                        }
                    )
                });
            }

            function getUserIdBySessionId(sessionId, callback){
                var requestDetails = {
                    allSuccessful: true,
                    response: undefined
                };

                requestManager.routes.auth.getUserIdBySessionId(sessionId).then(
                    function(response){
                        requestDetails.response = response;
                        callback(requestDetails);
                    },
                    function(response){
                        requestDetails.allSuccessful = false;
                        requestDetails.response = response;
                        callback(requestDetails);
                    }
                )
            }

            function getUserById(userId, sessionId,  callback){
                var requestDetails = {
                    allSuccessful: true,
                    response: undefined
                };

                requestManager.routes.user.getUserWithId(sessionId, userId).then(
                    function(response){
                        requestDetails.response = response;
                        callback(requestDetails);
                    },
                    function(response){
                        requestDetails.response = response;
                        requestDetails.allSuccessful = false;
                        callback(requestDetails);
                    }
                )
            }

            function getVisibleColumnsInCustomerList(allSettingsOfUser){
                var rowsVisibleInCustomerList = {};

                rowsVisibleInCustomerList.showCustomerNumberInCustomerList = util.getSettingByName(allSettingsOfUser, "showCustomerNumberInCustomerList");
                rowsVisibleInCustomerList.showCustomerNameInCustomerList = util.getSettingByName(allSettingsOfUser, "showCustomerNameInCustomerList");
                rowsVisibleInCustomerList.showCustomerStreetInCustomerList = util.getSettingByName(allSettingsOfUser, "showCustomerStreetInCustomerList");
                rowsVisibleInCustomerList.showCustomerZipInCustomerList = util.getSettingByName(allSettingsOfUser, "showCustomerZipInCustomerList");
                rowsVisibleInCustomerList.showCustomerCityInCustomerList = util.getSettingByName(allSettingsOfUser, "showCustomerCityInCustomerList");
                rowsVisibleInCustomerList.showCustomerEmailInCustomerList = util.getSettingByName(allSettingsOfUser, "showCustomerEmailInCustomerList");
                rowsVisibleInCustomerList.showCustomerOldestInvoiceInCustomerList = util.getSettingByName(allSettingsOfUser, "showCustomerOldestInvoiceInCustomerList");
                rowsVisibleInCustomerList.showCustomerVacantPosInCustomerList = util.getSettingByName(allSettingsOfUser, "showCustomerVacantPosInCustomerList");
                rowsVisibleInCustomerList.showCustomerLastActionInCustomerList = util.getSettingByName(allSettingsOfUser, "showCustomerLastActionInCustomerList");
                rowsVisibleInCustomerList.showCustomerCountryInCustomerList = util.getSettingByName(allSettingsOfUser, "showCustomerCountryInCustomerList");
                rowsVisibleInCustomerList.showCustomerAdvisorInCustomerList = util.getSettingByName(allSettingsOfUser, "showCustomerAdvisorInCustomerList");
                rowsVisibleInCustomerList.showCustomerBusinessPartnerNrInCustomerList = util.getSettingByName(allSettingsOfUser, "showCustomerBusinessPartnerNrInCustomerList");
                rowsVisibleInCustomerList.showCustomerAmountDunningsInCustomerList = util.getSettingByName(allSettingsOfUser, "showCustomerAmountDunningsInCustomerList");
                rowsVisibleInCustomerList.showCustomerVacantPosWithoutOverdueInCustomerList = util.getSettingByName(allSettingsOfUser, "showCustomerVacantPosWithoutOverdueInCustomerList");
                return rowsVisibleInCustomerList;
            }

            function getVisibleModulesInDashboard(allSettingsOfUser){
                var modulesVisibleInDashboard = {};

                modulesVisibleInDashboard.showProblematicClientsInDashboard = util.getSettingByName(allSettingsOfUser, "showProblematicClientsInDashboard");
                modulesVisibleInDashboard.showFavouriteClientsInDashboard = util.getSettingByName(allSettingsOfUser, "showFavouriteClientsInDashboard");
                modulesVisibleInDashboard.showPaymentAgreementsInDashboard = util.getSettingByName(allSettingsOfUser, "showPaymentAgreementsInDashboard");
                modulesVisibleInDashboard.showUnpaidPaymentAgreementsInDashboard = util.getSettingByName(allSettingsOfUser, "showUnpaidPaymentAgreementsInDashboard");
                modulesVisibleInDashboard.showReminderInDashboard = util.getSettingByName(allSettingsOfUser, "showReminderInDashboard");
                modulesVisibleInDashboard.showStatisticsInDashboard = util.getSettingByName(allSettingsOfUser, "showStatisticsInDashboard");
                if(util.getSettingByName(allSettingsOfUser, "showIncomingEmailsInDashboard") !== undefined) {
                    modulesVisibleInDashboard.showIncomingEmailsInDashboard = util.getSettingByName(allSettingsOfUser, "showIncomingEmailsInDashboard");
                }

                return modulesVisibleInDashboard;
            }

            function getUpdatedSettings(userSettings){
                var updatedSettings = [];


                angular.forEach(userSettings.visibleColumnsInCustomerList, function(setting){
                    if(setting.edited){
                        updatedSettings.push(setting);
                    }
                });

                angular.forEach(userSettings.visibleModulesInDashboard, function(setting){
                    if(setting.edited){
                        updatedSettings.push(setting);
                    }
                });

                if(userSettings.defaultNumRows.edited){
                    updatedSettings.push(userSettings.defaultNumRows);
                }

                return updatedSettings;
            }
        }
    ]
}); 

angular.module('generalHint', []);
angular.module('generalHint').component('generalHint', {
	templateUrl: 'app/components/generalHint/generalHint.template.html',
	controller: [
		"$scope",
		"requestManager",
		"$rootScope",
		"util",
		"sharedMemory",
		"$location",
		function ($scope, requestManager, $rootScope, util, sharedMemory, $location) {
			this.$onInit = function(){
				initVariables();
				initScope();

				requestManager.routes.sync.getStatus($rootScope.authData.sessionId).then(
					function (response) {
						$scope.syncStatus = response.data;
					}
				);

				getSettings($rootScope.authData.sessionId, function(requestDetails){
					if(requestDetails.allSuccessful){
						$scope.settings.content = requestDetails.response.data;
						$scope.settings.loaded = true;
						$scope.settings.generalHints = util.getSettingsWithMatchingRegex($scope.settings.content, /generalHint/);
					}
					else{
						if (!requestDetails.canceledRequest) {
							sharedMemory.error.setError(
								requestDetails,
								"COULD_NOT_LOAD_DATA",
								"Fehler beim laden der settings (beim initiieren der Komponente)",
								$location.path(),
								"generalHint"
							);
							$location.path("/error-page");
						}
					}
				});
			};

			function initScope(){
				$scope.showHint = showHint;
				$scope.getErrorMessage = getErrorMessage;
			}

			function initVariables(){
				$scope.settings = {
					content: undefined,
					loaded: false,
					generalHints: undefined
				}
				$scope.syncStatus = undefined;
				$scope.error = false;
			}

			function getSettings(sessionId, callback){
				var requestDetails = {
					allSuccessful: true,
					response: undefined
				};

				requestManager.routes.settings.getSettings(sessionId).then(
					function(response){
						requestDetails.response = response;
						callback(requestDetails);
					},
					function(response){
						requestDetails.allSuccessful = false;
						requestDetails.response = response;
						requestDetails.canceledRequest = response.status == -1;
						callback(requestDetails);
					}
				)
			}

			function showHint(){
				return function(hint){
					return hint.settingValue2 == "1";
				}
			}

			function getErrorMessage() {
				var errorSyncDate = moment($scope.syncStatus.lastFinished * 1000).format("DD.MM.YYYY");
				var errorSyncTime = moment($scope.syncStatus.lastFinished * 1000).format("HH:mm");
				var lastSuccessfulDate = moment($scope.syncStatus.lastSuccessfullFinished * 1000).format("DD.MM.YYYY");
				var lastSuccessfulTime = moment($scope.syncStatus.lastSuccessfullFinished * 1000).format("HH:mm");

				const date = new Date(moment($scope.syncStatus.lastSuccessfullFinished * 1000).format("YYYY-MM-DD") + 'T' + moment($scope.syncStatus.lastSuccessfullFinished * 1000).format("HH:mm:ss"));
				const dateNow = new Date(Date.now());

				const differenceMs = Math.abs(dateNow - date);
				const twentyFourHoursMs = 24 * 60 * 60 * 1000;

				$scope.error = differenceMs > twentyFourHoursMs;
				if (differenceMs > twentyFourHoursMs) {
					$scope.error = true;

					return `Synchronisierung am ${errorSyncDate} um ${errorSyncTime} mit Fehler. 
					Letzte erfolgreiche Synchronisierung am ${lastSuccessfulDate} um ${lastSuccessfulTime}`;
				} else {
					$scope.error = false;
					return `Die letzte Synchronisierung am ${errorSyncDate} um ${errorSyncTime} Uhr war fehlerhaft. 
					Die letzte erfolgreiche Synchronisierung war am ${lastSuccessfulDate} um ${lastSuccessfulTime}. Dies ist weniger als 24 Stunden her. 
					Bitte warte die nächste Synchronisation ab. Oft ist diese schon wieder erfolgreich. 
					Sollte die Synchronisation dann noch immer fehlschlagen, deutet dies auf ein nachhaltiges Problem mit dem DATEV System hin. 
					Eröffne dann <a href="https://support.riecken.io/help/3453743212" target="_blank">hier</a> ein Ticket.`;
				}
			}

		}
	]
}); 

angular.module('generalHintEditor', []);
angular.module('generalHintEditor').component('generalHintEditor', {
	templateUrl: 'app/components/generalHintEditor/generalHintEditor.template.html',
	bindings: {
		settings: "<"
	},
	controller: [
			"$scope",
			"requestManager",
			"$rootScope",
			"util",
			"$window",
			"$filter",
			"Notification",
			"sharedMemory",
			"$location",
			function ($scope, requestManager, $rootScope, util, $window, $filter, Notification, sharedMemory, $location) {
					init();

					function init(){
							initVariables();
							initScope();
					}

					this.$onChanges = function set(item){	
						if(item.settings.currentValue){
							$scope.settings.content = item.settings.currentValue;
							$scope.settings.currentlyLoading = false;
							$scope.settings.generalHints = util.getSettingsWithMatchingRegex($scope.settings.content, /generalHint/);
						}
						if(!$scope.settings.currentlyLoading){
							$scope.view.allDependenciesLoaded = true;
						}
					};

					function initScope(){
						$scope.saveChanges = saveChanges;
						$scope.openInfoModal = openInfoModal; 
					}

					function initVariables(){
							$scope.settings = {
								content: undefined,
								currentlyLoading: true,
								generalHints: undefined
							}
							$scope.view = {
								allDependenciesLoaded: true
							}
					}
				
					function saveChanges(){
						if($window.confirm($filter("translate")("generalHintEditor.CONFIRM_CHANGE"))){
							sendAllEditSettingRequests(function(details){
								if(details.allSuccessful){
									Notification.success($filter("translate")("generalHintEditor.SUCCESSFULLY_CHANGED"));
								}
								else{
									if (!details.canceledRequest) {
										sharedMemory.error.setError(
											details,
											"COULD_NOT_LOAD_DATA",
											"Fehler beim editieren der settings (pro setting ein Request)",
											$location.path(),
											"generalHintEditor"
										);
										$location.path("/error-page");
									}
								}
							});
						}
					}

					function sendAllEditSettingRequests(callback){
							var requestDetails = {
									numRequests: $scope.settings.generalHints.length,
									numRequestsLoaded: 0,
									numErrors: 0,
									numSuccess: 0,
									allSuccessful: true,
									responses: [], 
									canceledRequest: false
							};

							angular.forEach($scope.settings.generalHints, function(hint){
									hint.settingId = hint.id;

									sendEditSettingRequest(hint, function(editSettingRequestDetails){
									if(editSettingRequestDetails.allSuccessful){
										requestDetails.numSuccess++;
										requestDetails.numRequestsLoaded++;
										requestDetails.responses.push(editSettingRequestDetails.response);
										if(requestDetails.numRequests == requestDetails.numRequestsLoaded){
											callback(requestDetails);
										}
									}
									else{
										requestDetails.numErrors++;
										requestDetails.numRequestsLoaded++;
										requestDetails.responses.push(editSettingRequestDetails.response);
										requestDetails.allSuccessful = false;
										if(!requestDetails.canceledRequest){
											requestDetails.canceledRequest = response.status == -1;
										}
										if(requestDetails.numRequests == requestDetails.numRequestsLoaded){
												callback(requestDetails);
									}
								}
							})
						})
					}

					function sendEditSettingRequest(params, callback){
							var requestDetails = {
									numRequests: 1,
									numRequestsLoaded: 0,
									numErrors: 0,
									numSuccess: 0,
									allSuccessful: true,
									response: undefined, 
									canceledRequest: false
							};

							requestManager.routes.settings.editSettingWithKey($rootScope.authData.sessionId, params).then(
									function(response){
											requestDetails.numRequestsLoaded++;
											requestDetails.numSuccess++;
											requestDetails.response = response;
											callback(requestDetails);
									},
									function(response){
											requestDetails.numRequestsLoaded++;
											requestDetails.numErrors++;
											requestDetails.allSuccessful = false;
											requestDetails.response = response;
											requestDetails.canceledRequest = response.status == -1;
											callback(requestDetails);
									}
							)
					}
				
				function openInfoModal(){
					jQuery("#visibilityInfoModal").appendTo("body").modal("show");
				}
			}
	]
}); 

angular.module('taskList', []);
angular.module('taskList').component('taskList', {
        templateUrl: 'app/components/taskList/taskList.template.html',
        controller: [
            "$scope",
            "$rootScope",
            "Notification",
            "$location",
            "requestManager",
            "sharedMemory",
            "util",
            "$filter",
            "$window",
            "$route",
            function ($scope, $rootScope, Notification, $location, requestManager, sharedMemory, util, $filter, $window, $route) {
                this.$onInit = function(){
                    initVariables();
                    initScope();
                    getTaskListExtended($rootScope.authData.sessionId, function(requestDetails){
                        if(requestDetails.successful){
                            $scope.tasks.currentlyLoading = false;
                            $scope.tasks.taskList = angular.copy(requestDetails.response.data);
                            $scope.tasks.taskListModified = modifyTaskList(angular.copy($scope.tasks.taskList), angular.copy($scope.filter));
                            console.log($scope.tasks.taskListModified);
                            $scope.totalRows = $scope.tasks.taskListModified.reduce((acc, subArray) => acc + subArray.length, 0);
                        }
                        else{
                            sharedMemory.error.setError(
                                requestDetails,
                                "COULD_NOT_LOAD_DATA",
                                "Fehler beim laden der Tasks (beim initiieren der Komponente)",
                                $location.path(),
                                "taskList"
                            );
                            $location.path("/error-page");
                        }
                    });
                    sendGetUserIdRequest($rootScope.authData.sessionId, function(requestDetails){
                        if(requestDetails.successful){
                            $scope.userId.content = requestDetails.response.data;
                            $scope.userId.currentlyLoading = false;
                        }
                        else{
                            sharedMemory.error.setError(
                                requestDetails,
                                "COULD_NOT_LOAD_DATA",
                                "Fehler beim der UserId (beim initiieren der Komponente)",
                                $location.path(),
                                "taskList"
                            );
                            $location.path("/error-page");
                        }
                    });

                    getCustomerList($rootScope.authData, function(requestDetails){
                        if(requestDetails.successful){
                            $scope.customerList.content = requestDetails.response.data;
                            $scope.customerList.currentlyLoading = false;
                        }
                        else{
                            sharedMemory.error.setError(
                                requestDetails,
                                "COULD_NOT_LOAD_DATA",
                                "Fehler beim der customer (beim initiieren der Komponente)",
                                $location.path(),
                                "taskList"
                            );
                            $location.path("/error-page");
                        }

                    })
                };

                function initVariables(){
                    $scope.totalRows = undefined;
                    $scope.tasks = {
                        currentlyLoading: true,
                        taskList: undefined,
                        taskListModified: undefined
                    };
                    $scope.filter = {
                        numEntriesPerPageOptions: [10, 25, 50, 75, 100],
                        menuOpened: true,
                        searchTerm: undefined,
                        doneStatus: "NOT_DONE",
                        typeSelected: undefined,
                        showOnlyAssigned: false,
                        selectAllCheckbox: false,
                        typeOptions: [
                            {key: "ALL", text: $filter("translate")("taskList.ALL"), iconClass: undefined},
                            {key: "PHONE", text: $filter("translate")("taskList.PHONE_CALL"), iconClass: "fa fa-suitcase"},
                            {key: "LAWYER", text: $filter("translate")("taskList.INFORM_LAWYER"), iconClass: "fa fa-phone"},
                            {key: "CUSTOMER_ADVISOR", text: $filter("translate")("taskList.CUSTOMER_ADVISOR"), iconClass: "fa fa-user-circle"},
                        ]
                    };
                    $scope.filter.numEntriesPerPageSelected =  $scope.filter.numEntriesPerPageOptions[0];
                    $scope.pagination = {
                        pageIndex: 0
                    };
                    $scope.taskDetailsModal = {
                        task: undefined,
                        customerAssigned: undefined,
                        currentlyLoading: false
                    };
                    $scope.createNewTaskModal = {
                        taskName: undefined,
                        taskDescription: undefined,
                        taskType: undefined,
                        taskTypeOptions: [
                            {key: "PHONE", text: $filter("translate")("taskList.PHONE_CALL"), iconClass: "fa fa-suitcase"},
                            {key: "LAWYER", text: $filter("translate")("taskList.INFORM_LAWYER"), iconClass: "fa fa-phone"},
                            {key: "CUSTOMER_ADVISOR", text: $filter("translate")("taskList.CUSTOMER_ADVISOR"), iconClass: "fa fa-user-circle"}
                        ],
                        customerSelected: undefined,
                        customerAssigned: []
                    };
                    $scope.customerList = {
                        currentlyLoading: true,
                        content: undefined
                    };
                    $scope.userId = {
                        content: undefined,
                        currentlyLoading: false
                    }
                }

                function initScope(){
                    $scope.openTaskDetailsModal = openTaskDetailsModal;
                    $scope.toggleFilterOptions = toggleFilterOptions;
                    $scope.refreshTaskList = refreshTaskList;
                    $scope.decreasePaginationIndex = decreasePaginationIndex;
                    $scope.increasePaginationIndex = increasePaginationIndex;
                    $scope.reloadTaskList = reloadTaskList;
                    $scope.toggleCheckAll = toggleCheckAll;
                    $scope.setSelectedTasksDone = setSelectedTasksDone;
                    $scope.setSelectedTasksUndone = setSelectedTasksUndone;
                    $scope.deleteTask = deleteTask;
                    $scope.resetFilter = resetFilter;
                    $scope.openCreateNewTaskModal = onCreateNewTaskDropdownButtonClick;
                    $scope.createNewTask = onCreateNewTaskButtonClick;
                    $scope.addCustomerToAssignedList = addCustomerToAssignedList;
                    $scope.setDone = onSetDoneButtonClick;
                    $scope.setUndone = onSetUndoneButtonClick;
                    $scope.removeCustomerFromAssignedList = removeCustomerFromAssignedList;
                    $scope.forwardToCustomer = forwardToCustomer;
                    $scope.stopPropagationMobile = stopPropagationMobile;
                }

                function sendGetUserIdRequest(sessionId, callback){
                    const requestDetails = {
                        successful: true,
                        response: undefined
                    };

                    requestManager.routes.auth.getUserIdBySessionId(sessionId).then(
                        function(response){
                            requestDetails.response = response;
                            callback(requestDetails);
                        },
                        function(response){
                            requestDetails.response = response;
                            requestDetails.successful = false;
                            callback(requestDetails);
                        }
                    )
                }

                function getTaskListExtended(sessionId, callback){
                    const requestDetails = {
                        successful: true,
                        response: undefined
                    };

                    requestManager.routes.task.virtual.getTaskListExtended(sessionId).then(
                        function(response){
                            requestDetails.response = response;
                            callback(requestDetails);
                        },
                        function(response){
                            requestDetails.response = response;
                            requestDetails.successful = false;
                            callback(requestDetails);
                        }
                    )
                }

                function deleteTask() {
                    const selectedTasks = getSelectedTasks();
                    if (selectedTasks.length === 0) {
                        return;
                    }

                    let finishedRequests = 0;
                    selectedTasks.forEach(task => {
                        requestManager.routes.task.deleteTaskWithId($rootScope.authData.sessionId, task.task_id).then(
                            function () {
                                finishedRequests++;
                                if (finishedRequests === selectedTasks.length) {
                                    $route.reload();
                                }
                            },
                            function () {
                            }
                        )
                    });
                }

                function getCustomerList(authData, callback){
                    const requestDetails = {
                        successful: true,
                        response: undefined
                    };


                    requestManager.routes.customer.virtual.getCustomerListExtended(authData).then(
                        function(response) {
                            requestDetails.response = response;
                            callback(requestDetails);
                        },
                        function(response) {
                            requestDetails.response = response;
                            requestDetails.successful = false;
                            callback(requestDetails);
                        }
                    );
                }

                function modifyTaskList(taskList, filter){
                    taskList = addProperties(taskList);
                    taskList = applySearchTermFilter(taskList, filter);
                    taskList = applyDoneStatusFilter(taskList, filter);
                    taskList = applyTypeStatusFilter(taskList, filter);
                    taskList = applyOnlyAssignedCustomerFilter(taskList, filter);
                    taskList = util.divideArrayIntoChunks(taskList, filter.numEntriesPerPageSelected);

                    return taskList;
                }

                function addProperties(list){
                    list.forEach(function(element){
                        element.checked = false;
                    });

                    return list;
                }

                function applySearchTermFilter(list, filter){
                    if(angular.isUndefined(filter.searchTerm)){
                        return list;
                    }
                    const filteredList = [];

                    list.forEach(function(element){
                        if(element.task_name.toLowerCase().indexOf(filter.searchTerm.toLowerCase()) != -1 ||
                            element.customer_name.toLowerCase().indexOf(filter.searchTerm.toLowerCase()) != -1){
                            filteredList.push(element);
                        }
                    });
                    return filteredList;
                }

                function applyDoneStatusFilter(list, filter){
                    const filteredList = [];

                    if(filter.doneStatus == "ALL"){
                        return list;
                    }

                    switch (filter.doneStatus){
                        case "ALL":
                            return list;
                        case "NOT_DONE":
                            list.forEach(function(element){
                                if(element.task_done == "0"){
                                    filteredList.push(element);
                                }
                            });
                            return filteredList;
                        case "DONE":
                            list.forEach(function(element){
                                if(element.task_done == "1"){
                                    filteredList.push(element);
                                }
                            });
                            return filteredList;
                    }
                }

                function applyTypeStatusFilter(list, filter){
                    const filteredList = [];

                    if(angular.isUndefined(filter.typeSelected)){
                        return list;
                    }

                    switch (filter.typeSelected.key){
                        case "ALL":
                            return list;
                        case "LAWYER":
                            list.forEach(function(element){
                                if(element.task_type == "LAWYER"){
                                    filteredList.push(element);
                                }
                            });
                            return filteredList;
                        case "PHONE":
                            list.forEach(function(element){
                                if(element.task_type == "PHONE"){
                                    filteredList.push(element);
                                }
                            });
                            return filteredList;
                        case "CUSTOMER_ADVISOR":
                            list.forEach(function(element){
                                if(element.task_type == "CUSTOMER_ADVISOR"){
                                    filteredList.push(element);
                                }
                            });
                            return filteredList;
                    }
                }

                function applyOnlyAssignedCustomerFilter(list, filter){
                    const filteredList = [];

                    if(!filter.showOnlyAssigned){
                        return list;
                    }

                    list.forEach(function(task){
                        if(angular.isDefined(task.customerAdvisor_id) && parseInt(task.customerAdvisor_id) == parseInt($scope.userId.content)){
                            filteredList.push(task);
                        }
                    });
                    return filteredList;
                }

                function openTaskDetailsModal(task){
                    $scope.taskDetailsModal.task = task;
                    jQuery("#taskDetailsModal").modal("show");
                }

                function toggleFilterOptions(){
                    if($scope.filter.menuOpened){
                        jQuery("#filterOptions").hide(500)
                    }
                    else{
                        jQuery("#filterOptions").show(500);
                    }
                    $scope.filter.menuOpened = !$scope.filter.menuOpened;
                }

                function refreshTaskList(){
                    resetPaginationIndex();
                    $scope.tasks.taskListModified = modifyTaskList(angular.copy($scope.tasks.taskList), angular.copy($scope.filter));
                    $scope.totalRows = $scope.tasks.taskListModified.reduce((acc, subArray) => acc + subArray.length, 0);
                }

                function increasePaginationIndex(){
                    if($scope.pagination.pageIndex < $scope.tasks.taskListModified.length){
                        $scope.pagination.pageIndex++;
                    }
                }

                function decreasePaginationIndex(){
                    if($scope.pagination.pageIndex > 0){
                        $scope.pagination.pageIndex--;
                    }
                }

                function resetPaginationIndex(){
                    $scope.pagination.pageIndex = 0;
                }

                function reloadTaskList(){
                    $scope.tasks.currentlyLoading = true;

                    resetPaginationIndex();
                    getTaskListExtended($rootScope.authData.sessionId, function(requestDetails){
                        $scope.tasks.currentlyLoading = false;
                        if(requestDetails.successful){
                            $scope.tasks.currentlyLoading = false;
                            $scope.tasks.taskList = angular.copy(requestDetails.response.data);
                            $scope.tasks.taskListModified = modifyTaskList(angular.copy($scope.tasks.taskList), angular.copy($scope.filter));
                            $scope.totalRows = $scope.tasks.taskListModified.reduce((acc, subArray) => acc + subArray.length, 0);
                        }
                        else{
                            sharedMemory.error.setError(
                                requestDetails,
                                "COULD_NOT_LOAD_DATA",
                                "Fehler beim laden der Tasks (beim buttonclick auf refresh)",
                                $location.path(),
                                "taskList"
                            );
                            $location.path("/error-page");
                        }
                    });
                }

                function toggleCheckAll(){
                    $scope.tasks.taskListModified[$scope.pagination.pageIndex].forEach(function(task){
                        task.checked = $scope.filter.selectAllCheckbox;
                    });
                }

                function getSelectedTasks(){
                    const selectedTasks = [];

                    $scope.tasks.taskListModified.forEach(function(taskChunk){
                        taskChunk.forEach(function(task){
                            if(task.checked == "1"){
                                selectedTasks.push(task);
                            }
                        });
                    });

                    return selectedTasks;
                }

                function setSelectedTasksDone(){
                    const selectedTasks = getSelectedTasks();


                    setAllTasksDone($rootScope.authData.sessionId, selectedTasks, function(requestDetails){
                        if(requestDetails.allSuccessful){
                            Notification.success($filter("translate")("taskList.SET_DONE_SUCCESSFUL"));
                            reloadTaskList();
                        }
                        else{
                            Notification.success($filter("translate")("taskList.SET_DONE_NOT_SUCCESSFUL"));
                            console.error(requestDetails);
                            reloadTaskList();
                        }
                    })
                }

                function setSelectedTasksUndone(){
                    const selectedTasks = getSelectedTasks();


                    setAllTasksUndone($rootScope.authData.sessionId, selectedTasks, function(requestDetails){
                        if(requestDetails.allSuccessful){
                            Notification.success($filter("translate")("taskList.SET_UNDONE_SUCCESSFUL"));
                            reloadTaskList();
                        }
                        else{
                            Notification.success($filter("translate")("taskList.SET_UNDONE_NOT_SUCCESSFUL"));
                            reloadTaskList();
                            console.error(requestDetails);
                        }
                    })
                }

                function setAllTasksDone(sessionId, tasks, callback){
                    const requestDetails = {
                        allSuccessful: true,
                        numErrors: 0,
                        numSuccess: 0,
                        numRequests: 0,
                        numRequestsPlanned: tasks.length,
                        responses: [],
                        successfulResponses: [],
                        errorResponses: []
                    };

                    tasks.forEach(function(task){
                        sendEditTaskRequest(sessionId, task.task_id, {done: "1"}, function(editTaskRequestDetails){
                            if(editTaskRequestDetails.successful){
                                requestDetails.numSuccess++;
                                requestDetails.numRequests++;
                                requestDetails.responses.push(editTaskRequestDetails.response);
                                requestDetails.successfulResponses.push(editTaskRequestDetails.response);

                                if(requestDetails.numRequestsPlanned == requestDetails.numRequests){
                                    callback(requestDetails);
                                }
                            }
                            else{
                                requestDetails.numErrors++;
                                requestDetails.numRequests++;
                                requestDetails.responses.push(editTaskRequestDetails.response);
                                requestDetails.errorResponses.push(editTaskRequestDetails.response);
                                requestDetails.allSuccessful = false;

                                if(requestDetails.numRequestsPlanned == requestDetails.numRequests){
                                    callback(requestDetails);
                                }
                            }
                        });
                    });
                }

                function onSetDoneButtonClick(){
                    if($scope.taskDetailsModal.task.task_done == "1"){
                        return;
                    }

                    if($window.confirm($filter("translate")("taskList.SET_DONE"))){
                        setAllTasksDone($rootScope.authData.sessionId, [$scope.taskDetailsModal.task], function(requestDetails){
                            if(requestDetails.allSuccessful){
                                jQuery('#taskDetailsModal').on('hidden.bs.modal', function () {
                                    Notification.success($filter("translate")("taskList.SUCCESSFULLY_SET_DONE"));
                                    $route.reload();
                                }).modal("hide");
                            }
                            else{
                                if (requestDetails && requestDetails.responses && requestDetails.responses[0] && requestDetails.responses[0].status === 403) {
                                    Notification.error($filter("translate")("userSettings.ERROR_403_USER_NEED_APPROPRIATE_PERMISSION"));
                                } else {
                                    Notification.error($filter("translate")("taskList.CANT_SET_DONE"));
                                }
                            }
                        });
                    }
                }

                function onSetUndoneButtonClick(){
                    if($scope.taskDetailsModal.task.task_done == "0"){
                        return;
                    }

                    if($window.confirm($filter("translate")("taskList.SET_UNDONE"))){
                        setAllTasksUndone($rootScope.authData.sessionId, [$scope.taskDetailsModal.task], function(requestDetails){
                            if(requestDetails.allSuccessful){
                                jQuery('#taskDetailsModal').on('hidden.bs.modal', function () {
                                    Notification.success($filter("translate")("taskList.SUCCESSFULLY_SET_UNDONE"));
                                    $route.reload();
                                }).modal("hide");
                            }
                            else{
                                if (requestDetails && requestDetails.responses && requestDetails.responses[0] && requestDetails.responses[0].status === 403) {
                                    Notification.error($filter("translate")("userSettings.ERROR_403_USER_NEED_APPROPRIATE_PERMISSION"));
                                } else {
                                    Notification.error($filter("translate")("taskList.CANT_SET_UNDONE"));
                                }
                            }
                        });
                    }
                }

                function setAllTasksUndone(sessionId, tasks, callback){
                    const requestDetails = {
                        allSuccessful: true,
                        numErrors: 0,
                        numSuccess: 0,
                        numRequests: 0,
                        numRequestsPlanned: tasks.length,
                        responses: [],
                        successfulResponses: [],
                        errorResponses: []
                    };

                    tasks.forEach(function(task){
                        sendEditTaskRequest(sessionId, task.task_id, {done: "0"}, function(editTaskRequestDetails){
                            if(editTaskRequestDetails.successful){
                                requestDetails.numSuccess++;
                                requestDetails.numRequests++;
                                requestDetails.responses.push(editTaskRequestDetails.response);
                                requestDetails.successfulResponses.push(editTaskRequestDetails.response);

                                if(requestDetails.numRequestsPlanned == requestDetails.numRequests){
                                    callback(requestDetails);
                                }
                            }
                            else{
                                requestDetails.numErrors++;
                                requestDetails.numRequests++;
                                requestDetails.responses.push(editTaskRequestDetails.response);
                                requestDetails.errorResponses.push(editTaskRequestDetails.response);
                                requestDetails.allSuccessful = false;

                                if(requestDetails.numRequestsPlanned == requestDetails.numRequests){
                                    callback(requestDetails);
                                }
                            }
                        });
                    });
                }

                function sendEditTaskRequest(sessionId, taskId, params, callback){
                    const requestDetails = {
                        successful: true,
                        response: undefined
                    };

                    requestManager.routes.task.editTaskWithId(sessionId, taskId, params).then(
                        function(response){
                            requestDetails.response = response;
                            callback(requestDetails);
                        },
                        function(response){
                            requestDetails.response = response;
                            requestDetails.successful = false;
                            callback(requestDetails);
                        }
                    )
                }

                function resetFilter(){
                    $scope.filter.searchTerm = undefined;
                    $scope.filter.doneStatus = "ALL";
                    $scope.filter.typeSelected = undefined;
                    refreshTaskList();
                }

                function sendCreateNewTaskRequest(sessionId, body, callback){
                    const requestDetails = {
                        successful: true,
                        response: undefined
                    };

                    requestManager.routes.task.createTask(sessionId, body).then(
                        function(response){
                            requestDetails.response = response;
                            callback(requestDetails);
                        },
                        function(response){
                            requestDetails.response = response;
                            requestDetails.successful = false;
                            callback(requestDetails);
                        }
                    )
                }

                function openCreateNewTaskModal(){
                    jQuery("#createNewTaskModal").modal("show");
                }

                function closeCreateNewTaskModal(){
                    jQuery("#createNewTaskModal").modal("hide");
                }

                function onCreateNewTaskDropdownButtonClick(){
                    openCreateNewTaskModal();
                }

                function resetCreateNewTaskForm(){
                    $scope.createNewTaskModal.taskName = undefined;
                    $scope.createNewTaskModal.taskType = undefined;
                    $scope.createNewTaskModal.taskDescription = undefined;
                    $scope.createNewTaskModal.customerAssigned = [];
                    $scope.createNewTaskModal.customerSelected = undefined;
                    $scope.createNewTaskForm.$submitted = false;
                }

                function onCreateNewTaskButtonClick(){
                    if($scope.createNewTaskForm.$invalid){
                        return;
                    }

                    let body = undefined;
                    const sessionId = $rootScope.authData.sessionId;
                    const assignedCustomerList = angular.isDefined($scope.createNewTaskModal.customerSelected) ? [$scope.createNewTaskModal.customerSelected] : [];

                    if($window.confirm($filter("translate")("taskList.CONFIRM_CREATE_TASK"))){
                        body = {
                            name: $scope.createNewTaskModal.taskName,
                            description: $scope.createNewTaskModal.taskDescription,
                            type: $scope.createNewTaskModal.taskType.key,
                            done: "0"
                        };

                        sendCreateNewTaskRequest(sessionId, body, function(requestDetails){
                            if(requestDetails.successful){

                                sendAllAddCustomerToTaskRequests(sessionId,
                                    requestDetails.response.data,
                                    assignedCustomerList,
                                    function(addAllCustomerToTaskRequestDetails){
                                        reloadTaskList();
                                        resetCreateNewTaskForm();
                                        closeCreateNewTaskModal();
                                        if(addAllCustomerToTaskRequestDetails.allSuccessful){
                                            Notification.success($filter("translate")("taskList.TASK_CREATED_SUCCESSFULLY"));
                                        }
                                        else{
                                            jQuery("#createNewTaskModal").one("hidden.bs.modal", function(){
                                                sharedMemory.error.setError(
                                                    requestDetails,
                                                    "COULD_NOT_LOAD_DATA",
                                                    "Fehler beim erstellen einer verlinkung zwischen customer und task",
                                                    $location.path(),
                                                    "taskList"
                                                );
                                                $location.path("/error-page");
                                                $scope.$apply();
                                            }).modal("hide");
                                        }
                                    });
                            }
                            else{
                                jQuery("#createNewTaskModal").one("hidden.bs.modal", function(){
                                    sharedMemory.error.setError(
                                        requestDetails,
                                        "COULD_NOT_LOAD_DATA",
                                        "Fehler beim erstellen von neuen Tasks ",
                                        $location.path(),
                                        "taskList"
                                    );
                                    $location.path("/error-page");
                                }).modal("hide");
                            }
                        })
                    }
                }

                function addCustomerToAssignedList(){
                    if(angular.isDefined($scope.createNewTaskModal.customerSelected)){
                        let isInList = $scope.createNewTaskModal.customerAssigned.some(function (customer) {
                            return customer.id == $scope.createNewTaskModal.customerSelected.id;
                        });

                        if(!isInList){
                            $scope.createNewTaskModal.customerAssigned.push($scope.createNewTaskModal.customerSelected);
                        }
                    }
                }

                function sendAllAddCustomerToTaskRequests(sessionId, taskId, customerList, callback){
                    const requestDetails = {
                        numRequestsPlanned: customerList.length,
                        errorResponses: [],
                        successResponses: [],
                        allResponses: [],
                        numErrors: 0,
                        numSuccess: 0,
                        numResponses: 0,
                        allSuccessful: true
                    };

                    if(customerList.length === 0 ){
                        callback(requestDetails);
                        return;
                    }

                    customerList.forEach(function(customer){
                        sendAddCustomerToTaskRequest(sessionId, customer.id, taskId, function(addCustomerToTaskRequestDetails){
                            if(addCustomerToTaskRequestDetails.successful){
                                requestDetails.successResponses.push(addCustomerToTaskRequestDetails.response);
                                requestDetails.allResponses.push(addCustomerToTaskRequestDetails.response);
                                requestDetails.numSuccess++;
                                requestDetails.numResponses++;
                                if(requestDetails.numRequestsPlanned === requestDetails.numResponses){
                                    callback(requestDetails);
                                }
                            }
                            else{
                                requestDetails.errorResponses.push(addCustomerToTaskRequestDetails.response);
                                requestDetails.allResponses.push(addCustomerToTaskRequestDetails.response);
                                requestDetails.numErrors++;
                                requestDetails.numResponses++;
                                requestDetails.allSuccessful = false;
                                if(requestDetails.numRequestsPlanned === requestDetails.numResponses){
                                    callback(requestDetails);
                                }
                            }
                        });
                    });
                }

                function sendAddCustomerToTaskRequest(sessionId, customerId, taskId, callback){
                    const requestDetails = {
                        successful: true,
                        response: undefined
                    };
                    requestManager.routes.task.addCustomerToTask(sessionId, customerId, taskId).then(
                        function(response){
                            requestDetails.response = response;
                            callback(requestDetails);
                        },
                        function(response){
                            requestDetails.response = response;
                            requestDetails.successful = false;
                            callback(requestDetails);
                        }
                    )
                }

                function removeCustomerFromAssignedList(index){
                    $scope.createNewTaskModal.customerAssigned.splice(index, 1);
                }

                function forwardToCustomer(customerId){
                    let tabWindow = $window.open('about:blank', '_blank');
                    tabWindow.document.write('loading...');
                    let currentPath = $window.location.href.substr(0, window.location.href.indexOf('#') + 1);
                    tabWindow.location.href = currentPath + "!/user-details/" + customerId;
                    tabWindow.location.reload();

                    jQuery("#taskDetailsModal").one("hidden.bs.modal", function(){

                        // var currentPath = $window.location.href.substr(0, window.location.href.indexOf('#') + 1);
                        // $window.open(currentPath + "!/user-details/" + customerId, "_blank");
                    }).modal("hide");
                }

                function stopPropagationMobile(e){
                    e.stopPropagation();
                }
            }
        ]
    }
);

angular.module('taskListV2', []);

angular.module('taskListV2').component('taskListV2', {
        templateUrl: 'app/components/taskListV2/taskListV2.template.html',
        controller: [
            "$scope",
            "$rootScope",
            "Notification",
            "$location",
            "requestManager",
            "sharedMemory",
            "util",
            "$filter",
            "$window",
            "$route",
            "$timeout",
            function ($scope, $rootScope, Notification, $location, requestManager, sharedMemory, util, $filter, $window, $route, $timeout) {
                this.$onInit = function () {
                    $scope.europeanNumberFormat = 'en-US';
                    if ($rootScope.europeanNumberFormat !== undefined) {
                        if ($rootScope.europeanNumberFormat === '1' || $rootScope.europeanNumberFormat === 1) {
                            $scope.europeanNumberFormat = 'de-AT';
                        } else {
                            $scope.europeanNumberFormat = 'en-US';
                        }
                    }
                    initVariables();
                    initScope();

                    getTasks();
                    getCustomers();
                    getUsers();
                };

                function initVariables() {
                    $scope.initialLoading = true;
                    $scope.tasks = [];
                    $scope.loading = true;
                    $scope.searchTerm = '';
                    $scope.numShowEntriesOptions = [10, 20];
                    $scope.typeOptions = [
                        {key: "PHONE", text: $filter("translate")("taskList.PHONE_CALL"), iconClass: "fa fa-suitcase"},
                        {key: "LAWYER", text: $filter("translate")("taskList.INFORM_LAWYER"), iconClass: "fa fa-phone"},
                        {
                            key: "CUSTOMER_ADVISOR",
                            text: $filter("translate")("taskList.CUSTOMER_ADVISOR"),
                            iconClass: "fa fa-user-circle"
                        }
                    ];
                    $scope.selectedType = '';
                    $scope.filterAssignedToMe = false;
                    $scope.paginator = {
                        items: [],
                        itemsPerPage: null,
                        itemsTotal: null,
                        pageCurrent: null,
                        pagesTotal: null
                    };
                    $scope.filters = {
                        page: 1,
                        pageSize: 10,
                        order: "entryCreated",
                        direction: "desc",
                        searchTerm: null,
                        filter: null
                    };
                    $scope.menuOpened = true;
                    $scope.selectedStatus = 'NOT_DONE';
                    $scope.filterArray = ['done eq 0'];
                    $scope.selectAllCheckbox = false;
                    $scope.customers = [];
                    $scope.customersLoading = false;
                    $scope.submitLoading = false;
                    $scope.selectedCustomer = '';
                    $scope.searchCustomer = null;
                    $scope.showDropdown = false;
                    $scope.timeout = null;
                }

                function initScope() {
                    $scope.changeNumShowEntries = changeNumShowEntries;
                    $scope.nextPage = nextPage;
                    $scope.previousPage = previousPage;
                    $scope.firstPage = firstPage;
                    $scope.lastPage = lastPage;
                    $scope.checkAllTasks = checkAllTasks;
                    $scope.checkOneTask = checkOneTask;
                    $scope.openDetailsModal = openDetailsModal;
                    $scope.assignedToMe = assignedToMe;
                    $scope.openDetailsModal = openDetailsModal;
                    $scope.openCreateNewTaskModal = openCreateNewTaskModal;
                    $scope.setSelectedTasksDone = setSelectedTasksDone;
                    $scope.setSelectedTasksUndone = setSelectedTasksUndone;
                    $scope.deleteTask = deleteTask;
                    $scope.toggleFilterOptions = toggleFilterOptions;
                    $scope.onSearch = onSearch;
                    $scope.onSelectType = onSelectType;
                    $scope.onSelectStatus = onSelectStatus;
                    $scope.forwardToCustomer = forwardToCustomer;
                    $scope.setDone = setDone;
                    $scope.setUndone = setUndone;
                    $scope.createNewTask = createNewTask;
                    $scope.selectCustomer = selectCustomer;
                }

                $scope.searchCustomers = function () {
                    if ($scope.timeout) {
                        $timeout.cancel($scope.timeout);
                    }

                    $scope.customersLoading = true;

                    $scope.timeout = $timeout(function () {
                        $scope.customersLoading = true;
                        requestManager.routes.customers.getCustomers($rootScope.authData.sessionId, {
                            pageSize: 5,
                            searchTerm: $scope.searchCustomer
                        }).then(
                            function (response) {
                                $scope.customers = response.data.items;
                                $scope.customersLoading = false;
                            },
                            function () {
                            }
                        );
                    }, 700);
                }

                function selectCustomer(customer) {
                    $scope.searchCustomer = customer.name;
                    $scope.selectedCustomer = customer;
                    $scope.showDropdown = false;
                }

                function getCustomers() {
                    requestManager.routes.customers.getCustomers($rootScope.authData.sessionId, {
                        pageSize: 10,
                        searchTerm: $scope.searchCustomer
                    }).then(
                        function (response) {
                            $scope.customers = response.data.items;
                        },
                        function () {
                        }
                    );
                }

                /* ---------- filters ---------- */

                function changeNumShowEntries() {
                    $scope.filters.page = 1;
                    getTasks();
                }

                function onSearch() {
                    $scope.filters.page = 1;
                    getTasks();
                }

                function onSelectType(value) {
                    $scope.filters.page = 1;
                    removeElementFromArray(`type eq `);
                    if (value !== '') {
                        $scope.filterArray.push(`type eq ${value}`);
                    }
                    getTasks();
                }

                function onSelectStatus(value) {
                    $scope.filters.page = 1;
                    removeElementFromArray(`done eq `);
                    if (value !== '') {
                        $scope.filterArray.push(`done eq ${value === 'DONE' ? 1 : 0}`);
                    }
                    getTasks();
                }

                function assignedToMe() {
                    $scope.filters.page = 1;
                    $scope.filterAssignedToMe = !$scope.filterAssignedToMe;
                    const myId = $rootScope.authData.userId;
                    removeElementFromArray(`assignedUserId eq ${myId}`);
                    if ($scope.filterAssignedToMe) {
                        $scope.filterArray.push(`assignedUserId eq ${myId}`);
                    }
                    getTasks();
                }

                function toggleFilterOptions() {
                    if ($scope.menuOpened) {
                        jQuery("#filterOptions").hide(500)
                    } else {
                        jQuery("#filterOptions").show(500);
                    }
                    $scope.menuOpened = !$scope.menuOpened;
                }

                function removeElementFromArray(element) {
                    const index = $scope.filterArray.indexOf(element);
                    if (index > -1) {
                        $scope.filterArray.splice(index, 1);
                    } else {
                        const [key] = element.split(" eq ");
                        const regex = new RegExp(`^${key} eq`);
                        for (let i = 0; i < $scope.filterArray.length; i++) {
                            if (regex.test($scope.filterArray[i])) {
                                $scope.filterArray.splice(i, 1);
                                break;
                            }
                        }
                    }
                }

                /* ---------- pagination ---------- */

                function firstPage() {
                    $scope.filters.page = 1;
                    getTasks();
                }

                function lastPage() {
                    $scope.filters.page = $scope.paginator.pagesTotal;
                    getTasks();
                }

                function nextPage() {
                    $scope.filters.page++;
                    getTasks();
                }

                function previousPage() {
                    $scope.filters.page--;
                    getTasks();
                }

                /* ---------- v2 ---------- */

                function getTasks() {
                    $scope.loading = true;
                    $scope.filters.filter = $scope.filterArray.join(' and ');
                    requestManager.routes.task.getTasks($rootScope.authData.sessionId, $scope.filters).then(
                        function (response) {
                            $scope.paginator = response.data;
                            $scope.tasks = [...$scope.paginator.items];
                            $scope.initialLoading = false;
                            $scope.loading = false;
                        },
                        function () {
                        }
                    );
                }

                function checkAllTasks(status) {
                    $scope.tasks.forEach(task => {
                        task.selected = status;
                    });
                }

                function checkOneTask() {
                    $scope.selectAllCheckbox = $scope.tasks.every(item => item.selected === true);
                }

                function openDetailsModal(task) {
                    $scope.taskDetailsModal = task;
                    jQuery("#taskDetailsModal").modal("show");
                }

                function openCreateNewTaskModal() {
                    jQuery("#createNewTaskModalV2").modal("show");
                }

                function forwardToCustomer(customerId) {
                    let tabWindow = $window.open('about:blank', '_blank');
                    tabWindow.document.write('loading...');
                    let currentPath = $window.location.href.substr(0, window.location.href.indexOf('#') + 1);
                    tabWindow.location.href = currentPath + "!/user-details/" + customerId;
                    tabWindow.location.reload();

                    jQuery("#taskDetailsModal").one("hidden.bs.modal", function () {

                        // var currentPath = $window.location.href.substr(0, window.location.href.indexOf('#') + 1);
                        // $window.open(currentPath + "!/user-details/" + customerId, "_blank");
                    }).modal("hide");
                }

                function setDone() {
                    if ($scope.taskDetailsModal.done) {
                        return;
                    }

                    if ($window.confirm($filter("translate")("taskList.SET_DONE"))) {
                        setAllTasksDone($rootScope.authData.sessionId, [$scope.taskDetailsModal], function (requestDetails) {
                            if (requestDetails.allSuccessful) {
                                jQuery('#taskDetailsModal').on('hidden.bs.modal', function () {
                                    // Notification.success($filter("translate")("taskList.SUCCESSFULLY_SET_DONE"));
                                    getTasks();
                                    $rootScope.amountOfOpenTasks -= 1;
                                }).modal("hide");
                            } else {
                                if (requestDetails && requestDetails.responses && requestDetails.responses[0] && requestDetails.responses[0].status === 403) {
                                    Notification.error($filter("translate")("userSettings.ERROR_403_USER_NEED_APPROPRIATE_PERMISSION"));
                                } else {
                                    Notification.error($filter("translate")("taskList.CANT_SET_DONE"));
                                }
                            }
                        });
                    }
                }

                function setUndone() {
                    if (!$scope.taskDetailsModal.done) {
                        return;
                    }

                    if ($window.confirm($filter("translate")("taskList.SET_UNDONE"))) {
                        setAllTasksUndone($rootScope.authData.sessionId, [$scope.taskDetailsModal], function (requestDetails) {
                            if (requestDetails.allSuccessful) {
                                jQuery('#taskDetailsModal').on('hidden.bs.modal', function () {
                                    // Notification.success($filter("translate")("taskList.SUCCESSFULLY_SET_UNDONE"));
                                    getTasks();
                                    $rootScope.amountOfOpenTasks += 1;
                                }).modal("hide");
                            } else {
                                if (requestDetails && requestDetails.responses && requestDetails.responses[0] && requestDetails.responses[0].status === 403) {
                                    Notification.error($filter("translate")("userSettings.ERROR_403_USER_NEED_APPROPRIATE_PERMISSION"));
                                } else {
                                    Notification.error($filter("translate")("taskList.CANT_SET_UNDONE"));
                                }
                            }
                        });
                    }
                }

                function deleteTask() {
                    const selectedTasks = $scope.tasks.filter(task => task.selected);
                    if (selectedTasks.length === 0) {
                        return;
                    }

                    let finishedRequests = 0;
                    selectedTasks.forEach(task => {
                        requestManager.routes.task.deleteTaskWithId($rootScope.authData.sessionId, task.id).then(
                            function () {
                                finishedRequests++;
                                if (finishedRequests === selectedTasks.length) {
                                    getTasks();
                                }
                            },
                            function () {
                            }
                        )
                    });
                }

                function setSelectedTasksDone() {
                    const selectedTasks = $scope.tasks.filter(task => task.selected);
                    if (selectedTasks.length === 0) {
                        return;
                    }

                    setAllTasksDone($rootScope.authData.sessionId, selectedTasks, function (requestDetails) {
                        if (requestDetails.allSuccessful) {
                            Notification.success($filter("translate")("taskList.SET_DONE_SUCCESSFUL"));
                            getTasks();
                            $rootScope.amountOfOpenTasks -= requestDetails.numSuccess;
                            $scope.selectAllCheckbox = false;
                        } else {
                            Notification.success($filter("translate")("taskList.SET_DONE_NOT_SUCCESSFUL"));
                            console.error(requestDetails);
                            getTasks();
                        }
                    })
                }

                function setAllTasksDone(sessionId, tasks, callback) {
                    const requestDetails = {
                        allSuccessful: true,
                        numErrors: 0,
                        numSuccess: 0,
                        numRequests: 0,
                        numRequestsPlanned: tasks.length,
                        responses: [],
                        successfulResponses: [],
                        errorResponses: []
                    };

                    tasks.forEach(function (task) {
                        sendEditTaskRequest(sessionId, task.id, {done: 1}, function (editTaskRequestDetails) {
                            if (editTaskRequestDetails.successful) {
                                requestDetails.numSuccess++;
                                requestDetails.numRequests++;
                                requestDetails.responses.push(editTaskRequestDetails.response);
                                requestDetails.successfulResponses.push(editTaskRequestDetails.response);

                                if (requestDetails.numRequestsPlanned === requestDetails.numRequests) {
                                    callback(requestDetails);
                                }
                            } else {
                                requestDetails.numErrors++;
                                requestDetails.numRequests++;
                                requestDetails.responses.push(editTaskRequestDetails.response);
                                requestDetails.errorResponses.push(editTaskRequestDetails.response);
                                requestDetails.allSuccessful = false;

                                if (requestDetails.numRequestsPlanned === requestDetails.numRequests) {
                                    callback(requestDetails);
                                }
                            }
                        });
                    });
                }

                function setSelectedTasksUndone() {
                    const selectedTasks = $scope.tasks.filter(task => task.selected);
                    if (selectedTasks.length === 0) {
                        return;
                    }

                    setAllTasksUndone($rootScope.authData.sessionId, selectedTasks, function (requestDetails) {
                        if (requestDetails.allSuccessful) {
                            Notification.success($filter("translate")("taskList.SET_UNDONE_SUCCESSFUL"));
                            getTasks();
                            $rootScope.amountOfOpenTasks += requestDetails.numSuccess;
                            $scope.selectAllCheckbox = false;
                        } else {
                            Notification.success($filter("translate")("taskList.SET_UNDONE_NOT_SUCCESSFUL"));
                            getTasks();
                            console.error(requestDetails);
                        }
                    })
                }

                function setAllTasksUndone(sessionId, tasks, callback) {
                    const requestDetails = {
                        allSuccessful: true,
                        numErrors: 0,
                        numSuccess: 0,
                        numRequests: 0,
                        numRequestsPlanned: tasks.length,
                        responses: [],
                        successfulResponses: [],
                        errorResponses: []
                    };

                    tasks.forEach(function (task) {
                        sendEditTaskRequest(sessionId, task.id, {done: 0}, function (editTaskRequestDetails) {
                            if (editTaskRequestDetails.successful) {
                                requestDetails.numSuccess++;
                                requestDetails.numRequests++;
                                requestDetails.responses.push(editTaskRequestDetails.response);
                                requestDetails.successfulResponses.push(editTaskRequestDetails.response);

                                if (requestDetails.numRequestsPlanned === requestDetails.numRequests) {
                                    callback(requestDetails);
                                }
                            } else {
                                requestDetails.numErrors++;
                                requestDetails.numRequests++;
                                requestDetails.responses.push(editTaskRequestDetails.response);
                                requestDetails.errorResponses.push(editTaskRequestDetails.response);
                                requestDetails.allSuccessful = false;

                                if (requestDetails.numRequestsPlanned === requestDetails.numRequests) {
                                    callback(requestDetails);
                                }
                            }
                        });
                    });
                }

                function sendEditTaskRequest(sessionId, taskId, params, callback) {
                    const requestDetails = {
                        successful: true,
                        response: undefined
                    };

                    requestManager.routes.task.editTaskWithId(sessionId, taskId, params).then(
                        function (response) {
                            requestDetails.response = response;
                            callback(requestDetails);
                        },
                        function (response) {
                            requestDetails.response = response;
                            requestDetails.successful = false;
                            callback(requestDetails);
                        }
                    )
                }

                function getUsers() {
                    // TODO
                    requestManager.routes.user.getAllUser($rootScope.authData.sessionId).then(
                        function (response) {
                            $scope.users = response.data.filter(user => user.id !== '0');
                            $scope.usersLoading = false;
                        },
                        function () {
                        }
                    )
                }

                function createNewTask() {
                    if ($scope.createNewTaskForm.$invalid) {
                        return;
                    }

                    if ($window.confirm($filter("translate")("taskList.CONFIRM_CREATE_TASK"))) {
                        $scope.submitLoading = true;
                        const body = {
                            name: $scope.createNewTaskModal.taskName,
                            description: $scope.createNewTaskModal.taskDescription,
                            type: $scope.createNewTaskModal.taskType.key,
                            done: 0,
                            customerId: $scope.selectedCustomer.accountNumber,
                            assignedUserId: $scope.createNewTaskModal.assignedUserId.id,
                        };

                        sendCreateNewTaskRequest($rootScope.authData.sessionId, body, function (requestDetails) {
                            if (requestDetails.successful) {
                                closeCreateNewTaskModal();
                                resetCreateNewTaskForm();
                                getTasks();
                                $rootScope.amountOfOpenTasks += 1;
                                $scope.submitLoading = false;
                            } else {
                                $scope.submitLoading = false;
                                jQuery("#createNewTaskModalV2").one("hidden.bs.modal", function () {
                                    sharedMemory.error.setError(
                                        requestDetails,
                                        "COULD_NOT_LOAD_DATA",
                                        "Fehler beim erstellen von neuen Tasks ",
                                        $location.path(),
                                        "taskList"
                                    );
                                    $location.path("/error-page");
                                }).modal("hide");
                            }
                        })
                    }
                }

                function sendCreateNewTaskRequest(sessionId, body, callback) {
                    const requestDetails = {
                        successful: true,
                        response: undefined
                    };

                    requestManager.routes.task.createTaskV2(sessionId, body).then(
                        function (response) {
                            requestDetails.response = response;
                            callback(requestDetails);
                        },
                        function (response) {
                            requestDetails.response = response;
                            requestDetails.successful = false;
                            callback(requestDetails);
                        }
                    )
                }

                function closeCreateNewTaskModal() {
                    jQuery("#createNewTaskModalV2").modal("hide");
                }

                function resetCreateNewTaskForm() {
                    $scope.createNewTaskModal.taskName = undefined;
                    $scope.createNewTaskModal.taskType = undefined;
                    $scope.createNewTaskModal.taskDescription = undefined;
                    $scope.createNewTaskModal.assignedUserid = undefined;
                    $scope.createNewTaskModal.assignedCustomerId = undefined;
                    $scope.selectedCustomer = null;
                    $scope.searchCustomer = null;
                    $scope.createNewTaskForm.$submitted = false;
                }

            }
        ]
    }
);

angular.module('workflowCreator', []);
angular.module('workflowCreator').component('workflowCreator', {
    templateUrl: 'app/components/workflowCreator/workflowCreator.template.html',
    bindings: {
        settings: '<',
        emailTemplates: '<'
    },
    controller: [
        "$scope",
        "$rootScope",
        "requestManager",
        "Notification",
        "$filter",
        "$route",
        "$window",
        "sharedMemory",
        "$location",
        "util",
        function ($scope, $rootScope, requestManager, Notification, $filter, $route, $window, sharedMemory, $location, util) {
            (function init() {
                initVariables();
                initScope();
                initListener();
            })();

            this.$onChanges = function set(item) {
                if (item.settings && item.settings.currentValue) {
                    $scope.settings.content = item.settings.currentValue;
                    $scope.settings.currentlyLoading = false;
                }
                if (item.emailTemplates && item.emailTemplates.currentValue) {
                    $scope.emailTemplates.content = item.emailTemplates.currentValue;
                    $scope.emailTemplates.content.sort(function(a, b) {
                        const keyA = a.title;
                        const keyB = b.title;
                        if (keyA < keyB) return -1;
                        if (keyA > keyB) return 1;
                        return 0;
                    });
                    $scope.emailTemplates.currentlyLoading = false;
                }
                if (angular.isDefined($scope.settings) && angular.isDefined($scope.emailTemplates)) {
                    $scope.view.allDependenciesLoaded = true;
                }
                if ($scope.settings.content !== undefined) {
                    $scope.settings.companyLogo = $scope.settings.content.find(setting => {
                        return setting.settingCode === "companyLogo";
                    });
                }
            };

            function initVariables() {
                $scope.createWorkflowHeader = {
                    sendPhysicalMailEnrolled: "0",
                    noPermissionNeeded: undefined,
                    workflowName: undefined,
                    workflowAction: undefined,
                    //TODO: ADD ACTIONS
                    actionOptions: [
                        {key: "TO_PHONE_LIST", text: $filter("translate")("workflowCreator.TO_PHONE_LIST")},
                        {key: "TO_LAWYER_LIST", text: $filter("translate")("workflowCreator.TO_LAWYER_LIST")},
                        {key: "TO_MAIL", text: $filter("translate")("workflowCreator.TO_MAIL")},
                        {
                            key: "TO_PAYMENT_TERM_MAIL",
                            text: $filter("translate")("workflowCreator.TO_PAYMENT_TERM_MAIL")
                        },
                        {key: "TO_PROBLEMATIC", text: $filter("translate")("workflowCreator.TO_PROBLEMATIC")},
                        {key: "TO_CUSTOMERADVISOR", text: $filter("translate")("workflowCreator.TO_CUSTOMERADVISOR")},
                        {key: "TO_PHYSICAL_MAIL", text: $filter("translate")("workflowCreator.TO_PHYSICAL_MAIL")},
                        {
                            key: "TO_EMAIL_OR_PHYSICAL_MAIL",
                            text: $filter("translate")("workflowCreator.TO_EMAIL_OR_PHYSICAL_MAIL")
                        },
                        {key: "TO_EMAIL_AND_PHYSICAL_MAIL", text: $filter("translate")("workflowCreator.TO_EMAIL_AND_PHYSICAL_MAIL")},
                    ],
                    workflowInterval: undefined,
                    intervalOptions: [
                        {key: "P1D", text: $filter("translate")("workflowCreator.P1D")},
                        {key: "P1W", text: $filter("translate")("workflowCreator.P1W")},
                        {key: "P2W", text: $filter("translate")("workflowCreator.P2W")},
                        {key: "P1M", text: $filter("translate")("workflowCreator.P1M")}
                    ],
                    workflowIntervalDay: undefined,
                    intervalDayOptions: [
                        {key: "MO", text: $filter("translate")("workflowCreator.MONDAY")},
                        {key: "DI", text: $filter("translate")("workflowCreator.TUESDAY")},
                        {key: "MI", text: $filter("translate")("workflowCreator.WEDNESDAY")},
                        {key: "DO", text: $filter("translate")("workflowCreator.THURSDAY")},
                        {key: "FR", text: $filter("translate")("workflowCreator.FRIDAY")},
                        {key: "SA", text: $filter("translate")("workflowCreator.SATURDAY")},
                        {key: "SO", text: $filter("translate")("workflowCreator.SUNDAY")}
                    ],
                    intervalHourOptions: createIntervalHours(0, 23)
                };
                $scope.createWorkflowFilter = {
                    createWorkflowFilterList: [],
                    availableFilter: [
                        {key: "isProblematic", text: $filter("translate")("workflowCreator.IS_PROBLEMATIC")},
                        {key: "amountMails", text: $filter("translate")("workflowCreator.AMOUNT_MAILS")},
                        {key: "amountCalls", text: $filter("translate")("workflowCreator.AMOUNT_CALLS")},
                        {key: "amountDebts", text: $filter("translate")("workflowCreator.AMOUNT_DEBTS")},
                        {key: "lastTimeContacted", text: $filter("translate")("workflowCreator.LAST_TIME_CONTACTED")},
                        {key: "oldestInvoice", text: $filter("translate")("workflowCreator.OLDEST_INVOICE")},
                        {key: "preferredContactMethod", text: "Bevorzugte Kontaktmethode"},
                        {key: "exceedsCreditLimit", text: $filter("translate")("workflowCreator.EXCEEDS_CREDIT_LLMIT")},
                        {
                            key: "amountPendingCallJobs",
                            text: $filter("translate")("workflowCreator.AMOUNT_PENDING_CALLJOBS")
                        },
                        {
                            key: "paymentTermAppointmentsOverdueInDays",
                            text: $filter("translate")("workflowCreator.PAYMENT_TERM_APPOINTMENTS_OVERDUE_IN_DAYS")
                        },
                        //NEW FILTERS
                        {
                            key: "amountDaysSinceLastSuccessfullCall",
                            text: $filter("translate")("workflowCreator.AMOUNT_DAYS_SINCE_LAST_SUCCESSFULL_CALL")
                        },
                        {key: "individualField1", text: $filter("translate")("workflowCreator.INDIVIDUAL_FIELD_X") + 1},
                        {key: "individualField2", text: $filter("translate")("workflowCreator.INDIVIDUAL_FIELD_X") + 2},
                        {key: "individualField3", text: $filter("translate")("workflowCreator.INDIVIDUAL_FIELD_X") + 3},
                        {key: "individualField4", text: $filter("translate")("workflowCreator.INDIVIDUAL_FIELD_X") + 4},
                        {key: "individualField5", text: $filter("translate")("workflowCreator.INDIVIDUAL_FIELD_X") + 5},
                        {key: "individualField6", text: $filter("translate")("workflowCreator.INDIVIDUAL_FIELD_X") + 6},
                        {key: "individualField7", text: $filter("translate")("workflowCreator.INDIVIDUAL_FIELD_X") + 7},
                        {key: "individualField8", text: $filter("translate")("workflowCreator.INDIVIDUAL_FIELD_X") + 8},
                        {key: "individualField9", text: $filter("translate")("workflowCreator.INDIVIDUAL_FIELD_X") + 9},
                        {key: "individualField10", text: $filter("translate")("workflowCreator.INDIVIDUAL_FIELD_X") + 10},
                    ]
                };
                $scope.view = {
                    allDependenciesLoaded: false
                };
                $scope.emailTemplates = {
                    content: undefined,
                    currentlyLoading: true
                };
                $scope.settings = {
                    content: undefined,
                    currentlyLoading: true
                };
            }

            function initScope() {
                $scope.addWorkflowToList = addWorkflowToList;
                $scope.changeFilterProperties = changeFilterProperties;
                $scope.removeFilterRow = removeFilterRow;
                $scope.createWorkflow = onCreateWorkflowButtonClick;
                $scope.openWorkflowCreateInfoModal = openWorkflowCreateInfoModal;
                $scope.openWorkflowFilterInfoModal = openWorkflowFilterInfoModal;
                $scope.generateExecutedDate = generateExecutedDate;
            }

            function initListener() {
                $scope.$on('$routeChangeStart', function () {
                    requestManager.stopPendingRequests();
                });
            }

            function createIntervalHours(min, max) {
                const hourArray = [];
                for (let i = min; i < max + 1; i++) {
                    hourArray.push({key: i, text: i + ":00"});
                }
                return hourArray;
            }

            function addWorkflowToList() {
                const filter = {
                    filterType: $scope.createWorkflowFilter.availableFilter[0],
                    filterProperties: {
                        availableOperators: ["="],
                        operatorSelected: undefined,
                        valueInputType: "SELECT",
                        availableValues: [
                            {key: "1", text: $filter("translate")("workflowCreator.YES")},
                            {key: "0", text: $filter("translate")("workflowCreator.NO")}
                        ],
                        valueSelect: undefined,
                        valueInput: undefined
                    }
                };
                filter.filterProperties.operatorSelected = filter.filterProperties.availableOperators[0];
                filter.filterProperties.valueSelect = filter.filterProperties.availableValues[0];
                $scope.createWorkflowFilter.createWorkflowFilterList.push(filter);
            }

            function changeFilterProperties(filter) {
                switch (filter.filterType.key) {
                    case "exceedsCreditLimit":
                        filter.filterProperties = {
                            availableOperators: ["="],
                            operatorSelected: undefined,
                            valueInputType: "SELECT",
                            availableValues: [
                                {key: "1", text: $filter("translate")("workflowCreator.YES")},
                            ],
                            valueSelect: undefined,
                            valueInput: undefined
                        };
                        filter.filterProperties.operatorSelected = filter.filterProperties.availableOperators[0];
                        filter.filterProperties.valueSelect = filter.filterProperties.availableValues[0];
                        break;
                    case "isProblematic":
                        filter.filterProperties = {
                            availableOperators: ["="],
                            operatorSelected: undefined,
                            valueInputType: "SELECT",
                            availableValues: [
                                {key: "1", text: $filter("translate")("workflowCreator.YES")},
                                {key: "0", text: $filter("translate")("workflowCreator.NO")}
                            ],
                            valueSelect: undefined,
                            valueInput: undefined
                        };
                        filter.filterProperties.operatorSelected = filter.filterProperties.availableOperators[0];
                        filter.filterProperties.valueSelect = filter.filterProperties.availableValues[0];
                        break;
                    case "oldestInvoice":
                    case "lastTimeContacted":
                    case "amountMails":
                    case "amountCalls":
                    case "amountDebts":
                        filter.filterProperties = {
                            availableOperators: ["=", "!=", "<", ">", "<=", ">="],
                            operatorSelected: undefined,
                            valueInputType: "INPUT",
                            availableValues: undefined,
                            valueSelect: undefined,
                            valueInput: undefined
                        };
                        filter.filterProperties.operatorSelected = filter.filterProperties.availableOperators[0];
                        break;
                    case "preferredContactMethod":
                        filter.filterProperties = {
                            availableOperators: ["="],
                            operatorSelected: undefined,
                            valueInputType: "SELECT",
                            availableValues: [
                                {key: "PHONE", text: "Telefon"},
                                {key: "MAIL", text: "Post"},
                                {key: "EMAIL", text: "E-Mail"},
                                {key: "NONE", text: "Egal"}
                            ],
                            valueSelect: undefined,
                            valueInput: undefined
                        };
                        filter.filterProperties.operatorSelected = filter.filterProperties.availableOperators[0];
                        filter.filterProperties.valueSelect = filter.filterProperties.availableValues[0];
                        break;
                    case "paymentTermAppointmentsOverdueInDays":
                    case "amountPendingCallJobs":
                    case "amountDaysSinceLastSuccessfullCall":
                        filter.filterProperties = {
                            availableOperators: ["=", ">", "<"],
                            operatorSelected: undefined,
                            valueInputType: "INPUT",
                            availableValues: undefined,
                            valueSelect: undefined,
                            valueInput: undefined
                        };
                        filter.filterProperties.operatorSelected = filter.filterProperties.availableOperators[0];
                        break;
                    case "individualField1":
                    case "individualField2":
                    case "individualField3":
                    case "individualField4":
                    case "individualField5":
                    case "individualField6":
                    case "individualField7":
                    case "individualField8":
                    case "individualField9":
                    case "individualField10":
                        filter.filterProperties = {
                            availableOperators: ["=", "enthält"],
                            operatorSelected: undefined,
                            valueInputType: "INPUT-text",
                            availableValues: undefined,
                            valueSelect: undefined,
                            valueInput: undefined
                        };
                        filter.filterProperties.operatorSelected = filter.filterProperties.availableOperators[0];
                        break;
                }
            }

            function removeFilterRow(index) {
                $scope.createWorkflowFilter.createWorkflowFilterList.splice(index, 1)
            }

            function onCreateWorkflowButtonClick() {
                if ($scope.createWorkflowForm.$invalid) {
                    return;
                }

                if ($scope.createWorkflowFilter.createWorkflowFilterList.length === 0) {
                    Notification.error($filter("translate")("workflowCreator.NO_FILTER_SET"));
                    return;
                }

                if ($window.confirm($filter("translate")("workflowCreator.CONFIRM_CREATE_WORKFLOW"))) {
                    const workflowHeaderParams = createWorkflowHeaderParams($scope.createWorkflowHeader);

                    createWorkflow(
                        $rootScope.authData.sessionId,
                        workflowHeaderParams,
                        function (requestDetails) {
                            if (requestDetails.successful) {
                                const filterList = prepareWorkflowFilterListForRequest($scope.createWorkflowFilter.createWorkflowFilterList);
                                sendAllCreateWorkflowFilterRequests($rootScope.authData.sessionId, requestDetails.response.data, filterList, function (workflowFilterRequestDetails) {
                                    if (workflowFilterRequestDetails.allSuccessful) {
                                        Notification.success($filter("translate")("workflowCreator.CREATED_SUCCESSFULLY"));
                                        let workflowNextExecutionTime = generateExecutedDate(workflowHeaderParams.intervalAmount, workflowHeaderParams.intervalDay, workflowHeaderParams.intervalHour);
                                        workflowNextExecutionTime = moment(workflowNextExecutionTime, "YYYY-MM-DD HH:mm:ss");

                                        if ($scope.settings.companyLogo.file !== null) {
                                            Notification.warning({
                                                delay: 9999999,
                                                message: "Der Workflow '" + workflowHeaderParams.name + "' wird am " + workflowNextExecutionTime.format("DD.MM.YYYY") + " um " + workflowNextExecutionTime.format("HH:mm:ss") + " ausgeführt",
                                                closeOnClick: true
                                            });
                                        }
                                        $route.reload();
                                    } else {
                                        sharedMemory.error.setError(
                                            workflowFilterRequestDetails,
                                            "COULD_NOT_LOAD_DATA",
                                            "Fehler beim erstellen der workflow Filter",
                                            $location.path(),
                                            "workflowCreator"
                                        );
                                        $location.path("/error-page");
                                    }
                                })
                            } else {
                                sharedMemory.error.setError(
                                    requestDetails,
                                    "COULD_NOT_LOAD_DATA",
                                    "Fehler beim erstellen des Workflows",
                                    $location.path(),
                                    "workflowCreator"
                                );
                                $location.path("/error-page");
                            }
                        }
                    )
                }
            }

            function createWorkflow(sessionId, body, callback) {
                const requestDetails = {
                    successful: true,
                    response: undefined
                };

                requestManager.routes.workflow.createWorkflow(sessionId, body).then(
                    function (response) {
                        requestDetails.response = response;
                        callback(requestDetails);
                    },
                    function (response) {
                        requestDetails.response = response;
                        requestDetails.successful = false;
                        callback(requestDetails);
                    }
                )
            }

            function createWorkflowFilter(sessionId, workflowId, body, callback) {
                const requestDetails = {
                    successful: true,
                    response: undefined
                };

                requestManager.routes.workflow.createWorkflowFilterOfWorkflow(sessionId, workflowId, body).then(
                    function (response) {
                        requestDetails.response = response;
                        callback(requestDetails);
                    },
                    function (response) {
                        requestDetails.response = response;
                        requestDetails.successful = false;
                        callback(requestDetails);
                    }
                )
            }

            function prepareWorkflowFilterListForRequest(filterList) {
                const parsedList = [];
                filterList.forEach(function (filter) {
                    const processedFilterObject = {};

                    processedFilterObject.keyName = filter.filterType.key;
                    processedFilterObject.value1 = filter.filterProperties.operatorSelected;
                    if (filter.filterProperties.valueInputType === "SELECT") {
                        processedFilterObject.value2 = filter.filterProperties.valueSelect.key;
                    } else if (filter.filterProperties.valueInputType === "INPUT") {
                        processedFilterObject.value2 = filter.filterProperties.valueInput;
                    } else if (filter.filterProperties.valueInputType === "INPUT-text") {
                        processedFilterObject.value2 = filter.filterProperties.valueInput;
                    }
                    parsedList.push(processedFilterObject);
                });

                return parsedList;
            }

            function sendAllCreateWorkflowFilterRequests(sessionId, workflowId, filterList, callback) {
                const requestDetails = {
                    numRequestsPlanned: filterList.length,
                    numErrors: 0,
                    numSuccess: 0,
                    numResponses: 0,
                    responses: [],
                    successfulResponses: [],
                    errorResponses: [],
                    allSuccessful: true
                };

                filterList.forEach(function (filter) {
                    if (filter.value1 && filter.value1 === 'enthält') {
                        filter.value1 = 'contains';
                    }
                    createWorkflowFilter(sessionId, workflowId, filter, function (createWorkflowFilterRequestDetails) {
                        if (createWorkflowFilterRequestDetails.successful) {
                            requestDetails.numSuccess++;
                            requestDetails.numResponses++;
                            requestDetails.responses.push(createWorkflowFilterRequestDetails.response);
                            requestDetails.successfulResponses.push(createWorkflowFilterRequestDetails.response);
                            if (requestDetails.numResponses === requestDetails.numRequestsPlanned) {
                                callback(requestDetails);
                            }
                        } else {
                            requestDetails.numErrors++;
                            requestDetails.numResponses++;
                            requestDetails.responses.push(createWorkflowFilterRequestDetails.response);
                            requestDetails.errorResponses.push(createWorkflowFilterRequestDetails.response);
                            requestDetails.allSuccessful = false;
                            if (requestDetails.numResponses === requestDetails.numRequestsPlanned) {
                                callback(requestDetails);
                            }
                        }
                    })
                });
            }

            function createWorkflowHeaderParams(workflowHeader) {
                let intervalDay;

                if (workflowHeader.workflowIntervalDay === undefined) {
                    intervalDay = "IGNORE_INTERVAL_DAY";

                } else {
                    intervalDay = workflowHeader.workflowIntervalDay.key;
                }

                const body = {
                    name: workflowHeader.workflowName,
                    action: workflowHeader.workflowAction.key,
                    intervalAmount: workflowHeader.workflowInterval.key,
                    intervalDay: intervalDay,
                    intervalHour: workflowHeader.workflowIntervalHour.key,
                    lastExecuted: generateExecutedDate(workflowHeader.workflowInterval.key, intervalDay, workflowHeader.workflowIntervalHour.key, true)
                };


                // TODO: ADD NEW ACTIONS
                switch (workflowHeader.workflowAction.key) {
                    case "TO_PAYMENT_TERM_MAIL":
                    case "TO_MAIL":
                        body.value1 = workflowHeader.selectedTemplate.id;
                        body.noPermissionRequired = workflowHeader.noPermissionNeeded;
                        break;
                    case "TO_PHONE_LIST":
                    case "TO_LAWYER_LIST":
                        body.value1 = workflowHeader.taskDescription;
                        body.value2 = workflowHeader.taskTitle;
                        break;
                    case "TO_PHYSICAL_MAIL":
                        body.value1 = workflowHeader.sendPhysicalMailEnrolled;
                        body.noPermissionRequired = workflowHeader.noPermissionNeeded;
                        break;
                    case "TO_EMAIL_OR_PHYSICAL_MAIL":
                        body.value1 = workflowHeader.sendPhysicalMailEnrolled;
                        body.value2 = workflowHeader.selectedTemplate.id;
                        body.noPermissionRequired = workflowHeader.noPermissionNeeded;
                    case "TO_EMAIL_AND_PHYSICAL_MAIL":
                        body.value1 = workflowHeader.sendPhysicalMailEnrolled;
                        body.value2 = workflowHeader.selectedTemplate.id;
                        body.noPermissionRequired = workflowHeader.noPermissionNeeded;
                }

                //when interval is one day: ignore execution day -> because every day is execution day
                body.intervalDay = body.intervalAmount === "P1D" ? "IGNORE_INTERVAL_DAY" : body.intervalDay;

                //when the company logo doesn't exist, the workflow will be created as disabled
                if ($scope.settings.companyLogo.file === null) {
                    body.disabled = "1";
                } else {
                    body.disabled = "0";
                }

                return body;
            }

            function openWorkflowCreateInfoModal() {
                jQuery("#workflowCreatorInfoModal").modal("show");
            }

            function openWorkflowFilterInfoModal() {
                jQuery("#workflowFilterInfoModal").modal("show");
            }

            function generateExecutedDate(interval, dayOfWeek, hour, subtractInterval = false) {
                let date = moment();

                switch (interval) {
                    case "P1D": {
                        if (moment().hour() > hour) {
                            date.add(1, "day");
                        }
                        date.startOf("day").add(hour, "hours");
                        return date.format("YYYY-MM-DD HH:mm:ss");
                    }
                    case "P1W": {
                        if (subtractInterval) {
                            date.subtract(1, "week")
                        }
                        date.startOf("week").add(hour, "hours").add(getNumberOfDaysToCertainDay(dayOfWeek), "days");
                        return date.format("YYYY-MM-DD HH:mm:ss");
                    }
                    case "P2W": {
                        if (subtractInterval) {
                            date.subtract(2, "weeks")
                        }
                        date.startOf("week").add(hour, "hours").add(getNumberOfDaysToCertainDay(dayOfWeek), "days");
                        return date.format("YYYY-MM-DD HH:mm:ss");
                    }
                    case "P1M": {
                        if (subtractInterval) {
                            date.subtract(1, "month")
                        }
                        date.startOf("week").add(hour, "hours").add(getNumberOfDaysToCertainDay(dayOfWeek), "days");
                        return date.format("YYYY-MM-DD HH:mm:ss");
                    }
                }
            }

            function getNumberOfDaysToCertainDay(day) {
                switch (day) {
                    case "MO":
                        return 1;
                    case "DI":
                        return 2;
                    case "MI":
                        return 3;
                    case "DO":
                        return 4;
                    case "FR":
                        return 5;
                    case "SA":
                        return 6;
                    case "SO":
                        return 7;
                }
            }
        }
    ]
}); 

angular.module('workflowList', []);
angular.module('workflowList').component('workflowList', {
    templateUrl: 'app/components/workflowList/workflowList.template.html',
    bindings: {
        workflows: '<',
        settings: '<',
    },
    controller: [
        "$scope",
        "$rootScope",
        "requestManager",
        "Notification",
        "$filter",
        "$route",
        "$window",
        "$location",
        "sharedMemory",
        function ($scope, $rootScope, requestManager, Notification, $filter, $route, $window, $location, sharedMemory) {
            (function init(){
                initVariables();
                initScope();
            })();

            this.$onChanges = function set(item){
                if(item.workflows){
                    $scope.workflows.content = item.workflows.currentValue;
                    $scope.workflows.currentlyLoading = false;
                }
                if(!$scope.workflows.currentlyLoading){
                    $scope.view.allDependenciesLoaded = true;
                }
                if (item.settings && item.settings.currentValue) {
                    $scope.settings.content = item.settings.currentValue;
                    $scope.settings.currentlyLoading = false;
                    $scope.settings.companyLogo = $scope.settings.content.find(setting => {
                        return setting.settingCode === "companyLogo";
                    });
                    if ($scope.settings.companyLogo.file === null) {
                        Notification.error($filter("translate")("workflowList.NO_LOGO"));
                    }
                }
                if ($scope.settings.content !== undefined) {
                    $scope.settings.companyLogo = $scope.settings.content.find(setting => {
                        return setting.settingCode === "companyLogo";
                    });
                }
            };

            function initVariables(){
                $scope.workflows = {
                    content: undefined,
                    currentlyLoading: true
                };
                $scope.workflowDetailsModal = {
                    workflow: undefined,
                    currentlyLoading: true
                };
                $scope.view = {
                    allDependenciesLoaded: false
                };
                $scope.settings = {
                    content: undefined,
                    currentlyLoading: true
                };
            }

            function initScope(){
                $scope.openWorkflowDetailsModal = openWorkflowDetailsModal;
                $scope.deleteWorkflow = deleteWorkflow;
                $scope.forwardToWorkflow = forwardToWorkflow;
                $scope.getColorCodeForEntry = getColorCodeForEntry;
                $scope.disableOrEnableWorkFlow = disableOrEnableWorkFlow;
                $scope.getTooltipMessage = getTooltipMessage;
                $scope.propagationStop = propagationStop;
                $scope.executeWorkflowNow = executeWorkflowNow;
            }

            function propagationStop(e){
                e.stopPropagation();
            }

            function executeWorkflowNow(workflowId){
                let confirmValue = confirm($filter("translate")("workflowList.WORKFLOW_EXECUTE_NOW_CONFIRMATION_TEXT"));

                if(confirmValue){
                    requestManager.routes.workflow.editWorkflow($rootScope.authData.sessionId, {executeNow: 1, id: workflowId}).then(
                        function (response){
                            Notification.success($filter("translate")("workflowList.WORKFLOW_EXECUTE_NOW_SUCCESS_MSG"));
                            $route.reload();
                        },
                        function (response){
                            Notification.error($filter("translate")("workflowList.WORKFLOW_EXECUTE_NOW_ERROR_MSG"));
                        }
                    )
                }
            }

            function getColorCodeForEntry(disabledAttribue){
                if(disabledAttribue == 1){
                    return '#BFC9CA';
                }
            }

            function getTooltipMessage(disabledAttribute){
                if(disabledAttribute == 1){
                    return 'Um einen Workflow jetzt auszuführen, muss dieser zunächst aktiviert werden.'
                }
            }

            function disableOrEnableWorkFlow(id, changeValue){
                let params = {
                  id: id,
                  disabled: changeValue
                };

                requestManager.routes.workflow.disableOrEnableWorkflow($rootScope.authData.sessionId, params).then(
                    function(response){
                        Notification.success("Workflow erfolgreich bearbeitet!");
                        // setTimeout(() => {
                        //     $route.reload();
                        // }, 2000);
                        $route.reload();
                    },
                    function(response){
                        Notification.error("Fehler beim Bearbeiten des Workflows! Bitte wenden Sie sich an den Support!");
                    }
                )
            }

            function openWorkflowDetailsModal(workflow){
                $scope.workflowDetailsModal.currentlyLoading = true;
                $scope.workflowDetailsModal.workflow = workflow;

                getWorkflowFilter($rootScope.authData.sessionId, workflow.id, function(requestDetails){
                    $scope.workflowDetailsModal.currentlyLoading = false;

                    if(requestDetails.successful){
                        requestDetails.response.data.forEach(val => {
                            if (val.value1 && val.value1 === 'contains') {
                                val.value1 = 'enthält';
                            }
                        });
                        $scope.workflowDetailsModal.workflowFilter = requestDetails.response.data;
                        jQuery("#workflowDetailsModal").modal("show");
                    }
                    else{
                        sharedMemory.error.setError(
                            requestDetails,
                            "COULD_NOT_LOAD_DATA",
                            "Fehler beim laden der Workflowfilter",
                            $location.path(),
                            "workflowList"
                        );
                        $location.path("/error-page");
                    }
                });
            }

            function getWorkflowFilter(sessionId, workflowId, callback){
                var requestDetails= {
                    response: undefined,
                    successful: true
                };

                requestManager.routes.workflow.getWorkflowFilter(sessionId, workflowId).then(
                    function(response){
                        requestDetails.response = response;
                        callback(requestDetails);
                    },
                    function(response){
                        requestDetails.response = response;
                        requestDetails.successful = false;
                        callback(requestDetails);
                    }
                )
            }

            function deleteWorkflow(workflowId){
                if($window.confirm($filter("translate")("workflowList.CONFIRM_DELETE"))){
                    sendDeleteWorkflowRequest($rootScope.authData.sessionId, workflowId, function(requestDetails){
                        if(requestDetails.successful){
                            jQuery("#workflowDetailsModal").one("hidden.bs.modal", function(){
                                Notification.success($filter("translate")("workflowList.SUCCESSFULLY_DELETED"));
                                $route.reload();
                            }).modal("hide");
                        }
                        else{
                            sharedMemory.error.setError(
                                requestDetails,
                                "COULD_NOT_LOAD_DATA",
                                "Fehler beim löschen eines Workflows (inklusive Dependencies)",
                                $location.path(),
                                "workflowList"
                            );
                            $location.path("/error-page");
                        }
                    });
                }
            }

            function sendDeleteWorkflowRequest(sessionId, workflowId, callback){
                var requestDetails = {
                    successful: true,
                    response: undefined
                };

                requestManager.routes.workflow.deleteWorkflow(sessionId, workflowId).then(
                    function(response){
                        requestDetails.response = response;
                        callback(requestDetails);
                    },
                    function(response){
                        requestDetails.response = response;
                        requestDetails.successful = false;
                        callback(requestDetails);
                    }
                )
            }

            function forwardToWorkflow(id){
                jQuery("#workflowDetailsModal").one("hidden.bs.modal", function(){
                    $location.path("/editWorkflow/" + id);
                }).modal("hide");
            }
        }
    ]
}); 

angular.module('customerTaskList', []);
angular.module('customerTaskList').component('customerTaskList', {
    templateUrl: 'app/components/customerTaskList/customerTaskList.template.html',
    controller: [
        "$scope",
        "requestManager",
        "$routeParams",
        "Notification",
        "$rootScope",
        "$filter",
        "$location",
        "util",
        "sharedMemory",
        "$timeout",
        function ($scope, requestManager, $routeParams, Notification, $rootScope, $filter, $location, util, sharedMemory, $timeout) {
            init();

            function init() {
                initVariables();
                initScope();
                getTasksOfCustomer($rootScope.authData.sessionId, $routeParams.id, function (requestDetails) {
                    if (requestDetails.successful) {
                        $scope.tasks.content = requestDetails.response.data;
                        if ($scope.tasks.content && $scope.tasks.content.length > 0) {
                            $scope.tasks.content = $scope.tasks.content.filter(item => item !== null);
                        }
                        $scope.tasks.contentModified = angular.copy($scope.tasks.content).filter(function (element) {
                            return element.done === "0";
                        });
                        $scope.tasks.currentlyLoading = false;

                        if ($scope.tasks.contentModified.length > 0) {
                            $timeout(toggleColorAnimation, 1);
                        }
                    } else {
                        if (!requestDetails.canceledRequest) {
                            sharedMemory.error.setError(
                                requestDetails,
                                "COULD_NOT_LOAD_DATA",
                                "Fehler beim laden der User-Task-Liste (Beim initiieren der Komponente)",
                                $location.path(),
                                "customerTaskList"
                            );
                            $location.path("/error-page");
                        }
                    }
                });
            }

            function initVariables() {
                $scope.tasks = {
                    content: undefined,
                    currentlyLoading: true
                };
                $scope.taskDetailsModal = {
                    task: undefined
                }
            }

            function initScope() {
                $scope.openTaskDetailsModal = openTaskDetailsModal;
                $scope.setTaskDone = setTaskDone;
                $scope.toggleColorAnimation = toggleColorAnimation;
            }

            function getTasksOfCustomer(sessionId, customerId, callback) {
                var requestDetails = {
                    successful: true,
                    response: undefined,
                    canceledRequest: false
                };

                requestManager.routes.customer.getTasksOfCustomer(sessionId, customerId).then(
                    function (response) {
                        requestDetails.response = response;
                        callback(requestDetails);
                    },
                    function (response) {
                        requestDetails.response = response;
                        requestDetails.successful = false;
                        requestDetails.canceledRequest = response.status == -1;
                        callback(requestDetails);
                    }
                )
            }

            function openTaskDetailsModal(task) {
                $scope.taskDetailsModal.task = task;
                jQuery("#taskDetailsModal").modal("show");
            }

            function sendEditTaskRequest(sessionId, taskId, params, callback) {
                var requestDetails = {
                    successful: true,
                    response: undefined
                };

                requestManager.routes.task.editTaskWithId(sessionId, taskId, params).then(
                    function (response) {
                        requestDetails.response = response;
                        callback(requestDetails);
                    },
                    function (response) {
                        requestDetails.response = response;
                        requestDetails.successful = false;
                        callback(requestDetails);
                    }
                )
            }

            function setTaskDone(task) {
                var params = {
                    done: "1"
                };

                sendEditTaskRequest($rootScope.authData.sessionId, task.id, params, function (requestDetails) {
                    if (requestDetails.successful) {
                        Notification.success($filter("translate")("customerTaskList.MARKED_AS_DONE"));
                        $scope.tasks.currentlyLoading = true;

                        getTasksOfCustomer($rootScope.authData.sessionId, $routeParams.id, function (getTasksOfCustomerRequestDetails) {
                            if (getTasksOfCustomerRequestDetails.successful) {
                                jQuery("#taskDetailsModal").one("hidden.bs.modal", function () {
                                    $scope.tasks.content = getTasksOfCustomerRequestDetails.response.data;
                                    $scope.tasks.contentModified = removeDone(angular.copy($scope.tasks.content));
                                    $scope.tasks.currentlyLoading = false;
                                }).modal("hide");
                            } else {
                                jQuery("#taskDetailsModal").one("hidden.bs.modal", function () {
                                    sharedMemory.error.setError(
                                        getTasksOfCustomerRequestDetails,
                                        "COULD_NOT_LOAD_DATA",
                                        "Fehler beim laden der User-Task-Liste (Beim reload der Tabelle nach ändern des Task status von 'erledigt' zu 'unerledigt' oder umgekehrt",
                                        $location.path(),
                                        "customerTaskList"
                                    );
                                    $location.path("/error-page");
                                    $scope.$apply();
                                }).modal("hide");
                            }
                        });
                    } else {
                        if (requestDetails && requestDetails.response && requestDetails.response.status === 403) {
                            Notification.error($filter("translate")("userSettings.ERROR_403_USER_NEED_APPROPRIATE_PERMISSION"));
                        } else {
                            jQuery("#taskDetailsModal").one("hidden.bs.modal", function () {
                                sharedMemory.error.setError(
                                    requestDetails,
                                    "COULD_NOT_LOAD_DATA",
                                    "Fehler beim update eines Tasks (beim setzen von erledigt auf unerledigt oder umgekehrt)",
                                    $location.path(),
                                    "customerTaskList"
                                );
                                $location.path("/error-page");
                                $scope.$apply();
                            }).modal("hide");
                        }
                    }
                })
            }

            function toggleColorAnimation() {
                jQuery("#customer-task-box").css("background-color", "white");
            }
        }
    ]
}); 

angular.module('customerAdvisor', []);

angular.module('customerAdvisor').component('customerAdvisor', {
    templateUrl: 'app/components/customerAdvisor/customerAdvisor.template.html',
    bindings: {
        customer: "<"
    },
    controller: [
        "$scope",
        "requestManager",
        "$routeParams",
        "Notification",
        "$rootScope",
        "$filter",
        "$location",
        "util",
        "sharedMemory",
        "$window",
        "$route",
        "$timeout",
        function ($scope, requestManager, $routeParams, Notification, $rootScope, $filter, $location, util, sharedMemory, $window, $route, $timeout) {
            this.$onInit = function () {
                initVariables();
                initScope();

                fetchRecords();
            }

            this.$onChanges = function (item) {
                if (item.customer && item.customer.currentValue) {
                    $scope.customer.content = angular.copy(item.customer.currentValue);
                    $scope.customer.currentlyLoading = false;
                }
            };

            function initVariables() {
                $scope.customer = {
                    content: undefined,
                    currentlyLoading: true
                };
                $scope.record = null;
                $scope.recordLoading = true;
                $scope.recordDetailsModal = {
                    record: undefined
                };
            }

            function initScope() {
                $scope.openModal = openModal;
                $scope.toggleColorAnimation = toggleColorAnimation;
                $scope.sendEmailToCustomerAdvisor = sendEmailToCustomerAdvisor;
            }

            function openModal() {
                jQuery("#endCustomerAdvisorModal").modal("show");
            }

            function sendEmailToCustomerAdvisor() {
                $scope.informCustomerAdvisorModal.currentlyLoading = true;
                if ($window.confirm($filter("translate")("userDetailsActions.CLOSE_CUSTOMER_ADVISOR_CASE"))) {
                    endCustomerAdvisor(
                        $rootScope.authData.sessionId,
                        $routeParams["id"],
                        {
                            userDidEntry: $scope.record.userDidEntry,
                            comment: $scope.informCustomerAdvisorModal.email,
                        },
                        function (requestDetails) {
                            if (requestDetails.successful) {
                                jQuery("#endCustomerAdvisorModal").on('hidden.bs.modal', function () {
                                    $route.reload();
                                }).modal("hide");
                            } else {
                                sharedMemory.error.setError(
                                    requestDetails,
                                    "COULD_NOT_LOAD_DATA",
                                    "Fehler beim editieren des Customers ",
                                    $location.path(),
                                    "userDetailsAction"
                                );

                                $location.path("/error-page");
                            }
                        });
                }
            }

            function endCustomerAdvisor(sessionId, customerId, params, callback) {
                var requestDetails = {
                    successful: true,
                    response: undefined
                };

                requestManager.routes.customer.endCustomerAdvisor(sessionId, customerId, params).then(
                    function (response) {
                        requestDetails.response = response;
                        callback(requestDetails);
                    },
                    function (response) {
                        requestDetails.response = response;
                        requestDetails.successful = false;
                        callback(requestDetails);
                    }
                )
            }

            function toggleColorAnimation() {
                jQuery("#customer-advisor-box").css("background-color", "white");
            }

            function fetchRecords() {
                getRecordsOfCustomer($rootScope.authData.sessionId, $routeParams['id'], function (recordRequestDetails) {
                    if (recordRequestDetails.successful) {
                        if (recordRequestDetails.response.data.items && recordRequestDetails.response.data.items.length > 0) {
                            $scope.record = recordRequestDetails.response.data.items[0];
                            // $timeout(toggleColorAnimation, 1);
                        } else {
                            $scope.record = null;
                        }
                        $scope.recordLoading = false;
                    } else {
                        sharedMemory.error.setError(
                            recordRequestDetails,
                            "COULD_NOT_LOAD_DATA",
                            "Fehler beim laden der generischen Records (beim initiieren der Komponente)",
                            $location.path(),
                            "userRecords"
                        );
                        $location.path("/error-page");
                    }
                });
            }

            function getRecordsOfCustomer(sessionId, customerId, callback) {
                const requestDetails = {
                    successful: true,
                    response: undefined
                };

                const params = {
                    pageSize: 1,
                    order: 'entryCreated',
                    direction: 'DESC',
                    filter: `recordType eq record_customer_advisor_v2 and accountNumber eq ${customerId}`,
                }

                requestManager.routes.record.getCustomerAdvisorRecord(sessionId, params).then(
                    function (response) {
                        requestDetails.response = response;
                        callback(requestDetails);
                    },
                    function (response) {
                        requestDetails.response = response;
                        requestDetails.successful = false;
                        callback(requestDetails);
                    }
                )
            }
        }
    ]
}); 

angular.module('moduleSettings', []);
angular.module('moduleSettings').component('moduleSettings', { 
	templateUrl: 'app/components/moduleSettings/moduleSettings.template.html', 
	controller: [
		"$scope",
		"$rootScope",
		"$location",
		"$filter",
		"sharedMemory",
		"requestManager",
		function ($scope, $rootScope, $location, $filter, sharedMemory, requestManager) {
			this.$onInit = function(){
				initVariables();
				initScope();
			};

			function initVariables(){

			}

			function initScope(){
				$scope.openModal = openModal;
				$scope.activateCreditReformModule = activateCreditReformModule;
			}

			function openModal(modalId){
				jQuery("#" + modalId).appendTo("body").modal("show");
			}

			function activateCreditReformModule(){
				//todo validate gts-checkbox and stuff

                sendRegisterCreditReformModuleRequest($rootScope.authData.sessionId, function(requestDetails){
					if(requestDetails.successful){
						Notification.success($filter("translate")("moduleSettings.REGISTRATION_SUCCESSFUL"))
					}
					else{
						jQuery("#creditReformModal").one("hidden.bs.modal", function(){
                            sharedMemory.error.setError(
                                requestDetails,
                                "COULD_NOT_LOAD_DATA",
                                "Fehler beim registrieren für das Kreditreform Modul",
                                $location.path(),
                                "moduleSettings"
                            );
                            $location.path("/error-page");
						}).modal("hide");
					}
				});
			}

			function sendRegisterCreditReformModuleRequest(sessionId, callback){
				var requestDetails = {
					successful: true,
					response: undefined
				};

                requestManager.routes.creditReformRegistration.register(sessionId).then(
                	function(response){
						requestDetails.response = response;
						callback(requestDetails);
					},
					function(response){
                        requestDetails.response = response;
                        requestDetails.successful = false;
                        callback(requestDetails);
					}
				)
			}
		} 
	] 
}); 

angular.module('userPaymentAgreements', []);
angular.module('userPaymentAgreements').component('userPaymentAgreements', {
	templateUrl: 'app/components/userPaymentAgreements/userPaymentAgreements.template.html',
	controller: [
		"requestManager",
		"$location",
		"sharedMemory",
		"$rootScope",
		"$scope",
		"Notification",
		"$routeParams",
		"$filter",
		"$route",
		"$window",
		function(requestManager, $location, sharedMemory, $rootScope, $scope, Notification, $routeParams, $filter, $route, $window) {
			this.$onInit = function() {
				$scope.europeanNumberFormat = 'en-US';
				if ($rootScope.europeanNumberFormat !== undefined) {
					if ($rootScope.europeanNumberFormat === '1' || $rootScope.europeanNumberFormat === 1) {
						$scope.europeanNumberFormat = 'de-AT';
					}
					else {
						$scope.europeanNumberFormat = 'en-US';
					}
				}
				initVariables();
				initScope();
				sendGetAllPaymentAgreementsOfCustomerRequest($rootScope.authData.sessionId, $routeParams.id, function(requestDetails) {
					if (requestDetails.successful) {
						$scope.paymentAgreements.content = requestDetails.response.data;
						$scope.paymentAgreements.content.forEach(value => {
							value.amountInterval = +value.amountInterval;
							value.amountTotal = +value.amountTotal;
						});
						$scope.paymentAgreements.currentlyLoading = false;
					} 
					else {
						sharedMemory.error.setError(
							deletePaymentAppointmentRequestDetails,
							"COULD_NOT_LOAD_DATA",
							"Fehler beim lesen der paymentTerms eines customers",
							$location.path(),
							"userPaymentAgreements"
						);
						$location.path("/error-page");
					}
				});
				sendGetAllAccountsReceivableSettingsRequest($rootScope.authData.sessionId, function (requestDetails) {
					if (requestDetails.successful) {
						$scope.accountsReceivableSettings.content = requestDetails.response.data;
						$scope.accountsReceivableSettings.currentlyLoading = false;
					} else {
						sharedMemory.error.setError(
							requestDetails,
							"COULD_NOT_LOAD_DATA",
							"Fehler beim lesen des AccountsReceivableSettings",
							$location.path(),
							"userPaymentAgreements"
						);
						$location.path("/error-page");
					}
				});
			};

			function initVariables() {
				$scope.paymentAgreements = {
					content: undefined,
					currentlyLoading: true
				}
				$scope.paymentAgreementModal = {
					paymentAgreement: undefined,
					currentlyLoading: true,
					paymentAgreementAppointments: undefined,
					associatedAccountsReceivable: undefined
				}
				$scope.accountsReceivableSettings = {
					content: undefined,
					currentlyLoading: true
				};
			}

			function initScope() {
				$scope.openPaymentAgreementDetails = openPaymentAgreementDetails;
				$scope.onDeletePaymentAgreementClick = onDeletePaymentAgreementClick;
				$scope.onPrintPaymentAgreementClick = onPrintPaymentAgreementClick;
			}

			function sendGetAllPaymentAgreementsOfCustomerRequest(sessionId, customerId, callback) {
				var requestDetails = {
					successful: true,
					response: undefined
				};

				requestManager.routes.customer.getPaymentTermsOfCustomer(sessionId, customerId).then(
					function(response) {
						requestDetails.response = response;
						callback(requestDetails);
					},
					function(response) {
						requestDetails.response = response;
						requestDetails.successful = false;
						callback(requestDetails);
					}
				)
			}

			function sendGetAllAccountsReceivableSettingsRequest(sessionId, callback) {
				var requestDetails = {
					successful: true,
					response: undefined
				};

				requestManager.routes.accountsReceivableSettings.getAccountsReceivableSettings(sessionId).then(
					function (response) {
						requestDetails.response = response;
						callback(requestDetails);
					},
					function (response) {
						requestDetails.response = response;
						requestDetails.successful = false;
						callback(requestDetails);
					}
				)
			}

			function openPaymentAgreementDetails(paymentAgreement) {
				$scope.paymentAgreementModal.currentlyLoading = true;
				$scope.paymentAgreementModal.paymentAgreement = paymentAgreement;

				sendGetPaymentTermAppintmentsOfPaymentTermRequest($rootScope.authData.sessionId, $scope.paymentAgreementModal.paymentAgreement.id, function(requestDetails) {
					if (requestDetails.successful) {

						//sorting logic
						var sortedArray = requestDetails.response.data.sort(function(a, b){
                            var keyA = new Date(a.dateAppointment),
                                keyB = new Date(b.dateAppointment);
                            // Compare the 2 dates
                            if(keyA < keyB) return -1;
                            if(keyA > keyB) return 1;
                            return 0;
                        });

						$scope.paymentAgreementModal.paymentAgreementAppointments = sortedArray;
						$scope.paymentAgreementModal.paymentAgreementAppointments.forEach(value => {
							value.amountTotal = +value.amountTotal;
						});

						if (angular.isDefined($scope.paymentAgreementModal.paymentAgreementAppointments) && angular.isDefined($scope.paymentAgreementModal.associatedAccountsReceivable)) {
							$scope.paymentAgreementModal.currentlyLoading = false;
						}
					} else {
						sharedMemory.error.setError(
							deletePaymentAppointmentRequestDetails,
							"COULD_NOT_LOAD_DATA",
							"Fehler beim lesen der paymentTermAppointments eines paymentTerms",
							$location.path(),
							"userPaymentAgreements"
						);
						$location.path("/error-page");
					}
				});

				sendGetAccountsReceivableOfPaymentTerm($rootScope.authData.sessionId, $scope.paymentAgreementModal.paymentAgreement.id, function(requestDetails) {
					if (requestDetails.successful) {
						$scope.paymentAgreementModal.associatedAccountsReceivable = requestDetails.response.data;
						if (angular.isDefined($scope.paymentAgreementModal.paymentAgreementAppointments) && angular.isDefined($scope.paymentAgreementModal.associatedAccountsReceivable)) {
							$scope.paymentAgreementModal.currentlyLoading = false;
						}
					} else {
						sharedMemory.error.setError(
							deletePaymentAppointmentRequestDetails,
							"COULD_NOT_LOAD_DATA",
							"Fehler beim lesen der accountsReceivable, die einem paymentTerm zugeordnet sind",
							$location.path(),
							"userPaymentAgreements"
						);
						$location.path("/error-page");
					}
				});

				jQuery("#paymentAgreementDetailsModal").modal("show");
			}

			function sendGetAccountsReceivableOfPaymentTerm(sessionId, paymentTermId, callback) {
				var requestDetails = {
					successful: true,
					response: undefined
				};

				requestManager.routes.accountsReceivable.getAccountsReceivable($rootScope.authData.sessionId, [
					["isInPaymentTerm", "=", paymentTermId]
				]).then(
					function(response) {
						requestDetails.response = response;
						callback(requestDetails);
					},
					function(response) {
						requestDetails.response = response;
						requestDetails.successful = false;
						callback(requestDetails);
					}
				)
			}

			function sendGetPaymentTermAppintmentsOfPaymentTermRequest(sessionId, paymentAgreementId, callback) {
				var requestDetails = {
					successful: true,
					response: undefined
				}

				requestManager.routes.paymentTerm.getPaymentTermAppointmentsOfPaymentTerm(sessionId, paymentAgreementId).then(
					function(response) {
						requestDetails.response = response;
						callback(requestDetails);
					},
					function(response) {
						requestDetails.response = response;
						callback(requestDetails);
					}
				)
			}

			function sendDeletePaymentAppointmentRequest(sessionId, paymentTermId, paymentAppointmentIdList, callback) {
				var requestDetails = {
					allSuccessful: true,
					numRequestsPlanned: paymentAppointmentIdList.length,
					successfulResponses: [],
					errorResponses: [],
					responses: []
				};

				paymentAppointmentIdList.forEach(function(paymentAppointmentId) {
					requestManager.routes.paymentTerm.deletePaymentTermAppointmentOfPaymentTerm(sessionId, paymentTermId, paymentAppointmentId).then(
						function(response) {
							requestDetails.successfulResponses.push(response);
							requestDetails.responses.push(response);
							if (requestDetails.responses.length == requestDetails.numRequestsPlanned) {
								callback(requestDetails);
							}
						},
						function(response) {
							requestDetails.errorResponses.push(response);
							requestDetails.responses.push(response);
							requestDetails.successful = false;
							if (requestDetails.responses.length == requestDetails.numRequestsPlanned) {
								callback(requestDetails);
							}
						}
					)
				});
			}

			function sendDeletePaymentTermRequest(sessionId, customerId, paymentTermId, callback) {
				var requestDetails = {
					successful: true,
					response: undefined
				}

				requestManager.routes.customer.deletePaymentTermOfCustomer(sessionId, customerId, paymentTermId).then(
					function(response) {
						requestDetails.response = response;
						callback(requestDetails);
					},
					function(response) {
						requestDetails.response = response;
						callback(requestDetails);
					}
				)
			}

			function onPrintPaymentAgreementClick(){
                //check what browser the user is surfing on
                var ua = window.navigator.userAgent;
                var msie = ua.indexOf("MSIE ");
                var IEfound = false;
                var EdgeFound = /Edge/.test(window.navigator.userAgent);

                if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./))  // If Internet Explorer
                {
                    IEfound = true;
                }

                if(!IEfound && !EdgeFound){
                    var tabWindow = $window.open('about:blank', '_blank');
                    tabWindow.document.write('loading...');
                }

                var notificationReference = Notification.warning({
                    message: $filter("translate")("wrapper.LOADING_PDF"),
                    delay: null,
                    closeOnClick: false,
                });
                requestManager.routes.record.getPaymentAgreementOfCustomerV2($rootScope.authData.sessionId, $scope.paymentAgreementModal.paymentAgreement.id).then(
                    function(response){
                        var file = new Blob([response.data], {type: 'application/pdf'});

                        if(!IEfound && !EdgeFound){
                            var fileURL = URL.createObjectURL(file);
                            tabWindow.location.href = fileURL;
                        }
                        if(IEfound || EdgeFound){
                            let r =  "";
                            for(let i = 0; i < 4; i++){
                                if(i % 4 == 0)
                                    r += Math.random().toString(36).substring(8);
                                else{
                                    r += "-";
                                    r += Math.random().toString(36).substring(4);
                                }
                            }
                            $window.navigator.msSaveOrOpenBlob(file, r+".pdf");
                        }

                        //$window.open($sce.trustAsResourceUrl(fileURL), "_self");
                        notificationReference.then(function(notification){
                            notification.kill(false);
                        });

                    },
                    function(response){
                        Notification.error("Vorlage konnte nicht gefunden werden! Wenden Sie sich an den Support.");

                        notificationReference.then(function(notification){
                            notification.kill(false);
                        });
                    }
                )

			}

			function sendEditAccountsReceivableRequest(sessionId, accountsReceivableIdList, params, callback) {
				var requestDetails = {
					allSuccessful: true,
					numRequestsPlanned: accountsReceivableIdList.length,
					responses: [],
					successfulResponses: [],
					errorResponses: []
				}

				accountsReceivableIdList.forEach(function(accountsReceivableId) {
					requestManager.routes.accountsReceivable.editAccountsReceivable(sessionId, accountsReceivableId, params).then(
						function(response) {
							requestDetails.responses.push(response);
							requestDetails.successfulResponses.push(response);
							if (requestDetails.responses.length == requestDetails.numRequestsPlanned) {
								callback(requestDetails);
							}
						},
						function(response) {
							requestDetails.responses.push(response);
							requestDetails.errorResponses.push(response);
							requestDetails.allSuccessful = false;
							if (requestDetails.responses.length == requestDetails.numRequestsPlanned) {
								callback(requestDetails);
							}
						}
					)
				});

			}

			function sendEditAccountsReceivableSettingsRequest(sessionId, paymentTermId, params, callback) {
				var requestDetails = {
					allSuccessful: true,
					responses: [],
					successfulResponses: [],
					errorResponses: []
				}

				requestManager.routes.accountsReceivableSettings.editAccountsReceivableSettings(sessionId, paymentTermId, params).then(
					function(response) {
						requestDetails.successfulResponses.push(response);
						callback(requestDetails);
					},
					function(response) {
						requestDetails.responses.push(response);
						requestDetails.errorResponses.push(response);
						requestDetails.allSuccessful = false;
						callback(requestDetails);
					}
				)
			}

			function onDeletePaymentAgreementClick() {
				$scope.paymentAgreementModal.currentlyLoading = true;

				var sessionId = $rootScope.authData.sessionId;
				var paymentAppointmentIdList = Array.isArray($scope.paymentAgreementModal.paymentAgreementAppointments)
					? $scope.paymentAgreementModal.paymentAgreementAppointments.map(function(paymentAppointment) { return paymentAppointment.id }) : [];
				var accountsReceivableIdList = Array.isArray($scope.paymentAgreementModal.associatedAccountsReceivable)
					? $scope.paymentAgreementModal.associatedAccountsReceivable.map(function(accountsReceivable) { return accountsReceivable.id }) : [];
				let accountsReceivableSettings = $scope.accountsReceivableSettings.content.find(setting => setting.isInPaymentTerm === $scope.paymentAgreementModal.paymentAgreement.id);

				sendDeletePaymentAppointmentRequest(sessionId, $scope.paymentAgreementModal.paymentAgreement.id, paymentAppointmentIdList, function(deletePaymentAppointmentRequestDetails) {
					if (deletePaymentAppointmentRequestDetails.allSuccessful) {
						paymentAgreementModal.currentlyLoading
						sendDeletePaymentTermRequest(sessionId, $routeParams.id, $scope.paymentAgreementModal.paymentAgreement.id, function(deletePaymentTermRequestDetails) {
							if (deletePaymentTermRequestDetails.successful) {
								sendEditAccountsReceivableRequest(sessionId, accountsReceivableIdList, {isInPaymentTerm: null}, function(editAccountsReceivableRequestDetails) {
									if (editAccountsReceivableRequestDetails.allSuccessful) {
										sendEditAccountsReceivableSettingsRequest(sessionId, accountsReceivableSettings.id, {isInPaymentTerm: null}, function(editAccountsReceivableSettingsRequestDetails) {
											if (editAccountsReceivableSettingsRequestDetails.allSuccessful) {
												jQuery('#paymentAgreementDetailsModal').one("hidden.bs.modal", function(){
													Notification.success($filter("translate")("userPaymentAgreements.SUCCESSFULLY_DELETED"));
													$window.location.reload();
												}).modal("hide");
											} else {
												sharedMemory.error.setError(
													editAccountsReceivableSettingsRequestDetails,
													"COULD_NOT_LOAD_DATA",
													"Fehler beim ändern eines accountsReceivableSettings (beim setzen von isInPaymentTerm zu NULL)",
													$location.path(),
													"userPaymentAgreements"
												);
												$location.path('/error-page');
											}
										});

									} else {
										sharedMemory.error.setError(
											deletePaymentAppointmentRequestDetails,
											"COULD_NOT_LOAD_DATA",
											"Fehler beim ändern eines oder mehrerer accountsReceivable (beim setzen von isInPaymentTerm zu NULL)",
											$location.path(),
											"userPaymentAgreements"
										);
										$location.path("/error-page");
									}
								});
							} else {
								sharedMemory.error.setError(
									deletePaymentAppointmentRequestDetails,
									"COULD_NOT_LOAD_DATA",
									"Fehler beim löschen vom paymentTerm",
									$location.path(),
									"userPaymentAgreements"
								);
								$location.path("/error-page");
							}
						});
					} else {
						sharedMemory.error.setError(
							deletePaymentAppointmentRequestDetails,
							"COULD_NOT_LOAD_DATA",
							"Fehler beim löschen der paymentTermAppointments",
							$location.path(),
							"userPaymentAgreements"
						);
						$location.path("/error-page");
					}
				});
			}
		}
	]
});

angular.module('emailTemplateEditor', []);
angular.module('emailTemplateEditor').component('emailTemplateEditor', {
    templateUrl: 'app/components/emailTemplateEditor/emailTemplateEditor.template.html',
    bindings: {
        emailTemplates: "<"
    },
    controller: [
        "$scope",
        "requestManager",
        "Notification",
        "$rootScope",
        "$location",
        "sharedMemory",
        "$filter",
        "$route",
        "emailTemplateUtil",
        "$window",
        "$templateCache",
        "EMAIL_REPLACE_TAGS",
        function ($scope, requestManager, Notification, $rootScope, $location, sharedMemory, $filter, $route, emailTemplateUtil, $window, $templateCache, EMAIL_REPLACE_TAGS) {
            init();

            function init(){
                initVariables();
                initScope();
            }

            $('#deleteLanguageOrTemplateModal').on('hidden.bs.modal', function () {
                $scope.selectedLanguageForDeleteOptionsModal.languageWasSelectedForDeletion = false
            });

            this.$onChanges = function set(item){
                if(item.emailTemplates.currentValue){
                    $scope.emailTemplateList.contentUnchanged = angular.copy(item.emailTemplates.currentValue);
                    $scope.emailTemplateList.content = item.emailTemplates.currentValue === "null" ? [] : item.emailTemplates.currentValue;
                    $scope.emailTemplateList.emailTemplatesGroupedByTitle = emailTemplateUtil.getEmailTemplatesGroupedByTitle($scope.emailTemplateList.content);
                    $scope.emailTemplateList.emailTemplatesGroupedByTitle = setDefaultTemplateInTemplateGroup($scope.emailTemplateList.emailTemplatesGroupedByTitle);
                    $scope.emailTemplateList.currentlyLoading = false;
                }
                if(!$scope.emailTemplateList.currentlyLoading){
                    $scope.view.allDependenciesLoaded = true;
                }
            };

            function initVariables(){
                $scope.emailTemplateList = {
                    currentlyLoading: true,
                    content: undefined,
                    languagesAvailableForEmailTemplates: undefined
                };
                $scope.createTemplate = {
                    subject: undefined,
                    title: undefined,
                    text: "",
                    language: undefined
                };
                $scope.view = {
                    allDependenciesLoaded: true
                };
                $scope.addLanguageModal = {
                    selectedLanguageOption: undefined,
                    languageOptions: languageCodesV2,
                    template: undefined
                };
                $scope.emailReplaceTags = {
                    content: angular.copy(EMAIL_REPLACE_TAGS),
                    selected: undefined
                };

                $scope.addLanguageModal.languageOptions = $scope.addLanguageModal.languageOptions.filter((languageObject) => {
                    return languageObject.iso639v1;
                });

                $scope.availableLanguages = [];

                $scope.selectedLanguageForDeleteOptionsModal = {
                    content: undefined,
                    languageToBeDeleted: undefined,
                    languageWasSelectedForDeletion: false
                };
            }

            function initScope(){
                $scope.showCollapsedArea = showCollapsedArea;
                $scope.onCreateTemplateClick = onCreateTemplateClick;
                $scope.onDeleteTemplateClick = onDeleteTemplateClick;
                $scope.onLanguageChange = onLanguageChange;
                $scope.onAddLanguageClick = onAddLanguageClick;
                $scope.onSaveLanguageButtonClick = onSaveLanguageButtonClick;
                $scope.onSaveButtonClick = onSaveButtonClick;
                $scope.onRemoveLanguageClick = onRemoveLanguageClick;
                $scope.onRemoveTemplateClick = onRemoveTemplateClick;
                $scope.insertReplacementTag = insertReplacementTag;
                $scope.onRemoveLanguageOrTemplateClick = onRemoveLanguageOrTemplateClick;
                $scope.getObjectArrayLength = getObjectArrayLength;
                $scope.openInfoModal = openInfoModal;
            }

            function openInfoModal() {
                jQuery("#emailTemplatesInfoModal").appendTo("body").modal("show");
            }

            function showCollapsedArea(id){
                jQuery("#" + id).toggle(500);
            }

            function getObjectArrayLength(array){
                var size = 0, key;
                for (key in array) {
                    if (array.hasOwnProperty(key)) size++;
                }
                return size;
            }

            function sendSaveTemplateRequest(sessionId, paramsArray, callback){
                const requestDetails = {
                    numRequestsPlanned: paramsArray.length,
                    allSuccessful: true,
                    successfulResponses: [],
                    errorResponses: [],
                    responses: [],
                    canceledRequest: false
                };

                paramsArray.forEach(function(params){
                    requestManager.routes.emailTemplates.editEmailTemplateWithId(sessionId, params).then(
                        function(response){
                            requestDetails.responses.push(response);
                            requestDetails.successfulResponses.push(response);
                            if(requestDetails.responses.length === requestDetails.numRequestsPlanned){
                                callback(requestDetails);
                            }
                        },
                        function(response){
                            requestDetails.responses.push(response);
                            requestDetails.errorResponses.push(response);
                            requestDetails.allSuccessful = false;
                            if(!requestDetails.canceledRequest){
                                requestDetails.canceledRequest = response.status === -1;
                            }
                            if(requestDetails.responses.length === requestDetails.numRequestsPlanned){
                                callback(requestDetails);
                            }
                        }
                    )
                })

            }

            function onCreateTemplateClick(){
                if($scope.createTemplateForm.$invalid){
                    return;
                }

                const params = {
                    subject: $scope.createTemplate.subject,
                    title: $scope.createTemplate.title,
                    text: $scope.createTemplate.text,
                    language: "Default",
                };

                sendCreateEmailTemplateRequest($rootScope.authData.sessionId, params, function(requestDetails){
                    if(requestDetails.successful){
                        Notification.success($filter("translate")("emailTemplateEditor.CHANGED_SUCCESSFULLY"));
                        $window.location.reload();
                    }
                    else{
                        if (!requestDetails.canceledRequest) {
                            sharedMemory.error.setError(
                                requestDetails,
                                "COULD_NOT_LOAD_DATA",
                                "Fehler beim erstellen E-Mail Templates",
                                $location.path(),
                                "emailTemplateEditor"
                            );
                            $location.path("/error-page");
                        }
                    }
                });
            }

            function sendDeleteEmailTemplateRequest(sessionId, templateIdArray,  callback){
                const requestDetails = {
                    numRequestsPlanned: templateIdArray.length,
                    allSuccessful: true,
                    successfulResponses: [],
                    errorResponses: [],
                    responses: [],
                    canceledRequest: false
                };

                templateIdArray.forEach(function(templateId){
                    requestManager.routes.emailTemplates.deleteEmailTemplate(sessionId, templateId).then(
                        function(response){
                            requestDetails.responses.push(response);
                            requestDetails.successfulResponses.push(response);
                            if(requestDetails.responses.length === requestDetails.numRequestsPlanned){
                                callback(requestDetails);
                            }
                        },
                        function(response){
                            requestDetails.responses.push(response);
                            requestDetails.errorResponses.push(response);
                            requestDetails.allSuccessful = false;
                            if(!requestDetails.canceledRequest){
                                requestDetails.canceledRequest = response.status === -1;
                            }
                            if(requestDetails.responses.length === requestDetails.numRequestsPlanned){
                                callback(requestDetails);
                            }
                        }
                    )
                });
            }

            function sendCreateEmailTemplateRequest(sessionId, params, callback){
                const requestDetails = {
                    successful: true,
                    response: undefined,
                    canceledRequest: false
                };

                requestManager.routes.emailTemplates.createEmailTemplate(sessionId, params).then(
                    function(response){
                        requestDetails.response = response;
                        callback(requestDetails);
                    },
                    function(response){
                        requestDetails.response = response;
                        requestDetails.successful = false;
                        requestDetails.canceledRequest = response.status === -1;
                        callback(requestDetails);
                    }
                )
            }

            function onDeleteTemplateClick(template){
                sendDeleteEmailTemplateRequest($rootScope.authData.sessionId, template.id,  function(requestDetails){
                    if(requestDetails.successful){
                        Notification.success($filter("translate")("emailTemplateEditor.REMOVED_SUCCESSFULLY"));
                        $window.location.reload();
                    }
                    else{

                    }
                });
            }

            function onLanguageChange(index, template){
                //console.log(index, template);
            }

            function setDefaultTemplateInTemplateGroup(emailTemplateListGrouped){
                angular.forEach(emailTemplateListGrouped, function(emailTemplateGroup){
                    emailTemplateGroup.selectedLanguage = undefined;
                    emailTemplateGroup.selectedLanguage = emailTemplateGroup.languages["Default"];
                });

                return emailTemplateListGrouped;
            }

            function onAddLanguageClick(template){
                $scope.availableLanguages = [];
                $scope.availableLanguages = angular.copy($scope.addLanguageModal.languageOptions);
                const languages = Object.values(template.languages);
                languages.forEach(language => {
                    let x = $scope.availableLanguages.indexOf(
                        $scope.availableLanguages.find(
                            l => l.iso639v1 === language.language || l.iso639v1 === (language.language === 'Default' ? 'de' : language.language)
                        )
                    );
                    $scope.availableLanguages.splice(x, 1);
                });
                if ($scope.availableLanguages.length === 0) {
                    Notification.error($filter("translate")("emailTemplateEditor.NO_AVAILABLE_LANGUAGES"));
                    return;
                }
                jQuery("#addEmailTemplateLanguageModal").appendTo("body").modal("show");
                $scope.addLanguageModal.template = template;
            }

            function onRemoveLanguageOrTemplateClick(template){
                $scope.selectedLanguageForDeleteOptionsModal.content = template;
                jQuery("#deleteLanguageOrTemplateModal").appendTo("body").modal("show");
            }

            function onSaveLanguageButtonClick(){
                if(!$scope.addLanguageModal.selectedLanguageOption){
                    Notification.error($filter("translate")("emailTemplateEditor.LANGUAGE_NOT_SELECTED"));
                    return;
                }

                if(Object.keys($scope.addLanguageModal.template.languages).indexOf($scope.addLanguageModal.selectedLanguageOption.code) !== -1){
                    Notification.error($filter("translate")("emailTemplateEditor.TEMPLATE_LANGUAGE_EXISTS"));
                    return;
                }

                const params = {
                    language: $scope.addLanguageModal.selectedLanguageOption.iso639v1,
                    text: $scope.addLanguageModal.template.languages.Default.text,
                    title: $scope.addLanguageModal.template.languages.Default.title,
                    subject: $scope.addLanguageModal.template.languages.Default.subject
                };


                if($window.confirm($filter("translate")("emailTemplateEditor.CONFIRM_ADD_LANGUAGE"))){
                    sendCreateEmailTemplateRequest($rootScope.authData.sessionId, params, function(requestDetails){
                        if(requestDetails.successful){
                            jQuery("#addEmailTemplateLanguageModal").on("hidden.bs.modal", function(){
                                Notification.success($filter("translate")("emailTemplateEditor.LANGUAGE_ADDED"));
                                $window.location.reload();
                            }).modal("hide");
                        }
                        else{
                            jQuery("#addEmailTemplateLanguageModal").on("hidden.bs.modal", function(){
                                if (!requestDetails.canceledRequest) {
                                    sharedMemory.error.setError(
                                        requestDetails,
                                        "COULD_NOT_LOAD_DATA",
                                        "Fehler beim erstellen eines Email-Templates",
                                        $location.path(),
                                        "emailTemplateEditor"
                                    );
                                    $location.path("/error-page");
                                }
                            }).modal("hide");
                        }
                    });
                }
            }

            function onSaveButtonClick(template, index){

                if($scope.emailTemplateForm["templateSubject"+index].$invalid || $scope.emailTemplateForm["templateText"+index].$invalid){
                    return;
                }

                const editedTemplates = getEditedTemplates(template.languages);

                if(editedTemplates.length === 0 ){
                    Notification.warning($filter("translate")("emailTemplateEditor.NO_CHANGES"));
                    return;
                }

                sendSaveTemplateRequest($rootScope.authData.sessionId, editedTemplates, function(requestDetails){
                    if(requestDetails.allSuccessful){
                        Notification.success($filter("translate")("emailTemplateEditor.CHANGED_SUCCESSFULLY"));
                        $window.location.reload();
                    }
                    else{
                        if (!requestDetails.canceledRequest) {
                            sharedMemory.error.setError(
                                requestDetails,
                                "COULD_NOT_LOAD_DATA",
                                "Fehler beim editieren eines E-Mail Templates",
                                $location.path(),
                                "emailTemplateEditor"
                            );
                            $location.path("/error-page");
                        }
                    }
                })
            }

            function getEditedTemplates(templateList){
                const editedTemplates = [];

                angular.forEach(templateList, function(template){
                    if("edited" in template){
                        editedTemplates.push(template);
                    }
                });

                return editedTemplates;
            }

            function onRemoveLanguageClick(template){
                if(template.language === "Default"){
                    Notification.error($filter("translate")("emailTemplateEditor.DEFAULT_LANGUAGE_CANNOT_BE_DELETED"));
                    return;
                }

                if($window.confirm($filter("translate")("emailTemplateEditor.CONFIRM_DELETE_LANGUAGE"))){
                    sendDeleteEmailTemplateRequest($rootScope.authData.sessionId, [template.id], function(requestDetails){
                        if(requestDetails.allSuccessful){
                            Notification.success($filter("translate")("emailTemplateEditor.REMOVED_SUCCESSFULLY"));
                            $window.location.reload();
                        }
                        else{
                            if (!requestDetails.canceledRequest) {
                                sharedMemory.error.setError(
                                    requestDetails,
                                    "COULD_NOT_LOAD_DATA",
                                    "Fehler beim löschen eines E-Mail Templates",
                                    $location.path(),
                                    "emailTemplateEditor"
                                );
                                $location.path("/error-page");
                            }
                        }
                    });
                }
            }

            function onRemoveTemplateClick(templateList){
                const templateIdsToDelete = getIdsFromTemplateList(templateList);

                if($window.confirm($filter("translate")("emailTemplateEditor.CONFIRM_DELETE_TEMPLATE"))){
                    sendDeleteEmailTemplateRequest($rootScope.authData.sessionId, templateIdsToDelete, function(requestDetails){
                        if(requestDetails.allSuccessful){
                            Notification.success($filter("translate")("emailTemplateEditor.REMOVED_SUCCESSFULLY"));
                            $window.location.reload();
                        }
                        else{
                            if (!requestDetails.canceledRequest) {
                                sharedMemory.error.setError(
                                    requestDetails,
                                    "COULD_NOT_LOAD_DATA",
                                    "Fehler beim löschen eines E-Mail Templates",
                                    $location.path(),
                                    "emailTemplateEditor"
                                );
                                $location.path("/error-page");
                            }
                        }
                    });
                }
            }

            function getIdsFromTemplateList(templateList){
                const idList = [];
                angular.forEach(templateList, function(template){
                    idList.push(template.id);
                });
                return idList;
            }

            function insertReplacementTag(tag){
                $scope.createTemplate.text += tag.value;
            }
        }]
}); 

angular.module('workflowEditor', []);
angular.module('workflowEditor').component('workflowEditor', {
    bindings: {
        workflows: "<",
        workflowFilter: "<",
        settings: "<",
        emailTemplates: "<"
    },
    templateUrl: 'app/components/workflowEditor/workflowEditor.template.html',
    controller: [
        "$scope",
        "$rootScope",
        "requestManager",
        "$location",
        "$routeParams",
        "workflowUtil",
        "$filter",
        "Notification",
        function ($scope, $rootScope, requestManager, $location, $routeParams, workflowUtil, $filter, Notification) {
            onInit();

            this.$onChanges = function set(item) {
                if (item.workflows && item.workflows.currentValue) {
                    $scope.workflows.content = angular.copy(item.workflows.currentValue);
                    $scope.workflow.content = $scope.workflows.content.find(function (workflow) {
                        return workflow.id === $routeParams.id;
                    });
                    $scope.workflow.content = addMissingWorkflowProperties($scope.workflow.content);
                    $scope.view.workflowName = angular.copy($scope.workflow.content.name);
                    $scope.workflows.currentlyLoading = false;
                }

                if (item.workflowFilter && item.workflowFilter.currentValue) {
                    $scope.workflowFilter.content = angular.copy(item.workflowFilter.currentValue);
                    $scope.workflowFilter.content = addMissingWorkflowFilterProperties($scope.workflowFilter.content);
                    $scope.workflowFilter.currentlyLoading = false;
                }

                if (item.settings && item.settings.currentValue) {
                    $scope.settings.content = angular.copy(item.settings.currentValue);
                    $scope.settings.companyCountry = $scope.settings.content.find(function (setting) {
                        return setting.settingCode === "companyCountry";
                    }).settingValue1;
                    $scope.settings.companyCountry = $scope.settings.content.find(function (setting) {
                        return setting.settingCode === "companyCountry";
                    }).settingValue1;
                    $scope.settings.currentlyLoading = false;
                }

                if (item.emailTemplates && item.emailTemplates.currentValue) {
                    $scope.emailTemplates.content = angular.copy(item.emailTemplates.currentValue);
                    $scope.emailTemplates.currentlyLoading = false;
                }

                if (!$scope.workflowFilter.currentlyLoading && !$scope.workflows.currentlyLoading && !$scope.settings.currentlyLoading && !$scope.emailTemplates.currentlyLoading) {
                    switch ($scope.workflow.content.action.key) {
                        case "TO_PAYMENT_TERM_MAIL":
                        case "TO_MAIL":
                            $scope.workflow.content.selectedEmailTemplate = $scope.emailTemplates.content.find(
                                function (template) {
                                    return template.id === $scope.workflow.content.value1
                                }
                            );
                            break;
                        case "TO_EMAIL_OR_PHYSICAL_MAIL":
                            $scope.workflow.content.selectedEmailTemplate = $scope.emailTemplates.content.find(
                                function (template) {
                                    return template.id === $scope.workflow.content.value2;
                                }
                            );
                        case "TO_EMAIL_AND_PHYSICAL_MAIL":
                            $scope.workflow.content.selectedEmailTemplate = $scope.emailTemplates.content.find(
                                function (template) {
                                    return template.id === $scope.workflow.content.value2;
                                }
                            );
                    }

                    $scope.view.allDependenciesLoaded = true;

                }
            };

            function onInit() {
                initVariables();
                initScope();
            }

            function initVariables() {
                $scope.workflows = {
                    content: undefined,
                    currentlyLoading: true
                };
                $scope.workflowFilter = {
                    content: undefined,
                    currentlyLoading: true,
                    filterToDelete: []
                };
                $scope.workflow = {
                    content: undefined,
                    currentlyLoading: false
                };
                $scope.settings = {
                    content: undefined,
                    currentlyLoading: true
                };
                $scope.emailTemplates = {
                    content: undefined,
                    currentlyLoading: true
                };
                $scope.view = {
                    allDependenciesLoaded: false,
                    workflowName: undefined
                };
                $scope.dayList = workflowUtil.dayList;
                $scope.intervalList = workflowUtil.intervalList;
                $scope.hourList = workflowUtil.hourList;
                $scope.workflowTypeList = workflowUtil.workflowTypeList;
                $scope.workflowOperatorList = workflowUtil.workflowOperatorList;
                $scope.workflowFilterTypeList = workflowUtil.workflowFilterTypeList;
                $scope.booleanOptions = workflowUtil.booleanOptions;
                $scope.contactMethodOptions = workflowUtil.contactMethodOptions;
            }

            function initScope() {
                $scope.onSaveChangesButtonClick = onSaveChangesButtonClick;
                $scope.getEnabledOperators = getEnabledOperators;
                $scope.onAddFilterClick = onAddFilterClick;
                $scope.getAvailablesWorkflowTypes = getAvailablesWorkflowTypes;
                $scope.onRemoveFilterClick = onRemoveFilterClick;
                $scope.resetWorkflowValues = resetWorkflowValues;
                $scope.onCancelButtonClick = onCancelButtonClick;
            }

            function addMissingWorkflowProperties(workflow) {
                workflow.intervalDay = workflowUtil.dayList.find(function (day) {
                    return day.key === workflow.intervalDay;
                });

                workflow.intervalAmount = workflowUtil.intervalList.find(function (interval) {
                    return interval.key === workflow.intervalAmount;
                });

                workflow.action = workflowUtil.workflowTypeList.find(function (type) {
                    return type.key === workflow.action;
                });

                workflow.intervalHour = workflowUtil.hourList.find(function (hour) {
                    if (hour.key == workflow.intervalHour) {
                        return hour.text;
                    }
                });
                workflow.edited = false;
                return workflow;
            }

            function addMissingWorkflowFilterProperties(workflowFilterList) {
                workflowFilterList.forEach(function (workflowFilter) {

                    workflowFilter.keyName = workflowUtil.workflowFilterTypeList.find(function (filterType) {
                        return filterType.key === workflowFilter.keyName;
                    });

                    workflowFilter.value1 = workflowUtil.workflowOperatorList.find(function (operator) {
                        return operator.operator === workflowFilter.value1;
                    });

                    workflowFilter.existing = true;
                    workflowFilter.edited = false;
                });

                return workflowFilterList;
            }

            function onSaveChangesButtonClick(workflow, workflowFilter) {
                if ($scope.editWorkflowForm.$invalid) {
                    return;
                }

                const workflowChanges = {
                    finished: false,
                    successful: undefined
                };
                const workflowFilterChanges = {
                    finished: false,
                    successful: undefined
                };

                saveWorkflowChanges(workflow, function (status) {
                    if (status.successful) {
                        workflowChanges.finished = true;
                        workflowChanges.successful = true;
                        checkIfAllRequestsFinished();
                    } else {
                        workflowChanges.finished = true;
                        workflowChanges.successful = false;
                        checkIfAllRequestsFinished();
                    }
                });

                saveWorkflowFilterChanges(workflowFilter, function (status) {
                    if (status.successful) {
                        workflowFilterChanges.finished = true;
                        workflowFilterChanges.successful = true;
                        checkIfAllRequestsFinished();
                    } else {
                        workflowChanges.finished = true;
                        workflowChanges.successful = false;
                        checkIfAllRequestsFinished();
                    }
                });

                function checkIfAllRequestsFinished() {
                    if (workflowChanges.finished && workflowFilterChanges.finished) {
                        if (workflowChanges.successful && workflowFilterChanges.successful) {
                            Notification.success($filter("translate")("Workflow geändert"));
                            $location.path("/workflows");
                        } else {
                            console.log("error");
                        }
                    }
                }
            }

            function saveWorkflowChanges(workflow, callback) {
                const finishedStatus = {
                    workflow: {
                        finished: false,
                        withErrors: false,
                        errorDetails: undefined
                    },
                    successful: true
                };

                if (workflow.content.edited) {
                    let params = {
                        action: workflow.content.action.key,
                        id: workflow.content.id,
                        intervalAmount: workflow.content.intervalAmount.key,
                        intervalDay: workflow.content.intervalDay ? workflow.content.intervalDay.key : "IGNORE_INTERVAL_DAY",
                        intervalHour: workflow.content.intervalHour.key,
                        name: workflow.content.name,
                        priority: workflow.content.priority,
                        value1: workflow.content.value1,
                        value2: workflow.content.value2,
                        value3: workflow.content.value3,
                        noPermissionNeededForMailOrPhysicalMail: workflow.content.noPermissionNeededForMailOrPhysicalMail,
                    };
                    if (params.action === "TO_MAIL") {
                        params.value1 = workflow.content.selectedEmailTemplate.id;
                    }
                    if (params.action === "TO_EMAIL_OR_PHYSICAL_MAIL") {
                        params.value2 = workflow.content.selectedEmailTemplate.id;
                    }
                    if (params.action === "TO_EMAIL_AND_PHYSICAL_MAIL") {
                        params.value2 = workflow.content.selectedEmailTemplate.id;
                    }

                    sendEditWorkflowRequest($rootScope.authData.sessionId, params, function (requestDetails) {
                        if (requestDetails.successful) {
                            finishedStatus.workflow.finished = true;
                            callback(finishedStatus);
                        } else {
                            finishedStatus.workflow.finished = true;
                            finishedStatus.workflow.withErrors = true;
                            finishedStatus.workflow.errorDetails = requestDetails;
                            finishedStatus.workflow.successful = false;
                            callback(finishedStatus);
                        }
                    });
                } else {
                    finishedStatus.workflow.finished = true;
                    callback(finishedStatus);
                }
            }

            function saveWorkflowFilterChanges(workflowFilter, callback) {
                const finishedStatus = {
                    filterToDelete: {
                        finished: false,
                        withErrors: false,
                        errorDetails: undefined
                    },
                    filterToEdit: {
                        finished: false,
                        withErrors: false,
                        errorDetails: undefined
                    },
                    filterToCreate: {
                        finished: false,
                        withErrors: false,
                        errorDetails: undefined
                    },
                    successful: true
                };

                const filterToDelete = angular.copy(workflowFilter).filterToDelete.map(function (filter) {
                    return {
                        workflowId: $routeParams.id,
                        workflowFilterId: filter.id,
                    };
                });
                const filterToEdit = angular.copy(workflowFilter).content.filter(function (filter) {
                    filter.keyName = filter.keyName.key;
                    filter.value1 = filter.value1.operator;
                    return filter.existing && filter.edited;
                });
                const filterToCreate = angular.copy(workflowFilter).content.filter(function (filter) {
                    filter.keyName = filter.keyName.key;
                    filter.value1 = filter.value1.operator;
                    return !filter.existing;
                });

                if (filterToDelete.length > 0) {
                    sendDeleteWorkflowFilterRequest($rootScope.authData.sessionId, filterToDelete, function (requestDetails) {
                        if (requestDetails.allSuccessful) {
                            finishedStatus.filterToDelete.finished = true;
                            checkIfAllRequestsFinished();
                        } else {
                            finishedStatus.filterToDelete.finished = true;
                            finishedStatus.filterToDelete.withErrors = true;
                            finishedStatus.filterToDelete.errorDetails = requestDetails;
                            finishedStatus.successful = false;
                            checkIfAllRequestsFinished();
                        }
                    });
                } else {
                    finishedStatus.filterToDelete.finished = true;
                    checkIfAllRequestsFinished();
                }

                if (filterToEdit.length > 0) {
                    sendEditWorkflowFilterRequest($rootScope.authData.sessionId, filterToEdit, function (requestDetails) {
                        if (requestDetails.allSuccessful) {
                            finishedStatus.filterToEdit.finished = true;
                            checkIfAllRequestsFinished();
                        } else {
                            finishedStatus.filterToEdit.finished = true;
                            finishedStatus.filterToEdit.withErrors = true;
                            finishedStatus.filterToEdit.errorDetails = requestDetails;
                            finishedStatus.successful = false;
                            checkIfAllRequestsFinished();
                        }
                    });
                } else {
                    finishedStatus.filterToEdit.finished = true;
                    checkIfAllRequestsFinished();
                }

                if (filterToCreate.length > 0) {
                    sendCreateWorkflowFilterRequest($rootScope.authData.sessionId, $routeParams.id, filterToCreate, function (requestDetails) {
                        if (requestDetails.allSuccessful) {
                            finishedStatus.filterToCreate.finished = true;
                            checkIfAllRequestsFinished();
                        } else {
                            finishedStatus.filterToCreate.finished = true;
                            finishedStatus.filterToCreate.withErrors = true;
                            finishedStatus.filterToCreate.errorDetails = requestDetails;
                            finishedStatus.successful = false;
                            checkIfAllRequestsFinished();
                        }
                    });
                } else {
                    finishedStatus.filterToCreate.finished = true;
                    checkIfAllRequestsFinished();
                }

                function checkIfAllRequestsFinished() {
                    if (finishedStatus.filterToCreate.finished && finishedStatus.filterToDelete.finished && finishedStatus.filterToEdit.finished) {
                        callback(finishedStatus);
                    }
                }
            }

            function getEnabledOperators(workflowFilter) {
                switch (workflowFilter.keyName.inputType) {
                    case "boolean":
                        return $scope.workflowOperatorList.filter(function (operator) {
                            return operator.operator === "=";
                        });
                    case "text":
                        if (workflowFilter.keyName.key.includes('individualField')) {
                            return $scope.workflowOperatorList.filter(function (operator) {
                                return operator.operator === "=" || operator.operator === "enthält";
                            });
                        } else {
                            return $scope.workflowOperatorList;
                        }
                    case "select":
                        return $scope.workflowOperatorList.filter(function (operator) {
                            return operator.operator === "=";
                        });
                }
            }

            function onAddFilterClick() {
                $scope.workflowFilter.content.push({
                    existing: false,
                    keyName: workflowUtil.workflowFilterTypeList.find(function (filterType) {
                        return filterType.key === "isProblematic";
                    }),
                    value1: undefined,
                    value2: undefined,
                    edited: true,
                });
            }

            function onRemoveFilterClick(filterToRemove) {
                $scope.workflowFilter.content = $scope.workflowFilter.content.filter(filter => filter.id !== filterToRemove.id);
                $scope.workflowFilter.filterToDelete.push(filterToRemove);
                // const deletedFilter = $scope.workflowFilter.content.pop();
                //
                // if(deletedFilter.existing){
                //     $scope.workflowFilter.filterToDelete.push(deletedFilter);
                // }
            }

            function getAvailablesWorkflowTypes() {

                const availableWorkflowTypes = $scope.workflowTypeList.filter(function (workflowType) {
                    if (workflowType.workFlowAvailableInAllCountries) {
                        return true;
                    }

                    return workflowType.workflowAvailableInCountries.includes($scope.settings.companyCountry);
                });

                return availableWorkflowTypes;
            }

            function sendEditWorkflowRequest(sessionId, params, callback) {
                const requestDetails = {
                    response: undefined,
                    successful: true
                };

                requestManager.routes.workflow.editWorkflow(sessionId, params).then(
                    function (response) {
                        requestDetails.response = response;
                        callback(requestDetails);
                    },
                    function (response) {
                        requestDetails.response = response;
                        requestDetails.successful = false;
                        callback(requestDetails);
                    }
                )
            }

            function sendEditWorkflowFilterRequest(sessionId, paramsArray, callback) {
                const requestDetails = {
                    numRequestsPlanned: paramsArray.length,
                    allSuccessful: true,
                    responses: [],
                    successfulResponses: [],
                    errorResponses: [],
                };

                paramsArray.forEach(function (params) {
                    requestManager.routes.workflowFilter.editWorkflowFilter(sessionId, params).then(
                        function (response) {
                            requestDetails.responses.push(response);
                            requestDetails.successfulResponses.push(response);
                            if (requestDetails.responses.length === requestDetails.numRequestsPlanned) {
                                callback(requestDetails);
                            }

                        },
                        function (response) {
                            requestDetails.responses.push(response);
                            requestDetails.errorResponses.push(response);
                            requestDetails.allSuccessful = false;
                            if (requestDetails.responses.length === requestDetails.numRequestsPlanned) {
                                callback(requestDetails);
                            }
                        }
                    )
                });
            }

            function sendDeleteWorkflowFilterRequest(sessionId, paramsArray, callback) {
                const requestDetails = {
                    numRequestsPlanned: paramsArray.length,
                    allSuccessful: true,
                    responses: [],
                    successfulResponses: [],
                    errorResponses: [],
                };

                paramsArray.forEach(function (params) {
                    requestManager.routes.workflow.deleteWorkflowFilterOfWorkflow(sessionId, params).then(
                        function (response) {
                            requestDetails.responses.push(response);
                            requestDetails.successfulResponses.push(response);
                            if (requestDetails.responses.length === requestDetails.numRequestsPlanned) {
                                callback(requestDetails);
                            }

                        },
                        function (response) {
                            requestDetails.responses.push(response);
                            requestDetails.errorResponses.push(response);
                            requestDetails.allSuccessful = false;
                            if (requestDetails.responses.length === requestDetails.numRequestsPlanned) {
                                callback(requestDetails);
                            }
                        }
                    )
                });
            }

            function sendCreateWorkflowFilterRequest(sessionId, workflowId, paramsArray, callback) {
                const requestDetails = {
                    numRequestsPlanned: paramsArray.length,
                    allSuccessful: true,
                    responses: [],
                    successfulResponses: [],
                    errorResponses: [],
                };

                paramsArray.forEach(function (params) {
                    requestManager.routes.workflow.createWorkflowFilterOfWorkflow(sessionId, workflowId, params).then(
                        function (response) {
                            requestDetails.responses.push(response);
                            requestDetails.successfulResponses.push(response);
                            if (requestDetails.responses.length === requestDetails.numRequestsPlanned) {
                                callback(requestDetails);
                            }
                        },
                        function (response) {
                            requestDetails.responses.push(response);
                            requestDetails.errorResponses.push(response);
                            requestDetails.allSuccessful = false;
                            if (requestDetails.responses.length === requestDetails.numRequestsPlanned) {
                                callback(requestDetails);
                            }
                        }
                    )
                });
            }

            function resetWorkflowValues() {
                $scope.workflow.content.value1 = undefined;
                $scope.workflow.content.value2 = undefined;
            }

            function onCancelButtonClick() {
                $location.path("/workflows");
            }
        }
    ]
}); 

angular.module('workflowPriority', []);
angular.module('workflowPriority').component('workflowPriority', {
	templateUrl: 'app/components/workflowPriority/workflowPriority.template.html',
	bindings: {
			workflows: '<', 
	},
	controller: [
		"$scope",
		"$rootScope",
		"requestManager",
		"Notification",
		"$filter",
		"$route",
		"$window",
		"$location",
		"sharedMemory",
		"workflowUtil", 
		function($scope, $rootScope, requestManager, Notification, $filter, $route, $window, $location, sharedMemory, workflowUtil) {
			onInit();

			this.$onChanges = function set(item) {
				if (item.workflows && item.workflows.currentValue) {
					$scope.workflows.content = angular.copy(item.workflows.currentValue);
					$scope.workflows.updateableContent = angular.copy(item.workflows.currentValue); 
					$scope.workflows.updateableContent.forEach(function(workflow){workflow.priority = parseInt(workflow.priority)}); 
					$scope.workflows.updateableContent = workflowUtil.sortWorkflowsByPriority($scope.workflows.updateableContent); 
					$scope.workflows.currentlyLoading = false;
				}
				if (!$scope.workflows.currentlyLoading) {
					$scope.view.allDependenciesLoaded = true;
				}
			};

			function onInit() {
				initVariables();
				initScope();
			}
			
			function initVariables(){
				$scope.view = {
					allDependenciesLoaded: false, 
					
				}
				
				$scope.workflows = {
					content: undefined, 
					updateableContent: undefined, 
					currentlyLoading: true, 
					updated: false
				}
			}

			function initScope(){
				$scope.onSaveButtonClick = onSaveButtonClick; 
			}
			
			function onSaveButtonClick(){
				if(!$scope.workflows.updated){
					Notification.success($filter("translate")("workflowPriority.SAVED_SUCCESSFULLY")); 
					return; 
				}
				
				$scope.view.allDependenciesLoaded = false; 
				
				$scope.workflows.updateableContent.forEach(function(workflow, index){
					workflow.priority = index; 
				}); 
				
				sendEditWorkflowRequest(
					$rootScope.authData.sessionId, 
					$scope.workflows.updateableContent.map(function(workflow){ return {id: workflow.id, priority: workflow.priority}}), 
					function(requestDetails){
						$scope.allDependenciesLoaded = true; 
						
						if(requestDetails.allSuccessful){
							Notification.success($filter("translate")("workflowPriority.SAVED_SUCCESSFULLY")); 
							$route.reload(); 
						}
						else{
							sharedMemory.error.setError(
								requestDetails,
								"COULD_NOT_LOAD_DATA",
								"Fehler beim bearbeiten der Workflows (Ändern der Workflow-Priorität)",
								$location.path(),
								"workflowPriority"
							);
							$location.path("/error-page");
						}
					}
				); 
			}
			
			function sendEditWorkflowRequest(sessionId, paramsArray, callback){
				var requestDetails = {
					numRequestsPlanned: paramsArray.length, 
					allSuccessful: true, 
					allResponses: [], 
					successfulResponses: [], 
					errorResponses: [], 
				}
				paramsArray.forEach(function(params){
					requestManager.routes.workflow.editWorkflow(sessionId, params).then(
						function(response){
							requestDetails.allResponses.push(response); 
							requestDetails.successfulResponses.push(response); 
							if(requestDetails.allResponses.length == requestDetails.numRequestsPlanned){
								callback(requestDetails); 
							}
						}, 
						function(response){
							requestDetails.allResponses.push(response); 
							requestDetails.errorResponses.push(response); 
							requestDetails.allSuccessful = false; 
							if(requestDetails.allResponses.length == requestDetails.numRequestsPlanned){
								callback(requestDetails); 
							}
						}
					) 
				}); 
			}
		}
	]
});
angular.module('registerWizard', []);
angular.module('registerWizard').component('registerWizard', { 
	templateUrl: 'app/components/registerWizard/registerWizard.template.html', 
	controller: [ 
		"$scope", 
		"$rootScope", 
		"Notification", 
		"$filter", 
		"requestManager",
		"$http",
		"$location", 
		"$window", 
		function ($scope, $rootScope, Notification, $filter, requestManager, $http, $location, $window) { 
			this.$onInit = function(){
				initVariables();
				initScope();
			};
			
			function initVariables(){
				$scope.currentStep = 1;
				$scope.inBeta = true;
				$scope.registration = {
					databaseName: undefined, 
					workspaceUrl: undefined, 
				}
				setCountryCodes();
				setTimezones();
				
				$scope.companySettings = {
					companyName: "Testunternehmen",
					companyMail: "test@email.com",
					companyCity: "Teststadt",
					companyZip: "TestPLZ", 
					companyAddress: "Teststrasse",
					companyPhone: "12345678",
					companyCountry: undefined,
					companyUTCTimezone: undefined, 
					companyVat: "ATU68522306",
					consultantNumber: "12345678", 
					companyType: "TAX_CONSULTANT",
					companyLogo: "https://madeby.google.com/static/images/google_g_logo.svg", 
				};
				$scope.datevSettings = {
					ip: "80.157.191.214",
					port: "58452",
					username: "AGROLABdatevconnect",
					password: "password", 
					clientNo: "10111",
					fiscalYear: "20170101",
				};
				$scope.userData = {
					name: "Testname", 
					surname: "Testnachname", 
					email: "test@email.com", 
					password: "passwort"
				}
				$scope.workspace = {
					workspaceName: "mytestworkspace",
				}
				$scope.paymentType = "CREDIT_CARD"; 
			}
			
			function setCountryCodes(){
				$http({
					method: 'GET',
					url: "../node_modules/countries-list/dist/data.json",
				}).then(
					function(response){
						
						angular.forEach(response.data.countries, function (value, key){
							value.code = key;
						});
						$scope.countryCodes = response.data.countries;
					}, 
					function(response){
						
					}
				); 
			}
			
			function setTimezones(){
				$http({
					method: 'GET',
					url: "../node_modules/timezones.json/timezones.json",
				}).then(
					function(response){
						$scope.timezones = response.data;
					}, 
					function(response){
						console.log(response);
					}
				); 
			}
			
			function initScope(){
				$scope.onChangeStepClick = onChangeStepClick;
				$scope.onFinishClick = onFinishClick;
				$scope.forwardToCustomerWorkspace = forwardToCustomerWorkspace;
				$scope.createWorkspaceUrl = createWorkspaceUrl;
				$scope.forwardToGoCardlessPage = forwardToGoCardlessPage;
			}
			
			function onChangeStepClick(step, currentFormName){
				if(currentFormName && $scope[currentFormName].$valid){
					$scope.currentStep = step;
				}
				
				if(!currentFormName){
					$scope.currentStep = step;
				}
			}
			
			function onFinishClick(){
				var params = {
					userData: angular.copy($scope.userData), 
					companySettings: angular.copy($scope.companySettings), 
					datevSettings: angular.copy($scope.datevSettings), 
					databaseName: angular.copy($scope.workspace.workspaceName)
				}
				
				params.companySettings.companyCountry = $scope.companySettings.companyCountry.code;
				params.companySettings.companyUTCTimezone = $scope.companySettings.companyUTCTimezone.offset + "00";
				params.companySettings.companyUTCTimezone = params.companySettings.companyUTCTimezone.replace(".", "");
				if(params.companySettings.companyUTCTimezone.indexOf("-") === -1){
					params.companySettings.companyUTCTimezone = "+" + params.companySettings.companyUTCTimezone;
				}
				
				jQuery("#processingModal").modal("show");
		
				requestManager.routes.auth.register(params).then(
					function(response){ 
						$scope.registration.databaseName = response.config.data.databaseName; 
						$scope.registration.workspaceUrl = createWorkspaceUrl(response.config.data.databaseName); 
						
						
						jQuery("#processingModal").one("hidden.bs.modal", function(){
							jQuery("#successModal").modal("show");
						}).modal("hide");
					}, 
					function(response){
						jQuery("#processingModal").one("hidden.bs.modal", function(){
							Notification.error($filter("translate")("registerWizard.REGISTRATION_ERROR"));
						}).modal("hide");
					}
				);
			}
			
			function forwardToGoCardlessPage(){
				
				$scope.companySettings.companyUTCTimezone.offsetString = ($scope.companySettings.companyUTCTimezone.offset.toString()).length === 2 ? 
					$scope.companySettings.companyUTCTimezone.offset.toString() + "00" :
					$scope.companySettings.companyUTCTimezone.offset.toString() + "0";
				var prepareGoCardlessPaymentParams = {
					sessionToken: "test", 
					description: "Debitorcloud Monthly Subscription", 
					successUrl: undefined
				}
				var tempRegistrationParams = {
					"companyName": $scope.companySettings.companyName, 
					"companyLogo": $scope.companySettings.companyLogo, 
					"companyAddress": $scope.companySettings.companyAddress, 
					"companyZip": $scope.companySettings.companyZip, 
					"companyCity": $scope.companySettings.companyCity, 
					"companyCountry": $scope.companySettings.companyCountry.code, 
					"companyPhone": $scope.companySettings.companyPhone, 
					"companyMail": $scope.companySettings.companyMail, 
					"companyUTCTimezone": $scope.companySettings.companyUTCTimezone.offsetString, 
					"companyVat": $scope.companySettings.companyVat, 

					"datevIP": $scope.datevSettings.ip, 
					"datevPort": $scope.datevSettings.port, 
					"datevUsername": $scope.datevSettings.username, 
					"datevPassword": $scope.datevSettings.password, 
					"datevFiscalYear": $scope.datevSettings.fiscalYear, 
					"datevClientNo": $scope.datevSettings.clientNo, 

					"userName": $scope.userData.name, 
					"userSurname": $scope.userData.surname, 
					"userPassword": $scope.userData.password, 
					"userEmail": $scope.userData.email, 

					"databaseName": $scope.workspace.workspaceName, 

					"paymentType": $scope.paymentType
				}
				
				requestManager.routes.auth.storeTemporary(tempRegistrationParams).then(
					onStoreTempDataRequestSuccess, 
					onRequestError
				); 
				
				function onStoreTempDataRequestSuccess(response){
					prepareGoCardlessPaymentParams.successUrl = $scope.inBeta ? 
						"http://www.beta-debitorcloud.riecken-webservices.com:8080/index.html#!/register?id=" + response.data.id : "";
					
					requestManager.routes.auth.prepareGoCardlessPayment(prepareGoCardlessPaymentParams).then(onPrepareGoCardlessPaymentSuccess, onRequestError);
				}
				
				function onPrepareGoCardlessPaymentSuccess(response){
					var forwardUrl = response.data.forwardUrl; 
					var confirmationId = response.data.confirmationId;
					$window.location.replace(response.data.url);
				}
				
				function onRequestError(){
					
				}
			}
			
			function createWorkspaceUrl(databaseName){
				if($scope.inBeta){
					return 'https://' + databaseName + '.beta-debitorcloud.riecken-webservices.com:8080';
				}
				else{
					return 'https://' + databaseName + '.debitorcloud.com';
				}
			}
			
			function createGoCardlessReturnUrl(){
				var data = {
					companySettings: $scope.companySettings, 
					datevSettings: $scope.datevSettings, 
					userData: $scope.userData, 
					workspace: $scope.workspace
				}
				
				if($scope.inBeta){
					return 'http://www.beta-debitorcloud.riecken-webservices.com:8080?data=' + btoa(JSON.stringify(data));
				}
				else{
					return 'https://www.debitorcloud.com?data=' + atob(JSON.stringify(data));
				}
			}
			
			function forwardToCustomerWorkspace(){
					$window.location.replace($scope.registration.workspaceUrl);
			}
		} 
	] 
}); 

angular.module('physicalMailApprovalList', []);
angular.module('physicalMailApprovalList').component('physicalMailApprovalList', {
    templateUrl: 'app/components/physicalMailApprovalList/physicalMailApprovalList.template.html',
    bindings: {
        physicalMailList: "<",
        customerAdvisorId: "<",
        numRowsSetting: "<",
        assignedCustomersOnly: "<"
    },
    controller: [
        "$scope",
        "$rootScope",
        "requestManager",
        "util",
        "Notification",
        "$filter",
        "$window",
        "$location",
        function ($scope, $rootScope, requestManager, util, Notification, $filter, $window, $location) {
            const ctrl = this;
            init();
            getCustomer();
            $scope.europeanNumberFormat = 'en-US';
            if ($rootScope.europeanNumberFormat !== undefined) {
                if ($rootScope.europeanNumberFormat === '1' || $rootScope.europeanNumberFormat === 1) {
                    $scope.europeanNumberFormat = 'de-AT';
                } else {
                    $scope.europeanNumberFormat = 'en-US';
                }
            }

            ctrl.$onChanges = function set(item) {
                if (item && item.physicalMailList && item.physicalMailList.currentValue) {
                    item.physicalMailList.currentValue.forEach(value => {
                        value.sumAmountDebit = +value.sumAmountDebit;
                    });
                    $scope.physicalMailList.originalContent = angular.copy(item.physicalMailList.currentValue);
                    $scope.physicalMailList.content = angular.copy(item.physicalMailList.currentValue);
                    $scope.physicalMailList.currentlyLoading = false;
                    if (!$scope.userSettingsLoading) {
                        renderTable();
                    }
                }
                if (item && item.numRowsSetting && item.numRowsSetting.currentValue) {
                    $scope.physicalMailApprovalListNumRows = item.numRowsSetting.currentValue;
                    $scope.view.itemsPerPageSelected = +item.numRowsSetting.currentValue.value1;

                    if (!$scope.view.itemsPerPageSelected) {
                        $scope.view.itemsPerPageSelected = $scope.itemsPerPageOptions[0];
                    }

                    if (!$scope.physicalMailList.currentlyLoading) {
                        renderTable();
                    }
                    $scope.userSettingsLoading = false;
                }
                if (item && item.customerAdvisorId && item.customerAdvisorId.currentValue) {
                    $scope.customerAdvisorId = item.customerAdvisorId.currentValue;
                }
                if (item && item.assignedCustomersOnly && item.assignedCustomersOnly.currentValue) {
                    $scope.filterSettingId = item.assignedCustomersOnly.currentValue.id;
                    $scope.customerFilterSetting = item.assignedCustomersOnly.currentValue.value2;

                    if ($scope.customerFilterSetting === '1') {
                        $scope.filterActivated = true;
                        filterUnapprovedPhysicalMails();
                    }
                }
                if (!$scope.physicalMailList.currentlyLoading) {
                    $scope.view.currentlyLoading = false;
                }
            };

            function init() {
                initVariables();
                initScope();
            }


            function getCustomer() {
                if ($location.path().startsWith('/email-check')) {
                    if ($rootScope.settings === undefined) {
                        const interval = setInterval(() => {
                            if ($rootScope.settings !== undefined) {
                                const accountLengthImpersonal = $rootScope.settings.find(function (setting) {
                                    return setting.settingCode === 'accountLengthImpersonal';
                                });
                                if (accountLengthImpersonal !== undefined) {
                                    $scope.accountLengthImpersonal = +accountLengthImpersonal.settingValue1;
                                } else {
                                    $scope.accountLengthImpersonal = 8;
                                }
                                clearInterval(interval);
                            }
                        }, 100);
                    } else {
                        const accountLengthImpersonal = $rootScope.settings.find(function (setting) {
                            return setting.settingCode === 'accountLengthImpersonal';
                        });
                        if (accountLengthImpersonal !== undefined) {
                            $scope.accountLengthImpersonal = +accountLengthImpersonal.settingValue1;
                        } else {
                            $scope.accountLengthImpersonal = 8;
                        }
                    }
                }
            }

            function initVariables() {
                $scope.physicalMailList = {
                    currentlyLoading: true,
                    content: undefined,
                    contentChunked: undefined,
                    originalContent: undefined,
                    helperArray: undefined
                }
                $scope.itemsPerPageOptions = [10, 25, 50, 100];
                $scope.view = {
                    currentlyLoading: true,
                    itemsPerPageSelected: 0,
                    currentPage: 0,
                    selectAllCheckbox: false
                };
                $scope.emailDetailsModal = {
                    email: undefined,
                    currentlyLoading: false
                }
                $scope.ascending = true;
                $scope.type = 'recipientId';
                $scope.userSettingsLoading = true;
                $scope.customerAdvisorId = undefined;
                $scope.customerFilterSetting = undefined;
                $scope.filterSettingId = undefined;
                $scope.filterActivated = false;
            }

            function initScope() {
                $scope.showPdf = showPdf;
                $scope.updateApprovalStatus = updateApprovalStatus;
                $scope.changePage = changePage;
                $scope.toggleAllRowsInPage = toggleAllRowsInPage;
                $scope.renderTable = renderTable;
                $scope.updateApprovalStatusForSelection = updateApprovalStatusForSelection;
                $scope.getColorCodeForEntryCreatedToTodayDifference = getColorCodeForEntryCreatedToTodayDifference;
                $scope.getTooltipMessage = getTooltipMessage;
                $scope.parseDate = parseDate;
                $scope.applyOrder = applyOrder;
                $scope.openInfoModal = openInfoModal;
                $scope.filterUnapprovedPhysicalMails = filterUnapprovedPhysicalMails;

                $scope.$on('$routeChangeStart', function() {
                    saveChangedSettings();
                });
            }

            function openInfoModal() {
                jQuery("#physicalMailApprovalListInfoModal").modal("show");
            }

            function filterUnapprovedPhysicalMails() {
                if ($scope.filterActivated && $scope.physicalMailList.helperArray !== undefined) {
                    $scope.physicalMailList.originalContent = $scope.physicalMailList.helperArray;
                    $scope.filterActivated = false;
                    renderTable();
                    saveSettingParameterInBackend(0);
                } else {
                    const filteredArray = [];
                    angular.forEach($scope.physicalMailList.originalContent, (mail) => {
                        if (mail.customerAdvisorId === $scope.customerAdvisorId) {
                            filteredArray.push(mail);
                        }
                    });
                    $scope.physicalMailList.helperArray = $scope.physicalMailList.originalContent;
                    $scope.physicalMailList.originalContent = filteredArray;
                    $scope.filterActivated = true;
                    renderTable();
                    saveSettingParameterInBackend(1);
                }
            }

            function saveSettingParameterInBackend(property) {
                if ($scope.filterSettingId !== undefined) {
                    var paramsObject = {
                        value2: property
                    };

                    requestManager.routes.userSettings.editUserSetting($rootScope.authData.sessionId, $scope.filterSettingId, paramsObject);
                }
            }

            function renderTable() {
                $scope.physicalMailList.content = angular.copy($scope.physicalMailList.originalContent);
                let res = [];
                //for loop below does the same as lodash.values function
                for (let x in $scope.physicalMailList.content) {
                    $scope.physicalMailList.content.hasOwnProperty(x) && res.push($scope.physicalMailList.content[x])
                }
                $scope.physicalMailList.content = res;
                angular.forEach($scope.physicalMailList.content, function (email) {
                    email.selected = false;
                });
                $scope.physicalMailList.content = orderEmailListBy($scope.type, $scope.physicalMailList.content);
                $scope.physicalMailList.contentChunked = util.divideArrayIntoChunks($scope.physicalMailList.content, $scope.view.itemsPerPageSelected);
                $scope.view.currentPage = 0;
            }

            function orderEmailListBy(type, physicalMailList) {
                let sortedPhysicalMailList = physicalMailList;
                switch (type) {
                    case 'recipientId':
                        sortedPhysicalMailList = physicalMailList.sort((a, b) => a.recipientId - b.recipientId);
                        break;
                    case 'recipientName':
                        sortedPhysicalMailList = physicalMailList.sort((a, b) => (a.recipientSurame + a.recipientName).localeCompare((b.recipientSurame + b.recipientName)));
                        break;
                    // case 'price':
                    //     sortedPhysicalMailList = physicalMailList.sort((a, b) => a.price - b.price);
                    //     break;
                    case 'sumAmountDebit':
                        sortedPhysicalMailList = physicalMailList.sort((a, b) => a.sumAmountDebit - b.sumAmountDebit);
                        break;
                    case 'lastPaymentDate':
                        sortedPhysicalMailList = physicalMailList.sort((a, b) => new Date(new Date(b.lastPaymentDate).getTime() - a.lastPaymentDate).getTime());
                        break;
                }

                return $scope.ascending ? sortedPhysicalMailList : sortedPhysicalMailList.reverse();
            }

            function applyOrder(type) {
                if ($scope.type === type) {
                    $scope.ascending = !$scope.ascending;
                } else {
                    $scope.ascending = true;
                }
                $scope.type = type;
                renderTable();
            }

            function parseDate(dateObject) {
                if (dateObject === null) {
                    return "Noch nie";
                }
                var inputDateFormat = "YYYY-MM-DD HH:mm:ss";
                var outputDateFormat = "DD.MM.YYYY";
                var date = moment(dateObject, inputDateFormat);
                return date.format(outputDateFormat);
            }

            function getColorCodeForEntryCreatedToTodayDifference(entryCreated) {
                var t = entryCreated.split(/[- :]/);
                var d = new Date(t[0], t[1] - 1, t[2], t[3], t[4], t[5]);
                var cur = new Date();
                var dif = Math.abs(cur - d);

                if (dif < 172800000) {
                    return '#ffffff';
                } else if (dif > 345600000) {
                    return '#f8d7da';
                } else if (dif > 172800000) {
                    return '#fff3cd';
                }
            }

            function getTooltipMessage(entryObject) {
                var t = entryObject.split(/[- :]/);
                var d = new Date(t[0], t[1] - 1, t[2], t[3], t[4], t[5]);
                var cur = new Date();
                var dif = Math.abs(cur - d);

                if (dif < 172800000) {
                    return;
                } else if (dif > 345600000) {
                    return 'Mahnung liegt schon über 4 Tage in der Freigabeliste!';
                } else if (dif > 172800000) {
                    return 'Mahnung liegt schon über 2 Tage in der Freigabeliste!';
                }
            }

            function updateApprovalStatus(status, physicalMailId) {
                toggleProcessingModal(true);
                $scope.view.currentlyLoading = true;

                let params = {
                    approved: status,
                    id: physicalMailId,
                    approvedBy: $rootScope.authData.userId
                };

                if (status === "0") {
                    requestManager.routes.record.deleteWholeRecord($rootScope.authData.sessionId, physicalMailId).then(
                        function (response) {
                            reload(function () {
                                toggleProcessingModal(false, function () {
                                    Notification.success($filter("translate")("emailList.SUCCESSFUL_UPDATED"));
                                    $scope.view.currentlyLoading = false;
                                });
                            });
                        },
                        function (response) {

                        }
                    )

                } else {
                    requestManager.routes.recordPhysicalMail.updatePhysicalMail($rootScope.authData.sessionId, params).then(
                        function (response) {
                            reload(function () {
                                toggleProcessingModal(false, function () {
                                    Notification.success($filter("translate")("physicalMailApprovalList.SUCCESSFUL_UPDATED"));
                                    $scope.view.currentlyLoading = false;
                                });
                            });
                        },
                        function (response) {
                        }
                    );
                }
            }

            function showPdf(recordPhysicalMailId) {
                //check what browser the user is surfing on
                var ua = window.navigator.userAgent;
                var msie = ua.indexOf("MSIE ");
                var IEfound = false;

                if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./))  // If Internet Explorer
                {
                    IEfound = true;
                }

                if (!IEfound) {
                    var tabWindow = $window.open('about:blank', '_blank');
                    tabWindow.document.write('loading...');
                }

                requestManager.routes.recordPhysicalMail.getPhysicalMailPdf($rootScope.authData.sessionId, recordPhysicalMailId).then(
                    response => {

                        var notificationReference = Notification.warning({
                            message: $filter("translate")("wrapper.LOADING_PDF"),
                            delay: null,
                            closeOnClick: false,
                        });

                        var file = new Blob([response.data], {type: 'application/pdf'});
                        var fileURL = URL.createObjectURL(file);

                        tabWindow.location.href = fileURL;

                        notificationReference.then(function (notification) {
                            notification.kill(false);
                        });
                    }
                );
            }

            function reload(callback) {
                requestManager.routes.recordPhysicalMail.virtual.getUnapprovedPhysicalMail($rootScope.authData.sessionId).then(
                    function (response) {
                        $scope.physicalMailList.originalContent = angular.copy(response.data);
                        $scope.physicalMailList.content = angular.copy(response.data);
                        $scope.physicalMailList.content.map(function (email) {
                            email.selected = false;
                            return email;
                        });
                        $scope.physicalMailList.currentlyLoading = false;
                        renderTable();

                        if (callback) {
                            callback();
                        }
                    },
                    function (respone) {

                    }
                );
            }

            function changePage(increaseBy) {
                if (increaseBy === 1) {
                    if ($scope.physicalMailList.contentChunked.length - 1 > $scope.view.currentPage) {
                        $scope.view.currentPage += increaseBy;
                    }
                } else if (increaseBy === -1) {
                    if (0 < $scope.view.currentPage) {
                        $scope.view.currentPage += increaseBy;
                    }
                } else {
                    throw new RangeError("Valid values: -1 and 1");
                }
            }

            function toggleAllRowsInPage(status) {
                $scope.physicalMailList.content.forEach(function (email) {
                    email.selected = status;
                });
            }

            function updateApprovalStatusForSelection(status) {
                var selectedPhysicalMails = $scope.physicalMailList.contentChunked[$scope.view.currentPage].filter(function (physicalMail) {
                    return physicalMail.selected;
                });

                if (selectedPhysicalMails.length === 0) {
                    Notification.warning($filter("translate")("physicalMailApprovalList.NO_SELECTION"));
                    return;
                }

                toggleProcessingModal(true);
                $scope.view.currentlyLoading = true;

                var requestSummary = {
                    numRequestsPlanned: selectedPhysicalMails.length,
                    successfulRequests: [],
                    failedRequests: [],
                    allRequests: [],
                    withErrors: false
                }

                selectedPhysicalMails.forEach(function (physicalMail) {
                    if (status === "0") {
                        requestManager.routes.record.deleteWholeRecord($rootScope.authData.sessionId, physicalMail.recordId).then(
                            function (response) {
                                reload(function () {
                                    toggleProcessingModal(false, function () {
                                        Notification.success($filter("translate")("emailList.SUCCESSFUL_UPDATED"));
                                        $scope.view.currentlyLoading = false;
                                    });
                                });
                            },
                            function (response) {

                            }
                        )

                    } else {
                        let params = {
                            approved: status,
                            approvedBy: $rootScope.authData.userId,
                            id: physicalMail.recordPhysicalMailId
                        }
                        requestManager.routes.recordPhysicalMail.updatePhysicalMail($rootScope.authData.sessionId, params).then(onUpdateRecordPhysicalMailRequestFinished, onUpdateRecordPhysicalMailRequestFailed);
                    }
                });

                function onUpdateRecordPhysicalMailRequestFinished(response) {
                    requestSummary.successfulRequests.push(response);
                    requestSummary.allRequests.push(response);

                    if (requestSummary.allRequests.length === requestSummary.numRequestsPlanned) {
                        reload(function () {
                            toggleProcessingModal(false, function () {
                                Notification.success($filter("translate")("physicalMailApprovalList.SUCCESSFUL_UPDATED"));
                                $scope.view.currentlyLoading = false;
                            });
                        });
                    }
                }

                function onUpdateRecordPhysicalMailRequestFailed(response) {
                    requestSummary.failedRequests.push(response);
                    requestSummary.allRequests.push(response);

                    if (requestSummary.allRequests.length === requestSummary.numRequestsPlanned) {
                        reload(function () {
                            toggleProcessingModal(false, function () {
                                Notification.warning($filter("translate")("physicalMailApprovalList.UPDATED_WITH_ERRORS"));
                                $scope.view.currentlyLoading = false;
                            });
                        });
                    }
                }

                function showProcessingModal() {
                    jQuery("#processingModal").modal("show");
                }
            }

            function toggleProcessingModal(showModal, callback) {
                if (showModal) {
                    jQuery("#processingModal").modal("show");
                } else {
                    if (callback) {
                        jQuery("#processingModal").one("hidden.bs.modal", callback).modal("hide");
                    } else {
                        jQuery("#processingModal").modal("hide");
                    }
                }
            }

            function saveChangedSettings() {
                if ($scope.physicalMailApprovalListNumRows !== undefined && $scope.physicalMailApprovalListNumRows.edited) {
                    $scope.physicalMailApprovalListNumRows.value1 = $scope.view.itemsPerPageSelected;
                    sendEditSettingRequest($rootScope.authData.sessionId, $scope.physicalMailApprovalListNumRows, function (requestDetails) {
                        if (requestDetails.successful) {
                            Notification.success($filter("translate")("admonishedClientsList.SAVED_FILTER"));
                        } else {
                            sharedMemory.error.setError(
                                requestDetails,
                                "COULD_NOT_LOAD_DATA",
                                "Fehler beim automatischen speichern der userSettings (speichern von Filter im Kundenstamm)",
                                $location.path(),
                                "admonishedClientsList"
                            );
                            $location.path("/error-page");
                        }
                    });
                }
            }

            function sendEditSettingRequest(sessionId, setting, callback) {
                var requestDetails = {
                    successful: true,
                    responses: []
                };

                requestManager.routes.userSettings.editUserSetting(sessionId, setting.id, setting).then(
                    function(response) {
                        requestDetails.responses.push(response);
                        callback(requestDetails);
                    },
                    function(response) {
                        requestDetails.successful = false;
                        requestDetails.responses.push(response);
                        callback(requestDetails);
                    }
                )
            }
        }
    ]
}); 

angular.module('emailList', []);
angular.module('emailList').component('emailList', {
    templateUrl: 'app/components/emailList/emailList.template.html',
    bindings: {
        emailList: "<",
        customerAdvisorId: "<",
        assignedCustomersOnly: "<",
        numRowsSetting: "<"
    },
    controller: [
        "$scope",
        "$rootScope",
        "requestManager",
        "util",
        "Notification",
        "$filter",
        "$route",
        "$window",
        "sharedMemory",
        "$location",
        function ($scope, $rootScope, requestManager, util, Notification, $filter, $route, $window, sharedMemory, $location) {
            const ctrl = this;
            init();
            getCustomer();
            $scope.europeanNumberFormat = 'en-US';
            if ($rootScope.europeanNumberFormat !== undefined) {
                if ($rootScope.europeanNumberFormat === '1' || $rootScope.europeanNumberFormat === 1) {
                    $scope.europeanNumberFormat = 'de-AT';
                }
                else {
                    $scope.europeanNumberFormat = 'en-US';
                }
            }

            ctrl.$onChanges = function set(item) {
                if (item && item.emailList && item.emailList.currentValue) {
                    Object.values(item.emailList.currentValue).forEach(value => {
                        value.sumAmountDebitOverdue = +value.sumAmountDebitOverdue;
                    });
                    Object.values(item.emailList.currentValue).forEach(value => {
                        value.sumAmountDebit = +value.sumAmountDebit;
                    });
                    $scope.emailList.originalContent = angular.copy(item.emailList.currentValue);
                    $scope.emailList.content = angular.copy(item.emailList.currentValue);
                    $scope.emailList.currentlyLoading = false;
                    if (!$scope.userSettingsLoading) {
                        renderTable();
                    }
                }
                if (item && item.numRowsSetting && item.numRowsSetting.currentValue) {
                    $scope.emailApprovalListNumRows = item.numRowsSetting.currentValue;
                    $scope.view.itemsPerPageSelected = +item.numRowsSetting.currentValue.value1;

                    if (!$scope.view.itemsPerPageSelected) {
                        $scope.view.itemsPerPageSelected = $scope.itemsPerPageOptions[0];
                    }

                    if (!$scope.emailList.currentlyLoading) {
                        renderTable();
                    }
                    $scope.userSettingsLoading = false;
                }
                if (item && item.customerAdvisorId && item.customerAdvisorId.currentValue) {
                    $scope.customerAdvisorId = item.customerAdvisorId.currentValue;
                }
                if (item && item.assignedCustomersOnly && item.assignedCustomersOnly.currentValue) {
                    $scope.filterSettingId = item.assignedCustomersOnly.currentValue.id;
                    $scope.customerFilterSetting = item.assignedCustomersOnly.currentValue.value1;

                    if ($scope.customerFilterSetting === '1') {
                        $scope.filterActivated = true;
                        filterUnapprovedMails();
                    }
                }
                if (!$scope.emailList.currentlyLoading) {
                    $scope.view.currentlyLoading = false;
                }
            };

            function init() {
                initVariables();
                initScope();
            }

            function getCustomer() {
                if ($location.path().startsWith('/email-check')) {
                    if ($rootScope.settings === undefined) {
                        const interval = setInterval(() => {
                            if ($rootScope.settings !== undefined) {
                                const accountLengthImpersonal = $rootScope.settings.find(function (setting) {
                                    return setting.settingCode === 'accountLengthImpersonal';
                                });
                                if (accountLengthImpersonal !== undefined) {
                                    $scope.accountLengthImpersonal = +accountLengthImpersonal.settingValue1;
                                } else {
                                    $scope.accountLengthImpersonal = 8;
                                }
                                clearInterval(interval);
                            }
                        }, 100);
                    } else {
                        const accountLengthImpersonal = $rootScope.settings.find(function (setting) {
                            return setting.settingCode === 'accountLengthImpersonal';
                        });
                        if (accountLengthImpersonal !== undefined) {
                            $scope.accountLengthImpersonal = +accountLengthImpersonal.settingValue1;
                        } else {
                            $scope.accountLengthImpersonal = 8;
                        }
                    }
                }
            }

            function getColorCodeForEntryCreatedToTodayDifference(entryCreated) {
                var t = entryCreated.split(/[- :]/);
                var d = new Date(t[0], t[1] - 1, t[2], t[3], t[4], t[5]);
                var cur = new Date();
                var dif = Math.abs(cur - d);

                if (dif < 172800000) {
                    return '#ffffff';
                } else if (dif > 345600000) {
                    return '#f8d7da';
                } else if (dif > 172800000) {
                    return '#fff3cd';
                }
            }

            function getTooltipMessage(entryObject) {
                var t = entryObject.split(/[- :]/);
                var d = new Date(t[0], t[1] - 1, t[2], t[3], t[4], t[5]);
                var cur = new Date();
                var dif = Math.abs(cur - d);

                if (dif < 172800000) {
                    return;
                } else if (dif > 345600000) {
                    return 'Mahnung liegt schon über 4 Tage in der Freigabeliste!';
                } else if (dif > 172800000) {
                    return 'Mahnung liegt schon über 2 Tage aber weniger als 4 Tage in der Freigabeliste!';
                }
            }

            function initVariables() {
                $scope.emailList = {
                    currentlyLoading: true,
                    content: undefined,
                    contentChunked: undefined,
                    originalContent: undefined,
                    helperArray: undefined
                };
                $scope.itemsPerPageOptions = [10, 25, 50, 100];
                $scope.view = {
                    currentlyLoading: true,
                    itemsPerPageSelected: undefined,
                    currentPage: 0,
                    selectAllCheckbox: false
                };
                $scope.emailDetailsModal = {
                    email: undefined,
                    currentlyLoading: false
                }
                $scope.customerAdvisorId = undefined;
                $scope.customerFilterSetting = undefined;
                $scope.filterSettingId = undefined;
                $scope.filterActivated = false;
                $scope.ascending = true;
                $scope.type = 'recipientId';
                $scope.userSettingsLoading = true;
            }

            function initScope() {
                $scope.showEmailDetails = showEmailDetails;
                $scope.updateApprovalStatus = updateApprovalStatus;
                $scope.changePage = changePage;
                $scope.toggleAllRowsInPage = toggleAllRowsInPage;
                $scope.renderTable = renderTable;
                $scope.updateApprovalStatusForSelection = updateApprovalStatusForSelection;
                $scope.getColorCodeForEntryCreatedToTodayDifference = getColorCodeForEntryCreatedToTodayDifference;
                $scope.getTooltipMessage = getTooltipMessage;
                $scope.filterUnapprovedMails = filterUnapprovedMails;
                $scope.saveSettingParameterInBackend = saveSettingParameterInBackend;
                $scope.parseDate = parseDate;
                $scope.onRemoveTagClicked = onRemoveTagClicked;
                $scope.applyOrder = applyOrder;
                $scope.forwardToCustomer = forwardToCustomer;
                $scope.openInfoModal = openInfoModal;

                $scope.$on('$routeChangeStart', function() {
                    saveChangedSettings();
                });
            }

            function openInfoModal() {
                jQuery("#emailListInfoModal").modal("show");
            }

            function forwardToCustomer(recipientId) {
                let tabWindow = $window.open('about:blank', '_blank');
                tabWindow.document.write('loading...');
                let currentPath = $window.location.href.substr(0, window.location.href.indexOf('#') + 1);
                tabWindow.location.href = currentPath + "!/user-details/" + recipientId;
                tabWindow.location.reload();
                jQuery("#emailDetailsModal").one("hidden.bs.modal").modal("hide");
            }

            function onRemoveTagClicked(id, recipientArray, emailId) {
                if (recipientArray.length === 1) {
                    let confirmValue = confirm($filter("translate")("emailList.RECIPIENT_DELETE_CONFIRMATION_MESSAGE"));

                    if (confirmValue) {
                        requestManager.routes.record.deleteWholeRecord($rootScope.authData.sessionId, emailId).then(
                            function (response) {
                                Notification.success("E-Mail wurde erfolgreich entfernt!");
                                $route.reload();
                            },
                            function (response) {
                                console.log("Error trying to delete email record");
                            }
                        );
                    } else if (!confirmValue) {
                        return;
                    }
                } else {
                    requestManager.routes.recordEmail.virtual.deleteEmailRecipient($rootScope.authData.sessionId, id).then(
                        () => {
                            Notification.success("Empfänger wurde erfolgreich entfernt!");
                            $route.reload();
                        },
                        (error) => {
                            console.error("Error occured", error);
                        }
                    )
                }
            }

            function saveSettingParameterInBackend(property) {
                if ($scope.filterSettingId !== undefined) {
                    var paramsObject = {
                        value1: property,
                        value2: null,
                        value3: null
                    };

                    requestManager.routes.userSettings.editUserSetting($rootScope.authData.sessionId, $scope.filterSettingId, paramsObject);
                }
            }

            function renderTable() {
                $scope.emailList.content = angular.copy($scope.emailList.originalContent);
                let res = [];
                //for loop below does the same as lodash.values function
                for (let x in $scope.emailList.content) {
                    $scope.emailList.content.hasOwnProperty(x) && res.push($scope.emailList.content[x])
                }
                $scope.emailList.content = res;
                angular.forEach($scope.emailList.content, function (email) {
                    email.selected = false;
                });
                $scope.emailList.content = orderEmailListBy($scope.type, $scope.emailList.content);
                $scope.emailList.contentChunked = util.divideArrayIntoChunks($scope.emailList.content, $scope.view.itemsPerPageSelected);
                $scope.view.currentPage = 0;
            }

            function orderEmailListBy(type, emailList) {
                let sortedEmailList = emailList;
                switch (type) {
                    case 'recipientId':
                        sortedEmailList = emailList.sort((a, b) => a.recipientId - b.recipientId);
                        break;
                    case 'subject':
                        sortedEmailList = emailList.sort((a, b) => a.subject.localeCompare(b.subject));
                        break;
                    case 'recipient':
                        sortedEmailList = emailList.sort((a, b) => a.recipient.localeCompare(b.recipient));
                        break;
                    case 'sumAmountDebitOverdue':
                        sortedEmailList = emailList.sort((a, b) => a.sumAmountDebitOverdue - b.sumAmountDebitOverdue);
                        break;
                    case 'sumAmountDebit':
                        sortedEmailList = emailList.sort((a, b) => a.sumAmountDebit - b.sumAmountDebit);
                        break;
                    case 'recipientName':
                        sortedEmailList = emailList.sort((a, b) => a.recipientName.localeCompare(b.recipientName));
                        break;
                    case 'lastPaymentDate':
                        sortedEmailList = emailList.sort((a, b) => new Date(new Date(b.lastPaymentDate).getTime() - a.lastPaymentDate).getTime());
                        break;
                }

                return $scope.ascending ? sortedEmailList : sortedEmailList.reverse();
            }

            function applyOrder(type){
                if($scope.type === type){
                    $scope.ascending = !$scope.ascending;
                } else {
                    $scope.ascending = true;
                }
                $scope.type = type;
                renderTable();
            }

            function filterUnapprovedMails() {
                if ($scope.filterActivated && $scope.emailList.helperArray !== undefined) {
                    $scope.emailList.originalContent = $scope.emailList.helperArray;
                    $scope.filterActivated = false;
                    renderTable();
                    if ($scope.filterSettingId !== undefined) {
                        const params = {
                            value1: 0
                        };

                        requestManager.routes.userSettings.editUserSetting($rootScope.authData.sessionId, $scope.filterSettingId, params);
                    }
                } else {
                    const filteredArray = [];
                    angular.forEach($scope.emailList.originalContent, (mail) => {
                        if (mail.customerAdvisorId === $scope.customerAdvisorId) {
                            filteredArray.push(mail);
                        }
                    });
                    $scope.emailList.helperArray = $scope.emailList.originalContent;
                    $scope.emailList.originalContent = filteredArray;
                    $scope.filterActivated = true;
                    renderTable();

                    if ($scope.filterSettingId !== undefined) {
                        const object = {
                            value1: 1
                        };

                        requestManager.routes.userSettings.editUserSetting($rootScope.authData.sessionId, $scope.filterSettingId, object);
                    }
                }
            }

            function updateApprovalStatus(status, emailId) {
                toggleProcessingModal(true);
                $scope.view.currentlyLoading = true;

                let params = {
                    approved: status,
                    id: emailId,
                    approvedBy: $rootScope.authData.userId
                };

                if (status === "0") {
                    requestManager.routes.record.deleteWholeRecord($rootScope.authData.sessionId, emailId).then(
                        function (response) {
                            reload(function () {
                                toggleProcessingModal(false, function () {
                                    Notification.success($filter("translate")("emailList.SUCCESSFUL_UPDATED"));
                                    $scope.view.currentlyLoading = false;
                                });
                            });
                        },
                        function (response) {

                        }
                    )

                } else {
                    requestManager.routes.recordEmail.updateRecordEmail($rootScope.authData.sessionId, params).then(
                        function (response) {
                            reload(function () {
                                toggleProcessingModal(false, function () {
                                    Notification.success($filter("translate")("emailList.SUCCESSFUL_UPDATED"));
                                    $scope.view.currentlyLoading = false;
                                });
                            });
                        },
                        function (response) {

                        }
                    );
                }
            }

            function showEmailDetails(email) {
                $scope.emailDetailsModal.email = email;
                jQuery("#emailDetailsModal").modal("show");
            }

            function reload(callback) {
                requestManager.routes.recordEmail.virtual.getUnapprovedEmails($rootScope.authData.sessionId).then(
                    function (response) {
                        $scope.emailList.originalContent = angular.copy(response.data);
                        $scope.emailList.content = angular.copy(response.data);
                        //$scope.emailList.content.map(function(email){ email.selected = false; return email;});
                        angular.forEach($scope.emailList.content, function (email) {
                            email.selected = false;
                        });
                        $scope.emailList.currentlyLoading = false;

                        renderTable();
                        if (callback) {
                            callback();
                        }
                    },
                    function (response) {

                    }
                );
            }

            function changePage(increaseBy) {
                if (increaseBy === 1) {
                    if ($scope.emailList.contentChunked.length - 1 > $scope.view.currentPage) {
                        $scope.view.currentPage += increaseBy;
                    }
                } else if (increaseBy === -1) {
                    if (0 < $scope.view.currentPage) {
                        $scope.view.currentPage += increaseBy;
                    }
                } else {
                    throw new RangeError("Valid values: -1 and 1");
                }
            }

            function toggleAllRowsInPage(status) {
                $scope.emailList.content.forEach(function (email) {
                    email.selected = status;
                });
            }

            function updateApprovalStatusForSelection(status) {
                var selectedEmails = $scope.emailList.contentChunked[$scope.view.currentPage].filter(function (email) {
                    return email.selected;
                });
                if (selectedEmails.length === 0) {
                    Notification.warning($filter("translate")("emailList.NO_SELECTION"));
                    return;
                }

                toggleProcessingModal(true);
                $scope.view.currentlyLoading = true;

                var requestSummary = {
                    numRequestsPlanned: selectedEmails.length,
                    successfulRequests: [],
                    failedRequests: [],
                    allRequests: [],
                    withErrors: false
                };

                selectedEmails.forEach(function (email) {
                    /**
                     let params = {
                        approved: status,
                        approvedBy: $rootScope.authData.userId,
                        id: email.recordEmailId
                    }
                     requestManager.routes.recordEmail.updateRecordEmail($rootScope.authData.sessionId, params).then(onUpdateRecordEmailRequestFinished, onUpdateRecordEmailRequestFailed);
                     */
                    if (status == "0") {
                        updateApprovalStatus(status, email.recordId);
                    } else {
                        updateApprovalStatus(status, email.recordEmailId);
                    }
                });


                function onUpdateRecordEmailRequestFinished(response) {
                    requestSummary.successfulRequests.push(response);
                    requestSummary.allRequests.push(response);

                    if (requestSummary.allRequests.length === requestSummary.numRequestsPlanned) {
                        reload(function () {
                            toggleProcessingModal(false, function () {
                                Notification.success($filter("translate")("emailList.SUCCESSFUL_UPDATED"));
                                $scope.view.currentlyLoading = false;
                            });
                        });
                    }
                }

                function onUpdateRecordEmailRequestFailed(response) {
                    requestSummary.failedRequests.push(response);
                    requestSummary.allRequests.push(response);

                    if (requestSummary.allRequests.length === requestSummary.numRequestsPlanned) {
                        reload(function () {
                            toggleProcessingModal(false, function () {
                                Notification.warning($filter("translate")("emailList.UPDATED_WITH_ERRORS"));
                                $scope.view.currentlyLoading = false;
                            });
                        });
                    }
                }

                function showProcessingModal() {
                    jQuery("#processingModal").modal("show");
                }
            }

            function toggleProcessingModal(showModal, callback) {
                if (showModal) {
                    jQuery("#processingModal").modal("show");
                } else {
                    if (callback) {
                        jQuery("#processingModal").one("hidden.bs.modal", callback).modal("hide");
                    } else {
                        jQuery("#processingModal").modal("hide");
                    }
                }
            }

            function parseDate(dateObject) {
                if (dateObject === null) {
                    return "Noch nie";
                }
                var inputDateFormat = "YYYY-MM-DD HH:mm:ss";
                var outputDateFormat = "DD.MM.YYYY";
                var date = moment(dateObject, inputDateFormat);
                return date.format(outputDateFormat);
            }

            function saveChangedSettings() {
                if ($scope.emailApprovalListNumRows !== undefined && $scope.emailApprovalListNumRows.edited) {
                    $scope.emailApprovalListNumRows.value1 = $scope.view.itemsPerPageSelected;
                    sendEditSettingRequest($rootScope.authData.sessionId, $scope.emailApprovalListNumRows, function (requestDetails){
                        if(requestDetails.successful){
                            Notification.success($filter("translate")("admonishedClientsList.SAVED_FILTER"));
                        }
                        else{
                            sharedMemory.error.setError(
                                requestDetails,
                                "COULD_NOT_LOAD_DATA",
                                "Fehler beim automatischen speichern der userSettings (speichern von Filter im Kundenstamm)",
                                $location.path(),
                                "admonishedClientsList"
                            );
                            $location.path("/error-page");
                        }
                    });
                }
            }

            function sendEditSettingRequest(sessionId, setting, callback) {
                var requestDetails = {
                    successful: true,
                    responses: []
                };

                requestManager.routes.userSettings.editUserSetting(sessionId, setting.id, setting).then(
                    function(response) {
                        requestDetails.responses.push(response);
                        callback(requestDetails);
                    },
                    function(response) {
                        requestDetails.successful = false;
                        requestDetails.responses.push(response);
                        callback(requestDetails);
                    }
                )
            }

        }
    ]
}); 

angular.module('physicalMailApprovalListV2', []);

angular.module('physicalMailApprovalListV2').component('physicalMailApprovalListV2', {
    templateUrl: 'app/components/physicalMailApprovalListV2/physicalMailApprovalListV2.template.html',
    controller: [
        "$scope",
        "$rootScope",
        "requestManager",
        "util",
        "Notification",
        "$filter",
        "$route",
        "$window",
        "sharedMemory",
        "$location",
        function ($scope, $rootScope, requestManager, util, Notification, $filter, $route, $window, sharedMemory, $location) {
            this.$onInit = function() {
                $scope.europeanNumberFormat = 'en-US';
                if ($rootScope.europeanNumberFormat !== undefined) {
                    if ($rootScope.europeanNumberFormat === '1' || $rootScope.europeanNumberFormat === 1) {
                        $scope.europeanNumberFormat = 'de-AT';
                    }
                    else {
                        $scope.europeanNumberFormat = 'en-US';
                    }
                }
                initVariables();
                initScope();

                $scope.requestParams = $location.search();
                if (Object.values($scope.requestParams).length > 0) {
                    if ($scope.requestParams.page) {
                        if (parseInt($scope.requestParams.page, 10)) {
                            $scope.filters.page = parseInt($scope.requestParams.page, 10);
                        }
                    }
                    if ($scope.requestParams.search) {
                        $scope.filters.searchTerm = $scope.requestParams.search;
                    }
                    if ($scope.requestParams.filter) {
                        const filters = $scope.requestParams.filter.split(' and ');
                        const index = filters.indexOf('accountsReceivableSum gt 0');
                        if (index > -1) {
                            filters.splice(index, 1);
                        }
                        // console.log(filters);
                        filters.forEach(filter => {
                            const parts = filter.split(' ');
                            const key = parts[0];
                            const op = parts[1];
                            const value = parts[2];
                            if (key && op && value && $scope[key]) {
                                // console.log(key, op, value, 'type = ' + $scope[key].type);
                                $scope[key].op = op === 'eq' ? 'equals' : op;
                                if ($scope[key].type === 'float') {
                                    if (!isNaN(parseFloat(value))) {
                                        $scope[key].value = parseFloat(value);
                                    }
                                } else if ($scope[key].type === 'number') {
                                    if (!isNaN(parseInt(value))) {
                                        $scope[key].value = parseInt(value);
                                    }
                                } else if ($scope[key].type === 'text-boolean') {
                                    // console.log(value);
                                    if (value === '1') {
                                        $scope[key].value = 1;
                                    }
                                    if (value === '0') {
                                        $scope[key].value = 0;
                                    }
                                } else if ($scope[key].type === 'date') {
                                    if (value) {
                                        $scope[key].value = new Date(value);
                                    }

                                } else {
                                    if (key === 'customerAdvisorId' && value) {
                                        $scope.filterCustomerAdvisorId = true;
                                        $scope.customerAdvisorId.value = value;
                                    }
                                    if (key === 'partnerId') {
                                        $scope.filterPartnerId = true;
                                        $scope.partnerId.value = value;
                                    }
                                    $scope[key].value = value;
                                }
                                $scope.filterArray.push(`${key} ${op} ${value}`);
                            } else {
                                if (key === 'isProblematic' && value === '1') {
                                    $scope.filterIsProblematic = true;
                                    $scope.filterArray.push(`${key} ${op} ${value}`);
                                }
                            }
                        });
                        $scope.filters.filter = $scope.requestParams.filter;
                    }
                }

                v2getUserSettings(() => callAfterGettingUserSettings());
            };

            function initVariables() {
                $scope.userSettings = [];
                $scope.userSettingsJson = {};
                $scope.userSettingsLoading = true;
                $scope.physicalMails = [];
                $scope.loading = true;
                $scope.numShowEntriesOptions = [10, 20];
                $scope.filterAssignedToMe = false;
                $scope.paginator = {
                    items: [],
                    itemsPerPage: null,
                    itemsTotal: null,
                    pageCurrent: null,
                    pagesTotal: null,
                };
                $scope.filters = {
                    page: 1,
                    pageSize: 10,
                    order: "entryCreated",
                    direction: "desc",
                    filter: "recordType eq record_physical_mail and physicalMailApproved eq 0",
                };
                $scope.physicalMailDetailsModal = null;
                $scope.selectAllCheckbox = false;
            }

            function initScope() {
                $scope.changeNumShowEntries = changeNumShowEntries;
                $scope.sortBy = sortBy;
                $scope.nextPage = nextPage;
                $scope.previousPage = previousPage;
                $scope.firstPage = firstPage;
                $scope.lastPage = lastPage;
                $scope.checkAllPhysicalMails = checkAllPhysicalMails;
                $scope.checkOnePhysicalMail = checkOnePhysicalMail;
                $scope.openDetailsModal = openDetailsModal;
                $scope.showProcessingModal = showProcessingModal;
                $scope.assignedToMe = assignedToMe;
                $scope.approveMore = approveMore;
                $scope.approveOne = approveOne;
                $scope.openInfoModal = openInfoModal;
                $scope.showPdf = showPdf;
                $scope.parseDate = parseDate;
                $scope.applyOrder = applyOrder;
                $scope.forwardToCustomer = forwardToCustomer;
            }

            /* ---------- http requests ---------- */

            function v2getUserSettings(callback) {
                $scope.userSettingsLoading = true;
                requestManager.routes.user.getSettingsOfUser($rootScope.authData.sessionId, $rootScope.authData.userId).then(
                    function(response){
                        $scope.userSettings = response.data;
                        $scope.userSettingsJson = getUserSettingsJson($scope.userSettings);
                        // apply the necessary user settings
                        if ($scope.userSettingsJson.physicalMailApprovalListNumRows) {
                            if ($scope.userSettingsJson.physicalMailApprovalListNumRows.value1 !== '10' && $scope.userSettingsJson.physicalMailApprovalListNumRows.value1 !== '20') {
                                updateUserSetting($scope.userSettingsJson.physicalMailApprovalListNumRows.id, {value1: '10'});
                                $scope.userSettingsJson.physicalMailApprovalListNumRows.value1 = '10';
                            }
                            $scope.filters.pageSize = +$scope.userSettingsJson.physicalMailApprovalListNumRows.value1;
                        }
                        $scope.userSettingsLoading = false;
                        callback();
                    },
                    function(){
                    }
                );

                function getUserSettingsJson(settings) {
                    const listSettings = {};
                    settings.forEach(function(setting) {
                        setting.edited = false;
                        listSettings[setting.settingCode] = setting;
                    });
                    return listSettings;
                }
            }

            function callAfterGettingUserSettings() {
                getPhysicalMails();
            }

            function getPhysicalMails() {
                $scope.loading = true;
                requestManager.routes.record.getUnapprovedRecords($rootScope.authData.sessionId, $scope.filters).then(
                    function (response) {
                        $scope.paginator = response.data;
                        $scope.physicalMails = $scope.paginator.items;
                        $scope.loading = false;
                    },
                    function () {
                    }
                );
            }

            function updateUserSetting(id, values) {
                if (!id || !values) {
                    return;
                }
                const params = {
                    value1: values.value1,
                    value2: values.value2,
                    value3: values.value3
                };
                requestManager.routes.userSettings.editUserSetting($rootScope.authData.sessionId, id, params).then(
                    function () {
                    },
                    function () {
                    }
                );
            }

            /* ---------- filters ---------- */

            function changeNumShowEntries() {
                $scope.filters.page = 1;
                getPhysicalMails();
                updateUserSetting($scope.userSettingsJson.physicalMailApprovalListNumRows.id, {value1: $scope.filters.pageSize});
            }

            function assignedToMe() {
                $scope.filters.page = 1;
                $scope.filterAssignedToMe = !$scope.filterAssignedToMe;
                // appendRequestParam('page', 1);
                const myId = $rootScope.authData.userId;
                if ($scope.filterAssignedToMe) {
                    // TODO
                    // $scope.filters.filter = `recordType eq record_physical_mail and physicalMailApproved eq 0 eq 0 and customerAdvisorId eq ${myId}`;
                    $scope.filters.filter = `recordType eq record_physical_mail and physicalMailApproved eq 0`;
                } else {
                    $scope.filters.filter = "recordType eq record_physical_mail and physicalMailApproved eq 0";
                }
                getPhysicalMails();
            }

            function sortBy(value) {
                if (value === $scope.filters.order) {
                    $scope.filters.direction = $scope.filters.direction === 'desc' ? 'asc' : 'desc';
                } else {
                    $scope.filters.order = value;
                    $scope.filters.direction = 'asc';
                }
                getPhysicalMails();
            }

            /* ---------- pagination ---------- */

            function firstPage() {
                $scope.filters.page = 1;
                getPhysicalMails();
            }

            function lastPage() {
                $scope.filters.page = $scope.paginator.pagesTotal;
                getPhysicalMails();
            }

            function nextPage() {
                $scope.filters.page++;
                getPhysicalMails();
            }

            function previousPage() {
                $scope.filters.page--;
                getPhysicalMails();
            }

            /* ---------- v2 ---------- */

            function checkAllPhysicalMails(status) {
                $scope.physicalMails.forEach(physicalMail => {
                    physicalMail.selected = status;
                });
            }

            function checkOnePhysicalMail() {
                $scope.selectAllCheckbox = $scope.physicalMails.every(item => item.selected === true);
            }

            function openDetailsModal(physicalMail) {
                // $scope.physicalMailDetailsModal = physicalMail;
                // jQuery("#physicalMailDetailsModal").modal("show");
            }

            function openInfoModal() {
                jQuery("#physicalMailApprovalListInfoModal").modal("show");
            }

            function showProcessingModal(mode, callback = false) {
                if (mode) {
                    jQuery("#processingModal").modal("show");
                } else {
                    if (callback) {
                        jQuery("#processingModal").one("hidden.bs.modal", callback).modal("hide");
                    } else {
                        jQuery("#processingModal").modal("hide");
                    }
                }
            }

            function approveMore(status) {
                const selectedRecords = $scope.physicalMails.filter(physicalMail => physicalMail.selected);
                if (selectedRecords.length === 0) {
                    return;
                }
                showProcessingModal(true);
                if (status) {
                    let totalFinishedRequests = 0;
                    selectedRecords.forEach(record => {
                        let params = {
                            approved: status,
                            id: record.physicalMailId,
                            approvedBy: $rootScope.authData.userId
                        };
                        requestManager.routes.recordEmail.updateRecordPhysicalMail($rootScope.authData.sessionId, params).then(
                            function () {
                                totalFinishedRequests++;
                                if (selectedRecords.length === totalFinishedRequests) {
                                    showProcessingModal(false, function () {
                                        Notification.success($filter("translate")("physicalMailApprovalList.SUCCESSFUL_UPDATED"));
                                        $rootScope.amountUnapprovedDunnings -= totalFinishedRequests;
                                        getPhysicalMails();
                                    });
                                }
                            },
                            function () {
                            }
                        );
                    });
                } else {
                    let totalFinishedRequests = 0;
                    selectedRecords.forEach(record => {
                        requestManager.routes.record.deleteWholeRecord($rootScope.authData.sessionId, record.id).then(
                            function () {
                                totalFinishedRequests++;
                                if (selectedRecords.length === totalFinishedRequests) {
                                    showProcessingModal(false, function () {
                                        Notification.success($filter("translate")("physicalMailApprovalList.SUCCESSFUL_UPDATED"));
                                        $rootScope.amountUnapprovedDunnings -= totalFinishedRequests;
                                        getPhysicalMails();
                                    });
                                }
                            },
                            function () {
                            }
                        );
                    });
                }
            }

            function approveOne(status, record) {
                showProcessingModal(true);
                let params = {
                    approved: status,
                    id: record.physicalMailId,
                    approvedBy: $rootScope.authData.userId
                };
                if (status) {
                    requestManager.routes.recordEmail.updateRecordPhysicalMail($rootScope.authData.sessionId, params).then(
                        function () {
                            showProcessingModal(false, function () {
                                Notification.success($filter("translate")("physicalMailApprovalList.SUCCESSFUL_UPDATED"));
                                $rootScope.amountUnapprovedDunnings -= 1;
                                getPhysicalMails();
                            });
                        },
                        function () {
                        }
                    );
                } else {
                    requestManager.routes.record.deleteWholeRecord($rootScope.authData.sessionId, record.id).then(
                        function () {
                            showProcessingModal(false, function () {
                                Notification.success($filter("translate")("physicalMailApprovalList.SUCCESSFUL_UPDATED"));
                                $rootScope.amountUnapprovedDunnings -= 1;
                                getPhysicalMails();
                            });
                        },
                        function () {
                        }
                    )
                }
            }

            /* -------------------------------------------------------------------------------- */

            function forwardToCustomer(recipientId) {
                let tabWindow = $window.open('about:blank', '_blank');
                tabWindow.document.write('loading...');
                let currentPath = $window.location.href.substr(0, window.location.href.indexOf('#') + 1);
                tabWindow.location.href = currentPath + "!/user-details/" + recipientId;
                tabWindow.location.reload();
                jQuery("#physicalMailDetailsModal").one("hidden.bs.modal").modal("hide");
            }

            function orderPhysicalMailListBy(type, physicalMailList) {
                let sortedPhysicalMailList = physicalMailList;
                switch (type) {
                    case 'recipientId':
                        sortedPhysicalMailList = physicalMailList.sort((a, b) => a.recipientId - b.recipientId);
                        break;
                    case 'subject':
                        sortedPhysicalMailList = physicalMailList.sort((a, b) => a.subject.localeCompare(b.subject));
                        break;
                    case 'recipient':
                        sortedPhysicalMailList = physicalMailList.sort((a, b) => a.recipient.localeCompare(b.recipient));
                        break;
                    case 'sumAmountDebitOverdue':
                        sortedPhysicalMailList = physicalMailList.sort((a, b) => a.sumAmountDebitOverdue - b.sumAmountDebitOverdue);
                        break;
                    case 'sumAmountDebit':
                        sortedPhysicalMailList = physicalMailList.sort((a, b) => a.sumAmountDebit - b.sumAmountDebit);
                        break;
                    case 'recipientName':
                        sortedPhysicalMailList = physicalMailList.sort((a, b) => a.recipientName.localeCompare(b.recipientName));
                        break;
                    case 'lastPaymentDate':
                        sortedPhysicalMailList = physicalMailList.sort((a, b) => new Date(new Date(b.lastPaymentDate).getTime() - a.lastPaymentDate).getTime());
                        break;
                }

                return $scope.ascending ? sortedPhysicalMailList : sortedPhysicalMailList.reverse();
            }

            function applyOrder(type){
                if($scope.type === type){
                    $scope.ascending = !$scope.ascending;
                } else {
                    $scope.ascending = true;
                }
                $scope.type = type;
            }

            function parseDate(dateObject) {
                if (dateObject === null) {
                    return "Noch nie";
                }
                var inputDateFormat = "YYYY-MM-DD HH:mm:ss";
                var outputDateFormat = "DD.MM.YYYY";
                var date = moment(dateObject, inputDateFormat);
                return date.format(outputDateFormat);
            }


            function showPdf(email) {
                //check what browser the user is surfing on
                var ua = window.navigator.userAgent;
                var msie = ua.indexOf("MSIE ");
                var IEfound = false;

                if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./))  // If Internet Explorer
                {
                    IEfound = true;
                }

                if (!IEfound) {
                    var tabWindow = $window.open('about:blank', '_blank');
                    tabWindow.document.write('loading...');
                }

                requestManager.routes.recordPhysicalMail.getPhysicalMailPdf($rootScope.authData.sessionId, email.physicalMailId).then(
                    response => {

                        var notificationReference = Notification.warning({
                            message: $filter("translate")("wrapper.LOADING_PDF"),
                            delay: null,
                            closeOnClick: false,
                        });

                        var file = new Blob([response.data], {type: 'application/pdf'});
                        var fileURL = URL.createObjectURL(file);

                        tabWindow.location.href = fileURL;

                        notificationReference.then(function (notification) {
                            notification.kill(false);
                        });
                    }
                );
            }

        }
    ]
}); 

angular.module('emailListV2', []);

angular.module('emailListV2').component('emailListV2', {
    templateUrl: 'app/components/emailListV2/emailListV2.template.html',
    controller: [
        "$scope",
        "$rootScope",
        "requestManager",
        "util",
        "Notification",
        "$filter",
        "$route",
        "$window",
        "sharedMemory",
        "$location",
        function ($scope, $rootScope, requestManager, util, Notification, $filter, $route, $window, sharedMemory, $location) {
            this.$onInit = function() {
                $scope.europeanNumberFormat = 'en-US';
                if ($rootScope.europeanNumberFormat !== undefined) {
                    if ($rootScope.europeanNumberFormat === '1' || $rootScope.europeanNumberFormat === 1) {
                        $scope.europeanNumberFormat = 'de-AT';
                    }
                    else {
                        $scope.europeanNumberFormat = 'en-US';
                    }
                }
                initVariables();
                initScope();
                v2getUserSettings(() => callAfterGettingUserSettings());
            };

            function initVariables() {
                $scope.userSettings = [];
                $scope.userSettingsJson = {};
                $scope.userSettingsLoading = true;
                $scope.emails = [];
                $scope.loading = true;
                $scope.numShowEntriesOptions = [10, 20];
                $scope.filterAssignedToMe = false;
                $scope.paginator = {
                    items: [],
                    itemsPerPage: null,
                    itemsTotal: null,
                    pageCurrent: null,
                    pagesTotal: null,
                };
                $scope.filters = {
                    page: 1,
                    pageSize: 10,
                    order: "entryCreated",
                    direction: "desc",
                    filter: "recordType eq record_email and emailApproved eq 0",
                };
                $scope.emailDetailsModal = null;
                $scope.selectAllCheckbox = false;
            }

            function initScope() {
                $scope.changeNumShowEntries = changeNumShowEntries;
                $scope.sortBy = sortBy;
                $scope.nextPage = nextPage;
                $scope.previousPage = previousPage;
                $scope.firstPage = firstPage;
                $scope.lastPage = lastPage;
                $scope.checkAllEmails = checkAllEmails;
                $scope.checkOneEmail = checkOneEmail;
                $scope.openDetailsModal = openDetailsModal;
                $scope.showProcessingModal = showProcessingModal;
                $scope.assignedToMe = assignedToMe;
                $scope.approveMore = approveMore;
                $scope.approveOne = approveOne;
                $scope.openInfoModal = openInfoModal;
                $scope.openAttachments = openAttachments;

                $scope.parseDate = parseDate;
                $scope.onRemoveTagClicked = onRemoveTagClicked;
                $scope.applyOrder = applyOrder;
                $scope.forwardToCustomer = forwardToCustomer;
            }

            /* ---------- http requests ---------- */

            function v2getUserSettings(callback) {
                $scope.userSettingsLoading = true;
                requestManager.routes.user.getSettingsOfUser($rootScope.authData.sessionId, $rootScope.authData.userId).then(
                    function(response){
                        $scope.userSettings = response.data;
                        $scope.userSettingsJson = getUserSettingsJson($scope.userSettings);
                        // apply the necessary user settings
                        if ($scope.userSettingsJson.emailApprovalListNumRows) {
                            if ($scope.userSettingsJson.emailApprovalListNumRows.value1 !== '10' && $scope.userSettingsJson.emailApprovalListNumRows.value1 !== '20') {
                                updateUserSetting($scope.userSettingsJson.emailApprovalListNumRows.id, {value1: '10'});
                                $scope.userSettingsJson.emailApprovalListNumRows.value1 = '10';
                            }
                            $scope.filters.pageSize = +$scope.userSettingsJson.emailApprovalListNumRows.value1;
                        }
                        $scope.userSettingsLoading = false;
                        callback();
                    },
                    function(){
                    }
                );

                function getUserSettingsJson(settings) {
                    const listSettings = {};
                    settings.forEach(function(setting) {
                        setting.edited = false;
                        listSettings[setting.settingCode] = setting;
                    });
                    return listSettings;
                }
            }

            function callAfterGettingUserSettings() {
                getEmails();
            }

            function getEmails() {
                $scope.loading = true;
                requestManager.routes.record.getUnapprovedRecords($rootScope.authData.sessionId, $scope.filters).then(
                    function (response) {
                        $scope.paginator = response.data;
                        $scope.emails = $scope.paginator.items;
                        $scope.loading = false;
                    },
                    function () {
                    }
                );
            }

            function updateUserSetting(id, values) {
                if (!id || !values) {
                    return;
                }
                const params = {
                    value1: values.value1,
                    value2: values.value2,
                    value3: values.value3
                };
                requestManager.routes.userSettings.editUserSetting($rootScope.authData.sessionId, id, params).then(
                    function () {
                    },
                    function () {
                    }
                );
            }

            /* ---------- filters ---------- */

            function changeNumShowEntries() {
                $scope.filters.page = 1;
                getEmails();
                updateUserSetting($scope.userSettingsJson.emailApprovalListNumRows.id, {value1: $scope.filters.pageSize});
            }

            function assignedToMe() {
                $scope.filters.page = 1;
                $scope.filterAssignedToMe = !$scope.filterAssignedToMe;
                // appendRequestParam('page', 1);
                const myId = $rootScope.authData.userId;
                if ($scope.filterAssignedToMe) {
                    // TODO
                    // $scope.filters.filter = `recordType eq record_email and emailApproved eq 0 and customerAdvisorId eq ${myId}`;
                    // $scope.filters.filter = `recordType eq record_email and emailApproved eq 0 and userDidEntry eq ${myId}`;
                    $scope.filters.filter = `recordType eq record_email and emailApproved eq 0`;
                } else {
                    $scope.filters.filter = "recordType eq record_email and emailApproved eq 0";
                }
                getEmails();
            }

            function sortBy(value) {
                if (value === $scope.filters.order) {
                    $scope.filters.direction = $scope.filters.direction === 'desc' ? 'asc' : 'desc';
                } else {
                    $scope.filters.order = value;
                    $scope.filters.direction = 'asc';
                }
                getEmails();
            }

            function appendRequestParam(param, value) {
                $location.search(param, value);
            }

            /* ---------- pagination ---------- */

            function firstPage() {
                $scope.filters.page = 1;
                getEmails();
            }

            function lastPage() {
                $scope.filters.page = $scope.paginator.pagesTotal;
                getEmails();
            }

            function nextPage() {
                $scope.filters.page++;
                getEmails();
            }

            function previousPage() {
                $scope.filters.page--;
                getEmails();
            }

            /* ---------- v2 ---------- */

            function checkAllEmails(status) {
                $scope.emails.forEach(email => {
                    email.selected = status;
                });
            }

            function checkOneEmail() {
                $scope.selectAllCheckbox = $scope.emails.every(item => item.selected === true);
            }

            function openDetailsModal(email) {
                $scope.emailDetailsModal = email;
                jQuery("#emailDetailsModal").modal("show");
            }

            function openInfoModal() {
                jQuery("#emailListInfoModal").modal("show");
            }

            function openAttachments(email) {
                //check what browser the user is surfing on
                var ua = window.navigator.userAgent;
                var msie = ua.indexOf("MSIE ");
                var IEfound = false;

                if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./))  // If Internet Explorer
                {
                    IEfound = true;
                }

                if (!IEfound) {
                    var tabWindow = $window.open('about:blank', '_blank');
                    tabWindow.document.write('loading...');
                }

                requestManager.routes.recordPhysicalMail.getPhysicalMailPdf($rootScope.authData.sessionId, email.emailId).then(
                    response => {

                        var notificationReference = Notification.warning({
                            message: $filter("translate")("wrapper.LOADING_PDF"),
                            delay: null,
                            closeOnClick: false,
                        });

                        var file = new Blob([response.data], {type: 'application/pdf'});
                        var fileURL = URL.createObjectURL(file);

                        tabWindow.location.href = fileURL;

                        notificationReference.then(function (notification) {
                            notification.kill(false);
                        });
                    }
                );
            }

            function showProcessingModal(mode, callback = false) {
                if (mode) {
                    jQuery("#processingModal").modal("show");
                } else {
                    if (callback) {
                        jQuery("#processingModal").one("hidden.bs.modal", callback).modal("hide");
                    } else {
                        jQuery("#processingModal").modal("hide");
                    }
                }
            }

            function approveMore(status) {
                const selectedRecords = $scope.emails.filter(email => email.selected);
                if (selectedRecords.length === 0) {
                    return;
                }
                showProcessingModal(true);
                if (status) {
                    let totalFinishedRequests = 0;
                    selectedRecords.forEach(record => {
                        let params = {
                            approved: status,
                            id: record.emailId,
                            approvedBy: $rootScope.authData.userId
                        };
                        requestManager.routes.recordEmail.updateRecordEmail($rootScope.authData.sessionId, params).then(
                            function () {
                                totalFinishedRequests++;
                                if (selectedRecords.length === totalFinishedRequests) {
                                    showProcessingModal(false, function () {
                                        Notification.success($filter("translate")("emailList.SUCCESSFUL_UPDATED"));
                                        $rootScope.amountUnapprovedDunnings -= totalFinishedRequests;
                                        getEmails();
                                    });
                                }
                            },
                            function () {
                            }
                        );
                    });
                } else {
                    let totalFinishedRequests = 0;
                    selectedRecords.forEach(record => {
                        requestManager.routes.record.deleteWholeRecord($rootScope.authData.sessionId, record.id).then(
                            function () {
                                totalFinishedRequests++;
                                if (selectedRecords.length === totalFinishedRequests) {
                                    showProcessingModal(false, function () {
                                        Notification.success($filter("translate")("emailList.SUCCESSFUL_UPDATED"));
                                        $rootScope.amountUnapprovedDunnings -= totalFinishedRequests;
                                        getEmails();
                                    });
                                }
                            },
                            function () {
                            }
                        );
                    });
                }
            }

            function approveOne(status, record) {
                showProcessingModal(true);
                let params = {
                    approved: status,
                    id: record.emailId,
                    approvedBy: $rootScope.authData.userId
                };
                if (status) {
                    requestManager.routes.recordEmail.updateRecordEmail($rootScope.authData.sessionId, params).then(
                        function () {
                            showProcessingModal(false, function () {
                                Notification.success($filter("translate")("emailList.SUCCESSFUL_UPDATED"));
                                $rootScope.amountUnapprovedDunnings -= 1;
                                getEmails();
                            });
                        },
                        function () {
                        }
                    );
                } else {
                    requestManager.routes.record.deleteWholeRecord($rootScope.authData.sessionId, record.id).then(
                        function () {
                            showProcessingModal(false, function () {
                                Notification.success($filter("translate")("emailList.SUCCESSFUL_UPDATED"));
                                $rootScope.amountUnapprovedDunnings -= 1;
                                getEmails();
                            });
                        },
                        function () {
                        }
                    )
                }
            }

            /* -------------------------------------------------------------------------------- */

            function forwardToCustomer(recipientId) {
                let tabWindow = $window.open('about:blank', '_blank');
                tabWindow.document.write('loading...');
                let currentPath = $window.location.href.substr(0, window.location.href.indexOf('#') + 1);
                tabWindow.location.href = currentPath + "!/user-details/" + recipientId;
                tabWindow.location.reload();
                jQuery("#emailDetailsModal").one("hidden.bs.modal").modal("hide");
            }

            function onRemoveTagClicked(id, recipientArray, emailId) {
                console.log(id, recipientArray, emailId);
                if (recipientArray.length === 1) {
                    let confirmValue = confirm($filter("translate")("emailList.RECIPIENT_DELETE_CONFIRMATION_MESSAGE"));

                    if (confirmValue) {
                        requestManager.routes.record.deleteWholeRecord($rootScope.authData.sessionId, emailId).then(
                            function (response) {
                                Notification.success("E-Mail wurde erfolgreich entfernt!");
                                $route.reload();
                            },
                            function (response) {
                                console.log("Error trying to delete email record");
                            }
                        );
                    } else if (!confirmValue) {
                        return;
                    }
                } else {
                    requestManager.routes.recordEmail.virtual.deleteEmailRecipient($rootScope.authData.sessionId, id).then(
                        () => {
                            Notification.success("Empfänger wurde erfolgreich entfernt!");
                            $route.reload();
                        },
                        (error) => {
                            console.error("Error occured", error);
                        }
                    )
                }
            }

            function orderEmailListBy(type, emailList) {
                let sortedEmailList = emailList;
                switch (type) {
                    case 'recipientId':
                        sortedEmailList = emailList.sort((a, b) => a.recipientId - b.recipientId);
                        break;
                    case 'subject':
                        sortedEmailList = emailList.sort((a, b) => a.subject.localeCompare(b.subject));
                        break;
                    case 'recipient':
                        sortedEmailList = emailList.sort((a, b) => a.recipient.localeCompare(b.recipient));
                        break;
                    case 'sumAmountDebitOverdue':
                        sortedEmailList = emailList.sort((a, b) => a.sumAmountDebitOverdue - b.sumAmountDebitOverdue);
                        break;
                    case 'sumAmountDebit':
                        sortedEmailList = emailList.sort((a, b) => a.sumAmountDebit - b.sumAmountDebit);
                        break;
                    case 'recipientName':
                        sortedEmailList = emailList.sort((a, b) => a.recipientName.localeCompare(b.recipientName));
                        break;
                    case 'lastPaymentDate':
                        sortedEmailList = emailList.sort((a, b) => new Date(new Date(b.lastPaymentDate).getTime() - a.lastPaymentDate).getTime());
                        break;
                }

                return $scope.ascending ? sortedEmailList : sortedEmailList.reverse();
            }

            function applyOrder(type){
                if($scope.type === type){
                    $scope.ascending = !$scope.ascending;
                } else {
                    $scope.ascending = true;
                }
                $scope.type = type;
                renderTable();
            }

            function parseDate(dateObject) {
                if (dateObject === null) {
                    return "Noch nie";
                }
                var inputDateFormat = "YYYY-MM-DD HH:mm:ss";
                var outputDateFormat = "DD.MM.YYYY";
                var date = moment(dateObject, inputDateFormat);
                return date.format(outputDateFormat);
            }

        }
    ]
}); 

angular.module('debitCollection', []);
angular.module('debitCollection').component('debitCollection', {
    templateUrl: 'app/components/debitCollection/debitCollection.template.html',
    bindings: {
        settings: "<"
    },
    controller: [
        "$scope",
        "$rootScope",
        "requestManager",
        "$routeParams",
        "$window",
        "$route",
        "$filter",
        "Notification",
        "util",
        function ($scope, $rootScope, requestManager, $routeParams, $window, $route, $filter, Notification, util) {
            this.$onInit = function(){
                initVariables();
                initScope();
            };

            this.$onChanges = function set(item) {
                if (item.settings.currentValue) {
                    $scope.debtCollectionIban = util.getSettingByName(item.settings.currentValue, "debtCollectionIban");
                    $scope.debtCollectionBic = util.getSettingByName(item.settings.currentValue, "debtCollectionBic");
                    $scope.debtCollectionAccountOwner = util.getSettingByName(item.settings.currentValue, "debtCollectionAccountOwner");
                    $scope.debtCollectionName = util.getSettingByName(item.settings.currentValue, "debtCollectionName");
                    $scope.debtCollectionPhone = util.getSettingByName(item.settings.currentValue, "debtCollectionPhone");
                    $scope.debtCollectionEmail = util.getSettingByName(item.settings.currentValue, "debtCollectionEmail");
                    $scope.debitorcloudCountry = util.getSettingByName(item.settings.currentValue, "companyCountry");
                    $scope.settingsLoading = false;
                }
            }

            function initVariables(){
                $scope.accountsReceivable = {
                    currentlyLoading: true,
                    loaded: false,
                    content: {
                        amountInclInterest: undefined,
                        amountInterest: undefined,
                        interestPercentage: undefined,
                        amountExclInterest: undefined,
                        sumUndefinedCredits: undefined,
                        invoices: []
                    }
                };
                $scope.recordDescription = undefined;
                $scope.debtCollectionIban = undefined;
                $scope.debtCollectionBic = undefined;
                $scope.debtCollectionAccountOwner = undefined;
                $scope.debtCollectionName = undefined;
                $scope.debtCollectionPhone = undefined;
                $scope.debtCollectionEmail = undefined;
                $scope.debitorcloudCountry = undefined;
                $scope.settingsLoading = true;
                $scope.table = {
                    selectedInvoices: []
                };
                $scope.view = {
                    currentlyLoading: true
                };
                $scope.selectAllCheckbox = false;
                $scope.loading = false;
                $scope.requestFinished = false;
            }

            function initScope(){
                $scope.openDebitCollectionModal = openDebitCollectionModal;
                $scope.onSelectAllCheckboxClick = onSelectAllCheckboxClick;
                $scope.onCheckboxClick = onCheckboxClick;
                $scope.onSaveClick = onSaveClick;
                $scope.openTarife = openTarife;
            }

            function openTarife() {
                let win = window.open('', '_blank');
                win.document.write(`
                    <html>
                      <head>
                        <title>AKV Konditionenblatt</title>
                      </head>
                      <body>
                        <embed src="${'../../../assets/documents/akv_tarife_2023.pdf'}" width="100%" height="100%" type="application/pdf">
                      </body>
                    </html>
                  `);
                win.document.close();
            }

            function createAccountsReceivableScopeObject(response){
                $scope.accountsReceivable.content.amountInclInterest = currency(response.data.amountInclInterest).value;
                $scope.accountsReceivable.content.amountInterest = currency(response.data.amountInterest).value;
                $scope.accountsReceivable.content.interestPercentage = response.data.interestPercentage;
                $scope.accountsReceivable.content.amountExclInterest = response.data.sumAmount;
                $scope.accountsReceivable.content.sumUndefinedCredits = response.data.sumUndefinedCredits;
                angular.forEach(response.data.invoices, (invoice, key) => {
                    let invoiceFormatted = {
                        invoiceDate: angular.copy(invoice.invoiceDate),
                        invoiceDueDate: angular.copy(invoice.invoiceDueDate),
                        invoiceSum: angular.copy(invoice.invoiceSum),
                        invoiceNumber: key,
                        invoicePositions: [],
                        isInDebtCollection: undefined
                    };
                    delete invoice.invoiceDate;
                    delete invoice.invoiceDueDate;
                    delete invoice.invoiceSum;
                    delete invoice.invoiceCurrencyCode;
                    delete invoice.invoiceGuid;
                    delete invoice.invoiceReminders;
                    invoice.isSelected = false;
                    invoiceFormatted.invoicePositions = Object.keys(invoice).map(key => invoice[key]);

                    //this step is not necessary but prints a warning to the console when not all invoice-positions have the same
                    //invoice date - this case should not happen.
                    const isVirtualDueDateInEveryPositionSame = invoiceFormatted.invoicePositions.every((invoicePosition) => {
                        return invoicePosition.virtualDueDate === invoiceFormatted.invoicePositions[0].virtualDueDate;
                    });
                    if(!isVirtualDueDateInEveryPositionSame){
                        console.warn("Some of the invoice-positions in one or more invoices have different virtual due dates.");
                    }

                    // Iterate over all invoicePositions to make sure invoice isInDebtCollection when all single invoicePositions are in debtCollection
                    invoiceFormatted.isInDebtCollection = invoiceFormatted.invoicePositions.every((invoicePosition) => {
                        return invoicePosition.inDebtCollecton === "1";
                    });

                    invoiceFormatted.virtualDueDate = moment(invoiceFormatted.invoicePositions[0].virtualDueDate, "YYYY-MM-DD HH:mm:ss").unix();
                    if(!invoiceFormatted.isInDebtCollection){
                        $scope.accountsReceivable.content.invoices.push(invoiceFormatted);
                    }
                });
            }

            function openDebitCollectionModal(){
                if(!$scope.accountsReceivable.loaded){
                    loadAccountsReceivable();
                    $scope.accountsReceivable.loaded = true;
                }

                jQuery("#debitCollectionModal").appendTo("body").modal("show");
            }

            function loadAccountsReceivable(){
                requestManager.routes.customer.virtual.getAccountsReceivableSummaryOfCustomer($rootScope.authData.sessionId, $routeParams.id).then(onAccountsReceivableLoaded, onError);

                function onAccountsReceivableLoaded(response){
                    createAccountsReceivableScopeObject(response);
                    console.log(response.data);
                    $scope.accountsReceivable.currentlyLoading = false;
                    $scope.view.currentlyLoading = false;
                }

                function onError(response){
                    console.log(response);
                }
            }

            function onSelectAllCheckboxClick(){
                if ($scope.selectAllCheckbox) {
                    $scope.table.selectedInvoices = [];
                    $scope.accountsReceivable.content.invoices.forEach(invoice => {
                        invoice.isSelected = true;
                        $scope.table.selectedInvoices.push(invoice.invoiceNumber);
                    });
                } else {
                    $scope.table.selectedInvoices = [];
                    $scope.accountsReceivable.content.invoices.forEach(invoice => {
                        invoice.isSelected = false;
                    });
                }
            }

            function onCheckboxClick() {
                $scope.table.selectedInvoices = [];
                $scope.accountsReceivable.content.invoices.forEach(invoice => {
                    if (invoice.isSelected) {
                        $scope.table.selectedInvoices.push(invoice.invoiceNumber);
                    } else {
                        $scope.selectAllCheckbox = false;
                    }
                });
                if ($scope.accountsReceivable.content.invoices.length === $scope.table.selectedInvoices.length) {
                    $scope.selectAllCheckbox = true;
                }
            }

            function onSaveClick(){
                if ($scope.debitCollectionForm.$invalid){
                    return;
                }
                if (!$scope.debtCollectionIban || !$scope.debtCollectionIban.settingValue1 || !$scope.debtCollectionBic ||
                    !$scope.debtCollectionBic.settingValue1 || !$scope.debtCollectionAccountOwner || !$scope.debtCollectionAccountOwner.settingValue1 ||
                    !$scope.debtCollectionName || !$scope.debtCollectionName.settingValue1 || !$scope.debtCollectionPhone ||
                    !$scope.debtCollectionPhone.settingValue1 || !$scope.debtCollectionEmail || !$scope.debtCollectionEmail.settingValue1) {
                    Notification.warning($filter("translate")("debtCollectionSettings.INFO_MESSAGE"));
                    return;
                }
                if (window.confirm($filter("translate")("debitCollection.CONFIRM_DEBIT_COLLECTION_REQUEST"))) {
                    $scope.loading = true;
                    requestManager.routes.accountsReceivable.postCollectionAkv($rootScope.authData.sessionId, $routeParams['id'], $scope.table.selectedInvoices, $scope.recordDescription).then(
                        function() {
                            $scope.loading = false;
                            $scope.requestFinished = true;
                            jQuery("#debitCollectionModal").one("hidden.bs.modal", () => {
                                Notification.success($filter("translate")("debitCollection.SUCCESSFULLY_SENT"));
                                jQuery("#requestFinishedModal").appendTo("body").modal("show");
                            }).modal("hide");
                            jQuery("#requestFinishedModal").one("hidden.bs.modal", () => {
                                $scope.requestFinished = false;
                                $route.reload();
                            });
                        },
                        function (response) {
                            $scope.loading = false;
                            if (response && response.status === 403) {
                                Notification.error($filter("translate")("userSettings.ERROR_403_USER_NEED_APPROPRIATE_PERMISSION"));
                                $scope.currentlyLoading = false;
                            } else {
                                Notification.error($filter("translate")("debitCollection.ERROR_WHILE_PROCESSING"));
                            }
                        }
                    )
                }
            }
        }
    ]
}); 

angular.module('createPaymentAgreementButton', []);
angular.module('createPaymentAgreementButton').component('createPaymentAgreementButton', {
    templateUrl: 'app/components/createPaymentAgreementButton/createPaymentAgreementButton.template.html',
    bindings: {
        customerId: '<',
        interestRate: '<',
        settings: '<'
    },
    controller: [
        "$scope",
        "requestManager",
        "$rootScope",
        "$window",
        "$filter",
        "$route",
        "$routeParams",
        "Notification",
        function ($scope, requestManager, $rootScope, $window, $filter, $route, $routeParams, Notification) {
            const ctrl = this;

            this.$onInit = function () {
                initScope();
                initVariables();
            };

            function initScope() {
                $scope.onOpenPaymentAgreementModalClick = onOpenPaymentAgreementModalClick;
                $scope.onInvoiceSelectionChanged = onInvoiceSelectionChanged;
                $scope.onIntervalSelectionChanged = onIntervalSelectionChanged;
                $scope.onPaymentAppointmentsCreated = onPaymentAppointmentsCreated;
                $scope.onSavePaymentTermButtonClick = onSavePaymentTermButtonClick;
                $scope.resetForm = resetForm;
            }

            function initVariables() {
                $scope.loadingStatus = {
                    accountsReceivableLoaded: false,
                };
                $scope.view = {
                    showPaymentIntervalSelection: false,
                    showAppointmentSelection: false
                };
                $scope.accountReceivable = {
                    content: undefined
                };
                $scope.selectedInvoices = {
                    content: undefined,
                    sum: undefined
                };
                $scope.selectedInterval = {
                    content: undefined
                };
                $scope.paymentAppointmentList = {
                    content: undefined
                };
                $scope.settings = {
                    content: undefined
                };
                $scope.bookInDatev = undefined;
                $scope.paymentTermIdForBookInDatev = undefined;
                $scope.paymentAppointmentDatev = {
                    requests: 0,
                    finished: 0
                };
                $scope.accountForBookings = undefined;
                $scope.currentlyLoading = false;
                $scope.commentText = undefined;
            }

            function onOpenPaymentAgreementModalClick() {
                if (!ctrl.customerId || !ctrl.interestRate) {
                    throw new Error("Invalid input: customerId, interestRate or settings is undefined");
                } else {
                    $scope.customerId = ctrl.customerId;
                    $scope.interestRate = ctrl.interestRate;
                    $scope.settings = ctrl.settings.content;
                }
                $scope.settings.find(setting => {
                    if (setting.settingCode === 'bookInDatev') {
                        $scope.bookInDatev = setting.settingValue1;
                    }
                });
                $scope.settings.find(setting => {
                    if (setting.settingCode === 'accountForBookings') {
                        $scope.accountForBookings = setting.settingValue1;
                        if ($scope.accountForBookings !== null && $scope.accountForBookings.length !== 8) {
                            $scope.accountForBookings += '0'.repeat(8 - $scope.accountForBookings.length);
                        }
                    }
                });

                jQuery("#paymentAgreementModal").appendTo("body").modal("show");
                requestManager.routes.customer.virtual.getAccountsReceivableSummaryOfCustomer($rootScope.authData.sessionId, $scope.customerId).then(
                    onAccountsReceivableRequestFinished,
                    onRequestError
                );

                function onAccountsReceivableRequestFinished(response) {
                    $scope.loadingStatus.accountsReceivableLoaded = true;
                    const filteredInvoices = {};
                    angular.forEach(response.data.invoices, (invoice, key) => {
                        delete invoice.invoiceDate;
                        delete invoice.invoiceDueDate;
                        delete invoice.invoiceSum;
                        delete invoice.invoiceCurrencyCode;
                        delete invoice.invoiceGuid;
                        delete invoice.invoiceReminders;
                        response.data.invoices[key] = Object.values(invoice);

                        if (!invoice[0].isInPaymentTerm) {
                            filteredInvoices[key] = Object.values(invoice);
                        }
                    });
                    $scope.accountReceivable.content = filteredInvoices;
                }
            }

            function onRequestError(response) {
                if (response && response.status === 403) {
                    Notification.error($filter("translate")("userSettings.ERROR_403_USER_NEED_APPROPRIATE_PERMISSION"));
                    $scope.currentlyLoading = false;
                }
            }

            function onInvoiceSelectionChanged(selectedInvoices, accountsReceivableSum) {
                $scope.paymentAppointmentList.content = undefined;
                $scope.selectedInvoices.content = selectedInvoices;
                $scope.selectedInvoices.sum = accountsReceivableSum;
                $scope.view.showPaymentIntervalSelection = $scope.selectedInvoices.content && Object.values($scope.selectedInvoices.content).length > 0;
                $scope.view.showAppointmentSelection = false;
            }

            function onIntervalSelectionChanged(selectedInterval) {
                $scope.paymentAppointmentList.content = undefined;
                $scope.selectedInterval.content = selectedInterval;
                $scope.view.showAppointmentSelection = !!$scope.selectedInterval.content;
            }

            function onPaymentAppointmentsCreated(paymentAppointmentList) {
                $scope.paymentAppointmentList.content = angular.copy(paymentAppointmentList);
            }

            function onSavePaymentTermButtonClick() {
                $scope.currentlyLoading = true;
                $scope.paymentTermIdForBookInDatev = undefined;
                $scope.paymentAppointmentDatev.requests = 0;
                $scope.paymentAppointmentDatev.finished = 0;

                const paymentAppointmentListForRequest = $scope.paymentAppointmentList.content.map((appointment) => {
                    return {
                        dateAppointment: appointment.dateFormatted,
                        amountTotal: appointment.amount,
                        amountInterest: appointment.interest
                    };
                });
                const paymentTermParams = {
                    amountTotal: $scope.selectedInvoices.sum,
                    amountInterval: paymentAppointmentListForRequest[0].amountTotal,
                    sizeInterval: $scope.selectedInterval.content.intervalInMonths,
                    intervals: paymentAppointmentListForRequest.length,
                    amountInterest: $scope.paymentAppointmentList.content[$scope.paymentAppointmentList.content.length - 1].cumulatedInterest
                };
                const requestDetails = {
                    numRequestPlanned: 1 + $scope.selectedInvoices.content.length + paymentAppointmentListForRequest.length,
                    numFinished: 0,
                    numSuccessful: 0,
                    numErrors: 0
                };

                if ($scope.bookInDatev && $scope.bookInDatev === '1' &&
                    $scope.accountForBookings && $scope.accountForBookings.length === 8) {
                    if ($window.confirm($filter("translate")("vacantPosOfCustomer.CONFIRMATION_DIALOG"))) {
                        paymentTermParams['bookInDATEV'] = 1;
                        requestManager.routes.customer.createPaymentTermOfCustomer($rootScope.authData.sessionId, $scope.customerId, paymentTermParams).then(onPaymentTermCreatedBookInDatev, onRequestError);
                    } else {
                        $scope.currentlyLoading = false;
                    }
                } else {
                    requestManager.routes.customer.createPaymentTermOfCustomer($rootScope.authData.sessionId, $scope.customerId, paymentTermParams).then(onPaymentTermCreated, onRequestError);
                }

                function onPaymentTermCreatedBookInDatev(response) {
                    $scope.paymentTermIdForBookInDatev = response.data;

                    $scope.paymentAppointmentDatev.requests = paymentAppointmentListForRequest.length;
                    paymentAppointmentListForRequest.forEach(paymentTermAppointment => {
                        requestManager.routes.paymentTerm.createPaymentTermAppointmentOfPaymentTerm($rootScope.authData.sessionId, $scope.paymentTermIdForBookInDatev, paymentTermAppointment).then(
                            onPaymentTermAppointmentSuccessfullyCreated,
                            onRequestError
                        );
                    });
                }

                function onPaymentTermAppointmentSuccessfullyCreated(response) {
                    $scope.paymentAppointmentDatev.finished++;
                    if ($scope.paymentAppointmentDatev.requests !== 0 && $scope.paymentAppointmentDatev.finished === $scope.paymentAppointmentDatev.requests) {
                        onPaymentTermAppointmentCreatedBookInDatev()
                    }
                }

                function onPaymentTermAppointmentCreatedBookInDatev() {
                    const accountNumber = $scope.selectedInvoices.content[0][0].accountNumber;
                    const documentLink = $scope.selectedInvoices.content[0][0].documentLink;
                    // let invoices = '';
                    let totalAmount = 0;
                    let numberOfAppointments = 0;

                    let accounting_records = [];

                    angular.forEach($scope.selectedInvoices.content, function (selectedInvoice) {
                        let date = selectedInvoice[0].date;
                        if (new Date().getFullYear() !== new Date(date).getFullYear()) {
                            date = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
                        }
                        console.log(date);
                        let record = {
                            account_number: selectedInvoice[0].accountNumber,
                            amount: Number(selectedInvoice.sumOfAllPositions.toFixed(2)),
                            contra_account_number: $scope.accountForBookings,
                            date: date,
                            debit_credit_identifier: "H",
                            document_field1: selectedInvoice[0].documentField1,
                            posting_description: "Ratenvereinbarung, Forderung Ausbuchen",
                        };
                        if (selectedInvoice[0].documentLink) {
                            record.document_link = selectedInvoice[0].documentLink;
                            record.document_system = "DORG";
                        }
                        if (selectedInvoice[0].costCenterId) {
                            record.kost1_cost_center_id = selectedInvoice[0].costCenterId;
                        }
                        accounting_records.push(record);
                        totalAmount += selectedInvoice.sumOfAllPositions;
                        // invoices = invoices.concat(invoices.length === 0 ? '' : '&', selectedInvoice[0].documentField1);
                    });

                    const now = moment(new Date());
                    angular.forEach(paymentAppointmentListForRequest, function (appointment, index) {
                        const now = moment(new Date());
                        const dateAppointment = moment(new Date(
                            Number(appointment.dateAppointment.substring(6, 10)),
                            Number(appointment.dateAppointment.substring(3, 5)) - 1,
                            Number(appointment.dateAppointment.substring(0, 2))
                        ));
                        let record = {
                            account_number: accountNumber,
                            amount: Number(appointment.amountTotal.toFixed(2)),
                            contra_account_number: $scope.accountForBookings,
                            date: now.format("YYYY-MM-DD HH:mm:ss"),
                            debit_credit_identifier: "S",
                            // document_field1: invoices,
                            document_field1: "RV" + $scope.paymentTermIdForBookInDatev,
                            document_field2: dateAppointment.format("DDMMYY"),
                            posting_description: "Rate " + (index + 1),
                        }
                        if (documentLink) {
                            record.document_link = documentLink;
                            record.document_system = "DORG";
                        }
                        accounting_records.push(record);
                        numberOfAppointments += 1;
                    });

                    const params = {
                        accounting_reason: "independent_from_accounting_reason",
                        application_information: "Debitorcloud",
                        date_from: now.format("YYYY-MM-DD HH:mm:ss"),
                        date_to: now.format("YYYY-MM-DD HH:mm:ss"),
                        initials: "DC",
                        description: "DC Ratenvereinbarung",
                        is_committed: false,
                        record_type: "financial_accounting",
                        accounting_records: accounting_records
                    };

                    $scope.commentText = 'Es wurde eine Ratenzahlung in Höhe von ' + totalAmount.toFixed(2) +
                        ' mit ' + numberOfAppointments + ' Raten in der Buchhaltung verbucht.'
                    requestDetails.numRequestPlanned = 1;
                    requestManager.routes.accountsReceivable.postDATEV($rootScope.authData.sessionId, params)
                        .then(
                            function (response) {
                                // success
                                if (response.data[0].http_code === 200 || response.data[0].http_code === 201 || response.data[0].http_code === 204) {
                                    requestDetails.numSuccessful += 1;
                                    requestCompleted();
                                } else {
                                    requestDetails.numErrors += 1;
                                    if (response.data[0].body && response.data[0].body.additional_messages && response.data[0].body.additional_messages[0].description) {
                                        requestCompleted(response.data[0].body.additional_messages[0].description);
                                    } else {
                                        requestCompleted();
                                    }
                                }
                            },
                            function (response) {
                                // error
                                requestDetails.numErrors += 1;
                                requestCompleted(response);
                            }
                        );

                    function requestCompleted(responseDetails) {
                        if (requestDetails.numRequestPlanned === requestDetails.numSuccessful) {
                            Notification.warning(
                                {
                                    message: "Lieber Anwender, deine Buchungen wurden erfolgreich an die DATEV Buchhaltung übermittelt. " +
                                        "Dein Steuerberater prüft diese Buchungen nun. Danach werden die Buchungen in die Buchhaltung " +
                                        "effektiv übernommen und in die Debitorcloud übermittelt.<br><strong>Bitte beachte, dass du erst danach " +
                                        "deine gerade verbuchten Änderungen sehen kannst</strong>", delay: 20000
                                });
                            // ein Kommentar erstellen
                            requestManager.routes.customer.createRecordOfCustomer($rootScope.authData.sessionId, $routeParams.id, "record_comment").then(
                                function (response) {
                                    requestManager.routes.record.createCommentRecordOfRecord($rootScope.authData.sessionId, response.data, $scope.commentText)
                                        .then(
                                            function () {
                                                // success
                                                $route.reload();
                                                jQuery("#paymentAgreementModal").modal("hide");
                                            },
                                            function () {
                                                // error
                                                $route.reload();
                                                jQuery("#paymentAgreementModal").modal("hide");
                                            }
                                        );
                                }
                            );
                        } else {
                            $scope.currentlyLoading = false;
                            if (responseDetails) {
                                Notification.error({message: responseDetails, delay: 10000});
                            }
                            deletePaymentTermOnError();
                            Notification.error({
                                message: "Bei der Umbuchung ist es zu einem Fehler gekommen.",
                                delay: 10000
                            });
                        }
                    }
                }

                function onPaymentTermCreated(response) {
                    const paymentTermId = response.data;

                    paymentAppointmentListForRequest.forEach(paymentTermAppointment => {
                        requestManager.routes.paymentTerm.createPaymentTermAppointmentOfPaymentTerm($rootScope.authData.sessionId, paymentTermId, paymentTermAppointment).then(
                            onPaymentTermAppointmentCreated,
                            onRequestError
                        );
                    });

                    $scope.selectedInvoices.content.forEach(invoice => {
                        invoice.forEach(entry => {
                            requestManager.routes.accountsReceivable.editAccountsReceivable($rootScope.authData.sessionId, entry.id, {isInPaymentTerm: paymentTermId}).then(
                                onRequestError
                            );
                        });
                        onRequestFinished(true);
                        //console.log("inside selectedInvoices.content.foreach: ", $scope.selectedInvoices.content);
                        // requestManager.routes.accountsReceivable.editAccountsReceivable($rootScope.authData.sessionId, invoice[0].id, {isInPaymentTerm: paymentTermId}).then(
                        //     onInvoiceUpdated,
                        //     onRequestError
                        // );
                    });

                    onRequestFinished(true);
                }

                function onPaymentTermAppointmentCreated(response) {
                    onRequestFinished(true);
                }

                function onInvoiceUpdated(response) {
                    onRequestFinished(true);
                }

                function onRequestFinished(isSucessful) {
                    requestDetails.numFinished++;
                    if (requestDetails.numFinished === requestDetails.numRequestPlanned) {
                        $scope.currentlyLoading = false;
                        $route.reload();
                        jQuery("#paymentAgreementModal").modal("hide");
                        // jQuery("#paymentAgreementModal").one("hidden.bs.modal", () => {
                        //     $scope.currentlyLoading = false;
                        //     //resetForm();
                        //     console.log("before window location reload");
                        //     $route.reload();
                        // }).modal("hide");
                    }
                }

            }

            function resetForm() {
                $scope.view = {
                    showPaymentIntervalSelection: false,
                    showAppointmentSelection: false
                };
                $scope.accountReceivable = {
                    content: undefined
                };
                $scope.selectedInvoices = {
                    content: undefined,
                    sum: undefined
                };
                $scope.selectedInterval = {
                    content: undefined
                };
                $scope.paymentAppointmentList = {
                    content: undefined
                };
            }


            function deletePaymentTermOnError() {
                requestManager.routes.customer.deletePaymentTermOfCustomer($rootScope.authData.sessionId, ctrl.customerId, $scope.paymentTermIdForBookInDatev).then(
                    function() {
                    },
                    function() {
                    }
                )
            }
        }
    ]
}); 

angular.module('createPaymentAgreementButton').component('accountsReceivableSelection', {
    templateUrl: 'app/components/createPaymentAgreementButton/components/accountsReceivableSelection/accountsReceivableSelection.template.html',
    bindings: {
        accountsReceivableArray: "<",
        onInvoiceSelectionChanged: "&"
    },
    controller: [
        "$scope",
        "requestManager",
        "$rootScope",
        function ($scope, requestManager, $rootScope) {
            const ctrl = this;
            init();
            $scope.europeanNumberFormat = 'en-US';
            if ($rootScope.europeanNumberFormat !== undefined) {
                if ($rootScope.europeanNumberFormat === '1' || $rootScope.europeanNumberFormat === 1) {
                    $scope.europeanNumberFormat = 'de-AT';
                }
                else {
                    $scope.europeanNumberFormat = 'en-US';
                }
            }

            function init(){
                initVariables();
                initScope();
            }

            function initVariables(){
                $scope.accountsReceivable = {
                    content: undefined
                };
                $scope.accountsReceivableSum = 0;
            }

            function initScope(){
                $scope.checkAllInvoices = checkAllInvoices;
                $scope.submitChanges = submitChanges;
            }

            ctrl.$onChanges = function(item){
                if(item.accountsReceivableArray && item.accountsReceivableArray.currentValue){
                    $scope.accountsReceivable.content = item.accountsReceivableArray.currentValue;
                    angular.forEach($scope.accountsReceivable.content, invoice => {
                        let invoiceSum = 0;
                        invoice.forEach((invoicePosition) => {
                            if(invoicePosition.amountDebit){
                                invoiceSum += parseFloat(invoicePosition.amountDebit);
                            }
                        });
                        invoice.selected = false;
                        invoice.sumOfAllPositions = invoiceSum;
                    });
                    submitChanges();
                }
            };

            function checkAllInvoices(bool){
                angular.forEach($scope.accountsReceivable.content, invoice => invoice.selected = bool);
            }

            function submitChanges(){
                const selectedInvoices = [];
                let accountsReceivableSum = 0;

                angular.forEach($scope.accountsReceivable.content, invoice => {
                    if(invoice.selected) {
                        selectedInvoices.push(invoice);
                        invoice.forEach(invoicePosition => {
                            if(invoicePosition.amountDebit){
                                accountsReceivableSum += parseFloat(invoicePosition.amountDebit);
                            }
                        });
                    }

                });

                $scope.accountsReceivableSum = accountsReceivableSum;

                ctrl.onInvoiceSelectionChanged({selectedInvoices: selectedInvoices, accountsReceivableSum: accountsReceivableSum});
            }
        }
    ]
});

angular.module('createPaymentAgreementButton').component('paymentIntervalSelection', {
    templateUrl: 'app/components/createPaymentAgreementButton/components/paymentIntervalSelection/paymentIntervalSelection.template.html',
    bindings: {
        showBody: "<",
        onIntervalSelected: "&"
    },
    controller: [
        "$scope",
        "requestManager",
        "$rootScope",
        function ($scope, requestManager, $rootScope) {
            var ctrl = this;
            init();

            function init() {
                initVariables();
                initScope();
            }

            ctrl.$onChanges = function (item) {
                if (item.showBody && typeof item.showBody.currentValue === "boolean") {
                    toggleBodyVisibility(item.showBody.currentValue);
                    if (item.showBody.currentValue === false) {
                        resetValues();
                    }
                }
            };

            function initVariables() {
                $scope.paymentInterval = {
                    options: [
                        {text: "paymentIntervalSelection.MONTHLY", intervalInMonths: 1},
                        {text: "paymentIntervalSelection.QUARTER_YEAR", intervalInMonths: 3},
                        {text: "paymentIntervalSelection.HALF_YEAR", intervalInMonths: 6},
                        {text: "paymentIntervalSelection.YEARLY", intervalInMonths: 12},
                    ],
                    selected: undefined
                }
                if ($rootScope.pricingModel === 'v2' || $rootScope.pricingModel === 'v2+') {
                    $scope.paymentInterval.options.unshift({
                        text: "paymentIntervalSelection.MONTHLY_X2",
                        intervalInMonths: 0.5
                    });
                    $scope.paymentInterval.options.unshift({
                        text: "paymentIntervalSelection.WEEKLY",
                        intervalInMonths: 0.25
                    });
                }
                $scope.showbody = false;
            }

            function initScope() {
                $scope.onIntervalRadioButtonClicked = onIntervalRadioButtonClicked;
            }

            function toggleBodyVisibility(show) {
                if (show) {
                    //jQuery("#paymentIntervalBox").show("slow");
                    $scope.showbody = true;
                } else {
                    //jQuery("#paymentIntervalBox").hide("slow");
                    $scope.showbody = false;
                }
            }

            function onIntervalRadioButtonClicked() {
                ctrl.onIntervalSelected({selectedInterval: $scope.paymentInterval.selected});
            }


            function resetValues() {
                $scope.paymentInterval.selected = undefined;
            }
        }
    ]
});

angular.module('createPaymentAgreementButton').component('paymentAppointmentCreator', {
    templateUrl: 'app/components/createPaymentAgreementButton/components/paymentAppointmentCreator/paymentAppointmentCreator.template.html',
    bindings: {
        showBody: "<",
        intervalInMonths: "<",
        accountsReceivableSum: "<",
        interestRate: "<",
        onPaymentAppointmentsCreated: "&"
    },
    controller: [
        "$scope",
        "requestManager",
        "$rootScope",
        function ($scope, requestManager, $rootScope) {
            const ctrl = this;
            init();
            $scope.europeanNumberFormat = 'en-US';
            if ($rootScope.europeanNumberFormat !== undefined) {
                if ($rootScope.europeanNumberFormat === '1' || $rootScope.europeanNumberFormat === 1) {
                    $scope.europeanNumberFormat = 'de-AT';
                }
                else {
                    $scope.europeanNumberFormat = 'en-US';
                }
            }

            ctrl.$onChanges = function(item){
                if(item.showBody && typeof item.showBody.currentValue === "boolean"){
                    toggleBodyVisibility(item.showBody.currentValue);
                    if(item.showBody.currentValue === false){
                        resetValues();
                    }
                }
                if(item.interestRate && item.interestRate.currentValue){
                    $scope.interest = item.interestRate.currentValue/100;
                }
                if(item.intervalInMonths && item.intervalInMonths.currentValue !== item.intervalInMonths.previousValue){
                    resetValues();
                }
            };

            function init(){
                initVariables();
                initScope();
            }

            function initVariables(){
                $scope.interest = undefined;
                $scope.calculationType = {
                    options: [
                        {text: "paymentAppointmentCreator.AMOUNT_PER_INTERVAL", method: "AMOUNT_PER_INTERVAL"},
                        {text: "paymentAppointmentCreator.AMOUNT_OF_INTERVALS", method: "AMOUNT_OF_INTERVALS"},
                    ],
                    selected: undefined
                };
                $scope.calculationType.selected = $scope.calculationType.options[0];
                $scope.calculationParameter = {
                    followingAppointmentsOnFirstOfMonth: false,
                    firstAppointmentDate: new Date(),
                    minDate: new Date(),
                    amount: undefined
                };
                $scope.loadingStatus = {
                    isCurrentlyCalculatingAppointments: false
                };
                $scope.appointmentList = {
                    content: undefined
                }
                $scope.showbody = false;
            }

            function initScope(){
                $scope.onStartCalculationButtonClick = onStartCalculationButtonClick;
            }

            function toggleBodyVisibility(showBody){
                if(showBody){
                    //jQuery("#appointmentBox").show("slow");
                    $scope.showbody = true;
                }
                else{
                    //jQuery("#appointmentBox").hide("slow");
                    $scope.showbody = false;
                }
            }

            function onStartCalculationButtonClick(){
                $scope.paymentAppointmentForm.$submitted = true;
                if($scope.paymentAppointmentForm.$invalid){
                    return;
                }

                $scope.loadingStatus.isCurrentlyCalculatingAppointments = true;
                let paymentAppointmentList;

                switch($scope.calculationType.selected.method){
                    case "AMOUNT_PER_INTERVAL":
                        paymentAppointmentList = getPaymentTermAppointmentListWithAmountPerInterval(
                            $scope.calculationParameter.amount,
                            $scope.calculationParameter.firstAppointmentDate,
                            $scope.calculationParameter.followingAppointmentsOnFirstOfMonth
                        );

                        $scope.loadingStatus.isCurrentlyCalculatingAppointments = false;
                        $scope.appointmentList.content = paymentAppointmentList;

                        ctrl.onPaymentAppointmentsCreated({paymentAppointmentList});
                        break;
                    case "AMOUNT_OF_INTERVALS":
                        paymentAppointmentList = getPaymentTermAppointmentListWithAmountOfIntervals(
                            $scope.calculationParameter.amount,
                            $scope.calculationParameter.firstAppointmentDate,
                            $scope.calculationParameter.followingAppointmentsOnFirstOfMonth
                        );

                        $scope.loadingStatus.isCurrentlyCalculatingAppointments = false;
                        $scope.appointmentList.content = paymentAppointmentList;

                        ctrl.onPaymentAppointmentsCreated({paymentAppointmentList: paymentAppointmentList});
                        break;
                    default:
                        throw new Error("Unknown calculation method: " + $scope.calculationType.selected.method);
                }
            }

            function getPaymentTermAppointmentListWithAmountPerInterval(amountPerInterval, firstAppointment, followingAppointmentsOnFirstOfMonth){
                const appointmentList = [];
                let date = moment(firstAppointment);
                let invoiceSum = Number.parseFloat(angular.copy(ctrl.accountsReceivableSum).toFixed(2));
                const isFirstIteration = true;
                const intervalInMonths = angular.copy(ctrl.intervalInMonths);
                let cumulatedInterest = 0;
                let appointment;

                while(1){
                    if(invoiceSum - amountPerInterval <= 0 ){

                        appointment = {
                            dateMoment: angular.copy(date),
                            dateFormatted: date.format("DD.MM.YYYY"),
                            interest: calculateInterest(invoiceSum, $scope.interest, angular.copy(date)),
                            cumulatedInterest: undefined,
                            amount: invoiceSum,
                            restValue: 0
                        };

                        cumulatedInterest = cumulatedInterest + appointment.interest;
                        appointment.cumulatedInterest = cumulatedInterest;

                        appointmentList.push(appointment);
                        break;
                    }
                    else{
                        appointment = {
                            dateMoment: angular.copy(date),
                            dateFormatted: date.format("DD.MM.YYYY"),
                            interest: calculateInterest(amountPerInterval, $scope.interest, angular.copy(date)),
                            cumulatedInterest: undefined,
                            amount: amountPerInterval,
                            restValue: Number.parseFloat((invoiceSum - amountPerInterval).toFixed(2))
                        };

                        cumulatedInterest = cumulatedInterest + appointment.interest;
                        appointment.cumulatedInterest = cumulatedInterest;

                        appointmentList.push(appointment);
                        invoiceSum -= amountPerInterval;
                    }

                    if(isFirstIteration && followingAppointmentsOnFirstOfMonth){
                        date = date.date(1);
                    }

                    if (intervalInMonths === 0.25 || intervalInMonths === 0.5) {
                        date = date.add(4 * intervalInMonths, 'weeks');
                    } else {
                        date = date.add(intervalInMonths, 'months');
                    }
                }

                return appointmentList;
            }

            function getPaymentTermAppointmentListWithAmountOfIntervals(amountOfIntervals, firstAppointment, followingAppointmentsOnFirstOfMonth){
                const appointmentList = [];
                let date = moment(firstAppointment, "DD.MM.YYYY");
                let invoiceSum = Number.parseFloat(angular.copy(ctrl.accountsReceivableSum).toFixed(2));
                let isFirstIteration = true;
                const intervalInMonths = angular.copy(ctrl.intervalInMonths);
                let cumulatedInterest = 0;
                let amountPerInterval;
                let firstIntervalAmount;
                const remainingIntervalAmounts = Math.round(invoiceSum / amountOfIntervals, 2);


                //in case the amount can´t be divided without rest we need to update the first rate
                if(Number.parseFloat((invoiceSum - (remainingIntervalAmounts * amountOfIntervals)).toFixed(2)) !== 0){
                    const rest = Number.parseFloat((invoiceSum - (remainingIntervalAmounts * amountOfIntervals)).toFixed(2));
                    firstIntervalAmount = Number.parseFloat((remainingIntervalAmounts + rest).toFixed(2));
                }
                else{
                    firstIntervalAmount = remainingIntervalAmounts;
                }

                while(1){
                    let appointment;
                    amountPerInterval = isFirstIteration ? firstIntervalAmount : remainingIntervalAmounts;

                    if(Number.parseFloat((invoiceSum - amountPerInterval).toFixed(2)) <= 0 ){
                        appointment = {
                            dateMoment: angular.copy(date),
                            dateFormatted: date.format("DD.MM.YYYY"),
                            interest: calculateInterest(invoiceSum, $scope.interest, angular.copy(date)),
                            cumulatedInterest: undefined,
                            amount: Number.parseFloat((invoiceSum).toFixed(2)),
                            restValue: 0
                        };

                        cumulatedInterest = cumulatedInterest + appointment.interest;
                        appointment.cumulatedInterest = cumulatedInterest;

                        appointmentList.push(appointment);
                        break;
                    }
                    else{
                        appointment = {
                            dateMoment: angular.copy(date),
                            dateFormatted: date.format("DD.MM.YYYY"),
                            interest: calculateInterest(amountPerInterval, $scope.interest, angular.copy(date)),
                            cumulatedInterest: undefined,
                            amount: amountPerInterval,
                            restValue: Number.parseFloat((invoiceSum - amountPerInterval).toFixed(2))
                        };

                        cumulatedInterest = cumulatedInterest + appointment.interest;
                        appointment.cumulatedInterest = cumulatedInterest;

                        appointmentList.push(appointment);
                        invoiceSum -= amountPerInterval;
                        Math.round(invoiceSum, 2)
                    }

                    if(isFirstIteration && followingAppointmentsOnFirstOfMonth){
                        date = date.date(1);
                    }

                    if (intervalInMonths === 0.25 || intervalInMonths === 0.5) {
                        date = date.add(4 * intervalInMonths, 'weeks');
                    } else {
                        date = date.add(intervalInMonths, 'months');
                    }
                    isFirstIteration = false;
                }

                return appointmentList;
            }

            function calculateInterest(amount, interest, date){
                return (amount*interest/360)*date.daysInMonth();
            }

            function resetValues(){
                $scope.calculationParameter = {
                    followingAppointmentsOnFirstOfMonth: false,
                    firstAppointmentDate: new Date(),
                    minDate: new Date(),
                    amount: undefined
                };

                $scope.appointmentList = {
                    content: undefined
                }
            }
        }
    ]
});

angular.module('sendPhysicalMailButton', []);
angular.module('sendPhysicalMailButton').component('sendPhysicalMailButton', { 
	templateUrl: 'app/components/sendPhysicalMailButton/sendPhysicalMailButton.template.html', 
	bindings: {
		customerId: "<"
	},
	controller: [ 
		"$scope",
		"requestManager",
		"$rootScope", 
		"$filter", 
		"$window",
		"util",
		"$route", 
		"Notification", 
		function ($scope, requestManager, $rootScope, $filter, $window, util, $route, Notification) { 
			var ctrl = this; 
			init();
			
			ctrl.$onChanges = function(item){
				if(item.customerId && item.customerId.currentValue){
					$scope.customerId = item.customerId.currentValue; 
				}
			};
			
			function init(){
				initVariables(); 
				initScope();
			}
			
			function initVariables(){
				if ($rootScope.settings !== undefined) {
					const companyCountry = $rootScope.settings.filter((setting) => {
						return setting.settingCode === 'companyCountry';
					})[0];
					//after login there is no settingValue1 but there is a value1 (for settingCode = companyCountry)
					//after clicking the save changes button in the settings, we have then the value settingValue1
					$scope.companyCountry = companyCountry.value1 || companyCountry.settingValue1;
				}
				$scope.customerId = undefined;
				$scope.costs = {
					secured: undefined, 
					regular: undefined
				};
				$scope.sendSecured = false;
				$scope.view = {
					errorOccurred: false, 
					currentlyLoading: true
				};
				$scope.submitLoading = false;
				$scope.increaseDunningLevel = false;
			}
			
			
			function initScope(){
				$scope.openSendPhysicalMailModal = openSendPhysicalMailModal; 
				$scope.sendPhysicalMail = sendPhysicalMail; 
			}
			
			function openSendPhysicalMailModal (){
				if(!$scope.customerId){
					$scope.view.errorOccurred = true;
				}
				
				requestManager.routes.recordPhysicalMail.virtual.calculateSendingCosts(
					$rootScope.authData.sessionId, 
					$scope.customerId,
					$scope.companyCountry
				).then(
					onCalculateSendingCostsRequestSuccess, 
					onCalculateSendingCostsRequestError
				); 
				jQuery("#sendPhysicalMailModal").appendTo("body").modal("show");
				
				function onCalculateSendingCostsRequestSuccess(response){
					$scope.view.currentlyLoading = false ; 
					$scope.costs.regular = response.data.regular; 
					$scope.costs.secured = response.data.secured;
				}
				
				function onCalculateSendingCostsRequestError(response){
					if (response && response.status === 403) {
						jQuery("#sendPhysicalMailModal").modal("hide");
						Notification.error($filter("translate")("userSettings.ERROR_403_USER_NEED_APPROPRIATE_PERMISSION"));
					} else {
						$scope.view.errorOccurred = true;
					}
				}
			}
			
			
			function sendPhysicalMail(secured, dunningLevel){
				$scope.submitLoading = true;
				if($window.confirm($filter("translate")("sendPhysicalMailButton.CONFIRM_SEND_MAIL"))){
						requestManager.routes.recordPhysicalMail.virtual.sendPhysicalMail($rootScope.authData.sessionId, $scope.customerId, secured ? "1" : "0", dunningLevel ? '1' : null).then(
						onSendPhysicalMailRequestSuccessful,
						onSendPhysicalMailRequestError
					)
				} else {
					$scope.submitLoading = false;
				}
				
				function onSendPhysicalMailRequestSuccessful(){
					jQuery("#sendPhysicalMailModal").one("hidden.bs.modal", () => {
						jQuery("#sendPhysicalMailModal").detach();
						$rootScope.amountUnapprovedDunnings += 1;
						$scope.submitLoading = false;
						$route.reload(); 
					}).modal("hide");
				}
				
				function onSendPhysicalMailRequestError(response){
					$scope.submitLoading = false;
					Notification.error($filter("translate")("sendPhysicalMailButton.SENDING_NOT_SUCCESSFUL")); 
				}
			}
		} 
	] 
}); 

angular.module('paidInvoicesList', []);
angular.module('paidInvoicesList').component('paidInvoicesList', { 
	templateUrl: 'app/components/paidInvoicesList/paidInvoicesList.template.html', 
	controller: [ 
		"requestManager", 
		"$scope", 
		"$rootScope", 
		"$routeParams",
		"util", 
		function (requestManager, $scope, $rootScope, $routeParams, util) { 
			this.$onInit = function(){
				$scope.europeanNumberFormat = 'en-US';
				if ($rootScope.europeanNumberFormat !== undefined) {
					if ($rootScope.europeanNumberFormat === '1' || $rootScope.europeanNumberFormat === 1) {
						$scope.europeanNumberFormat = 'de-AT';
					}
					else {
						$scope.europeanNumberFormat = 'en-US';
					}
				}
				initScope(); 
				initVariables(); 
				
				requestManager.routes.accountsReceivable.virtual.getPaidInvoicesOfCustomer($rootScope.authData.sessionId, $routeParams.id).then(
					onGetPaidInvoicesOfCustomerFinished, 
					onRequestError
				);
				
				function onGetPaidInvoicesOfCustomerFinished(response){
					response.data.forEach(invoice => {
						invoice.dateObject = moment(invoice.date, "YYYY-MM-DD HH:mm:ss").toDate();
                        invoice.folded = true;
					});
					$scope.paidInvoices.content = response.data;
					$scope.paidInvoices.content.forEach(value => {
						value.invoiceAmount = +value.invoiceAmount;
						value.movements.forEach(val => {
							val.amountDebit = +val.amountDebit;
						});
					});
					$scope.paidInvoices.contentChunked =  util.divideArrayIntoChunks(response.data, 10); 
					$scope.paidInvoices.currentlyLoading = false;
				}
			};
			
			function initScope(){
				$scope.changePaginationIndex = changePaginationIndex;
			}
			
			function initVariables(){
				$scope.paidInvoices = {
					content: undefined, 
					currentlyLoading: true, 
					contentChunked: undefined
				}; 
				$scope.pagination = {
					index: 0
				};
				$scope.error = {
					occurred: false, 
					code: undefined, 
					reason: undefined
				}
			}
			
			function onRequestError(response){
				$scope.error.occurred = true; 
				$scope.error.code = "REQUEST_FAILED";
				$scope.error.reason = response

			}
			
			function changePaginationIndex(value){
				if(value < 0){
					if($scope.pagination.index > 0){
						$scope.pagination.index += value;
					}
				}
				else{
					if($scope.pagination.index < $scope.paidInvoices.contentChunked.length-1){
						$scope.pagination.index += value;
					}
				}
			}
		} 
	] 
}); 

angular.module('pausedInvoices', []);
angular.module('pausedInvoices').component('pausedInvoices', {
    templateUrl: 'app/components/pausedInvoices/pausedInvoices.template.html',
    controller: [
        "$scope",
        "$rootScope",
        "requestManager",
        "util",
        "$filter",
        "Notification",
        "$route",
        function ($scope, $rootScope, requestManager, util, $filter, Notification, $route) {
            this.$onInit = function(){
                initScopeFunctions();
                initScopeVariables();
                sendApiRequests();
            };

            function initScopeFunctions(){
                $scope.onRemoveDunningBlockClick = onRemoveDunningBlockClick;
                $scope.changePaginationIndex = changePaginationIndex;
            }

            function initScopeVariables(){
                $scope.pausedInvoices = {
                    errorOccurred: false,
                    currentlyLoading: true,
                    content: undefined
                };
                $scope.pagination = {
                    page: 1,
                };
                $scope.view = {
                    errorOccurred: false,
                    errorMessage: undefined,
                    currentlyLoading: true
                }
            }

            function sendApiRequests(){
                requestManager.routes.accountsReceivable.getPausedInvoices($rootScope.authData.sessionId).then(
                    (response) => {
                        $scope.pausedInvoices.content = response.data;
                        $scope.pausedInvoices.content.map((invoice) => {
                            invoice.virtualDueDate = moment(invoice.virtualDueDate, "YYYY-MM-DD HH:mm:ss").toDate();
                            invoice.date = moment(invoice.date, "YYYY-MM-DD HH:mm:ss").toDate();
                        });
                        $scope.pausedInvoices.contentChunked =  util.divideArrayIntoChunks(response.data, 10);
                        $scope.pausedInvoices.currentlyLoading = false;
                        $scope.view.currentlyLoading = false;
                    },
                    (response) => {
                        $scope.view.errorOccurred = true;
                        $scope.view.errorMessage = $filter("translate")("pausedInvoices.ERROR_WHILE_INITIALIZING_COMPONENT");
                        $scope.pausedInvoices.content = response.data;
                        $scope.pausedInvoices.currentlyLoading = false;
                        $scope.view.currentlyLoading = false;
                        $scope.pausedInvoices.errorOccurred = true;
                    }
                )
            }

            function onRemoveDunningBlockClick(invoiceId){
                $scope.view.currentlyLoading = true;
                requestManager.routes.accountsReceivable.getAccountsReceivable($rootScope.authData.sessionId, [["openItemNumber", "=", invoiceId]]).then(
                    onAccountsReceivableLoaded,
                    () => {
                        Notification.error($filter("translate")("pausedInvoices.ERROR_DURING_PROCESSING"));
                    }
                );

                function onAccountsReceivableLoaded(response){
                    const accountsReceivableList = response.data;
                    const requestSummary = {
                        numRequestPlanned: accountsReceivableList.length,
                        numRequestsFinished: 0,
                        errors: []
                    };

                    accountsReceivableList.forEach((accountsReceivable) => {
                        requestManager.routes.accountsReceivable.editAccountsReceivable($rootScope.authData.sessionId, accountsReceivable.id, {hasDunningBlock: "0"}).then(
                            () => {
                                updateFinishedStatus();
                            },
                            (response) => {
                                errors.push(response);
                                updateFinishedStatus()
                            }
                        )
                    });

                    function updateFinishedStatus(){
                        requestSummary.numRequestsFinished++;
                        if(requestSummary.numRequestsFinished === requestSummary.numRequestPlanned){
                            if(requestSummary.errors.length === 0){
                                Notification.success($filter("translate")("pausedInvoices.SUCCESSFULLY_UPDATED"));
                                $route.reload();
                            }
                            else{
                                Notification.error($filter("translate")("pausedInvoices.ERROR_DURING_PROCESSING"));
                                $route.reload();
                            }
                        }
                    }
                }
            }

            function changePaginationIndex(changeBy){
                if(!(changeBy === 1 || changeBy === -1)){
                    throw new Error("changeBy must be either 1 or -1");
                }

                if(($scope.pagination.page === 1 && changeBy === -1) || ($scope.pagination.page ===  $scope.pausedInvoices.contentChunked.length && changeBy === 1)){
                    return;
                }

                $scope.pausedInvoices.contentChunked += changeBy;
            }
        }
    ]
}); 

angular.module('pdfTemplateCanvas', []);
angular.module('pdfTemplateCanvas').component('pdfTemplateCanvas', {
    templateUrl: 'app/components/pdfTemplateCanvas/pdfTemplateCanvas.template.html',
    bindings: {
        templateSettings: "<",
        onSaveTemplateClick: "&"
    },
    controller: [
        "$scope",
        "$rootScope",
        "requestManager",
        "$route",
        "Notification",
        function ($scope, $rootScope, requestManager, $route, Notification) {
            const ctrl = this;
            init();

            /**
             * Function that is first called when the controller is being loaded. It takes care of calling the respective variable
             * and function initialization methods as well as setting up the CKEDITORs (editable fields in the created canvas).
             */
            function init(){
                $scope.fonts = ['Arial', 'Arial Black', 'Comic Sans MS', 'Courier New', 'Georgia', 'Helvetica', 'Helvetica Neue', 'Impact',
                    'Lucida Console', 'Lucida Sans Unicode', 'Palatino Linotype', 'Tahoma', 'Times New Roman', 'Trebuchet MS', 'Verdana'];
                $scope.fontSizes = ['10px', '13px', '16px', '18px', '24px', '32px', '48px'];

                initVariables();
                initScopeFunctions();

                // $scope.toolbarFull = [
                //     { name: 'document', items : [ 'Source','-','Save','NewPage','DocProps','Preview','Print','-','Templates' ] },
                //     { name: 'clipboard', items : [ 'Cut','Copy','Paste','PasteText','PasteFromWord','-','Undo','Redo' ] },
                //     { name: 'editing', items : [ 'Find','Replace','-','SelectAll','-','SpellChecker', 'Scayt' ] },
                //     { name: 'forms', items : [ 'Form', 'Checkbox', 'Radio', 'TextField', 'Textarea', 'Select', 'Button', 'ImageButton',
                //             'HiddenField' ] },
                //     '/',
                //     { name: 'basicstyles', items : [ 'Bold','Italic','Underline','Strike','Subscript','Superscript','-','RemoveFormat' ] },
                //     { name: 'paragraph', items : [ 'NumberedList','BulletedList','-','Outdent','Indent','-','Blockquote','CreateDiv',
                //             '-','JustifyLeft','JustifyCenter','JustifyRight','JustifyBlock','-','BidiLtr','BidiRtl' ] },
                //     { name: 'links', items : [ 'Link','Unlink','Anchor' ] },
                //     { name: 'insert', items : [ 'Image','Flash','Table','HorizontalRule','Smiley','SpecialChar','PageBreak','Iframe' ] },
                //     '/',
                //     { name: 'styles', items : [ 'Styles','Format','Font','FontSize' ] },
                //     { name: 'colors', items : [ 'TextColor','BGColor' ] },
                //     { name: 'tools', items : [ 'Maximize', 'ShowBlocks','-','About' ] }
                // ];

                $scope.toolbar = [
                    { name: 'basicstyles', items : [ 'Bold','Italic','Underline','Strike','Subscript','Superscript','-','RemoveFormat' ] },
                    { name: 'paragraph', items : [ 'JustifyLeft','JustifyCenter','JustifyRight','JustifyBlock' ] },
                    { name: 'links', items : [ 'Link' ] },
                    { name: 'styles', items : [ 'Font','FontSize' ] },
                    { name: 'colors', items : [ 'TextColor' ] },
                    { name: 'tools', items : [ 'Maximize', 'ShowBlocks' ] }
                ];

                $scope.customMenu = [
                    ['bold', 'italic', 'underline', 'strikethrough', 'subscript', 'superscript'],
                    // ['format-block'],
                    ['font'],
                    ['font-size'],
                    // ['font-color', 'hilite-color'],
                    ['remove-format'],
                    // ['ordered-list', 'unordered-list', 'outdent', 'indent'],
                    ['left-justify', 'center-justify', 'right-justify'],
                    // ['code', 'quote', 'paragraph'],
                    ['link', 'image']
                ];

                /* editors for reminder-related templates */
                // CKEDITOR.replace('editorInfoFieldFirstPage', {
                //     toolbar: $scope.toolbar,
                // });

                // CKEDITOR.replace('editorTitle', {
                //     toolbar: $scope.toolbar,
                // });

                // CKEDITOR.replace('editorFooterFirstPage', {
                //     toolbar: $scope.toolbar,
                // });
                // CKEDITOR.replace('editorOverdueInvoiceText', {
                //     toolbar: $scope.toolbar,
                // });
                // CKEDITOR.replace('editorNonOverdueInvoiceTextFirstPage', {
                //     toolbar: $scope.toolbar,
                // });
                // CKEDITOR.replace('editorFooterExtraPage', {
                //     toolbar: $scope.toolbar,
                // });
                // CKEDITOR.replace('editorNonOverdueTextExtraPage', {
                //     toolbar: $scope.toolbar,
                // });

                /* editors for SEPA-related templates */
                // CKEDITOR.replace('sepaMainTextField', {
                //     toolbar: $scope.toolbar,
                // });
                // CKEDITOR.replace('editorFooterSepa', {
                //     toolbar: $scope.toolbar,
                // });

                /* editors for lawyer-report related templates */
                // CKEDITOR.replace('editorHeaderLawyerReport', {
                //     toolbar: $scope.toolbar,
                // });
                // CKEDITOR.replace('editorSubHeaderLawyerReport', {
                //     toolbar: $scope.toolbar,
                // });

                /* editors for email-related templates */
                // CKEDITOR.replace('editorEmailDisclaimer', {
                //     toolbar: $scope.toolbar,
                // });
                // CKEDITOR.replace('editorEmailDisclaimer2', {
                //     toolbar: $scope.toolbar,
                // });

                // editor for paymentterm agreement
                // CKEDITOR.replace('editorPaymentTermAgreementText', {
                //     toolbar: $scope.toolbar,
                // });
            }

            /**
             * Method is called when it detects changes int the frontend (i.e. switching between templates)
             * @param change Changed object that is passed to the function.
             */
            this.$onChanges = function(change){
                if(change.templateSettings && change.templateSettings.currentValue){
                    $scope.templateSettings = angular.copy(change.templateSettings.currentValue);
                    $scope.templateType = $scope.templateSettings.find(x => x.settingObject === true).templateSetting;
                    $scope.resetPdfTemplate();
                }
            };

            /**
             * Initialize functions; declaring them as part of '$scope' makes them globally accessible
             */
            function initScopeFunctions(){
                $scope.resetPdfTemplate = resetPdfTemplate;
                $scope.onSaveButtonClick = onSaveButtonClick;
                $scope.insertReplacementTag = insertReplacementTag;
            }

            /**
             * Initialize variables; declaring them as part of '$scope' makes them globally accessible
             */
            function initVariables() {
                $scope.emailInputField = undefined;
                $scope.wysiwygEditorActivated = undefined;
                $scope.templateSettings = undefined;
                $scope.templateType = undefined;
                $scope.notOverdueInvoiceHint = "Dieses Feld wird nur angezeigt, wenn offene und nicht überfällige Rechnungen vorhanden sind";
                $scope.emailReplaceTags = {
                    content: [
                        {key: "Email Text", value: "[emailText]"},
                        {key: "Firmenname", value: "[companyName]"},
                        {key: "Adresse", value: "[companyAddress]"},
                        {key: "PLZ", value: "[companyZip]"},
                        {key: "Ort", value: "[companyCity]"},
                        {key: "Land", value: "[companyCountry]"},
                        {key: "Telefon", value: "[companyPhone]"},
                        {key: "E-Mail Adresse", value: "[companyMail]"},
                    ]
                };
            }

            function insertReplacementTag(tag){
                if ($scope.emailInputField === '') {
                    $scope.emailInputField = tag.value;
                } else {
                    $scope.emailInputField += tag.value;
                }
                // CKEDITOR.instances["editorEmailDisclaimer2"].setData($scope.emailInputField);
            }

            function createSetting(settingValue2, settingValue3) {
                sendCreateSettingRequest($rootScope.authData.sessionId, {
                    settingCode: $scope.templateSettings[0].settingCode,
                    settingValue1: $scope.templateSettings[0].settingValue1,
                    settingValue2: settingValue2,
                    settingValue3: settingValue3
                }, function(requestDetails) {
                    if (requestDetails.successful) {
                        if (settingValue2 === 'font') {
                            $scope.font = {
                                settingCode: $scope.templateSettings[0].settingCode,
                                settingValue1: $scope.templateSettings[0].settingValue1,
                                settingValue2: settingValue2,
                                settingValue3: settingValue3
                            }
                            $scope.templateSettings.push($scope.font);
                        }
                        if (settingValue2 === 'fontSize') {
                            $scope.fontSize = {
                                settingCode: $scope.templateSettings[0].settingCode,
                                settingValue1: $scope.templateSettings[0].settingValue1,
                                settingValue2: settingValue2,
                                settingValue3: settingValue3
                            }
                            $scope.templateSettings.push($scope.fontSize);
                        }
                        if ($scope.font !== undefined && $scope.fontSize !== undefined) {
                            $route.reload();
                        }
                    } else {
                        sharedMemory.error.setError(
                            requestDetails,
                            "COULD_NOT_LOAD_DATA",
                            "Fehler beim erstellen eines neuen Settings (Schriftart, Schriftgröße)",
                            $location.path(),
                            "systemSettings"
                        );
                        $location.path("/error-page");
                    }
                });
            }

            function sendCreateSettingRequest(sessionId, params, callback) {
                var requestDetails = {
                    successful: true,
                    response: undefined
                };

                requestManager.routes.settings.createSettingWithKey(sessionId, params).then(
                    function(response) {
                        requestDetails.response = response;
                        callback(requestDetails);
                    },
                    function(response) {
                        requestDetails.response = response;
                        requestDetails.successful = false;
                        callback(requestDetails);
                    }
                )
            }

            /**
             * Method is called when the user in the frontend switches between template language and/or dunning level.
             * It resets/re-initializes the values displayed in the created canvas
             */
            function resetPdfTemplate(){
                $scope.wysiwygEditorActivated = $rootScope.settings.find(x => x.settingCode === 'wysiwygEditorActivated');
                $scope.advancedSettings = $rootScope.settings.find(x => x.settingCode === 'advancedSettings');
                /* Reminder-templated related variables */
                if($scope.templateType === 'reminderTemplate') {
                    $scope.additionalInfoField = getFieldWithName("additionalInfo", $scope.templateSettings);
                    $scope.title = getFieldWithName("title", $scope.templateSettings);
                    $scope.overdueInvoiceText = getFieldWithName("overdueInvoiceText", $scope.templateSettings);
                    $scope.notOverdueInvoiceText = getFieldWithName("notOverdueInvoiceText", $scope.templateSettings);
                    $scope.footer = getFieldWithName("footer", $scope.templateSettings);
                    $scope.notOverdueInvoiceFooter = getFieldWithName("notOverdueInvoiceFooter", $scope.templateSettings);
                    $scope.nonOverdueOpNewPage = $scope.templateSettings.find(x => x.settingCode === 'nonOverdueOpNewPage');
                    $scope.font = getFieldWithName("font", $scope.templateSettings);
                    if ($scope.wysiwygEditorActivated.settingValue1 === '1') {
                        if ($scope.font === undefined) {
                            createSetting('font', 'Arial');
                        }
                        $scope.fontSize = getFieldWithName("fontSize", $scope.templateSettings);
                        if ($scope.fontSize === undefined) {
                            createSetting('fontSize', '13px');
                        }
                    }
                }

                /* Payment-Term-Agreement related variables */
                if($scope.templateType === 'paymentTermAgreementText') {
                    $scope.paymentTermAgreementText = $scope.templateSettings.filter((setting) => {
                        return setting.settingCode === 'paymentTermAgreementText';
                    });
                    $scope.paymentTermAgreementText.settingValue1 = atob($scope.paymentTermAgreementText[0].settingValue1)
                }

                /*SEPA related variables*/
                if($scope.templateType === 'sepaDirectDebitTemplate') {
                    $scope.mainSepaMessage = getFieldWithName("mainSepaMessage", $scope.templateSettings);
                    $scope.sepaFooter = getFieldWithName("sepaFooter", $scope.templateSettings);
                }

                /* Lawyer report related variables */
                if($scope.templateType === 'lawyerReportTemplate') {
                    $scope.headerLawyerReport = getFieldWithName("headerLawyerReport", $scope.templateSettings);
                    $scope.subHeaderLawyerReport = getFieldWithName("subHeaderLawyerReport", $scope.templateSettings);
                }

                /* Email template related variables */
                if($scope.templateType === 'emailTemplate'){
                    angular.forEach($scope.templateSettings, (key, value) => {
                        if(key.settingCode === 'emailTemplate1'){
                            $scope.emailDisclaimer = key;
                        }
                    });
                }

                /* Reminder template related editors */
                if($scope.templateType === 'reminderTemplate') {
                    // if ($scope.additionalInfoField) {
                    //     CKEDITOR.instances["editorInfoFieldFirstPage"].setData($scope.additionalInfoField.settingValue3);
                    // } else {
                    //     CKEDITOR.instances["editorInfoFieldFirstPage"].setData('');
                    // }

                    // if ($scope.title) {
                    //     CKEDITOR.instances["editorTitle"].setData($scope.title.settingValue3);
                    // } else {
                    //     CKEDITOR.instances["editorTitle"].setData('');
                    // }

                    // if ($scope.footer) {
                    //     CKEDITOR.instances["editorFooterFirstPage"].setData($scope.footer.settingValue3);
                    // } else {
                    //     CKEDITOR.instances["editorFooterFirstPage"].setData('');
                    // }

                    // if ($scope.overdueInvoiceText) {
                    //     CKEDITOR.instances["editorOverdueInvoiceText"].setData($scope.overdueInvoiceText.settingValue3);
                    // } else {
                    //     CKEDITOR.instances["editorOverdueInvoiceText"].setData('');
                    // }

                    // if ($scope.notOverdueInvoiceText) {
                    //     CKEDITOR.instances["editorNonOverdueInvoiceTextFirstPage"].setData($scope.notOverdueInvoiceText.settingValue3);
                    //     CKEDITOR.instances["editorNonOverdueTextExtraPage"].setData($scope.notOverdueInvoiceText.settingValue3);
                    // } else {
                    //     CKEDITOR.instances["editorNonOverdueInvoiceTextFirstPage"].setData('');
                    //     CKEDITOR.instances["editorNonOverdueTextExtraPage"].setData('');
                    // }

                    // if ($scope.notOverdueInvoiceFooter) {
                    //     CKEDITOR.instances["editorFooterExtraPage"].setData($scope.notOverdueInvoiceFooter.settingValue3);
                    // } else {
                    //     CKEDITOR.instances["editorFooterExtraPage"].setData('');
                    // }
                }

                /* Payment-Term-Agreement report related editors */
                if($scope.templateType === 'paymentTermAgreementText') {
                    // if ($scope.paymentTermAgreementText) {
                    //     CKEDITOR.instances["editorPaymentTermAgreementText"].setData($scope.paymentTermAgreementText.settingValue1);
                    // } else {
                    //     CKEDITOR.instances["editorPaymentTermAgreementText"].setData('');
                    // }
                }

                /*SEPA related editors*/
                if($scope.templateType === 'sepaDirectDebitTemplate') {
                    // if ($scope.mainSepaMessage) {
                    //     CKEDITOR.instances["sepaMainTextField"].setData($scope.mainSepaMessage.settingValue3);
                    // } else {
                    //     CKEDITOR.instances["sepaMainTextField"].setData('');
                    // }

                    // if ($scope.sepaFooter) {
                    //     CKEDITOR.instances["editorFooterSepa"].setData($scope.sepaFooter.settingValue3);
                    // } else {
                    //     CKEDITOR.instances["editorFooterSepa"].setData('');
                    // }
                }

                /* Lawyer report related editors */
                if($scope.templateType === 'lawyerReportTemplate') {
                    // if ($scope.headerLawyerReport) {
                    //     CKEDITOR.instances["editorHeaderLawyerReport"].setData($scope.headerLawyerReport.settingValue3);
                    // } else {
                    //     CKEDITOR.instances["editorHeaderLawyerReport"].setData('');
                    // }

                    // if ($scope.subHeaderLawyerReport) {
                    //     CKEDITOR.instances["editorSubHeaderLawyerReport"].setData($scope.subHeaderLawyerReport.settingValue3);
                    // } else {
                    //     CKEDITOR.instances["editorSubHeaderLawyerReport"].setData('');
                    // }
                }

                /* Email template related editors */
                if($scope.templateType === 'emailTemplate'){
                    if ($scope.emailDisclaimer) {
                       if ($scope.wysiwygEditorActivated.settingValue1 === '1') {
                    //         CKEDITOR.instances["editorEmailDisclaimer2"].setData(atob($scope.emailDisclaimer.settingValue2));
                            $scope.emailDisclaimer.settingValue2 = atob($scope.emailDisclaimer.settingValue2);
                    //     } else {
                    //         CKEDITOR.instances["editorEmailDisclaimer"].setData(atob($scope.emailDisclaimer.settingValue2));
                       }
                    } else {
                    //     if ($scope.wysiwygEditorActivated.settingValue1 === '1') {
                    //         CKEDITOR.instances["editorEmailDisclaimer2"].setData('');
                    //     } else {
                    //         CKEDITOR.instances["editorEmailDisclaimer"].setData('');
                    //     }
                    }
                }

            }

            /**
             * Method is called in order to get the respective key of the setting (saved in settingValue2 in the database column).
             * @param fieldName The name which the value saved in setting.settingValue2 is compared to
             * @param settingList The list of settings that is to be filtered and in which the desired settingValue2 is searched for
             * @returns {*} The desired setting.settingValue2
             */
            function getFieldWithName(fieldName, settingList){
                const filteredSettings =  settingList.filter((setting) => {
                    return setting.settingValue2 === fieldName;
                });

                if(filteredSettings.length > 1){
                    console.warn("There are at least two settings with the same setting name");
                }

                if(filteredSettings.length === 0){
                    console.warn("Setting with field-name " + fieldName + " does not exist");
                }

                return filteredSettings[0];
            }

            /**
             * The method is called when the user clicks on the green 'Save' button
             */
            function onSaveButtonClick(){

                /* Reminder-template related variables */
                if($scope.templateType === 'reminderTemplate') {
                    // $scope.additionalInfoField.settingValue3 = CKEDITOR.instances.editorInfoFieldFirstPage.getData();
                    // $scope.title.settingValue3 = CKEDITOR.instances.editorTitle.getData();
                    // $scope.footer.settingValue3 = CKEDITOR.instances.editorFooterFirstPage.getData();
                    // $scope.overdueInvoiceText.settingValue3 = CKEDITOR.instances.editorOverdueInvoiceText.getData();
                    // $scope.notOverdueInvoiceFooter.settingValue3 = CKEDITOR.instances.editorFooterExtraPage.getData();
                    // if (+$scope.nonOverdueOpNewPage.settingValue1) {
                    //     $scope.notOverdueInvoiceText.settingValue3 = CKEDITOR.instances.editorNonOverdueTextExtraPage.getData();
                    // } else {
                    //     $scope.notOverdueInvoiceText.settingValue3 = CKEDITOR.instances.editorNonOverdueInvoiceTextFirstPage.getData();
                    // }
                    ctrl.onSaveTemplateClick({templateSettings: $scope.templateSettings});
                }

                /* SEPA related variables */
                if($scope.templateType === 'sepaDirectDebitTemplate'){
                    // $scope.mainSepaMessage.settingValue3 = CKEDITOR.instances.sepaMainTextField.getData();
                    // $scope.sepaFooter.settingValue3 = CKEDITOR.instances.editorFooterSepa.getData();
                    // ctrl.onSaveTemplateClick({templateSettings: $scope.templateSettings});
                }

                /* Lawyer report related variables */
                if($scope.templateType === 'lawyerReportTemplate'){
                    // $scope.headerLawyerReport.settingValue3 = CKEDITOR.instances.editorHeaderLawyerReport.getData();
                    // $scope.subHeaderLawyerReport.settingValue3 = CKEDITOR.instances.editorSubHeaderLawyerReport.getData();
                    // ctrl.onSaveTemplateClick({templateSettings: $scope.templateSettings});
                }

                /* Email template related variables */
                if($scope.templateType === 'emailTemplate'){
                    if ($scope.wysiwygEditorActivated.settingValue1 === '1') {
                        // let text = CKEDITOR.instances.editorEmailDisclaimer2.getData();
                        // if (!(text && text.includes('[emailText]'))) {
                        //     text = '<p>[emailText]</p>' + text;
                        // }
                        // $scope.emailDisclaimer.settingValue2 = window.btoa(text); // window.btoa() converts string to base64
                        if (!($scope.emailDisclaimer.settingValue2 && $scope.emailDisclaimer.settingValue2.includes('[emailText]'))) {
                            $scope.emailDisclaimer.settingValue2 = '<p>[emailText]</p>' + $scope.emailDisclaimer.settingValue2;
                        }
                        $scope.emailDisclaimer.settingValue2 = window.btoa($scope.emailDisclaimer.settingValue2);
                    } else {
                        // $scope.emailDisclaimer.settingValue2 = window.btoa(CKEDITOR.instances.editorEmailDisclaimer.getData()); // window.btoa() converts string to base64
                        $scope.emailDisclaimer.settingValue2 = window.btoa($scope.emailDisclaimer.settingValue2);
                    }
                    ctrl.onSaveTemplateClick({templateSettings: $scope.templateSettings});
                }

                /* PaymentTermAgreement-Text template related variables */
                if($scope.templateType === 'paymentTermAgreementText'){
                    // if (isReallyEmpty(CKEDITOR.instances.editorPaymentTermAgreementText.getData())) {
                    //     Notification.error("Das Feld muss ausgefüllt werden.");
                    // } else {
                    //     $scope.paymentTermAgreementText[0].settingValue1 = window.btoa(CKEDITOR.instances.editorPaymentTermAgreementText.getData()); // window.btoa() converts string to base64
                    //     ctrl.onSaveTemplateClick({templateSettings: $scope.paymentTermAgreementText});
                    // }
                    if (isReallyEmpty($scope.paymentTermAgreementText[0].settingValue1)) {
                        Notification.error("Das Feld muss ausgefüllt werden.");
                    } else {
                        $scope.paymentTermAgreementText[0].settingValue1 = window.btoa($scope.paymentTermAgreementText.settingValue1);
                        ctrl.onSaveTemplateClick({templateSettings: $scope.paymentTermAgreementText});
                    }
                }
            }

            function isReallyEmpty(htmlString) {
                const parser = new DOMParser();
                const doc = parser.parseFromString(htmlString, 'text/html');
                doc.body.querySelectorAll('script, style').forEach(el => el.remove());
                return !doc.body.textContent.trim();
            }
        }
    ]
}); 

angular.module('pdfTemplateSelector', []);
angular.module('pdfTemplateSelector').component('pdfTemplateSelector', {
    templateUrl: 'app/components/pdfTemplateSelector/pdfTemplateSelector.template.html',
    bindings: {
        settings: "<",
        onTemplateChanged: "&"
    },
    controller: [
        "$scope",
        "sharedMemory",
        "$location",
        "$filter",
        "$rootScope",
        "requestManager",
        "Notification",
        "$window",
        "util",
        function ($scope, sharedMemory, $location, $filter, $rootScope, requestManager, Notification, $window, util) {
            const ctrl = this;
            init();

            function init(){
                initVariables();
                initScopeFunctions();
            }

            this.$onChanges = function(change){
                if(change.settings && change.settings.currentValue){
                    $scope.settings.all = change.settings.currentValue;
                    $scope.wysiwygEditorActivated = $scope.settings.all.find(setting => setting.settingCode === 'wysiwygEditorActivated');
                    $scope.settings.loaded = true;
                }
            };

            function initScopeFunctions(){
                $scope.onTemplateTypeChanges = onTemplateTypeChanges;
                $scope.onTemplateLanguageChanged = onTemplateLanguageChanged;
                $scope.onDunningLevelChanged = onDunningLevelChanged;
                $scope.changeTemplateTypeVariable = changeTemplateTypeVariable;
                $scope.addLanguage = addLanguage;
                $scope.sendCreateSettingRequest = sendCreateSettingRequest;
                $scope.onAddLanguageButtonClick = onAddLanguageButtonClick;
                $scope.onRemoveLanguageButtonClick = onRemoveLanguageButtonClick;
                $scope.createRemovableTemplateList = createRemovableTemplateList;
                $scope.onRemoveLanguageClick = onRemoveLanguageClick;
                $scope.sendRemoveSettingRequest = sendRemoveSettingRequest;
                $scope.translateISO2Language = translateISO2Language;
            }

            function initVariables() {
                $scope.settings = {
                    loaded: false,
                    all: undefined,
                    availableLanguagesOfTemplateType: undefined,
                    pdfTemplatesOfTemplateType: undefined,
                };
                $scope.wysiwygEditorActivated = undefined;

                $scope.templateTypes = [
                    {
                        key: "reminderTemplate",
                        value: "Mahnung",
                        dunningLevels: [
                            {key: 1, value: "1. Mahnung"},
                            {key: 2, value: "2. Mahnung"},
                            {key: 3, value: "3. Mahnung"},
                        ]
                    },
                    {
                        key: "paymentTermAgreementText",
                        value: "Zahlungsvereinbarung",
                    },
                    {
                        key: "emailTemplate",
                        value: "E-Mail-Vorlage",
                    },
                ];

                $scope.view = {
                    selectedTemplateType: undefined,
                    selectedLanguage: undefined,
                    selectedDunningLevel: undefined
                };

                $scope.selectedTemplateVariable = {
                    templateType: undefined,
                    language: undefined,
                    dunningLevel: undefined,
                };

                $scope.templateTypeOptions = [
                    {
                        key: "NO_SELECTION",
                        text: "",
                        hasDunningLevelOptions: false
                    },
                    // TODO: DECOMMENT WHEN READD EMAIL-ADD-LANGUAGE
                    // {
                    //     key: "reminderTemplate",
                    //     text: $filter("translate")("templateEditor.DEMAND_NOTE_TEMPLATE"),
                    //     hasDunningLevelOptions: true
                    // },
                    // {
                    //     key: "sepaDirectDebitTemplate",
                    //     text: $filter("translate")("templateEditor.SEPA_MANDAT_TEMPLATE"),
                    //     hasDunningLevelOptions: false
                    // },
                    // {
                    //     key: "emailTemplate",
                    //     text: $filter("translate")("templateEditor.EMAIL_TEMPLATE"),
                    //     hasDunningLevelOptions: true
                    // },
                    // {
                    //     key: "emailTemplate1",
                    //     text: $filter("translate")("templateEditor.EMAIL_TEMPLATE"),
                    //     hasDunningLevelOptions: true
                    // },
                    // {
                    //     key: "lawyerReportTemplate",
                    //     text: $filter("translate")("templateEditor.LAWYER_REPORT_TEMPLATE"),
                    //     hasDunningLevelOptions: false
                    // }
                ];

                $scope.dunningLevelOptions = [
                    {key: "NO_SELECTION", text: ""},
                    {key: "1", text: $filter("translate")("templateEditor.DUNNING_LEVEL_ONE")},
                    {key: "2", text: $filter("translate")("templateEditor.DUNNING_LEVEL_TWO")},
                    {key: "3", text: $filter("translate")("templateEditor.DUNNING_LEVEL_THREE")}
                ];
                $scope.languageOptions = angular.copy(languageCodesV2);
                $scope.languageOptions.unshift({iso639v1: "NO_SELECTION", iso639v1: "NO_SELECTION", english: "", french: "", german: ""});

                $scope.addLanguageModal = {
                    selectedTemplateType: $scope.templateTypeOptions.find(function(type){ return type.key == "NO_SELECTION"}),
                    selectedLanguage: $scope.languageOptions.find(function(option){ return option.iso639v1 === "NO_SELECTION"}),
                    currentlyLoading: false
                };

                $scope.removeLanguageModal = {
                    selectedTemplate: $scope.templateTypeOptions.find(function(type){return type.key == "NO_SELECTION"})
                };

                $scope.removeableTemplateOptions = undefined;
            }

            function getPdfTemplateSettingsOfType(allSettings, type){
                return allSettings.filter((setting) => {
                    return setting.settingCode.startsWith(type);
                });
            }

            function getAvailableLanguages(settings){
                const foundLanguages = [];
                settings.forEach((setting) => {
                    if(!foundLanguages.includes(setting.settingValue1) && setting.settingValue1 != null){
                        foundLanguages.push(setting.settingValue1);
                    }
                });
                return foundLanguages;
            }

            function changeTemplateTypeVariable(newValue, type){
               if (type == 1){
                   $scope.selectedTemplateVariable.templateType = newValue;
                   $scope.selectedTemplateVariable.language = undefined;
                   $scope.selectedTemplateVariable.dunningLevel = undefined;
               }
                if (type == 2){
                    $scope.selectedTemplateVariable.language = newValue;
                }
                if (type == 3){
                    $scope.selectedTemplateVariable.dunningLevel = newValue;
                    $scope.selectedTemplateVariable.language = undefined;
                }
            }

            function onTemplateTypeChanges(key){
                if(key.startsWith('emailTemplate'))
                    key += '1';
                $scope.view.selectedLanguage = undefined;
                $scope.view.selectedDunningLevel = undefined;
                $scope.settings.pdfTemplatesOfTemplateType = getPdfTemplateSettingsOfType($scope.settings.all, key);
                if(key === 'paymentTermAgreementText'){
                    $scope.settings.availableLanguagesOfTemplateType = ['Default']
                }else{
                    $scope.settings.availableLanguagesOfTemplateType = getAvailableLanguages($scope.settings.pdfTemplatesOfTemplateType);
                }
                ctrl.onTemplateChanged({templateSettings: undefined, templateType: $scope.view.selectedTemplateType.key});
            }

            function onTemplateLanguageChanged(){
                if(isTemplateLanguageSet()){
                    // TODO emailTemplate -> emailTemplate1 (wysiwygEditorActivated setting)
                    // GET emailTemplate1 setting -> problem: settingValue1 = Default, settingValue2 = base64 (opposite of emailTemplate setting)
                    const matchingTemplateSetting = getTemplateSettingsWithMatchingCriteria($scope.view.selectedTemplateType.key, $scope.view.selectedLanguage, undefined);
                    ctrl.onTemplateChanged({templateSettings: matchingTemplateSetting, templateType: $scope.view.selectedTemplateType.key});
                }
                if(hasTemplateTypeDunningLevelOptions($scope.view.selectedTemplateType) && isDunningLevelSet()){
                    const matchingTemplateSetting = getTemplateSettingsWithMatchingCriteria($scope.view.selectedTemplateType.key, $scope.view.selectedLanguage, $scope.view.selectedDunningLevel.key);
                    ctrl.onTemplateChanged({templateSettings: matchingTemplateSetting, templateType: $scope.view.selectedTemplateType.key});
                }
            }

            function onDunningLevelChanged(){
                $scope.view.selectedLanguage = undefined;
                if(isTemplateLanguageSet() && isDunningLevelSet()){
                    const matchingTemplateSetting = getTemplateSettingsWithMatchingCriteria($scope.view.selectedTemplateType.key, $scope.view.selectedLanguage, $scope.view.selectedDunningLevel.key);
                    ctrl.onTemplateChanged({templateSettings: matchingTemplateSetting, templateType: $scope.view.selectedTemplateType.key});
                } else {
                    ctrl.onTemplateChanged({templateSettings: undefined, templateType: $scope.view.selectedTemplateType.key});
                }
            }

            function hasTemplateTypeDunningLevelOptions(templateType){
                return !!templateType.dunningLevels;
            }

            function isTemplateLanguageSet(){
                return !!$scope.view.selectedLanguage;
            }

            function isDunningLevelSet(){
                return !!$scope.view.selectedDunningLevel;
            }

            function getTemplateSettingsWithMatchingCriteria(templateType, language, dunningLevel){
                if(templateType === 'paymentTermAgreementText'){
                    return $scope.settings.all.filter((setting) => {
                        return setting.settingCode.startsWith(templateType);
                    });
                }else{
                    if(angular.isDefined(dunningLevel)){
                        return $scope.settings.all.filter((setting) => {
                            return setting.settingCode.startsWith(templateType) && setting.settingValue1 === language && setting.settingCode.endsWith(dunningLevel.toString());
                        });
                    }
                    else{
                        return $scope.settings.all.filter((setting) => {
                            return setting.settingCode.startsWith(templateType) && setting.settingValue1 === language;
                        });
                    }
                }
            }

            function onAddLanguageButtonClick(){
                jQuery("#addLanguageModal").appendTo("body").modal("show");
                const defaultTemplate = getTemplateSettingsWithMatchingCriteria('reminderTemplate3', 'Default', undefined);
            }

            function addLanguage(language, template){
                var params = [];
                $scope.addLanguageModal.currentlyLoading = true;

                switch(template.key){
                    case 'reminderTemplate':
                        for(var i = 1; i < 4; i++){
                            const defaultTemplate = getTemplateSettingsWithMatchingCriteria(template.key + i, 'Default', undefined);
                            angular.forEach(defaultTemplate, (item) => {
                                params.push({settingCode: template.key + i, settingValue1: language, settingValue2: item.settingValue2, settingValue3: item.settingValue3})
                            });
                        }
                        break;
                    case 'emailTemplate':
                        params = [
                            {settingCode: template.key, settingValue1: language, settingValue2: 'else', settingValue3: "Standardwert"},
                            {settingCode: template.key + '1', settingValue1: language, settingValue2: 'disclaimerField', settingValue3: "Standardwert"},
                        ];
                        break;
                    case 'sepaDirectDebitTemplate':
                        params = [
                            {settingCode: template.key, settingValue1: language, settingValue2: 'mainSepaMessage', settingValue3: "Standardwert"},
                            {settingCode: template.key, settingValue1: language, settingValue2: 'sepaFooter', settingValue3: "Standardwert"},
                        ];
                        break;
                    case 'lawyerReportTemplate':
                        params = [
                            {settingCode: template.key, settingValue1: language, settingValue2: 'headerLawyerReport', settingValue3: "Standardwert"},
                            {settingCode: template.key, settingValue1: language, settingValue2: 'subHeaderLawyerReport', settingValue3: "Standardwert"},
                        ];
                        break;
                }
                sendCreateSettingRequest($rootScope.authData.sessionId, params, function(requestDetails){
                    if(requestDetails.allSuccessful){
                       $window.location.reload();
                    }
                    else{
                        jQuery("#addLanguageModal").one("hidden.bs.modal", function(){
                            sharedMemory.error.setError(
                                requestDetails,
                                "COULD_NOT_LOAD_DATA",
                                "Fehler beim erstellen von Settings",
                                $location.path(),
                                "templateEditor"
                            );
                            $location.path("/error-page");
                        }).modal("hide");
                    }
                });
            }

            function sendCreateSettingRequest(sessionId, paramsArray, callback){
                var requestDetails ={
                    numRequestsPlanned: paramsArray.length,
                    allSuccessful: true,
                    responses: [],
                    successfulResponses: [],
                    errorResponses: [],
                };

                paramsArray.forEach(function(params){
                    requestManager.routes.settings.createSettingWithKey(sessionId, params).then(
                        function(response){
                            requestDetails.responses.push(response);
                            requestDetails.successfulResponses.push(response);
                            if(requestDetails.responses.length == requestDetails.numRequestsPlanned){
                                callback(requestDetails);
                            }
                        },
                        function(response){
                            requestDetails.responses.push(response);
                            requestDetails.errorResponses.push(response);
                            requestDetails.allSuccessful = false;
                            if(requestDetails.responses.length == requestDetails.numRequestsPlanned){
                                callback(requestDetails);
                            }
                        }
                    )
                })
            }

            function onRemoveLanguageButtonClick(){
                jQuery("#removeLanguageModal").appendTo("body").modal("show");
                $scope.removeableTemplateOptions = createRemovableTemplateList();
            }

            function onRemoveLanguageClick(template){
                if(template.key === "NO_SELECTION"){
                    Notification.error("Es wurde keine Sprache ausgewählt!");
                    return;
                }
                var templatesOfType = util.getSettingsWithMatchingRegex($scope.settings.all, new RegExp(template.templateType));
                var templatesToRemove = templatesOfType.filter(function(templateToCompare){ return templateToCompare.settingValue1 == template.language.iso639v1});

                sendRemoveSettingRequest($rootScope.authData.sessionId, templatesToRemove, function(requestDetails){
                    if(requestDetails.allSuccessful){
                        jQuery("#removeLanguageModal").one("hidden.bs.modal", function(){
                            Notification.success($filter("translate")("templateEditor.SAVED_CHANGES"));
                            $window.location.reload();
                        }).modal("hide");
                    }
                    else{
                        jQuery("#removeLanguageModal").one("hidden.bs.modal", function(){
                            if (!requestDetails.canceledRequest) {
                                sharedMemory.error.setError(
                                    requestDetails,
                                    "COULD_NOT_LOAD_DATA",
                                    "Fehler beim löschen von Settings",
                                    $location.path(),
                                    "templateEditor"
                                );
                                $location.path("/error-page");
                            }
                        }).modal("hide");
                    }
                });
            }

            function createRemovableTemplateList(){
                var availableSettings = $scope.templateTypeOptions.filter(function(value){ return value; });
                var removableObjectList = [];

                availableSettings.forEach(function(setting){
                    var settings = util.getSettingsWithMatchingRegex($scope.settings.all, new RegExp(setting.key));
                    var languages = [];
                    settings.forEach(function(setting){
                        if(languages.indexOf(setting.settingValue1) == -1 && setting.settingValue1 != "Default"){
                            languages.push(setting.settingValue1);
                        }
                    });

                    languages.forEach(function(language){
                        removableObjectList.push({
                            templateType: setting.key,
                            language: $scope.languageOptions.find(function(languageOption){return languageOption.iso639v1 === language}),
                            hasDunningLevelOptions: setting.hasDunningLevelOptions,
                            text: setting.text
                        });
                    });
                });

                return removableObjectList;
            }

            function sendRemoveSettingRequest(sessionId, paramsArray, callback){
                var requestDetails ={
                    numRequestsPlanned: paramsArray.length,
                    allSuccessful: true,
                    responses: [],
                    successfulResponses: [],
                    errorResponses: [],
                    canceledRequest: false
                }
                paramsArray.forEach(function(params){
                    requestManager.routes.settings.deleteSetting(sessionId, params.id).then(
                        function(response){
                            requestDetails.responses.push(response);
                            requestDetails.successfulResponses.push(response);
                            if(requestDetails.responses.length == requestDetails.numRequestsPlanned){
                                callback(requestDetails);
                            }
                        },
                        function(response){
                            requestDetails.responses.push(response);
                            requestDetails.errorResponses.push(response);
                            requestDetails.allSuccessful = false;
                            if(!requestDetails.canceledRequest){
                                requestDetails.canceledRequest = response.status == -1;
                            }
                            if(requestDetails.responses.length == requestDetails.numRequestsPlanned){
                                callback(requestDetails);
                            }
                        }
                    )
                })
            }

            function translateISO2Language(language){
                if(language == "Default")
                    return language;
                const readableLanguage = $scope.languageOptions.find(function(languageOption){return languageOption.iso639v1 === language});
                return readableLanguage.german;
            }
        }
    ]
}); 

angular.module('analysisComp', []);
angular.module('analysisComp').component('analysisComp', {
    templateUrl: 'app/components/analysis/analysisComp.template.html',
    controller: [
        "$scope",
        "$rootScope",
        "requestManager",
        "$location",
        "$routeParams",
        "workflowUtil",
        "$filter",
        "Notification",
        "sharedMemory",
        "$window",
        function ($scope, $rootScope, requestManager, $location, $routeParams, workflowUtil, $filter, Notification, sharedMemory, $window) {
            onInit();

            function onInit() {
                $scope.europeanNumberFormat = 'en-US';
                if ($rootScope.europeanNumberFormat !== undefined) {
                    if ($rootScope.europeanNumberFormat === '1' || $rootScope.europeanNumberFormat === 1) {
                        $scope.europeanNumberFormat = 'de-AT';
                    } else {
                        $scope.europeanNumberFormat = 'en-US';
                    }
                }
                initVariables();
                initScope();
                loadData($rootScope.authData.sessionId, function (requestDetails) {
                    if (requestDetails.allSuccessful) {
                        $scope.prognosticatedPayments.currentlyLoading = false;
                        $scope.prognosticatedPayments.content = requestDetails.response.data;
                        $scope.prognosticatedPayments.content.opActualOverdue = +$scope.prognosticatedPayments.content.opActualOverdue;
                        $scope.prognosticatedPayments.content.opActual = +$scope.prognosticatedPayments.content.opActual;
                        $scope.prognosticatedPayments.content.opActualOverdue30 = +$scope.prognosticatedPayments.content.opActualOverdue30;
                        $scope.prognosticatedPayments.content.opActualOverdue60 = +$scope.prognosticatedPayments.content.opActualOverdue60;
                        $scope.prognosticatedPayments.content.opActualOverdue90 = +$scope.prognosticatedPayments.content.opActualOverdue90;

                        if ($scope.prognosticatedPayments.content.prognosisPaymentTerm == null) {
                            $scope.prognosticatedPayments.content.prognosisPaymentTerm = 0;
                        }
                        $scope.companyName = $rootScope.settings.filter((setting) => {
                            return setting.settingCode === 'companyName';
                        });

                        $scope.openPayments.overdue = ($scope.prognosticatedPayments.content.percentageOverdueOp * 100).toFixed(2);
                        $scope.openPayments.overdueLessThan30 = ($scope.prognosticatedPayments.content.percentageOverdueUntil30ofOverdueOp * 100).toFixed(2);
                        $scope.openPayments.overdueBetween30and60 = ($scope.prognosticatedPayments.content.percentageOverdueBetween30and60ofOverdueOp * 100).toFixed(2);
                        $scope.openPayments.overdueBetween60and90 = ($scope.prognosticatedPayments.content.percentageOverdueBetween60and90ofOverdueOp * 100).toFixed(2);
                        $scope.openPayments.overdueMoreThan90 = ($scope.prognosticatedPayments.content.percentageOverdueAfter90ofOverdueOp * 100).toFixed(2);

                        $scope.paymentTerms.amountTerms = $scope.prognosticatedPayments.content.amountPaymentTerms;
                        $scope.paymentTerms.amountOp = +$scope.prognosticatedPayments.content.amountPaymentTermsOp;

                        $scope.problematicCustomers.amountTerms = $scope.prognosticatedPayments.content.amountProblematicCustomer;
                        $scope.problematicCustomers.amountOp = +$scope.prognosticatedPayments.content.amountProblematicCustomerOp;

                        $scope.topFiveValues.oldest = $scope.prognosticatedPayments.content.getTop5Oldest;
                        $scope.topFiveValues.openPositions = $scope.prognosticatedPayments.content.getTop5Op;
                        $scope.topFiveValues.openPositions.forEach(value => {
                            value.saldo = +value.saldo;
                        });

                        //console.log($scope.prognosticatedPayments.content);
                    } else {
                        if (!requestDetails.canceledRequest) {
                            sharedMemory.error.setError(
                                requestDetails,
                                "COULD_NOT_LOAD_DATA",
                                "Fehler beim Laden der Systemstatistiken (beim initiieren der Komponente)",
                                $location.path(),
                                "prognosticatedPayments"
                            );
                            $location.path("/error-page");
                        }
                    }
                });

                loadStatistics($rootScope.authData.sessionId, function (requestDetails) {
                    if (requestDetails.allSuccessful) {
                        $scope.statistics.content = requestDetails.response.data;
                        $scope.statistics.currentlyLoading = false;
                        setDaysSalesOutstanding();
                        $scope.finishedLoading = true;
                    } else {
                        if (!requestDetails.canceledRequest) {
                            sharedMemory.error.setError(
                                requestDetails,
                                "COULD_NOT_LOAD_DATA",
                                "Fehler beim laden der Statistiken (beim initiieren der Komponente)",
                                $location.path(),
                                "overviewStatistics"
                            );
                            $location.path("/error-page");
                        }
                    }
                });
            }

            function initVariables() {
                $scope.currentTab = 0;
                $scope.currentStatisticsTab = 0;
                $scope.companyName = undefined;
                $scope.finishedLoading = false;
                $scope.prognosticatedPayments = {
                    currentlyLoading: true,
                    content: []
                };
                $scope.statistics = {
                    currentlyLoading: true,
                    content: {}
                };
                $scope.openPayments = {
                    overdue: undefined,
                    overdueLessThan30: undefined,
                    overdueBetween30and60: undefined,
                    overdueBetween60and90: undefined,
                    overdueMoreThan90: undefined
                };
                $scope.paymentTerms = {
                    amountTerms: undefined,
                    amountOp: undefined,
                };
                $scope.problematicCustomers = {
                    amountTerms: undefined,
                    amountOp: undefined,
                };
                $scope.chart = {
                    labels: [],
                    data: [[]],
                };
                $scope.topFiveValues = {
                    oldest: undefined,
                    openPositions: undefined,
                };
                $scope.changedDues = {
                    content: undefined,
                };

                $scope.changedDuesView = {};
            }

            function initScope() {
                $scope.setTabIndexTo = setTabIndexTo;
                $scope.setStatisticsTabIndexTo = setStatisticsTabIndexTo;
                $scope.setStatisticsPieChartData = setStatisticsPieChartData;
                $scope.loadData = loadData;
                $scope.loadStatistics = loadStatistics;
                $scope.forwardToUserPage = forwardToUserPage;
                $scope.toggleSubtable = toggleSubtable;
                $scope.printDebtsAgeReport = printDebtsAgeReport;
                $scope.printPaymentTermReport = printPaymentTermReport;
            }

            function toggleSubtable(customerId) {
                if ($scope.changedDuesView[customerId] === undefined || $scope.changedDuesView[customerId].isOpened === undefined)
                    $scope.changedDuesView[customerId].isOpened = true;
                else
                    $scope.changedDuesView[customerId].isOpened = !$scope.changedDuesView[customerId].isOpened;
            }

            function setTabIndexTo(index) {
                $scope.currentTab = index;
                if (index === 1) { //CHANGED DUES
                    getCustomerVirtualStalled($rootScope.authData.sessionId);
                }
            }

            function initChangedDuesView() {
                _.values($scope.changedDues.content).forEach(
                    function (value) {
                        $scope.changedDuesView[value.id] = {isOpened: false};
                    }
                )
                console.log($scope.changedDuesView);

            }

            function getCustomerVirtualStalled(sessionId) {
                requestManager.routes.customer.virtual.getStalledCustomer(sessionId)
                    .then(
                        function (response) {
                            $scope.changedDues.content = response.data;
                            console.log($scope.changedDues.content);
                            initChangedDuesView();
                        },
                        function (response) {
                            console.warn(response);
                        }
                    );
            }

            function setStatisticsTabIndexTo(index) {
                if (index == 1) {
                    setStatisticsPieChartData();
                }
                $scope.currentStatisticsTab = index;
            }

            function setStatisticsPieChartData() {
                new Chart(document.getElementById("statisticsPiechart"), {
                    type: 'pie',
                    data: {
                        labels: ["Davon weniger als 30 Tage", "Davon mehr als 30 Tage", "Davon mehr als 60 Tage", "Davon mehr als 90 Tage"],
                        datasets: [{
                            backgroundColor: ["#66ff66", "#ffff80", "#ff8533", "#e60000"],
                            data: [$scope.openPayments.overdueLessThan30, $scope.openPayments.overdueBetween30and60, $scope.openPayments.overdueBetween60and90, $scope.openPayments.overdueMoreThan90]
                        }]
                    },
                    options: {
                        title: {
                            display: true,
                            text: 'Überfällige offene Posten in %'
                        },
                        legend: {
                            display: true,
                            position: 'right'
                        }
                    }
                });
            }

            function setDaysSalesOutstanding() {
                $scope.chart.series = ['Offene Posten', 'Umsatz'];
                $scope.chart.colors = ['#45b7cd', '#EEEE00'];
                $scope.chart.options = {
                    legend: {
                        display: true,
                        labels: {
                            fontColor: '#000'
                        }
                    }
                };
                $scope.chart.data = [[], []];
                $scope.chart.labels = [];
                angular.forEach($scope.statistics.content.opHistorical, function (value, key) {
                    $scope.chart.labels.push(key);
                    $scope.chart.data[0].push(+parseFloat(value).toFixed(2));
                });

                angular.forEach($scope.statistics.content.turnover, function (value, key) {
                    if (value == null)
                        $scope.chart.data[1].push(0);
                    else
                        $scope.chart.data[1].push(+parseFloat(value).toFixed(2));
                });
            }

            function forwardToUserPage(userId) {
                $location.path("/user-details/" + userId);
            }

            function loadData(sessionId, callback) {
                var requestDetails = {
                    allSuccessful: true,
                    response: undefined,
                    canceledRequest: false
                };

                requestManager.routes.system.getSystemStatistics(sessionId).then(
                    function (response) {
                        requestDetails.response = response;
                        callback(requestDetails);
                    },
                    function (response) {
                        requestDetails.response = response;
                        requestDetails.allSuccessful = false;
                        requestDetails.canceledRequest = response.status == -1;
                        callback(requestDetails);
                    }
                )
            }

            function loadStatistics(sessionId, callback) {
                var requestDetails = {
                    allSuccessful: true,
                    response: undefined,
                    canceledRequest: false
                };

                requestManager.routes.system.getHistoricalSystemStatistics(sessionId).then(
                    function (response) {
                        requestDetails.response = response;
                        callback(requestDetails);
                    },
                    function (response) {
                        requestDetails.response = response;
                        requestDetails.allSuccessful = false;
                        requestDetails.canceledRequest = response.status == -1;
                        callback(requestDetails);
                    }
                )
            }

            function printDebtsAgeReport() {
                //check what browser the user is surfing on
                var ua = window.navigator.userAgent;
                var msie = ua.indexOf("MSIE ");

                var IEfound = false;
                var EdgeFound = /Edge/.test(window.navigator.userAgent);

                if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./))  // If Internet Explorer
                {
                    IEfound = true;
                }

                if (!IEfound && !EdgeFound) {
                    var tabWindow = $window.open('about:blank', '_blank');
                    tabWindow.document.write('loading...');
                }

                var notificationReference = Notification.warning({
                    message: $filter("translate")("wrapper.LOADING_PDF"),
                    delay: null,
                    closeOnClick: false,
                });

                if ($rootScope.pricingModel === 'v2' || $rootScope.pricingModel === 'v2+') {
                    requestManager.routes.report.debtsAgeV2($rootScope.authData.sessionId, $routeParams.id).then(
                        function (response) {
                            var file = new Blob([response.data], {type: 'application/pdf'});

                            if (!IEfound && !EdgeFound) {
                                var fileURL = URL.createObjectURL(file);
                                tabWindow.location.href = fileURL;
                            }
                            if (IEfound || EdgeFound) {
                                let r = "";
                                for (let i = 0; i < 4; i++) {
                                    if (i % 4 == 0)
                                        r += Math.random().toString(36).substring(8);
                                    else {
                                        r += "-";
                                        r += Math.random().toString(36).substring(4);
                                    }
                                }
                                $window.navigator.msSaveOrOpenBlob(file, r + ".pdf");
                            }

                            //$window.open($sce.trustAsResourceUrl(fileURL), "_self");
                            notificationReference.then(function (notification) {
                                notification.kill(false);
                            });
                        },
                        function (response) {
                            console.log(response);
                            Notification.error($filter("translate")("wrapper.NOTIFICATION_ERROR"));

                            notificationReference.then(function (notification) {
                                notification.kill(false);
                            });
                        }
                    );
                } else {
                    requestManager.routes.report.debtsAge($rootScope.authData.sessionId, $routeParams.id).then(
                        function (response) {
                            var file = new Blob([response.data], {type: 'application/pdf'});

                            if (!IEfound && !EdgeFound) {
                                var fileURL = URL.createObjectURL(file);
                                tabWindow.location.href = fileURL;
                            }
                            if (IEfound || EdgeFound) {
                                let r = "";
                                for (let i = 0; i < 4; i++) {
                                    if (i % 4 == 0)
                                        r += Math.random().toString(36).substring(8);
                                    else {
                                        r += "-";
                                        r += Math.random().toString(36).substring(4);
                                    }
                                }
                                $window.navigator.msSaveOrOpenBlob(file, r + ".pdf");
                            }

                            //$window.open($sce.trustAsResourceUrl(fileURL), "_self");
                            notificationReference.then(function (notification) {
                                notification.kill(false);
                            });

                        },
                        function (response) {
                            console.log(response);
                            Notification.error($filter("translate")("wrapper.NOTIFICATION_ERROR"));

                            notificationReference.then(function (notification) {
                                notification.kill(false);
                            });
                        }
                    );
                }
            }

            function printPaymentTermReport() {
                //check what browser the user is surfing on
                var ua = window.navigator.userAgent;
                var msie = ua.indexOf("MSIE ");

                var IEfound = false;
                var EdgeFound = /Edge/.test(window.navigator.userAgent);

                if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./))  // If Internet Explorer
                {
                    IEfound = true;
                }

                if (!IEfound && !EdgeFound) {
                    var tabWindow = $window.open('about:blank', '_blank');
                    tabWindow.document.write('loading...');
                }

                var notificationReference = Notification.warning({
                    message: $filter("translate")("wrapper.LOADING_PDF"),
                    delay: null,
                    closeOnClick: false,
                });
                requestManager.routes.report.paymentTerm($rootScope.authData.sessionId, $routeParams.id).then(
                    function (response) {
                        var file = new Blob([response.data], {type: 'application/pdf'});

                        if (!IEfound && !EdgeFound) {
                            var fileURL = URL.createObjectURL(file);
                            tabWindow.location.href = fileURL;
                        }
                        if (IEfound || EdgeFound) {
                            let r = "";
                            for (let i = 0; i < 4; i++) {
                                if (i % 4 == 0)
                                    r += Math.random().toString(36).substring(8);
                                else {
                                    r += "-";
                                    r += Math.random().toString(36).substring(4);
                                }
                            }
                            $window.navigator.msSaveOrOpenBlob(file, r + ".pdf");
                        }

                        //$window.open($sce.trustAsResourceUrl(fileURL), "_self");
                        notificationReference.then(function (notification) {
                            notification.kill(false);
                        });

                    },
                    function (response) {
                        console.log(response);
                        Notification.error($filter("translate")("wrapper.NOTIFICATION_ERROR"));

                        notificationReference.then(function (notification) {
                            notification.kill(false);
                        });
                    }
                )
            }

        }
    ]
});

angular.module('userDetailsFileUpload', []);
angular.module('userDetailsFileUpload').component('userDetailsFileUpload', {
    templateUrl: 'app/components/userDetailsFileUpload/userDetailsFileUpload.template.html',
    bindings: {
        customer: "<"
    },
    controller: [
        "$scope",
        "Notification",
        "$rootScope",
        "$location",
        "$window",
        "requestManager",
        "util",
        "$filter",
        "sharedMemory",
        "$route",
        function ($scope, Notification, $rootScope, $location, $window, requestManager, util, $filter, sharedMemory, $route) {
            init();

            function init(){
                initVariables();
                initScope();
            }

            this.$onChanges = function(item) {
                if (item.customer && item.customer.currentValue) {
                    $scope.customer.content = angular.copy(item.customer.currentValue);
                    $scope.customer.content.updated = false;
                    $scope.customer.currentlyLoading = false;
                }

                if (!$scope.customer.currentlyLoading && !$scope.finishedLoadingFilesFromBackend) {
                    getUploadedFilesFromBackend($rootScope.authData.sessionId, $scope.customer.content.id);
                }
            };

            function initScope(){
                $scope.getBasicAttachment = getBasicAttachment;
                $scope.downloadSelectedFileFromBackend = downloadSelectedFileFromBackend;
                $scope.removeSelectedFile = removeSelectedFile;
                $scope.onUploadButtonClicked = onUploadButtonClicked;
                $scope.uploadFile = uploadFile;
                $scope.getUploadedFilesFromBackend = getUploadedFilesFromBackend;
                $scope.onFileDescriptionButtonClick = onFileDescriptionButtonClick;
            }

            function initVariables(){
                $scope.customer = {
                    content: undefined,
                    currentlyLoading: true
                };
                $scope.dataFilesList = {
                    content: undefined,
                };
                $scope.finishedLoadingFilesFromBackend = false;
                $scope.fileUploadUnderway = false;

                $scope.files = {
                    desktop: undefined,
                    desktopDescription: undefined,
                    mobile: undefined,
                    mobileDescription: undefined,
                };
                $scope.uploadText = "Datei wird gerade hochgeladen...";
                $scope.infoModal = {
                    body: undefined
                }
            }

            function getUploadedFilesFromBackend(sessionId, customerId){
                requestManager.routes.fileupload.getAllUploadedFiles(sessionId, customerId).then(
                    (response) => {
                        if(response.data !== 'null'){
                            $scope.dataFilesList.content = response.data;
                        }
                        $scope.finishedLoadingFilesFromBackend = true;
                    },
                    () => {
                        console.warn("Error trying to retrieve all uploaded files from backend");
                    }
                )
            }

            function onLogoConverted(base64){
                if(base64){
                    $scope.files.desktop = base64;
                    uploadFile($rootScope.authData.sessionId, $scope.customer.content.id, {file: $scope.files.desktop, description: $scope.files.desktopDescription});
                }
            }

            function onLogoConversionFailed(){
                console.log("File conversion failed");
            }

            function getBasicAttachment(platformParameter){
                if(platformParameter === 'desktop'){
                    $scope.files.desktop = jQuery('#basicFileUpload').prop('files')[0];

                    // if($scope.files.desktop.type === "image/png"){
                    //    util.convertFileToBase64($scope.files.desktop).then(
                    //        onLogoConverted,
                    //        onLogoConversionFailed
                    //    ) ;
                    // }
                    if($scope.files.desktop === undefined) {
                        Notification.warning("Keine Datei ausgewählt!");
                        return;
                    }

                    if ($scope.files.desktop.size > 1.5e+7){
                        Notification.warning("Datei ist zu groß! Max 15 MB erlaubt!");
                        return;
                    } else if($scope.files.desktopDescription === undefined){
                        Notification.warning("Beschreibungsfeld fehlt!");
                        return;
                    }
                    uploadFile($rootScope.authData.sessionId, $scope.customer.content.id, {file: $scope.files.desktop, description: $scope.files.desktopDescription});


                }
                else if(platformParameter === 'mobile'){
                    $scope.files.mobile = jQuery('#basicFileUploadMobile').prop('files')[0];

                    if($scope.files.mobile === undefined) {
                        Notification.warning("Keine Datei ausgewählt!");
                        return;
                    }

                    if($scope.files.mobile.size > 1.5e+7){
                        Notification.warning("Datei ist zu groß! Max 15 MB erlaubt!");
                        return;
                    } else if($scope.files.mobileDescription === undefined){
                        Notification.warning("Beschreibungsfeld fehlt!");
                        return;
                    }
                    uploadFile($rootScope.authData.sessionId, $scope.customer.content.id, {file: $scope.files.mobile, description: $scope.files.mobileDescription});
                }
                else {
                    return;
                }
            }

            function uploadFile(sessionId, customerId, params){
                $scope.fileUploadUnderway = true;
                setTimeout(() => {
                    $scope.uploadText = "Virencheck wird durchgeführt...";
                }, 2000);
                requestManager.routes.fileupload.uploadFile(sessionId, customerId, params).then(
                    (response) => {
                        $scope.fileUploadUnderway = false;
                        Notification.success("Datei erfolgreich hochgeladen");
                        $route.reload();
                    },
                    (response) => {
                        console.log("Error trying to upload file to backend");
                    }
                )
            }

            function downloadSelectedFileFromBackend(file){
                var tabWindow = $window.open('about:blank', '_blank');
                tabWindow.document.write('Datei wird heruntergeladen. Fenster schließt in Kürze...');

                requestManager.routes.fileupload.downloadSelectedFile($rootScope.authData.sessionId, file.id).then(
                    (response) => {
                        let filename = file.filename;
                        let guId = response.data;
                        let fileURL = requestManager.getAuthPath()+ '/download/' + guId;

                        tabWindow.location.href = fileURL;
                        setTimeout(() => {
                            tabWindow.close();
                        }, 1500);
                        //var contentType = response.headers('content-type');
                        // var contentType = "application/octet-stream";
                        // var linkElement = document.createElement('a');
                        //
                        // try {
                        //     var blob = new Blob([response.data], { type: contentType });
                        //     var url = window.URL.createObjectURL(blob);
                        //
                        //     linkElement.setAttribute('href', url);
                        //     linkElement.setAttribute("download", filename);
                        //
                        //     var clickEvent = new MouseEvent("click", {
                        //         "view": window,
                        //         "bubbles": true,
                        //         "cancelable": false
                        //     });
                        //     linkElement.dispatchEvent(clickEvent);
                        // } catch (ex) {
                        //     console.log(ex);
                        // }
                    },
                    () => {
                        console.warn("Error trying to download single file from backend");
                    }
                )
            }

            function removeSelectedFile(fileId){
                let confirmValue = confirm($filter("translate")("userDetailsFileUpload.REMOVE_FILE_CONFIRMATION_TEXT"));
                if(confirmValue){
                    requestManager.routes.fileupload.deleteSelectedFile($rootScope.authData.sessionId, fileId).then(
                        () => {
                            Notification.success("Datei wurde erfolgreich gelöscht!");
                            $route.reload();
                        },
                        () => {
                            console.warn("Error trying to delete file in backend");
                        }
                    )
                }
            }

            function onUploadButtonClicked(platform){
                getBasicAttachment(platform);
            }

            function onFileDescriptionButtonClick(desc){
                $scope.infoModal.body = desc;
                jQuery("#fileDescriptionModal").modal("show");
            }

        }
    ]
});

angular.module('emailCenterListIncoming', []);
angular.module('emailCenterListIncoming').component('emailCenterListIncoming', {
    templateUrl: 'app/components/emailCenterListIncoming/emailCenterListIncoming.template.html',
    bindings: {
        dashboardWidget: "<",
    },
    controller: [
        "$scope",
        "requestManager",
        "$rootScope",
        "$location",
        "util",
        "sharedMemory",
        "$filter",
        "Notification",
        "$route",
        "cookieManager",
        "paginationManager",
        "$window",
        function ($scope, requestManager, $rootScope, $location, util, sharedMemory, $filter, Notification, $route, cookieManager, paginationManager, $window) {
            this.$onInit = function () {
                initVariables();
                initScope();
                $scope.incomingList.currentlyLoading = true;
                loadIncomingList($rootScope.authData.sessionId, function (requestDetails) {
                    if (requestDetails.successful) {
                        $scope.incomingList.content = requestDetails.response.data;
                        $scope.incomingList.currentlyLoading = false;
                        processUserListForFrontend();
                    } else {
                        // sharedMemory.error.setError(
                        //     requestDetails,
                        //     "COULD_NOT_LOAD_DATA",
                        //     "Fehler beim laden der Incominglinste nach klick auf 'refresh' Button",
                        //     $location.path(),
                        //     "admonishedClientsList"
                        // );
                        // $location.path("/error-page");
                    }
                });
                applySortBy('date');
            };

            this.$onChanges = function (item) {
                if (item && item.dashboardWidget && item.dashboardWidget.currentValue) {
                    $scope.isDashboardWidget = true;
                }
            }

            $scope.$watchGroup(["incomingList.currentlyLoading", "emailTemplates.currentlyLoading", "userSettings.currentlyLoading"], function () {
                if (!$scope.incomingList.currentlyLoading && !$scope.incomingList.currentlyLoading && !$scope.userSettings.currentlyLoading) {
                    $scope.allDependenciesLoaded = true;
                }
            });

            function initVariables() {
                $scope.allDependenciesLoaded = false;
                $scope.incomingList = {
                    currentlyLoading: true,
                    incomingList: undefined
                };
                $scope.incomingListModified = [];
                $scope.incomingListChunked = [];
                $scope.pagination = {
                    paginationIndex: paginationManager.readIndex() === undefined ? 0 : paginationManager.readIndex(),
                    paginationArray: []
                };
                $scope.filter = {
                    numShowEntriesOptions: ["10", "25", "50", "75", "100", "ALL"],
                };
                $scope.email = {
                    attachments: [],
                    recipientsList: undefined,
                    emailContent: undefined,
                    recordsCreated: 0,
                    recordsPending: undefined,
                    allRecordsCreated: false,
                    recordEmailCreated: 0,
                    recordEmailPending: undefined,
                    allRecordEmailCreated: false,
                    recordEmailRecipientCreated: 0,
                    recordEmailRecipientPending: undefined,
                    allRecordEmailRecipientsCreated: false,
                    attachReport: true
                };
                $scope.userSettings = {
                    currentlyLoading: false,
                    content: $rootScope.userSettings,
                    contentFiltered: getListSettings($rootScope.userSettings),
                };
                $scope.infoModal = {
                    body: undefined,
                    title: undefined
                };
                $scope.mailApprovalActivated = undefined;
                $scope.filterSettingId = undefined;
                $scope.floatTheadOptions = {
                    scrollContainer: true,
                    position: 'absolute'
                };
                $scope.emailModal = {
                    content: undefined
                };
                $scope.emailReplyModal = {
                    content: undefined
                }
                $scope.isDashboard = $location.$$url === '/dashboard';
            }

            function initScope() {
                $scope.$on('$routeChangeStart', function (next, current) {
                    requestManager.stopPendingRequests();
                    saveChangedSettings();
                });

                $scope.toggleFilter = toggleFilter;
                $scope.openEmailModal = openEmailModal;
                $scope.refreshUserList = refreshUserList;
                $scope.forwardToUserPage = forwardToUserPage;
                $scope.increasePaginationIndex = increasePaginationIndex;
                $scope.decreasePaginationIndex = decreasePaginationIndex;
                $scope.changeEmailTemplate = changeEmailTemplate;
                $scope.sendEmail = sendEmail;
                $scope.changeNumShowEntries = changeNumShowEntries;
                $scope.goToPage = goToPage;
                $scope.processUserListFrontEndCalledFromRowFilter = processUserListFrontEndCalledFromRowFilter;
                $scope.processUserListForFrontend = processUserListForFrontend;
                $scope.openEmailModal = openEmailModal;
                $scope.openReplyModal = openReplyModal;
                $scope.closeEmailModal = closeEmailModal;
                $scope.closeReplyModal = closeReplyModal;
                $scope.getEmailAttachmentAsPdf = getEmailAttachmentAsPdf;
                $scope.applySortBy = applySortBy;
            }

            function processUserListFrontEndCalledFromRowFilter() {
                $scope.incomingListModified = angular.copy($scope.incomingList.content);
                $scope.pagination.paginationIndex = 0;
                paginationManager.saveIndex($scope.pagination.paginationIndex);
                applyTextFilter();
                applyOrder();
                if (!$scope.isDashboardWidget) {
                    $scope.incomingListChunked = util.divideArrayIntoChunks($scope.incomingListModified, parseInt($scope.userSettings.contentFiltered.defaultNumRowsInList.value1));
                } else {
                    $scope.incomingListChunked = util.divideArrayIntoChunks($scope.incomingListModified, 10);
                }
                createPaginationArray();
            }

            function processUserListForFrontend() {
                $scope.incomingListModified = angular.copy($scope.incomingList.content);
                $scope.pagination.paginationIndex = paginationManager.readIndex() === undefined ? 0 : paginationManager.readIndex();
                applyTextFilter();
                applyOrder();
                if (!$scope.isDashboardWidget) {
                    $scope.incomingListChunked = util.divideArrayIntoChunks($scope.incomingListModified, parseInt($scope.userSettings.contentFiltered.defaultNumRowsInList.value1));
                } else {
                    $scope.incomingListChunked = util.divideArrayIntoChunks($scope.incomingListModified, 10);
                }
                createPaginationArray();
            }

            function applySortBy(filter){
                if($scope.filter.sortBy == filter){
                    $scope.filter.sortAscending = !$scope.filter.sortAscending;
                } else{
                    $scope.filter.sortAscending = false;
                }
                $scope.filter.sortBy = filter;
                processUserListForFrontend();
            }

            function applyOrder() {
                if (angular.isUndefined($scope.filter.sortBy)) {
                    return;
                }

                var sortType;

                switch ($scope.filter.sortBy) {
                    case "mailFromName":
                    case "mailToName":
                    case "subject":
                        sortType = "STRING";
                        break;
                    case "date":
                        sortType = "DATE";
                        break;
                }
                if ($scope.filter.sortAscending) {
                    switch (sortType) {
                        case "STRING":
                            $scope.incomingListModified.sort(function (a, b) {
                                if (!a[$scope.filter.sortBy]) {
                                    a[$scope.filter.sortBy] = 1;
                                }
                                if (!b[$scope.filter.sortBy]) {
                                    b[$scope.filter.sortBy] = -1;
                                }
                                return a[$scope.filter.sortBy].localeCompare(b[$scope.filter.sortBy]);
                            });
                            break;
                        case "NUMBER":
                            $scope.incomingListModified.sort(function (a, b) {
                                if (!a[$scope.filter.sortBy]) {
                                    a[$scope.filter.sortBy] = 0;
                                }
                                if (!b[$scope.filter.sortBy]) {
                                    b[$scope.filter.sortBy] = 0;
                                }
                                return parseFloat(a[$scope.filter.sortBy]) - parseFloat(b[$scope.filter.sortBy]);
                            });
                            break;
                        case "DATE":
                            if ($scope.incomingListModified === undefined || $scope.incomingListModified === null || $scope.incomingListModified === 'null') {
                                $scope.incomingListModified = [];
                            }
                            $scope.incomingListModified.sort(function (a, b) {
                                if (!a[$scope.filter.sortBy]) {
                                    return 1;
                                }
                                if (!b[$scope.filter.sortBy]) {
                                    return -1;
                                }
                                return (a[$scope.filter.sortBy]).localeCompare(b[$scope.filter.sortBy]);
                            });
                            break;
                    }
                } else {
                    switch (sortType) {
                        case "STRING":
                            $scope.incomingListModified.sort(function (b, a) {
                                if (!a[$scope.filter.sortBy]) {
                                    a[$scope.filter.sortBy] = "";
                                }
                                if (!b[$scope.filter.sortBy]) {
                                    b[$scope.filter.sortBy] = "";
                                }

                                return a[$scope.filter.sortBy].localeCompare(b[$scope.filter.sortBy]);
                            });
                            break;
                        case "NUMBER":
                            $scope.incomingListModified.sort(function (b, a) {
                                if (!a[$scope.filter.sortBy]) {
                                    a[$scope.filter.sortBy] = 0;
                                }
                                if (!b[$scope.filter.sortBy]) {
                                    b[$scope.filter.sortBy] = 0;
                                }
                                return parseFloat(a[$scope.filter.sortBy]) - parseFloat(b[$scope.filter.sortBy]);
                            });
                            break;
                        case "DATE":
                            if ($scope.incomingListModified === undefined || $scope.incomingListModified === null || $scope.incomingListModified === 'null') {
                                $scope.incomingListModified = [];
                            }
                            $scope.incomingListModified.sort(function (b, a) {
                                if (!a[$scope.filter.sortBy]) {
                                    return -1;
                                }
                                if (!b[$scope.filter.sortBy]) {
                                    return 1;
                                }
                                return (a[$scope.filter.sortBy]).localeCompare(b[$scope.filter.sortBy]);
                            });
                            break;
                    }
                }

            }

            function loadIncomingList(sessionId, callback) {
                var requestDetails = {
                    response: undefined,
                    successful: true,
                    canceledRequest: false
                };


                requestManager.routes.emailCenter.incoming.getIncomingEmails(sessionId).then(
                    function (response) {
                        requestDetails.response = response;
                        callback(requestDetails);
                    },
                    function (response) {
                        requestDetails.response = response;
                        requestDetails.successful = false;
                        requestDetails.canceledRequest = response.status == -1;
                        callback(requestDetails);
                    }
                );
            }

            function createPaginationArray() {
                if ($scope.incomingListModified.length == 0) {
                    return;
                }
                $scope.pagination.paginationArray = [];

                if ($scope.incomingListChunked.length < 9) {
                    for (var i = 0; i < $scope.incomingListChunked.length; i++) {
                        $scope.pagination.paginationArray.push({
                            text: i + 1,
                            clickable: true,
                            isActive: false
                        })
                    }
                    $scope.pagination.paginationArray[$scope.pagination.paginationIndex].isActive = true;
                } else {
                    $scope.pagination.paginationArray.push({
                        text: 1,
                        clickable: true,
                        isActive: false
                    }, {
                        text: 2,
                        clickable: true,
                        isActive: false
                    }, {
                        text: 3,
                        clickable: true,
                        isActive: false
                    }, {
                        text: 4,
                        clickable: true,
                        isActive: false
                    }, {
                        text: "...",
                        clickable: false,
                        isActive: false
                    }, {
                        text: $scope.incomingListChunked.length - 3,
                        clickable: true,
                        isActive: false
                    }, {
                        text: $scope.incomingListChunked.length - 2,
                        clickable: true,
                        isActive: false
                    }, {
                        text: $scope.incomingListChunked.length - 1,
                        clickable: true,
                        isActive: false
                    }, {
                        text: $scope.incomingListChunked.length,
                        clickable: true,
                        isActive: false
                    });

                    switch ($scope.pagination.paginationIndex) {
                        case 0:
                            $scope.pagination.paginationArray[0].isActive = true;
                            break;
                        case 1:
                            $scope.pagination.paginationArray[1].isActive = true;
                            break;
                        case 2:
                            $scope.pagination.paginationArray[2].isActive = true;
                            break;
                        case 3:
                            $scope.pagination.paginationArray[3].isActive = true;
                            break;
                        case $scope.incomingListChunked.length - 4:
                            $scope.pagination.paginationArray[5].isActive = true;
                            break;
                        case $scope.incomingListChunked.length - 3:
                            $scope.pagination.paginationArray[6].isActive = true;
                            break;
                        case $scope.incomingListChunked.length - 2:
                            $scope.pagination.paginationArray[7].isActive = true;
                            break;
                        case $scope.incomingListChunked.length - 1:
                            $scope.pagination.paginationArray[8].isActive = true;
                            break;
                        default:
                            $scope.pagination.paginationArray[4].isActive = true;
                    }
                }
            }

            function getAllCheckedCustomer() {
                var checkedCustomer = [];
                angular.forEach($scope.incomingListChunked, function (chunk) {
                    angular.forEach(chunk, function (customer) {
                        if (customer.checked) {
                            checkedCustomer.push(customer);
                        }
                    });
                });

                return checkedCustomer;
            }

            function applyTextFilter() {
                if (angular.isUndefined($scope.filter.searchTerm)) {
                    return;
                }

                var filteredList = [];

                angular.forEach($scope.incomingListModified, function (incomingMail) {
                    if (angular.isDefined(incomingMail.mailFromName) && !_.isNull(incomingMail.mailFromName) && incomingMail.mailFromName.toLowerCase().indexOf($scope.filter.searchTerm.toLowerCase()) !== -1) {
                        filteredList.push(incomingMail);
                        return;
                    }
                    if (angular.isDefined(incomingMail.mailToName) && !_.isNull(incomingMail.mailToName) && incomingMail.mailToName.toLowerCase().indexOf($scope.filter.searchTerm.toLowerCase()) !== -1) {
                        filteredList.push(incomingMail);
                        return;
                    }
                    if (angular.isDefined(incomingMail.subject) && !_.isNull(incomingMail.subject) && incomingMail.subject.toLowerCase().indexOf($scope.filter.searchTerm.toLowerCase()) !== -1) {
                        filteredList.push(incomingMail);
                        return;
                    }
                    if (angular.isDefined(incomingMail.date) && !_.isNull(incomingMail.date) && incomingMail.date.toLowerCase().indexOf($scope.filter.searchTerm.toLowerCase()) !== -1) {
                        filteredList.push(incomingMail);
                        return;
                    }
                });

                $scope.incomingListModified = filteredList;
            }

            function createRecord(sessionId, customerId, recordType, callback) {
                var requestDetails = {
                    successful: true,
                    response: undefined,
                    data: undefined
                };

                requestManager.routes.customer.createRecordOfCustomer(sessionId, customerId, recordType).then(
                    function (response) {
                        requestDetails.response = response;
                        requestDetails.data = response.data;
                        callback(requestDetails);
                    },
                    function (response) {
                        requestDetails.response = response;
                        requestDetails.data = response.data;
                        requestDetails.successful = false;
                        callback(requestDetails);
                    }
                );
            }

            function createRecordEmail(sessionId, recordId, body, callback) {
                var requestDetails = {
                    successful: true,
                    response: undefined,
                    data: undefined
                };

                requestManager.routes.record.createEmailRecordOfRecord(sessionId, recordId, body).then(
                    function (response) {
                        requestDetails.response = response;
                        requestDetails.data = response.data;
                        callback(requestDetails);
                    },
                    function (response) {
                        requestDetails.response = response;
                        requestDetails.data = response.data;
                        requestDetails.successful = false;
                        callback(requestDetails);
                    }
                );
            }

            function createRecordEmailRecipient(sessionId, recordEmailId, body, callback) {
                var requestDetails = {
                    successful: true,
                    response: undefined,
                    data: undefined
                };

                requestManager.routes.record.createRecordEmailRecipientOfRecordEmail(sessionId, recordEmailId, body).then(
                    function (response) {
                        requestDetails.response = response;
                        requestDetails.data = response.data;
                        callback(requestDetails);
                    },
                    function (response) {
                        requestDetails.response = response;
                        requestDetails.data = response.data;
                        requestDetails.successful = false;
                        callback(requestDetails);
                    }
                );
            }

            function createRecordEmailAttachment(sessionId, recordEmailId, attachmentList, callback) {
                var requestDetails = {
                    numRequestPlanned: attachmentList.length,
                    successResponses: [],
                    errorResponses: [],
                    responses: [],
                    allSuccessful: true
                };

                attachmentList.forEach(function (attachment) {
                    requestManager.routes.record.createRecordEmailAttachmentOfRecordEmail(sessionId, recordEmailId, attachment).then(
                        function (response) {
                            requestDetails.responses.push(response);
                            requestDetails.successResponses.push(response);

                            if (requestDetails.numRequestPlanned == requestDetails.responses.length) {
                                callback(requestDetails);
                            }
                        },
                        function (response) {
                            requestDetails.responses.push(response);
                            requestDetails.errorResponses.push(response);
                            requestDetails.allSuccessful = false;

                            if (requestDetails.numRequestPlanned == requestDetails.responses.length) {
                                callback(requestDetails);
                            }
                        }
                    )
                });

            }

            function sendEmailToAllRecipients(sessionId, recipients, email, attachmentList, callback) {

                var requestDetails = {
                    numRequestPlanned: ($scope.email.recipientsList.length * 3) + ($scope.email.recipientsList.length * attachmentList.length),
                    successResponses: [],
                    errorResponses: [],
                    responses: [],
                    responsesSortedByRecipient: {},
                    allSuccessful: true
                };

                recipients.forEach(function (recipient) {
                    requestDetails.responsesSortedByRecipient[recipient.accountNumber] = {
                        recipient: recipient,
                        record: undefined,
                        recordEmail: undefined,
                        recordEmailRecipient: undefined,
                        recordEmailAttachment: undefined
                    }
                });

                var debug = true;


                recipients.forEach(function (recipient) {

                    createRecord(sessionId, recipient.accountNumber, "record_email", function (createRecordRequestDetails) {
                        requestDetails.responses.push(createRecordRequestDetails.response);
                        requestDetails.responsesSortedByRecipient[recipient.accountNumber].record = createRecordRequestDetails.response;

                        if (createRecordRequestDetails.successful) {
                            requestDetails.successResponses.push(createRecordRequestDetails);

                            createRecordEmail(sessionId, createRecordRequestDetails.data, email, function (createRecordEmailRequestDetails) {
                                requestDetails.responses.push(createRecordEmailRequestDetails.response);
                                requestDetails.responsesSortedByRecipient[recipient.accountNumber].recordEmail = createRecordEmailRequestDetails.response;

                                if (createRecordEmailRequestDetails.successful) {
                                    requestDetails.successResponses.push(createRecordEmailRequestDetails);

                                    createRecordEmailRecipient(sessionId, createRecordEmailRequestDetails.data, {
                                        type: "to",
                                        mail: recipient.email
                                    }, function (createRecordEmailRecipientDetails) {
                                        requestDetails.responses.push(createRecordEmailRecipientDetails.response);
                                        requestDetails.responsesSortedByRecipient[recipient.accountNumber].recordEmailRecipient = createRecordEmailRecipientDetails.response;

                                        if (createRecordEmailRecipientDetails.successful) {
                                            requestDetails.successResponses.push(createRecordEmailRecipientDetails);

                                            if (requestDetails.numRequestPlanned == requestDetails.responses.length) {
                                                callback(requestDetails);
                                            }
                                        } else {
                                            requestDetails.errorResponses.push(createRecordEmailRecipientDetails.responses);
                                            if (requestDetails.numRequestPlanned == requestDetails.responses.length) {
                                                callback(requestDetails);

                                            }
                                        }
                                    });

                                    createRecordEmailAttachment($rootScope.authData.sessionId, createRecordEmailRequestDetails.data, attachmentList, function (createRecordEmailAttachmentRequestDetails) {
                                        Array.prototype.push.apply(requestDetails.responses, createRecordEmailAttachmentRequestDetails.responses);
                                        requestDetails.responsesSortedByRecipient[recipient.accountNumber].recordEmailAttachment = createRecordEmailAttachmentRequestDetails.responses;
                                        if (debug) console.log("create record email attachment response: ", createRecordEmailAttachmentRequestDetails);

                                        if (createRecordEmailAttachmentRequestDetails.allSuccessful) {
                                            Array.prototype.push.apply(requestDetails.successResponses, createRecordEmailAttachmentRequestDetails.successResponses);
                                            if (requestDetails.numRequestPlanned == requestDetails.responses.length) {
                                                callback(requestDetails);
                                            }
                                        } else {
                                            Array.prototype.push.apply(requestDetails.errorResponses, createRecordEmailAttachmentRequestDetails.errorResponses);
                                            requestDetails.allSuccessful = false;
                                            if (requestDetails.numRequestPlanned == requestDetails.responses.length) {
                                                callback(requestDetails);
                                            }
                                        }
                                    });
                                } else {
                                    requestDetails.errorResponses.push(createRecordEmailRequestDetails.response);
                                    requestDetails.allSuccessful = false;
                                    callback(requestDetails);
                                }
                            })
                        } else {
                            requestDetails.errorResponses.push(createRecordRequestDetails.responses);
                            requestDetails.allSuccessful = false;
                            callback(requestDetails);
                        }
                    })
                });
            }

            function getAttachments() {
                var attachment1 = jQuery('#attachment1').prop('files')[0];
                var attachment2 = jQuery('#attachment2').prop('files')[0];
                var attachment3 = jQuery('#attachment3').prop('files')[0];
                var attachmentList = [];

                if (angular.isDefined(attachment1)) {
                    attachmentList.push(attachment1)
                }
                if (angular.isDefined(attachment2)) {
                    attachmentList.push(attachment2)
                }
                if (angular.isDefined(attachment3)) {
                    attachmentList.push(attachment3)
                }

                return attachmentList;

            }

            function toggleFilter() {
                if ($scope.filter.opened) {
                    jQuery("#filterOptions").hide(500)
                } else {
                    jQuery("#filterOptions").show(500);
                }
                $scope.filter.opened = !$scope.filter.opened;
            }

            function openEmailModal(emailId) {
                let mail = undefined;
                $scope.incomingListChunked[0].forEach(
                    email => {
                        if (email.id === emailId) {
                            mail = email;
                        }
                    }
                );

                if (mail === undefined) {
                    Notification.error($filter("translate")("emailCenter.NO_MAIL_FOUND"));
                    return;
                } else {
                    $scope.emailModal.content = mail;
                    // console.log($scope.emailModal.content);
                    requestManager.routes.recordEmailReply.getAttachmentsWithId($rootScope.authData.sessionId,$scope.emailModal.content.id)
                        .then(
                            response => {
                                $scope.emailModal.content.attachments = response.data;
                                jQuery("#emailInfoModal").modal("show");
                            }
                        )
                }
            }

            function closeEmailModal() {
                $scope.emailModal.content = undefined;
                jQuery("#emailInfoModal").modal("hide");
            }

            function openReplyModal(emailId) {
                let mail = undefined;
                $scope.incomingListChunked[0].forEach(
                    email => {
                        if (email.id === emailId) {
                            mail = email;
                        }
                    }
                );
                if (mail === undefined) {
                    Notification.error($filter("translate")("emailCenter.NO_MAIL_FOUND"));
                    return;
                } else {
                    $scope.emailReplyModal.content = angular.copy(mail);
                    // console.log($scope.emailReplyModal.content);
                    $scope.emailReplyModal.content.receiverList = [];
                    $scope.emailReplyModal.content.receiverList.push({email: $scope.emailReplyModal.content.mailFrom});
                    $scope.emailReplyModal.content.subject = 'AW: ' + $scope.emailReplyModal.content.subject;
                    $scope.emailReplyModal.content.content = '<br><hr>' + $scope.emailReplyModal.content.content;
                    jQuery("#replyEmailModal").modal("show");
                }
            }

            function closeReplyModal() {
                $scope.emailReplyModal.content = undefined;
                jQuery("#replyEmailModal").modal("hide");
            }


            function refreshUserList() {
                $scope.incomingList.currentlyLoading = true;
                loadIncomingList($rootScope.authData.sessionId, function (requestDetails) {
                    if (requestDetails.successful) {
                        $scope.incomingList.content = requestDetails.response.data;
                        $scope.incomingList.currentlyLoading = false;
                        processUserListForFrontend();
                        Notification.success($filter("translate")("admonishedClientsList.REFRESHED_PAGE"))
                    } else {
                        // sharedMemory.error.setError(
                        //     requestDetails,
                        //     "COULD_NOT_LOAD_DATA",
                        //     "Fehler beim laden der Userliste nach klick auf 'refresh' Button",
                        //     $location.path(),
                        //     "admonishedClientsList"
                        // );
                        // $location.path("/error-page");
                    }
                });
            }

            function forwardToUserPage(userId) {
                $location.path("/user-details/" + userId);
            }

            function increasePaginationIndex() {
                if ($scope.pagination.paginationIndex < $scope.incomingListChunked.length - 1) {
                    $scope.pagination.paginationIndex++;
                    paginationManager.saveIndex($scope.pagination.paginationIndex);
                    createPaginationArray();
                }
            }

            function decreasePaginationIndex() {
                if ($scope.pagination.paginationIndex > 0) {
                    $scope.pagination.paginationIndex--;
                    paginationManager.saveIndex($scope.pagination.paginationIndex);
                    createPaginationArray();
                }
            }

            function changeEmailTemplate() {
                $scope.email.emailContent = $scope.emailTemplates.selected.text;
                $scope.email.emailSubject = $scope.emailTemplates.selected.subject;
            }

            function sendEmail() {
                if (angular.isUndefined($scope.email.emailContent) || $scope.email.emailContent == "") {
                    Notification.error($filter("translate")("admonishedClientsList.NO_CONTENT"));
                    return;
                }
                if (angular.isUndefined($scope.email.emailSubject) || $scope.email.emailSubject == "") {
                    Notification.error($filter("translate")("admonishedClientsList.NO_SUBJECT"));
                    return;
                }

                var attachments = getAttachments();

                var email = {
                    content: $scope.email.emailContent,
                    subject: $scope.email.emailSubject,
                    attachReport: $scope.email.attachReport ? 1 : 0
                };


                sendEmailToAllRecipients($rootScope.authData.sessionId, $scope.email.recipientsList, email, attachments, function (requestResult) {
                    if (requestResult.allSuccessful) {
                        Notification.success($filter("translate")("admonishedClientsList.EMAIL_SENT"));
                        jQuery('#emailModal').modal("hide");
                    } else {
                        jQuery('#emailModal').one("hidden.bs.modal", function () {
                            sharedMemory.error.setError(
                                requestResult,
                                "COULD_NOT_LOAD_DATA",
                                "Fehler beim versenden der Emails an mehrere Empfänger (mehrere Requests gekettet)",
                                $location.path(),
                                "admonishedClientsList"
                            );
                            $location.path("/error-page");
                            $scope.$apply();
                        }).modal("hide");
                    }
                });
            }

            function changeNumShowEntries() {
                $scope.filter.numShowEntriesSelected = $scope.filter.numShowEntriesSelected == "ALL" ? $scope.incomingListModified.length : $scope.filter.numShowEntriesSelected;
                processUserListFrontEndCalledFromRowFilter();
            }

            function goToPage(pageObject) {
                if (pageObject.clickable) {
                    $scope.pagination.paginationIndex = pageObject.text - 1;
                    paginationManager.saveIndex($scope.pagination.paginationIndex);
                    createPaginationArray();
                }
            }

            function getListSettings(settings) {
                var listSettings = {};
                settings.forEach(function (setting) {
                    setting.edited = false;
                    listSettings[setting.settingCode] = setting;
                });

                return listSettings;
            }

            function getUpdatedSettings(userSettings) {
                var updatedSettings = [];

                angular.forEach(userSettings, function (setting) {
                    if (setting.edited) {
                        updatedSettings.push(setting);
                    }
                });
                return updatedSettings;
            }

            function saveChangedSettings() {
                var updatedSettings = getUpdatedSettings($scope.userSettings.contentFiltered);
                sendEditSettingRequest($rootScope.authData.sessionId, updatedSettings, function (requestDetails) {
                    if (requestDetails.allSuccessful) {
                        Notification.success($filter("translate")("admonishedClientsList.SAVED_FILTER"));
                    }
                    else {
                        sharedMemory.error.setError(
                            requestDetails,
                            "COULD_NOT_LOAD_DATA",
                            "Fehler beim automatischen speichern der userSettings (speichern von Filter im Kundenstamm)",
                            $location.path(),
                            "admonishedClientsList"
                        );
                        $location.path("/error-page");
                    }
                });
            }

            function sendEditSettingRequest(sessionId, paramsArray, callback) {
                var requestDetails = {
                    numRequestsPlanned: paramsArray.length,
                    allSuccessful: true,
                    responses: [],
                    successfulResponses: [],
                    errorResponses: []
                };

                paramsArray.forEach(function (params) {
                    requestManager.routes.userSettings.editUserSetting(sessionId, params.id, params).then(
                        function (response) {
                            requestDetails.successfulResponses.push(response);
                            requestDetails.responses.push(response);
                            if (requestDetails.responses.length == requestDetails.numRequestsPlanned) {
                                callback(requestDetails);
                            }
                        },
                        function (response) {
                            requestDetails.successfulResponses.push(response);
                            requestDetails.responses.push(response);
                            requestDetails.allSuccessful = false;
                            if (requestDetails.responses.length == requestDetails.numRequestsPlanned) {
                                callback(requestDetails);
                            }
                        }
                    )
                });
            }

            function getEmailAttachmentAsPdf(attachmentId) {
                //check what browser the user is surfing on
                var ua = window.navigator.userAgent;
                var msie = ua.indexOf("MSIE ");
                var IEfound = false;
                var EdgeFound = /Edge/.test(window.navigator.userAgent);

                if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./))  // If Internet Explorer
                {
                    IEfound = true;
                }

                if (!IEfound && !EdgeFound) {
                    var tabWindow = $window.open('about:blank', '_blank');
                    tabWindow.document.write('loading...');
                }
                requestManager.routes.record.getEmailAttachementAsPDF($rootScope.authData.sessionId, attachmentId).then(
                    function (response) {
                        var file = new Blob([response.data], {type: 'application/pdf'});

                        if (!IEfound && !EdgeFound) {
                            var fileURL = URL.createObjectURL(file);
                            tabWindow.location.href = fileURL;
                        }
                        if (IEfound || EdgeFound) {
                            let r = "";
                            for (let i = 0; i < 4; i++) {
                                if (i % 4 == 0)
                                    r += Math.random().toString(36).substring(8);
                                else {
                                    r += "-";
                                    r += Math.random().toString(36).substring(4);
                                }
                            }
                            $window.navigator.msSaveOrOpenBlob(file, r + ".pdf");
                        }
                    },
                    function () {
                        Notification.error("Fehler beim Laden des Anhangs! Bitte wenden Sie sich an den Support.");
                    }
                )
            }
        }
    ]
}); 

angular.module('emailCenterListOutgoing', []);
angular.module('emailCenterListOutgoing').component('emailCenterListOutgoing', {
    templateUrl: 'app/components/emailCenterListOutgoing/emailCenterListOutgoing.template.html',
    controller: [
        "$scope",
        "requestManager",
        "$rootScope",
        "$location",
        "util",
        "sharedMemory",
        "$filter",
        "Notification",
        "$route",
        "cookieManager",
        "paginationManager",
        "$window",
        function ($scope, requestManager, $rootScope, $location, util, sharedMemory, $filter, Notification, $route, cookieManager, paginationManager, $window) {
            this.$onInit = function () {
                initVariables();
                initScope();
                $scope.outgoingList.currentlyLoading = true;
                loadOutgoingList($rootScope.authData.sessionId, function (requestDetails) {
                    if (requestDetails.successful) {
                        $scope.outgoingList.content = _.values(requestDetails.response.data);
                        $scope.outgoingList.currentlyLoading = false;
                        processUserListForFrontend();
                    } else {
                        sharedMemory.error.setError(
                            requestDetails,
                            "COULD_NOT_LOAD_DATA",
                            "Fehler beim laden der Outgoing-Liste nach klick auf 'refresh' Button",
                            $location.path(),
                            "admonishedClientsList"
                        );
                        $location.path("/error-page");
                    }
                });
                applySortBy('entryCreated');
            };

            $scope.$watchGroup(["outgoingList.currentlyLoading", "emailTemplates.currentlyLoading", "userSettings.currentlyLoading"], function () {
                if (!$scope.outgoingList.currentlyLoading && !$scope.outgoingList.currentlyLoading && !$scope.userSettings.currentlyLoading) {
                    $scope.allDependenciesLoaded = true;
                }
            });

            function initVariables() {
                $scope.allDependenciesLoaded = false;
                $scope.outgoingList = {
                    currentlyLoading: true,
                    outgoingList: undefined
                };
                $scope.outgoingListModified = [];
                $scope.outgoingListChunked = [];
                $scope.pagination = {
                    paginationIndex: paginationManager.readIndex() === undefined ? 0 : paginationManager.readIndex(),
                    paginationArray: []
                };
                $scope.filter = {
                    numShowEntriesOptions: ["10", "25", "50", "75", "100", "ALL"],
                };
                $scope.email = {
                    attachments: [],
                    recipientsList: undefined,
                    emailContent: undefined,
                    recordsCreated: 0,
                    recordsPending: undefined,
                    allRecordsCreated: false,
                    recordEmailCreated: 0,
                    recordEmailPending: undefined,
                    allRecordEmailCreated: false,
                    recordEmailRecipientCreated: 0,
                    recordEmailRecipientPending: undefined,
                    allRecordEmailRecipientsCreated: false,
                    attachReport: true
                };
                $scope.userSettings = {
                    currentlyLoading: false,
                    content: $rootScope.userSettings,
                    contentFiltered: getListSettings($rootScope.userSettings),
                };
                $scope.infoModal = {
                    body: undefined,
                    title: undefined
                };
                $scope.mailApprovalActivated = undefined;
                $scope.filterSettingId = undefined;
                $scope.floatTheadOptions = {
                    scrollContainer: true,
                    position: 'absolute'
                };
                $scope.emailModal = {
                    content: undefined
                };
                $scope.emailReplyModal = {
                    content: undefined
                }
            }

            function initScope() {
                $scope.$on('$routeChangeStart', function (next, current) {
                    requestManager.stopPendingRequests();
                    saveChangedSettings();
                });

                $scope.toggleFilter = toggleFilter;
                $scope.openEmailModal = openEmailModal;
                $scope.refreshUserList = refreshUserList;
                $scope.forwardToUserPage = forwardToUserPage;
                $scope.increasePaginationIndex = increasePaginationIndex;
                $scope.decreasePaginationIndex = decreasePaginationIndex;
                $scope.changeEmailTemplate = changeEmailTemplate;
                $scope.sendEmail = sendEmail;
                $scope.changeNumShowEntries = changeNumShowEntries;
                $scope.goToPage = goToPage;
                $scope.processUserListFrontEndCalledFromRowFilter = processUserListFrontEndCalledFromRowFilter;
                $scope.processUserListForFrontend = processUserListForFrontend;
                $scope.openEmailModal = openEmailModal;
                $scope.openReplyModal = openReplyModal;
                $scope.closeEmailModal = closeEmailModal;
                $scope.closeReplyModal = closeReplyModal;
                $scope.getEmailRecipientRequest = getEmailRecipientRequest;
                $scope.closeModalAndForwardTo = closeModalAndForwardTo;
                $scope.getEmailAttachmentAsPdf = getEmailAttachmentAsPdf;
                $scope.applySortBy = applySortBy;
                $scope.forwardToCustomer = forwardToCustomer;
            }

            function forwardToCustomer(customerId) {
                $location.path("/user-details/" + customerId);
            }

            function processUserListFrontEndCalledFromRowFilter() {
                $scope.outgoingListModified = angular.copy($scope.outgoingList.content);
                $scope.pagination.paginationIndex = 0;
                paginationManager.saveIndex($scope.pagination.paginationIndex);
                applyTextFilter();
                applyOrder();
                $scope.outgoingListChunked = util.divideArrayIntoChunks($scope.outgoingListModified, parseInt($scope.userSettings.contentFiltered.defaultNumRowsInList.value1));
                createPaginationArray();
            }

            function processUserListForFrontend() {
                $scope.outgoingListModified = angular.copy($scope.outgoingList.content);
                $scope.pagination.paginationIndex = paginationManager.readIndex() === undefined ? 0 : paginationManager.readIndex();
                applyTextFilter();
                applyOrder();
                $scope.outgoingListChunked = util.divideArrayIntoChunks($scope.outgoingListModified, parseInt($scope.userSettings.contentFiltered.defaultNumRowsInList.value1));
                createPaginationArray();
            }

            function applySortBy(filter) {
                if ($scope.filter.sortBy == filter) {
                    $scope.filter.sortAscending = !$scope.filter.sortAscending;
                } else {
                    $scope.filter.sortAscending = false;
                }
                $scope.filter.sortBy = filter;
                processUserListForFrontend();
            }

            function applyOrder() {
                if (angular.isUndefined($scope.filter.sortBy)) {
                    return;
                }

                var sortType;

                switch ($scope.filter.sortBy) {
                    case "from":
                    case "to":
                    case "subject":
                        sortType = $scope.filter.sortBy;
                        break;
                    case "entryCreated":
                        sortType = "DATE";
                        break;
                }
                if ($scope.filter.sortAscending) {
                    switch (sortType) {
                        case "from":
                            $scope.outgoingListModified.sort(function (a, b) {
                                let aName, bName;
                                if (!a['userName'] || !a['userSurname']) {
                                    aName = '-1';
                                }
                                if (!b['userName'] || !b['userSurname']) {
                                    bName = '-1';
                                }
                                aName = a['userName'] + a['userSurname'];
                                bName = b['userName'] + b['userSurname'];
                                return aName.localeCompare(bName);
                            });
                            break;
                        case "DATE":
                            if ($scope.outgoingListModified === undefined || $scope.outgoingListModified === null || $scope.outgoingListModified === 'null') {
                                $scope.outgoingListModified = [];
                            }
                            $scope.outgoingListModified.sort(function (a, b) {
                                if (!a[$scope.filter.sortBy]) {
                                    return 1;
                                }
                                if (!b[$scope.filter.sortBy]) {
                                    return -1;
                                }
                                return (a[$scope.filter.sortBy]).localeCompare(b[$scope.filter.sortBy]);
                            });
                            break;
                    }
                } else {
                    switch (sortType) {
                        case "from":
                            $scope.outgoingListModified.sort(function (a, b) {
                                let aName, bName;
                                if (!a['userName'] || !a['userSurname']) {
                                    aName = '-1';
                                }
                                if (!b['userName'] || !b['userSurname']) {
                                    bName = '-1';
                                }
                                aName = a['userName'] + a['userSurname'];
                                bName = b['userName'] + b['userSurname'];
                                return bName.localeCompare(aName);
                            });
                            break;
                        case "DATE":
                            if ($scope.outgoingListModified === undefined || $scope.outgoingListModified === null || $scope.outgoingListModified === 'null') {
                                $scope.outgoingListModified = [];
                            }
                            $scope.outgoingListModified.sort(function (b, a) {
                                if (!a[$scope.filter.sortBy]) {
                                    return -1;
                                }
                                if (!b[$scope.filter.sortBy]) {
                                    return 1;
                                }
                                return (a[$scope.filter.sortBy]).localeCompare(b[$scope.filter.sortBy]);
                            });
                            break;
                    }
                }

            }

            function loadOutgoingList(sessionId, callback) {
                var requestDetails = {
                    response: undefined,
                    successful: true,
                    canceledRequest: false
                };


                requestManager.routes.emailCenter.outgoing.getOutgoingEmails(sessionId).then(
                    function (response) {
                        requestDetails.response = response;
                        callback(requestDetails);
                    },
                    function (response) {
                        requestDetails.response = response;
                        requestDetails.successful = false;
                        requestDetails.canceledRequest = response.status == -1;
                        callback(requestDetails);
                    }
                );
            }

            function createPaginationArray() {
                if ($scope.outgoingListModified.length == 0) {
                    return;
                }
                $scope.pagination.paginationArray = [];

                if ($scope.outgoingListChunked.length < 9) {
                    for (var i = 0; i < $scope.outgoingListChunked.length; i++) {
                        $scope.pagination.paginationArray.push({
                            text: i + 1,
                            clickable: true,
                            isActive: false
                        })
                    }
                    $scope.pagination.paginationArray[$scope.pagination.paginationIndex].isActive = true;
                } else {
                    $scope.pagination.paginationArray.push({
                        text: 1,
                        clickable: true,
                        isActive: false
                    }, {
                        text: 2,
                        clickable: true,
                        isActive: false
                    }, {
                        text: 3,
                        clickable: true,
                        isActive: false
                    }, {
                        text: 4,
                        clickable: true,
                        isActive: false
                    }, {
                        text: "...",
                        clickable: false,
                        isActive: false
                    }, {
                        text: $scope.outgoingListChunked.length - 3,
                        clickable: true,
                        isActive: false
                    }, {
                        text: $scope.outgoingListChunked.length - 2,
                        clickable: true,
                        isActive: false
                    }, {
                        text: $scope.outgoingListChunked.length - 1,
                        clickable: true,
                        isActive: false
                    }, {
                        text: $scope.outgoingListChunked.length,
                        clickable: true,
                        isActive: false
                    });

                    switch ($scope.pagination.paginationIndex) {
                        case 0:
                            $scope.pagination.paginationArray[0].isActive = true;
                            break;
                        case 1:
                            $scope.pagination.paginationArray[1].isActive = true;
                            break;
                        case 2:
                            $scope.pagination.paginationArray[2].isActive = true;
                            break;
                        case 3:
                            $scope.pagination.paginationArray[3].isActive = true;
                            break;
                        case $scope.outgoingListChunked.length - 4:
                            $scope.pagination.paginationArray[5].isActive = true;
                            break;
                        case $scope.outgoingListChunked.length - 3:
                            $scope.pagination.paginationArray[6].isActive = true;
                            break;
                        case $scope.outgoingListChunked.length - 2:
                            $scope.pagination.paginationArray[7].isActive = true;
                            break;
                        case $scope.outgoingListChunked.length - 1:
                            $scope.pagination.paginationArray[8].isActive = true;
                            break;
                        default:
                            $scope.pagination.paginationArray[4].isActive = true;
                    }
                }
            }

            function applyTextFilter() {
                if (!$scope.filter.searchTerm) {
                    return;
                }
                var filteredList = [];

                angular.forEach($scope.outgoingListModified, (outgoingMail) => {
                    // names
                    if (outgoingMail.mailFromName && outgoingMail.mailFromName.toLowerCase().indexOf($scope.filter.searchTerm.toLowerCase()) !== -1) {
                        filteredList.push(outgoingMail);
                    }
                    else if (outgoingMail.mailToName && outgoingMail.mailToName.toLowerCase().indexOf($scope.filter.searchTerm.toLowerCase()) !== -1) {
                        filteredList.push(outgoingMail);
                    }
                    else if (outgoingMail.userName && outgoingMail.userName.toLowerCase().indexOf($scope.filter.searchTerm.toLowerCase()) !== -1) {
                        filteredList.push(outgoingMail);
                    }
                    else if (outgoingMail.userSurname && outgoingMail.userSurname.toLowerCase().indexOf($scope.filter.searchTerm.toLowerCase()) !== -1) {
                        filteredList.push(outgoingMail);
                    }
                    // mail
                    else if (outgoingMail.mailFrom && outgoingMail.mailFrom.toLowerCase().indexOf($scope.filter.searchTerm.toLowerCase()) !== -1) {
                        filteredList.push(outgoingMail);
                    }
                    else if (outgoingMail.reciepients && outgoingMail.reciepients[0].mail && outgoingMail.reciepients[0].mail.toLowerCase().indexOf($scope.filter.searchTerm.toLowerCase()) !== -1) {
                        filteredList.push(outgoingMail);
                    }
                    //subject
                    else if (outgoingMail.subject && outgoingMail.subject.toLowerCase().indexOf($scope.filter.searchTerm.toLowerCase()) !== -1) {
                        filteredList.push(outgoingMail);
                    }
                    else if (outgoingMail.content.subject && outgoingMail.content.subject.toLowerCase().indexOf($scope.filter.searchTerm.toLowerCase()) !== -1) {
                        filteredList.push(outgoingMail);
                    }

                    // date
                    else if (outgoingMail.date && outgoingMail.date.toLowerCase().indexOf($scope.filter.searchTerm.toLowerCase()) !== -1) {
                        filteredList.push(outgoingMail);
                    }
                });

                $scope.outgoingListModified = filteredList;
            }

            function createRecord(sessionId, customerId, recordType, callback) {
                var requestDetails = {
                    successful: true,
                    response: undefined,
                    data: undefined
                };

                requestManager.routes.customer.createRecordOfCustomer(sessionId, customerId, recordType).then(
                    function (response) {
                        requestDetails.response = response;
                        requestDetails.data = response.data;
                        callback(requestDetails);
                    },
                    function (response) {
                        requestDetails.response = response;
                        requestDetails.data = response.data;
                        requestDetails.successful = false;
                        callback(requestDetails);
                    }
                );
            }

            function createRecordEmail(sessionId, recordId, body, callback) {
                var requestDetails = {
                    successful: true,
                    response: undefined,
                    data: undefined
                };

                requestManager.routes.record.createEmailRecordOfRecord(sessionId, recordId, body).then(
                    function (response) {
                        requestDetails.response = response;
                        requestDetails.data = response.data;
                        callback(requestDetails);
                    },
                    function (response) {
                        requestDetails.response = response;
                        requestDetails.data = response.data;
                        requestDetails.successful = false;
                        callback(requestDetails);
                    }
                );
            }

            function createRecordEmailRecipient(sessionId, recordEmailId, body, callback) {
                var requestDetails = {
                    successful: true,
                    response: undefined,
                    data: undefined
                };

                requestManager.routes.record.createRecordEmailRecipientOfRecordEmail(sessionId, recordEmailId, body).then(
                    function (response) {
                        requestDetails.response = response;
                        requestDetails.data = response.data;
                        callback(requestDetails);
                    },
                    function (response) {
                        requestDetails.response = response;
                        requestDetails.data = response.data;
                        requestDetails.successful = false;
                        callback(requestDetails);
                    }
                );
            }

            function createRecordEmailAttachment(sessionId, recordEmailId, attachmentList, callback) {
                var requestDetails = {
                    numRequestPlanned: attachmentList.length,
                    successResponses: [],
                    errorResponses: [],
                    responses: [],
                    allSuccessful: true
                };

                attachmentList.forEach(function (attachment) {
                    requestManager.routes.record.createRecordEmailAttachmentOfRecordEmail(sessionId, recordEmailId, attachment).then(
                        function (response) {
                            requestDetails.responses.push(response);
                            requestDetails.successResponses.push(response);

                            if (requestDetails.numRequestPlanned == requestDetails.responses.length) {
                                callback(requestDetails);
                            }
                        },
                        function (response) {
                            requestDetails.responses.push(response);
                            requestDetails.errorResponses.push(response);
                            requestDetails.allSuccessful = false;

                            if (requestDetails.numRequestPlanned == requestDetails.responses.length) {
                                callback(requestDetails);
                            }
                        }
                    )
                });

            }

            function sendEmailToAllRecipients(sessionId, recipients, email, attachmentList, callback) {

                var requestDetails = {
                    numRequestPlanned: ($scope.email.recipientsList.length * 3) + ($scope.email.recipientsList.length * attachmentList.length),
                    successResponses: [],
                    errorResponses: [],
                    responses: [],
                    responsesSortedByRecipient: {},
                    allSuccessful: true
                };

                recipients.forEach(function (recipient) {
                    requestDetails.responsesSortedByRecipient[recipient.accountNumber] = {
                        recipient: recipient,
                        record: undefined,
                        recordEmail: undefined,
                        recordEmailRecipient: undefined,
                        recordEmailAttachment: undefined
                    }
                });

                var debug = true;


                recipients.forEach(function (recipient) {

                    createRecord(sessionId, recipient.accountNumber, "record_email", function (createRecordRequestDetails) {
                        requestDetails.responses.push(createRecordRequestDetails.response);
                        requestDetails.responsesSortedByRecipient[recipient.accountNumber].record = createRecordRequestDetails.response;

                        if (createRecordRequestDetails.successful) {
                            requestDetails.successResponses.push(createRecordRequestDetails);

                            createRecordEmail(sessionId, createRecordRequestDetails.data, email, function (createRecordEmailRequestDetails) {
                                requestDetails.responses.push(createRecordEmailRequestDetails.response);
                                requestDetails.responsesSortedByRecipient[recipient.accountNumber].recordEmail = createRecordEmailRequestDetails.response;

                                if (createRecordEmailRequestDetails.successful) {
                                    requestDetails.successResponses.push(createRecordEmailRequestDetails);

                                    createRecordEmailRecipient(sessionId, createRecordEmailRequestDetails.data, {
                                        type: "to",
                                        mail: recipient.email
                                    }, function (createRecordEmailRecipientDetails) {
                                        requestDetails.responses.push(createRecordEmailRecipientDetails.response);
                                        requestDetails.responsesSortedByRecipient[recipient.accountNumber].recordEmailRecipient = createRecordEmailRecipientDetails.response;

                                        if (createRecordEmailRecipientDetails.successful) {
                                            requestDetails.successResponses.push(createRecordEmailRecipientDetails);

                                            if (requestDetails.numRequestPlanned == requestDetails.responses.length) {
                                                callback(requestDetails);
                                            }
                                        } else {
                                            requestDetails.errorResponses.push(createRecordEmailRecipientDetails.responses);
                                            if (requestDetails.numRequestPlanned == requestDetails.responses.length) {
                                                callback(requestDetails);

                                            }
                                        }
                                    });

                                    createRecordEmailAttachment($rootScope.authData.sessionId, createRecordEmailRequestDetails.data, attachmentList, function (createRecordEmailAttachmentRequestDetails) {
                                        Array.prototype.push.apply(requestDetails.responses, createRecordEmailAttachmentRequestDetails.responses);
                                        requestDetails.responsesSortedByRecipient[recipient.accountNumber].recordEmailAttachment = createRecordEmailAttachmentRequestDetails.responses;
                                        if (debug) console.log("create record email attachment response: ", createRecordEmailAttachmentRequestDetails);

                                        if (createRecordEmailAttachmentRequestDetails.allSuccessful) {
                                            Array.prototype.push.apply(requestDetails.successResponses, createRecordEmailAttachmentRequestDetails.successResponses);
                                            if (requestDetails.numRequestPlanned == requestDetails.responses.length) {
                                                callback(requestDetails);
                                            }
                                        } else {
                                            Array.prototype.push.apply(requestDetails.errorResponses, createRecordEmailAttachmentRequestDetails.errorResponses);
                                            requestDetails.allSuccessful = false;
                                            if (requestDetails.numRequestPlanned == requestDetails.responses.length) {
                                                callback(requestDetails);
                                            }
                                        }
                                    });
                                } else {
                                    requestDetails.errorResponses.push(createRecordEmailRequestDetails.response);
                                    requestDetails.allSuccessful = false;
                                    callback(requestDetails);
                                }
                            })
                        } else {
                            requestDetails.errorResponses.push(createRecordRequestDetails.responses);
                            requestDetails.allSuccessful = false;
                            callback(requestDetails);
                        }
                    })
                });
            }

            function getAttachments() {
                var attachment1 = jQuery('#attachment1').prop('files')[0];
                var attachment2 = jQuery('#attachment2').prop('files')[0];
                var attachment3 = jQuery('#attachment3').prop('files')[0];
                var attachmentList = [];

                if (angular.isDefined(attachment1)) {
                    attachmentList.push(attachment1)
                }
                if (angular.isDefined(attachment2)) {
                    attachmentList.push(attachment2)
                }
                if (angular.isDefined(attachment3)) {
                    attachmentList.push(attachment3)
                }

                return attachmentList;

            }

            function getEmailAttachmentAsPdf(attachmentId) {
                //check what browser the user is surfing on
                var ua = window.navigator.userAgent;
                var msie = ua.indexOf("MSIE ");
                var IEfound = false;
                var EdgeFound = /Edge/.test(window.navigator.userAgent);

                if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./))  // If Internet Explorer
                {
                    IEfound = true;
                }

                if (!IEfound && !EdgeFound) {
                    var tabWindow = $window.open('about:blank', '_blank');
                    tabWindow.document.write('loading...');
                }
                requestManager.routes.record.getEmailAttachementAsPDF($rootScope.authData.sessionId, attachmentId).then(
                    function (response) {
                        var file = new Blob([response.data], {type: 'application/pdf'});

                        if (!IEfound && !EdgeFound) {
                            var fileURL = URL.createObjectURL(file);
                            tabWindow.location.href = fileURL;
                        }
                        if (IEfound || EdgeFound) {
                            let r = "";
                            for (let i = 0; i < 4; i++) {
                                if (i % 4 == 0)
                                    r += Math.random().toString(36).substring(8);
                                else {
                                    r += "-";
                                    r += Math.random().toString(36).substring(4);
                                }
                            }
                            $window.navigator.msSaveOrOpenBlob(file, r + ".pdf");
                        }
                    },
                    function () {
                        Notification.error("Fehler beim Laden des Anhangs! Bitte wenden Sie sich an den Support.");
                    }
                )
            }

            function toggleFilter() {
                if ($scope.filter.opened) {
                    jQuery("#filterOptions").hide(500)
                } else {
                    jQuery("#filterOptions").show(500);
                }
                $scope.filter.opened = !$scope.filter.opened;
            }

            function openEmailModal(email) {
                if (email.submittedToAtPost) {
                    return;
                }
                $scope.emailModal.content = email;
                if (email.direction) {
                    jQuery("#emailInfoModalReply").modal("show");
                } else {
                    jQuery("#emailInfoModalNormal").modal("show");
                }
            }

            function closeModalAndForwardTo(modalName, path) {
                $scope.emailModal.content = undefined;
                $scope.location = $location.path(path);
                jQuery("#" + modalName).modal("hide");
            }

            function closeEmailModal(modalName) {
                $scope.emailModal.content = undefined;
                jQuery("#" + modalName).modal("hide");
            }

            function openReplyModal(emailId) {
                let mail = undefined;
                $scope.outgoingListChunked[0].forEach(
                    email => {
                        if (email.id === emailId) {
                            mail = email;
                        }
                    }
                );
                if (mail === undefined) {
                    Notification.error($filter("translate")("emailCenter.NO_MAIL_FOUND"));
                    return;
                } else {
                    $scope.emailReplyModal.content = angular.copy(mail);
                    $scope.emailReplyModal.content.receiverList = [];
                    $scope.emailReplyModal.content.receiverList.push({email: $scope.emailReplyModal.content.mailFrom});
                    $scope.emailReplyModal.content.subject = 'AW: ' + $scope.emailReplyModal.content.subject;
                    $scope.emailReplyModal.content.content = '<br><hr>' + $scope.emailReplyModal.content.content;
                    jQuery("#replyEmailModal").modal("show");
                }
            }

            function closeReplyModal() {
                $scope.emailReplyModal.content = undefined;
                jQuery("#replyEmailModal").modal("hide");
            }


            function refreshUserList() {
                $scope.outgoingList.currentlyLoading = true;
                loadOutgoingList($rootScope.authData.sessionId, function (requestDetails) {
                    if (requestDetails.successful) {
                        $scope.outgoingList.content = _.values(requestDetails.response.data);
                        $scope.outgoingList.currentlyLoading = false;
                        processUserListForFrontend();
                        Notification.success($filter("translate")("admonishedClientsList.REFRESHED_PAGE"))
                    } else {
                        sharedMemory.error.setError(
                            requestDetails,
                            "COULD_NOT_LOAD_DATA",
                            "Fehler beim laden der Userliste nach klick auf 'refresh' Button",
                            $location.path(),
                            "admonishedClientsList"
                        );
                        $location.path("/error-page");
                    }
                });
            }

            function forwardToUserPage(userId) {
                $location.path("/user-details/" + userId);
            }

            function increasePaginationIndex() {
                if ($scope.pagination.paginationIndex < $scope.outgoingListChunked.length - 1) {
                    $scope.pagination.paginationIndex++;
                    paginationManager.saveIndex($scope.pagination.paginationIndex);
                    createPaginationArray();
                }
            }

            function decreasePaginationIndex() {
                if ($scope.pagination.paginationIndex > 0) {
                    $scope.pagination.paginationIndex--;
                    paginationManager.saveIndex($scope.pagination.paginationIndex);
                    createPaginationArray();
                }
            }

            function changeEmailTemplate() {
                $scope.email.emailContent = $scope.emailTemplates.selected.text;
                $scope.email.emailSubject = $scope.emailTemplates.selected.subject;
            }

            function sendEmail() {
                if (angular.isUndefined($scope.email.emailContent) || $scope.email.emailContent == "") {
                    Notification.error($filter("translate")("admonishedClientsList.NO_CONTENT"));
                    return;
                }
                if (angular.isUndefined($scope.email.emailSubject) || $scope.email.emailSubject == "") {
                    Notification.error($filter("translate")("admonishedClientsList.NO_SUBJECT"));
                    return;
                }

                var attachments = getAttachments();

                var email = {
                    content: $scope.email.emailContent,
                    subject: $scope.email.emailSubject,
                    attachReport: $scope.email.attachReport ? 1 : 0
                };


                sendEmailToAllRecipients($rootScope.authData.sessionId, $scope.email.recipientsList, email, attachments, function (requestResult) {
                    if (requestResult.allSuccessful) {
                        Notification.success($filter("translate")("admonishedClientsList.EMAIL_SENT"));
                        jQuery('#emailModal').modal("hide");
                    } else {
                        jQuery('#emailModal').one("hidden.bs.modal", function () {
                            sharedMemory.error.setError(
                                requestResult,
                                "COULD_NOT_LOAD_DATA",
                                "Fehler beim versenden der Emails an mehrere Empfänger (mehrere Requests gekettet)",
                                $location.path(),
                                "admonishedClientsList"
                            );
                            $location.path("/error-page");
                            $scope.$apply();
                        }).modal("hide");
                    }
                });
            }

            function changeNumShowEntries() {
                $scope.filter.numShowEntriesSelected = $scope.filter.numShowEntriesSelected == "ALL" ? $scope.outgoingListModified.length : $scope.filter.numShowEntriesSelected;
                processUserListFrontEndCalledFromRowFilter();
            }

            function goToPage(pageObject) {
                if (pageObject.clickable) {
                    $scope.pagination.paginationIndex = pageObject.text - 1;
                    paginationManager.saveIndex($scope.pagination.paginationIndex);
                    createPaginationArray();
                }
            }

            function getListSettings(settings) {
                var listSettings = {};
                settings.forEach(function (setting) {
                    setting.edited = false;
                    listSettings[setting.settingCode] = setting;
                });

                return listSettings;
            }

            function getUpdatedSettings(userSettings) {
                var updatedSettings = [];

                angular.forEach(userSettings, function (setting) {
                    if (setting.edited) {
                        updatedSettings.push(setting);
                    }
                });
                return updatedSettings;
            }

            function saveChangedSettings() {
                var updatedSettings = getUpdatedSettings($scope.userSettings.contentFiltered);
                sendEditSettingRequest($rootScope.authData.sessionId, updatedSettings, function (requestDetails) {
                    if (requestDetails.allSuccessful) {
                        Notification.success($filter("translate")("admonishedClientsList.SAVED_FILTER"));
                    } else {
                        sharedMemory.error.setError(
                            requestDetails,
                            "COULD_NOT_LOAD_DATA",
                            "Fehler beim automatischen speichern der userSettings (speichern von Filter im Kundenstamm)",
                            $location.path(),
                            "admonishedClientsList"
                        );
                        $location.path("/error-page");
                    }
                });
            }

            function sendEditSettingRequest(sessionId, paramsArray, callback) {
                var requestDetails = {
                    numRequestsPlanned: paramsArray.length,
                    allSuccessful: true,
                    responses: [],
                    successfulResponses: [],
                    errorResponses: []
                };

                paramsArray.forEach(function (params) {
                    requestManager.routes.userSettings.editUserSetting(sessionId, params.id, params).then(
                        function (response) {
                            requestDetails.successfulResponses.push(response);
                            requestDetails.responses.push(response);
                            if (requestDetails.responses.length == requestDetails.numRequestsPlanned) {
                                callback(requestDetails);
                            }
                        },
                        function (response) {
                            requestDetails.successfulResponses.push(response);
                            requestDetails.responses.push(response);
                            requestDetails.allSuccessful = false;
                            if (requestDetails.responses.length == requestDetails.numRequestsPlanned) {
                                callback(requestDetails);
                            }
                        }
                    )
                });
            }

            function getEmailRecipientRequest(sessionId, emailList, callback) {
                var requestDetails = {
                    numRequestPlanned: emailList.length,
                    allSuccessful: true,
                    responses: [],
                    successResponses: [],
                    errorResponses: [],
                    contentAssignedToId: {},
                    content: []
                };

                if (requestDetails.numRequestPlanned === 0) {
                    callback(requestDetails);
                    return;
                }

                emailList.forEach(function (email) {
                    requestManager.routes.recordEmail.getRecordEmailRecipientsOfRecordEmail(sessionId, email.id).then(
                        function (response) {
                            requestDetails.responses.push(response);
                            requestDetails.successResponses.push(response);
                            requestDetails.contentAssignedToId[email.id] = response.data;
                            requestDetails.content.push(response.data);
                            if (requestDetails.numRequestPlanned == requestDetails.responses.length) {
                                callback(requestDetails);
                            }
                        },
                        function (response) {
                            requestDetails.responses.push(response);
                            requestDetails.errorResponses.push(response);
                            requestDetails.contentAssignedToId[email.id] = response.data;
                            requestDetails.content.push(response.data);
                            requestDetails.allSuccessful = false;
                            if (requestDetails.numRequestPlanned == requestDetails.responses.length) {
                                callback(requestDetails);
                            }
                        }
                    )
                });
            }
        }
    ]
}); 

angular.module('emailCenterListIncomingV2', []);

angular.module('emailCenterListIncomingV2').component('emailCenterListIncomingV2', {
    templateUrl: 'app/components/emailCenterListIncomingV2/emailCenterListIncomingV2.template.html',
    controller: [
        "$scope",
        "requestManager",
        "$rootScope",
        "$location",
        "util",
        "sharedMemory",
        "$filter",
        "Notification",
        "$route",
        "cookieManager",
        "paginationManager",
        "$window",
        function ($scope, requestManager, $rootScope, $location, util, sharedMemory, $filter, Notification, $route, cookieManager, paginationManager, $window) {
            this.$onInit = function () {
                initVariables();
                initScope();

                getRecords();
            };

            function initVariables() {
                $scope.initialLoading = true;
                $scope.records = [];
                $scope.loading = true;
                $scope.searchTerm = '';
                $scope.numShowEntriesOptions = [10, 20];
                $scope.paginator = {
                    items: [],
                    itemsPerPage: null,
                    itemsTotal: null,
                    pageCurrent: null,
                    pagesTotal: null
                };
                $scope.filters = {
                    page: 1,
                    pageSize: 10,
                    order: "entryCreated",
                    direction: "desc",
                    searchTerm: null,
                    filter: null
                };
                $scope.emailModal = undefined;
            }

            function initScope() {
                $scope.changeNumShowEntries = changeNumShowEntries;
                $scope.onSearch = onSearch;
                $scope.nextPage = nextPage;
                $scope.previousPage = previousPage;
                $scope.firstPage = firstPage;
                $scope.lastPage = lastPage;
                $scope.openEmailModal = openEmailModal;
                $scope.closeEmailModal = closeEmailModal;
            }

            function changeNumShowEntries() {
                $scope.filters.page = 1;
                getRecords();
            }

            function onSearch() {
                $scope.filters.page = 1;
                getRecords();
            }

            function firstPage() {
                $scope.filters.page = 1;
                getRecords();
            }

            function lastPage() {
                $scope.filters.page = $scope.paginator.pagesTotal;
                getRecords();
            }

            function nextPage() {
                $scope.filters.page++;
                getRecords();
            }

            function previousPage() {
                $scope.filters.page--;
                getRecords();
            }

            function getRecords() {
                $scope.loading = true;
                requestManager.routes.record.inbox($rootScope.authData.sessionId, $scope.filters).then(
                    function (response) {
                        $scope.paginator = response.data;
                        $scope.records = [...$scope.paginator.items];
                        $scope.initialLoading = false;
                        $scope.loading = false;
                    },
                    function () {
                    }
                );
            }

            function openEmailModal(email) {
                let mail = email;
                if (mail === undefined) {
                    Notification.error($filter("translate")("emailCenter.NO_MAIL_FOUND"));
                    return;
                }

                $scope.emailModal = mail;
                requestManager.routes.recordEmailReply.getAttachmentsWithId($rootScope.authData.sessionId, $scope.emailModal.emailReplyId)
                    .then(
                        response => {
                            $scope.emailModal.attachments = response.data;
                            jQuery("#emailInfoModal").modal("show");
                        }
                    )
            }

            function closeEmailModal() {
                $scope.emailModal.content = undefined;
                jQuery("#emailInfoModal").modal("hide");
            }
        }
    ]
}); 

angular.module('emailCenterListOutgoingV2', []);

angular.module('emailCenterListOutgoingV2').component('emailCenterListOutgoingV2', {
    templateUrl: 'app/components/emailCenterListOutgoingV2/emailCenterListOutgoingV2.template.html',
    controller: [
        "$scope",
        "requestManager",
        "$rootScope",
        "$location",
        "util",
        "sharedMemory",
        "$filter",
        "Notification",
        "$route",
        "cookieManager",
        "paginationManager",
        "$window",
        function ($scope, requestManager, $rootScope, $location, util, sharedMemory, $filter, Notification, $route, cookieManager, paginationManager, $window) {
            this.$onInit = function () {
                initVariables();
                initScope();

                getRecords();
            };

            function initVariables() {
                $scope.initialLoading = true;
                $scope.records = [];
                $scope.loading = true;
                $scope.searchTerm = '';
                $scope.numShowEntriesOptions = [10, 20];
                $scope.paginator = {
                    items: [],
                    itemsPerPage: null,
                    itemsTotal: null,
                    pageCurrent: null,
                    pagesTotal: null
                };
                $scope.filters = {
                    page: 1,
                    pageSize: 10,
                    order: "entryCreated",
                    direction: "desc",
                    searchTerm: null,
                    filter: null
                };
                $scope.recordModal = undefined;
            }

            function initScope() {
                $scope.changeNumShowEntries = changeNumShowEntries;
                $scope.onSearch = onSearch;
                $scope.nextPage = nextPage;
                $scope.previousPage = previousPage;
                $scope.firstPage = firstPage;
                $scope.lastPage = lastPage;
                $scope.openEmailModal = openEmailModal;
                $scope.getEmailAttachmentAsPdf = getEmailAttachmentAsPdf;
                $scope.getPhysicalMailAttachmentAsPdf = getPhysicalMailAttachmentAsPdf;
                $scope.forwardToCustomer = forwardToCustomer;
            }

            function changeNumShowEntries() {
                $scope.filters.page = 1;
                getRecords();
            }

            function onSearch() {
                $scope.filters.page = 1;
                getRecords();
            }

            function firstPage() {
                $scope.filters.page = 1;
                getRecords();
            }

            function lastPage() {
                $scope.filters.page = $scope.paginator.pagesTotal;
                getRecords();
            }

            function nextPage() {
                $scope.filters.page++;
                getRecords();
            }

            function previousPage() {
                $scope.filters.page--;
                getRecords();
            }

            function getRecords() {
                $scope.loading = true;
                requestManager.routes.record.outbox($rootScope.authData.sessionId, $scope.filters).then(
                    function (response) {
                        $scope.paginator = response.data;
                        $scope.records = [...$scope.paginator.items];
                        $scope.initialLoading = false;
                        $scope.loading = false;
                    },
                    function () {
                    }
                );
            }

            function forwardToCustomer(customerId) {
                let tabWindow = $window.open('about:blank', '_blank');
                tabWindow.document.write('loading...');
                let currentPath = $window.location.href.substr(0, window.location.href.indexOf('#') + 1);
                tabWindow.location.href = currentPath + "!/user-details/" + customerId;
                tabWindow.location.reload();
            }

            function openEmailModal(record) {
                $scope.recordModal = record;
                jQuery("#emailInfoModalNormal").modal("show");
            }

            function getEmailAttachmentAsPdf(attachmentId) {
                requestManager.routes.record.emailAttachmentId($rootScope.authData.sessionId, attachmentId).then(
                    function (response) {
                        openPdf(response.data.data);
                    },
                    function () {
                        Notification.error("Fehler beim Laden des Anhangs! Bitte wenden Sie sich an den Support.");
                    }
                )
            }

            function getPhysicalMailAttachmentAsPdf(physicalMailId) {
                requestManager.routes.record.physicalMailId($rootScope.authData.sessionId, physicalMailId).then(
                    function (response) {
                        openPdf(response.data.data);
                    },
                    function () {
                        Notification.error("Fehler beim Laden des Anhangs! Bitte wenden Sie sich an den Support.");
                    }
                )
            }

            function openPdf(base64string) {
                const byteCharacters = atob(base64string);
                const byteNumbers = new Array(byteCharacters.length);
                for (let i = 0; i < byteCharacters.length; i++) {
                    byteNumbers[i] = byteCharacters.charCodeAt(i);
                }
                const byteArray = new Uint8Array(byteNumbers);

                const blob = new Blob([byteArray], { type: 'application/pdf' });

                const blobUrl = URL.createObjectURL(blob);
                window.open(blobUrl, '_blank');
            }

        }
    ]
}); 

angular.module('createSupportTicket', []);
angular.module('createSupportTicket').component('createSupportTicket', { 
	templateUrl: 'app/components/createSupportTicket/createSupportTicket.template.html',
    controller: [
        "$scope",
        "$rootScope",
        "requestManager",
        "util",
        "Notification",
        "$filter",
        "$route",
        "$location",
        function ($scope, $rootScope, requestManager, util, Notification, $filter, $route, $location) {
            var isPostingTicket = false;

            this.$onInit = function(){
                initVariables();
                initScope();
            };

            function initVariables(){
                $scope.ticketBody = {
                    name: $rootScope.authData.userData.name + ' ' + $rootScope.authData.userData.surname,
                    email: $rootScope.authData.userData.email,
                    subject: undefined,
                    description: undefined,
                    attachments: undefined
                }
                $scope.fileUploads = [];
            }

            function initScope(){
                $scope.postSupportTicket = postSupportTicket;
                $scope.addFileLine = addFileLine;
                $scope.deleteFileLine = deleteFileLine;
                $scope.getAttachments = getAttachments;
                $scope.getTotalMbSize = getTotalMbSize;
			}

            function postSupportTicket(){
                if(!isPostingTicket){
                    if(typeof $scope.ticketBody.name === 'undefined'
                        || typeof $scope.ticketBody.email === 'undefined'
                        || typeof $scope.ticketBody.subject === 'undefined'
                        || typeof $scope.ticketBody.description === 'undefined'){
                        Notification.error($filter("translate")("supportTicketCreate.MISSING_INPUT"))
                    } else {

                        // check size of attachments
                        var isSizeAllowed = getTotalMbSize();
                        if(isSizeAllowed === 'error'){
                            Notification.error($filter("translate")("supportTicketCreate.MORE_THAN_15MB"));
                            return;
                        }else if(isSizeAllowed === 'warning'){
                            // Notification.warning($filter("translate")("userDetailsActions.MORE_THAN_10MB"));
                            if(!confirm($filter("translate")("supportTicketCreate.MORE_THAN_10MB")))
                                return;
                        }

                        $scope.ticketBody.attachments = getAttachments();
                        console.log($scope.ticketBody);

                        // disable other clicks and post ticket to backend
                        isPostingTicket = true;
                        requestManager.routes.supportTicket.postOneSupportTicket($rootScope.authData.sessionId, $scope.ticketBody).then(
                            (response) => {
                                Notification.success($filter("translate")("supportTicketCreate.CREATE_TICKET_SUCCESSFUL"));
                                isPostingTicket = false;
                                $location.path("/support-ticket/list/" + response.data);
                            },
                            () => {
                                Notification.error($filter("translate")("supportTicketCreate.CREATE_TICKET_ERROR"))
                                isPostingTicket = false;
                            }
                        );
                    }
                }
            }

            function getAttachments() {
                var attachmentList = []
                $scope.fileUploads.forEach(
                    (value) => {
                        if (value !== 'null' && value.index !== 'false') {
                            var attachment = jQuery('#attachment' + value.index).prop('files')[0];
                            if (attachment) {
                                attachmentList.push(attachment);
                            }
                        }
                    }
                );
                return attachmentList;
            }

            function addFileLine() {

                $scope.fileUploads.push({
                    "index": $scope.fileUploads.length
                });

            }

            function deleteFileLine(index) {
                $scope.fileUploads[index] = {
                    index: 'false'
                };
            }

            function getTotalMbSize(){
                var attachmentList = []
                var bytes = 0;
                $scope.fileUploads.forEach(
                    (value) => {
                        if (value.index !== 'false') {
                            var attachment = jQuery('#attachment' + value.index).prop('files')[0];
                            if (attachment) {
                                bytes += attachment.size;
                                attachmentList.push(attachment);
                            }
                        }
                    }
                );

                if(bytes > 15000000) // more than 15MB
                    return 'error';
                else if(bytes >= 10000000) // more than 10MB
                    return 'warning';
                return 'success';
            }
        }
    ]
}); 

angular.module('supportTicketOverview', []);
angular.module('supportTicketOverview').component('supportTicketOverview', {
    templateUrl: 'app/components/supportTicketOverview/supportTicketOverview.template.html',
    controller: [
        "$scope",
        "$location",

        function ($scope, $location) {
            this.$onInit = function () {
                initScope();
            };

            function initScope() {
                $scope.forwardTo = forwardTo;
            }

            function forwardTo(path) {
                $scope.location = $location.path(path);
            }
        }
    ]
}); 

angular.module('listSupportTicket', []);
angular.module('listSupportTicket').component('listSupportTicket', { 
	templateUrl: 'app/components/listSupportTicket/listSupportTicket.template.html',
    controller: [
        "$scope",
        "$rootScope",
        "requestManager",
        "util",
        "Notification",
        "$filter",
        "$route",
        "$routeParams",
        function ($scope, $rootScope, requestManager, util, Notification, $filter, $route, $routeParams) {
            this.$onInit = function(){
                if($routeParams.id){ openTicketInDetail($routeParams.id); }

                initVariables();
                initScope();
            };

            function initVariables(){
                $scope.detailedTicket = undefined;
            }

            function initScope(){
                getAllTickets();
                $scope.getOneSupportTicketInDetail = getOneTicketInDetail;
                $scope.openTicketInDetail = openTicketInDetail;
                $scope.closeSupportTicketModal = closeSupportTicketModal;
            }

            function getAllTickets(){
                requestManager.routes.supportTicket.getAllSupportTickets($rootScope.authData.sessionId).then(
                    (response) => {
                        console.log(response);
                        $scope.supportTickets = response.data;
                    },
                    (response) => {
                        console.log("TICKETS NOT SUCCESS");
                    }
                );
            }

            function getOneTicketInDetail(ticket){
                requestManager.routes.supportTicket.getOneSupportTicketInDetail($rootScope.authData.sessionId, ticket.id).then(
                    (response) => {
                        $scope.detailedTicket = response.data;
                        $scope.detailedTicket.email = ticket.email;
                        $scope.detailedTicket.id = ticket.id;
                        $scope.detailedTicket.entryCreated = ticket.entryCreated;
                        console.log($scope.detailedTicket);
                    },
                    (response) => {
                        console.log("TICKETS NOT SUCCESS");
                    }
                );
            }

            function openTicketInDetail(ticketId){
                getOneTicketInDetail(ticketId);
                jQuery("#supportTicketModal").modal("show");
            }

            function closeSupportTicketModal() {
                $scope.detailedTicket = undefined;
                jQuery("#supportTicketModal").modal("hide");
            }
		} 
	] 
}); 

angular.module('bookstack', []);

angular.module('bookstack').component('bookstack', {
    templateUrl: 'app/components/bookstack/bookstack.template.html',
    transclude: true,
    bindings: {
        elementId: "<",
        pageId: "<"
    },
    controller: [
        "$scope",
        "bookstackApi",
        function ($scope, bookstackApi) {
            this.$onInit = function () {
                $scope.currentlyLoading = true;
                $scope.error = false;
                $scope.content = undefined;
                $scope.errorMessage = undefined;
                $scope.pageId = this.pageId;
                $scope.elementId = this.elementId;

                bookstackApi.fetchBookstack(this.pageId, this.elementId).then(
                    function (response) {
                        // if the response contains some property then the request was unsuccessful
                        if (response.hasOwnProperty('status')) {
                            $scope.error = true;
                            $scope.currentlyLoading = false;
                            if (response.data !== null) {
                                $scope.errorMessage = response.data.error.message;
                            }
                        } else {
                            $scope.content = response;
                            $scope.currentlyLoading = false;
                            $scope.error = false;
                        }
                    }
                );
            }
        }]
});

angular.module('emailSettings', []);

angular.module('emailSettings').component('emailSettings', {
    templateUrl: 'app/components/emailSettings/emailSettings.template.html',
    bindings: {
        settings: "<"
    },
    controller: [
        "$scope",
        "$rootScope",
        "requestManager",
        "util",
        "$window",
        "Notification",
        function ($scope, $rootScope, requestManager, util, $window, Notification) {
            init();

            function init() {
                initVariables();
                initScope();
            }

            function initVariables() {
                $scope.settings = {
                    currentlyLoading: true,
                    content: undefined,
                    individualEmailAddress: undefined,
                    senderId: undefined,
                    dnsId: undefined
                };
                $scope.inputValue = undefined;
                $scope.validated = undefined;
                $scope.ownerShipTokenRecordName = undefined;
                $scope.ownerShipToken = undefined;
                $scope.dkimRecordName = undefined;
                $scope.dkimRecordValue = undefined;
                $scope.spfRecordValue = undefined;
                $scope.domain = undefined;
                $scope.isCheckInProgress = undefined;
                $scope.dkimStatus = undefined;
                $scope.spfStatus = undefined;
                $scope.ownerShipTokenRecordNameCopied = false;
                $scope.ownerShipTokenCopied = false;
                $scope.dkimRecordNameCopied = false;
                $scope.dkimRecordValueCopied = false;
                $scope.spfRecordValueCopied = false;
                $scope.loading = true;
                $scope.createLoading = false;
                $scope.validateLoading = false;
                $scope.checkAgainLoading = false;
                $scope.deleteLoading = false;
                $scope.error = false;
            }

            function initScope() {
                $scope.checkAgain = checkAgain;
                $scope.deleteIndividualSenderAddress = deleteIndividualSenderAddress;
                $scope.saveChanges = saveChanges;
                $scope.validateIndividualSenderAddress = validateIndividualSenderAddress;
                $scope.ownerShipTokenRecordNameToClipboard = ownerShipTokenRecordNameToClipboard;
                $scope.ownerShipTokenToClipboard = ownerShipTokenToClipboard;
                $scope.dkimRecordNameToClipboard = dkimRecordNameToClipboard;
                $scope.dkimRecordValueToClipboard = dkimRecordValueToClipboard;
                $scope.domainToClipboard = domainToClipboard;
                $scope.spfRecordValueToClipboard = spfRecordValueToClipboard;
            }

            this.$onChanges = function set(item) {
                if ($scope.settings.individualEmailAddress === undefined && item.settings.currentValue) {
                    setIndividualEmailAddress(item);
                }
            }

            function setIndividualEmailAddress(item) {
                $scope.settings.content = angular.copy(item.settings.currentValue);
                $scope.settings.currentlyLoading = false;
                $scope.settings.individualEmailAddress = util.getSettingByName($scope.settings.content, "individualEmailAddress");
                $scope.settings.senderId = util.getSettingByName($scope.settings.content, "senderId");
                $scope.settings.dnsId = util.getSettingByName($scope.settings.content, "dnsId");
                if (!$scope.settings.individualEmailAddress && $scope.settings.senderId) {
                    $scope.inputValue = $scope.settings.senderId.settingValue2;
                }
                if ($scope.settings.individualEmailAddress) {
                    $scope.inputValue = $scope.settings.individualEmailAddress.settingValue1;
                }
                if ($scope.settings.senderId) {
                    getIndividualSenderAddress(validateIndividualSenderAddress());
                } else {
                    $scope.loading = false;
                }
            }

            function getIndividualSenderAddress(callback) {
                requestManager.routes.senderAddress.getIndividualSenderAddress($rootScope.authData.sessionId)
                    .then(
                        function (response) {
                            if (response.data[0] !== undefined) {
                                $scope.ownerShipTokenRecordName = response.data[0].OwnerShipTokenRecordName;
                                $scope.ownerShipToken = response.data[0].OwnerShipToken;
                                $scope.isCheckInProgress = response.data[0].IsCheckInProgress;
                                $scope.dkimRecordName = response.data[0].DKIMRecordName;
                                $scope.dkimRecordValue = response.data[0].DKIMRecordValue;
                                $scope.dkimStatus = response.data[0].DKIMStatus;
                                $scope.spfRecordValue = response.data[0].SPFRecordValue;
                                $scope.spfStatus = response.data[0].SPFStatus;
                                $scope.domain = response.data[0].Domain;
                            }
                            $scope.loading = false;
                            $scope.error = false;
                            if (callback !== undefined) {
                                callback();
                            }
                        },
                        function (response) {
                            $scope.loading = false;
                            if (response.data === 'Object not found') {
                                $scope.error = true;
                            }
                        }
                    )
            }

            function validateIndividualSenderAddress() {
                $scope.validateLoading = true;
                requestManager.routes.senderAddress.validateIndividualSenderAddress($rootScope.authData.sessionId)
                    .then(
                        function (response) {
                            $scope.validated = true;
                            // if OK then create setting individualEmailAddress
                            if (!$scope.settings.individualEmailAddress) {
                                requestManager.routes.settings.createSettingWithKey($rootScope.authData.sessionId, {
                                    settingCode: 'individualEmailAddress',
                                    settingValue1: $scope.settings.senderId.settingValue2
                                }).then(
                                    function (response) {
                                        $scope.validateLoading = false;
                                    },
                                    function (response) {
                                        $scope.validateLoading = false;
                                    }
                                )
                            }
                        },
                        function (response) {
                            $scope.validated = false;
                            $scope.validateLoading = false;
                        }
                    )
            }

            function checkAgain() {
                $scope.checkAgainLoading = true;
                requestManager.routes.senderAddress.checkIndividualSenderAddress($rootScope.authData.sessionId)
                    .then(
                        function (response) {
                            $scope.dkimStatus = response.data[0].DKIMStatus;
                            $scope.spfStatus = response.data[0].SPFStatus;
                            $scope.checkAgainLoading = false;
                        },
                        function (response) {
                            $scope.checkAgainLoading = false;
                        }
                    )
            }

            function deleteIndividualSenderAddress() {
                $scope.deleteLoading = true;
                requestManager.routes.senderAddress.deleteIndividualSenderAddress($rootScope.authData.sessionId)
                    .then(
                        function (response) {
                            $window.location.reload();
                            $scope.deleteLoading = false;
                        },
                        function (response) {
                            $scope.deleteLoading = false;
                        }
                    )
            }

            function saveChanges() {
                if ($scope.emailSettingsForm.$invalid) {
                    return;
                }

                $scope.createLoading = true;
                requestManager.routes.senderAddress.postIndividualSenderAddress($rootScope.authData.sessionId, $scope.emailSettingsForm.email.$viewValue)
                    .then(
                        function (response) {
                            getIndividualSenderAddress();
                            // get new setting values
                            requestManager.routes.settings.getSettings($rootScope.authData.sessionId).then(
                                function(response){
                                    $scope.settings.content = response.data;
                                    $scope.settings.senderId = util.getSettingByName($scope.settings.content, "senderId");
                                    $scope.settings.dnsId = util.getSettingByName($scope.settings.content, "dnsId");
                                },
                                function(response){
                                }
                            );
                            $scope.createLoading = false;
                        },
                        function (response) {
                            $scope.createLoading = false;
                            Notification.error(response.data);
                        }
                    )
            }

            function ownerShipTokenRecordNameToClipboard() {
                let r = document.createRange();
                r.selectNode(document.getElementById("ownerShipTokenRecordName"));
                window.getSelection().removeAllRanges();
                window.getSelection().addRange(r);
                document.execCommand('copy');
                window.getSelection().removeAllRanges();
                $scope.ownerShipTokenRecordNameCopied = true;
                setTimeout(() => {
                    $scope.ownerShipTokenRecordNameCopied = false;
                }, 1000);
            }

            function ownerShipTokenToClipboard() {
                let r = document.createRange();
                r.selectNode(document.getElementById("ownerShipToken"));
                window.getSelection().removeAllRanges();
                window.getSelection().addRange(r);
                document.execCommand('copy');
                window.getSelection().removeAllRanges();
                $scope.ownerShipTokenCopied = true;
                setTimeout(() => {
                    $scope.ownerShipTokenCopied = false;
                }, 1000);
            }

            function dkimRecordNameToClipboard() {
                let r = document.createRange();
                r.selectNode(document.getElementById("dkimRecordName"));
                window.getSelection().removeAllRanges();
                window.getSelection().addRange(r);
                document.execCommand('copy');
                window.getSelection().removeAllRanges();
                $scope.dkimRecordNameCopied = true;
                setTimeout(() => {
                    $scope.dkimRecordNameCopied = false;
                }, 1000);
            }

            function dkimRecordValueToClipboard() {
                let r = document.createRange();
                r.selectNode(document.getElementById("dkimRecordValue"));
                window.getSelection().removeAllRanges();
                window.getSelection().addRange(r);
                document.execCommand('copy');
                window.getSelection().removeAllRanges();
                $scope.dkimRecordValueCopied = true;
                setTimeout(() => {
                    $scope.dkimRecordValueCopied = false;
                }, 1000);
            }

            function domainToClipboard() {
                let r = document.createRange();
                r.selectNode(document.getElementById("domain"));
                window.getSelection().removeAllRanges();
                window.getSelection().addRange(r);
                document.execCommand('copy');
                window.getSelection().removeAllRanges();
                $scope.domainCopied = true;
                setTimeout(() => {
                    $scope.domainCopied = false;
                }, 1000);
            }

            function spfRecordValueToClipboard() {
                let r = document.createRange();
                r.selectNode(document.getElementById("spfRecordValue"));
                window.getSelection().removeAllRanges();
                window.getSelection().addRange(r);
                document.execCommand('copy');
                window.getSelection().removeAllRanges();
                $scope.spfRecordValueCopied = true;
                setTimeout(() => {
                    $scope.spfRecordValueCopied = false;
                }, 1000);
            }
        }
    ]
});

angular.module('inkassoSettings', []);

angular.module('inkassoSettings').component('inkassoSettings', {
    templateUrl: 'app/components/inkassoSettings/inkassoSettings.template.html',
    bindings: {
        settings: "<"
    },
    controller: [
        "$scope",
        "$rootScope",
        "requestManager",
        "util",
        "$route",
        "$filter",
        "Notification",
        function ($scope, $rootScope, requestManager, util, $route, $filter, Notification) {
            init();

            function init() {
                initVariables();
                initScope();
            }

            function initVariables() {
                $scope.loading = true;
                $scope.settings = undefined;
                $scope.formModel = undefined;
            }

            function initScope() {
                $scope.saveChanges = saveChanges;
            }

            this.$onChanges = function set(item) {
                if (item.settings.currentValue) {
                    $scope.settings = item.settings.currentValue;
                    $scope.loading = false;
                    initSettingsForm();
                    Object.values($scope.formModel).forEach(s => {
                        s.updated = false;
                    });
                }
            }

            function saveChanges() {
                const settingsToEdit = [];
                Object.values($scope.formModel).forEach(s => {
                    if (s.updated) {
                        settingsToEdit.push(s);
                    }
                });
                if (settingsToEdit.length !== 0) {
                    let finishedRequests = 0;
                    settingsToEdit.forEach(updatedSettings => {
                        const body = {
                            id: updatedSettings.id,
                            settingCode: updatedSettings.settingCode,
                            settingValue1: updatedSettings.settingValue1,
                            settingValue2: updatedSettings.settingValue2,
                            settingValue3: updatedSettings.settingValue3,
                        }
                        sendSaveChangesRequest($rootScope.authData.sessionId, body, function (requestDetails) {
                            if (requestDetails.successful) {
                                finishedRequests++;
                                if (finishedRequests === settingsToEdit.length) {
                                    Notification.success($filter("translate")("debtCollectionSettings.SAVE_SUCCESSFUL"));
                                    $route.reload();
                                }
                            } else {
                                sharedMemory.error.setError(
                                    requestDetails,
                                    "COULD_NOT_LOAD_DATA",
                                    "Fehler beim editieren eines neuen Settings (debtCollection)",
                                    $location.path(),
                                    "systemSettings"
                                );
                                $location.path("/error-page");
                            }
                        });
                    });
                }
            }

            function initSettingsForm() {
                $scope.formModel = {
                    iban: util.getSettingByName($scope.settings, "debtCollectionIban"),
                    bic: util.getSettingByName($scope.settings, "debtCollectionBic"),
                    accountOwner: util.getSettingByName($scope.settings, "debtCollectionAccountOwner"),
                    name: util.getSettingByName($scope.settings, "debtCollectionName"),
                    phone: util.getSettingByName($scope.settings, "debtCollectionPhone"),
                    email: util.getSettingByName($scope.settings, "debtCollectionEmail")
                };
                const settingsToCreate = [];
                if (!$scope.formModel.iban) {
                    settingsToCreate.push("debtCollectionIban");
                }
                if (!$scope.formModel.bic) {
                    settingsToCreate.push("debtCollectionBic");
                }
                if (!$scope.formModel.accountOwner) {
                    settingsToCreate.push("debtCollectionAccountOwner");
                }
                if (!$scope.formModel.name) {
                    settingsToCreate.push("debtCollectionName");
                }
                if (!$scope.formModel.phone) {
                    settingsToCreate.push("debtCollectionPhone");
                }
                if (!$scope.formModel.email) {
                    settingsToCreate.push("debtCollectionEmail");
                }
                if (settingsToCreate.length > 0) {
                    let finishedRequests = 0;
                    settingsToCreate.forEach(setting => {
                        sendCreateSettingRequest($rootScope.authData.sessionId, {
                            settingCode: setting
                        }, function(requestDetails) {
                            if (requestDetails.successful) {
                                finishedRequests++;
                                if (finishedRequests === settingsToCreate.length) {
                                    $route.reload();
                                }
                            } else {
                                sharedMemory.error.setError(
                                    requestDetails,
                                    "COULD_NOT_LOAD_DATA",
                                    "Fehler beim erstellen eines neuen Settings (debtCollection)",
                                    $location.path(),
                                    "systemSettings"
                                );
                                $location.path("/error-page");
                            }
                        });
                    });
                }
            }

            function sendSaveChangesRequest(sessionId, params, callback) {
                let requestDetails = {
                    successful: true,
                    response: undefined
                };

                requestManager.routes.settings.editSettingWithKey(sessionId, params).then(
                    function (response) {
                        requestDetails.response = response;
                        callback(requestDetails);
                    },
                    function (response) {
                        requestDetails.response = response;
                        requestDetails.successful = false;
                        callback(requestDetails);
                    }
                )
            }

            function sendCreateSettingRequest(sessionId, params, callback) {
                let requestDetails = {
                    successful: true,
                    response: undefined
                };

                requestManager.routes.settings.createSettingWithKey(sessionId, params).then(
                    function(response) {
                        requestDetails.response = response;
                        callback(requestDetails);
                    },
                    function(response) {
                        requestDetails.response = response;
                        requestDetails.successful = false;
                        callback(requestDetails);
                    }
                )
            }
        }
    ]
});

angular.module('newPassword', []);

angular.module('newPassword').component('newPassword', {
    templateUrl: 'app/components/newPassword/newPassword.template.html',
    controller: [
        "$scope",
        "$rootScope",
        "$routeParams",
        "$location",
        "$route",
        "requestManager",
        "cookieManager",
        "sharedMemory",
        "logOutTimer",
        "paginationManager",
        "Notification",
        function ($scope, $rootScope, $routeParams, $location, $route, requestManager, cookieManager, sharedMemory, logOutTimer, paginationManager, Notification) {
            this.$onInit = function () {
                initVariables();
                initScope();

                if ($routeParams && $routeParams.id) {
                    $rootScope.authData.sessionId = "";
                    $rootScope.authData.isLoggedIn = false;
                    $rootScope.authData.sessionExpire = undefined;

                    cookieManager.deleteCookie();
                    logOutTimer.deleteDate();
                    paginationManager.deleteIndex();
                    sharedMemory.reset();
                    window.localStorage.removeItem("data");
                    checkResetLink();
                }

                $scope.brandingLogo = undefined;
                $scope.imageLandscape = undefined;
                if ($rootScope.brandingLogo) {
                    $scope.brandingLogo = $rootScope.brandingLogo;
                    let img = new Image();
                    img.src = $scope.brandingLogo;
                    img.onload = function () {
                        $scope.imageLandscape = img.naturalWidth > img.naturalHeight;
                    }
                }
            }

            function initVariables() {
                $scope.loading = true;
                $scope.submitLoading = false;
                $scope.linkValid = true;
                $scope.resetDone = false;
                $scope.resetPasswordFormModel = {
                    password: "",
                    confirmPassword: ""
                };
                $scope.IEfound = false;
                let ua = window.navigator.userAgent;
                let msie = ua.indexOf("MSIE ");

                if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./))  // If Internet Explorer
                {
                    $scope.IEfound = true;
                }
            }

            function initScope() {
                $scope.passwordReset = passwordReset;
                $scope.toLogin = toLogin;
            }

            function checkResetLink() {
                requestManager.routes.auth.checkResetPasswordLink($routeParams.id).then(
                    function () {
                        $scope.linkValid = true;
                        $scope.loading = false;
                    },
                    function () {
                        $scope.linkValid = false;
                        Notification.error("Der Link zum Zurücksetzen des Passworts existiert nicht oder ist abgelaufen.");
                        $location.path('/login');
                    }
                )
            }

            function passwordReset() {
                if ($scope.resetPasswordFormModel.$invalid
                    || $scope.resetPasswordFormModel.password.length === 0
                    || $scope.resetPasswordFormModel.confirmPassword.length === 0
                    || $scope.resetPasswordFormModel.password !== $scope.resetPasswordFormModel.confirmPassword) {
                    return;
                }

                requestManager.routes.auth.resetPassword(
                    $routeParams.id,
                    $scope.resetPasswordFormModel.password,
                    $scope.resetPasswordFormModel.confirmPassword
                ).then(
                    function () {
                        $scope.submitLoading = false;
                        $scope.resetDone = true;
                    },
                    function () {
                        Notification.error("Bei der Verarbeitung der Anfrage ist es zu einem Fehler gekommen.");
                        $scope.submitLoading = false;
                    }
                )
            }

            function toLogin() {
                $location.path('/login');
            }
        }
    ]
});

angular.module("cookieManager", []);
angular.module("cookieManager").factory("cookieManager", [
    "$cookies",
    function($cookies){
        var cookieManager = {};

        cookieManager.cookieName = "datev_debitorcloud";


        cookieManager.saveCookie = function (object){
            $cookies.putObject(cookieManager.cookieName, object);
        };

        cookieManager.deleteCookie = function (){
            $cookies.remove(cookieManager.cookieName);
        };

        cookieManager.readCookie = function (){
            return $cookies.getObject(cookieManager.cookieName);
        };

        return cookieManager;
    }]
);
angular.module("requestManager", []);
angular.module("requestManager").factory("requestManager", [
    "$http",
    "$filter",
    "upload",
    "$location",
    "$q",
    function($http, $filter, upload, $location, $q) {
        var requestManager = {};

        requestManager.supportEmailAddress = "support@debitorcloud.com";
        requestManager.index = 0;
        requestManager.counter = 0;

        function getSubdomain() {
            if (window.localStorage.getItem("useInstance")) {
                return window.localStorage.getItem("useInstance");
            }
            return $location.absUrl()
                .replace("https://", "")
                .replace("http://", "")
                .split(".")[0];
        }

        function getBasePath() {
            requestManager.index++;

            if(requestManager.index == 6) {
                requestManager.counter++;
                requestManager.index = 0;
            }
            return "https://debitorc_" + getSubdomain() + requestManager.counter  + ".api.debitorcloud.com/index.php";
        }

        function getBrandingPath() {
            requestManager.index++;

            if (requestManager.index == 6) {
                requestManager.counter++;
                requestManager.index = 0;
            }
            return "https://debitorc_" + getSubdomain() + requestManager.counter + ".auth.debitorcloud.com/v2/index.php";
        }

        function getAuthenticationPath() {
            requestManager.index++;

            if(requestManager.index == 6) {
                requestManager.counter++;
                requestManager.index = 0;
            }
            return "https://debitorc_" + getSubdomain() + requestManager.counter  + ".auth.debitorcloud.com/index.php";
        }

        requestManager.getAuthPath = function () {
            return getAuthenticationPath();

        }

        requestManager.getSubDomain = function() {
            return getSubdomain();
        }

        requestManager.getBasePath = function (){
            return getBasePath();
        }


        // Neue Routen immer leer defininieren sonst undefined -> führt zu fehlern
        requestManager.routes = {
            economicYear: {},
            user: {},
            customer: {
                virtual: {}
            },
            customers: {},
            auth: {},
            resetPassword: {},
            accountsReceivable: {
                virtual: {}
            },
            accountsReceivableSettings: {},
            paymentTerm: {
                virtual: {}
            },
            paymentTermAppointment: {},
            record: {},
            customerStatistics: {},
            notifications: {},
            system: {},
            emailTemplates: {
                virtual: {}
            },
            recordEmail: {
                virtual: {}
            },
            report: {},
            settings: {},
            userSettings: {},
            task: {
                virtual: {}
            },
            workflow: {},
            recordEmailAttachment: {},
            creditReformRegistration: {},
            language: {},
            recordEmailReply: {},
            recordPhysicalMail: {
                virtual: {}
            },
            reminder: {},
            workflowFilter: {},
            recordInkasso: {},
            sync: {},
            fileupload: {},
            emailCenter: {
                incoming: {},
                outgoing: {}
            },
            supportTicket:{},
            senderAddress: {},
            clientManagement: {},
            branding: {},
            reset: {},
            masterData: {},
        };

        requestManager.pendingRequests = [];

        requestManager.stopPendingRequests = function(){
            requestManager.pendingRequests.forEach(function(cancelerPromise){
                cancelerPromise.resolve();
            });
        };

        /*
         *
         * Auth routes
         * */
        requestManager.routes.auth.auth = function(password, email) {
            var canceler = $q.defer();
            requestManager.pendingRequests.push(canceler);

            return $http({
                method: 'POST',
                timeout: canceler.promise,
                url: getAuthenticationPath() + '/auth',
                headers: {
                    "Content-Type": "application/json"
                },
                data: {
                    password: password,
                    email: email
                }
            });
        };

        requestManager.routes.auth.getUserIdBySessionId = function(sessionId) {
            var canceler = $q.defer();
            requestManager.pendingRequests.push(canceler);

            return $http({
                method: 'GET',
                timeout: canceler.promise,
                url: getAuthenticationPath() + '/auth/session/' + sessionId + "/user",
                headers: {
                    "Content-Type": "application/json"
                }
            });
        };

        requestManager.routes.auth.sendResetPasswordLink = function(email) {
            var canceler = $q.defer();
            requestManager.pendingRequests.push(canceler);

            return $http({
                method: 'POST',
                timeout: canceler.promise,
                url: getBrandingPath() + '/reset-password',
                headers: {
                    "Content-Type": "application/json"
                },
                data: {
                    email: email
                }
            });
        }

        requestManager.routes.auth.checkResetPasswordLink = function(id) {
            var canceler = $q.defer();
            requestManager.pendingRequests.push(canceler);

            return $http({
                method: 'GET',
                timeout: canceler.promise,
                url: getBrandingPath() + '/reset-password/' + id,
                headers: {
                    "Content-Type": "application/json"
                }
            });
        }

        requestManager.routes.auth.resetPassword = function(id, password, confirmPassword) {
            var canceler = $q.defer();
            requestManager.pendingRequests.push(canceler);

            return $http({
                method: 'POST',
                timeout: canceler.promise,
                url: getBrandingPath() + '/reset-password-confirm',
                headers: {
                    "Content-Type": "application/json"
                },
                data: {
                    id: id,
                    password: password,
                    confirmPassword: confirmPassword
                }
            });
        }

        requestManager.routes.auth.register = function(params) {
            var canceler = $q.defer();
            requestManager.pendingRequests.push(canceler);
            return $http({
                method: 'POST',
                timeout: canceler.promise,
                url: getAuthenticationPath() + '/register',
                headers: {
                    "Content-Type": "application/json"
                },
                data: params
            });
        };

        requestManager.routes.auth.checkDatabaseNameAvailability = function(params) {
            var canceler = $q.defer();
            requestManager.pendingRequests.push(canceler);
            return $http({
                method: 'GET',
                timeout: canceler.promise,
                url: getAuthenticationPath() + '/register/checkAvailability/' + params,
                headers: {
                    "Content-Type": "application/json"
                },
            });
        };

        requestManager.routes.auth.checkVat = function(params) {
            var canceler = $q.defer();
            requestManager.pendingRequests.push(canceler);
            return $http({
                method: 'GET',
                timeout: canceler.promise,
                url: getAuthenticationPath() + '/register/checkVat/' + params,
                headers: {
                    "Content-Type": "application/json"
                },
            });
        };

        requestManager.routes.auth.prepareGoCardlessPayment = function(params) {
            var canceler = $q.defer();
            requestManager.pendingRequests.push(canceler);

            return $http({
                method: 'POST',
                timeout: canceler.promise,
                url: getAuthenticationPath() + '/register/goCardless/preparePayment',
                headers: {
                    "Content-Type": "application/json"
                },
                data: {
                    successUrl: params.successUrl,
                    description: params.description,
                    sessionToken: params.sessionToken
                }
            });
        };

        requestManager.routes.auth.storeTemporary = function(params) {
            var canceler = $q.defer();
            requestManager.pendingRequests.push(canceler);

            return $http({
                method: 'POST',
                timeout: canceler.promise,
                url: getAuthenticationPath() + '/register/storeTemporary',
                headers: {
                    "Content-Type": "application/json"
                },
                data: params
            });
        };

        requestManager.routes.economicYear.actualizeDate = function(sessionId, params) {
            var canceler = $q.defer();
            requestManager.pendingRequests.push(canceler);

            return $http({
                method: 'POST',
                timeout: canceler.promise,
                url: getBasePath() + '/changeFiscalYear',
                headers: {
                    "Content-Type": "application/json",
                    "Session": sessionId
                },
                data: params
            });
        };

        /**
         * Web Crawler Request
         */

        requestManager.routes.customer.getCrawler = function(sessionId, customerName) {
            var canceler = $q.defer();
            requestManager.pendingRequests.push(canceler);

            return $http({
                method: 'GET',
                timeout: canceler.promise,
                headers: {
                    "Content-Type": "application/json",
                    "Session": sessionId
                },
                url: getBasePath() + '/debitor_crawler/' + customerName
            });
        };

        /*
         *
         * Customer routes
         * */

        requestManager.routes.customer.getAllCustomer = function(sessionId, filter) {
            var canceler = $q.defer();
            requestManager.pendingRequests.push(canceler);

            if (angular.isUndefined(filter)) {
                filter = "";
            }

            return $http({
                method: 'GET',
                timeout: canceler.promise,
                headers: {
                    "Content-Type": "application/json",
                    "Session": sessionId
                },
                url: getBasePath() + '/customer?filter=' + filter
            });
        };

        requestManager.routes.customer.getCustomerWithId = function(sessionId, id) {
            var canceler = $q.defer();
            requestManager.pendingRequests.push(canceler);


            return $http({
                method: 'GET',
                timeout: canceler.promise,
                headers: {
                    "Content-Type": "application/json",
                    "Session": sessionId
                },
                url: getBasePath() + '/customer/' + id
            });
        };

        requestManager.routes.customer.getCustomerCommunications = function(sessionId, id) {
            var canceler = $q.defer();
            requestManager.pendingRequests.push(canceler);


            return $http({
                method: 'GET',
                timeout: canceler.promise,
                headers: {
                    "Content-Type": "application/json",
                    "Session": sessionId
                },
                url: getBasePath() + '/customer/' + id + '/communications'
            });
        };

        requestManager.routes.customer.getCustomerMailCommunications = function(sessionId, id) {
            var canceler = $q.defer();
            requestManager.pendingRequests.push(canceler);


            return $http({
                method: 'GET',
                timeout: canceler.promise,
                headers: {
                    "Content-Type": "application/json",
                    "Session": sessionId
                },
                url: getBasePath() + '/customer/' + id + '/communications/mail'
            });
        };

        requestManager.routes.customer.editCustomerCommunications = function(sessionId, id, params) {
            var canceler = $q.defer();
            requestManager.pendingRequests.push(canceler);


            return $http({
                method: 'PUT',
                timeout: canceler.promise,
                headers: {
                    "Content-Type": "application/json",
                    "Session": sessionId
                },
                data: {
                    isActiveForDunning: params.isActiveForDunning
                },
                url: getBasePath() + '/customer_communications/' + id
            });
        };

        requestManager.routes.customer.editCustomer = function(sessionId, id, params) {
            var canceler = $q.defer();
            requestManager.pendingRequests.push(canceler);

            return $http({
                method: 'PUT',
                timeout: canceler.promise,
                headers: {
                    "Content-Type": "application/json",
                    "Session": sessionId
                },
                data: {
                    surname: params.surname,
                    address: params.address,
                    zip: params.zip,
                    city: params.city,
                    creditLimit: params.creditLimit,
                    country: params.country,
                    phone: params.phone,
                    email: params.email,
                    isProblematic: params.isProblematic,
                    customerAdvisor: params.customerAdvisor,
                    isAtCustomerAdvisor: params.isAtCustomerAdvisor,
                    getsOPList: params.getsOPList,
                    automaticDunning: params.automaticDunning,
                    getsDunningNote: params.getsDunningNote,
                    preferredContactMethod: params.preferredContactMethod,
                    individualField6: params.individualField6,
                    individualField7: params.individualField7,
                    getsOPopenList: params.getsOPopenList,
                },
                url: getBasePath() + '/customer/' + id
            });
        };

        requestManager.routes.customer.markCustomerProblematic = function(sessionId, id, isProblematic) {
            var canceler = $q.defer();
            requestManager.pendingRequests.push(canceler);

            return $http({
                method: 'PUT',
                timeout: canceler.promise,
                headers: {
                    "Content-Type": "application/json",
                    "Session": sessionId
                },
                data: {
                    isProblematic: isProblematic
                },
                url: getBasePath() + '/customer/' + id
            });
        };

        requestManager.routes.customer.getAllRecordsOfCustomer = function(sessionId, customerId, filter) {
            var canceler = $q.defer();
            requestManager.pendingRequests.push(canceler);

            if (angular.isUndefined(filter)) {
                filter = ""
            }

            return $http({
                method: 'GET',
                timeout: canceler.promise,
                headers: {
                    "Content-Type": "application/json",
                    "Session": sessionId
                },
                url: getBasePath() + '/customer/' + customerId + '/record?joinRecordDetails=true&filter=' + filter
            });
        };

        requestManager.routes.customer.createRecordOfCustomer = function(sessionId, customerId, recordType, addsToDunningLevel=0) {
            var canceler = $q.defer();
            requestManager.pendingRequests.push(canceler);

            return $http({
                method: 'POST',
                timeout: canceler.promise,
                dataType: "json",
                headers: {
                    "Session": sessionId,
                    "Content-Type": "application/json"
                },
                data: {
                    recordType: recordType,
                    addsToDunningLevel: addsToDunningLevel
                },
                url: getBasePath() + '/customer/' + customerId + '/record'
            });
        };

        requestManager.routes.customer.createPaymentTermOfCustomer = function(sessionId, customerId, params) {
            var canceler = $q.defer();
            requestManager.pendingRequests.push(canceler);

            return $http({
                method: 'POST',
                timeout: canceler.promise,
                headers: {
                    "Content-Type": "application/json",
                    "Session": sessionId
                },
                data: params,
                url: getBasePath() + '/customer/' + customerId + '/payment_term'
            });
        };

        requestManager.routes.customer.getPaymentTermsOfCustomer = function(sessionId, customerId) {
            var canceler = $q.defer();
            requestManager.pendingRequests.push(canceler);

            return $http({
                method: 'GET',
                timeout: canceler.promise,
                headers: {
                    "Content-Type": "application/json",
                    "Session": sessionId
                },

                url: getBasePath() + '/customer/' + customerId + '/payment_term'
            });
        };

        requestManager.routes.customer.deletePaymentTermOfCustomer = function(sessionId, customerId, paymentTermId) {
            var canceler = $q.defer();
            requestManager.pendingRequests.push(canceler);
            return $http({
                method: 'DELETE',
                timeout: canceler.promise,
                headers: {
                    "Content-Type": "application/json",
                    "Session": sessionId
                },

                url: getBasePath() + '/customer/' + customerId + '/payment_term/' + paymentTermId
            });
        };

        requestManager.routes.customer.getStatisticsOfCustomer = function(sessionId, customerId) {
            var canceler = $q.defer();
            requestManager.pendingRequests.push(canceler);

            return $http({
                method: 'GET',
                timeout: canceler.promise,
                headers: {
                    "Content-Type": "application/json",
                    "Session": sessionId
                },
                url: getBasePath() + '/customer/' + customerId + '/statistics/actual'
            });
        };

        requestManager.routes.customer.getTasksOfCustomer = function(sessionId, customerId) {
            var canceler = $q.defer();
            requestManager.pendingRequests.push(canceler);

            return $http({
                method: 'GET',
                timeout: canceler.promise,
                headers: {
                    "Content-Type": "application/json",
                    "Session": sessionId
                },
                url: getBasePath() + '/customer/' + customerId + '/task'
            });
        };

        requestManager.routes.record.getRecords = function(sessionId, filters) {
            var canceler = $q.defer();
            requestManager.pendingRequests.push(canceler);

            const params = filterParams(filters);

            let branch = 'main';
            let instance = location.host.substring(0, location.host.indexOf(".debitorcloud"));
            if (!instance) {
                // Development
                instance = window.localStorage.getItem("useInstance");
                branch = 'development';
            }

            // TODO remove next line
            branch = 'development';

            let url = `https://${branch}.api-v2.debitorcloud.com/records`;

            return $http({
                method: 'GET',
                timeout: canceler.promise,
                headers: {
                    "Content-Type": "application/json",
                    "Session": sessionId,
                    "X-Instance": `${instance}.debitorcloud.com`
                },
                params,
                url: url
            });
        };

        requestManager.routes.record.getUnapprovedRecords = function(sessionId, filters) {
            var canceler = $q.defer();
            requestManager.pendingRequests.push(canceler);

            const params = filterParams(filters);

            let branch = 'main';
            let instance = location.host.substring(0, location.host.indexOf(".debitorcloud"));
            if (!instance) {
                // Development
                instance = window.localStorage.getItem("useInstance");
                branch = 'development';
            }

            // TODO remove next line
            branch = 'development';

            let url = `https://${branch}.api-v2.debitorcloud.com/records`;

            return $http({
                method: 'GET',
                timeout: canceler.promise,
                headers: {
                    "Content-Type": "application/json",
                    "Session": sessionId,
                    "X-Instance": `${instance}.debitorcloud.com`
                },
                params,
                url: url
            });
        };

        requestManager.routes.record.inbox = function(sessionId, filters) {
            var canceler = $q.defer();
            requestManager.pendingRequests.push(canceler);

            const params = filterParams(filters);

            let branch = 'main';
            let instance = location.host.substring(0, location.host.indexOf(".debitorcloud"));
            if (!instance) {
                // Development
                instance = window.localStorage.getItem("useInstance");
                branch = 'development';
            }

            // TODO remove next line
            branch = 'development';

            let url = `https://${branch}.api-v2.debitorcloud.com/records/inbox`;

            return $http({
                method: 'GET',
                timeout: canceler.promise,
                headers: {
                    "Content-Type": "application/json",
                    "Session": sessionId,
                    "X-Instance": `${instance}.debitorcloud.com`
                },
                params,
                url: url
            });
        };

        requestManager.routes.record.outbox = function(sessionId, filters) {
            var canceler = $q.defer();
            requestManager.pendingRequests.push(canceler);

            const params = filterParams(filters);

            let branch = 'main';
            let instance = location.host.substring(0, location.host.indexOf(".debitorcloud"));
            if (!instance) {
                // Development
                instance = window.localStorage.getItem("useInstance");
                branch = 'development';
            }

            // TODO remove next line
            branch = 'development';

            let url = `https://${branch}.api-v2.debitorcloud.com/records/outbox`;

            return $http({
                method: 'GET',
                timeout: canceler.promise,
                headers: {
                    "Content-Type": "application/json",
                    "Session": sessionId,
                    "X-Instance": `${instance}.debitorcloud.com`
                },
                params,
                url: url
            });
        };

        requestManager.routes.record.emailAttachmentId = function(sessionId, id) {
            var canceler = $q.defer();
            requestManager.pendingRequests.push(canceler);

            let branch = 'main';
            let instance = location.host.substring(0, location.host.indexOf(".debitorcloud"));
            if (!instance) {
                // Development
                instance = window.localStorage.getItem("useInstance");
                branch = 'development';
            }

            // TODO remove next line
            branch = 'development';

            let url = `https://${branch}.api-v2.debitorcloud.com/records/emailAttachments/${id}`;

            return $http({
                method: 'GET',
                timeout: canceler.promise,
                response: 'blob',
                headers: {
                    "Content-Type": "arraybuffer",
                    "Session": sessionId,
                    "X-Instance": `${instance}.debitorcloud.com`
                },
                url: url
            });
        };

        requestManager.routes.record.physicalMailId = function(sessionId, id) {
            var canceler = $q.defer();
            requestManager.pendingRequests.push(canceler);

            let branch = 'main';
            let instance = location.host.substring(0, location.host.indexOf(".debitorcloud"));
            if (!instance) {
                // Development
                instance = window.localStorage.getItem("useInstance");
                branch = 'development';
            }

            // TODO remove next line
            branch = 'development';

            let url = `https://${branch}.api-v2.debitorcloud.com/records/physicalMail/${id}`;

            return $http({
                method: 'GET',
                timeout: canceler.promise,
                response: 'blob',
                headers: {
                    "Content-Type": "arraybuffer",
                    "Session": sessionId,
                    "X-Instance": `${instance}.debitorcloud.com`
                },
                url: url
            });
        };

        requestManager.routes.record.getAmountsUnapproved = function(sessionId) {
            var canceler = $q.defer();
            requestManager.pendingRequests.push(canceler);

            let branch = 'main';
            let instance = location.host.substring(0, location.host.indexOf(".debitorcloud"));
            if (!instance) {
                // Development
                instance = window.localStorage.getItem("useInstance");
                branch = 'development';
            }

            // TODO remove next line
            branch = 'development';

            let url = `https://${branch}.api-v2.debitorcloud.com/records/amountUnapprovedDunnings`;

            return $http({
                method: 'GET',
                timeout: canceler.promise,
                headers: {
                    "Content-Type": "application/json",
                    "Session": sessionId,
                    "X-Instance": `${instance}.debitorcloud.com`
                },
                url: url
            });
        }

        requestManager.routes.customers.getCustomers = function(sessionId, filters) {
            var canceler = $q.defer();
            requestManager.pendingRequests.push(canceler);

            const params = filterParams(filters);

            let branch = 'main';
            let instance = location.host.substring(0, location.host.indexOf(".debitorcloud"));
            if (!instance) {
                // Development
                instance = window.localStorage.getItem("useInstance");
                branch = 'development';
            }

            // TODO remove next line
            branch = 'development';

            let url = `https://${branch}.api-v2.debitorcloud.com/customer/extended`;

            return $http({
                method: 'GET',
                timeout: canceler.promise,
                headers: {
                    "Content-Type": "application/json",
                    "Session": sessionId,
                    "X-Instance": `${instance}.debitorcloud.com`
                },
                params,
                url: url
            });
        }

        requestManager.routes.record.getCustomerAdvisorRecord = function (sessionId, filters) {
            var canceler = $q.defer();
            requestManager.pendingRequests.push(canceler);

            const params = filterParams(filters);

            let branch = 'main';
            let instance = location.host.substring(0, location.host.indexOf(".debitorcloud"));
            if (!instance) {
                // Development
                instance = window.localStorage.getItem("useInstance");
                branch = 'development';
            }

            // TODO remove next line
            branch = 'development';

            let url = `https://${branch}.api-v2.debitorcloud.com/records`;

            return $http({
                method: 'GET',
                timeout: canceler.promise,
                headers: {
                    "Content-Type": "application/json",
                    "Session": sessionId,
                    "X-Instance": `${instance}.debitorcloud.com`
                },
                params,
                url: url
            });
        }

        requestManager.routes.customer.startCustomerAdvisor = function (sessionId, id, params) {
            var canceler = $q.defer();
            requestManager.pendingRequests.push(canceler);

            let branch = 'main';
            let instance = location.host.substring(0, location.host.indexOf(".debitorcloud"));
            if (!instance) {
                // Development
                instance = window.localStorage.getItem("useInstance");
                branch = 'development';
            }

            // TODO remove next line
            branch = 'development';

            let url = `https://${branch}.api-v2.debitorcloud.com/customer/${id}/createNewCustomerAdvisorCase`;

            return $http({
                method: 'POST',
                timeout: canceler.promise,
                headers: {
                    "Content-Type": "application/json",
                    "Session": sessionId,
                    "X-Instance": `${instance}.debitorcloud.com`
                },
                data: params,
                url: url
            });
        }

        requestManager.routes.customer.endCustomerAdvisor = function (sessionId, id, params) {
            var canceler = $q.defer();
            requestManager.pendingRequests.push(canceler);

            let branch = 'main';
            let instance = location.host.substring(0, location.host.indexOf(".debitorcloud"));
            if (!instance) {
                // Development
                instance = window.localStorage.getItem("useInstance");
                branch = 'development';
            }

            // TODO remove next line
            branch = 'development';

            let url = `https://${branch}.api-v2.debitorcloud.com/customer/${id}/closeCustomerAdvisorCase`;

            return $http({
                method: 'POST',
                timeout: canceler.promise,
                headers: {
                    "Content-Type": "application/json",
                    "Session": sessionId,
                    "X-Instance": `${instance}.debitorcloud.com`
                },
                data: params,
                url: url
            });
        }

        function filterParams(params) {
            const filteredParams = {};
            Object.keys(params).forEach(key => {
                if (params[key] !== null && params[key] !== undefined && params[key] !== '') {
                    filteredParams[key] = params[key];
                }
            });
            return filteredParams;
        }

        requestManager.routes.customer.virtual.getCustomerListExtended = function(authData,
                                                                                  limit = undefined,
                                                                                  offset = undefined,
                                                                                  sortBy = undefined,
                                                                                  sortAscending = undefined,
                                                                                  searchTerm = undefined,
                                                                                  problematic = undefined,
                                                                                  assignedToMe = undefined,
                                                                                  assignedToExternalPartner = undefined,
                                                                                  autoBrand = undefined,
                                                                                  autoHouse = undefined,
                                                                                  hasPartsInvoice = undefined,
                                                                                  hasNewCarInvoice = undefined,
                                                                                  hasUsedCarInvoice = undefined,
                                                                                  showAll = undefined)
        {
            var canceler = $q.defer();
            requestManager.pendingRequests.push(canceler);

            const filters = {
                offset: angular.isUndefined(offset) || isNaN(offset) ? 0 : +offset,
                limit: angular.isUndefined(limit) || isNaN(limit) ? 1000 : +limit,
                orderBy: angular.isDefined(sortBy) ? sortBy : null,
                orderByOrder: angular.isDefined(sortAscending) ? sortAscending : null,
                searchTerm: searchTerm,
                problematic: problematic ? problematic : null,
                assignedToMe: assignedToMe ? assignedToMe : null,
                assignedToExternalPartner: assignedToExternalPartner ? assignedToExternalPartner : null,
                filter_individualField1: autoBrand && autoBrand !== '20' ? autoBrand : null,
                filter_individualField2: autoHouse && autoHouse !== '100' ? autoHouse : null,
                filter_individualField3: hasPartsInvoice ? 1 : null,
                filter_individualField4: hasNewCarInvoice ? 1 : null,
                filter_individualField5: hasUsedCarInvoice ? 1 : null,
                showAll: !angular.isUndefined(showAll) && !showAll ? true : null,
            };
            const params = filterParams(filters);

            let url = getBasePath() + '/v2/customer/virtual/extended';

            return $http({
                method: 'GET',
                timeout: canceler.promise,
                headers: {
                    "Content-Type": "application/json",
                    "Session": authData.sessionId
                },
                params: params,
                url: url
            });
        };

        requestManager.routes.masterData.getAreaOfResponsibilities = function (sessionId) {
            var canceler = $q.defer();
            requestManager.pendingRequests.push(canceler);

            let branch = 'main';
            let instance = location.host.substring(0, location.host.indexOf(".debitorcloud"));
            if (!instance) {
                // Development
                instance = window.localStorage.getItem("useInstance");
                branch = 'development';
            }

            // TODO remove next line
            branch = 'development';

            let url = `https://${branch}.api-v2.debitorcloud.com/datev/masterData/areaOfResponsibilities`;

            return $http({
                method: 'GET',
                timeout: canceler.promise,
                headers: {
                    "Content-Type": "application/json",
                    "Session": sessionId,
                    "X-Instance": `${instance}.debitorcloud.com`
                },
                url: url
            });
        }

        requestManager.routes.customer.getCustomerExcelFile = function(sessionId) {
            var canceler = $q.defer();
            requestManager.pendingRequests.push(canceler);

            return $http({
                method: 'GET',
                timeout: canceler.promise,
                responseType: 'blob',
                headers: {
                    "Content-Type": "application/json",
                    "Session": sessionId
                },
                url: getBasePath() + '/customer_list_export'
            });
        };

        requestManager.routes.customer.virtual.getAccountsReceivableSummaryOfCustomer = function(sessionId, customerId, filter) {
            var canceler = $q.defer();
            requestManager.pendingRequests.push(canceler);

            if (angular.isUndefined(filter)) {
                filter = ""
            }

            return $http({
                method: 'GET',
                timeout: canceler.promise,
                headers: {
                    "Content-Type": "application/json",
                    "Session": sessionId
                },
                url: getBasePath() + '/customer/' + customerId + '/accounts_receivable/summary?filter=' + filter
            });
        };

        requestManager.routes.customer.virtual.getProblematicCustomer = function(sessionId) {
            var canceler = $q.defer();
            requestManager.pendingRequests.push(canceler);

            return $http({
                method: 'GET',
                timeout: canceler.promise,
                headers: {
                    "Content-Type": "application/json",
                    "Session": sessionId
                },
                url: getBasePath() + '/customer/virtual/problematic'
            });
        };

        requestManager.routes.customer.virtual.getStalledCustomer = function(sessionId) {
            var canceler = $q.defer();
            requestManager.pendingRequests.push(canceler);

            return $http({
                method: 'GET',
                timeout: canceler.promise,
                headers: {
                    "Content-Type": "application/json",
                    "Session": sessionId
                },
                url: getBasePath() + '/customer/virtual/stalled' // /customer/0/historical_statistics
            });
        };

        /*
         * User routes
         * */

        requestManager.routes.user.getAllUser = function(sessionId, filter) {
            var canceler = $q.defer();
            requestManager.pendingRequests.push(canceler);

            if (angular.isUndefined(filter)) {
                filter = ""
            }

            return $http({
                method: 'GET',
                timeout: canceler.promise,
                headers: {
                    "Content-Type": "application/json",
                    "Session": sessionId
                },
                url: getBasePath() + '/user?filter=' + filter
            });
        };

        requestManager.routes.user.createUser = function(sessionId, params) {
            var canceler = $q.defer();
            requestManager.pendingRequests.push(canceler);

            return $http({
                method: 'POST',
                timeout: canceler.promise,
                headers: {
                    "Content-Type": "application/json",
                    "Session": sessionId
                },
                data: {
                    name: params.name,
                    surname: params.surname,
                    email: params.email,
                    password: params.password,
                    foreignPersonalKey: params.foreignPersonalKey
                },
                url: getBasePath() + '/user'
            });
        };

        // route to create a user in the setup wizard and send an invitation with a password via email address
        requestManager.routes.user.addUser = function(sessionId, params) {
            var canceler = $q.defer();
            requestManager.pendingRequests.push(canceler);

            return $http({
                method: 'POST',
                timeout: canceler.promise,
                headers: {
                    "Content-Type": "application/json",
                    "Session": sessionId
                },
                data: {
                    name: params.name,
                    surname: params.surname,
                    email: params.email,
                    administrator: params.administrator,
                    approvalStatus: params.approvalStatus,
                    companyName: params.companyName
                },
                url: getBasePath() + '/add-user'
            });
        };

        requestManager.routes.user.editUser = function(sessionId, params) {
            var canceler = $q.defer();
            requestManager.pendingRequests.push(canceler);

            return $http({
                method: 'PUT',
                timeout: canceler.promise,
                headers: {
                    "Content-Type": "application/json",
                    "Session": sessionId
                },
                data: {
                    name: params.name,
                    surname: params.surname,
                    email: params.email,
                    password: params.password,
                    disabled: params.disabled,
                    foreignPersonalKey: params.foreignPersonalKey,
                    approvalNotification: params.approvalNotification,
                    isDefaultCustomerAdvisor: params.isDefaultCustomerAdvisor,
                    wantsNewsletter: params.wantsNewsletter,
                    administrator: params.administrator,
                    approvalStatus: params.approvalStatus,
                    canCreateDunningNotes: params.canCreateDunningNotes,
                    canCreateComments: params.canCreateComments,
                    canDeleteComments: params.canDeleteComments,
                    canCreatePhoneCalls: params.canCreatePhoneCalls,
                    canEditReceivableInformation: params.canEditReceivableInformation,
                    canEditTasks: params.canEditTasks,
                    canMakeCustomersProblematic: params.canMakeCustomersProblematic,
                },
                url: getBasePath() + '/user/' + params.id
            });
        };

        requestManager.routes.user.changeUserPassword = function(sessionId, params) {
            var canceler = $q.defer();
            requestManager.pendingRequests.push(canceler);

            return $http({
                method: 'PUT',
                timeout: canceler.promise,
                headers: {
                    "Content-Type": "application/json",
                    "Session": sessionId
                },
                data: {
                    oldPassword: params.oldPassword,
                    newPassword: params.newPassword
                },
                url: getBasePath() + '/user/' + params.id + "/changePassword"
            });
        };

        requestManager.routes.user.getUserWithId = function(sessionId, id) {
            var canceler = $q.defer();
            requestManager.pendingRequests.push(canceler);

            return $http({
                method: 'GET',
                timeout: canceler.promise,
                headers: {
                    "Content-Type": "application/json",
                    "Session": sessionId
                },
                url: getBasePath() + '/user/' + id
            });
        };

        requestManager.routes.user.createNotificationOfUser = function(sessionId, params) {
            var canceler = $q.defer();
            requestManager.pendingRequests.push(canceler);

            return $http({
                method: 'POST',
                timeout: canceler.promise,
                headers: {
                    "Content-Type": "application/json",
                    "Session": sessionId
                },
                data: {
                    customerForReminder: params.customerForReminder,
                    dateReminder: params.dateReminder,
                    userDidEntry: params.userId,
                    notificationText: params.notificationText
                },
                url: getBasePath() + '/user/' + params.userId + "/notification"
            });
        };

        requestManager.routes.user.getFavouriteCustomer = function(sessionId, userId, filter) {
            var canceler = $q.defer();
            requestManager.pendingRequests.push(canceler);
            var url;

            if (angular.isUndefined(filter)) {
                url = getBasePath() + '/user/' + userId + "/customer"
            } else {
                url = getBasePath() + '/user/' + userId + "/customer?filter=" + filter;
            }

            return $http({
                method: 'GET',
                timeout: canceler.promise,
                headers: {
                    "Content-Type": "application/json",
                    "Session": sessionId
                },
                url: url
            });
        };

        requestManager.routes.user.setFavouriteCustomer = function(sessionId, userId, customerId) {
            var canceler = $q.defer();
            requestManager.pendingRequests.push(canceler);

            return $http({
                method: 'POST',
                timeout: canceler.promise,
                headers: {
                    "Content-Type": "application/json",
                    "Session": sessionId
                },
                data: {
                    customerId: customerId
                },
                url: getBasePath() + '/user/' + userId + "/customer"
            });
        };

        requestManager.routes.user.removeFavouriteCustomer = function(sessionId, userId, customerId) {
            var canceler = $q.defer();
            requestManager.pendingRequests.push(canceler);

            return $http({
                method: 'DELETE',
                timeout: canceler.promise,
                headers: {
                    "Content-Type": "application/json",
                    "Session": sessionId
                },
                data: {
                    customerId: customerId
                },
                url: getBasePath() + '/user/' + userId + "/customer"
            });
        };

        requestManager.routes.user.getSettingsOfUser = function(sessionId, userId) {
            var canceler = $q.defer();
            requestManager.pendingRequests.push(canceler);

            return $http({
                method: 'GET',
                timeout: canceler.promise,
                headers: {
                    "Content-Type": "application/json",
                    "Session": sessionId
                },
                url: getBasePath() + '/user/' + userId + "/settings"
            });
        };

        requestManager.routes.user.createSettingsOfUser = function(sessionId, setting, userId) {
            var canceler = $q.defer();
            requestManager.pendingRequests.push(canceler);

            return $http({
                method: 'POST',
                timeout: canceler.promise,
                headers: {
                    "Content-Type": "application/json",
                    "Session": sessionId
                },
                data: {
                    settingCode: setting.settingCode,
                    value1: setting.value1,
                    value2: setting.value2,
                    value3: setting.value3
                },
                url: getBasePath() + '/user/' + userId + "/settings"
            });
        };

        /*
         * Accounts receivable routes
         * */

        requestManager.routes.accountsReceivable.getAccountsReceivable = function(sessionId, filter) {
            var canceler = $q.defer();
            requestManager.pendingRequests.push(canceler);
            var url = angular.isUndefined(filter) ? '/accounts_receivable' : '/accounts_receivable?filter=' + JSON.stringify(filter);

            return $http({
                method: 'GET',
                timeout: canceler.promise,
                headers: {
                    "Content-Type": "application/json",
                    "Session": sessionId
                },
                url: getBasePath() + url
            });
        };

        requestManager.routes.accountsReceivable.editAccountsReceivable = function(sessionId, id, params) {
            var canceler = $q.defer();
            requestManager.pendingRequests.push(canceler);

            return $http({
                method: 'PUT',
                timeout: canceler.promise,
                headers: {
                    "Content-Type": "application/json",
                    "Session": sessionId
                },
                data: {
                    hasDunningBlock: params.hasDunningBlock,
                    hasInterestBlock: params.hasInterestBlock,
                    isInPaymentTerm: params.isInPaymentTerm,
                    dueDate: params.dueDate,
                    virtualDueDate: params.virtualDueDate,
                    virtualDueDateEntry: params.virtualDueDateEntry,
                    inDebtCollecton: params.inDebtCollecton
                },
                url: getBasePath() + '/accounts_receivable/' + id
            });
        };

        requestManager.routes.accountsReceivableSettings.getAccountsReceivableSettings = function(sessionId) {
            var canceler = $q.defer();
            requestManager.pendingRequests.push(canceler);

            return $http({
                method: 'GET',
                timeout: canceler.promise,
                headers: {
                    "Content-Type": "application/json",
                    "Session": sessionId
                },
                url: getBasePath() + '/accounts_receivable_settings'
            });
        }

        requestManager.routes.accountsReceivableSettings.editAccountsReceivableSettings = function(sessionId, id, params) {
            var canceler = $q.defer();
            requestManager.pendingRequests.push(canceler);

            return $http({
                method: 'PUT',
                timeout: canceler.promise,
                headers: {
                    "Content-Type": "application/json",
                    "Session": sessionId
                },
                data: params,
                url: getBasePath() + '/accounts_receivable_settings/' + id
            });
        }

        requestManager.routes.accountsReceivable.postDATEV = function(sessionId, params) {
            var canceler = $q.defer();
            requestManager.pendingRequests.push(canceler);

            return $http({
                method: 'POST',
                timeout: canceler.promise,
                headers: {
                    "Content-Type": "application/json",
                    "Session": sessionId
                },
                data: params,
                url: getBasePath() + '/book'
            });
        };

        requestManager.routes.accountsReceivable.deactivateAccountsReceivableDunningBlock = function(sessionId, accountNumber, openItemNumber) {
            var canceler = $q.defer();
            requestManager.pendingRequests.push(canceler);

            return $http({
                method: 'PUT',
                timeout: canceler.promise,
                headers: {
                    "Content-Type": "application/json",
                    "Session": sessionId
                },
                data: {
                    accountNumber: accountNumber,
                    openItemNumber: openItemNumber
                },
                url: getBasePath() + '/accounts_receivable/dunningBlock/deactivate'
            });
        };

        requestManager.routes.accountsReceivable.editAmountReminders = function(sessionId, accountNumber, openItemNumber, amountReminders) {
            var canceler = $q.defer();
            requestManager.pendingRequests.push(canceler);

            return $http({
                method: 'PUT',
                timeout: canceler.promise,
                headers: {
                    "Content-Type": "application/json",
                    "Session": sessionId
                },
                data: {
                    amountReminders: amountReminders
                },
                url: getBasePath() + '/customer/' + accountNumber + '/openItem/' + openItemNumber +'/amountReminders'
            })
        };

        requestManager.routes.accountsReceivable.activateAccountsReceivableDunningBlock = function(sessionId, accountNumber, openItemNumber) {
            var canceler = $q.defer();
            requestManager.pendingRequests.push(canceler);

            return $http({
                method: 'PUT',
                timeout: canceler.promise,
                headers: {
                    "Content-Type": "application/json",
                    "Session": sessionId
                },
                data: {
                    accountNumber: accountNumber,
                    openItemNumber: openItemNumber
                },
                url: getBasePath() + '/accounts_receivable/dunningBlock/activate'
            });
        };

        requestManager.routes.accountsReceivable.virtual.getPaidInvoicesOfCustomer = function(sessionId, customerId) {
            var canceler = $q.defer();
            requestManager.pendingRequests.push(canceler);

            return $http({
                method: 'GET',
                timeout: canceler.promise,
                headers: {
                    "Content-Type": "application/json",
                    "Session": sessionId
                },
                url: getBasePath() + '/customer/' + customerId + '/paidInvoices'
            });
        };

        requestManager.routes.accountsReceivable.getPausedInvoices = function(sessionId) {
            var canceler = $q.defer();
            requestManager.pendingRequests.push(canceler);

            return $http({
                method: 'GET',
                timeout: canceler.promise,
                headers: {
                    "Content-Type": "application/json",
                    "Session": sessionId
                },
                url: getBasePath() + '/invoice/paused'
            });
        };

        requestManager.routes.accountsReceivable.createDebtCollectionRequest = function(sessionId, openItemNumbers, customerId, userId, description) {
            var canceler = $q.defer();
            requestManager.pendingRequests.push(canceler);

            return $http({
                method: 'POST',
                timeout: canceler.promise,
                headers: {
                    "Content-Type": "application/json",
                    "Session": sessionId
                },
                data: {
                    openItemNumbers: openItemNumbers,
                    customerId: customerId,
                    userId: userId,
                    description: description
                },
                url: getBasePath() + '/accounts_receivable/debt_collection'
            });
        };

        requestManager.routes.accountsReceivable.postCollectionAkv = function (sessionId, customerId, openItemNumbers, recordDescription) {
            var canceler = $q.defer();
            requestManager.pendingRequests.push(canceler);

            return $http({
                method: 'POST',
                timeout: canceler.promise,
                headers: {
                    "Content-Type": "application/json",
                    "Session": sessionId
                },
                data: {
                    customerId,
                    openItemNumbers,
                    recordDescription
                },
                url: getBasePath() + '/collection_akv'
            });
        }

        /*
         * Payment terms routes
         * */
        requestManager.routes.paymentTerm.getAllPaymentTerms = function(sessionId) {

            var canceler = $q.defer();
            requestManager.pendingRequests.push(canceler);

            return $http({
                method: 'GET',
                timeout: canceler.promise,
                headers: {
                    "Content-Type": "application/json",
                    "Session": sessionId
                },
                url: getBasePath() + '/payment_term'
            });
        };

        requestManager.routes.paymentTerm.getAllUnfinishedPaymentTerms = function(sessionId) {

            var canceler = $q.defer();
            requestManager.pendingRequests.push(canceler);

            return $http({
                method: 'GET',
                timeout: canceler.promise,
                headers: {
                    "Content-Type": "application/json",
                    "Session": sessionId
                },
                url: getBasePath() + '/payment_term?filter=[{"0":"finished", "1":"IS", "2":"NULL"}]'
            });
        };

        requestManager.routes.paymentTerm.createPaymentTerm = function(sessionId, params) {
            var canceler = $q.defer();
            requestManager.pendingRequests.push(canceler);

            return $http({
                method: 'POST',
                timeout: canceler.promise,
                headers: {
                    "Content-Type": "application/json",
                    "Session": sessionId
                },
                data: {
                    amountTotal: params.amountTotal,
                    amountInterval: params.amountInterval,
                    sizeInterval: params.sizeInterval,
                    intervals: params.intervals,
                    amountInterest: params.amountInterest
                },
                url: getBasePath() + '/payment_term'
            });
        };

        requestManager.routes.paymentTerm.createPaymentTermAppointmentOfPaymentTerm = function(sessionId, paymentTermId, params) {
            var canceler = $q.defer();
            requestManager.pendingRequests.push(canceler);

            return $http({
                method: 'POST',
                timeout: canceler.promise,
                headers: {
                    "Content-Type": "application/json",
                    "Session": sessionId
                },
                data: {
                    amountTotal: params.amountTotal,
                    dateAppointment: $filter("date")(params.dateAppointment, "yyyy.MM.dd hh.mm.ss"),
                    amountInterest: params.amountInterest
                },
                url: getBasePath() + '/payment_term/' + paymentTermId + '/payment_term_appointments'
            });
        };

        requestManager.routes.paymentTerm.deletePaymentTermAppointmentOfPaymentTerm = function(sessionId, paymentTermId, paymentTermAppointmentId) {
            var canceler = $q.defer();
            requestManager.pendingRequests.push(canceler);

            return $http({
                method: 'DELETE',
                timeout: canceler.promise,
                headers: {
                    "Content-Type": "application/json",
                    "Session": sessionId
                },
                url: getBasePath() + '/payment_term/' + paymentTermId + '/payment_term_appointments/' + paymentTermAppointmentId
            });
        };

        requestManager.routes.paymentTerm.getPaymentTermAppointmentsOfPaymentTerm = function(sessionId, paymentTermId) {
            var canceler = $q.defer();
            requestManager.pendingRequests.push(canceler);

            return $http({
                method: 'GET',
                timeout: canceler.promise,
                headers: {
                    "Content-Type": "application/json",
                    "Session": sessionId
                },
                url: getBasePath() + '/payment_term/' + paymentTermId + '/payment_term_appointments'
            });
        };

        requestManager.routes.paymentTerm.getCustomerOfPaymentTerm = function(sessionId, paymentTermId) {
            var canceler = $q.defer();
            requestManager.pendingRequests.push(canceler);

            return $http({
                method: 'GET',
                timeout: canceler.promise,
                headers: {
                    "Content-Type": "application/json",
                    "Session": sessionId
                },
                url: getBasePath() + '/payment_term/' + paymentTermId + '/customer'
            });
        };

        requestManager.routes.paymentTerm.getPaymentTermOfPaymentTermAppointment = function(sessionId, paymentTermAppointmentId) {
            var canceler = $q.defer();
            requestManager.pendingRequests.push(canceler);

            return $http({
                method: 'GET',
                timeout: canceler.promise,
                headers: {
                    "Content-Type": "application/json",
                    "Session": sessionId
                },
                url: getBasePath() + '/payment_term_appointment/' + paymentTermAppointmentId + '/payment_term'
            });
        };

        requestManager.routes.paymentTerm.virtual.getPaymentTermsOverdue = function(sessionId) {
            var canceler = $q.defer();
            requestManager.pendingRequests.push(canceler);

            return $http({
                method: 'GET',
                timeout: canceler.promise,
                headers: {
                    "Content-Type": "application/json",
                    "Session": sessionId
                },
                url: getBasePath() + '/payment_term_overdue'
            });
        };

        /*
         * Payment term appointments
         * */

        requestManager.routes.paymentTermAppointment.getAllPaymentTermsAppointments = function(sessionId, filter) {
            var canceler = $q.defer();
            requestManager.pendingRequests.push(canceler);

            if (angular.isUndefined(filter)) {
                filter = ""
            }

            return $http({
                method: 'GET',
                timeout: canceler.promise,
                headers: {
                    "Content-Type": "application/json",
                    "Session": sessionId
                },
                url: getBasePath() + '/payment_term_appointment?filter=' + filter
            });
        };

        requestManager.routes.paymentTermAppointment.deletePaymentTermAppointmentWithId = function(sessionId, paymentTermAppointmentId) {
            var canceler = $q.defer();
            requestManager.pendingRequests.push(canceler);
            return $http({
                method: 'DELETE',
                timeout: canceler.promise,
                headers: {
                    "Content-Type": "application/json",
                    "Session": sessionId
                },
                url: getBasePath() + '/payment_term_appointment/' + paymentTermAppointmentId
            });
        };


        /*
         * Record routes
         * */

        requestManager.routes.record.getAllRecords = function(sessionId, filter) {
            var canceler = $q.defer();
            requestManager.pendingRequests.push(canceler);

            var url = undefined;
            if (angular.isUndefined(filter)) {
                url = getBasePath() + '/record';
            } else {
                url = getBasePath() + '/record?filter=' + filter;
            }

            return $http({
                method: 'GET',
                timeout: canceler.promise,
                headers: {
                    "Content-Type": "application/json",
                    "Session": sessionId
                },
                url: url
            });
        };

        requestManager.routes.record.getAllCommentRecordsOfRecord = function(sessionId, recordId, filter) {
            var canceler = $q.defer();
            requestManager.pendingRequests.push(canceler);

            if (angular.isUndefined(filter)) {
                filter = ""
            }

            return $http({
                method: 'GET',
                timeout: canceler.promise,
                headers: {
                    "Content-Type": "application/json",
                    "Session": sessionId
                },
                url: getBasePath() + '/record/' + recordId + '/record_comment?filter='
            });
        };


        requestManager.routes.record.getPinnedComment = function(customerId, sessionId) {
            var canceler = $q.defer();
            requestManager.pendingRequests.push(canceler);

            return $http({
                method: 'GET',
                timeout: canceler.promise,
                headers: {
                    "Content-Type": "application/json",
                    "Session": sessionId
                },
                url: getBasePath() + '/customer/' + customerId + '/record_comment/pinned'
            });
        };

        requestManager.routes.record.getAllPhoneRecordsOfRecord = function(sessionId, recordId, filter) {
            var canceler = $q.defer();
            requestManager.pendingRequests.push(canceler);

            if (angular.isUndefined(filter)) {
                filter = ""
            }

            return $http({
                method: 'GET',
                timeout: canceler.promise,
                headers: {
                    "Content-Type": "application/json",
                    "Session": sessionId
                },
                url: getBasePath() + '/record/' + recordId + '/record_phone?filter='
            });
        };

        requestManager.routes.record.getAllEmailReplyRecordsOfRecord = function(sessionId, recordId) {
            var canceler = $q.defer();
            requestManager.pendingRequests.push(canceler);

            return $http({
                method: 'GET',
                timeout: canceler.promise,
                headers: {
                    "Content-Type": "application/json",
                    "Session": sessionId
                },
                url: getBasePath() + '/record/' + recordId + '/record_email_reply'
            });
        };

        requestManager.routes.record.getAllEmailRecordsOfRecord = function(sessionId, recordId, filter) {
            var canceler = $q.defer();
            requestManager.pendingRequests.push(canceler);

            var url = undefined;

            if (angular.isUndefined(filter)) {
                url = getBasePath() + '/record/' + recordId + '/record_email';
            } else {
                url = getBasePath() + '/record/' + recordId + '/record_email?filter=' + filter;
            }

            return $http({
                method: 'GET',
                timeout: canceler.promise,
                headers: {
                    "Content-Type": "application/json",
                    "Session": sessionId
                },
                url: url
            });
        };

        requestManager.routes.record.createPhoneRecordOfRecord = function(sessionId, recordId, params) {
            var canceler = $q.defer();
            requestManager.pendingRequests.push(canceler);

            return $http({
                method: 'POST',
                timeout: canceler.promise,
                headers: {
                    "Content-Type": "application/json",
                    "Session": sessionId
                },
                data: {
                    phoneNumber: params.phoneNumber,
                    text: params.text,
                    success: params.success
                },
                url: getBasePath() + '/record/' + recordId + '/record_phone?filter='
            });
        };

        requestManager.routes.record.createCommentRecordOfRecord = function(sessionId, recordId, text) {
            var canceler = $q.defer();
            requestManager.pendingRequests.push(canceler);

            return $http({
                method: 'POST',
                timeout: canceler.promise,
                headers: {
                    "Content-Type": "application/json",
                    "Session": sessionId
                },
                data: {
                    text: text
                },
                url: getBasePath() + '/record/' + recordId + '/record_comment?filter='
            });
        };

        requestManager.routes.record.putCommentRecord = function(sessionId, commentId, body) {
            var canceler = $q.defer();
            requestManager.pendingRequests.push(canceler);

            return $http({
                method: 'PUT',
                timeout: canceler.promise,
                headers: {
                    "Content-Type": "application/json",
                    "Session": sessionId
                },
                data: body,
                url: getBasePath() + '/record_comment/' + commentId
            });
        };

        requestManager.routes.record.getAllEmailReplyRecordsOfRecord = function(sessionId, recordEmailReplyId) {
            var canceler = $q.defer();
            requestManager.pendingRequests.push(canceler);

            return $http({
                method: 'GET',
                timeout: canceler.promise,
                headers: {
                    "Content-Type": "application/json",
                    "Session": sessionId
                },
                url: getBasePath() + '/record/' + recordEmailReplyId + '/record_email_reply'
            });
        };

        requestManager.routes.record.getRecordPhysicalMailOfRecord = function(sessionId, recordPhysicalMailId) {
            var canceler = $q.defer();
            requestManager.pendingRequests.push(canceler);

            return $http({
                method: 'GET',
                timeout: canceler.promise,
                headers: {
                    "Content-Type": "application/json",
                    "Session": sessionId
                },
                url: getBasePath() + '/record/' + recordPhysicalMailId + '/record_physical_mail'
            });
        };


        requestManager.routes.record.createEmailRecordOfRecord = function(sessionId, recordId, params) {
            var canceler = $q.defer();
            requestManager.pendingRequests.push(canceler);

            return $http({
                method: 'POST',
                timeout: canceler.promise,
                headers: {
                    "Content-Type": "application/json",
                    "Session": sessionId
                },
                data: {
                    content: params.content,
                    subject: params.subject,
                    sent: params.sent,
                    mailSend: params.mailSend,
                    smtp: params.smtp,
                    attachReport: params.attachReport,
                    attachOPList: params.attachOPList,
                    attachOPopenList: params.attachOPopenList,
                    userId: params.userId,
                },
                url: getBasePath() + '/record/' + recordId + '/record_email?filter='
            });
        };

        requestManager.routes.record.createRecordEmailRecipientOfRecordEmail = function(sessionId, recordEmailId, params) {
            var canceler = $q.defer();
            requestManager.pendingRequests.push(canceler);

            return $http({
                method: 'POST',
                timeout: canceler.promise,
                headers: {
                    "Content-Type": "application/json",
                    "Session": sessionId
                },
                data: {
                    type: params.type,
                    mail: params.mail

                },
                url: getBasePath() + '/record_email/' + recordEmailId + '/record_email_recipient?filter='
            });
        };

        requestManager.routes.record.createRecordEmailAttachmentOfRecordEmail = function(sessionId, recordEmailId, attachment) {

            return upload({
                url: getBasePath() + "/record_email/" + recordEmailId + "/record_email_attachment",
                headers: {
                    "Content-Type": "application/json",
                    "Session": sessionId
                },
                data: {
                    file: attachment
                }
            });
        };

        requestManager.routes.record.createRecordInkassoOfRecord = function(sessionId, recordId, params){
            var canceler = $q.defer();
            requestManager.pendingRequests.push(canceler);

            return $http({
                method: 'POST',
                timeout: canceler.promise,
                headers: {
                    "Content-Type": "application/json",
                    "Session": sessionId
                },
                data: {
                    description: params.description,
                },
                url: getBasePath() + '/record/' + recordId + '/record_inkasso'
            });
        }

        requestManager.routes.record.getRecordInkassoOfRecord = function(sessionId, recordId){
            var canceler = $q.defer();
            requestManager.pendingRequests.push(canceler);

            return $http({
                method: 'GET',
                timeout: canceler.promise,
                headers: {
                    "Content-Type": "application/json",
                    "Session": sessionId
                },
                url: getBasePath() + '/record/' + recordId + '/record_inkasso'
            });
        }


        /*
         * Customer Statistics routes
         * */

        requestManager.routes.customerStatistics.getCustomerStatistics = function(sessionId, customerId) {
            var canceler = $q.defer();
            requestManager.pendingRequests.push(canceler);

            return $http({
                method: 'GET',
                timeout: canceler.promise,
                headers: {
                    "Content-Type": "application/json",
                    "Session": sessionId
                },
                url: getBasePath() + '/customer/' + customerId + '/statistics/historical'
            });
        };

        /*
         * Notification routes
         * */

        requestManager.routes.notifications.getNotificationsOfUser = function(sessionId, userId, filter) {
            var canceler = $q.defer();
            requestManager.pendingRequests.push(canceler);

            var url = angular.isUndefined(filter) ? '/notification' : '/notification?filter=' + JSON.stringify(filter);

            return $http({
                method: 'GET',
                timeout: canceler.promise,
                headers: {
                    "Content-Type": "application/json",
                    "Session": sessionId
                },
                url: getBasePath() + '/user/' + userId + url
            });
        };

        /*
         * System routes
         * */

        requestManager.routes.system.getSystemStatistics = function(sessionId) {
            var canceler = $q.defer();
            requestManager.pendingRequests.push(canceler);

            return $http({
                method: 'GET',
                timeout: canceler.promise,
                headers: {
                    "Content-Type": "application/json",
                    "Session": sessionId
                },
                url: getBasePath() + '/system/statistics/actual'
            });
        };

        requestManager.routes.system.getHistoricalSystemStatistics = function(sessionId) {
            var canceler = $q.defer();
            requestManager.pendingRequests.push(canceler);

            return $http({
                method: 'GET',
                timeout: canceler.promise,
                headers: {
                    "Content-Type": "application/json",
                    "Session": sessionId
                },
                url: getBasePath() + '/system/statistics/historical' // /customer/0/historical_statistics
            });
        };


        /*
         * Email templates routes
         * */

        requestManager.routes.emailTemplates.getEmailTemplates = function(sessionId) {
            var canceler = $q.defer();
            requestManager.pendingRequests.push(canceler);

            return $http({
                method: 'GET',
                timeout: canceler.promise,
                headers: {
                    "Content-Type": "application/json",
                    "Session": sessionId
                },
                url: getBasePath() + '/email_template'
            });
        };

        requestManager.routes.emailTemplates.editEmailTemplateWithId = function(sessionId, params) {
            var canceler = $q.defer();
            requestManager.pendingRequests.push(canceler);

            return $http({
                method: 'PUT',
                timeout: canceler.promise,
                headers: {
                    "Content-Type": "application/json",
                    "Session": sessionId
                },
                data: {
                    text: params.text,
                    title: params.title,
                    subject: params.subject,
                    language: params.language,
                },
                url: getBasePath() + '/email_template/' + params.id
            });
        };

        requestManager.routes.emailTemplates.createEmailTemplate = function(sessionId, params) {
            var canceler = $q.defer();
            requestManager.pendingRequests.push(canceler);

            return $http({
                method: 'POST',
                timeout: canceler.promise,
                headers: {
                    "Content-Type": "application/json",
                    "Session": sessionId
                },
                data: {
                    text: params.text,
                    title: params.title,
                    subject: params.subject,
                    language: params.language,
                },
                url: getBasePath() + '/email_template'
            });
        };

        requestManager.routes.emailTemplates.deleteEmailTemplate = function(sessionId, templateId) {
            var canceler = $q.defer();
            requestManager.pendingRequests.push(canceler);

            return $http({
                method: 'DELETE',
                timeout: canceler.promise,
                headers: {
                    "Content-Type": "application/json",
                    "Session": sessionId
                },
                url: getBasePath() + '/email_template/' + templateId
            });
        };

        requestManager.routes.emailTemplates.virtual.replaceEmailTemplate = function(sessionId, userId, customerId, templateId) {
            var canceler = $q.defer();
            requestManager.pendingRequests.push(canceler);

            return $http({
                method: 'POST',
                timeout: canceler.promise,
                headers: {
                    "Content-Type": "application/json",
                    "Session": sessionId
                },
                data: {
                    userId: userId,
                    customerId: customerId,
                    templateId: templateId
                },
                url: getBasePath() + '/email_template_replace'
            });
        };

        /*
         * Record email routes
         * */

        requestManager.routes.recordEmail.getRecordEmailRecipientsOfRecordEmail = function(sessionId, recordEmailId) {
            var canceler = $q.defer();
            requestManager.pendingRequests.push(canceler);

            return $http({
                method: 'GET',
                timeout: canceler.promise,
                headers: {
                    "Content-Type": "application/json",
                    "Session": sessionId
                },
                url: getBasePath() + "/record_email/" + recordEmailId + "/record_email_recipient"
            });
        };

        requestManager.routes.recordEmail.getRecordEmail = function(sessionId, filter){
            var canceler = $q.defer();
            requestManager.pendingRequests.push(canceler);

            var url = angular.isUndefined(filter) ? '/record_email' : '/record_email?filter=' + JSON.stringify(filter);

            return $http({
                method: 'GET',
                timeout: canceler.promise,
                headers: {
                    "Content-Type": "application/json",
                    "Session": sessionId
                },
                url: getBasePath() + url
            });
        };

        requestManager.routes.recordEmail.updateRecordEmail = function(sessionId, params){
            var canceler = $q.defer();
            requestManager.pendingRequests.push(canceler);

            if(params.approved == 0) {
                return $http({
                    method: 'DELETE',
                    timeout: canceler.promise,
                    headers: {
                        "Content-Type": "application/json",
                        "Session": sessionId
                    },
                    data: {
                        approved: params.approved,
                        approvedBy: params.approvedBy
                    },
                    url: getBasePath() + "/record/" + params.id + "/all"
                });
            }else {
                return $http({
                    method: 'PUT',
                    timeout: canceler.promise,
                    headers: {
                        "Content-Type": "application/json",
                        "Session": sessionId
                    },
                    data: {
                        approved: params.approved,
                        approvedBy: params.approvedBy
                    },
                    url: getBasePath() + "/record_email/" + params.id
                });
            }
        }

        requestManager.routes.recordEmail.updateRecordPhysicalMail = function(sessionId, params){
            var canceler = $q.defer();
            requestManager.pendingRequests.push(canceler);

            if(params.approved == 0) {
                return $http({
                    method: 'DELETE',
                    timeout: canceler.promise,
                    headers: {
                        "Content-Type": "application/json",
                        "Session": sessionId
                    },
                    data: {
                        approved: params.approved,
                        approvedBy: params.approvedBy
                    },
                    url: getBasePath() + "/record/" + params.id + "/all"
                });
            }else {
                return $http({
                    method: 'PUT',
                    timeout: canceler.promise,
                    headers: {
                        "Content-Type": "application/json",
                        "Session": sessionId
                    },
                    data: {
                        approved: params.approved,
                        approvedBy: params.approvedBy
                    },
                    url: getBasePath() + "/record_physical_mail/" + params.id
                });
            }
        }

        requestManager.routes.recordEmail.virtual.getEmails = function(sessionId) {
            var canceler = $q.defer();
            requestManager.pendingRequests.push(canceler);

            return $http({
                method: 'GET',
                timeout: canceler.promise,
                headers: {
                    "Content-Type": "application/json",
                    "Session": sessionId
                },
                url: getBasePath() + "/emails"
            });
        };

        requestManager.routes.recordEmail.virtual.getUnapprovedEmails = function(sessionId) {
            var canceler = $q.defer();
            requestManager.pendingRequests.push(canceler);

            return $http({
                method: 'GET',
                timeout: canceler.promise,
                headers: {
                    "Content-Type": "application/json",
                    "Session": sessionId
                },
                url: getBasePath() + "/emails/unapproved/multiplerecipients"
            });
        };

        requestManager.routes.recordEmail.virtual.deleteEmailRecipient = function(sessionId, recipientId) {
            var canceler = $q.defer();
            requestManager.pendingRequests.push(canceler);

            return $http({
                method: 'DELETE',
                timeout: canceler.promise,
                headers: {
                    "Content-Type": "application/json",
                    "Session": sessionId
                },
                url: getBasePath() + "/record_email_recipient/" + recipientId
            });
        };

        /*
         * Report Routes
         * */

        requestManager.routes.record.getDemandNoteOfCustomer = function(sessionId, customerId) {
            var canceler = $q.defer();
            requestManager.pendingRequests.push(canceler);

            return $http({
                method: 'GET',
                timeout: canceler.promise,
                responseType: 'blob',
                headers: {
                    "Content-Type": "application/json",
                    "Session": sessionId
                },
                url: getBasePath() + "/report/customer/" + customerId + "/demandNote"
            });
        };

        requestManager.routes.record.getCustomerReport = function(sessionId, customerId) {
            var canceler = $q.defer();
            requestManager.pendingRequests.push(canceler);

            return $http({
                method: 'GET',
                timeout: canceler.promise,
                responseType: 'blob',
                headers: {
                    "Content-Type": "application/json",
                    "Session": sessionId
                },
                url: getBasePath() + "/report/accountStatement/" + customerId
            });
        };

        requestManager.routes.record.getCustomerReport2 = function(sessionId, customerId) {
            var canceler = $q.defer();
            requestManager.pendingRequests.push(canceler);

            return $http({
                method: 'GET',
                timeout: canceler.promise,
                responseType: 'blob',
                headers: {
                    "Content-Type": "application/json",
                    "Session": sessionId
                },
                url: getBasePath() + "/report/accountStatementOpen/" + customerId
            });
        };

        requestManager.routes.record.getCustomerReport3 = function(sessionId, customerId) {
            var canceler = $q.defer();
            requestManager.pendingRequests.push(canceler);

            return $http({
                method: 'GET',
                timeout: canceler.promise,
                responseType: 'blob',
                headers: {
                    "Content-Type": "application/json",
                    "Session": sessionId
                },
                url: getBasePath() + "/report/accountStatementEVB/" + customerId
            });
        }

        requestManager.routes.report.debtsAge = function(sessionId) {
            var canceler = $q.defer();
            requestManager.pendingRequests.push(canceler);

            return $http({
                method: 'GET',
                timeout: canceler.promise,
                responseType: 'blob',
                headers: {
                    "Content-Type": "application/json",
                    "Session": sessionId
                },
                url: getBasePath() + "/report/debtsAge"
            });
        };

        requestManager.routes.report.debtsAgeV2 = function(sessionId) {
            let canceler = $q.defer();
            requestManager.pendingRequests.push(canceler);

            let branch = 'main';
            let instance = location.host.substring(0, location.host.indexOf(".debitorcloud"));
            if (!instance) {
                // Development
                instance = window.localStorage.getItem("useInstance");
                branch = 'development';
            }

            // TODO remove next line
            branch = 'development';

            let url = `https://${branch}.api-v2.debitorcloud.com/reports/agingAnalysis/pdf`;

            return $http({
                method: 'GET',
                timeout: canceler.promise,
                responseType: 'blob',
                headers: {
                    "Content-Type": "arraybuffer",
                    "Session": sessionId,
                    "X-Instance": `${instance}.debitorcloud.com`
                },
                url: url
            });
        }

        requestManager.routes.system.statisticsV2 = function(sessionId) {
            let canceler = $q.defer();
            requestManager.pendingRequests.push(canceler);

            let branch = 'main';
            let instance = location.host.substring(0, location.host.indexOf(".debitorcloud"));
            if (!instance) {
                // Development
                instance = window.localStorage.getItem("useInstance");
                branch = 'development';
            }

            // TODO remove next line
            branch = 'development';

            let url = `https://${branch}.api-v2.debitorcloud.com/reports/overallAgingAnalysis`;

            return $http({
                method: 'GET',
                timeout: canceler.promise,
                headers: {
                    "Content-Type": "application/json",
                    "Session": sessionId,
                    "X-Instance": `${instance}.debitorcloud.com`
                },
                url: url
            });
        }

        requestManager.routes.task.getNumberOfOpenTasks = function (sessionId) {
            var canceler = $q.defer();
            requestManager.pendingRequests.push(canceler);

            let branch = 'main';
            let instance = location.host.substring(0, location.host.indexOf(".debitorcloud"));
            if (!instance) {
                // Development
                instance = window.localStorage.getItem("useInstance");
                branch = 'development';
            }

            // TODO remove next line
            branch = 'development';

            let url = `https://${branch}.api-v2.debitorcloud.com/tasks/amountOpen`;

            return $http({
                method: 'GET',
                timeout: canceler.promise,
                headers: {
                    "Content-Type": "application/json",
                    "Session": sessionId,
                    "X-Instance": `${instance}.debitorcloud.com`
                },
                url: url
            });
        }

        requestManager.routes.report.paymentTerm = function(sessionId) {
            var canceler = $q.defer();
            requestManager.pendingRequests.push(canceler);

            return $http({
                method: 'GET',
                timeout: canceler.promise,
                responseType: 'blob',
                headers: {
                    "Content-Type": "application/json",
                    "Session": sessionId
                },
                url: getBasePath() + "/report/paymentTerms"
            });
        };

        requestManager.routes.record.getPaymentAgreementOfCustomer = function(sessionId, paymentTermId) {
            var canceler = $q.defer();
            requestManager.pendingRequests.push(canceler);

            return $http({
                method: 'GET',
                timeout: canceler.promise,
                responseType: 'blob',
                headers: {
                    "Content-Type": "application/json",
                    "Session": sessionId
                },
                url: getBasePath() + "/report/paymentTerm/" + paymentTermId
            });
        };

        requestManager.routes.record.getPaymentAgreementOfCustomerV2 = function (sessionId, paymentTermId) {
            var canceler = $q.defer();
            requestManager.pendingRequests.push(canceler);

            let branch = 'main';
            let instance = location.host.substring(0, location.host.indexOf(".debitorcloud"));
            if (!instance) {
                // Development
                instance = window.localStorage.getItem("useInstance");
                branch = 'development';
            }

            // TODO remove next line
            branch = 'development';

            let url = `https://${branch}.api-v2.debitorcloud.com/paymentTerms/${paymentTermId}/pdf`;

            return $http({
                method: 'GET',
                timeout: canceler.promise,
                responseType: 'blob',
                headers: {
                    "Content-Type": "arraybuffer",
                    "Session": sessionId,
                    "X-Instance": `${instance}.debitorcloud.com`
                },
                url: url
            });
        }

        requestManager.routes.record.getLawyerReport = function(sessionId, customerId, params) {
            var canceler = $q.defer();
            requestManager.pendingRequests.push(canceler);

            return $http({
                method: 'POST',
                timeout: canceler.promise,
                responseType: 'blob',
                headers: {
                    "Content-Type": "application/json",
                    "Session": sessionId
                },
                data: params,
                url: getBasePath() + "/report/customer/" + customerId + "/lawyerReport"
            });
        };

        requestManager.routes.record.uploadPdfTemplate = function(sessionId, file) {
            return upload({
                url: getBasePath() + "/report/pdf/upload",
                headers: {
                    "Content-Type": "application/json",
                    "Session": sessionId
                },
                data: {
                    file: file
                }
            });
        };


        /*
         * Settings Routes
         * */

        requestManager.routes.settings.getSettings = function(sessionId) {
            var canceler = $q.defer();
            requestManager.pendingRequests.push(canceler);

            return $http({
                method: 'GET',
                timeout: canceler.promise,
                headers: {
                    "Content-Type": "application/json",
                    "Session": sessionId
                },
                url: getBasePath() + "/settings"
            });
        };

        requestManager.routes.settings.editSettingWithKey = function(sessionId, params) {
            var canceler = $q.defer();
            requestManager.pendingRequests.push(canceler);

            return $http({
                method: 'PUT',
                timeout: canceler.promise,
                headers: {
                    "Content-Type": "application/json",
                    "Session": sessionId
                },
                data: {
                    settingCode: params.settingCode,
                    settingValue1: params.settingValue1,
                    settingValue2: params.settingValue2,
                    settingValue3: params.settingValue3,
                    file: params.file
                },
                url: getBasePath() + "/settings/" + params.id
            });
        };

        requestManager.routes.settings.createSettingWithKey = function(sessionId, params) {
            var canceler = $q.defer();
            requestManager.pendingRequests.push(canceler);

            return $http({
                method: 'POST',
                timeout: canceler.promise,
                headers: {
                    "Content-Type": "application/json",
                    "Session": sessionId
                },
                data: {
                    settingCode: params.settingCode,
                    settingValue1: params.settingValue1,
                    settingValue2: params.settingValue2,
                    settingValue3: params.settingValue3
                },
                url: getBasePath() + "/settings"
            });
        };

        requestManager.routes.settings.deleteSetting = function(sessionId, settingId) {
            var canceler = $q.defer();
            requestManager.pendingRequests.push(canceler);

            return $http({
                method: 'DELETE',
                timeout: canceler.promise,
                headers: {
                    "Content-Type": "application/json",
                    "Session": sessionId
                },
                url: getBasePath() + "/settings/" + settingId
            });
        };

        requestManager.routes.settings.generateTemplates = function(sessionId){
            var canceler = $q.defer();
            requestManager.pendingRequests.push(canceler);

            return $http({
                method: 'POST',
                timeout: canceler.promise,
                headers: {
                    "Content-Type": "application/json",
                    "Session": sessionId
                },
                url: getBasePath() + "/settings/generate_templates"
            });
        };

        /*
         * User-settings Routes
         * */

        //insert create user setting route here

        requestManager.routes.userSettings.editUserSetting = function(sessionId, settingId, params) {
            var canceler = $q.defer();
            requestManager.pendingRequests.push(canceler);

            return $http({
                method: 'PUT',
                timeout: canceler.promise,
                headers: {
                    "Content-Type": "application/json",
                    "Session": sessionId
                },
                data: {
                    value1: params.value1,
                    value2: params.value2,
                    value3: params.value3
                },
                url: getBasePath() + "/user_settings/" + settingId
            });
        };

        requestManager.routes.user.get = function(sessionId, settingId, params) {
            var canceler = $q.defer();
            requestManager.pendingRequests.push(canceler);

            return $http({
                method: 'PUT',
                timeout: canceler.promise,
                headers: {
                    "Content-Type": "application/json",
                    "Session": sessionId
                },
                data: {
                    value1: params.value1,
                    value2: params.value2,
                    value3: params.value3
                },
                url: getBasePath() + "/user_settings/" + settingId
            });
        };

        /*
         * Task Routes
         * */

        requestManager.routes.task.getAllTasks = function(sessionId) {
            var canceler = $q.defer();
            requestManager.pendingRequests.push(canceler);

            return $http({
                method: 'GET',
                timeout: canceler.promise,
                headers: {
                    "Content-Type": "application/json",
                    "Session": sessionId
                },
                url: getBasePath() + "/task"
            });
        };

        requestManager.routes.task.getNumberOfOpenTasks = function (sessionId) {
            var canceler = $q.defer();
            requestManager.pendingRequests.push(canceler);

            let branch = 'main';
            let instance = location.host.substring(0, location.host.indexOf(".debitorcloud"));
            if (!instance) {
                // Development
                instance = window.localStorage.getItem("useInstance");
                branch = 'development';
            }

            // TODO remove next line
            branch = 'development';

            let url = `https://${branch}.api-v2.debitorcloud.com/tasks/amountOpen`;

            return $http({
                method: 'GET',
                timeout: canceler.promise,
                headers: {
                    "Content-Type": "application/json",
                    "Session": sessionId,
                    "X-Instance": `${instance}.debitorcloud.com`
                },
                url: url
            });
        }

        requestManager.routes.task.getTasks = function(sessionId, filters) {
            var canceler = $q.defer();
            requestManager.pendingRequests.push(canceler);

            const params = filterParams(filters);

            let branch = 'main';
            let instance = location.host.substring(0, location.host.indexOf(".debitorcloud"));
            if (!instance) {
                // Development
                instance = window.localStorage.getItem("useInstance");
                branch = 'development';
            }

            // TODO remove next line
            branch = 'development';

            let url = `https://${branch}.api-v2.debitorcloud.com/tasks`;

            return $http({
                method: 'GET',
                timeout: canceler.promise,
                headers: {
                    "Content-Type": "application/json",
                    "Session": sessionId,
                    "X-Instance": `${instance}.debitorcloud.com`
                },
                params,
                url,
            });
        };

        requestManager.routes.task.editTaskWithId = function(sessionId, taskId, params) {
            var canceler = $q.defer();
            requestManager.pendingRequests.push(canceler);

            return $http({
                method: 'PUT',
                timeout: canceler.promise,
                headers: {
                    "Content-Type": "application/json",
                    "Session": sessionId
                },
                data: {
                    name: params.name,
                    description: params.description,
                    done: params.done,
                    type: params.type
                },
                url: getBasePath() + "/task/" + taskId
            });
        };

        requestManager.routes.task.deleteTaskWithId = function(sessionId, taskId) {
            var canceler = $q.defer();
            requestManager.pendingRequests.push(canceler);

            return $http({
                method: 'DELETE',
                timeout: canceler.promise,
                headers: {
                    "Content-Type": "application/json",
                    "Session": sessionId
                },
                url: getBasePath() + "/task/" + taskId
            });
        };

        requestManager.routes.task.createTask = function(sessionId, params) {
            var canceler = $q.defer();
            requestManager.pendingRequests.push(canceler);

            return $http({
                method: 'POST',
                timeout: canceler.promise,
                headers: {
                    "Content-Type": "application/json",
                    "Session": sessionId
                },
                data: {
                    name: params.name,
                    description: params.description,
                    done: params.done,
                    type: params.type
                },
                url: getBasePath() + "/task"
            });
        };

        requestManager.routes.task.createTaskV2 = function(sessionId, body) {
            var canceler = $q.defer();
            requestManager.pendingRequests.push(canceler);

            let branch = 'main';
            let instance = location.host.substring(0, location.host.indexOf(".debitorcloud"));
            if (!instance) {
                // Development
                instance = window.localStorage.getItem("useInstance");
                branch = 'development';
            }

            // TODO remove next line
            branch = 'development';

            let url = `https://${branch}.api-v2.debitorcloud.com/tasks`;

            return $http({
                method: 'POST',
                timeout: canceler.promise,
                headers: {
                    "Content-Type": "application/json",
                    "Session": sessionId,
                    "X-Instance": `${instance}.debitorcloud.com`
                },
                data: body,
                url: url
            });
        };

        requestManager.routes.task.addCustomerToTask = function(sessionId, customerId, taskId) {
            var canceler = $q.defer();
            requestManager.pendingRequests.push(canceler);

            return $http({
                method: 'POST',
                timeout: canceler.promise,
                headers: {
                    "Content-Type": "application/json",
                    "Session": sessionId
                },
                data: {
                    id: customerId
                },
                url: getBasePath() + "/task/" + taskId + "/customer"
            });
        };

        requestManager.routes.task.getCustomerAssignedToTask = function(sessionId, taskId) {
            var canceler = $q.defer();
            requestManager.pendingRequests.push(canceler);

            return $http({
                method: 'GET',
                timeout: canceler.promise,
                headers: {
                    "Content-Type": "application/json",
                    "Session": sessionId
                },

                url: getBasePath() + "/task/" + taskId + "/customer"
            });
        };

        requestManager.routes.task.virtual.getTaskListExtended = function(sessionId) {
            var canceler = $q.defer();
            requestManager.pendingRequests.push(canceler);

            return $http({
                method: 'GET',
                timeout: canceler.promise,
                headers: {
                    "Content-Type": "application/json",
                    "Session": sessionId
                },

                url: getBasePath() + "/task_list_extended"
            });
        };

        /*
         * Workflow Routes
         * */
        requestManager.routes.workflow.createWorkflow = function(sessionId, params) {
            var canceler = $q.defer();
            requestManager.pendingRequests.push(canceler);

            return $http({
                method: 'POST',
                timeout: canceler.promise,
                headers: {
                    "Content-Type": "application/json",
                    "Session": sessionId
                },
                data: {
                    name: params.name,
                    action: params.action,
                    intervalAmount: params.intervalAmount,
                    intervalDay: params.intervalDay,
                    intervalHour: params.intervalHour,
                    disabled: params.disabled,
                    value1: params.value1,
                    value2: params.value2,
                    value3: params.value3,
                    priority: params.priority,
                    noPermissionNeededForMailOrPhysicalMail: params.noPermissionRequired ? params.noPermissionRequired : 0,
                    lastExecuted: params.lastExecuted
                },
                url: getBasePath() + "/workflow"
            });
        };

        requestManager.routes.workflow.getAllWorkflows = function(sessionId) {
            var canceler = $q.defer();
            requestManager.pendingRequests.push(canceler);

            return $http({
                method: 'GET',
                timeout: canceler.promise,
                headers: {
                    "Content-Type": "application/json",
                    "Session": sessionId
                },

                url: getBasePath() + "/workflow"
            });
        };

        requestManager.routes.workflow.createWorkflowFilterOfWorkflow = function(sessionId, workflowId, params) {
            var canceler = $q.defer();
            requestManager.pendingRequests.push(canceler);
            if (params.value1 && params.value1 === 'enthält') {
                params.value1 = 'contains';
            }

            return $http({
                method: 'POST',
                timeout: canceler.promise,
                headers: {
                    "Content-Type": "application/json",
                    "Session": sessionId
                },
                data: {
                    keyName: params.keyName,
                    value1: params.value1,
                    value2: params.value2
                },
                url: getBasePath() + "/workflow/" + workflowId + "/workflow_filter"
            });
        };

        requestManager.routes.workflow.deleteWorkflowFilterOfWorkflow = function(sessionId, params) {
            var canceler = $q.defer();
            requestManager.pendingRequests.push(canceler);

            return $http({
                method: 'DELETE',
                timeout: canceler.promise,
                headers: {
                    "Content-Type": "application/json",
                    "Session": sessionId
                },
                url: getBasePath() + "/workflow/" + params.workflowId + "/workflow_filter/" + params.workflowFilterId
            });
        };

        requestManager.routes.workflow.getWorkflowFilter = function(sessionId, workflowId) {
            var canceler = $q.defer();
            requestManager.pendingRequests.push(canceler);

            return $http({
                method: 'GET',
                timeout: canceler.promise,
                headers: {
                    "Content-Type": "application/json",
                    "Session": sessionId
                },
                url: getBasePath() + "/workflow/" + workflowId + "/workflow_filter"
            });
        };

        requestManager.routes.workflow.deleteWorkflow = function(sessionId, workflowId) {
            var canceler = $q.defer();
            requestManager.pendingRequests.push(canceler);

            return $http({
                method: 'DELETE',
                timeout: canceler.promise,
                headers: {
                    "Content-Type": "application/json",
                    "Session": sessionId
                },
                url: getBasePath() + "/workflow/" + workflowId,
            });
        };

        requestManager.routes.workflow.editWorkflow = function(sessionId, params) {
            var canceler = $q.defer();
            requestManager.pendingRequests.push(canceler);

            return $http({
                method: 'PUT',
                timeout: canceler.promise,
                headers: {
                    "Content-Type": "application/json",
                    "Session": sessionId
                },
                data: {
                    action: params.action,
                    intervalAmount: params.intervalAmount,
                    intervalDay: params.intervalDay,
                    intervalHour: params.intervalHour,
                    name: params.name,
                    priority: params.priority,
                    value1: params.value1,
                    value2: params.value2,
                    value3: params.value3,
                    executeNow: params.executeNow,
                    lastExecuted: params.lastExecuted,
                    noPermissionNeededForMailOrPhysicalMail: params.noPermissionNeededForMailOrPhysicalMail ? params.noPermissionNeededForMailOrPhysicalMail : 0,
                },
                url: getBasePath() + "/workflow/" + params.id,
            });
        };

        requestManager.routes.workflow.disableOrEnableWorkflow = function(sessionId, params) {
            var canceler = $q.defer();
            requestManager.pendingRequests.push(canceler);

            return $http({
                method: 'PUT',
                timeout: canceler.promise,
                headers: {
                    "Content-Type": "application/json",
                    "Session": sessionId
                },
                data: {
                    disabled: params.disabled
                },
                url: getBasePath() + "/workflow/" + params.id,
            });
        };

        /*
         * Record email attachment Routes
         * */

        requestManager.routes.recordEmailAttachment.createRecordEmailAttachment = function(sessionId, attachment) {
            return upload({
                url: getBasePath() + "/record_email_attachment",
                headers: {
                    "Content-Type": "application/json",
                    "Session": sessionId
                },
                data: {
                    file: attachment
                }
            })
        };

        /*
         * Credit reform Routes
         * */

        requestManager.routes.creditReformRegistration.register = function(sessionId) {
            var canceler = $q.defer();
            requestManager.pendingRequests.push(canceler);

            return $http({
                method: 'POST',
                timeout: canceler.promise,
                headers: {
                    "Content-Type": "application/json",
                    "Session": sessionId
                },
                url: getBasePath() + "/modules/creditreform/register"
            });
        };


        /*
         * Language Routes
         * */

        requestManager.routes.language.getTranslations = function(sessionId) {
            var canceler = $q.defer();
            requestManager.pendingRequests.push(canceler);

            return $http({
                method: 'GET',
                timeout: canceler.promise,
                headers: {
                    "Content-Type": "application/json",
                    "Session": sessionId
                },
                url: getBasePath() + "/language"
            });
        };

        requestManager.routes.language.editTranslations = function(sessionId, params) {
            var canceler = $q.defer();
            requestManager.pendingRequests.push(canceler);

            return $http({
                method: 'PUT',
                timeout: canceler.promise,
                headers: {
                    "Content-Type": "application/json",
                    "Session": sessionId
                },
                data: {
                    blockCode: params.blockCode,
                    language: params.language,
                    translation: params.translation,
                },
                url: getBasePath() + "/language/" + params.id
            });
        };

        /*
         * Email Reply Routes
         * */

        requestManager.routes.recordEmailReply.getAllReplies = function(sessionId) {
            var canceler = $q.defer();
            requestManager.pendingRequests.push(canceler);

            return $http({
                method: 'GET',
                timeout: canceler.promise,
                headers: {
                    "Content-Type": "application/json",
                    "Session": sessionId
                },
                url: getBasePath() + "/record_email_reply"
            });
        };

        requestManager.routes.recordEmailReply.getReplyWithId = function(sessionId, replyId) {
            var canceler = $q.defer();
            requestManager.pendingRequests.push(canceler);

            return $http({
                method: 'GET',
                timeout: canceler.promise,
                headers: {
                    "Content-Type": "application/json",
                    "Session": sessionId
                },
                url: getBasePath() + "/record_email_reply/" + replyId
            });
        };

        requestManager.routes.recordEmailReply.getAttachmentsWithId = function(sessionId, replyId) {
            var canceler = $q.defer();
            requestManager.pendingRequests.push(canceler);

            return $http({
                method: 'GET',
                timeout: canceler.promise,
                headers: {
                    "Content-Type": "application/json",
                    "Session": sessionId
                },
                url: getBasePath() + "/record_email_reply/" + replyId + '/attachments'
            });
        };

        requestManager.routes.recordPhysicalMail.virtual.sendPhysicalMail = function(sessionId, customerId, secured, addsToDunningLevel) {
            var canceler = $q.defer();
            requestManager.pendingRequests.push(canceler);

            let data = {
                einschreiben: secured,
            };
            if (addsToDunningLevel !== null) {
                data['addsToDunningLevel'] = addsToDunningLevel
            }

            return $http({
                method: 'POST',
                timeout: canceler.promise,
                headers: {
                    "Content-Type": "application/json",
                    "Session": sessionId
                },
                data,
                url: getBasePath() + "/customer/" + customerId + "/sendPhysicalMail"
            });
        }

        requestManager.routes.recordPhysicalMail.virtual.calculateSendingCosts = function(sessionId, customerId, country) {
            var canceler = $q.defer();
            requestManager.pendingRequests.push(canceler);

            var url =  getBasePath() + "/customer/" + customerId + "/calculateSendingCosts";
            if(country == 'DE') {
                url =   getBasePath() + "/customer/" + customerId + "/calculateSendingCostsDEPost";
            }
            return $http({
                method: 'GET',
                timeout: canceler.promise,
                headers: {
                    "Content-Type": "application/json",
                    "Session": sessionId
                },
                url: url
            });
        }

        requestManager.routes.recordPhysicalMail.updatePhysicalMail = function(sessionId, params) {
            var canceler = $q.defer();
            requestManager.pendingRequests.push(canceler);

            return $http({
                method: 'PUT',
                timeout: canceler.promise,
                headers: {
                    "Content-Type": "application/json",
                    "Session": sessionId
                },
                data: {
                    einschreiben: params.einschreiben,
                    approved: params.approved,
                    approvedBy: params.approvedBy
                },
                url: getBasePath() + "/record_physical_mail/" + params.id
            });
        }

        requestManager.routes.recordPhysicalMail.virtual.getUnapprovedPhysicalMail = function(sessionId) {
            var canceler = $q.defer();
            requestManager.pendingRequests.push(canceler);

            return $http({
                method: 'GET',
                timeout: canceler.promise,
                headers: {
                    "Content-Type": "application/json",
                    "Session": sessionId
                },
                url: getBasePath() + "/physical_mail/unapproved"
            });
        };

        requestManager.routes.record.getEmailAttachementAsPDF = function(sessionId, attachementId) {
            var canceler = $q.defer();
            requestManager.pendingRequests.push(canceler);

            return $http({
                method: 'GET',
                timeout: canceler.promise,
                responseType: 'blob',
                headers: {
                    "Content-Type": "application/json",
                    "Session": sessionId
                },
                url: getBasePath() + "/record_email_attachment/" + attachementId
            });
        };

        requestManager.routes.record.getSepaOfCustomer = function(sessionId, customerId) {
            var canceler = $q.defer();
            requestManager.pendingRequests.push(canceler);

            return $http({
                method: 'GET',
                timeout: canceler.promise,
                responseType: 'blob',
                headers: {
                    "Content-Type": "application/json",
                    "Session": sessionId
                },
                url: getBasePath() + "/report/customer/" + customerId + "/sepaMandatBusiness"
            });
        };

        requestManager.routes.recordPhysicalMail.getPhysicalMailPdf = function(sessionId, recordPhysicalMailId) {
            var canceler = $q.defer();
            requestManager.pendingRequests.push(canceler);

            return $http({
                method: 'GET',
                timeout: canceler.promise,
                responseType: 'blob',
                headers: {
                    "Content-Type": "application/json",
                    "Session": sessionId,
                },
                url: getBasePath() + "/record_physical_mail/" + recordPhysicalMailId + "/pdf"
            });
        };

        requestManager.routes.reminder.deleteReminderWithId = function(sessionId, reminderId) {
            var canceler = $q.defer();
            requestManager.pendingRequests.push(canceler);

            return $http({
                method: 'DELETE',
                timeout: canceler.promise,
                headers: {
                    "Content-Type": "application/json",
                    "Session": sessionId
                },
                url: getBasePath() + "/notification/" + reminderId
            });
        }

        requestManager.routes.reminder.editReminderWithId = function(sessionId, params) {
            var canceler = $q.defer();
            requestManager.pendingRequests.push(canceler);

            return $http({
                method: 'PUT',
                timeout: canceler.promise,
                headers: {
                    "Content-Type": "application/json",
                    "Session": sessionId
                },
                data: {
                    userDidEntry: params.userDidEntry,
                    customerForReminder: params.customerForReminder,
                    dateReminder: params.dateReminder
                },
                url: getBasePath() + "/notification/" + params.id
            });
        }


        requestManager.routes.workflowFilter.editWorkflowFilter = function(sessionId, params) {
            var canceler = $q.defer();
            requestManager.pendingRequests.push(canceler);
            if (params.value1 && params.value1 === 'enthält') {
                params.value1 = 'contains';
            }

            return $http({
                method: 'PUT',
                timeout: canceler.promise,
                headers: {
                    "Content-Type": "application/json",
                    "Session": sessionId
                },
                data: {
                    keyName: params.keyName,
                    value1: params.value1,
                    value2: params.value2,
                    value3: params.value3
                },
                url: getBasePath() + "/workflow_filter/" + params.id
            });
        };


        requestManager.routes.sync.getStatus= function(sessionId) {
            var canceler = $q.defer();
            requestManager.pendingRequests.push(canceler);

            return $http({
                method: 'GET',
                timeout: canceler.promise,
                headers: {
                    "Content-Type": "application/json",
                    "Session": sessionId
                },
                url: getBasePath() + "/sync/status"
            });
        };

        requestManager.routes.sync.syncNow= function(sessionId) {
            var canceler = $q.defer();
            requestManager.pendingRequests.push(canceler);

            return $http({
                method: 'GET',
                timeout: canceler.promise,
                headers: {
                    "Content-Type": "application/json",
                    "Session": sessionId
                },
                url: getBasePath() + "/sync/list"
            });
        };

        requestManager.routes.record.deleteWholeRecord = function(sessionId, recordId){
            var canceler = $q.defer();
            requestManager.pendingRequests.push(canceler);

            return $http({
                method: 'DELETE',
                timeout: canceler.promise,
                headers: {
                    "Content-Type": "application/json",
                    "Session": sessionId
                },
                url: getBasePath() + "/record/" + recordId + "/all"
            });
        };

        requestManager.routes.record.deleteCommentRecord = function(sessionId, recordId){
            var canceler = $q.defer();
            requestManager.pendingRequests.push(canceler);

            return $http({
                method: 'DELETE',
                timeout: canceler.promise,
                headers: {
                    "Content-Type": "application/json",
                    "Session": sessionId
                },
                url: getBasePath() + "/record_comment/" + recordId
            });
        };

        requestManager.routes.recordEmail.getRecordEmailAttachmentsOfRecordEmail = function(sessionId, recordEmailId){
            var canceler = $q.defer();
            requestManager.pendingRequests.push(canceler);

            return $http({
                method: 'GET',
                timeout: canceler.promise,
                headers: {
                    "Content-Type": "application/json",
                    "Session": sessionId
                },
                url: getBasePath() + "/record_email/" + recordEmailId + "/record_email_attachment"
            });
        };

        /**
         * File upload/download related routes
         */

        requestManager.routes.fileupload.uploadFile = function(sessionId, customerId, params) {
            var canceler = $q.defer();
            requestManager.pendingRequests.push(canceler);
            let fd = new FormData();
            fd.append('file', params.file);
            let desc = {
                description: params.description
            };
            fd.append('description', params.description);

            return $http({
                method: 'POST',
                timeout: canceler.promise,
                headers: {
                    "Content-Type": undefined,
                    "Session": sessionId
                },
                transformRequest: angular.identity,
                data: fd,
                url: getBasePath() + "/customer/" + customerId + "/files"
            });
        };

        requestManager.routes.fileupload.getAllUploadedFiles = function(sessionId, customerId) {
            var canceler = $q.defer();
            requestManager.pendingRequests.push(canceler);

            return $http({
                method: 'GET',
                timeout: canceler.promise,
                headers: {
                    "Content-Type": "application/json",
                    "Session": sessionId
                },
                url: getBasePath() + "/customer/" + customerId + "/files"
            });
        };

        requestManager.routes.fileupload.downloadSelectedFile = function(sessionId, fileId) {
            var canceler = $q.defer();
            requestManager.pendingRequests.push(canceler);

            return $http({
                method: 'GET',
                timeout: canceler.promise,
                headers: {
                    "Content-Type": "application/json",
                    "Session": sessionId
                },
                url: getBasePath() + "/customer/files/" + fileId
            });
        };

        requestManager.routes.fileupload.deleteSelectedFile = function(sessionId, fileId) {
            var canceler = $q.defer();
            requestManager.pendingRequests.push(canceler);

            return $http({
                method: 'DELETE',
                timeout: canceler.promise,
                headers: {
                    "Content-Type": "application/json",
                    "Session": sessionId
                },
                url: getBasePath() + "/customer/files/" + fileId
            });
        };

        requestManager.routes.emailCenter.incoming.getIncomingEmails= function(sessionId) {
            var canceler = $q.defer();
            requestManager.pendingRequests.push(canceler);


            return $http({
                method: 'GET',
                timeout: canceler.promise,
                headers: {
                    "Content-Type": "application/json",
                    "Session": sessionId
                },
                url: getBasePath() + '/record_email_reply'
            });
        };

        requestManager.routes.emailCenter.outgoing.getOutgoingEmails= function(sessionId) {
            var canceler = $q.defer();
            requestManager.pendingRequests.push(canceler);


            return $http({
                method: 'GET',
                timeout: canceler.promise,
                headers: {
                    "Content-Type": "application/json",
                    "Session": sessionId
                },
                url: getBasePath() + '/emails'
            });
        };

        requestManager.routes.supportTicket.getAllSupportTickets = function(sessionId) {
            var canceler = $q.defer();
            requestManager.pendingRequests.push(canceler);


            return $http({
                method: 'GET',
                timeout: canceler.promise,
                headers: {
                    "Content-Type": "application/json",
                    "Session": sessionId
                },
                url: getBasePath() + '/support_tickets'
            });
        };

        requestManager.routes.supportTicket.getOneSupportTicketInDetail = function(sessionId,ticketId) {
            var canceler = $q.defer();
            requestManager.pendingRequests.push(canceler);


            return $http({
                method: 'GET',
                timeout: canceler.promise,
                headers: {
                    "Content-Type": "application/json",
                    "Session": sessionId
                },
                url: getBasePath() + '/support_tickets/' + ticketId
            });
        };

        requestManager.routes.supportTicket.postOneSupportTicket = function(sessionId, body) {
            var canceler = $q.defer();
            requestManager.pendingRequests.push(canceler);

            return $http({
                method: 'POST',
                timeout: canceler.promise,
                url: getBasePath() + '/support_tickets',
                headers: {
                    "Content-Type": "multipart/form-data",
                    "Session": sessionId
                },
                data: {
                    name: body.name,
                    email: body.email,
                    subject: body.subject,
                    description: body.description
                }
            });
        };

        requestManager.routes.senderAddress.postIndividualSenderAddress = function (sessionId, body) {
            var canceler = $q.defer();
            requestManager.pendingRequests.push(canceler);

            return $http({
                method: 'POST',
                timeout: canceler.promise,
                headers: {
                    "Content-Type": "application/json",
                    "Session": sessionId
                },
                data: {
                    email: body
                },
                url: getBasePath() + "/individual_sender_address"
            });
        }

        requestManager.routes.senderAddress.validateIndividualSenderAddress = function (sessionId) {
            var canceler = $q.defer();
            requestManager.pendingRequests.push(canceler);

            return $http({
                method: 'POST',
                timeout: canceler.promise,
                headers: {
                    "Content-Type": "application/json",
                    "Session": sessionId
                },
                url: getBasePath() + "/individual_sender_address/validate"
            });
        }

        requestManager.routes.senderAddress.getIndividualSenderAddress = function (sessionId) {
            var canceler = $q.defer();
            requestManager.pendingRequests.push(canceler);

            return $http({
                method: 'GET',
                timeout: canceler.promise,
                headers: {
                    "Content-Type": "application/json",
                    "Session": sessionId
                },
                url: getBasePath() + "/individual_sender_address"
            });
        }

        requestManager.routes.senderAddress.checkIndividualSenderAddress = function (sessionId) {
            var canceler = $q.defer();
            requestManager.pendingRequests.push(canceler);

            return $http({
                method: 'POST',
                timeout: canceler.promise,
                headers: {
                    "Content-Type": "application/json",
                    "Session": sessionId
                },
                url: getBasePath() + "/individual_sender_address/check"
            });
        }

        requestManager.routes.senderAddress.deleteIndividualSenderAddress = function (sessionId) {
            var canceler = $q.defer();
            requestManager.pendingRequests.push(canceler);

            return $http({
                method: 'DELETE',
                timeout: canceler.promise,
                headers: {
                    "Content-Type": "application/json",
                    "Session": sessionId
                },
                url: getBasePath() + "/individual_sender_address"
            });
        }

        requestManager.routes.clientManagement.getAvailableClients = function (sessionId) {
            var canceler = $q.defer();
            requestManager.pendingRequests.push(canceler);

            return $http({
                method: 'GET',
                timeout: canceler.promise,
                headers: {
                    "Content-Type": "application/json",
                    "Session": sessionId
                },
                url: getBasePath() + "/module_client_registration/availableClients"
            });
        }

        requestManager.routes.clientManagement.getRegisteredClients = function (sessionId) {
            var canceler = $q.defer();
            requestManager.pendingRequests.push(canceler);

            return $http({
                method: 'GET',
                timeout: canceler.promise,
                headers: {
                    "Content-Type": "application/json",
                    "Session": sessionId
                },
                url: getBasePath() + "/module_client_registration/registeredClients"
            });
        }

        requestManager.routes.clientManagement.addClient = function (sessionId, params) {
            var canceler = $q.defer();
            requestManager.pendingRequests.push(canceler);

            return $http({
                method: 'POST',
                timeout: canceler.promise,
                headers: {
                    "Content-Type": "application/json",
                    "Session": sessionId
                },
                data: params,
                url: getBasePath() + "/module_client_registration/client"
            });
        }

        requestManager.routes.branding.getBranding = function () {
            var canceler = $q.defer();
            requestManager.pendingRequests.push(canceler);

            return $http({
                method: 'GET',
                timeout: canceler.promise,
                url: getBrandingPath() + "/branding"
            });
        }

        requestManager.routes.branding.getBrandingLogo = function () {
            var canceler = $q.defer();
            requestManager.pendingRequests.push(canceler);

            return $http({
                method: 'GET',
                timeout: canceler.promise,
                url: getBrandingPath() + "/branding-logo"
            });
        }

        requestManager.routes.branding.getBrandingWallpaper = function () {
            var canceler = $q.defer();
            requestManager.pendingRequests.push(canceler);

            return $http({
                method: 'GET',
                timeout: canceler.promise,
                url: getBrandingPath() + "/branding-wallpaper"
            });
        }

        requestManager.routes.reset.resetCustomContent = function (sessionId) {
            var canceler = $q.defer();
            requestManager.pendingRequests.push(canceler);

            return $http({
                method: 'POST',
                timeout: canceler.promise,
                headers: {
                    "Content-Type": "application/json",
                    "Session": sessionId
                },
                url: getBasePath() + "/data-reset"
            });
        }

        return requestManager;
    }
]);

angular.module("util", [])
angular.module("util").factory("util", [

    function(){
        var util = {};

        util.divideArrayIntoChunks = function(array, chunkSize){
            var listDivided = [];
            var listIndex = 0;

            angular.forEach(array, function(value, index){
                if(index == (listIndex+1)*chunkSize ){
                    listIndex++;
                }

                if(angular.isUndefined(listDivided[listIndex])){
                    listDivided[listIndex] = [];
                }

                listDivided[listIndex].push(value);
            });

            return listDivided;
        };

        util.getSettingByName = function(settingsArray, name){
            for(var i = 0; i < settingsArray.length; i++){
                if(settingsArray[i] !== null && settingsArray[i].settingCode === name){
                    return settingsArray[i];
                }
            }

            return undefined;
        };

        util.getSettingsWithMatchingRegex = function(settingsArray, regex){
            var matchingValues = [];
            settingsArray.forEach(function(setting){
                if(regex.test(setting.settingCode)){
                  matchingValues.push(setting)
                }
            });
            return matchingValues;
        };

        util.getCustomerDetailsByCustomerId = function(customerList, customerId){
            for(var i = 0; i < customerList.length; i++){
                if(customerList[i].id == customerId){
                    return customerList[i];
                }
            }
        };

        util.createErrorUrl = function(errorSource, userDescription, devDescription, route, component){
            var errorDetails = {
                errorSource: errorSource,
                userDescription: userDescription,
                devDescription: devDescription,
                timestamp: new Date().toString(),
                route: route,
                component: component
            };

            return "/error-page?errorDetails=" + JSON.stringify(errorDetails);
        };

        util.convertFileToBase64 = function(file){
            return new Promise(function(resolve, reject) {
                var reader = new FileReader();
                console.log("file", file);
                reader.onload = function() { resolve(reader.result); };
                reader.onerror = function(error){ reject(error); };
                reader.readAsDataURL(file);
            });
        };

        return util;
    }]
);

angular.module("sharedMemory", []);
angular.module("sharedMemory").factory("sharedMemory", [
  function(){
    var sharedMemory = {};

    sharedMemory.error = {
        errorDetails: undefined,
        isSet: false,
        setError: function(errorSource, userDescription, devDescription, route, component){
            sharedMemory.error.errorDetails = {
                errorSource: errorSource,
                userDescription: userDescription,
                devDescription: devDescription,
                timestamp: new Date().toString(),
                route: route,
                component: component
            };
            sharedMemory.error.isSet = true;
        }
    };


    sharedMemory.user = {
      userDetails: {},
      isSet: false,
      setUserDetails: function(userDetails){
          sharedMemory.user.userDetails = userDetails;
          sharedMemory.user.isSet = true;
      },
      getUserDetails: function(){
        return sharedMemory.user.userDetails;
      },
      resetUser: function(){
          sharedMemory.user.userDetails = [];
          sharedMemory.user.isSet = false;
      }
    };

    sharedMemory.emailTemplates = {
        emailTemplates: [],
        isSet: false,
        setEmailTemplates: function(emailTemplates){
            sharedMemory.emailTemplates.emailTemplates = emailTemplates;
            sharedMemory.emailTemplates.isSet = true;
        },
        getEmailTemplates: function() {
            return sharedMemory.emailTemplates.emailTemplates;
        },
        resetEmailTemplates: function(){
            sharedMemory.emailTemplates.emailTemplates = [];
            sharedMemory.emailTemplates.isSet = false;
        }
    };

      sharedMemory.userSettings = {
          userSettings: [],
          isSet: false,
          setUserSettings: function(userSettings){
              sharedMemory.userSettings.userSettings = userSettings;
              sharedMemory.userSettings.isSet = true;
          },
          getUserSettings: function(){
              return sharedMemory.userSettings.userSettings;
          },
          resetUserSettings: function(){
              sharedMemory.userSettings.userSettings = [];
              sharedMemory.userSettings.isSet = false;
          }
      };

      sharedMemory.reset = function(){
          sharedMemory.user.resetUser();
          sharedMemory.userSettings.resetUserSettings();
          sharedMemory.emailTemplates.resetEmailTemplates();
      };

    return sharedMemory;
  }]
);

angular.module("emailTemplateUtil", [])
angular.module("emailTemplateUtil").factory("emailTemplateUtil", [

    function () {
        const emailTemplateUtil = {};

        emailTemplateUtil.getEmailTemplatesGroupedByTitle = function (allEmailTemplates) {
            const languagesGroupedByEmailTemplateTitle = {};

            allEmailTemplates.forEach(function (emailTemplate) {
                emailTemplate.languageObject = languageCodesV2.find(function (language) {
                    return language.iso639v1 === emailTemplate.language;
                });

                if (!emailTemplate.languageObject && emailTemplate.language === "Default") {
                    emailTemplate.languageObject = {
                        german: "Default"
                    }
                }

                if (!(emailTemplate.title in languagesGroupedByEmailTemplateTitle)) {
                    languagesGroupedByEmailTemplateTitle[emailTemplate.title] = {};
                    languagesGroupedByEmailTemplateTitle[emailTemplate.title].languages = {};
                }

                if (emailTemplate.languageObject)
                    languagesGroupedByEmailTemplateTitle[emailTemplate.title].languages[emailTemplate.language] = emailTemplate;
            });


            return languagesGroupedByEmailTemplateTitle;
        };

        return emailTemplateUtil;
    }]
);
angular.module("workflowUtil", [])
angular.module("workflowUtil").factory("workflowUtil", [
    "$filter",
    function($filter){
        var workflowUtil = {
            dayList: [
                {key: "MO", text: $filter("translate")("workflowUtil.MONDAY")},
                {key: "DI", text: $filter("translate")("workflowUtil.TUESDAY")},
                {key: "MI", text: $filter("translate")("workflowUtil.WEDNESDAY")},
                {key: "DO", text: $filter("translate")("workflowUtil.THURSDAY")},
                {key: "FR", text: $filter("translate")("workflowUtil.FRIDAY")},
                {key: "SA", text: $filter("translate")("workflowUtil.SATURDAY")},
                {key: "SO", text: $filter("translate")("workflowUtil.SUNDAY")}
            ],
            intervalList: [
                {key: "P1D", text: $filter("translate")("workflowUtil.P1D")},
                {key: "P1W", text: $filter("translate")("workflowUtil.P1W")},
                {key: "P2W", text: $filter("translate")("workflowUtil.P2W")},
                {key: "P1M", text: $filter("translate")("workflowUtil.P1M")}
            ],
            workflowTypeList: [
                {
                    key: "TO_PHONE_LIST",
                    text: $filter("translate")("workflowUtil.TO_PHONE_LIST"),
                    needsAdditionalInformation: true,
                    workFlowAvailableInAllCountries: true,
                    workflowAvailableInCountries: undefined,
                },
                {
                    key: "TO_LAWYER_LIST",
                    text: $filter("translate")("workflowUtil.TO_LAWYER_LIST"),
                    needsAdditionalInformation: true,
                    workFlowAvailableInAllCountries: true,
                    workflowAvailableInCountries: undefined,
                },
                {
                    key: "TO_MAIL",
                    text: $filter("translate")("workflowUtil.TO_MAIL"),
                    needsAdditionalInformation: true,
                    workFlowAvailableInAllCountries: true,
                    workflowAvailableInCountries: undefined,
                },
                {
                    key: "TO_PAYMENT_TERM_MAIL",
                    text: $filter("translate")("workflowUtil.TO_PAYMENT_TERM_MAIL"),
                    needsAdditionalInformation: true,
                    workFlowAvailableInAllCountries: true,
                    workflowAvailableInCountries: undefined,
                },
                {
                    key: "TO_PROBLEMATIC",
                    text: $filter("translate")("workflowUtil.TO_PROBLEMATIC"),
                    needsAdditionalInformation: false,
                    workFlowAvailableInAllCountries: true,
                    workflowAvailableInCountries: undefined,
                },
                {
                    key: "TO_CUSTOMERADVISOR",
                    text: $filter("translate")("workflowUtil.TO_CUSTOMERADVISOR"),
                    needsAdditionalInformation: false,
                    workFlowAvailableInAllCountries: true,
                    workflowAvailableInCountries: undefined,
                },
                {
                    key: "TO_PHYSICAL_MAIL",
                    text: $filter("translate")("workflowEditor.TO_PHYSICAL_MAIL"),
                    needsAdditionalInformation: true,
                    workFlowAvailableInAllCountries: false,
                    workflowAvailableInCountries: ["AT", "DE"],
                },
                {
                    key: "TO_EMAIL_OR_PHYSICAL_MAIL",
                    text: $filter("translate")("workflowEditor.TO_EMAIL_OR_PHYSICAL_MAIL"),
                    needsAdditionalInformation: true,
                    workFlowAvailableInAllCountries: false,
                    workflowAvailableInCountries: ["AT", "DE"],
                },
                {
                    key: "TO_EMAIL_AND_PHYSICAL_MAIL",
                    text: $filter("translate")("workflowEditor.TO_EMAIL_AND_PHYSICAL_MAIL"),
                    needsAdditionalInformation: true,
                    workFlowAvailableInAllCountries: true,
                    workflowAvailableInCountries: ["AT", "DE"],
                }
            ],
            workflowFilterTypeList: [
                {key: "isProblematic", text: $filter("translate")("workflowUtil.IS_PROBLEMATIC"), inputType: "boolean"},
                {key: "amountMails", text: $filter("translate")("workflowUtil.AMOUNT_MAILS"), inputType: "text"},
                {key: "amountCalls", text: $filter("translate")("workflowUtil.AMOUNT_CALLS"), inputType: "text"},
                {key: "amountInterest", text: $filter("translate")("workflowUtil.AMOUNT_INTEREST"), inputType: "text"},
                {key: "amountDebts", text: $filter("translate")("workflowUtil.AMOUNT_DEBTS"), inputType: "text"},
                {key: "lastTimeContacted", text: $filter("translate")("workflowUtil.LAST_TIME_CONTACTED"), inputType: "text"},
                {key: "oldestInvoice", text: $filter("translate")("workflowUtil.OLDEST_INVOICE"), inputType: "text"},
                {key: "preferredContactMethod", text: "Bevorzugte Kontaktmethode", inputType: "select"},
                // NEW FILTERS
                {key: "exceedsCreditLimit", text: $filter("translate")("workflowCreator.EXCEEDS_CREDIT_LLMIT"), inputType: "boolean"},
                {key: "amountPendingCallJobs", text: $filter("translate")("workflowCreator.AMOUNT_PENDING_CALLJOBS"), inputType: "text"},
                {key: "paymentTermAppointmentsOverdueInDays", text: $filter("translate")("workflowCreator.PAYMENT_TERM_APPOINTMENTS_OVERDUE_IN_DAYS"), inputType: "text"},
                {key: "amountDaysSinceLastSuccessfullCall", text: $filter("translate")("workflowCreator.AMOUNT_DAYS_SINCE_LAST_SUCCESSFULL_CALL"), inputType: "text"},
                {key: "individualField1", text: $filter("translate")("workflowCreator.INDIVIDUAL_FIELD_X") + 1, inputType: "text"},
                {key: "individualField2", text: $filter("translate")("workflowCreator.INDIVIDUAL_FIELD_X") + 2, inputType: "text"},
                {key: "individualField3", text: $filter("translate")("workflowCreator.INDIVIDUAL_FIELD_X") + 3, inputType: "text"},
                {key: "individualField4", text: $filter("translate")("workflowCreator.INDIVIDUAL_FIELD_X") + 4, inputType: "text"},
                {key: "individualField5", text: $filter("translate")("workflowCreator.INDIVIDUAL_FIELD_X") + 5, inputType: "text"},
                {key: "individualField6", text: $filter("translate")("workflowCreator.INDIVIDUAL_FIELD_X") + 6, inputType: "text"},
                {key: "individualField7", text: $filter("translate")("workflowCreator.INDIVIDUAL_FIELD_X") + 7, inputType: "text"},
                {key: "individualField8", text: $filter("translate")("workflowCreator.INDIVIDUAL_FIELD_X") + 8, inputType: "text"},
                {key: "individualField9", text: $filter("translate")("workflowCreator.INDIVIDUAL_FIELD_X") + 9, inputType: "text"},
                {key: "individualField10", text: $filter("translate")("workflowCreator.INDIVIDUAL_FIELD_X") + 10, inputType: "text"},
            ],
            workflowOperatorList: [
                {operator: "=", enabled: true},
                {operator: "!=", enabled: true},
                {operator: "<", enabled: true},
                {operator: ">", enabled: true},
                {operator: "<=", enabled: true},
                {operator: ">=", enabled: true},
                {operator: "enthält", enabled: true},
            ],
            booleanOptions: [
                {key: "1", text: $filter("translate")("workflowUtil.YES")},
                {key: "0", text: $filter("translate")("workflowUtil.NO")},
            ],
            contactMethodOptions: [
                {key:"MAIL", text:"Post"},
                {key:"EMAIL", text:"E-Mail"},
                {key:"PHONE", text:"Telefon"},
                {key: "NONE", text: "Egal"}
            ],
            hourList: createIntervalHours(0, 23),
        };

        function createIntervalHours(min, max){
            var hourArray = [];

            for(var i = min; i < max+1; i++){
                hourArray.push({key: i, text: i + ":00"});
            }

            return hourArray;
        }

        workflowUtil.sortWorkflowsByPriority = function(workflowList){
            workflowList.sort(function(a, b){
                if (a.priority < b.priority) {
                    return -1;
                }
                if (a.priority > b.priority) {
                    return 1;
                }

                return 0;
            });

            return workflowList;
        }

        return workflowUtil;
    }]
);

angular.module("logOutTimer", []);
angular.module("logOutTimer").factory("logOutTimer", [
    function(){
        var logOutTimer = {};
        logOutTimer.expireDate = undefined;

        logOutTimer.saveDate = function (object){
            logOutTimer.expireDate = object;
        };

        logOutTimer.deleteDate = function (){
            logOutTimer.expireDate = undefined;
        };

        logOutTimer.readDate = function (){
            return logOutTimer.expireDate;
        };
        return logOutTimer;
    }]
);
angular.module("paginationManager", []);
angular.module("paginationManager").factory("paginationManager", [
    function(){
        var paginationManager = {};
        paginationManager.paginationIndex = undefined;

        paginationManager.initiateIndex = function(){
            paginationManager.paginationIndex = 0;
        }

        paginationManager.saveIndex = function (index){
            paginationManager.paginationIndex = index;
        };

        paginationManager.deleteIndex = function (){
            paginationManager.paginationIndex = undefined;
        };

        paginationManager.readIndex = function (){
            return paginationManager.paginationIndex;
        };
        return paginationManager;
    }]
);
angular.module("bookstackApi", []);

angular.module("bookstackApi").factory("bookstackApi", [
    "$http",
    "$q",
    function ($http, $q) {
        var bookstackApi = {};
        bookstackApi.pendingRequests = [];

        bookstackApi.stopPendingRequests = function () {
            bookstackApi.pendingRequests.forEach(function (cancelerPromise) {
                cancelerPromise.resolve();
            });
        };

        bookstackApi.fetchBookstack = function (pageId, elementId) {
            const cancelar = $q.defer();
            bookstackApi.pendingRequests.push(cancelar);

            return $http({
                method: 'GET',
                timeout: cancelar.promise,
                url: "https://api.hilfe.debitorcloud.com/pages/" + pageId,
                headers: {
                    "Content-Type": "application/json"
                }
            }).then(
                function (response) {
                    let html = response.data.html;
                    if (elementId) {
                        const div = document.createElement("div");
                        div.innerHTML = html;
                        html = undefined;
                        const children = div.getElementsByTagName("*");
                        for (let i = 0; i < children.length; i++) {
                            if (children[i].id === elementId) {
                                html = children[i].textContent;
                            }
                        }
                    }
                    return html;
                },
                function (error) {
                    return error;
                }
            );
        }

        return bookstackApi;
    }
]);

angular.module("app").directive('alpha', function() {
  return {
    require: 'ngModel',
    link: function(scope, elm, attrs, ctrl) {
      ctrl.$validators.alpha = function(modelValue, viewValue) {
        if(modelValue && validator.isAlpha(modelValue)){
          return true;
        }
        
        return false;
      };
    }
  };
});
angular.module("app").directive('noNumbers', function() {
  return {
    require: 'ngModel',
    link: function(scope, elm, attrs, ctrl) {
      ctrl.$validators.noNumbers = function(modelValue, viewValue) {
        if(!modelValue){
          return true; 
        }
        
        if(modelValue.match(/\d+/g)){
          return false;
        }
        
        return true;
      };
    }
  };
});
angular.module("app").directive('floatTableHead', function() {
  return {
    link: function(scope, elm, attrs, ctrl) {
        //console.log(scope, elm, attrs);
        $(() => {
            elm.floatThead({
                position: 'absolute'
            })
        });
    }
  };
});
angular.module("app").directive('phoneNumber', function() {
  return {
    require: 'ngModel',
    link: function(scope, elm, attrs, ctrl) {
      ctrl.$validators.phoneNumber = function(modelValue, viewValue) {
        if(modelValue && validator.isMobilePhone(modelValue, 'any')){
          return true;
        }
        
        return false;
      };
    }
  };
});
angular.module("app").directive('isIpAddress', function() {
  return {
    require: 'ngModel',
    link: function(scope, elm, attrs, ctrl) {
      ctrl.$validators.isIpAddress = function(modelValue, viewValue) {
        if(modelValue && validator.isIP(modelValue)){
          return true;
        }
        
        return false;
      };
    }
  };
});
angular.module("app").directive('isLowercase', function() {
  return {
    require: 'ngModel',
    link: function(scope, elm, attrs, ctrl) {
      ctrl.$validators.isLowercase = function(modelValue, viewValue) {
        if(modelValue && validator.isLowercase(modelValue)){
          return true;
        }
        
        return false;
      };
    }
  };
});
angular.module("app").directive('databaseNameAvailable', [
	"$http",
	"$q",
	"requestManager",
	function($http, $q, requestManager) {
		return {
			require: 'ngModel',
			link: function(scope, elm, attrs, ctrl) {
				ctrl.$asyncValidators.databaseNameAvailable = function(modelValue, viewValue) {
					return requestManager.routes.auth.checkDatabaseNameAvailability(modelValue).then(
						function(response) {
							if(response.data.isAvailable){
								return $q.resolve(); 
							}
							else{
								return $q.reject();
							}
						}, 
						function(response){
							$q.reject("Error");
						}
					);
				};
			}
		};
	}
]);
angular.module("app").directive('validator', [
	"$http",
	"$q",
	"requestManager",
	function($http, $q, requestManager) {
		return {
			require: 'ngModel',
			link: function(scope, elm, attrs, ctrl) {
				switch(attrs.validator){
					case "isValidVat": 
						addIsValidVatValidator(ctrl);
						break;
					default: 
						throw "Validator not defined";
				}
				
				function addIsValidVatValidator(){
					ctrl.$asyncValidators.isValidVat = function(modelValue, viewValue) {
						return requestManager.routes.auth.checkVat(modelValue).then(
							function(response) {

								if(response.data.valid ){
									return $q.resolve(); 
								}
								else{
									return $q.reject();
								}
							}, 
							function(response){
								return $q.reject();
							}
						);
					};
				}
				
				
			}
		};
	}
]);
angular.module("app").directive('disableCharacters', function () {
    return {
        restrict: 'A',
        require: 'ngModel',
        link: function (scope, elem, attrs, ngModel) {
            ngModel.$parsers.push(function (viewValue) {
                var reg = /^[^`´ÄÖÜäöüßµ§²³°~!@#$€%\^&*()_+={}|[\]\\:';"<>?,./-/0-9]*$/;
                // if view values matches regexp, update model value
                if (viewValue.match(reg)) {
                    return viewValue;
                }
                // keep the model value as it is
                var transformedValue = ngModel.$modelValue;
                ngModel.$setViewValue(transformedValue);
                ngModel.$render();
                return transformedValue;
            });
        }
    };
});

angular.module("login", []);
angular.module("login").component("login", {
    templateUrl: 'app/routes/login/login.template.html',
    controller: [
        "$rootScope",
        "$location",
        function ($rootScope, $location) {
          this.$onInit = function(){
            if($rootScope.authData.isLoggedIn){
                $location.path("/dashboard");
            }
          }
        }
    ]
});
angular.module('dashboard', []);
angular.module('dashboard').component('dashboard', {
	templateUrl: 'app/routes/dashboard/dashboard.template.html',
	controller: [
		"$rootScope",
		"$location",
		"sharedMemory",
		"$scope",
		"requestManager",
		function ($rootScope, $location, sharedMemory, $scope, requestManager) {
		this.$onInit = function(){
				if(!$rootScope.authData.isLoggedIn){
					$location.path("/login");
				}
				initVariables();
				initScope();

				if ($rootScope.settings === undefined) {
					requestManager.routes.settings.getSettings($rootScope.authData.sessionId).then(
						(response) => {
							const accountLengthImpersonal = response.data.find((setting) => {
								return setting.settingCode === "accountLengthImpersonal";
							});
							if (accountLengthImpersonal !== undefined) {
								$scope.accountLengthImpersonal = +accountLengthImpersonal.settingValue1;
							} else {
								$scope.accountLengthImpersonal = 8;
							}
							$scope.settings.content = response.data;
							$scope.settings.currentlyLoading = false;
						},
						() => {
							$scope.settings.currentlyLoading = false;
						}
					);
				} else {
					const accountLengthImpersonal = $rootScope.settings.find((setting) => {
						return setting.settingCode === "accountLengthImpersonal";
					});
					if (accountLengthImpersonal !== undefined) {
						$scope.accountLengthImpersonal = +accountLengthImpersonal.settingValue1;
					} else {
						$scope.accountLengthImpersonal = 8;
					}
					$scope.settings.currentlyLoading = false;
				}

				sendAjaxRequests();
			}

			function initVariables(){
				$scope.userSettings = {
					content: undefined,
					currentlyLoading: false
				};
				$scope.emailList = {
					currentlyLoading: true,
					content: undefined
				};
				$scope.physicalMailList = {
					currentlyLoading: true,
					content: undefined
				};
                $scope.settings = {
                    content: undefined,
                    currentlyLoading: true
                };
				$scope.title = 'Dashboard';
				$scope.accountLengthImpersonal = undefined;
			}

			function initScope(){
				$scope.getSettingValue = getSettingValue;
			}

			function sendAjaxRequests(){
				requestManager.routes.recordEmail.virtual.getUnapprovedEmails($rootScope.authData.sessionId).then(
					function(response){
						$scope.emailList.currentlyLoading = false;
						$scope.emailList.content = response.data;
						$scope.emailList.size = Object.keys($scope.emailList.content).length;
					},
					function(respone){

					}
				);

				requestManager.routes.recordPhysicalMail.virtual.getUnapprovedPhysicalMail($rootScope.authData.sessionId).then(
					function(response){
						$scope.physicalMailList.currentlyLoading = false;
						$scope.physicalMailList.content = response.data;
						$scope.physicalMailList.size = $scope.physicalMailList.content.length;
					},
					function(respone){

					}
				);
			}

			function getSettingValue(key){

				if(!$rootScope.userSettings) {
					return false;
				}

				var setting = $rootScope.userSettings.find(function(setting){
					if (setting !== null) {
						return setting.settingCode === key;
					} else {
						return null;
					}
				});
				if(setting === undefined) {
					return false;
				}
				// var setting = $rootScope.userSettings.filter((setting) => {
				// 	return setting.settingCode === key;
				// });

				if(!setting){
					throw new Error("No setting with key: " + key);
				}
				else{
					return setting.value1 === "1";
				}
			}
		}
	]
}); 

angular.module('emailCenter', []);
angular.module('emailCenter').component('emailCenter', { 
	templateUrl: 'app/routes/emailCenter/emailCenter.template.html', 
	controller: [ 
		function () { 
			this.$onInit = function(){
			};
		} 
	] 
}); 

angular.module('allAdmonishedClients', []);
angular.module('allAdmonishedClients').component('allAdmonishedClients', {
    templateUrl: 'app/routes/allAdmonishedClients/allAdmonishedClients.template.html',
    controller: [
        "$scope",
        function ($scope) {
            this.$onInit = function(){
                $scope.instance = location.host.substring(0, location.host.indexOf(".debitorcloud"));
                if (!$scope.instance) {
                    $scope.instance = localStorage.getItem('useInstance');
                }
            };
        }
    ]
});

angular.module('workflows', []);
angular.module('workflows').component('workflows', { 
	templateUrl: 'app/routes/workflows/workflows.template.html', 
	controller: [
		"$scope",
		"$rootScope",
		"requestManager",
		"$filter",
		"sharedMemory",
		"$location",
		function ($scope, $rootScope, requestManager, $filter, sharedMemory, $location) { 
			this.$onInit = function(){
				initVariables();
				initScope();
				sendGetSettingsRequest($rootScope.authData.sessionId, function(requestDetails){
					if(requestDetails.successful){
						$scope.settings.content = requestDetails.response.data; 
						$scope.settings.currentlyLoading = false; 
					}
					else{
						if (!requestDetails.canceledRequest) {
							sharedMemory.error.setError(
								requestDetails,
								"COULD_NOT_LOAD_DATA",
								"Fehler beim laden der Settings (beim initiieren der Komponente)",
								$location.path(),
								"workflows"
							);
							$location.path("/error-page");
						}
					}
				});
				sendGetEmailTemplatesRequest($rootScope.authData.sessionId, function(requestDetails){
					if(requestDetails.successful){
							$scope.emailTemplates.content = requestDetails.response.data == "null" ? [] : requestDetails.response.data;
							$scope.emailTemplates.currentlyLoading = false;
					}
					else{
						if (!requestDetails.canceledRequest) {
							sharedMemory.error.setError(
								requestDetails,
								"COULD_NOT_LOAD_DATA",
								"Fehler beim laden der Email-Templates (beim initiieren der Komponente)",
								$location.path(),
								"workflows"
							);
							$location.path("/error-page");
						}
					}
				});
				sendGetWorkdflowsRequest($rootScope.authData.sessionId, function(requestDetails){
					if(requestDetails.successful){
						$scope.workflows.content = requestDetails.response.data;
						$scope.workflows.currentlyLoading = false;

					}
					else{
						if (!requestDetails.canceledRequest) {
							sharedMemory.error.setError(
									requestDetails,
									"COULD_NOT_LOAD_DATA",
									"Fehler beim laden der Workflows",
									$location.path(),
									"workflowList"
							);
							$location.path("/error-page");
						}
					}
				});
			};
			
			function initVariables(){
				$scope.settings = {
					content: undefined, 
					currentlyLoading: true
				}
				$scope.emailTemplates = {
					content: undefined,
					currentlyLoading: true
				}
				$scope.workflows = {
					content: undefined, 
					currentlyLoading: true
				}
				$scope.view = {
					allDependenciesLoaded: false
				}
			}
			
			function initScope(){
				
			}
			
			function sendGetSettingsRequest(sessionId, callback){
				var requestDetails = {
						successful: true,
						response: undefined, 
						canceledRequest: false
				};

				requestManager.routes.settings.getSettings(sessionId).then(
						function(response){
								requestDetails.response = response;
								callback(requestDetails);
						},
						function(response){
								requestDetails.allSuccessful = false;
								requestDetails.response = response;
								requestDetails.canceledRequest = response.status == -1;
								callback(requestDetails);
						}
				)
			}
			
			function sendGetEmailTemplatesRequest(sessionId, callback){
				var requestDetails = {
						successful: true,
						response: undefined, 
						canceledRequest: false
				};

				requestManager.routes.emailTemplates.getEmailTemplates(sessionId).then(
					function(response){
							requestDetails.response = response;
							callback(requestDetails);
					},
					function(response){
							requestDetails.response = response;
							requestDetails.successful = false;
							requestDetails.canceledRequest = response.status == -1;
							callback(requestDetails);
					}
				)
			}
			
			function sendGetWorkdflowsRequest(sessionId, callback){
					var requestDetails= {
							response: undefined,
							successful: true, 
							canceledRequest: false
					};

					requestManager.routes.workflow.getAllWorkflows(sessionId).then(
							function(response){
									requestDetails.response = response;
									callback(requestDetails);
							},
							function(response){
									requestDetails.response = response;
									requestDetails.successful = false;
									requestDetails.canceledRequest = response.status == -1;
									callback(requestDetails);
							}
					)
			}
			
			
		} 
	] 
}); 

angular.module('settings', []);
angular.module('settings').component('settings', {
    templateUrl: 'app/routes/settings/settings.template.html',
    controller: [
        "requestManager",
        "$scope",
        "$rootScope",
        "$location",
        "util",
        "Notification",
        "$filter",
        "$window",
        "sharedMemory",
        function (requestManager, $scope, $rootScope, $location, util, Notification, $filter, $window, sharedMemory) {
            const ctrl = this;
            init();

            function init() {
                initVariables();
                initScope();
                sendGetSettingsRequest($rootScope.authData.sessionId, function (requestDetails) {
                    if (requestDetails.successful) {
                        $scope.settings.content = requestDetails.response.data;
                        $scope.wysiwygSetting = $scope.settings.content.find(setting => setting.settingCode === 'wysiwygEditorActivated');
                        $scope.advancedSettings = $scope.settings.content.find(setting => setting.settingCode === 'advancedSettings');
                        $scope.advancedSettings = $scope.advancedSettings !== undefined ? $scope.advancedSettings.settingValue1 : undefined;
                        $scope.settings.currentlyLoading = false;
                    }
                    else {
                        sharedMemory.error.setError(
                            requestDetails,
                            "COULD_NOT_LOAD_DATA",
                            "Fehler beim laden der settings (beim initiieren der Komponente)",
                            $location.path(),
                            "settings"
                        );
                        $location.path("/error-page");
                    }
                });
                sendGetTranslationsRequest($rootScope.authData.sessionId, function (requestDetails) {
                    if (requestDetails.successful) {
                        $scope.translations.content = requestDetails.response.data;
                        $scope.translations.currentlyLoading = false;
                    }
                    else {
                        sharedMemory.error.setError(
                            requestDetails,
                            "COULD_NOT_LOAD_DATA",
                            "Fehler beim laden der  (beim initiieren der Komponente)",
                            $location.path(),
                            "settings"
                        );
                        $location.path("/error-page");
                    }
                });
                sendGetEmailTemplatesRequest($rootScope.authData.sessionId, function (requestDetails) {
                    if (requestDetails.successful) {
                        $scope.emailTemplates.content = requestDetails.response.data;
                        $scope.emailTemplates.currentlyLoading = false;
                    }
                    else {
                        sharedMemory.error.setError(
                            requestDetails,
                            "COULD_NOT_LOAD_DATA",
                            "Fehler beim laden E-Mail Templates",
                            $location.path(),
                            "settings"
                        );
                        $location.path("/error-page");
                    }
                });
                requestManager.routes.user.getAllUser($rootScope.authData.sessionId).then(
                    function (response) {
                        $scope.userList.content = response.data;
                        $scope.userList.currentlyLoading = false;
                    },
                    function (response) {
                        sharedMemory.error.setError(
                            response,
                            "COULD_NOT_LOAD_DATA",
                            "Fehler beim laden der User",
                            $location.path(),
                            "settings"
                        );
                        $location.path("/error-page");
                    }
                );
            }

            function initVariables() {
                $scope.currentTab = 0;
                $scope.settings = {
                    content: undefined,
                    currentlyLoading: true
                };
                $scope.translations = {
                    content: undefined,
                    currentlyLoading: true
                };
                $scope.emailTemplates = {
                    content: undefined,
                    currentlyLoading: true
                };
                $scope.userList = {
                    content: undefined,
                    currentlyLoading: true
                }
            }

            function initScope() {
                $scope.setTabIndexTo = setTabIndexTo;
                $scope.template = {
                    templateSettings: undefined,
                    templateType: undefined
                };

                ctrl.onTemplateChanged = onTemplateChanged;
                ctrl.onSaveTemplateClick = onSaveTemplateClick;
            }

            function setTabIndexTo(index) {
                $scope.currentTab = index;
            }

            function sendGetSettingsRequest(sessionId, callback) {
                var requestDetails = {
                    successful: true,
                    response: undefined
                };

                requestManager.routes.settings.getSettings(sessionId).then(
                    function (response) {
                        requestDetails.response = response;
                        callback(requestDetails);
                    },
                    function (response) {
                        requestDetails.response = response;
                        requestDetails.successful = false;
                        callback(requestDetails);
                    }
                )
            }

            function sendGetTranslationsRequest(sessionId, callback) {
                var requestDetails = {
                    successful: true,
                    response: undefined
                };

                requestManager.routes.language.getTranslations(sessionId).then(
                    function (response) {
                        requestDetails.response = response;
                        callback(requestDetails);
                    },
                    function (response) {
                        requestDetails.response = response;
                        requestDetails.successful = false;
                        callback(requestDetails);
                    }
                )
            }

            function sendGetEmailTemplatesRequest(sessionId, callback) {
                var requestDetails = {
                    successful: true,
                    response: undefined
                };

                requestManager.routes.emailTemplates.getEmailTemplates(sessionId).then(
                    function (response) {
                        requestDetails.response = response;
                        callback(requestDetails);
                    },
                    function (response) {
                        requestDetails.response = response;
                        requestDetails.successful = false;
                        callback(requestDetails);
                    }
                )
            }

            function onTemplateChanged(templateSettings, templateType) {
                $scope.template.templateSettings = templateSettings;
                if ($scope.template.templateSettings) {
                    if ($scope.template.templateType === 'reminderTemplate') {
                        $scope.template.templateSettings.push($scope.settings.content.find(x => x.settingCode === 'nonOverdueOpNewPage'));
                    }
                    // pass the templateType to the pdf Canvas
                    $scope.template.templateSettings.push({templateSetting: templateType, settingObject: true});
                }
                $scope.template.templateType = templateType;
            }

            function onSaveTemplateClick(templateSettings) {
                console.log('onSaveTemplate', templateSettings);
                const requestSummary = {
                    numRequestPlanned: templateSettings.length,
                    successfulResponses: [],
                    errorResponses: [],
                    allResponses: [],
                    withErrors: false
                };

                templateSettings.forEach((setting) => {
                    if (setting.id !== undefined) {
                        if (setting.settingValue3 !== undefined && setting.settingValue3 !== null) {
                            setting.settingValue3 = setting.settingValue3.replaceAll("&quot;", "'");
                        }
                        requestManager.routes.settings.editSettingWithKey($rootScope.authData.sessionId, setting).then(
                            function (response) {
                                requestSummary.successfulResponses.push(response);
                                requestSummary.allResponses.push(response);
                                checkFinishStatus();
                            },
                            function (response) {
                                requestSummary.errorResponses.push(response);
                                requestSummary.allResponses.push(response);
                                requestSummary.withErrors = true;
                                checkFinishStatus();
                            }
                        )
                    } else {
                        requestSummary.allResponses.push({});
                    }
                });

                function checkFinishStatus() {
                    if (requestSummary.numRequestPlanned === requestSummary.allResponses.length) {
                        requestManager.routes.settings.generateTemplates($rootScope.authData.sessionId).then(
                            (response) => {
                                Notification.success("Vorlage wurde gespeichert");
                                $window.location.reload();
                            },
                            (response) => {
                                // TODO: handle error properly
                            }
                        );
                    }
                }
            }
        }
    ]
});

angular.module('profile', []);
angular.module('profile').component('profile', { 
	templateUrl: 'app/routes/profile/profile.template.html', 
	controller: [ 
		function () { 
			this.$onInit = function(){
			};
		} 
	] 
}); 

angular.module('userDetails', []);
angular.module('userDetails').component('userDetails', { 
	templateUrl: 'app/routes/userDetails/userDetails.template.html', 
	controller: [
		"requestManager",
		"$rootScope",
		"$routeParams",
		"$scope",
		"$location",
		"sharedMemory",
		"$window",
		"$sce",
		function (requestManager, $rootScope, $routeParams, $scope, $location, sharedMemory, $window, $sce) {
			init();
			
			function init(){
                angular.element('.inmodal').hide();
				initVariables();

				getCustomerData($rootScope.authData.sessionId, $routeParams.id, function(requestDetails){
					if(requestDetails.successful){
						$scope.customer.currentlyLoading = false;
						$scope.customer.content = requestDetails.response.data[0];
					}
					else{
						sharedMemory.error.setError(
								requestDetails,
								"COULD_NOT_LOAD_DATA",
								"Fehler beim laden der Kundendaten (beim initiieren der Komponente)",
								$location.path(),
								"userInformations"
						);
						$location.path("/error-page");
					}
				});
			}
			
			function initVariables(){
				$scope.customer = {
					currentlyLoading: true,
					content: undefined
				}; 
				$scope.view = {
					allDependenciesLoaded: false
				};
			}
			
			function getCustomerData(sessionId, customerId, callback){
				var requestDetails = {
					successful: true,
					response: undefined
				};

				requestManager.routes.customer.getCustomerWithId(sessionId, customerId).then(
					function(response){
						requestDetails.response = response;
						callback(requestDetails);
					},
					function(response){
						requestDetails.response = response;
						requestDetails.successful = false;
						callback(requestDetails);
					}
				)
			}
		} 
	] 
}); 

angular.module('errorPage', []);
angular.module('errorPage').component('errorPage', { 
	templateUrl: 'app/routes/errorPage/errorPage.template.html', 
	controller: [
		function () {
			this.$onInit = function(){
			};
		} 
	] 
}); 

angular.module('tasks', []);
angular.module('tasks').component('tasks', { 
	templateUrl: 'app/routes/tasks/tasks.template.html', 
	controller: [

		function () {

		}
	]
}); 

angular.module('editWorkflow', []);
angular.module('editWorkflow').component('editWorkflow', {
	templateUrl: 'app/routes/editWorkflow/editWorkflow.template.html',
	controller: [
		"$scope",
		"$rootScope",
		"requestManager",
		"$location",
		"$routeParams",
		function($scope, $rootScope, requestManager, $location, $routeParams) {
			this.$onInit = function() {
				initVariables();
				initScope();
				sendGetWorkdflowsRequest($rootScope.authData.sessionId, function(requestDetails) {
					if (requestDetails.successful) {
						$scope.workflows.content = requestDetails.response.data;
						$scope.workflows.selectedWorkflow = $scope.workflows.content.find(function(workflow) {
							return workflow.id == $routeParams.id;
						});
						$scope.workflows.currentlyLoading = false;
						
						sendGetWorkflowFilterRequest($rootScope.authData.sessionId, $scope.workflows.selectedWorkflow.id, function(workflowFilterRequestDetails) {
							if (workflowFilterRequestDetails.successful) {
								workflowFilterRequestDetails.response.data.forEach(val => {
									if (val.value1 && val.value1 === 'contains') {
										val.value1 = 'enthält';
									}
								});
								$scope.workflowFilter.content = workflowFilterRequestDetails.response.data;
								$scope.workflowFilter.currentlyLoading = false;
							} 
							else {

							}
						});
					} else {

					}
				});
				sendGetSettingsRequest($rootScope.authData.sessionId, function(requestDetails) {
					if(requestDetails.successful){
						$scope.settings.content = requestDetails.response.data;
						$scope.settings.currentlyLoading = false;
					}
					else {
						
					}
				});
				sendGetEmailTemplatesRequest($rootScope.authData.sessionId, function(requestDetails){
					if(requestDetails.successful){
						$scope.emailTemplates.content = requestDetails.response.data;
						$scope.emailTemplates.currentlyLoading = false;
					}
					else {
						
					}
				})
			};

			function initVariables() {
				$scope.workflows = {
					content: undefined,
					selectedWorkflow: undefined,
					currentlyLoading: true
				}; 
				
				$scope.workflowFilter = {
					content: undefined,
					currentlyLoading: true
				}; 
				
				$scope.settings = {
					content: undefined,
					currentlyLoading: true
				}; 
				
				$scope.emailTemplates = {
					content: undefined,
					currentlyLoading: true
				}; 
			}

			function initScope() {

			}

			function sendGetWorkdflowsRequest(sessionId, callback) {
				var requestDetails = {
					response: undefined,
					successful: true
				};

				requestManager.routes.workflow.getAllWorkflows(sessionId).then(
					function(response) {
						requestDetails.response = response;
						callback(requestDetails);
					},
					function(response) {
						requestDetails.response = response;
						requestDetails.successful = false;
						callback(requestDetails);
					}
				)
			}

			function sendGetWorkflowFilterRequest(sessionId, workflowId, callback) {
				var requestDetails = {
					response: undefined,
					successful: true
				};

				requestManager.routes.workflow.getWorkflowFilter(sessionId, workflowId).then(
					function(response) {
						requestDetails.response = response;
						callback(requestDetails);
					},
					function(response) {
						requestDetails.response = response;
						requestDetails.successful = false;
						callback(requestDetails);
					}
				)
			}
			
			function sendGetSettingsRequest(sessionId, callback){
				var requestDetails = {
						successful: true,
						response: undefined
				};

				requestManager.routes.settings.getSettings(sessionId).then(
					function(response){
							requestDetails.response = response;
							callback(requestDetails);
					},
					function(response){
							requestDetails.allSuccessful = false;
							requestDetails.response = response;
							callback(requestDetails);
					}
				)
			}
			
			
			function sendGetEmailTemplatesRequest(sessionId, callback){
				var requestDetails = {
					successful: true, 
					response: undefined
				}

				requestManager.routes.emailTemplates.getEmailTemplates(sessionId).then(
					function(response){
						requestDetails.response = response; 
						callback(requestDetails);
					}, 
					function(response){
						requestDetails.response = response; 
						requestDetails.successful = false; 
						callback(requestDetails);
					}
				)
			}
			
		}
	]
});

angular.module('register', []);
angular.module('register').component('register', { 
	templateUrl: 'app/routes/register/register.template.html', 
	controller: [ 
		function () { 
			this.$onInit = function(){
			
			};
		} 
	] 
}); 

angular.module('emailCheck', []);
angular.module('emailCheck').component('emailCheck', {
    templateUrl: 'app/routes/emailCheck/emailCheck.template.html',
    controller: [
        "$scope",
        "$rootScope",
        "requestManager",
        function ($scope, $rootScope, requestManager) {
            this.$onInit = function () {
                initVariables();
                getUserId($rootScope.authData.sessionId);
                sendAjaxRequests();
            };

            function initVariables() {
                $scope.emailList = {
                    currentlyLoading: true,
                    content: undefined
                };
                $scope.physicalMailList = {
                    currentlyLoading: true,
                    content: undefined
                };
                $scope.userSettings = {
                    currentlyLoading: true,
                    content: undefined,
                    approvalListAssignedCustomersOnly: undefined,
                    emailApprovalListNumRows: undefined,
                    physicalMailApprovalListNumRows: undefined
                };
            }

            function getFilterSettingFromBackendApi() {
                requestManager.routes.user.getSettingsOfUser($rootScope.authData.sessionId, $scope.customerAdvisorId).then(
                    function (response) {
                        $scope.userSettings.content = response.data;
                        $scope.userSettings.content.forEach(setting => {
                            if (setting.settingCode === 'approvalListAssignedCustomersOnly') {
                                $scope.userSettings.approvalListAssignedCustomersOnly = setting;
                            }
                            if (setting.settingCode === 'emailApprovalListNumRows') {
                                $scope.userSettings.emailApprovalListNumRows = setting;
                            }
                            if (setting.settingCode === 'physicalMailApprovalListNumRows') {
                                $scope.userSettings.physicalMailApprovalListNumRows = setting;
                            }
                        });
                        $scope.userSettings.currentlyLoading = false;
                    },
                    function () {
                        console.error("Error trying to retrieve user settings from backend in component mail approval list");
                    }
                );
            }

            function getUserId(sessionId) {
                requestManager.routes.auth.getUserIdBySessionId(sessionId).then(
                    function (response) {
                        $scope.customerAdvisorId = response.data;
                        getFilterSettingFromBackendApi();
                    },
                    function () {
                        console.log("Error trying to get user/customer advisor ID from backend");
                    }
                )
            }

            function sendAjaxRequests() {
                requestManager.routes.recordEmail.virtual.getUnapprovedEmails($rootScope.authData.sessionId).then(
                    function (response) {
                        $scope.emailList.currentlyLoading = false;
                        $scope.emailList.content = response.data;
                    },
                    function (respone) {

                    }
                );

                requestManager.routes.recordPhysicalMail.virtual.getUnapprovedPhysicalMail($rootScope.authData.sessionId).then(
                    function (response) {
                        $scope.physicalMailList.currentlyLoading = false;
                        $scope.physicalMailList.content = response.data;
                    },
                    function (respone) {

                    }
                );
            }
        }
    ]
}); 

angular.module('analysis', []);
angular.module('analysis').component('analysis', {
    templateUrl: 'app/routes/analysis/analysis.template.html',
    controller: [
        function () {
            this.$onInit = function(){
            };
        }
    ]
});
angular.module('unclearPayments', []);

angular.module('unclearPayments').component('unclearPayments', {
    templateUrl: 'app/routes/unclearPayments/unclearPayments.template.html',
    controller: [
        "$scope",
        "$rootScope",
        "requestManager",
        function ($scope, $rootScope, requestManager) {
            this.$onInit = function(){
                $scope.europeanNumberFormat = 'en-US';
                if ($rootScope.europeanNumberFormat !== undefined) {
                    if ($rootScope.europeanNumberFormat === '1' || $rootScope.europeanNumberFormat === 1) {
                        $scope.europeanNumberFormat = 'de-AT';
                    }
                    else {
                        $scope.europeanNumberFormat = 'en-US';
                    }
                }
                initVariables();
                initScope();
                // TODO get unclearPayments
                $scope.sumOfUnclearPayments = $scope.unclearPayments.reduce((sum, x) => sum + x.invoiceSum, 0);
            };

            function initVariables() {
                $scope.currentlyLoading = false;
                $scope.unclearPayments = [
                    {invoiceNumber: 10101010, invoiceDate: new Date("2022-09-28 08:17:13"), invoiceSum: 1200.01},
                    {invoiceNumber: 10202020, invoiceDate: new Date("2022-10-20 08:17:13"), invoiceSum: 1500.20},
                    {invoiceNumber: 10303030, invoiceDate: new Date("2022-11-28 08:17:13"), invoiceSum: 1800.64},
                    {invoiceNumber: 10404040, invoiceDate: new Date("2022-12-20 08:17:13"), invoiceSum: 2400.25},
                ];
                $scope.sumOfUnclearPayments = 0;
            }

            function initScope() {
                $scope.transfer = transfer;
            }

            function transfer() {
                // TODO
                console.log('tranfer');
                $scope.sameUser = false;
                // TODO
                jQuery('#unattributableBookingsModal').modal('show');
            }
        }
    ]
});

angular.module('emailCenterIncoming', []);
angular.module('emailCenterIncoming').component('emailCenterIncoming', { 
	templateUrl: 'app/routes/emailCenterIncoming/emailCenterIncoming.template.html', 
	controller: [ 
		function () { 
			this.$onInit = function(){
			};
		} 
	] 
}); 

angular.module('emailCenterOutgoing', []);
angular.module('emailCenterOutgoing').component('emailCenterOutgoing', { 
	templateUrl: 'app/routes/emailCenterOutgoing/emailCenterOutgoing.template.html', 
	controller: [ 
		function () { 
			this.$onInit = function(){
			};
		} 
	] 
}); 

angular.module('supportTicket', []);
angular.module('supportTicket').component('supportTicket', { 
	templateUrl: 'app/routes/supportTicket/supportTicket.template.html', 
	controller: [ 
		function () { 
			this.$onInit = function(){
			};
		} 
	] 
}); 

angular.module('supportTicketCreate', []);
angular.module('supportTicketCreate').component('supportTicketCreate', { 
	templateUrl: 'app/routes/supportTicketCreate/supportTicketCreate.template.html', 
	controller: [ 
		function () { 
			this.$onInit = function(){
			};
		} 
	] 
}); 

angular.module('supportTicketList', []);
angular.module('supportTicketList').component('supportTicketList', { 
	templateUrl: 'app/routes/supportTicketList/supportTicketList.template.html', 
	controller: [ 
		function () { 
			this.$onInit = function(){
			};
		} 
	] 
}); 

angular.module('clientManagement', []);

angular.module('clientManagement').component('clientManagement', {
    templateUrl: 'app/routes/clientManagement/clientManagement.template.html',
    controller: [
        "$scope",
        "$rootScope",
        "requestManager",
        "$location",
        "Notification",
        "$filter",
        "$window",
        "$route",
        function ($scope, $rootScope, requestManager, $location, Notification, $filter, $window, $route) {
            this.$onInit = function () {
                initVariables();
                initScope();

                getRegisteredClients(function (requestDetails) {
                    if (requestDetails.successful) {
                        $scope.registeredClients.content = requestDetails.response;
                    } else {
                        // TODO error notification
                    }
                });
            }

            function initVariables() {
                $scope.thisClient = getThisClient();
                $scope.thisInstance = undefined;
                $scope.registeredClients = {
                    content: undefined,
                    currentlyLoading: false
                };
                $scope.availableClients = {
                    content: undefined,
                    currentlyLoading: false,
                    error: false
                };
                $scope.filteredClients = [];
                $scope.filter = {
                    searchTerm: undefined
                };
                $scope.selectedClient = undefined;
                $scope.databaseExists = false;
                $scope.currentlyLoading = false;
                $scope.infoModal = {
                    workspaceName: undefined,
                    username: undefined,
                    password: undefined,
                    ready: false
                };
            }

            function initScope() {
                $scope.getListOfClients = getListOfClients;
                $scope.searchClients = searchClients;
                $scope.resetStyle = resetStyle;
                $scope.onSelectClient = onSelectClient;
                $scope.onAddClient = onAddClient;
                $scope.redirectToInstance = redirectToInstance;

                jQuery('#responseInfoModal').on('hidden.bs.modal', function () {
                    $route.reload();
                }).modal("hide");
            }

            function redirectToInstance(instance) {
                if (instance) {
                    $window.open('https://' + instance, '_blank');
                } else {
                    $window.open('https://' + $scope.infoModal.workspaceName, '_blank');
                }
            }

            function onAddClient() {
                $scope.currentlyLoading = true;
                $scope.selectedClient.instanceName = $scope.selectedClient.instanceName.replace(/\s/g, '');

                clientPostRequest(function (requestDetails) {
                    if (requestDetails.successful) {
                        jQuery('#addClientModal').modal('hide');
                        if (requestDetails.response.hasOwnProperty('message') && requestDetails.response.message === 'Successful') {
                            if (requestDetails.response.hasOwnProperty('password') && requestDetails.response.hasOwnProperty('username') && requestDetails.response.hasOwnProperty('workspaceName')) {
                                $scope.infoModal.workspaceName = requestDetails.response.workspaceName;
                                $scope.infoModal.username = requestDetails.response.username;
                                $scope.infoModal.password = requestDetails.response.password;
                                $scope.infoModal.ready = true;
                            }
                            jQuery('#responseInfoModal').modal('show');
                        }
                        $scope.currentlyLoading = false;
                    } else {
                        if (requestDetails.response.includes('database exists')) {
                            $scope.databaseExists = true;
                        } else {
                            if (requestDetails.response[0].rule === 'validate_max_len') {
                                Notification.error($filter("translate")("clientManagement.NAME_LENGTH_VALIDATION"));
                            } else {
                                Notification.error($filter("translate")("clientManagement.ERROR"));
                            }
                        }
                        $scope.currentlyLoading = false;
                    }
                });
            }

            function clientPostRequest(callback) {
                const requestDetails = {
                    response: undefined,
                    successful: true
                };
                const body = {
                    workspaceName: $scope.selectedClient.instanceName,
                    datevClientId: $scope.selectedClient.id
                };

                requestManager.routes.clientManagement.addClient($rootScope.authData.sessionId, body).then(
                    function (response) {
                        requestDetails.response = response.data;
                        if (response.data.hasOwnProperty('message') && response.data.message !== 'Successful') {
                            requestDetails.successful = false;
                        }
                        if (response.data.hasOwnProperty('errors')) {
                            requestDetails.successful = false;
                            requestDetails.response = response.data.errors;
                        }
                        callback(requestDetails);
                    },
                    function (response) {
                        requestDetails.successful = false;
                        callback(requestDetails);
                    }
                )
            }

            function resetStyle() {
                $scope.databaseExists = false;
            }

            function onSelectClient(client) {
                $scope.selectedClient = client;
                $scope.selectedClient.instanceName = client.name.replace(/[^a-zA-Z]/g, '');
                $scope.selectedClient.instanceName = $scope.selectedClient.instanceName.replace(/\s/g, '').toLowerCase();
                jQuery('#selectClientModal').modal('hide');
                jQuery('#addClientModal').modal('show');
            }

            function searchClients() {
                filterClientsByTerm();
            }

            function filterClientsByTerm() {
                $scope.filteredClients = $scope.availableClients.content.filter(client => {
                    if (('' + client.number).includes($scope.filter.searchTerm) || (client.name).toLowerCase().includes($scope.filter.searchTerm.toLowerCase())) {
                        return client;
                    }
                });
            }

            function getAvailableClients(callback) {
                $scope.availableClients.currentlyLoading = true;
                const requestDetails = {
                    response: undefined,
                    successful: true,
                };

                requestManager.routes.clientManagement.getAvailableClients($rootScope.authData.sessionId).then(
                    function (response) {
                        requestDetails.response = response.data;
                        $scope.availableClients.currentlyLoading = false;
                        callback(requestDetails);
                    },
                    function (response) {
                        if (response.status === 401 || response.statusText === 'Unauthorized') {
                            $scope.availableClients.error = true;
                        }
                        requestDetails.successful = false;
                        $scope.availableClients.currentlyLoading = false;
                        callback(requestDetails);
                    }
                )
            }

            function getRegisteredClients(callback) {
                $scope.registeredClients.currentlyLoading = true;
                const requestDetails = {
                    response: undefined,
                    successful: true,
                };

                requestManager.routes.clientManagement.getRegisteredClients($rootScope.authData.sessionId).then(
                    function (response) {
                        if ($scope.thisClient) {
                            if (response.data.hasOwnProperty('debitorc_' + $scope.thisClient)) {
                                $scope.thisInstance = response.data['debitorc_' + $scope.thisClient];
                                delete response.data['debitorc_' + $scope.thisClient];
                            }
                        }
                        angular.forEach(Object.values(response.data), (obj, index) => {
                            let instanceName = Object.keys(response.data)[index];
                            if (instanceName) {
                                instanceName = instanceName.substring(9, instanceName.length);
                                instanceName = instanceName + ".debitorcloud.com";
                            }
                            obj['instance'] = instanceName;
                        });
                        requestDetails.response = Object.values(response.data);
                        $scope.registeredClients.currentlyLoading = false;
                        callback(requestDetails);
                    },
                    function () {
                        requestDetails.successful = false;
                        $scope.registeredClients.currentlyLoading = false;
                        callback(requestDetails);
                    }
                )
            }

            function getListOfClients() {
                getAvailableClients(function (requestDetails) {
                    if (requestDetails.successful) {
                        $scope.availableClients.content = requestDetails.response;
                        $scope.filteredClients = $scope.availableClients.content;
                    } else {
                        // TODO notification
                    }
                });
                jQuery('#selectClientModal').modal('show');
            }

            function getThisClient() {
                if (window.localStorage.getItem("useInstance")) {
                    return window.localStorage.getItem("useInstance");
                }
                return $location.absUrl()
                    .replace("https://", "")
                    .replace("http://", "")
                    .split(".")[0];
            }

        }
    ]
});

angular.module('resetCustomContent', []);

angular.module('resetCustomContent').component('resetCustomContent', {
    templateUrl: 'app/routes/resetCustomContent/resetCustomContent.template.html',
    controller: [
        "$scope",
        "$rootScope",
        "requestManager",
        "$location",
        "Notification",
        "$filter",
        "$window",
        function ($scope, $rootScope, requestManager, $location, Notification, $filter, $window) {
            this.$onInit = function () {
                initScope();
                $scope.loading = false;
                $scope.successfully = false;
            }

            function initScope() {
                $scope.onCancel = onCancel;
                $scope.onReset = onReset;
            }

            function onCancel() {
                $location.path("/dashboard");
            }

            function onReset() {
                if($window.confirm("Debitorcloud zurücksetzen")) {
                    $scope.loading = true;
                    requestManager.routes.reset.resetCustomContent($rootScope.authData.sessionId).then(
                        function () {
                            $scope.loading = false;
                            $scope.successfully = true;
                        },
                        function () {
                            $scope.loading = false;
                        }
                    )
                }
            }

        }
    ]
});

angular.module('setupWizard', []);

angular.module('setupWizard').component('setupWizard', {
    templateUrl: 'app/routes/setupWizard/setupWizard.template.html',
    controller: [
        "$scope",
        "$rootScope",
        "$http",
        "cookieManager",
        "sharedMemory",
        "$location",
        "requestManager",
        "util",
        "Notification",
        "$filter",
        "$window",
        function ($scope, $rootScope, $http, cookieManager, sharedMemory, $location, requestManager, util, Notification, $filter, $window) {
            this.$onInit = function () {
                initVariables();
                initScope();

                getSettings();
            };

            function initVariables() {
                //setCountryCodes();
                $scope.countries = [
                    {name: "Österreich", code: "AT"},
                    {name: "Deutschland", code: "DE"},
                ];
                $scope.intervalDayOptions = [
                    {key: "MO", text: $filter("translate")("setupWizard.MONDAY")},
                    {key: "DI", text: $filter("translate")("setupWizard.TUESDAY")},
                    {key: "MI", text: $filter("translate")("setupWizard.WEDNESDAY")},
                    {key: "DO", text: $filter("translate")("setupWizard.THURSDAY")},
                    {key: "FR", text: $filter("translate")("setupWizard.FRIDAY")},
                    {key: "SA", text: $filter("translate")("setupWizard.SATURDAY")},
                    {key: "SO", text: $filter("translate")("setupWizard.SUNDAY")}
                ];
                $scope.intervalHourOptions = createIntervalHours(0, 23);
                $scope.currentStep = 1;
                $scope.settings = {
                    content: undefined,
                    currentlyLoading: false,
                    error: false
                };
                $scope.companySettings = {
                    companyName: undefined,
                    companyMail: undefined,
                    companyCity: undefined,
                    companyZip: undefined,
                    companyAddress: undefined,
                    companyPhone: undefined,
                    companyCountry: undefined
                };
                $scope.logoSettings = {
                    companyLogo: undefined
                };
                $scope.paperLayoutSettings = {
                    layout: undefined
                };
                $scope.workflowsSettings = {
                    workflowIntervalDay: $scope.intervalDayOptions[4],
                    workflowIntervalHour: $scope.intervalHourOptions[12],
                    variant: undefined
                };
                $scope.userInvitationSettings = {
                    users: []
                };
                $scope.companySettingsRequests = {
                    total: 0,
                    finished: 0
                };
                $scope.paperLayoutSettingsRequests = {
                    total: 0,
                    finished: 0
                };
                $scope.workflowsSettingsRequests = {
                    total: 0,
                    finished: 0
                };
                $scope.userInvitationSettingsRequests = {
                    total: 0,
                    finished: 0
                };
                $scope.userWithId0Requests = {
                    total: 1,
                    finished: 0
                };
                $scope.setupWizardCompleted = undefined;
                $scope.loading = false;
            }

            function initScope() {
                $scope.onChangeStepClick = onChangeStepClick;
                $scope.imageSelect = imageSelect;
                $scope.onFinishSetup = onFinishSetup;
                $scope.logout = logout;
            }

            $scope.setFile = function (element) {
                $scope.currentFile = element.files[0];
                let reader = new FileReader();

                reader.onload = function (event) {
                    $scope.image_source = event.target.result;
                    $scope.logoSettings.companyLogo.file = event.target.result;
                    $scope.$apply();
                }
                // when the file is read it triggers the onload event above.
                reader.readAsDataURL(element.files[0]);
            }

            $scope.setFile2 = function (element) {
                $scope.currentFile = element.files[0];
                let reader = new FileReader();

                reader.onload = function (event) {
                    $scope.image_source2 = event.target.result;
                    $scope.paperLayoutSettings.layout = element.files[0];
                    $scope.$apply();
                }
                // when the file is read it triggers the onload event above.
                reader.readAsDataURL(element.files[0]);
            }

            function onFinishSetup() {
                if ($scope.setupWizardCompleted !== undefined && $scope.setupWizardCompleted.settingValue1 === "1") {
                    // TODO Notification
                    return;
                }
                $scope.loading = true;

                // company and logo settings
                $scope.companySettingsRequests.total = Object.values($scope.companySettings).length + 1;
                $scope.companySettingsRequests.finished = 0;

                if ($scope.paperLayoutSettings.layout !== undefined) {
                    $scope.paperLayoutSettingsRequests.total = 1;
                    $scope.paperLayoutSettingsRequests.finished = 0;
                }

                if ($scope.userInvitationSettings.users.length !== 0) {
                    $scope.userInvitationSettingsRequests.total = $scope.userInvitationSettings.users.length;
                    $scope.userInvitationSettingsRequests.finished = 0;
                }

                // Workflows Settings
                if ($scope.workflowsSettings.variant !== undefined) {
                    if ($scope.workflowsSettings.variant === 'variante1') {
                        workflowVariante1();
                    } else if ($scope.workflowsSettings.variant === 'variante2') {
                        workflowVariante2();
                    } else if ($scope.workflowsSettings.variant === 'variante3') {
                        workflowVariante3();
                    }
                } else {
                    // TODO error notification and return
                    Notification.error('Error');
                    return;
                }

                // Set email address of the user with the id 0
                editUser(function (requestDetails) {
                    if (requestDetails.successful) {
                        $scope.userWithId0Requests.finished += 1;
                        checkIfSetupFinished();
                    } else {
                        Notification.error('Error');
                    }
                });

                // User Invitation Settings
                if ($scope.userInvitationSettings.users.length !== 0) {
                    angular.forEach($scope.userInvitationSettings.users, function (user) {
                        user.companyName = $scope.companySettings.companyName.settingValue1;
                        console.log(user);
                        addUserRequest($rootScope.authData.sessionId, user, function (requestDetails) {
                            if (requestDetails.successful) {
                                $scope.userInvitationSettingsRequests.finished += 1;
                                checkIfSetupFinished();
                            } else {
                                Notification.error('Error');
                            }
                        })
                    });
                }

                // Paper Layout Settings
                if ($scope.paperLayoutSettings.layout !== undefined) {
                    sendUploadPdfTemplateRequest($scope.paperLayoutSettings.layout, function (requestDetails) {
                        if (requestDetails.successful) {
                            $scope.paperLayoutSettingsRequests.finished += 1;
                            checkIfSetupFinished();
                        } else {
                            Notification.error('Error');
                        }
                    });
                }

                // Company Settings
                let settings = $scope.companySettings;
                settings.companyLogo = $scope.logoSettings.companyLogo;
                if (Object.values(settings).length !== 0) {
                    angular.forEach(settings, function (setting, key) {
                        requestManager.routes.settings.editSettingWithKey($rootScope.authData.sessionId, setting).then(
                            function (response) {
                                $scope.companySettingsRequests.finished += 1;
                                checkIfSetupFinished();
                            },
                            function (response) {
                                Notification.error("Error");
                            }
                        )
                    });
                } else {
                    Notification.error($filter("translate")("setupWizard.CONTACT_SUPPORT"));
                }
            }

            function editUser(callback) {
                let params =  {
                    email: $scope.companySettings.companyMail.settingValue1,
                    id: 0
                };

                let requestDetails = {
                    successful: true
                };

                requestManager.routes.user.editUser($rootScope.authData.sessionId, params).then(
                    function(){
                        callback(requestDetails);
                    },
                    function(){
                        requestDetails.successful = false;
                        callback(requestDetails);
                    }
                )
            }

            function workflowVariante1() {
                $scope.workflowsSettingsRequests.total = 15;
                $scope.workflowsSettingsRequests.finished = 0;
                workflowPaymentReminder(function (requestDetails) {
                    if (requestDetails.successful) {
                        $scope.workflowsSettingsRequests.finished += 1;
                        createWorkflowFilter(requestDetails.response.data, {
                            keyName: "amountMails",
                            value1: "=",
                            value2: "0"
                        }, function (requestDetails) {
                            if (requestDetails.successful) {
                                $scope.workflowsSettingsRequests.finished += 1;
                                checkIfSetupFinished();
                            } else {
                                // TODO error notification
                            }
                        });
                        createWorkflowFilter(requestDetails.response.data, {
                            keyName: "oldestInvoice",
                            value1: ">=",
                            value2: "7"
                        }, function (requestDetails) {
                            if (requestDetails.successful) {
                                $scope.workflowsSettingsRequests.finished += 1;
                                checkIfSetupFinished();
                            } else {
                                // TODO error notification
                            }
                        });
                        createWorkflowFilter(requestDetails.response.data, {
                            keyName: "amountDebts",
                            value1: ">",
                            value2: "0"
                        }, function (requestDetails) {
                            if (requestDetails.successful) {
                                $scope.workflowsSettingsRequests.finished += 1;
                                checkIfSetupFinished();
                            } else {
                                // TODO error notification
                            }
                        });
                        createWorkflowFilter(requestDetails.response.data, {
                            keyName: "isProblematic",
                            value1: "=",
                            value2: "0"
                        }, function (requestDetails) {
                            if (requestDetails.successful) {
                                $scope.workflowsSettingsRequests.finished += 1;
                                checkIfSetupFinished();
                            } else {
                                // TODO error notification
                            }
                        });
                    } else {
                        // TODO error notification
                    }
                });

                workflowFirstReminder(function (requestDetails) {
                    if (requestDetails.successful) {
                        $scope.workflowsSettingsRequests.finished += 1;
                        createWorkflowFilter(requestDetails.response.data, {
                            keyName: "amountMails",
                            value1: "=",
                            value2: "1"
                        }, function (requestDetails) {
                            if (requestDetails.successful) {
                                $scope.workflowsSettingsRequests.finished += 1;
                                checkIfSetupFinished();
                            } else {
                                // TODO error notification
                            }
                        });
                        createWorkflowFilter(requestDetails.response.data, {
                            keyName: "lastTimeContacted",
                            value1: ">=",
                            value2: "7"
                        }, function (requestDetails) {
                            if (requestDetails.successful) {
                                $scope.workflowsSettingsRequests.finished += 1;
                                checkIfSetupFinished();
                            } else {
                                // TODO error notification
                            }
                        });
                        createWorkflowFilter(requestDetails.response.data, {
                            keyName: "amountDebts",
                            value1: ">",
                            value2: "0"
                        }, function (requestDetails) {
                            if (requestDetails.successful) {
                                $scope.workflowsSettingsRequests.finished += 1;
                                checkIfSetupFinished();
                            } else {
                                // TODO error notification
                            }
                        });
                        createWorkflowFilter(requestDetails.response.data, {
                            keyName: "isProblematic",
                            value1: "=",
                            value2: "0"
                        }, function (requestDetails) {
                            if (requestDetails.successful) {
                                $scope.workflowsSettingsRequests.finished += 1;
                                checkIfSetupFinished();
                            } else {
                                // TODO error notification
                            }
                        });
                    } else {
                        // TODO error notification
                    }
                });

                workflowLastReminder(function (requestDetails) {
                    if (requestDetails.successful) {
                        $scope.workflowsSettingsRequests.finished += 1;
                        createWorkflowFilter(requestDetails.response.data, {
                            keyName: "amountMails",
                            value1: "=",
                            value2: "2"
                        }, function (requestDetails) {
                            if (requestDetails.successful) {
                                $scope.workflowsSettingsRequests.finished += 1;
                                checkIfSetupFinished();
                            } else {
                                // TODO error notification
                            }
                        });
                        createWorkflowFilter(requestDetails.response.data, {
                            keyName: "lastTimeContacted",
                            value1: ">=",
                            value2: "7"
                        }, function (requestDetails) {
                            if (requestDetails.successful) {
                                $scope.workflowsSettingsRequests.finished += 1;
                                checkIfSetupFinished();
                            } else {
                                // TODO error notification
                            }
                        });
                        createWorkflowFilter(requestDetails.response.data, {
                            keyName: "amountDebts",
                            value1: ">",
                            value2: "0"
                        }, function (requestDetails) {
                            if (requestDetails.successful) {
                                $scope.workflowsSettingsRequests.finished += 1;
                                checkIfSetupFinished();
                            } else {
                                // TODO error notification
                            }
                        });
                        createWorkflowFilter(requestDetails.response.data, {
                            keyName: "isProblematic",
                            value1: "=",
                            value2: "0"
                        }, function (requestDetails) {
                            if (requestDetails.successful) {
                                $scope.workflowsSettingsRequests.finished += 1;
                                checkIfSetupFinished();
                            } else {
                                // TODO error notification
                            }
                        });
                    } else {
                        // TODO error notification
                    }
                });
            }

            function workflowVariante2() {
                $scope.workflowsSettingsRequests.total = 23;
                $scope.workflowsSettingsRequests.finished = 0;
                workflowPaymentReminder(function (requestDetails) {
                    if (requestDetails.successful) {
                        $scope.workflowsSettingsRequests.finished += 1;
                        createWorkflowFilter(requestDetails.response.data, {
                            keyName: "amountMails",
                            value1: "=",
                            value2: "0"
                        }, function (requestDetails) {
                            if (requestDetails.successful) {
                                $scope.workflowsSettingsRequests.finished += 1;
                                checkIfSetupFinished();
                            } else {
                                // TODO error notification
                            }
                        });
                        createWorkflowFilter(requestDetails.response.data, {
                            keyName: "oldestInvoice",
                            value1: ">=",
                            value2: "7"
                        }, function (requestDetails) {
                            if (requestDetails.successful) {
                                $scope.workflowsSettingsRequests.finished += 1;
                                checkIfSetupFinished();
                            } else {
                                // TODO error notification
                            }
                        });
                        createWorkflowFilter(requestDetails.response.data, {
                            keyName: "amountDebts",
                            value1: ">",
                            value2: "0"
                        }, function (requestDetails) {
                            if (requestDetails.successful) {
                                $scope.workflowsSettingsRequests.finished += 1;
                                checkIfSetupFinished();
                            } else {
                                // TODO error notification
                            }
                        });
                        createWorkflowFilter(requestDetails.response.data, {
                            keyName: "isProblematic",
                            value1: "=",
                            value2: "0"
                        }, function (requestDetails) {
                            if (requestDetails.successful) {
                                $scope.workflowsSettingsRequests.finished += 1;
                                checkIfSetupFinished();
                            } else {
                                // TODO error notification
                            }
                        });
                    } else {
                        // TODO error notification
                    }
                });

                workflowFirstReminder(function (requestDetails) {
                    if (requestDetails.successful) {
                        $scope.workflowsSettingsRequests.finished += 1;
                        createWorkflowFilter(requestDetails.response.data, {
                            keyName: "amountMails",
                            value1: "=",
                            value2: "1"
                        }, function (requestDetails) {
                            if (requestDetails.successful) {
                                $scope.workflowsSettingsRequests.finished += 1;
                                checkIfSetupFinished();
                            } else {
                                // TODO error notification
                            }
                        });
                        createWorkflowFilter(requestDetails.response.data, {
                            keyName: "lastTimeContacted",
                            value1: ">=",
                            value2: "7"
                        }, function (requestDetails) {
                            if (requestDetails.successful) {
                                $scope.workflowsSettingsRequests.finished += 1;
                                checkIfSetupFinished();
                            } else {
                                // TODO error notification
                            }
                        });
                        createWorkflowFilter(requestDetails.response.data, {
                            keyName: "amountDebts",
                            value1: ">",
                            value2: "0"
                        }, function (requestDetails) {
                            if (requestDetails.successful) {
                                $scope.workflowsSettingsRequests.finished += 1;
                                checkIfSetupFinished();
                            } else {
                                // TODO error notification
                            }
                        });
                        createWorkflowFilter(requestDetails.response.data, {
                            keyName: "isProblematic",
                            value1: "=",
                            value2: "0"
                        }, function (requestDetails) {
                            if (requestDetails.successful) {
                                $scope.workflowsSettingsRequests.finished += 1;
                                checkIfSetupFinished();
                            } else {
                                // TODO error notification
                            }
                        });
                    } else {
                        // TODO error notification
                    }
                });

                workflowPhoneCall(function (requestDetails) {
                    if (requestDetails.successful) {
                        $scope.workflowsSettingsRequests.finished += 1;
                        createWorkflowFilter(requestDetails.response.data, {
                            keyName: "amountMails",
                            value1: "=",
                            value2: "2"
                        }, function (requestDetails) {
                            if (requestDetails.successful) {
                                $scope.workflowsSettingsRequests.finished += 1;
                                checkIfSetupFinished();
                            } else {
                                // TODO error notification
                            }
                        });
                        createWorkflowFilter(requestDetails.response.data, {
                            keyName: "amountPendingCallJobs",
                            value1: "=",
                            value2: "0"
                        }, function (requestDetails) {
                            if (requestDetails.successful) {
                                $scope.workflowsSettingsRequests.finished += 1;
                                checkIfSetupFinished();
                            } else {
                                // TODO error notification
                            }
                        });
                        createWorkflowFilter(requestDetails.response.data, {
                            keyName: "amountDebts",
                            value1: ">",
                            value2: "0"
                        }, function (requestDetails) {
                            if (requestDetails.successful) {
                                $scope.workflowsSettingsRequests.finished += 1;
                                checkIfSetupFinished();
                            } else {
                                // TODO error notification
                            }
                        });
                        createWorkflowFilter(requestDetails.response.data, {
                            keyName: "amountCalls",
                            value1: "=",
                            value2: "0"
                        }, function (requestDetails) {
                            if (requestDetails.successful) {
                                $scope.workflowsSettingsRequests.finished += 1;
                                checkIfSetupFinished();
                            } else {
                                // TODO error notification
                            }
                        });
                        createWorkflowFilter(requestDetails.response.data, {
                            keyName: "lastTimeContacted",
                            value1: ">=",
                            value2: "7"
                        }, function (requestDetails) {
                            if (requestDetails.successful) {
                                $scope.workflowsSettingsRequests.finished += 1;
                                checkIfSetupFinished();
                            } else {
                                // TODO error notification
                            }
                        });
                        createWorkflowFilter(requestDetails.response.data, {
                            keyName: "isProblematic",
                            value1: "=",
                            value2: "0"
                        }, function (requestDetails) {
                            if (requestDetails.successful) {
                                $scope.workflowsSettingsRequests.finished += 1;
                                checkIfSetupFinished();
                            } else {
                                // TODO error notification
                            }
                        });
                    } else {
                        // TODO error notification
                    }
                });

                workflowLastReminderAfterPhoneCall(function (requestDetails) {
                    if (requestDetails.successful) {
                        $scope.workflowsSettingsRequests.finished += 1;
                        createWorkflowFilter(requestDetails.response.data, {
                            keyName: "amountMails",
                            value1: "=",
                            value2: "2"
                        }, function (requestDetails) {
                            if (requestDetails.successful) {
                                $scope.workflowsSettingsRequests.finished += 1;
                                checkIfSetupFinished();
                            } else {
                                // TODO error notification
                            }
                        });
                        createWorkflowFilter(requestDetails.response.data, {
                            keyName: "amountDaysSinceLastSuccessfullCall",
                            value1: ">",
                            value2: "6"
                        }, function (requestDetails) {
                            if (requestDetails.successful) {
                                $scope.workflowsSettingsRequests.finished += 1;
                                checkIfSetupFinished();
                            } else {
                                // TODO error notification
                            }
                        });
                        createWorkflowFilter(requestDetails.response.data, {
                            keyName: "amountDebts",
                            value1: ">",
                            value2: "0"
                        }, function (requestDetails) {
                            if (requestDetails.successful) {
                                $scope.workflowsSettingsRequests.finished += 1;
                                checkIfSetupFinished();
                            } else {
                                // TODO error notification
                            }
                        });
                        createWorkflowFilter(requestDetails.response.data, {
                            keyName: "amountCalls",
                            value1: "=",
                            value2: "1"
                        }, function (requestDetails) {
                            if (requestDetails.successful) {
                                $scope.workflowsSettingsRequests.finished += 1;
                                checkIfSetupFinished();
                            } else {
                                // TODO error notification
                            }
                        });
                        createWorkflowFilter(requestDetails.response.data, {
                            keyName: "isProblematic",
                            value1: "=",
                            value2: "0"
                        }, function (requestDetails) {
                            if (requestDetails.successful) {
                                $scope.workflowsSettingsRequests.finished += 1;
                                checkIfSetupFinished();
                            } else {
                                // TODO error notification
                            }
                        });
                    } else {
                        // TODO error notification
                    }
                });
            }

            function workflowVariante3() {
                $scope.workflowsSettingsRequests.total = 20;
                $scope.workflowsSettingsRequests.finished = 0;
                workflowPaymentReminder(function (requestDetails) {
                    if (requestDetails.successful) {
                        $scope.workflowsSettingsRequests.finished += 1;
                        createWorkflowFilter(requestDetails.response.data, {
                            keyName: "amountMails",
                            value1: "=",
                            value2: "0"
                        }, function (requestDetails) {
                            if (requestDetails.successful) {
                                $scope.workflowsSettingsRequests.finished += 1;
                                checkIfSetupFinished();
                            } else {
                                // TODO error notification
                            }
                        });
                        createWorkflowFilter(requestDetails.response.data, {
                            keyName: "oldestInvoice",
                            value1: ">=",
                            value2: "7"
                        }, function (requestDetails) {
                            if (requestDetails.successful) {
                                $scope.workflowsSettingsRequests.finished += 1;
                                checkIfSetupFinished();
                            } else {
                                // TODO error notification
                            }
                        });
                        createWorkflowFilter(requestDetails.response.data, {
                            keyName: "amountDebts",
                            value1: ">",
                            value2: "0"
                        }, function (requestDetails) {
                            if (requestDetails.successful) {
                                $scope.workflowsSettingsRequests.finished += 1;
                                checkIfSetupFinished();
                            } else {
                                // TODO error notification
                            }
                        });
                        createWorkflowFilter(requestDetails.response.data, {
                            keyName: "isProblematic",
                            value1: "=",
                            value2: "0"
                        }, function (requestDetails) {
                            if (requestDetails.successful) {
                                $scope.workflowsSettingsRequests.finished += 1;
                                checkIfSetupFinished();
                            } else {
                                // TODO error notification
                            }
                        });
                    } else {
                        // TODO error notification
                    }
                });

                workflowFirstReminder(function (requestDetails) {
                    if (requestDetails.successful) {
                        $scope.workflowsSettingsRequests.finished += 1;
                        createWorkflowFilter(requestDetails.response.data, {
                            keyName: "amountMails",
                            value1: "=",
                            value2: "1"
                        }, function (requestDetails) {
                            if (requestDetails.successful) {
                                $scope.workflowsSettingsRequests.finished += 1;
                                checkIfSetupFinished();
                            } else {
                                // TODO error notification
                            }
                        });
                        createWorkflowFilter(requestDetails.response.data, {
                            keyName: "lastTimeContacted",
                            value1: ">=",
                            value2: "7"
                        }, function (requestDetails) {
                            if (requestDetails.successful) {
                                $scope.workflowsSettingsRequests.finished += 1;
                                checkIfSetupFinished();
                            } else {
                                // TODO error notification
                            }
                        });
                        createWorkflowFilter(requestDetails.response.data, {
                            keyName: "amountDebts",
                            value1: ">",
                            value2: "0"
                        }, function (requestDetails) {
                            if (requestDetails.successful) {
                                $scope.workflowsSettingsRequests.finished += 1;
                                checkIfSetupFinished();
                            } else {
                                // TODO error notification
                            }
                        });
                        createWorkflowFilter(requestDetails.response.data, {
                            keyName: "isProblematic",
                            value1: "=",
                            value2: "0"
                        }, function (requestDetails) {
                            if (requestDetails.successful) {
                                $scope.workflowsSettingsRequests.finished += 1;
                                checkIfSetupFinished();
                            } else {
                                // TODO error notification
                            }
                        });
                    } else {
                        // TODO error notification
                    }
                });

                workflowLastReminder(function (requestDetails) {
                    if (requestDetails.successful) {
                        $scope.workflowsSettingsRequests.finished += 1;
                        createWorkflowFilter(requestDetails.response.data, {
                            keyName: "amountMails",
                            value1: "=",
                            value2: "2"
                        }, function (requestDetails) {
                            if (requestDetails.successful) {
                                $scope.workflowsSettingsRequests.finished += 1;
                                checkIfSetupFinished();
                            } else {
                                // TODO error notification
                            }
                        });
                        createWorkflowFilter(requestDetails.response.data, {
                            keyName: "lastTimeContacted",
                            value1: ">=",
                            value2: "7"
                        }, function (requestDetails) {
                            if (requestDetails.successful) {
                                $scope.workflowsSettingsRequests.finished += 1;
                                checkIfSetupFinished();
                            } else {
                                // TODO error notification
                            }
                        });
                        createWorkflowFilter(requestDetails.response.data, {
                            keyName: "amountDebts",
                            value1: ">",
                            value2: "0"
                        }, function (requestDetails) {
                            if (requestDetails.successful) {
                                $scope.workflowsSettingsRequests.finished += 1;
                                checkIfSetupFinished();
                            } else {
                                // TODO error notification
                            }
                        });
                        createWorkflowFilter(requestDetails.response.data, {
                            keyName: "isProblematic",
                            value1: "=",
                            value2: "0"
                        }, function (requestDetails) {
                            if (requestDetails.successful) {
                                $scope.workflowsSettingsRequests.finished += 1;
                                checkIfSetupFinished();
                            } else {
                                // TODO error notification
                            }
                        });
                    } else {
                        // TODO error notification
                    }
                });

                workflowHandoverToLawyer(function (requestDetails) {
                    if (requestDetails.successful) {
                        $scope.workflowsSettingsRequests.finished += 1;
                        createWorkflowFilter(requestDetails.response.data, {
                            keyName: "amountMails",
                            value1: "=",
                            value2: "3"
                        }, function (requestDetails) {
                            if (requestDetails.successful) {
                                $scope.workflowsSettingsRequests.finished += 1;
                                checkIfSetupFinished();
                            } else {
                                // TODO error notification
                            }
                        });
                        createWorkflowFilter(requestDetails.response.data, {
                            keyName: "lastTimeContacted",
                            value1: ">=",
                            value2: "7"
                        }, function (requestDetails) {
                            if (requestDetails.successful) {
                                $scope.workflowsSettingsRequests.finished += 1;
                                checkIfSetupFinished();
                            } else {
                                // TODO error notification
                            }
                        });
                        createWorkflowFilter(requestDetails.response.data, {
                            keyName: "amountDebts",
                            value1: ">",
                            value2: "0"
                        }, function (requestDetails) {
                            if (requestDetails.successful) {
                                $scope.workflowsSettingsRequests.finished += 1;
                                checkIfSetupFinished();
                            } else {
                                // TODO error notification
                            }
                        });
                        createWorkflowFilter(requestDetails.response.data, {
                            keyName: "isProblematic",
                            value1: "=",
                            value2: "0"
                        }, function (requestDetails) {
                            if (requestDetails.successful) {
                                $scope.workflowsSettingsRequests.finished += 1;
                                checkIfSetupFinished();
                            } else {
                                // TODO error notification
                            }
                        });
                    } else {
                        // TODO error notification
                    }
                });
            }

            function workflowPaymentReminder(callback) {
                let body = {
                    name: "Zahlungserinnerung",
                    action: "TO_EMAIL_OR_PHYSICAL_MAIL",
                    intervalAmount: "P1W",
                    intervalDay: $scope.workflowsSettings.workflowIntervalDay.key,
                    intervalHour: $scope.workflowsSettings.workflowIntervalHour.key,
                    disabled: "1",
                    value1: "0",
                    value2: "1",
                    priority: "0",
                    noPermissionNeededForMailOrPhysicalMail: "0",
                    lastExecuted: generateExecutedDate($scope.workflowsSettings.workflowIntervalDay.key, $scope.workflowsSettings.workflowIntervalHour.key, true),
                };

                const requestDetails = {
                    successful: true,
                    response: undefined
                };
                requestManager.routes.workflow.createWorkflow($rootScope.authData.sessionId, body).then(
                    function (response) {
                        requestDetails.response = response;
                        callback(requestDetails);
                    },
                    function (response) {
                        requestDetails.successful = false;
                        callback(requestDetails);
                    }
                )
            }

            function workflowFirstReminder(callback) {
                let body = {
                    name: "Erste Mahnung",
                    action: "TO_EMAIL_OR_PHYSICAL_MAIL",
                    intervalAmount: "P1W",
                    intervalDay: $scope.workflowsSettings.workflowIntervalDay.key,
                    intervalHour: $scope.workflowsSettings.workflowIntervalHour.key,
                    disabled: "1",
                    value1: "0",
                    value2: "2",
                    priority: "1",
                    noPermissionNeededForMailOrPhysicalMail: "0",
                    lastExecuted: generateExecutedDate($scope.workflowsSettings.workflowIntervalDay.key, $scope.workflowsSettings.workflowIntervalHour.key, true),
                };

                const requestDetails = {
                    successful: true,
                    response: undefined
                };
                requestManager.routes.workflow.createWorkflow($rootScope.authData.sessionId, body).then(
                    function (response) {
                        requestDetails.response = response;
                        callback(requestDetails);
                    },
                    function (response) {
                        requestDetails.successful = false;
                        callback(requestDetails);
                    }
                )
            }

            function workflowLastReminder(callback) {
                let body = {
                    name: "Letzte Mahnung",
                    action: "TO_EMAIL_OR_PHYSICAL_MAIL",
                    intervalAmount: "P1W",
                    intervalDay: $scope.workflowsSettings.workflowIntervalDay.key,
                    intervalHour: $scope.workflowsSettings.workflowIntervalHour.key,
                    disabled: "1",
                    value1: "0",
                    value2: "2",
                    priority: "2",
                    noPermissionNeededForMailOrPhysicalMail: "0",
                    lastExecuted: generateExecutedDate($scope.workflowsSettings.workflowIntervalDay.key, $scope.workflowsSettings.workflowIntervalHour.key, true),
                };

                const requestDetails = {
                    successful: true,
                    response: undefined
                };
                requestManager.routes.workflow.createWorkflow($rootScope.authData.sessionId, body).then(
                    function (response) {
                        requestDetails.response = response;
                        callback(requestDetails);
                    },
                    function (response) {
                        requestDetails.successful = false;
                        callback(requestDetails);
                    }
                )
            }

            function workflowPhoneCall(callback) {
                let body = {
                    name: "Telefonanruf",
                    action: "TO_PHONE_LIST",
                    intervalAmount: "P1W",
                    intervalDay: $scope.workflowsSettings.workflowIntervalDay.key,
                    intervalHour: $scope.workflowsSettings.workflowIntervalHour.key,
                    disabled: "1",
                    value1: "Kunde muss wegen offener Posten nach Mahnung angerufen werden",
                    value2: "Anruf",
                    priority: "2",
                    noPermissionNeededForMailOrPhysicalMail: "0",
                    lastExecuted: generateExecutedDate($scope.workflowsSettings.workflowIntervalDay.key, $scope.workflowsSettings.workflowIntervalHour.key, true),
                };

                const requestDetails = {
                    successful: true,
                    response: undefined
                };
                requestManager.routes.workflow.createWorkflow($rootScope.authData.sessionId, body).then(
                    function (response) {
                        requestDetails.response = response;
                        callback(requestDetails);
                    },
                    function (response) {
                        requestDetails.successful = false;
                        callback(requestDetails);
                    }
                )
            }

            function workflowLastReminderAfterPhoneCall(callback) {
                let body = {
                    name: "Letzte Mahnung",
                    action: "TO_EMAIL_OR_PHYSICAL_MAIL",
                    intervalAmount: "P1W",
                    intervalDay: $scope.workflowsSettings.workflowIntervalDay.key,
                    intervalHour: $scope.workflowsSettings.workflowIntervalHour.key,
                    disabled: "1",
                    value1: "0",
                    value2: "2",
                    priority: "3",
                    noPermissionNeededForMailOrPhysicalMail: "0",
                    lastExecuted: generateExecutedDate($scope.workflowsSettings.workflowIntervalDay.key, $scope.workflowsSettings.workflowIntervalHour.key, true),
                };

                const requestDetails = {
                    successful: true,
                    response: undefined
                };
                requestManager.routes.workflow.createWorkflow($rootScope.authData.sessionId, body).then(
                    function (response) {
                        requestDetails.response = response;
                        callback(requestDetails);
                    },
                    function (response) {
                        requestDetails.successful = false;
                        callback(requestDetails);
                    }
                )
            }

            function workflowHandoverToLawyer(callback) {
                let body = {
                    name: "Inkassoübergabe",
                    action: "TO_LAWYER_LIST",
                    intervalAmount: "P1W",
                    intervalDay: $scope.workflowsSettings.workflowIntervalDay.key,
                    intervalHour: $scope.workflowsSettings.workflowIntervalHour.key,
                    disabled: "1",
                    value1: "Kunde muss zum Anwalt / Inkasso übergeben werden",
                    value2: "Anwaltsübergabe",
                    priority: "3",
                    noPermissionNeededForMailOrPhysicalMail: "0",
                    lastExecuted: generateExecutedDate($scope.workflowsSettings.workflowIntervalDay.key, $scope.workflowsSettings.workflowIntervalHour.key, true),
                };

                const requestDetails = {
                    successful: true,
                    response: undefined
                };
                requestManager.routes.workflow.createWorkflow($rootScope.authData.sessionId, body).then(
                    function (response) {
                        requestDetails.response = response;
                        callback(requestDetails);
                    },
                    function (response) {
                        requestDetails.successful = false;
                        callback(requestDetails);
                    }
                )
            }

            function createWorkflowFilter(id, body, callback) {
                const requestDetails = {
                    successful: true,
                    response: undefined
                };
                requestManager.routes.workflow.createWorkflowFilterOfWorkflow($rootScope.authData.sessionId, id, body).then(
                    function (response) {
                        requestDetails.response = response;
                        callback(requestDetails);
                    },
                    function (response) {
                        requestDetails.response = response;
                        requestDetails.successful = false;
                        callback(requestDetails);
                    }
                )
            }

            function addUserRequest(sessionId, user, callback) {
                let requestDetails = {
                    successful: true
                }

                requestManager.routes.user.addUser(sessionId, user).then(
                    function () {
                        callback(requestDetails);
                    },
                    function () {
                        requestDetails.successful = false;
                        callback(requestDetails);
                    }
                )
            }

            function sendUploadPdfTemplateRequest(file, callback) {
                let requestDetails = {
                    successful: true
                };

                requestManager.routes.record.uploadPdfTemplate($rootScope.authData.sessionId, file).then(
                    function () {
                        callback(requestDetails);
                    },
                    function () {
                        requestDetails.successful = false;
                        callback(requestDetails);
                    }
                )
            }

            function checkIfSetupFinished() {
                if (($scope.companySettingsRequests.total === $scope.companySettingsRequests.finished)
                    && ($scope.paperLayoutSettingsRequests.total === $scope.paperLayoutSettingsRequests.finished)
                    && ($scope.workflowsSettingsRequests.total === $scope.workflowsSettingsRequests.finished)
                    && ($scope.userWithId0Requests.total === $scope.userWithId0Requests.finished)
                    && ($scope.userInvitationSettingsRequests.total === $scope.userInvitationSettingsRequests.finished)) {
                    Notification.success("Erfolgreich");
                    $scope.setupWizardCompleted.settingValue1 = "1";
                    requestManager.routes.settings.editSettingWithKey($rootScope.authData.sessionId, $scope.setupWizardCompleted).then(
                        function () {
                            $scope.loading = false;
                            $window.location.reload();
                        },
                        function () {
                            Notification.error("Error");
                        }
                    )
                }
            }

            function getSettings() {
                $scope.settings.currentlyLoading = true;
                requestManager.routes.settings.getSettings($rootScope.authData.sessionId).then(
                    function (response) {
                        $scope.settings.content = response.data;
                        $scope.companySettings.companyName = util.getSettingByName($scope.settings.content, "companyName");
                        $scope.companySettings.companyMail = util.getSettingByName($scope.settings.content, "companyMail");
                        $scope.companySettings.companyCity = util.getSettingByName($scope.settings.content, "companyCity");
                        $scope.companySettings.companyZip = util.getSettingByName($scope.settings.content, "companyZip");
                        $scope.companySettings.companyAddress = util.getSettingByName($scope.settings.content, "companyAddress");
                        $scope.companySettings.companyPhone = util.getSettingByName($scope.settings.content, "companyPhone");
                        $scope.companySettings.companyCountry = util.getSettingByName($scope.settings.content, "companyCountry");
                        $scope.logoSettings.companyLogo = util.getSettingByName($scope.settings.content, "companyLogo");
                        $scope.setupWizardCompleted = util.getSettingByName($scope.settings.content, "setupWizardCompleted");
                        $scope.settings.currentlyLoading = false;
                    },
                    function (response) {
                        $scope.settings.currentlyLoading = false;
                        $scope.settings.error = true;
                        // TODO notification please contact support
                    }
                )
            }

            function imageSelect(value) {
                $scope.workflowsSettings.variant = value;
            }

            function onChangeStepClick(step, currentFormName) {
                if (currentFormName === undefined && step < $scope.currentStep) {
                    $scope.currentStep = step;
                    return;
                }

                if (currentFormName && $scope[currentFormName].$valid) {
                    $scope.currentStep = step;
                }

                if (!currentFormName) {
                    $scope.currentStep = step;
                }
            }

            function logout() {
                $rootScope.authData.sessionId = "";
                $rootScope.authData.isLoggedIn = false;

                cookieManager.deleteCookie();
                sharedMemory.reset();
                $location.path("/login");
            }

            function generateExecutedDate(dayOfWeek, hour, subtractInterval = false) {
                let date = moment();
                if (subtractInterval) {
                    date.subtract(1, "week")
                }
                date.startOf("week").add(hour, "hours").add(getNumberOfDaysToCertainDay(dayOfWeek), "days");
                return date.format("YYYY-MM-DD HH:mm:ss");
            }

            function getNumberOfDaysToCertainDay(day) {
                switch (day) {
                    case "MO":
                        return 1;
                    case "DI":
                        return 2;
                    case "MI":
                        return 3;
                    case "DO":
                        return 4;
                    case "FR":
                        return 5;
                    case "SA":
                        return 6;
                    case "SO":
                        return 7;
                }
            }

            function createIntervalHours(min, max) {
                const hourArray = [];
                for (let i = min; i < max + 1; i++) {
                    hourArray.push({key: i, text: i + ":00"});
                }
                return hourArray;
            }

            function setCountryCodes() {
                $http({
                    method: 'GET',
                    url: "../node_modules/countries-list/dist/data.json",
                }).then(
                    function (response) {
                        angular.forEach(response.data.countries, function (value, key) {
                            value.code = key;
                        });
                        $scope.countries = response.data.countries;
                    },
                    function (response) {
                    }
                );
            }
        }
    ]
});

angular.module('branding', []);

angular.module('branding').component('branding', {
    templateUrl: 'app/routes/branding/branding.template.html',
    controller: [
        "$scope",
        "$rootScope",
        "requestManager",
        "util",
        "Notification",
        "$window",
        function ($scope, $rootScope, requestManager, util, Notification, $window) {
            this.$onInit = function () {
                initVariables();
                initScope();

                getSettings();
            }

            function initVariables() {
                $scope.settings = {
                    content: undefined,
                    currentlyLoading: true,
                    error: false
                };
                $scope.brandingSettings = {
                    isBrandingActive: undefined,
                    brandingLogo: undefined,
                    brandingWallpaper: undefined,
                    brandingPrimaryColor: undefined,
                    // brandingSecondaryColor: undefined,
                    poweredByDebitorcloudPosition: undefined
                }
                $scope.settingsToCreate = {
                    content: [],
                    numberOfRequests: 0,
                    numberOfFinishedRequests: 0,
                    currentlyLoading: false
                };
                $scope.possiblePositions = [
                    {
                        name: 'Oben links',
                        value: 'TL'
                    },
                    {
                        name: 'Oben rechts',
                        value: 'TR'
                    },
                    {
                        name: 'Unten links',
                        value: 'BL'
                    },
                    {
                        name: 'Unten rechts',
                        value: 'BR'
                    }
                ];
                $scope.onSaveLoading = false;
            }

            function initScope() {
                $scope.saveChanges = saveChanges;
            }

            $scope.setFile = function (element) {
                $scope.currentFile = element.files[0];
                let reader = new FileReader();

                reader.onload = function (event) {
                    $scope.image_source = event.target.result;
                    $scope.brandingSettings.brandingLogo.file = event.target.result;
                    $scope.$apply();
                }
                // when the file is read it triggers the onload event above.
                reader.readAsDataURL(element.files[0]);
            }

            $scope.setFile2 = function (element) {
                $scope.currentFile = element.files[0];
                let reader = new FileReader();

                reader.onload = function (event) {
                    $scope.image_source = event.target.result;
                    $scope.brandingSettings.brandingWallpaper.file = event.target.result;
                    $scope.$apply();
                }
                // when the file is read it triggers the onload event above.
                reader.readAsDataURL(element.files[0]);
            }

            function saveChanges() {
                $scope.onSaveLoading = true;
                $scope.totalRequests = Object.values($scope.brandingSettings).length;
                $scope.finishedRequests = 0;
                angular.forEach($scope.brandingSettings, setting => {
                    requestManager.routes.settings.editSettingWithKey($rootScope.authData.sessionId, setting).then(
                        function () {
                            $scope.finishedRequests += 1;
                            if ($scope.totalRequests === $scope.finishedRequests) {
                                Notification.success("Erfolgreich");
                                $scope.onSaveLoading = false;
                            }
                        },
                        function () {
                            Notification.error("Error");
                            $window.location.reload();
                            $scope.onSaveLoading = false;
                        }
                    )
                });
            }

            function getSettings() {
                $scope.settings.currentlyLoading = true;
                requestManager.routes.settings.getSettings($rootScope.authData.sessionId).then(
                    function (response) {
                        $scope.settings.content = response.data;
                        $scope.brandingSettings.isBrandingActive = util.getSettingByName($scope.settings.content, "isBrandingActive");
                        $scope.brandingSettings.brandingLogo = util.getSettingByName($scope.settings.content, "brandingLogo");
                        $scope.brandingSettings.brandingWallpaper = util.getSettingByName($scope.settings.content, "brandingWallpaper");
                        $scope.brandingSettings.brandingPrimaryColor = util.getSettingByName($scope.settings.content, "brandingPrimaryColor");
                        // $scope.brandingSettings.brandingSecondaryColor = util.getSettingByName($scope.settings.content, "brandingSecondaryColor");
                        $scope.brandingSettings.poweredByDebitorcloudPosition = util.getSettingByName($scope.settings.content, "poweredByDebitorcloudPosition");
                        checkIfSettingsExist();
                        if ($scope.settingsToCreate.numberOfRequests !== 0 && $scope.settingsToCreate.numberOfRequests !== $scope.settingsToCreate.numberOfFinishedRequests) {
                            $scope.settingsToCreate.currentlyLoading = true;
                            createSettings();
                        }
                        $scope.settings.currentlyLoading = false;
                    },
                    function () {
                        $scope.settings.currentlyLoading = false;
                        $scope.settings.error = true;
                    }
                )
            }

            function createSettings() {
                $scope.settingsToCreate.content.forEach(setting => {
                    const params = {
                        settingCode: setting
                    }
                    if (setting.includes("Color")) {
                        params.settingValue1 = "#77b82a";
                    } else if (setting.includes("poweredBy")) {
                        params.settingValue1 = "BR";
                    }
                    sendCreateSettingRequest($rootScope.authData.sessionId, params, function (requestDetails) {
                        if (requestDetails.successful) {
                            if ($scope.settingsToCreate.numberOfFinishedRequests === $scope.settingsToCreate.numberOfRequests) {
                                $scope.settingsToCreate.currentlyLoading = false;
                                $scope.settings.currentlyLoading = true;
                                getSettings();
                            }
                        } else {
                            // TODO error notification
                        }
                    })
                });
            }

            function sendCreateSettingRequest(sessionId, params, callback) {
                let requestDetails = {
                    successful: true,
                    response: undefined
                };

                requestManager.routes.settings.createSettingWithKey(sessionId, params).then(
                    function () {
                        $scope.settingsToCreate.numberOfFinishedRequests += 1;
                        callback(requestDetails);
                    },
                    function (response) {
                        requestDetails.response = response;
                        requestDetails.successful = false;
                        callback(requestDetails);
                    }
                )
            }

            function checkIfSettingsExist() {
                if ($scope.brandingSettings.isBrandingActive === undefined) {
                    $scope.settingsToCreate.numberOfRequests += 1;
                    $scope.settingsToCreate.content.push('isBrandingActive');
                }
                if ($scope.brandingSettings.brandingLogo === undefined) {
                    $scope.settingsToCreate.numberOfRequests += 1;
                    $scope.settingsToCreate.content.push('brandingLogo');
                }
                if ($scope.brandingSettings.brandingWallpaper === undefined) {
                    $scope.settingsToCreate.numberOfRequests += 1;
                    $scope.settingsToCreate.content.push('brandingWallpaper');
                }
                if ($scope.brandingSettings.brandingPrimaryColor === undefined) {
                    $scope.settingsToCreate.numberOfRequests += 1;
                    $scope.settingsToCreate.content.push('brandingPrimaryColor');
                }
                // if ($scope.brandingSettings.brandingSecondaryColor === undefined) {
                //     $scope.settingsToCreate.numberOfRequests += 1;
                //     $scope.settingsToCreate.content.push('brandingSecondaryColor');
                // }
                if ($scope.brandingSettings.poweredByDebitorcloudPosition === undefined) {
                    $scope.settingsToCreate.numberOfRequests += 1;
                    $scope.settingsToCreate.content.push('poweredByDebitorcloudPosition');
                }
            }
        }
    ]
});

angular.module('clientSettings', []);

angular.module('clientSettings').component('clientSettings', {
    templateUrl: 'app/routes/clientSettings/clientSettings.template.html',
    controller: [
        "$scope",
        "$rootScope",
        "requestManager",
        "util",
        "Notification",
        "$window",
        function ($scope, $rootScope, requestManager, util, Notification, $window) {
            this.$onInit = function () {
                initVariables();
                getAreaOfResponsibilities();

                initScope();
                getSettings();
            }

            function initVariables() {
                $scope.allSettings = {
                    content: undefined,
                    currentlyLoading: true,
                    error: false
                };
                $scope.areaOfResponsibilities = {
                    content: undefined,
                    currentlyLoading: true,
                    error: false,
                    errorMessage: undefined,
                };
                $scope.settings = {
                    syncCustomerAdvisorFromMasterData: undefined,
                    syncCustomerAdvisorFromResponsibilityType: undefined,
                    syncPartnerFromResponsibilityType: undefined,
                }
                $scope.settingsToCreate = {
                    content: [],
                    numberOfRequests: 0,
                    numberOfFinishedRequests: 0,
                    currentlyLoading: false
                };
                $scope.onSaveLoading = false;
            }

            function initScope() {
                $scope.saveChanges = saveChanges;
            }

            function saveChanges() {
                $scope.onSaveLoading = true;
                $scope.totalRequests = Object.values($scope.settings).length;
                $scope.finishedRequests = 0;
                angular.forEach($scope.settings, setting => {
                    requestManager.routes.settings.editSettingWithKey($rootScope.authData.sessionId, setting).then(
                        function () {
                            $scope.finishedRequests += 1;
                            if ($scope.totalRequests === $scope.finishedRequests) {
                                Notification.success("Erfolgreich");
                                $scope.onSaveLoading = false;
                            }
                        },
                        function () {
                            Notification.error("Error");
                            $window.location.reload();
                            $scope.onSaveLoading = false;
                        }
                    )
                });
            }

            function getAreaOfResponsibilities() {
                $scope.areaOfResponsibilities.currentlyLoading = true;
                requestManager.routes.masterData.getAreaOfResponsibilities($rootScope.authData.sessionId).then(
                    function (response) {
                        $scope.areaOfResponsibilities.content = response.data;
                        $scope.areaOfResponsibilities.currentlyLoading = false;
                    },
                    function () {
                        $scope.areaOfResponsibilities.currentlyLoading = false;
                        $scope.areaOfResponsibilities.error = true;
                    }
                );
            }

            function getSettings() {
                $scope.allSettings.currentlyLoading = true;
                requestManager.routes.settings.getSettings($rootScope.authData.sessionId).then(
                    function (response) {
                        $scope.allSettings.content = response.data;
                        $scope.settings.syncCustomerAdvisorFromMasterData = util.getSettingByName($scope.allSettings.content, "syncCustomerAdvisorFromMasterData");
                        $scope.settings.syncCustomerAdvisorFromResponsibilityType = util.getSettingByName($scope.allSettings.content, "syncCustomerAdvisorFromResponsibilityType");
                        $scope.settings.syncPartnerFromResponsibilityType = util.getSettingByName($scope.allSettings.content, "syncPartnerFromResponsibilityType");
                        checkIfSettingsExist();
                        if ($scope.settingsToCreate.numberOfRequests !== 0 && $scope.settingsToCreate.numberOfRequests !== $scope.settingsToCreate.numberOfFinishedRequests) {
                            $scope.settingsToCreate.currentlyLoading = true;
                            createSettings();
                        }
                        $scope.allSettings.currentlyLoading = false;
                    },
                    function () {
                        $scope.allSettings.currentlyLoading = false;
                        $scope.allSettings.error = true;
                    }
                );
            }

            function createSettings() {
                $scope.settingsToCreate.content.forEach(setting => {
                    const params = {
                        settingCode: setting
                    }
                    sendCreateSettingRequest($rootScope.authData.sessionId, params, function (requestDetails) {
                        if (requestDetails.successful) {
                            if ($scope.settingsToCreate.numberOfFinishedRequests === $scope.settingsToCreate.numberOfRequests) {
                                $scope.settingsToCreate.currentlyLoading = false;
                                $scope.allSettings.currentlyLoading = true;
                                getSettings();
                            }
                        } else {
                            // TODO error notification
                        }
                    })
                });
            }

            function sendCreateSettingRequest(sessionId, params, callback) {
                let requestDetails = {
                    successful: true,
                    response: undefined
                };

                requestManager.routes.settings.createSettingWithKey(sessionId, params).then(
                    function () {
                        $scope.settingsToCreate.numberOfFinishedRequests += 1;
                        callback(requestDetails);
                    },
                    function (response) {
                        requestDetails.response = response;
                        requestDetails.successful = false;
                        callback(requestDetails);
                    }
                )
            }

            function checkIfSettingsExist() {
                if ($scope.settings.syncCustomerAdvisorFromMasterData === undefined) {
                    $scope.settingsToCreate.numberOfRequests += 1;
                    $scope.settingsToCreate.content.push('syncCustomerAdvisorFromMasterData');
                }
                if ($scope.settings.syncCustomerAdvisorFromResponsibilityType === undefined) {
                    $scope.settingsToCreate.numberOfRequests += 1;
                    $scope.settingsToCreate.content.push('syncCustomerAdvisorFromResponsibilityType');
                }
                if ($scope.settings.syncPartnerFromResponsibilityType === undefined) {
                    $scope.settingsToCreate.numberOfRequests += 1;
                    $scope.settingsToCreate.content.push('syncPartnerFromResponsibilityType');
                }
            }
        }
    ]
});

angular.module("app").constant("EMAIL_REPLACE_TAGS", [
    {key: "SENDING_USER", value: "[sendingUser]"},
    {key: "SUM_OUTSTANDING_INVOICES", value: "[sumOutstandingInvoices]"},
    {key: "SUM_INTEREST", value: "[sumInterest]"},
    {key: "PLUS_X_DAYS", value: "[+Xdays]"},
    {key: "LAST_BOOKING_DATE", value: "[lastBookingDate]"},
    // {key: "CUSTOMER_NAME", value: "[customerName]"},
    {key: "CUSTOMER_FULL_NAME", value: "[customerName]"},
    // {key: "CUSTOMER_SURNAME", value: "[customerSurname]"},
    {key: "CUSTOMER_ADDRESS", value: "[customerAddress]"},
    {key: "CUSTOMER_EMAIL", value: "[customerEmail]"},
    {key: "CUSTOMER_PHONE", value: "[customerPhone]"},
    {key: "COMPANY_PHONE", value: "[companyPhone]"},
    {key: "SENDING_USER_EMAIL", value: "[sendingUserEmail]"},
    {key: "CUSTOMER_ID", value: "[customerId]"},
    {key: "OPEN_ITEM_NUMBERS", value: "[openItemNumbers]"},
    {key: "PAYMENT_TERM_REFERENCE", value: "[paymentTermReference]"},
    {key: "SUM_OVERDUE_ITEMS", value: "[sumOverdueItems]"},
    {key: "INDIVIDUAL_FIELD_X", value: "[individualFieldX]"}
]);

angular.module("app").filter('replaceEmptyString', function() {
    return function(input, replaceBy) {
        if(input === "" || input === null || input === undefined ){
            return replaceBy;
        }
        else {
            return input;
        }
    };
});

angular.module("app").filter('getCustomerAdvisor', function() {
    return function(input, allUsers) {
        if (input !== '' && input !== null) {
            let customerAdvisor = allUsers.find((user) => {
                if (user.id === input) {
                    return user;
                }
            });
            if (customerAdvisor) {
                return customerAdvisor.name + ' ' + customerAdvisor.surname;
            }
        } else {
            return input;
        }
    };
});

angular.module("app").filter('replaceDefaultDate', function() {
    return function(input, replaceBy) {
        if(input === "01.01.1970" || input === null || typeof input === "object"){
            return replaceBy;
        }
        else {
            return input;
        }
    };
});

angular.module('app').filter('htmlToPlainText', function() {
        return function(text) {
            return  text ? String(text).replace(/<[^>]+>/gm, '') : '';
        };
    }
);

angular.module('app').filter('trim', function() {
        return function(text, length,  endString) {
            if(text !== undefined && text.length > length){
                return  text.substring(0, length) + endString;
            }
            else{
                return  text;
            }
        };
    }
);
angular.module('app').filter('checkMark', function() {
        return function(text) {
            if (text == "-1") {
                return '\u002D'
            }
            if(text == "0"){
                return '\u2718';
            }
            else if(!text){
              return '\u2718';
            }
            else if(text == "1"){
                return '\u2713';
            }

        };
    }
);
angular.module('app').filter('propsFilter', function() {
    return function(items, props) {
        var out = [];

        if (angular.isArray(items)) {
            var keys = Object.keys(props);

            items.forEach(function(item) {
                var itemMatches = false;

                for (var i = 0; i < keys.length; i++) {
                    var prop = keys[i];
                    var text = props[prop].toLowerCase();
                    if (item[prop] && item[prop].toString().toLowerCase().indexOf(text) !== -1) {
                        itemMatches = true;
                        break;
                    }
                }

                if (itemMatches) {
                    out.push(item);
                }
            });
        } else {
            // Let the output be the input untouched
            out = items;
        }

        return out;
    };
});

angular.module('app').filter('keyNameFilter', ["$filter", function($filter) {
    return function(item) {
        switch (item) {
            case "isProblematic":
                return $filter("translate")("filter.IS_PROBLEMATIC");
            case "amountMails":
                return $filter("translate")("filter.AMOUNT_MAILS");
            case "amountCalls":
                return $filter("translate")("filter.AMOUNT_CALLS");
            case "amountInterest":
                return $filter("translate")("filter.AMOUNT_INTEREST");
            case "amountDebts":
                return $filter("translate")("filter.AMOUNT_DEBTS");
            case "oldestInvoice":
                return $filter("translate")("filter.OLDEST_INVOICE");
            case "lastTimeContacted":
                return $filter("translate")("filter.LAST_TIME_CONTACTED");
            case "preferredContactMethod":
                return $filter("translate")("filter.PREFERRED_CONTACT_METHOD");
            case "exceedsCreditLimit":
                return $filter("translate")("workflowCreator.EXCEEDS_CREDIT_LLMIT");
            case "amountPendingCallJobs":
                return $filter("translate")("workflowCreator.AMOUNT_PENDING_CALLJOBS");
            case "paymentTermAppointmentsOverdueInDays":
                return $filter("translate")("workflowCreator.PAYMENT_TERM_APPOINTMENTS_OVERDUE_IN_DAYS");
            case "amountDaysSinceLastSuccessfullCall":
                return $filter("translate")("workflowCreator.AMOUNT_DAYS_SINCE_LAST_SUCCESSFULL_CALL");
            case "individualField1":
                return $filter("translate")("workflowCreator.INDIVIDUAL_FIELD_X") + '1';
            case "individualField2":
                return $filter("translate")("workflowCreator.INDIVIDUAL_FIELD_X") + '2';
            case "individualField3":
                return $filter("translate")("workflowCreator.INDIVIDUAL_FIELD_X") + '3';
            case "individualField4":
                return $filter("translate")("workflowCreator.INDIVIDUAL_FIELD_X") + '4';
            case "individualField5":
                return $filter("translate")("workflowCreator.INDIVIDUAL_FIELD_X") + '5';
            case "individualField6":
                return $filter("translate")("workflowCreator.INDIVIDUAL_FIELD_X") + '6';
            case "individualField7":
                return $filter("translate")("workflowCreator.INDIVIDUAL_FIELD_X") + '7';
            case "individualField8":
                return $filter("translate")("workflowCreator.INDIVIDUAL_FIELD_X") + '8';
            case "individualField9":
                return $filter("translate")("workflowCreator.INDIVIDUAL_FIELD_X") + '9';
            case "individualField10":
                return $filter("translate")("workflowCreator.INDIVIDUAL_FIELD_X") + '10';
        }
    };
}]);

angular.module('app').filter('boolToText', ["$filter", function($filter) {
    return function(item) {
        if(typeof(item) == "number"){
            if(item == 1){
                return $filter("translate")("filter.YES");
            }
            else if(item == 0){
                return $filter("translate")("filter.NO");
            }
            else{
                return undefined;
            }
        }

        if(typeof(item) == "string"){
            if(item == "1"){
                return $filter("translate")("filter.YES");
            }
            else if(item == "0"){
                return $filter("translate")("filter.NO");
            }
            else{
                return undefined;
            }
        }

        if(typeof(item) == "boolean"){
            if(item){
                return $filter("translate")("filter.YES");
            }
            else{
                return $filter("translate")("filter.NO");
            }
        }
    };
}]);

angular.module('app').filter('customDateFilter', function(){
    return function(dateString, inputDateFormat, outputDateFormat) {
        if(angular.isUndefined(inputDateFormat)){
            inputDateFormat = "YYYY-MM-DD HH:mm:ss";
        }
        if(angular.isUndefined(outputDateFormat)){
            outputDateFormat = "DD.MM.YYYY";
        }
        // Testing data
        // if (dateString === '2023-10-11 10:04:56') {
        //     const utcTime = moment(dateString, inputDateFormat).subtract(new Date().getTimezoneOffset() + 120, 'minutes').toDate();
        //     console.log(utcTime);
        //     console.log(utcTime.getDate() + '.' + (utcTime.getMonth() + 1) + '.' + utcTime.getFullYear() + ', ' + utcTime.getHours().toString().padStart(2, '0') + ':' + utcTime.getMinutes().toString().padStart(2, '0'));
        //     console.log(navigator.language, Intl.DateTimeFormat().resolvedOptions().timeZone);
        // }

        let date = moment(dateString, inputDateFormat).subtract(new Date().getTimezoneOffset() + 120, 'minutes').toDate();
        // let localDate = date.toLocaleString();
        if (outputDateFormat === "DD.MM.YYYY") {
            return date.getDate().toString().padStart(2, '0') + '.' + (date.getMonth() + 1).toString().padStart(2, '0') + '.' + date.getFullYear();
        } else {
            return date.getDate().toString().padStart(2, '0') + '.' + (date.getMonth() + 1).toString().padStart(2, '0') + '.' + date.getFullYear()
                + ', ' + date.getHours().toString().padStart(2, '0') + ':' + date.getMinutes().toString().padStart(2, '0');
        }
    };
});

angular.module('app').filter('customDateFilter2', function(){
    return function(dateString, inputDateFormat, outputDateFormat) {
        if(angular.isUndefined(inputDateFormat)){
            inputDateFormat = "YYYY-MM-DD HH:mm:ss";
        }
        if(angular.isUndefined(outputDateFormat)){
            outputDateFormat = "DD.MM.YYYY";
        }

        let date = moment(dateString, inputDateFormat).toDate();
        if (outputDateFormat === "DD.MM.YYYY") {
            return date.getDate().toString().padStart(2, '0') + '.' + (date.getMonth() + 1).toString().padStart(2, '0') + '.' + date.getFullYear();
        } else {
            return date.getDate().toString().padStart(2, '0') + '.' + (date.getMonth() + 1).toString().padStart(2, '0') + '.' + date.getFullYear()
                + ', ' + date.getHours().toString().padStart(2, '0') + ':' + date.getMinutes().toString().padStart(2, '0');
        }
    };
});

angular.module('app').filter('customDateFilterNotUTC', function(){
    return function(dateString, inputDateFormat, outputDateFormat) {
        if(angular.isUndefined(inputDateFormat)){
            inputDateFormat = "YYYY-MM-DD HH:mm:ss";
        }
        if(angular.isUndefined(outputDateFormat)){
            outputDateFormat = "DD.MM.YYYY";
        }
        return moment(dateString, inputDateFormat).format(outputDateFormat);
    };
});

angular.module('app').filter('accountLengthImpersonal', function(){
    return function(customerId, length) {
        if (length === undefined || isNaN(length) || length < 4) {
            return customerId;
        }
        return ('' + customerId).substring(0, length + 1);
    };
});

angular.module('app').filter('emailStatus', function(){
    return function(item) {
        switch (item) {
            case "unknown":
                return 'unbekannt';
            case "queued":
                return "in Warteschlange";
            case "sent":
                return "geschickt";
            case "opened":
                return "geöffnet";
            case "clicked":
                return "geklickt";
            case "bounce":
                return "Bounce";
            case "spam":
                return "Spam";
            case "unsub":
                return "abgemeldet";
            case "blocked":
                return "blockiert";
            case "hardbounced":
                return "hart zurückgewiesen";
            case "softbounced":
                return "weich zurückgewiesen";
            case "deferred":
                return "verzögert";
            default:
                return item;
        }
    };
});

var translations = {};

translations.de = {
    loginPanel: {
        EMAIL: "E-Mail-Adresse",
        PASSWORD: "Passwort",
        REMEMBER_ME: "Angemeldet bleiben",
        SIGN_IN: "Einloggen",
        FORGOT_PASSWORD: "Passwort vergessen",
        REGISTER: "Registrieren",
        WELCOME_TO_DEBITORCLOUD: "Willkommen bei Debitorcloud!",
        LOGIN_ERROR_MSG: "Benutzername oder Passwort falsch!",
        LOGIN_SUCCESSFUL_MSG: "Login erfolgreich!",
        RESET_PASSWORD_TEXT: "Um ein neues Passwort anzufordern, gib deine E-Mail-Adresse ein und" +
        " klicke auf Passwort zurücksetzen",
        RESET_PASSWORD: "Passwort zurücksetzen",
        RESET_PASSWORD_SUCCESSFUL_MSG: "Die Anfrage wurde erfolgreich gesendet",
        RESET_PASSWORD_REQUEST_SENT: "Wenn die E-Mail-Adresse bereits im System existiert, erhalten Sie eine E-Mail mit einem Bestätigungslink für Ihr neues Passwort.",
        BACK_TO_LOGIN: "Zurück zum Login",
        REQUIRED: "Das ist ein Pflichtfeld",
        INVALID_FORMAT_EMAIL: "Das E-Mail Format ist nicht korrekt",
        CREDENTIALS_FALSE_MSG: "Die Anmeldedaten sind nicht korrekt",
        NEW_PASSWORD: "Neues Passwort",
        NEW_PASSWORD_CONFIRM: "Passwort bestätigen",
        SAVE: "Speichern",
        RESET_PASSWORD_DONE: "Du hast dein Passwort erfolgreich geändert."
    },
    wrapper: {
        DEBITORCLOUD: "Debitorcloud",
        DC: "DC",
        MY_PROFILE: "Mein Profil",
        DASHBOARD: "Dashboard",
        EMAIL_CENTER: "Versandzentrum",
        EMAIL_INCOMING: "Posteingang",
        EMAIL_OUTGOING: "Postausgang",
        ALL_ADMONISHED_CLIENTS: "Kundenübersicht",
        WORKFLOWS: "Workflows",
        EMAILS: "E-Mails",
        SETTINGS: "Einstellungen",
        CLIENT_MANAGEMENT: "Mandantenverwaltung",
        BRANDING: "Kanzleibranding",
        CLIENT_SETTINGS: "Kanzleieinstellungen",
        UNCLEAR_PAYMENTS: "Unklare Zahlungen",
        NOT_ASSIGNED_POSITIONS: "Nicht zugeordnete Posten",
        LOGOUT: "Ausloggen",
        CREATE_DUNNING_LETTER: "PDF-Mahnung",
        IS_FAVOURITE: "Favorit",
        IS_NOT_FAVOURITE: "Kein Favorit",
        REMOVE_FAVOURITE_SUCCESS: "Favorit entfernt",
        SET_FAVOURITE_SUCCESS: "Als Favorit markiert",
        TASKS: "Aufgaben",
        COPYRIGHT: "Copyright",
        RIECKEN_WS: "Riecken.io",
        ALL_RIGHTS_RESERVED: "Alle Rechte vorbehalten",
        PROBLEMATIC: "Problematisch",
        ASSIGNED_TO_CUSTOMER_ADVISOR: "Kundenbetreuer",
        LOADING_PDF: "PDF wird geladen... ",
        VERSION: "v 1.2.1",
        SUBJECT: "Betreff",
        CUSTOMER_ADVISOR_TASK_FINISHED: "Kundenbetreueraufgabe als bearbeitet markieren",
        SAVE: "Speichern",
        REQUIRED: "Das ist ein Pflichtfeld",
        MIN_LENGTH: "Die Mindestlänge beträgt 80 Zeichen",
        PROCESSING: "Anfrage wird bearbeitet... ",
        CUSTOMER_ADVISOR_TASK_FINISHED_MESSAGE: "Kundenbetreueraufgabe als bearbeitet markiert",
        APPROVE_COMMUNICATION: "Freigabe",
        NOTIFICATION_ERROR: "Template konnte nicht geladen werden. Füge unter Einstellungen > PDF-Einstellungen eine Vorlage ein.",
        ANALYSIS: "Auswertungen",
        SUPPORT_TICKET: "Support",
        PROFILE: "Profil",
        ACTUALIZE_ECONOMIC_YEAR: "Wirtschaftsjahr aktualisieren",
        NEW_ECONOMIC_YEAR: "Neues Wirtschaftsjahr",
        START_ECONOMICYEAR_ACTUALIZATION: "Los geht's!",
        BACK_TO_FILTERED_RESULTS: "ZURÜCK ZU DEN SUCHERGEBNISSEN",
    },
    prognosticatedPayments: {
        FROM_BEHAVIOR: "Aus Zahlungsverhalten",
        TOTAL: "Gesamt",
        FROM_AGREEMENTS: "Aus Zahlungsvereinbarungen",
        PROGNOSTICATED_INCOME: "Prognostiziertes Einkommen",
        NUMBER_OF_EMAILS_AND_POSTAL_ITEMS_TO_BE_APPROVED: "Anzahl der freizugebenden Mahnungen",
        NUMBER_OF_OPEN_TASKS: "Alle unerledigten Aufgaben",
        ALL_VACANT: "Alle überfälligen offenen Posten",
        CALCULATING: "Wert wird berechnet...",
        FROM_PAYMENTTERMS: "Davon aus Zahlungsvereinbarungen: ",
        SIXTY_DAYS: "> 60 Tage",
        THIRTY_DAYS: "> 30 Tage",
        NINETY_DAYS: "> 90 Tage",
        WITH_OVERDUE: "Davon überfällig",
        OVERDUE_UNTIL_END_MONTH: "Alle offenen Posten",
        DSO_TODAY: "Debitorenlaufzeit aktuell",
        OVERDUE_UNTIL_END_MONTH_INFO: "Summe der Posten, die noch offen sind. ",
        PROGNOSTICATED_INCOME_INFO: "",
        ALL_VACANT_INFO: "Summe der offenen Posten, die bereits überfällig sind.",
        NUMBER_OF_OPEN_TASKS: "Alle unerledigten Aufgaben",
        OVERDUE_MORE_THAN_30: "bis 30 Tage überfällig",
        OVERDUE_MORE_THAN_60: "31-60 Tage überfällig",
        OVERDUE_MORE_THAN_90: "Mehr als 60 Tage überfällig",
        OVERDUE_MORE_THAN_30_INFO: "Gesamtsumme der Forderungen, die das Fälligkeitsdatum mehr als 30 Tage überschritten haben. ",
        OVERDUE_MORE_THAN_60_INFO: "Gesamtsumme der Forderungen, die das Fälligkeitsdatum mehr als 60 Tage überschritten haben. ",
        OVERDUE_MORE_THAN_90_INFO: "Gesamtsumme der Forderungen, die das Fälligkeitsdatum mehr als 90 Tage überschritten haben. ",
    },

    problematicClients: {
        PROBLEMATIC_CLIENTS: "Problematische Kunden",
        CUSTOMER_NR: "Kundennr.",
        CUSTOMER: "Kunde",
        AMOUNT: "Betrag",
        OLDEST_INVOICE: "Älteste Rechnung",
        OPEN: "Öffnen",
        LOADING: "Wird geladen...",
        NO_PROBLEMATIC_CLIENTS: "Keine problematischen Kunden vorhanden",
        PAGE: "Seite",
        OF: "von",
        INFO_MODAL_BODY: 'In diesem Bereich siehst du Kunden, die im Kundendashboard als problematisch markiert wurden. ' +
        'Du kannst den Status im Kundendashboard unter Aktionen > Problematischer Kunde ändern.',
        INFO_MODAL_TITLE: "Problematische Kunden"
    },
    favouriteClients: {
        FAVOURITE_CLIENTS: "Deine Favoriten",
        CUSTOMER_NR: "Kundennr.",
        CUSTOMER_NAME: "Kundenname",
        CUSTOMER_EMAIL: "E-Mail",
        CUSTOMER_IS_PROBLEMATIC: "Problematisch",
        NO_FAVOURITE_CLIENTS: "Keine Favoriten",
        OF: "von",
        PAGE: "Seite",
        INFO_MODAL_BODY: "In diesem Bereich siehst du Kunden, die du im Kundendashboard als Favorit markiert hast. ",
        INFO_MODAL_TITLE: "Favoriten"
    },
    admonishedClientsList: {
        CUSTOMER_NUMBER: "KdNr.",
        TITLE: "Titel",
        STREET: "Straße",
        ZIP: "PLZ",
        CITY: "Ort",
        COUNTRY: "Land",
        EMAIL: "E-Mail",
        OLDEST_INVOICE: "Älteste Rechnung",
        VACANT_POS_SUM: "Summe überfälliger OP",
        LAST_ACTION: "Letzte Aktion",
        CUSTOMER_ADVISOR: "Kundenbetreuer",
        FILTER: "Filter",
        REFRESH: "Aktualisieren",
        SEARCH_TERM: "Suchbegriff",
        MIN_VACANT_POS_SUM: "Mindest OP Summe",
        MAX_VACANT_POS_SUM: "Maximale OP Summe",
        DEPRECIATION_PERIOD_MIN: "Debitorenlaufzeit minimum",
        DEPRECIATION_PERIOD_MAX: "Debitorenlaufzeit maximum",
        ACCOUNTS_RECEIVABLE_TURNOVER_MIN: "Debitorenumschlag minimum",
        ACCOUNTS_RECEIVABLE_TURNOVER_MAX: "Debitorenumschlag maximum",
        ONLY_CUSTOMER_VACANT_POS: "Nur Kunden mit offenen Posten",
        ONLY_PROBLEMATIC: "Nur problematische Kunden",
        ONLY_FAVOURITES: "Nur Favoriten",
        ONLY_CLIENTS_WITH_PAYMENT_AGREEMENT: "Nur Kunden mit Zahlungsvereinbarungen",
        ONLY_CLIENTS_WITH_UPCOMING_REMINDER: "Nur Kunden, bei denen eine Erinnerung für mich ansteht",
        NUM_ROWS: " Kunden pro Seite",
        LAST_CONTACT_SINCE: "Letzter ",
        SEND_EMAIL: "E-Mail versenden",
        SENDER: "Absender",
        TEMPLATE: "Vorlage",
        ATTACHMENTS: "Anhänge",
        ABORT: "Abbrechen",
        PAGE: "Seite",
        OF: "von",
        NAME: "Name",
        SUBJECT: "Betreff",
        NO_USER_SELECTED: "Kein Kunde ausgewählt",
        EMAIL_MOVED_TO_OUTBOX: "Nachricht(en) wurden in den Postausgang verschoben",
        NO_FILTER_RESULTS: "Keine Resultate",
        RESET_FILTER: "Filter zurücksetzen",
        EMAIL_SENT: "E-Mail(s) werden zum Versand eingereiht",
        EMAIL_NOT_SENT: "Bei der Verarbeitung der Anfrage ist es zu einem Fehler gekommen. E-Mails konnten nicht versendet werden.",
        ATTACH_REPORT: "Aktuelle Mahnung mitschicken",
        SHOW_COLUMNS: "Spalten anzeigen: ",
        SHOW_CUSTOMER_NR: "Kundennr.",
        SHOW_CUSTOMER_NAME: "Kundennamen",
        SHOW_CUSTOMER_STREET: "Straße",
        SHOW_CUSTOMER_ZIP: "Postleitzahl",
        SHOW_CUSTOMER_CITY: "Ort",
        SHOW_CUSTOMER_COUNTRY: "Land",
        SHOW_CUSTOMER_EMAIL: "E-Mail",
        SHOW_CUSTOMER_OLDEST_INVOICE: "Älteste Rechnung",
        SHOW_CUSTOMER_VACANT_POS: "Summe überfälliger OP",
        SHOW_CUSTOMER_LAST_ACTION: "Letzte Aktion",
        SHOW_CUSTOMER_ADVISOR: "Kundenbetreuer",
        SAVED_FILTER: "Filter wurde gespeichert",
        ONLY_CUSTOMER_ASSIGNED_TO_ME: "Nur mir zugewiesene Kunden",
        AMOUNT_DUNNINGS: "Anzahl Mahnungen",
        SUM_VACANT_POS_WITHOUT_OVERDUE: "Summe OP",
        BUSINESS_PARTNER_NUMBER: "Business-Partner Nr.",
        REFRESHED_PAGE: "Daten wurden aktualisiert",
        PROBLEMATIC_CLIENTS_INFO_TITLE: "Nur problematische Kunden",
        PROBLEMATIC_CLIENTS_INFO_BODY: "Blende problematische Kunden ein oder aus. Du kannst den Status 'Ist Problematisch' im Kundendashboard unter Aktionen > Problematischer Kunde ändern.",
        ASSIGNED_CUSTOMER_INFO_TITLE: "Nur mir zugewiesene Kunden",
        ASSIGNED_CUSTOMER_INFO_BODY: "Blende Kunden ein oder aus, die du als Kundenbetreuer betreust. Du kannst den Kundenbetreuer im Kundendashboard unter Aktionen > Kundenbetreuer informieren festlegen.",
        BUSINESS_PARTNER_NUMBER_INFO_TITLE: "Business-Partner-Nummer",
        BUSINESS_PARTNER_NUMBER_INFO_BODY: "",
        GENERAL_INFO_TITLE: "Einstellungen bearbeiten",
        GENERAL_INFO_BODY: "Sobald Änderungen vorgenommen werden, werden die Einstellungen gespeichert. Außerdem kannst du die Einstellungen bei den Profileinstellungen unter Mein Profil > Ansicht ändern",
        AMOUNT_DUNNINGS_INFO_TITLE: "Anzahl Mahnungen",
        AMOUNT_DUNNINGS_INFO_BODY: "Die Anzahl der Mahnungen, die der Kunde per E-Mail oder per Post erhalten hat.",
        RECIPIENTS: "Empfänger",
        HAS_PARTS_INVOICE: "hat Teile Rechnung",
        HAS_NEW_CAR_INVOICE: "hat Neuwagen Rechnung",
        HAS_USED_CAR_INVOICE: "hat Gebrauchtwagen Rechnung",
    },
    emailCenter: {
        INCOMING: "Posteingang",
        OUTGOING: "Postausgang",
        SENDER: "Von",
        RECEIVER: "An",
        SUBJECT: "Betreff",
        DATE: "Datum",
        REPLY: "Antworten",
        TEXT: "Text",
        EMAIL_OVERVIEW: "E-Mail",
        CLOSE: "Schließen",
        TYPE: "Typ",
        SEARCHTERM: "Suchbegriff",
        NO_MAIL_FOUND: "Fehler! Es wurde keine E-Mail gefunden.",
        ATTACHMENTS: "Anhänge",
        ATTACHMENT: "Anhang",
    },
    errorPage: {
        ERROR_PAGE_TITLE: "Ooops! Etwas ist schief gelaufen.",
        ERROR_PAGE_TEXT: "Wir arbeiten daran diesen Fehler zu korrigieren. " +
        "Wenn du möchtest, kannst du die Fehlernachricht an unseren Support senden" +
        " um eine genauere Fehleranalyse zu ermöglichen.",
        ERROR_DESCRIPTION: "Fehlerbeschreibung",
        SEND_MESSAGE_TO_SUPPORT_TITLE: "Support kontaktieren",
        SEND_MESSAGE_TO_SUPPORT_TEXT: "Klicke hier, um eine Nachricht an den Support zu schicken",
        RECEIVED: "Fehler entstanden am",
        STATUS_CODE: "Statuscode",
        STATUS_TEXT: "Statustext",
        RESPONSE: "Details",
        USER_TEXT: "Fehlerbeschreibung",
        CREATE_EMAIL_TEXT: "Unser Support steht dir jederzeit unter der E-Mail Adresse ",
        CREATE_EMAIL_TEXT1: " zur Verfügung. Falls du uns kontaktieren möchtest, sende den Text unterhalb mit, damit wir die Fehlerquelle schneller identifizieren können."
    },

    userDetailsActions: {
        SEND_EMAIL: "Mahnung per E-Mail",
        RECORD_CALL: "Anruf verzeichnen",
        SAVE_PAYMENT_AGREEMENT: "Zahlungsvereinbarung festlegen",
        CREATE_COMMENT: "Kommentar",
        MARK_NOT_PROBLEMATIC: "Problematischer Kunde",
        PREPARE_PDF_FOR_LAWYER: "Anwaltsübergabe",
        PREPARE_EMAIL: "Kundenbetreuer informieren",
        ACTION: "Aktionen",
        INFORM_ACCOUNT_MANAGER: "Kundenbetreuer informieren",
        TELEPHONE_CALL_NOTE: "Telefonanruf verzeichnen",
        TELEPHONE_NUMBER: "Telefonnummer",
        CLIENT_AVAILABLE: "Kunde erreicht",
        CLIENT_NOT_AVAILABLE: "Kunde nicht erreichbar",
        CLIENT_WILL_CALL_BACK: "Kunde ruft zurück",
        SAVE: "Speichern",
        REQUIRED: "Das ist ein Pflichtfeld",
        TELEPHONE_NOTE_CREATED: "Anruf wurde eingetragen",
        ABORT: "Abbrechen",
        COMMENT_CREATED: "Kommentar erstellt",
        TEMPLATE: "Vorlage",
        ATTACHMENTS: "Anhänge",
        MARKED_SUCCESSFULLY_UNPROBLEMATIC: "Kunde wurde als unproblematisch eingestuft",
        MARKED_SUCCESSFULLY_PROBLEMATIC: "Kunde wurde als problematisch eingestuft",
        EMAIL_CREATED: "E-Mail wurde in den Postausgang verschoben",
        SUBJECT: "Betreff",
        PAYMENT_AGREEMENT: "Zahlungsvereinbarung",
        UNPAID_INVOICES: "Offene Rechnungen",
        SELECT_PAYMENT_METHOD: "Zahlungsmethode auswählen",
        CREATE_APPOINTMENTS: "Termine erstellen",
        INVOICE: "Rechnungsnr.",
        DATE: "Datum",
        AMOUNT_INTEREST: "Offener Betrag",
        MONTHLY: "Monatlich",
        QUARTER: "Pro Quartal",
        HALF_YEAR: "Halbjährlich",
        YEAR: "Jährlich",
        METHOD: "Zahlungsmethode",
        TOTAL_AMOUNT_FOR_PAYMENT_AGREEMENT: "Gesamtbetrag für Zahlungsvereinbarung",
        AMOUNT_PER_MONTH: "Betrag pro Monat",
        AMOUNT_OF_MONTHS: "Anzahl von Monaten",
        NO_VACANT_POS: "Keine offenen Posten",
        NUMBER: "Nr.",
        AMOUNT: "Betrag",
        REST: "Restbetrag",
        INTEREST: "Zinsen",
        SELECT_PAYMENT_PERIOD: "Zahlungsintervall festlegen",
        PAYMENT_PERIOD: "Intervall",
        AMOUNT_PER_INTERVAL: "Betrag pro Intervall",
        AMOUNT_OF_INTERVALS: "Anzahl an Intervallen",
        CREATED_REMINDER_SUCCESSFULLY: "Erinnerung wurde erfolgreich erstellt.",
        CREATE_REMINDER: "Erinnerung erstellen",
        SELECTED_DATE: "Ausgewähltes Datum",
        CREATED_PAYMENT_AGREEMENT_SUCCESSFULLY: "Zahlungsvereinbarung wurde erfolgreich erstellt.",
        INFORM_CUSTOMER_ADVISOR: "Kundenbetreuer informieren",
        ASSIGNED_TO: "Zugewiesen an:",
        EMAIL: "E-Mail hinzufügen",
        CONFIRM_SEND_EMAIL: "Soll diese E-Mail versendet werden?",
        CREATE_NEW_CUSTOMER_ADVISOR_CASE: "Kunden zur Klärung übergeben?",
        CLOSE_CUSTOMER_ADVISOR_CASE: "Fall abschließen?",
        EMAIL_SENT: "E-Mail(s) werden zum Versand eingereiht",
        EMAIL_NOT_SENT: "E-Mail konnte nicht versendet werden",
        CONFIRM_CREATE_TEL_CALL_NOTE: "Soll das Telefonat protokolliert werden?",
        CONFIRM_CREATE_PAYMENT_AGREEMENT: "Soll diese Zahlungsvereinbarung gespeichert werden?",
        CONFIRM_CREATE_COMMENT: "Soll der Kommentar erstellt werden?",
        SEND_EMAIL_TO_CUSTOMER: "Eine Mahnung per E-Mail an den Kunden senden.",
        CREATE_TEL_NOTE: "Inhalt eines geführten Telefonats erfassen.",
        CREATE_PAYMENT_AGREEMENT: "Eine mit dem Kunden besprochene Zahlungsvereinbarung erfassen.",
        CREATE_COMMENT_TEXT: "Einen manuellen Kommentar für die Records erfassen.",
        CREATE_REMINDER_TEXT: "Richtet eine E-Mail Erinnerung für einen Benutzer ein.",
        MARK_AS_1: "Als ",
        MARK_AS_2: " markieren",
        PREPARE_FOR_LAWYER: "Bereitet ein PDF mit der Kundenhistorie für die Anwaltsübergabe vor.",
        SEND_MAIL_TO_CUSTOMER_ADVISOR: "Informiere den Kundenbetreuer per E-Mail über kundenbezogene Anliegen.",
        PROBLEMATIC: "problematisch",
        NOT_PROBLEMATIC: "nicht problematisch",
        MIN_LENGTH: "Mindestlänge: 20 Zeichen",
        ATTACH_REPORT: "Aktuelle Mahnung mitschicken",
        CREATE_REPORT: "Anwaltsübergabe erstellen ",
        RECIPIENT: "Empfänger",
        EMAIL_LIST_EMTPY: "Keine Empfänger angegeben",
        INVALID_EMAIL_FORMAT: "Falsches E-Mail Format",
        PROCESSING_REQUEST: "Anfrage wird verarbeitet...",
        SEND_ENROLLED: "Soll der Brief eingeschrieben verschickt werden?",
        YES: "Ja",
        NO: "Nein",
        PREPARED_FOR_SENDING: "Zum Versenden vorbereitet",
        PROCESSING: "Anfrage wird bearbeitet...",
        INKASSO_DEBIT_COLLECTION_AGENCY: "An Inkassobüro übergeben",
        INKASSO_DEBIT_COLLECTION_AGENCY_TEXT: "Wähle bestimmte Rechnungen aus, die an das Inkassobüro übergeben werden.",
        BACK_BUTTON: "Zurück",
        MORE_THAN_15MB: "E-Mails mit Anhängen größer 15 MB sind nicht erlaubt",
        MORE_THAN_10MB: "E-Mails mit Anhängen größer 10 MB können möglicherweise nicht beim Empfänger ankommen. Dies ist von der Konfiguration des E-Mail Servers des Empfängers abhängig.",
        NO_LOGO: "Es ist kein Logo hochgeladen, bitte lade ein Logo in den Einstellungen hoch, um ein E-Mail zu versenden."
    },

    userInformations: {
        CUSTOMER_NR: "Kundennr.",
        ADDRESS: "Adresse",
        TELEPHONE: "Tel.",
        EMAIL: "E-Mail",
        LANGUAGE: "Sprache",
        IN_SYSTEM_SINCE: "Im System seit",
        LAST_TIME_ZERO: "Letztmalig auf Null",
        CREATE_SEPA: "SEPA-Mandat B2B erstellen",
        CUSTOMER_INFORMATION: "Kundeninformationen",
        LOADING: "Wird geladen...",
        CONTACT: "Kontaktdaten",
        COMPANY_DATA: "Firmendaten",
        UID: "UID",
        DOB: "Beginn Rechtsform",
        TURNOVER: "Umsatz",
        NUM_EMPLOYEES: "Mitarbeiter",
        COMPANY_TARGET: "Unternehmensgegenstand",
        GOOGLE_MAPS: "Google Maps",
        SEACHING: "Suche nach Daten...",
        SEARCH_RESULTS: "Resultate:",
        WEBCRAWLER: "Web-Crawler",
        NO_RESULTS: "Keine Resultate gefunden",
        COMPANY_REGISTRATION_NR: "Firmenbuchnr.",
        FAX: "Fax",
        WEB: "Homepage",
        SEND_VACANT_POS_LIST: "Kontoauszug verschicken",
        SEND_DUNNING_NOTE: "Mahnung verschicken",
        AUTOMATIC_DUNNING: "In Workflows berücksichtigen",
        SUCCESSFULLY_CHANGED: "Erfolgreich geändert",
        AUTOMATIC_DUNNING_INFO_TITLE: "In Workflows berücksichtigen",
        AUTOMATIC_DUNNING_INFO_BODY: "Eine automatische Mahnung wird nur dann versendet, wenn es einen Workflow gibt, der auf diesen Kunden zutrifft.",
        DUNNING_INFO_TITLE: "PDF-Mahnung anhängen",
        DUNNING_INFO_BODY: "Wenn aktiviert, erhält der Kunde bei den E-Mail- und Post-Workflows automatisch eine Mahnung als PDF im Anhang.",
        OP_LIST_INFO: "Kontoauszug anhängen",
        OP_LIST_OPEN_INFO: "Kontoauszug (offen) anhängen",
        OP_LIST_BODY: "Wenn aktiviert, erhält der Kunde bei den E-Mail-Workflows automatisch einen Kontoauszug als PDF im Anhang. Warnung: Bei Postsendungen werden derzeit nur Mahnungen unterstützt.",
        EMAIL_CONTACT_INFO: "E-Mail-Adresse aktivieren",
        EMAIL_CONTACT_INFO_BODY: "Sind für einen Kunden mehrere Adressen hinterlegt, kannst du mit diesem Schalter die bevorzugte Adresse aktivieren.",
        PHONE_CONTACT_INFO: "Telefonnummer aktivieren",
        PHONE_CONTACT_INFO_BODY: "Sind für einen Kunden mehrere Telefonnummern hinterlegt, kannst du mit diesem Schalter die bevorzugte Nummer aktivieren.",
        STATEMENT:'Statement',
        BUSINESS_PARTNER_INACTIVE_INFO_BODY: "Dieser Geschäftspartner ist in der DATEV Buchhaltung deaktiviert und kann aufgrund deiner Einstellung nicht wieder aktiviert werden. Um den Geschäftspartner wieder zu aktivieren, aktiviere ihn bitte in DATEV."
    },

    userRecords: {
        USER_RECORDS: "Kundenkommunikation",
        SEARCH_TERM: "Suchbegriff",
        TYPE: "Typ",
        RECORD: "Record",
        TIME: "Datum/Zeit",
        USER: "Benutzer",
        CREATED_BY: "Erstellt von",
        TEXT: "Text",
        RECORD_COMMENT: "Kommentar",
        CLOSE: "Schließen",
        DELETE: "Löschen",
        RECORD_PHONE: "Telefonanruf",
        TELEPHONE_NUMBER: "Telefonnummer",
        RECORD_EMAIL: "E-Mail",
        SUBJECT: "Betreff",
        LOADING: "Wird geladen...",
        LOADING_MESSAGE: "Bitte um ein wenig Geduld. Das Einlesen der Daten dauert noch etwas länger, da dieser Kunde sehr viele Einträge hat.",
        NO_RECORDS: "Keine Kundenkommunikation",
        SENT: "Versendet",
        SMTP: "SMTP",
        DEMAND_NOTE_SENT: "Mahnung verschickt",
        REGISTERED: "Eingeschrieben",
        RECORD_PHYSICAL_MAIL: "Mahnbrief",
        FROM: "Von ",
        RECEIVED: "Erhalten am",
        TO: "An",
        RECORD_EMAIL_REPLY: "Antwort-Mail",
        RECORD_CUSTOMER_ADVISOR: "Kundenberater",
        SUCCESS: "Kunden erreicht: ",
        PASSED_TO_DEBT_COLLECTION_AGENCY: "Übergabe an Inkassounternehmen",
        DESCRIPTION: "Beschreibung",
        RECORD_INKASSO: "Übergabe an Inkassobüro",
        ADMONITION: "Zur Mahnung",
        PRICE: "Preis",
        APPROVED: "Freigegeben am:",
        NOT_REACHED: "Kunde nicht erreicht",
        REACHED: "Kunde erreicht",
        NO_RESULTS: "Keine Resultate gefunden",
        UNAPPROVED : "Nicht freigegeben"
    },

    settings: {
        GENERAL_SETTINGS: "Grundeinstellungen",
        PDF_SETTINGS: "PDF-Einstellungen",
        SYSTEM_SETTINGS: "Systemeinstellungen",
        ACCOUNTING_SETTINGS: "Buchhaltungseinstellungen",
        IMPORT_SETTINGS: "Importeinstellungen",
        TEXT_AND_TRANSLATIONS_SETTINGS: "Textblöcke und Übersetzungen",
        USER_SETTINGS: "Benutzer",
        IMPORT: "Daten importieren",
        OCR_STATUS: "OCR-Status",
        MODULE_SETTINGS: "Zusatzmodule",
        EMAIL_TEMPLATES: "E-Mail Vorlagen",
        EMAIL_SETTINGS: "E-Mail Einstellungen",
        INKASSO_SETTINGS: "Inkasso Einstellungen",
        LOADING: "Wird geladen..."
    },
    generalSettings: {
        COMPANY_INFORMATION: "Firmenstammdaten",
        GENERAL_SETTINGS_TEXT: "Gib hier deine Firmenstammdaten ein. " +
        "Diese sind wichtig, da die auf der Mahnung ausgedruckten Stammdaten aus diesen Werten bezogen werden.",
        COMPANY_NAME: "Firmenname",
        ADDRESS: "Adresse",
        ZIP: "PLZ",
        CITY: "Ort",
        COUNTRY: "Land",
        PHONE: "Tel.",
        EMAIL: "E-Mail Adresse",
        REQUIRED: "Das ist ein Pflichtfeld",
        INVALID_FORMAT_COUNTRY_CODE: "Falsches Format. Nur zweistellige Ländercodes erlaubt. Beispiele: AT, DE, FR, ...",
        INVALID_FORMAT_EMAIL: "Falsches Format",
        SAVE_CHANGES: "Änderungen speichern",
        SAVE_CHANGES_CONFIRM: "Sollen die Änderungen gespeichert werden? Die Änderungen werden auf allen Formularen, E-Mail-Templates und Mahnungen sichtbar sein.",
        SAVE_SUCCESSFUL: "Änderungen gespeichert.",
        SAVE_NOT_SUCCESSFUL: "Änderungen konnten nicht gespeichert werden",
        COMPANY_LOGO: "Logo",
        EMAIL_FOR_INVOICES: "E-Mail für Rechnungen",
        COMPANY_UID: "UID-Nummer",
        ADVANCED_SETTINGS: "Erweiterter Modus",
    },
    pdfSettings: {
        PDF_SETTINGS: "Mahnungslayout Einstellungen",
        PDF_SETTINGS_TEXT: "Hier kannst du Einstellungen am Druck der Mahnungen vornehmen",
        CHOOSE_PAPER_LAYOUT: "Briefpapier für Mahnungen PDF",
        PRINT_TYPE: "Druck Mahnungen",
        PADDING_BOTTOM: "Seitenabstand unten",
        PADDING_TOP: "Seitenabstand oben",
        PADDING_LEFT: "Seitenabstand links",
        PADDING_RIGHT: "Seitenabstand rechts",
        PADDING_TOP_EXTRA_PAGE: "Seitenabstand oben Extraseite",
        WITH_DETAILS: "Mahnungen mit Detailseite ausdrucken",
        WITHOUT_DETAILS: "Mahnungen ohne Detailseite ausdrucken",
        REQUIRED: "Das ist ein Pflichtfeld",
        CONFIRM_SAVE: "Sollen die Einstellungen beibehalten werden?",
        CHANGED_SUCCESSFULLY: "Erfolgreich gespeichert",
        CHANGED_ERROR: "Einstellungen konnten nicht gespeichert werden",
        NON_OVERDUE_OP_NEW_PAGE: "Liste aller offenen, nicht überfälligen Rechnungen auf extra Seite drucken",
        NON_OVERDUE_OP_SHOW: "Nicht überfällige, aber offene Posten auf Mahnungen ausweisen",
        CALCULATE_INTEREST_ON_LAWYER_REPORT: "Zinskalkulation in Anwaltsübergabe durchführen",
        UPLOAD_NOT_POSSIBLE: "Upload nicht möglich. Die maximale Dateigröße beträgt 1MB"
    },
    systemSettings: {
        SYSTEM_SETTINGS: "Systemeinstellungen",
        SYSTEM_SETTINGS_TEXT: "Hier kannst du generelle Einstellungen am System vornehmen.",
        NUM_DAYS: "Anzahl an Tagen, die ein Zahlungstermin für eine Zahlungsvereinbarung überfällig sein darf," +
        " bevor dieser als nicht eingehalten gilt	",
        EXPENSES_IN_EURO: "Mahnspesen in €",
        EXPENSES_IN_PERCENT: "Mahnzinsen in %",
        EXPENSES: "Mahnzinsen",
        PERCENT: "Prozent",
        SAVE_CHANGES: "Änderungen speichern",
        SAVE_CHANGES_CONFIRM: "Sollen die Änderungen gespeichert werden? Die Änderungen werden auf allen Formularen, E-Mail-Templates und Mahnungen sichtbar sein.",
        SAVE_SUCCESSFUL: "Änderungen gespeichert.",
        SAVE_NOT_SUCCESSFUL: "Änderungen konnten nicht gespeichert werden",
        DUNNING_CHARGES: "Mahnspesen in €",
        DUNNING_INTEREST: "Mahnzinsen in %",
        DUNNDING_LEVEL_ONE: "1. Mahnstufe",
        DUNNDING_LEVEL_TWO: "2. Mahnstufe",
        DUNNDING_LEVEL_THREE: "3. Mahnstufe",
        REQUIRED: "Das ist ein Pflichtfeld",
        APPROVE_MAIL: "Manuelle Freigabe für E-Mails und Postendungen aus Workflows erforderlich",
        APPROVE_MAIL_TEXT: "Wenn diese Funktion aktiviert ist, landen alle durch Workflows erstellten E-Mails und Postsendungen zuerst in der Freigabeliste und müssen dort von einem autorisierten Benutzer freigeben werden, bevor sie verschickt werden. Wenn diese Funktion deaktiviert ist, gehen alle Sendung direkt bei der Workflow- Ausführung raus.",
        DIRECT_MAIL_APPROVE: "E-Mail Versand ohne zusätzliche Freigabe",
        DIRECT_MAIL_APPROVE_TEXT: "Wenn diese Funktion aktiviert ist, werden E-Mails, die über die Aktion \"Mahnung per E-Mail\" über die Kundenansicht erstellet wurden, ohne zusätzlichen Freigabeschritt verschickt. Ist diese Funktion deaktiviert, landen E-Mails zuerst in der Freigabeliste.",
        AUTHORIZED_USER: "Freigabeberechtigte User:",
        REMOVE: "entfernen",
        ADD: "hinzufügen",
        ADMIN: "admin",
        DEACTIVATE: "E-Mail und Postfreigabe deaktiviert",
        ACTIVATE: "E-Mail und Postfreigabe aktiviert",
        PAYMENT_AGREEMENT_INTEREST_RATE: "Zinssatz bei Zahlungsvereinbarungen",
        UCLEARED_PAYMENTS_AS_POSTIONS_LIST: "Unzugeordnete Gutschriften in Einzelpositionen andrucken",
        AMOUNT_REMINDERS_ON_DEMAND_NOTE: "Posten-Mahnstufe andrucken",
        ACCOUNT_LENGTH_IMPERSONAL: "Sachkontenlänge",
        CURRENCY_CODE: "Währungskennzeichen auf Mahnung andrucken",
        EUROPEAN_NUMBER_FORMAT: "Europäisches Zahlenformat",
        REASON_FOR_PAUSE_OF_DEMANDS: "Bei Forderungspausierung einen Grund abfragen",
        WYSIWYG_EDITOR: "Allgemeines Feld mit Schriftgröße und Schriftart auswählen",
        BOOK_INSTALLMENT_AGREEMENT_IN_DATEV: "Ratenvereinbarung in DATEV buchen",
        BOOK_DUE_DATE_CHANGES_IN_DATEV: "Änderung von Fälligkeiten in DATEV buchen",
        BOOK_DUE_DATE_CHANGES_IN_DATEV_INFO: "Wenn diese Option aktiviert ist, wird eine Rechnung bei Änderung dessen Fälligkeit, in DATEV mittels Generalumkehr aus- und wieder mit dem neuen Fälligkeitsdatum eingebucht.",
        ACCOUNT_FOR_BOOKINGS: "Konto für Buchungen",
        ASSIGN_UNCLEAR_PAYMENTS_VIA_COLLECTIVE_ACCOUNT: "Unzugeordnete Zahlungen über Sammelkonto zuordnen",
        ASSIGN_UNCLEAR_PAYMENTS_VIA_COLLECTIVE_ACCOUNT_INFO: "Wenn dieses Feature aktiviert ist, kann ein Sammelkonto angegeben werden. " +
            "Alle Zahlungsbuchungen, die auf dieses Konto gebucht werden, werden in der Debitorcloud angezeigt und können in der Debitorcloud " +
            "dann einem Debitoren und dort ggf. einer Rechnungsnummer zugeordnet werden. Bei Zuordnung wird eine Buchung in DATEV ausgelöst.",
        TRANSFER_OF_UNALLOCATED_CREDITS: "Umbuchung von Unzugeordneten Gutschriften",
        TRANSFER_OF_UNALLOCATED_CREDITS_INFO: "Wenn dieses Feature aktiv ist, können die Anwender unzugeordnete Gutschriften auf einem Kundenkonto einer Rechnung zuteilen oder auf ein anderes Kundenkonto umbuchen. Diese Umbuchung wird in DATEV gebucht.",
    },

    clientManagement: {
        EXISTING_CLIENTS: "Bestehende Mandanten",
        SELECT_CLIENT: "Mandanten hinzufügen",
        COMPANY_NAME: "Firmenname",
        COMPANY_VAT: "UID-Nummer",
        COMPANY_PHONE: "Telefonnummer",
        COMPANY_MAIL: "E-Mail Adresse",
        INSTANCE: "Instanz",
        NO_EXISTING_CLIENTS: "Keine bestehende Mandanten",
        SEARCH: "Suche",
        ID: "Id",
        NAME: "Name",
        CREATE: "Erstellen",
        ERROR: "Ein unerwarteter Fehler ist aufgetreten.",
        NAME_LENGTH_VALIDATION: "Name zu lang, maximal 32 Zeichen erlaubt.",
        NOT_AVAILABLE: "Dieser Name ist bereits vergeben",
        LOADING: "Wird geladen...",
    },

    branding: {
        BRANDING_SETTINGS: "Einstellungen",
        BRANDING_ACTIVE: "Branding aktivieren",
        BRANDING_LOGO: "Branding Logo",
        BRANDING_WALLPAPER: "Hintergrund",
        BRANDING_PRIMARY_COLOR: "Primäre Farbe",
        BRANDING_SECONDARY_COLOR: "Sekundäre Farbe",
        POWERED_BY_DEBITORCLOUD_POSITION: "Logoposition von „powered by“",
        SAVE_CHANGES: "Änderungen speichern",
        LOADING: "Wird geladen...",
    },

    unclearPayments: {
        LOADING: "Wird geladen...",
        NO_UNCLEAR_PAYMENTS: "Keine unklare Zahlungen",
        SUM_OTHER_CREDIT: "Summe unklarer Gutschriften",
    },

    accountingSettings: {
        ACCOUNTING_SETTINGS: "Buchhaltungseinstellungen",
        ACCOUNTING_SETTINGS_TEXT: "Hier kannst du Einstellungen an den Konten festlegen. Diese Einstellungen sind wichtig, " +
        "um die einzelnen OP-Zeilen aus der Buchhaltung korrekt auswerten und zuordnen zu können",
        INCOME_ACCOUNTS: "Erlöskonten",
        BANK_ACCOUNTS: "Bankkonten",
        SAVE_CHANGES: "Änderungen speichern",
        ADD_NEW_ACCOUNT: "Neues Konto hinzufügen"
    },

    paymentAgreements: {
        CUSTOMER_NR: "Kundennr.",
        CUSTOMER: "Kunde",
        PAYMENT_AGREEMENTS: "Zahlungsvereinbarungen",
        SUM_VACANT_POS: "Summe OP",
        INITIAL_VACANT_POS: "Ursprüngl. OP",
        EVERY: "alle",
        MONTHS: "Monat(e)",
        LOADING: "Wird geladen...",
        OPEN: "Öffnen",
        NO_PAYMENT_AGREEMENTS: "Keine Zahlungsvereinbarungen vorhanden",
        OF: "von",
        PAGE: "Seite",
        INFO_MODAL_TITLE: "Zahlungsvereinbarungen",
        INFO_MODAL_BODY: "In diesem Bereich siehst du Kunden, für die im Kundendashboard eine Zahlungsvereinbarung festgelegt wurde.",
    },

    unpaidPaymentAgreements: {
        CUSTOMER_NR: "Kundennr.",
        CUSTOMER: "Kunde",
        PAYMENT_AGREEMENTS: "Zahlungsvereinbarung",
        SUM_VACANT_POS: "Summe OP",
        INITIAL_VACANT_POS: "Ursprüngl. OP",
        EVERY: "alle",
        MONTHS: "Monat(e)",
        UNPAID_PAYMENT_AGREEMENTS: "Nicht eingehaltene Zahlungsvereinbarungen",
        LOADING: "Wird geladen...",
        OPEN: "Öffnen",
        NO_UNPAID_AGREEMENTS: "Keine unbezahlten Zahlungsvereinbarungen",
        PAGE: "Seite",
        OF: "von",
        INFO_MODAL_BODY: "In diesem Bereich siehst du Kunden, die festgelegte Zahlungsvereinbarungen nicht eingehalten haben. Eine Zahlungsvereinbarung gilt dann als nicht eingehalten, " +
        " wenn der zuvor in den Einstellungen festgelegte Zeitrahmen überschritten wurde. Diese Einstellung kann nur von einem Systemadministrator verändert werden (siehe Einstellungen > Systemeinstellungen)",
        INFO_MODAL_TITLE: "Nicht eingehaltene Zahlungsvereinbarungen"
    },

    overviewStatistics: {
        ACCOUNTS_RECEIVABLE_TERM: "Debitorenlaufzeit",
        ACCOUNTS_RECEIVABLE_TURNOVER: "Debitorenumschlag",
        VACANT_POS: "Offene Posten",
        OVERALL_DEVELOPMENT: "Gesamtentwicklung"
    },
    reminder: {
        REMINDER: "Erinnerungen",
        CUSTOMER: "Kunde",
        REMINDER_DATE: "Erinnerung am",
        LOADING: "Wird geladen...",
        NO_EVENTS: "Keine Erinnerungen",
        DETAILS: "Details",
        NAME: "Name",
        EMAIL: "E-Mail",
        PHONE: "Tel.",
        DATE: "Datum",
        DELETE: "Löschen",
        EDIT: "Bearbeiten",
        TO_CUSTOMER: "Zum Kunden",
        SUCCESSFULLY_DELETED: "Gelöscht",
        EDIT_REMINDER: "Erinnerung bearbeiten",
        ABORT: "Abbrechen",
        SAVE: "Speichern",
        PROCESSING: "Anfrage wird bearbeitet...",
        REMINDER_UPDATED: "Erinnerung geändert",
        INFO_MODAL_BODY: "In diesem Bereich siehst du ausständige Erinnerungen. Du kannst Erinnerungen im Kundendashboard erstellen. Sobald eine das ausgewählte Datum erreicht hat, erhältst du eine E-Mail mit der Beschreibung.",
        INFO_MODAL_TITLE: "Erinnerungen"
    },
    vacantPosOfCustomer: {
        INVOICE_NR: "Rechnungsnr.",
        DEFAULT_OF_PAYMENT: "In ZV",
        PAUSED: "Pausiert",
        ADMONITION: "Gemahnt",
        INVOICE_DATE: "Rechnungsdatum",
        DUE_DATE: "Fälligkeitsdatum",
        AMOUNT: "Betrag",
        AMOUNT_TO_PAY: "Offener Betrag",
        AMOUNT_REMINDERS: "Posten-Mahnstufe",
        AMOUNT_REMINDERS_INFO_TEXT: "Bitte beachte, dass die Mahnstufe auf Postenebene keinen Einfluss darauf hat, welche Vorlage beim Versenden einer Mahnung verwendet wird. Wird eine Mahnung erstellt / versendet, wird in dem Moment ermittelt, wie viele Mahnungen vorab zu den betroffenen Posten bereits versendet wurden. Auf dieser Basis wird ermittelt, welche Mahnvorlage verwendet wird. Die Mahnstufe auf Postenebene kann allerdings in der Tabelle der offenen Posten auf der Mahnung zur detaillierteren Information angedruckt werden.",
        VACANT_POS: "Offene Posten",
        LOADING: "Wird geladen...",
        OTHER_PAYMENTS: "Summe sonstiger Zahlungen",
        OTHER_CREDIT: "Summer sonstiger Gutschriften",
        OTHER_CHARGEBACK: "Summe sonstiger Rückbuchungen",
        TOTAL: "Gesamtforderung inkl. Zinsen",
        HISTORICAL_ACCOUNTING_INFORMATION: "Historische Rechnungsinformationen",
        NO_VACANT_POS: "Keine offenen Posten",
        SUM_OTHER_CREDIT: "Summe sonstiger Gutschriften",
        SUM_VACANT_POS: "Summe offener Posten",
        INTEREST: "Zinsen",
        CONFIRM_DUNNING_BLOCK: "Forderung pausieren",
        CHANGED_SUCCESSFULLY: "Erfolgreich geändert",
        CONFIRM_REMOVE_DUNNING_BLOCK: "Sperre aufheben?",
        CREATE_NEW_DUE_DATE: "Neues Fälligkeitsdatum hinzufügen",
        REQUIRED: "Das ist ein Pflichtfeld",
        MIN_LENGTH: "Die Mindestlänge beträgt 20 Zeichen",
        SET_NEW_DUE_DATE: "Speichern",
        CONFIRMATION_DIALOG: "Möchten Sie die Anfrage wirklich absenden?"
    },
    customerStatistics: {
        ACCOUNTS_RECEIVABLE_TERM: "Debitorenlaufzeit",
        ACCOUNTS_RECEIVABLE_TURNOVER: "Debitorenumschlag",
        VACANT_POS: "Offene Posten",
        CUSTOMER_DEVELOPMENT: "Entwicklung des Kunden"
    },

    userEmails: {
        SENT: "Gesendet",
        RECIPIENT: "Empfänger",
        SUBJECT: "Betreff",
        DATE: "Gesendet",
        FOLDERS: "Ordner",
        CONTENT: "Inhalt",
        CLOSE: "Schließen",
        EMAIL_DETAILS: "Details"
    },

    userSettings: {
        CREATE_USER: "Benutzer erstellen",
        CREATE_USER_TEXT: "Hier kannst du einen Benutzer erstellen.",
        USER_LIST: "Benutzerübersicht",
        USER_LIST_TEXT: "Hier kannst du eine Auflistung aller Benutzer sehen und diese bearbeiten",
        EDIT: "Bearbeiten",
        NAME: "Name",
        SURNAME: "Vorname",
        EMAIL: "E-Mail",
        PASSWORD: "Passwort",
        ID: "ID",
        USER_CREATED: "User wurde hinzugefügt",
        USER_UPDATED: "Benutzerrechte wurden aktualisiert",
        USER_NOT_UPDATED: "Benutzerrechte konnten nicht aktualisiert werden",
        ADMIN: "Administrator",
        APPROVAL_STATUS: "Freigabeberechtigt",
        CAN_READ: "Leserechte",
        CAN_CREATE_DUNNING_NOTES: "Mahnungen erstellen (manuell)",
        CAN_CREATE_COMMENTS: "Kommentare erstellen",
        CAN_DELETE_COMMENTS: "Kommentare löschen",
        CAN_CREATE_PHONE_CALLS: "Anruf verzeichnen",
        CAN_EDIT_RECEIVABLE_INFORMATION: "OPOS bearbeiten",
        CAN_EDIT_TASKS: "Aufgaben bearbeiten",
        CAN_MAKE_CUSTOMER_PROBLEMATIC: "Kunde als Problematisch markieren",
        WANTS_NEWSLETTER: "Newsletter",
        APPROVAL_NOTIFICATION_TITLE: 'Freigabebenachrichtigung',
        APPROVAL_INFO_TITLE: "Freigabeberechtigt",
        CHANGED_SUCCESSFULLY: "Erfolgreich geändert",
        CHANGE_NOT_SUCCESSFUL: "Benutzer konnte nicht erfolgreich geändert werden",
        SAVE: "Speichern",
        DELETE: "Löschen",
        RIGHTS: "Rechte",
        USER_RIGHTS: "Benutzer Rechte",
        ABORT: "Abbrechen",
        REQUIRED: "Das ist ein Pflichtfeld",
        CONFIRM_DISABLE: "Soll der Benutzer wirklich gelöscht werden?",
        SUCCESSFULLY_DISABLED: "Erfolgreich gelöscht!",
        FOREIGN_KEY: "Externe Personalnummer",
        ADMINISTRATOR_INFO_TITLE: "Administrator",
        ADMINISTRATOR_INFO_BODY: "Bitte sei bei der Vergabe von Administratorrechten vorsichtig! Ein Benutzer mit Administratorrechten hat Zugriff auf alle systemrelevanten Einstellungen und kann auch anderen Benutern Administratorrechte geben. ",
        APPROVAL_INFO_BODY: "Ein freigabeberechtigter Benutzer hat die Möglichkeit, Briefe oder E-Mails für den Versand freizugeben. Das ist nur notwendig, wenn in den Systemeinstellungen die E-Mail Freigabe aktiviert wurde (siehe 'Einstellungen'-'Systemeinstellungen'-'E-Mail Freigabe')",
        DELETE_OWN_USER_NOT_ALLOWED: "Man kann seinen eigenen Account nicht löschen.",
        EXTERNAL_ADVISER_NUMBER_TITLE: 'Individuelles Feld für "Externe Partnernummer"',
        EXTERNAL_ADVISER_NUMBER_SUBTITLE: "Individuelle Feld Nummer DATEV für Zuordnung der externen Partnernummer",
        USE_CUSTOMER_ADVISER_FOR_EMAILS: "E-Mails mit dem Namen des Kundenbetreuers als Verfasser senden",
        APPROVAL_NOTIFICATION_BODY: 'Diese Option ermöglicht es, die freigabeberechtigte Person per E-Mail zu erinnern, dass noch E-Mail- oder Postfreigaben ausstehen.',
        ERROR_403_USER_NEED_APPROPRIATE_PERMISSION: "Du hast nicht die erforderlichen Berechtigungen, um diese Aktion durchzuführen."
    },

    userProfileSettings: {
        PROFILE_SETTINGS: "Profileinstellungen",
        PROFILE_SETTINGS_TEXT: "",
        NAME: "Name",
        SURNAME: "Vorname",
        EMAIL: "E-Mail-Adresse",
        CHANGE_SETTINGS: "Profildaten ändern",
        OLD_PASSWORD: "Altes Passwort",
        NEW_PASSWORD: "Neues Passwort",
        NEW_PASSWORD_CONFIRM: "Neues Passwort bestätigen",
        PASSWORD: "Passwort",
        GENERAL_SETTINGS: "Grundeinstellungen",
        CHANGE_PROFILE_SUCCESSFUL: "Profil geändert",
        CHANGE_PROFILE_NOT_SUCCESSFUL: "Profil konnte nicht geändert werden",
        REQUIRED: "Das ist ein Pflichtfeld",
        INVALID_FORMAT: "Falsches Format",
        PASSWORD_DIFFERENT: "Die Passwörter stimmen nicht überein",
        PASSWORD_SETTINGS: "Passwort ändern",
        PASSWORD_SETTINGS_TEXT: "",
        CHANGE_PASSWORD: "Passwort ändern",
        CHANGE_PASSWORD_NOT_SUCCESSFUL: "Passwort konnte nicht geändert werden",
        CHANGE_PASSWORD_SUCCESSFUL: "Passwort wurde geändert",
        VIEW: "Ansicht",
        VIEW_SETTINGS: "Anzeigeeinstellungen",
        VIEW_SETTINGS_TEXT: "",
        NUM_ROWS_IN_LIST: "Anzahl der Reihen, die in der Kundenübersicht angezeigt werden",
        SAVE_CHANGES: "Änderungen speichern",
        SETTINGS_SAVED: "Änderung gespeichert",
        SETTINGS_NOT_SAVED: "Änderung konnte nicht gespeichert werden",
        VISIBLE_COLUMNS: "Spalten, die in der Kundenübersicht angezeigt werden",
        VISIBLE_MODULES_IN_DASHBOARD: "Bereiche, die auf dem Dashboard angezeigt werden",
        SHOW_PROBLEMATIC_CLIENTS: "Problematischen Kunden",
        SHOW_FAVOURITES: "Favoriten",
        SHOW_PAYMENT_AGREEMENTS: "Zahlungsvereinbarungen",
        SHOW_UNPAID_PAYMENT_AGREEMENTS: "Nicht eingehaltene Zahlungsvereinbarungen ",
        SHOW_STATISTICS: "Statistiken",
        SHOW_REMINDER: "Erinnerungen"
    },

    importData: {
        START_IMPORT: "Import starten",
        VACANT_POS_LIST: "OP Liste",
        BASE_DATA: "Stammdaten",
        RECEIPT_IMPORT: "Belege importieren (als ZIP)"
    },

    generalHintEditor: {
        DANGER: "Gefahr (Rot)",
        WARNING: "Warnung (Gelb)",
        SUCCESS: "Erfolg (Grün)",
        INFO: "Information (Blau)",
        HINT: "Hinweis",
        SAVE_CHANGES: "Änderungen speichern",
        HINT_TYPE: "Hinweistyp",
        CREATE_HINT_TEXT: "Hier kannst du Hinweise erstellen, die für alle Benutzer auf dem Dashboard angezeigt werden. Du kannst bis zu drei Hinweise gleichzeitig einblenden und dabei aus vier Hinweistypen auswählen.",
        CREATE_HINT: "Hinweis erstellen",
        VISIBILITY: "Sichtbarkeit",
        VISIBLE: "Sichtbar",
        INVISIBLE: "Ausgeblendet",
        CONFIRM_CHANGE: "Änderungen speichern?",
        SUCCESSFULLY_CHANGED: "Änderungen gespeichert",
        VISIBILITY_INFO_MODAL_BODY: "Sobald ein Hinweis auf den Status \"Sichtbar\" gesetzt wurde, ist er für alle Mitarbeiter am Dashboard sichtbar.",
        VISIBILITY_INFO_MODAL_TITLE: "Sichtbarkeit"
    },

    taskList: {
        NAME: "Betreff",
        DONE: "Erledigt",
        DONE_BY: "Erledigt von",
        TYPE: "Typ",
        FILTER: "Filter",
        REFRESH: "Aktualisieren",
        NUM_ROWS: "Aufgaben pro Seite",
        DONE_STATUS: "Status",
        NOT_DONE: "Nicht erledigt",
        ALL: "Alle",
        SEARCH_TERM: "Suchbegriff",
        TASK_DETAILS: "Details",
        DESCRIPTION: "Beschreibung",
        PAGE: "Seite",
        OF: "von",
        ACTION: "Aktion",
        MARK_AS_DONE: "Als erledigt markieren",
        MARK_AS_UNDONE: "Als nicht erledigt markieren",
        DELETE_TASK: "Aufgabe löschen",
        LOADING: "Wird geladen...",
        NO_FILTER_RESULTS: "Keine Resultate",
        RESET_FILTER: "Filter zurücksetzen",
        PHONE_CALL: "Kontaktieren",
        INFORM_LAWYER: "Anwalt kontaktieren",
        UNDONE: "Nicht erledigt",
        CREATE_NEW_TASK: "Neue Aufgabe erstellen",
        TASK_NAME: "Betreff",
        TASK_DESCRIPTION: "Aufgabenbeschreibung",
        TASK_TYPE: "Typ",
        CREATE_TASK: "Aufgabe erstellen",
        TASK_CREATED_SUCCESSFULLY: "Aufgabe erfolgreich erstellt",
        TASK_NOT_CREATED: "Aufgabe konnte nicht erstellt werden",
        CONFIRM_CREATE_TASK: "Sobald du diese Aufgabe erstellst, wird sie für alle anderen Mitarbeiter sichtbar. Fortfahren?",
        ASSIGN_CUSTOMER: "Kunden hinzufügen",
        ASSIGNED_CUSTOMER: "Zugewiesene Kunden",
        ASSIGNED_TO: "Zugewiesen an:",
        EMAIL: "E-Mail",
        ACCOUNT: "KdNr.",
        SET_DONE: "Aufgabe wirklich als 'erledigt' markieren?",
        SET_UNDONE: "Aufgabe wirklich als 'nicht erledigt' markieren?",
        SUCCESSFULLY_SET_DONE: "Erfolgreich als 'erledigt' markiert",
        SUCCESSFULLY_SET_UNDONE: "Erfolgreich als 'nicht erledigt' markiert",
        REMOVE: "Entfernen",
        ACCOUNT_NR: "Kundennr.",
        IS_PROBLEMATIC: "Problematisch",
        CUSTOMER_ADVISOR: "Kundenbetreuer",
        REQUIRED: "Das ist ein Pflichtfeld",
        SET_DONE_SUCCESSFUL: "Als 'erledigt' markiert",
        SET_UNDONE_SUCCESSFUL: "Als 'nicht erledigt' markiert",
        ID: "Kundennr.",
        ONLY_MINE: "Nur meine Kunden",
        ADVISOR: "Kundenbetreuer",
        CUSTOMER_NAME: "Kundenname",
        CUSTOMER_PHONE: "Telefonnr.",
        CUSTOMER_EMAIL: "E-Mail-Adresse"
    },

    importSettings: {
        ROWS_IN_VACANT_POS_CSV_FILE: "Spalten im OP CSV-File",
        ROWS_IN_VACANT_POS_CSV_FILE_TEXT: "Hier kannst du definieren, welche Spalten im CSV-File welche Funktion haben. Dies erlaubt uns eine grobe Formatprüfung und das individuelle Importieren von verschiedenen CSV-Formaten",
        ACCOUNT_NR: "Kontonummer",
        CUSTOMER_TITLE: "Kundentitel",
        INVOICE_NR: "Rechnungsnr.",
        DATE: "Datum",
        DUE_DATE: "Fälligkeitsdatum",
        AMOUNT_CREDIT: "Betrag Soll",
        AMOUNT_DEBIT: "Betrag Haben",
        CONTRA_ACCOUNT: "Gegenkonto",
        BASE_DATA_STREET: "Stammdaten: Straße",
        BASE_DATA_ZIP: "Stammdaten: Postleitzahl",
        BASE_DATA_CITY: "Stammdaten: Ort",
        BASE_DATA_COUNTRY: "Stammdaten: Land",
        BASE_DATA_PHONE: "Stammdaten: Telefon",
        ROWS_IN_BASE_DATA_CSV_FILE: "Spalten im Stammdaten CSV File",
        ROWS_IN_BASE_DATA_CSV_FILE_TEXT: "Hier kannst du definieren, welche Spalten im CSV-File welche Funktion haben. Dies erlaubt uns eine grobe Formatprüfung und das individuelle Importieren von verschiedenen CSV-Formaten",
        CUSTOMER_NR: "Kundennr.",
        NAME: "Name",
        STREET: "Straße",
        ZIP: "Postleitzahl",
        CITY: "Ort",
        COUNTRY: "Land",
        LANGUAGE: "Sprache",
        CUSTOMER_ADVISOR: "Kundenbetreuer",
        EMAIL: "E-Mail",
        PHONE: "Telefonnummer",
        CREDITLINE: "Kreditlinie",
        COMPANY: "Unternehmen",
        DATEV_CONNECT_URL: "DATEV-URL",
        DATEV_CONNECT_CREDENTIALS: "DATEV-Anmeldedaten",
        DATEV_CONNECT_SOURCE: "DATEV-Grundeinstellungen",
        IP: "IP-Adresse",
        PORT: "Port",
        USER: "Benutzer",
        PW: "Passwort",
        CLIENT_NR: "Mandantennr.",
        FISCAL_YEAR: "Wirtschaftsjahr",
        IMPORT_SETTINGS: "Importeinstellungen",
        IMPORT_SETTINGS_TEXT: "",
        REQUIRED: "Das ist ein Pflichtfeld",
        CONFIRM_CHANGES: "Möchtest du die Änderungen beibehalten? ",
        CHANGE_SUCCESSFUL: "Einstellungen geändert",
        CHANGE_NOT_SUCCESSFUL: "Einstellungen konnten nicht geändert werden",
        SAVE_CHANGES: "Änderungen speichern",
        INFO_MODAL_BODY: "Bitte ändere diese Daten nur mit größter Vorsicht, da dies Auswirkungen auf den Import der Kundendaten zur Folge hat.",
        INFO_MODAL_TITLE: "Einstellungen der DATEVconnect-Schnittstelle.",
        IGNORED_CUSTOMER_ID: "Zu ignorierende Debitoren"
    },
    workflowCreator: {
        CREATE_NEW_WORKFLOW: "Neuen Workflow erstellen",
        CREATE_NEW_WORKFLOW_TEXT: "Gib hier die Workflowdetails ein.",
        TO_PHONE_LIST: "Zur Aufgabenliste Typ 'Telefonanruf' hinzufügen",
        TO_LAWYER_LIST: "Zur Aufgabenliste Typ 'Anwaltsübergabe' hinzufügen",
        TO_MAIL: "E-Mail versenden",
        TO_PAYMENT_TERM_MAIL: "Ratenzahler Erinnerung senden",
        TO_PROBLEMATIC: "Kunden als problematisch markieren",
        TO_CUSTOMERADVISOR: "An den Kundenbetreuer übergeben",
        TO_EMAIL_AND_PHYSICAL_MAIL: "Mahnung per E-Mail und per Post versenden",
        P1D: "Täglich",
        P1W: "Wöchentlich",
        P2W: "Alle 2 Wochen",
        P1M: "Monatlich",
        MONDAY: "Montag",
        TUESDAY: "Dienstag",
        WEDNESDAY: "Mittwoch",
        THURSDAY: "Donnerstag",
        FRIDAY: "Freitag",
        SATURDAY: "Samstag",
        SUNDAY: "Sonntag",
        NAME: "Name",
        ACTION: "Aktion",
        INTERVAL: "Intervall",
        INTERVAL_DAY: "Intervalltag",
        IS_PROBLEMATIC: "Ist problematisch",
        AMOUNT_CALLS: "Anzahl Anrufe",
        AMOUNT_MAILS: "Anzahl Mahnungen",
        AMOUNT_INTEREST: "Betrag Zinsen",
        AMOUNT_DEBTS: "Betrag Schulden",
        OPERATOR: "Operator",
        VALUE: "Wert",
        ADD_FILTER: "Filter hinzufügen",
        FILTER: "Filter",
        YES: "Ja",
        NO: "Nein",
        CREATE_WORKFLOW: "Workflow erstellen",
        CREATED_SUCCESSFULLY: "Erfolgreich erstellt",
        REQUIRED: "Pflichtfeld",
        CONFIRM_CREATE_WORKFLOW: "Möchtest du diesen Workflow erstellen? Die Änderungen sind sofort wirksam.",
        NO_FILTER_SET: "Kein Filter vorhanden",
        ADDITIONAL_INFO_WORKFLOWACTION: "Zusätzliche Informationen",
        EMAIL_TEMPLATE: "E-Mail Vorlage",
        TASK_DESCRIPTION: "Aufgabenbeschreibung",
        INTERVAL_HOUR: "Intervallstunde",
        TASK_TITLE: "Titel",
        TO_PHYSICAL_MAIL: "Mahnbrief versenden",
        TO_EMAIL_OR_PHYSICAL_MAIL: "E-Mail oder Mahnbrief versenden (falls keine E-Mail vorhanden)",
        LAST_TIME_CONTACTED: "Anzahl Tage seit letzter Mahnung",
        OLDEST_INVOICE: "Anzahl überfälliger Tage der ältesten Rechnung",
        SEND_ENROLLED: "Brief eingeschrieben versenden",
        EDIT_WORKFLOWS: "Workflow bearbeiten",
        SAVE: "Speichern",
        CANCEL: "Abbrechen",
        REMOVE_FILTER: "Entfernen",
        WORKFLOW_SPECIFIC_DETAILS: "Workflowspezifische Details",
        INFO_MODAL_TITLE: "Workflow erstellen",
        WORKFLOW_NAME_INFO_LABEL: "Workflowname: ",
        WORKFLOW_NAME_INFO: "Der Name, den der Workflow tragen soll. Er ist für alle Benutzer sichtbar. Ein Beispiel dafür wäre: 'Kunden mit mehr als 3.000 Euro OP als problematisch markieren'",
        WORKFLOW_INTERVAL_INFO_LABEL: "Intervall",
        WORKFLOW_INTERVAL_INFO: "Definiert, wie häufig ein Workflow ausgeführt werden soll.",
        WORKFLOW_INTERVAL_DAY_INFO_LABEL: "Intervalltag",
        WORKFLOW_INTERVAL_DAY_INFO: "Legt fest, an welchem Wochentag der Workflow ausgeführt werden soll. Hinweis: Diese Auswahl ist nur verfügbar, wenn bei Intervall nicht 'Täglich' ausgewählt wurde. ",
        WORKFLOW_INTERVAL_HOUR_INFO_LABEL: "Intervallstunde",
        WORKFLOW_INTERVAL_HOUR_INFO: "Legt fest, zu welcher Stunde der Workflow ausgeführt werden soll. Beachte, dass es zu Verzögerungen kommen kann, wenn viele Workflows gleichzeitig ausgeführt werden. Wenn die Reihenfolge der Workflows relevant ist, kann diese unter Workflowpriorität festgelegt werden.",
        WORKFLOW_ACTION_INFO_LABEL: "Workflowaktion",
        WORKFLOW_ACTION_INFO: "Legt fest, welche Aktion durchgeführt werden soll, sobald ein Workflow ausgeführt wird. ",
        TASK_PHONE_CALL_LABEL: "Aktion: Zur Aufgabenliste Typ 'Telefonanruf' hinzufügen",
        TASK_PHONE_CALL_DESCRIPTION: "Für Kunden, die auf den Workflowfilter zutreffen, werden Aufgaben vom Typ \"Telefonanruf\" erstellt, die vom jeweiligen Kundenbetreuer bearbeitet werden müssen.",
        TASK_LAWYER_LABEL: "Aktion: Zur Aufgabenliste Typ 'Anwaltsübergabe' hinzufügen",
        TASK_LAWYER_DESCRIPTION: "Für Kunden, die auf den Workflowfilter zutreffen, werden Aufgaben vom Typ \"Anwaltsübergabe\" erstellt, die vom jeweiligen Kundenbetreuer bearbeitet werden müssen. ",
        SEND_EMAIL_LABEL: "Aktion: E-Mail versenden",
        SEND_EMAIL_DESCRIPTION: "An Kunden, die auf den Workflowfilter zutreffen, wird eine E-Mail mit der aktuellen Mahnung versendet.",
        MARK_AS_PROBLEMATIC_LABEL: "Aktion: Kunden als problematisch markieren",
        MARK_AS_PROBLEMATIC_DESCRIPTION: "Kunden, die auf den Workflow zutreffen, werden als \"Problematisch\" markiert.",
        CUSTOMER_ADVISOR_LABEL: "Aktion: An den Kundenbetreuer übergeben",
        CUSTOMER_ADVISOR_DESCRIPTION: "An den Kundenbetreuer wird eine E-Mail versendet, dass der jeweilige Kunde bearbeitet werden muss.",
        SEND_PHYSICAL_MAIL_LABEL: "Aktion: Mahnbrief versenden",
        SEND_PHYSICAL_MAIL_DESCRIPTION: "An Kunden, die auf den Workflowfilter zutreffen, wird ein Brief mit der aktuellen Mahnung versendet.",
        SEND_EMAIL_OR_PHYSICAL_MAIL_LABEL: "Aktion: E-Mail oder Mahnbrief versenden (falls keine E-Mail vorhanden)",
        SEND_EMAIL_OR_PHYSICAL_MAIL_DESCRIPTION: "An Kunden, die auf den Workflowfilter zutreffen, wird eine E-Mail oder ein Brief mit der aktuellen Mahnung versendet. Der Mahnbrief wird nur versendet, wenn beim Kunden keine E-Mail-Adresse hinterlegt ist. ",
        WORKFLOW_INFO_MODAL_BODY: "Erstellt eine Auswahl an Kunden, auf die die definierten Kriterien zutreffen. Der Workflow wird nur für diese Kunden ausgeführt.",
        WORKFLOW_INFO_MODAL_TITLE: "Workflow-Filter",
        EXCEEDS_CREDIT_LLMIT: "Kreditlimit überschritten",
        AMOUNT_PENDING_CALLJOBS: "Anzahl unerledigter Anrufaufgaben",
        PAYMENT_TERM_APPOINTMENTS_OVERDUE_IN_DAYS: "Anzahl Tage Zahlungstermin überfällig",
        AMOUNT_DAYS_SINCE_LAST_SUCCESSFULL_CALL: "Anzahl Tage seit letztem erfolgreichen Anruf",
        INDIVIDUAL_FIELD_X: "Individuelles Feld ",
    },
    workflowList: {
        WORKFLOW_LIST: "Liste aller Workflows",
        NAME: "Name",
        INTERVAL: "Intervall",
        INTERVAL_DAY: "Intervalltag",
        WORKFLOW_ACTION: "Workflowaktion",
        WORKFLOW_LIST_TEXT: "Hier kannst du alle Workflows sehen, bearbeiten und löschen.",
        WORKFLOW_DETAILS: "Workflowdetails",
        MENU: "Aktion",
        WORKFLOW_FILTER: "Filter",
        DELETE: "Löschen",
        EDIT: "Ändern",
        FILTER: "Filter",
        OPERATOR: "Operator",
        VALUE: "Wert",
        NO_WORKFLOWS: "Keine Workflows vorhanden",
        SUCCESSFULLY_DELETED: "Erfolgreich gelöscht",
        CONFIRM_DELETE: "Soll dieser Workflow wirklich gelöscht werden? ",
        P1D: "Täglich",
        P1W: "Wöchentlich",
        P2W: "Alle 2 Wochen",
        P1M: "Monatlich",
        TO_PHONE_LIST: "Zur Aufgabenliste Typ 'Telefonanruf' hinzufügen",
        TO_LAWYER_LIST: "Zur Aufgabenliste Typ 'Anwaltsübergabe' hinzufügen",
        TO_MAIL: "E-Mail versenden",
        TO_PAYMENT_TERM_MAIL: "Ratenzahler Erinnerung senden",
        TO_PROBLEMATIC: "Kunden als problematisch markieren",
        TO_CUSTOMERADVISOR: "An den Kundenbetreuer übergeben",
        MONDAY: "Montag",
        TUESDAY: "Dienstag",
        WEDNESDAY: "Mittwoch",
        THURSDAY: "Donnerstag",
        FRIDAY: "Freitag",
        SATURDAY: "Samstag",
        SUNDAY: "Sonntag",
        IGNORE_INTERVAL_DAY: "Keiner",
        INTERVAL_HOUR: "Intervallstunde",
        LAST_EXECUTED: "Zuletzt ausgeführt",
        TO_PHYSICAL_MAIL: "Mahnbrief versenden",
        TO_EMAIL_OR_PHYSICAL_MAIL: "E-Mail oder Mahnbrief versenden (falls keine E-Mail vorhanden)",
        LAST_TIME_CONTACTED: "Anzahl Tage seit letzter Mahnung",
        OLDEST_INVOICE: "Anzahl überfälliger Tage der ältesten Rechnung",
        MO: "Montag",
        DI: "Dienstag",
        MI: "Mittwoch",
        DO: "Donnerstag",
        FR: "Freitag",
        SA: "Samstag",
        SO: "Sonntag",
        EXECUTE_WORKFLOW_NOW: "Ausführung zurücksetzen",
        WORKFLOW_RESET: "Workflow wurde zurückgesetzt. Der Workflow wird innerhalb der nächsten 5 Minuten ausgeführt. ",
        WORKFLOW_RESET_FAILED: "Der Workflow konnte nicht zurückgesetzt werden.",
        CONFIRMATION_WINDOW_TEXT: "Warnung: Wenn du den Workflow manuell ausführst, kann dies abhängig von den Einstellungen in deinem Workflow zu Verzögerungen führen bis dieser erneut vom System ausgeführt wird. Bei Fragen wende dich bitte an office@riecken-webservices.at.",
        WORKFLOW_EXECUTE_NOW_CONFIRMATION_TEXT: "Möchtest du den Workflow jetzt ausführen?",
        WORKFLOW_EXECUTE_NOW_SUCCESS_MSG: "Der Workflow wird innerhalb der nächsten Augenblicke ausgeführt!",
        WORKFLOW_EXECUTE_NOW_ERROR_MSG: "Der Workflow konnte nicht ausgeführt werden. Bitte kontaktiere unseren Support.",
        NO_LOGO: "Es ist kein Logo hochgeladen, bitte lade ein Logo in den Einstellungen hoch, um ein Workflows zu aktivieren.",
        TO_EMAIL_AND_PHYSICAL_MAIL: "Mahnung per E-Mail und per Post versenden",
    },

    filter: {
        TO_PHONE_LIST: "Zu Telefonliste hinzufügen",
        TO_LAWYER_LIST: "Zu Anwaltsliste hinzufügen",
        TO_MAIL: "Zu Mail hinzufügen",
        TO_LAWYER: "Zu Anwalt hinzufügen",
        TO_INKASSO: "Zu Inkasso hinzufügen",
        TO_PROBLEMATIC: "Zu 'ist problematisch' hinzufügen",
        TO_CUSTOMERADVISOR: "Zu Kundenbetreuer hinzufügen",
        IS_PROBLEMATIC: "Ist problematisch",
        AMOUNT_CALLS: "Anzahl Anrufe",
        AMOUNT_MAILS: "Anzahl Mahnungen",
        AMOUNT_INTEREST: "Betrag Zinsen",
        AMOUNT_DEBTS: "Betrag Schulden",
        OLDEST_INVOICE: "Anzahl überfälliger Tage der ältesten Rechnung",
        LAST_TIME_CONTACTED: "Anzahl Tage seit letzter Mahnung",
        PREFERRED_CONTACT_METHOD: "Bevorzugte Kontaktmethode",
        EXCEEDS_CREDIT_LLMIT: "Kreditlimit überschritten",
        AMOUNT_PENDING_CALLJOBS: "Anzahl unerledigter Anrufaufgaben",
        PAYMENT_TERM_APPOINTMENTS_OVERDUE_IN_DAYS: "Anzahl Tage Zahlungstermin überfällig",
    },

    customerTaskList: {
        TASKS: "Unerledigte Aufgaben",
        NO_TASKS: "Keine unerledigten Aufgaben",
        NAME: "Name",
        DONE: "Erledigt",
        TYPE: "Typ",
        DESCRIPTION: "Beschreibung",
        TASK_DETAILS: "Aufgabendetails",
        MARKED_AS_DONE: "Als erledigt markiert",
        LOADING: "Lädt...",
        ONLY_MINE: "Nur meine Kunden"

    },
    moduleSettings: {
        MODULE_SETTINGS: "Zusätzliche Module",
        MODULE_SETTINGS_TEXT: "Text einfügen",
        ACTIVATE: "Aktivieren",
        CREDIT_REFORM_MODULE: "Modul Kreditrefrom",
        CREDIT_REFORM: "Kreditreform",
        ACTIVATE_MODULE: "Modul aktivieren"
    },
    translationSettings: {
        TRANSLATIONS: "Übersetzungen",
        ISO_2: "ISO2 Ländercode",
        TEXT: "Übersetzung",
        ACTION: "Aktion",
        SAVE: "Speichern",
        LANGUAGE: "Sprache",
        SEPA_MANDAT: "SEPA-Mandat",
        ADD_LANGUAGE: "Sprache hinzufügen",
        SUCCESSFULLY_SAVED: "Gespeichert",
        TEMPLATES: "Vorlagen",
        DEMAND_NOTE: "Mahnung",
        LAWYER_REPORT: "Anwaltsübergabe",
        EMAIL_TEMPLATE: "E-Mail-Vorlage",
        CREATED_LANGUAGE_SUCCESSFULLY: "Sprache erfolgreich angelegt",
        CREATE: "Erstellen",
        CREATE_NEW_LANGUAGE: "Neue Sprache hinzufügen",
        OPTIONS: "Optionen",
        REMOVE_LANGUAGE: "Sprache entfernen",
        TRANSLATION_SAVED: "Übersetzung gespeichert",
        EMPTY_TRANSLATION_NOT_ALLOWED: "Das Eingabefeld darf nicht leer sein",
        CONFIRM_TEMPLATE_DELETE: "Soll diese Sprache wirklich gelöscht werden?",
        DUNNING_LEVEL: "Mahnstufe",
        DUNNING_LEVEL_ONE: "1. Mahnung",
        DUNNING_LEVEL_TWO: "2. Mahnung",
        DUNNING_LEVEL_THREE: "3. Mahnung"
    },
    userPaymentAgreements: {
        PAYMENT_AGREEMENTS: "Zahlungsvereinbarungen",
        ENTRY_CREATED: "Erstellungsdatum",
        TOTAL: "Gesamtbetrag",
        NUM_INTERVAL: "Anzahl Intervalle",
        AMOUNT_INTERVAL: "Betrag pro Intervall",
        FINISHED: "Beendet",
        DUE_DATE: "Fälligkeitsdatum",
        PAID: "Bezahlt",
        PAID_DATE: "Bezahlt am ",
        DETAILS: "Details",
        NO_PAYMENT_AGREEMENTS: "Keine Zahlungsvereinbarungen",
        DELETE: "Löschen",
        PDF_BUTTON: "Zahlungsvereinbarung drucken",
        SUCCESSFULLY_DELETED: "Erfolgreich gelöscht",
        PROCESSING: "Anfrage wird bearbeitet..."
    },
    dueDateChangedTooltip: {
        CAUTION_DUE_DATE_CHANGED: "Vorsicht: Fälligkeitsdatum verändert! Ursprüngliches Fälligkeitsdatum: "
    },
    emailTemplateEditor: {
        TITLE: "Titel",
        SUBJECT: "Betreff",
        CONTENT: "Inhalt",
        EMAIL_TEMPLATES: "E-Mail-Vorlagen",
        EMAIL_TEMPLATES_TEXT: "Hier kannst du vorgefertigte E-Mail-Vorlagen bearbeiten oder löschen. \n Um eine Vorlage zu bearbeiten, klicke auf deren Namen. Klicke im Feld Sprache auf das grüne Plus, um eine neue Sprache hinzuzufügen.",
        CHANGED_SUCCESSFULLY: "Erfolgreich geändert",
        SAVE: "Speichern",
        CREATE_EMAIL_TEMPLATE_TEXT: "Erstelle vordefinierte E-Mail-Vorlagen, um wiederkehrende Prozesse zu beschleunigen. \n" +
        "Wenn du eine E-Mail-Vorlage erstellst, wird sie mit einer Standardsprache initialisiert. Andere Sprachen können nachträglich hinzugefügt werden.",
        CREATE_EMAIL_TEMPLATE: "E-Mail Vorlage erstellen",
        LANGUAGE: "Sprache",
        DELETE: "Löschen",
        REMOVED_SUCCESSFULLY: "Erfolgreich gelöscht",
        ADD_LANGUAGE: "Sprache hinzufügen",
        REQUIRED: "Das ist ein Pflichtfeld",
        REMOVE_LANGUAGE: "Sprache löschen",
        REMOVE_TEMPLATE: "Gesamte Vorlage löschen",
        SAVE_TEMPLATE: "Vorlage speichern",
        LANGUAGE_ADDED: "Sprache hinzugefügt",
        TEMPLATE_LANGUAGE_EXISTS: "Sprache existiert bereits",
        LANGUAGE_NOT_SELECTED: "Keine Sprache ausgewählt",
        CONFIRM_ADD_LANGUAGE: "Soll die Sprache zu diesem Template hinzugefügt werden?",
        DEFAULT_LANGUAGE_CANNOT_BE_DELETED: "Defaultwert kann nicht gelöscht werden",
        CONFIRM_DELETE_LANGUAGE: "Soll diese Sprache wirklich gelöscht werden?",
        CONFIRM_DELETE_TEMPLATE: "Soll diese E-Mail-Vorlage wirklich gelöscht werden?",
        NO_CHANGES: "Es wurden keine Änderungen vorgenommen",
        NO_NUMBERS_ALLOWED: "Dieses Feld darf keine Zahlen enthalten",
        SENDING_USER: "Name des Absenders",
        SUM_OUTSTANDING_INVOICES: "Summe offener Rechnungen",
        SUM_INTEREST: "Summe der Zinsen",
        PLUS_X_DAYS: "Heutiges Datum plus X Tage",
        LAST_BOOKING_DATE: "Letztes Buchungsdatum",
        CUSTOMER_NAME: "Kundenvorname",
        CUSTOMER_SURNAME: "Kundennachname",
        CUSTOMER_FULL_NAME: "Kundenname",
        CUSTOMER_ADDRESS: "Kundenadresse",
        CUSTOMER_EMAIL: "Kundenemail",
        CUSTOMER_PHONE: "Telefonnummer des Kunden",
        SENDING_USER_EMAIL: "E-Mail-Adresse des Absenders",
        CUSTOMER_ID: "Kunden-ID",
        OPEN_ITEM_NUMBERS: "Rechnungsnummern der offenen Rechnungen",
        PAYMENT_TERM_REFERENCE: "Referenz der Zahlungsvereinbarung",
        SUM_OVERDUE_ITEMS: "Summe offener überfälliger Rechnungen",
        INSERT: "Platzhalter einfügen",
        COMPANY_PHONE: "Telefonnummer dieses Unternehmens",
        INDIVIDUAL_FIELD_X: "Individuelles Feld X",
        TAGS: "Tags",
        NO_AVAILABLE_LANGUAGES: "Es wurden bereits alle verfügbare Sprachen hinzugefügt!",
    },
    templateEditor: {
        TEMPLATE_TYPE: "Vorlage",
        TEMPLATE_LANGUAGE: "Templatesprache",
        DUNNING_LEVEL: "Mahnstufe",
        TEMPLATE_EDITOR_HEADING: "Vorlagen",
        TEMPLATE_EDITOR_TEXT: "",
        CONFIRM_SAVE: "Soll die Vorlage gespeichert werden? ",
        SAVE: "Speichern",
        DEMAND_NOTE_TEMPLATE: "Mahnung",
        SEPA_MANDAT_TEMPLATE: "SEPA-Mandat",
        EMAIL_TEMPLATE: "E-Mail",
        LAWYER_REPORT_TEMPLATE: "Anwaltsübergabe",
        DUNNING_LEVEL_ONE: "1. Mahnstufe",
        DUNNING_LEVEL_TWO: "2. Mahnstufe",
        DUNNING_LEVEL_THREE: "3. Mahnstufe",
        ACCOUNT_STATEMENT: "Kontoauszug",
        SETTING_EXISTS: "Sprache bereits vorhanden!",
        ADD_LANGUAGE: "Sprache hinzufügen",
        SAVED_CHANGES: "Änderungen gespeichert",
        LANGUAGE: "Sprache",
        REMOVE_LANGUAGE: "Sprache löschen ",
        PROCESSING: "Anfrage wird bearbeitet..."
    },

    workflowEditor: {
        NAME: "Workflowname",
        INTERVAL: "Workflowintervall",
        INTERVAL_DAY: "Intervalltag",
        INTERVAL_HOUR: "Intervallstunde",
        ACTION: "Workflowtyp",
        EDIT_WORKFLOWS: "Workflow bearbeiten",
        FILTER: "Filtertyp",
        OPERATOR: "Operator",
        VALUE: "Vergleichswert",
        SAVE: "Speichern",
        CANCEL: "Abbrechen",
        ADD_FILTER: "Hinzufügen",
        REMOVE_FILTER: "Entfernen",
        TO_PHYSICAL_MAIL: "Mahnbrief versenden",
        TO_EMAIL_OR_PHYSICAL_MAIL: "E-Mail oder Mahnbrief versenden (falls keine E-Mail vorhanden)",
        TO_PAYMENT_TERM_MAIL: "Ratenzahler Erinnerung senden",
        REQUIRED: "Pflichtfeld",
        WORKFLOW_SPECIFIC_DETAILS: "Workflowspezifische Details",
        TASK_TITLE: "Aufgabentitel",
        TASK_DESCRIPTION: "Aufgabenbeschreibung",
        EMAIL_TEMPLATE: "E-Mail-Template",
        SEND_ENROLLED: "Eingeschrieben verschicken",
        NO: "Nein",
        YES: "Ja",
        TO_EMAIL_AND_PHYSICAL_MAIL: "Mahnung per E-Mail und per Post versenden",
    },


    workflowUtil: {
        P1D: "Täglich",
        P1W: "Wöchentlich",
        P2W: "Alle 2 Wochen",
        P1M: "Monatlich",
        TO_PHONE_LIST: "Zur Aufgabenliste Typ 'Telefonanruf' hinzufügen",
        TO_LAWYER_LIST: "Zur Aufgabenliste Typ 'Anwaltsübergabe' hinzufügen",
        TO_MAIL: "E-Mail versenden",
        TO_PAYMENT_TERM_MAIL: "Ratenzahler Erinnerung senden",
        TO_PROBLEMATIC: "Kunden als problematisch markieren",
        TO_CUSTOMERADVISOR: "An den Kundenbetreuer übergeben",
        MONDAY: "Montag",
        TUESDAY: "Dienstag",
        WEDNESDAY: "Mittwoch",
        THURSDAY: "Donnerstag",
        FRIDAY: "Freitag",
        SATURDAY: "Samstag",
        SUNDAY: "Sonntag",
        IGNORE_INTERVAL_DAY: "Keiner",
        INTERVAL_HOUR: "Intervallstunde",
        LAST_EXECUTED: "Zuletzt ausgeführt",
        TO_PHYSICAL_MAIL: "Mahnbrief versenden",
        TO_EMAIL_OR_PHYSICAL_MAIL: "E-Mail oder Mahnbrief versenden (falls keine E-Mail vorhanden)",
        IS_PROBLEMATIC: "Ist problematisch",
        AMOUNT_CALLS: "Anzahl Anrufe",
        AMOUNT_MAILS: "Anzahl Mahnungen",
        AMOUNT_INTEREST: "Betrag Zinsen",
        AMOUNT_DEBTS: "Betrag Schulden",
        LAST_TIME_CONTACTED: "Anzahl Tage seit letzter Mahnung",
        OLDEST_INVOICE: "Anzahl überfälliger Tage der ältesten Rechnung",
        YES: "Ja",
        NO: "Nein",
        EXCEEDS_CREDIT_LLMIT: "Kreditlimit überschritten",
        AMOUNT_PENDING_CALLJOBS: "Anzahl unerledigter Anrufaufgaben",
        PAYMENT_TERM_APPOINTMENTS_OVERDUE_IN_DAYS: "Anzahl Tage Zahlungstermin überfällig",

    },

    workflowPriority: {
        SAVED_SUCCESSFULLY: "Änderungen gespeichert",
        SAVE: "Speichern",
        WORKFLOW_PRIORITIES: "Workflowpriorität",
        WORKFLOW_PRIORITIES_TEXT0: "Hier legst du fest, in welcher Reihenfolge die Workflows abgearbeitet werden. Klicke auf einen Workflow, halte die Maustaste gedrückt und verschiebe den Workflow in die gewünschte Position.",
        SAVING: "Änderungen werden gespeichert"
    },

    registerWizard: {
        COMPANY: "Unternehmen",
        DATEV: "Datev",
        ADMIN_USER: "Admin-User",
        WORKSPACE: "Workspace",
        CONFIRM_REGISTRATION: "Registrierung bestätigen",
        COMPANY_SETTINGS: "Allgemeine Informationen zum Unternehmen",
        COMPANY_NAME: "Unternehmensname",
        EMAIL: "E-Mail",
        CITY: "Stadt",
        ZIP: "Postleitzahl",
        STREET: "Straße",
        PHONE: "Telefonnummer",
        COUNTRY: "Land",
        REQUIRED: "Das ist ein Pflichtfeld",
        INVALID_EMAIL_FORMAT: "Das ist kein gültiges E-Mail Format",
        DATEV_DATA: "DATEV-Informationen",
        IP_ADDRESS: "IP-Adresse",
        PORT: "Port",
        USER: "Benutzer",
        PASSWORD: "Passwort",
        CLIENT_NO: "Mandantennr.",
        FISCAL_YEAR: "Wirtschaftsjahr",
        NEXT: "Weiter",
        BACK: "Zurück",
        FIRST_NAME: "Vorname",
        LAST_NAME: "Nachname",
        FINISH_REGISTRATION: "Registrierung abschließen",
        CREATING_WORKSPACE_TEXT: "Workspace wird erstellt...",
        CREATING_WORKSPACE_TEXT2: "Bitte schließe die Seite während des Registriervorgangs nicht. ",
        CREATING_WORKSPACE: "Registrierung",
        LOGO_URL: "Logo-URL",
        TIMEZONE: "Zeitzone",
        INVALID_URL: "Das ist keine gültige URL",
        INVALID_IP_ADDRESS: "Das ist keine gültige IP-Adresse",
        INVALID_PHONE_NUMBER: "Das ist keine gültige Telefonnummer",
        ONLY_LOWERCASED_CHARS: "Es dürfen nur Kleinbuchstaben verwendet werden.",
        REGISTRATION_ERROR: "Registrierung fehlgeschlagen",
        CREATING_WORKSPACE_SUCCESS_TEXT0: "Dein Debitorcloud-Workspace wurde erfolgreich erstellt.",
        CREATING_WORKSPACE_SUCCESS_TEXT1: "Dein Debitorcloud-Workspace wurde erfolgreich erstellt.",
        TO_WORKSPACE: "Zu meinem Debitorcloud-Workspace",
        CREATING_WORKSPACE_SUCCESS: "Registrierung erfolgreich",
        BACK_TO_LOGIN: "Zurück zum Login",
        NOT_AVAILABLE: "Dieser Name ist bereits vergeben",
        CC_NUMBER: "Kreditkartennummer",
        EXPIRE_DATE: "Gültig bis",
        CVC: "CVC",
        PAYMENT_INFORMATION: "Zahlungsinformationen",
        VAT: "UID",
        INVALID_VAT: "Das ist keine gültige UID",
        CHECKING_VAT: "UID wird geprüft...",
        CONSULTANT_NUMBER: "Beraternummer",
        TAX_CONSULTANT: "Steuerberater",
        ENTREPRENEUR: "Unternehmer"
    },

    setupWizard: {
        COMPANY: "Stammdaten",
        LOGO: "Logo",
        PAPER_LAYOUT: "Briefpapier",
        WORKFLOWS: "Workflows",
        USER_INVITATION: "Benutzer einladen",
        COMPANY_SETTINGS_TAB: "Abfrage folgender Daten",
        COMPANY_NAME: "Unternehmensname",
        EMAIL: "E-Mail",
        CITY: "Stadt",
        ZIP: "Postleitzahl",
        STREET: "Straße",
        PHONE: "Telefonnummer",
        COUNTRY: "Land",
        COMPANY_LOGO_TAB: "Bitte lade ein Logo hoch",
        PAPER_LAYOUT_TAB: "Bitte lade ein Briefpapier hoch",
        PAPER_LAYOUT_OPTIONAL: "Briefpapier (optional)",
        WORKFLOWS_TAB: "Auswahl von drei Workflowkonfigurationen vorgeben",
        WORKFLOWS_TAB_INFO: "Mach dir keine Sorgen! Die Workflows sind nach der Einrichtung standardmäßig deaktiviert. So hast du Gelegenheit, " +
            "die Debitorcloud in Ruhe kennen zu lernen. Du kannst sie später schnell und einfach im Menüpunkt \"Workflows\" aktivieren.",
        DAY_AND_TIME: "Tag und Uhrzeit der Mahnung",
        MONDAY: "Montag",
        TUESDAY: "Dienstag",
        WEDNESDAY: "Mittwoch",
        THURSDAY: "Donnerstag",
        FRIDAY: "Freitag",
        SATURDAY: "Samstag",
        SUNDAY: "Sonntag",
        VARIANT: "Variante",
        USER_INVITATION_TAB: "Benutzer via E-Mail ein Einladungslink zur Debitorcloud zukommen lassen",
        EMAIL_OPTIONAL: "E-Mails (optional)",
        REQUIRED: "Das ist ein Pflichtfeld",
        INVALID_EMAIL_FORMAT: "Das ist kein gültiges E-Mail Format",
        NEXT: "Weiter",
        BACK: "Zurück",
        BACK_TO_LOGIN: "Zurück zum Login",
        FINISH_SETUP: "Einrichtung abschließen",
        INVALID_PHONE_NUMBER: "Das ist keine gültige Telefonnummer",
        CONTACT_SUPPORT: "Änderungen konnten nicht gespeichert werden. Bitte kontaktiere unseren Support.",
        INFO_MESSAGE: "Wenn der Benutzer ein Freigaberecht hat, bedeutet es, dass er über bereitstehende Mahnungen informiert wird und diese auch freigeben kann.",
        // CREATING_WORKSPACE_TEXT2: "Bitte schließe die Seite während des Registriervorgangs nicht. ",
    },

    emailList: {
        NUM_ROWS: "E-Mails pro Seite",
        EMAIL_APPROVAL: "E-Mail-Freigabe",
        ACTION: "Aktion",
        RECIPIENT: "Empfänger",
        SUBJECT: "Betreff",
        CREATED_BY: "Erstellt von:",
        TO: "Empfänger",
        TEXT: "Inhalt",
        CLOSE: "Schließen",
        RECORD_EMAIL: "E-Mail",
        PROCESSING: "Anfrage wird bearbeitet...",
        SUCCESSFUL_UPDATED: "Erfolgreich geändert",
        NO_EMAILS: "Keine E-Mails vorhanden",
        APPROVE_ALL_ELEMENTS: "Ausgewählte E-Mails freigeben",
        DECLINE_ALL_ELEMENTS: "Ausgewählte E-Mails ablehnen",
        NO_SELECTION: "Keine Elemente ausgewählt",
        SUM_DEBTS: "Summe OP",
        CUSTOMER_ID: "Kundennummer",
        CUSTOMER_NAME: "Kundenname",
        CUSTOMER_ADVISER: "Kundenbetreuer",
        LAST_PAYMENT: "Letzte Zahlung",
        RECIPIENT_DELETE_CONFIRMATION_MESSAGE: "Bist du sicher, dass du die letzte Empfängeradresse entfernen willst? Die E-Mail wird entfernt, wenn sie keine Empfänger hat.",
        POPOVER_TITLE: "E-Mail-Freigabe Liste",
        POPOVER_TEXT: "Hier kannst du E-Mails freigeben, nach Kundenbetreuer filtern oder Empfängerlisten bearbeiten. Mit Klick auf den Mülleimer wird die E-Mail-Adresse " +
        "aus der Liste der Empfänger entfernt.",
        SUM_DEBTS_OVERDUE: "Summe überfälliger OP",
    },


    physicalMailApprovalList: {
        NUM_ROWS: "Postsendungen pro Seite",
        PHYSICAL_MAIL_APPROVAL: "Post-Freigabe",
        ACTION: "Aktion",
        RECIPIENT: "Empfänger",
        PROCESSING: "Anfrage wird bearbeitet...",
        SUCCESSFUL_UPDATED: "Erfolgreich geändert",
        NO_EMAILS: "Keine Postsendungen vorhanden",
        APPROVE_ALL_ELEMENTS: "Ausgewählte Postsendungen freigeben",
        DECLINE_ALL_ELEMENTS: "Ausgewählte Postsendungen ablehnen",
        NO_SELECTION: "Keine Elemente ausgewählt",
        ACCOUNT_NO: "Kundennummer",
        PRICE: "Preis",
        SUM_DEBTS: "Summe OP",
        CUSTOMER_ADVISOR: "Kundenbetreuer",
        POPOVER_TITLE: "Post-Freigabe Liste"
    },


    debitCollection: {
        ACCOUNTS_RECEIVABLE_SELECTION: "Forderungen",
        ACCOUNTS_RECEIVABLE_NUMBER: "Rechnungsnummer",
        ACCOUNTS_RECEIVABLE_DATE: "Datum",
        ACCOUNTS_RECEIVABLE_OUTSTANDING_BALANCE: "Offener Betrag",
        SAVE: "Speichern",
        CONFIRM_DEBIT_COLLECTION_REQUEST: "Sollen die ausgewählten Forderungen wirklich an ein Inkassounternehmen übergeben werden?",
        SUCCESSFULLY_SENT: "Die Forderungen wurden an ein Inkassounternehmen weitergeleitet",
        ERROR_WHILE_PROCESSING: "Während der Anfrage ist ein Fehler aufgetreten. Die Übergabe an das Inkassounternehmen konnte nicht  durchgeführt werden.",
        NO_INVOICES: "Keine Rechnungen für Übergabe vorhanden",
        RECORD_DESCRIPTION: "Beschreibung"
    },

    paymentAppointmentCreator: {
        START_CALCULATION: "Berechnen",
        AMOUNT_OF_INTERVALS: "Anzahl Intervalle",
        AMOUNT_PER_INTERVAL: "Betrag pro Intervall",
        CALCULATION_METHOD: "Berechnungsmethode",
        AMOUNT_IN_EURO: "Betrag in Euro",
        DATE_OF_FOLLOWING_APPOINTMENTS: "Nachfolgende Raten sollen am Monatsersten fällig sein",
        DATE_OF_FIRST_APPOINTMENT: "Datum der ersten Rate",
        REQUIRED_ERROR: "Pflichtfeld",
        MIN_VALUE_ERROR: "Wert zu niedrig",
        MAX_VALUE_ERROR: "Wert zu hoch",
        MIN_DATE_ERROR: "Datum darf nicht in der Vergangenheit liegen"
    },

    paymentIntervalSelection: {
        HALF_YEAR: "Halbjährlich",
        QUARTER_YEAR: "Pro Quartal",
        MONTHLY: "Monatlich",
        YEARLY: "Jährlich",
        WEEKLY: "Wöchentlich",
        MONTHLY_X2: "Zweiwöchentlich",
    },

    accountsReceivableSelection: {
        TOTAL: "Gesamtsumme der ausgewählten Rechnungen"
    },

    createPaymentAgreementButton: {
        SAVE_PAYMENT_AGREEMENT: "Zahlungsvereinbarung erstellen",
        CREATE_PAYMENT_AGREEMENT: "Für diesen Kunden eine neue Zahlungsvereinbarung erstellen",
    },

    paidInvoicesList: {
        PAID_INVOICES_LIST: "Bezahlte Rechnungen",
        INVOICE_ID: "Rechnungsnummer",
        INVOICE_AMOUNT: "Rechnungsbetrag",
        INVOICE_DESCRIPTION: "Beschreibung",
        INVOICE_DATE: "Rechnungsdatum",
        PAGE: "Seite",
        OF: "von",
        NO_PAID_INVOICES: "Keine bezahlten Rechnungen",
        ERROR_OCCURRED: "Ein unerwarteter Fehler ist aufgetreten. Komponente kann derzeit nicht geladen werden."
    },

    sendPhysicalMailButton: {
        ADDITIONAL_COSTS_HINT: "Beachte, dass beim Versand von Mahnbriefen zusätzliche Kosten entstehen. ",
        INCREASE_DUNNING_LEVEL: "Mahnstufe erhöhen",
        HINT: "Hinweis",
        IN_COOPERATION_WITH: "In Kooperation mit",
        SEND_PHYSICAL_MAIL: "Mahnung per Post",
        SEND_PHYSICAL_MAIL_SECURED: "Mahnunng verschicken (eingeschrieben)",
        CONFIRM_SEND_MAIL: "Mahnung versenden?",
        SEND_PHYSICAL_MAIL_TEXT: "Eine Mahnung per Post an den Kunden senden.",
        SEND_PHYSICAL_MAIL_SECURED_TEXT: "Versende eine aktuelle Mahnung eingeschrieben per Post an den Kunden",
        SEND_ENROLLED: "Eingeschrieben versenden?",
        YES: "Ja",
        NO: "Nein",
        ABORT: "Abbrechen",
        SAVE: "Speichern",
        SECURED: "Eingeschrieben",
        REGULAR: "Regulär",
        PRICE: "Preise",
        PROCESSING_REQUEST: "Daten werden geladen...",
        ERROR_OCCURRED: "Ein unerwarteter Fehler ist aufgetreten. Komponente kann derzeit nicht geladen werden.",
        ERROR_INFO_TEXT: "Für diese Sendung konnte kein Preis ermittelt werden. Dies kann entweder daran liegen, dass die Schnittstelle " +
            "zum Post-Dienstleister nicht verfügbar ist oder das die Stammdaten des Debitoren nicht ausreichend gepflegt sind (insbesondere Angabe \"Land\"), um einen Preis zu ermitteln.",
        PRICE_INFORMATION_TEXT: "Für eine detaillierte Preisauskunft besuche bitte",
        SENDING_NOT_SUCCESSFUL: "Die Anfrage kann derzeit nicht verarbeitet werden. Bitte versuche es später erneut",
        INFO_TEXT: "Aufgrund des Wechsels des Postdienstleisters steht derzeit kein Versenden von Mahnbriefen per Einschreiben zur Verfügung. " +
            "Wir arbeiten gemeinsam mit dem neuen Dienstleister bereits daran, Einschreiben bald wieder verfügbar machen zu können."
    },

    defaultCustomerAdvisorSelection: {
        SAVE: "Speichern",
        DEFAULT_CUSTOMER_ADVISOR_TEXT: "Hier kannst du einen Kundenbetreuer auswählen, der standardmäßig alle neuen Kunden entgegennimmt.",
        DEFAULT_CUSTOMER_ADVISOR_TITLE: "Standard-Kundenbetreuer",
        ERROR_WHILE_PROCESSING: "Standard-Kundenbetreuer konnte nicht gespeichert werden.",
        NO_CHANGES: "Es wurden keine Änderungen vorgenommen",
        CHANGED_SUCCESSFULLY: "Änderungen gespeichert"
    },

    externalPersonalNumberKeyEditor: {
        EXTERNAL_PERSONAL_KEY: "Individuelles Feld für \"Externe Personalnummer\"",
        EXTERNAL_PERSONAL_KEY_DESCRIPTION: "Individuelle Feld Nummer DATEV für Zuordnung der externen Personalnummer",
        SAVE: "Speichern",
        SAVED_SUCCESSFULLY: "Erfolgreich gespeichert",
        ERROR_DURING_PROCESSING: "Daten konnten nicht gespeichert werden. "
    },

    pausedInvoices: {
        INVOICE_ID: "Rechnungsnr.",
        INVOICE_DATE: "Rechnungsdatum",
        DUE_DATE: "Fälligkeitsdatum",
        OUTSTANDING_BALANCE: "Offener Betrag",
        PAUSED_INVOICES: "Pausierte Rechnungen",
        UNPAUSE: "Aufheben",
        NO_PAUSED_INVOICES: "Keine pausierten Rechnungen",
        ERROR_OCCURRED: "Beim Laden der Komponente ist es zu einem Fehler gekommen.",
        SUCCESSFULLY_UPDATED: "Erfolgreich geändert",
        ERROR_DURING_PROCESSING: "Aktion konnte nicht durchgeführt werden.",
        ERROR_WHILE_INITIALIZING_COMPONENT: "Beim Laden der Komponente ist es zu einem Fehler gekommen.",
        LOADING: "Wird geladen...",
        PAGE: "Seite",
        OF: "von"
    },

    analysisComp: {
        OVERVIEW: "Übersicht",
        CHANGED_DUES: "Veränderte Fälligkeiten",
        ANALYSIS_PDF: "Auswertungen(PDF)",
        DUE_DATE: "Altes Fälligkeitsdatum",
        OPEN_ITEM_NUMBER: "Rechnungsnummer",
        HAS_DUNNING_BLOCK: "Pausiert",
        VIRTUAL_DUE_DATE: "Neues Fälligkeitsdatum",
        ANALYSIS: "Auswertung",
        DEBTS_AGE: "Forderungsaltersreport",
        PAYMENT_TERM: "Zahlungsvereinbarungsreport",
    },

    userDetailsFileUpload: {
        BOX_TITLE: "Dateien hochladen",
        BOX_NO_CONTENT: "Keine Dateien vorhanden",
        UPLOAD_POPOVER_TITLE: "Datei hochladen",
        UPLOAD_POPOVER_TEXT: "Hier kannst du Dateien per Drag-and-Drop hochladen. Dafür musst du die gewünschte Datei mit der Maus ins untere Feld ziehen.",
        REMOVE_FILE_CONFIRMATION_TEXT: "Bist du sicher, dass du die Datei löschen möchtest?"
    },

    pdfTemplateSelector: {
        NO_DELETABLE_OPTIONS_TEXT: "Keine Sprache, abgesehen von Standard/Default, zum Löschen vorhanden."
    },

    supportTicket: {
        CREATE_TICKET: "Ticket erstellen",
        SHOW_TICKETS: "Tickets anzeigen",
        SUPPORT_TICKET_NO: "Ticket #",
        SENDER: "Absender",
        SUBJECT: "Betreff",
        DATE: "Datum",
        TEXT: "Beschreibung",
        CLOSE: "Schließen",

    },
    supportTicketCreate: {
        MISSING_INPUT: 'Deine Daten sind nicht volständig! (Betreff, Beschreibung)',
        CREATE_TICKET_SUCCESSFUL: 'Dein Support-Ticket wurde erfolgreich erstellt',
        CREATE_TICKET_ERROR: 'Dein Support-Ticket konnte nicht erstellt werden. Bitte kontaktiere unseren Kundendienst unter Telefonnummer: +43 1 375 00 51',
        MORE_THAN_15MB: "Anhänge zu groß! Maximal 15 MB erlaubt.",
        MORE_THAN_10MB: "Anhänge überschreiten 10 MB. Trotzdem senden?"
    },
    emailSettings: {
        EMAIL_SETTINGS: "E-Mail Einstellungen",
        SENDER: "Absender E-Mail Adresse für Mahnungen",
        REQUIRED: "Das ist ein Pflichtfeld",
        INVALID_EMAIL_FORMAT: "Das E-Mail Format ist nicht korrekt",
        SAVE_CHANGES: "Änderungen speichern",
    },
    debtCollectionSettings: {
        DEBT_COLLECTION_SETTINGS: "Inkasso Einstellungen",
        BANK_DETAILS_FOR_DEBT_COLLECTION: "Bankdaten für Inkasso",
        IBAN: "IBAN",
        BIC: "BIC",
        ACCOUNT_OWNER: "Kontoinhaber",
        CONTACT_PERSON_FOR_DEBT_COLLECTION: "Ansprechpartner für Inkasso",
        NAME: "Name",
        PHONE: "Telefon",
        EMAIL: "E-Mail",
        REQUIRED: "Das ist ein Pflichtfeld",
        SAVE_CHANGES: "Änderungen speichern",
        SAVE_SUCCESSFUL: "Änderungen gespeichert",
        INFO_MESSAGE: "Um die Übergabe an Inkasso zu verwenden, richten Sie bitte Ihre Kontoinformationen und Ansprechpartnerdaten für Inkasso in den Einstellungen ein.",
    }

};

var countryCodes = [{"name": "Afghanistan", "alpha2": "AF", "countrycode": "004"},
    {"name": "Åland Islands", "alpha2": "AX", "countrycode": "248"},
    {"name": "Albania", "alpha2": "AL", "countrycode": "008"},
    {"name": "Algeria", "alpha2": "DZ", "countrycode": "012"},
    {"name": "American Samoa", "alpha2": "AS", "countrycode": "016"},
    {"name": "Andorra", "alpha2": "AD", "countrycode": "020"},
    {"name": "Angola", "alpha2": "AO", "countrycode": "024"},
    {"name": "Anguilla", "alpha2": "AI", "countrycode": "660"},
    {"name": "Antarctica", "alpha2": "AQ", "countrycode": "010"},
    {"name": "Antigua and Barbuda", "alpha2": "AG", "countrycode": "028"},
    {"name": "Argentina", "alpha2": "AR", "countrycode": "032"},
    {"name": "Armenia", "alpha2": "AM", "countrycode": "051"},
    {"name": "Aruba", "alpha2": "AW", "countrycode": "533"},
    {"name": "Australia", "alpha2": "AU", "countrycode": "036"},
    {"name": "Austria", "alpha2": "AT", "countrycode": "040"},
    {"name": "Azerbaijan", "alpha2": "AZ", "countrycode": "031"},
    {"name": "Bahamas", "alpha2": "BS", "countrycode": "044"},
    {"name": "Bahrain", "alpha2": "BH", "countrycode": "048"},
    {"name": "Bangladesh", "alpha2": "BD", "countrycode": "050"},
    {"name": "Barbados", "alpha2": "BB", "countrycode": "052"},
    {"name": "Belarus", "alpha2": "BY", "countrycode": "112"},
    {"name": "Belgium", "alpha2": "BE", "countrycode": "056"},
    {"name": "Belize", "alpha2": "BZ", "countrycode": "084"},
    {"name": "Benin", "alpha2": "BJ", "countrycode": "204"},
    {"name": "Bermuda", "alpha2": "BM", "countrycode": "060"},
    {"name": "Bhutan", "alpha2": "BT", "countrycode": "064"},
    {"name": "Bolivia (Plurinational State of)", "alpha2": "BO", "countrycode": "068"},
    {"name": "Bonaire, Sint Eustatius and Saba", "alpha2": "BQ", "countrycode": "535"},
    {"name": "Bosnia and Herzegovina", "alpha2": "BA", "countrycode": "070"},
    {"name": "Botswana", "alpha2": "BW", "countrycode": "072"},
    {"name": "Bouvet Island", "alpha2": "BV", "countrycode": "074"},
    {"name": "Brazil", "alpha2": "BR", "countrycode": "076"},
    {"name": "British Indian Ocean Territory", "alpha2": "IO", "countrycode": "086"},
    {"name": "Brunei Darussalam", "alpha2": "BN", "countrycode": "096"},
    {"name": "Bulgaria", "alpha2": "BG", "countrycode": "100"},
    {"name": "Burkina Faso", "alpha2": "BF", "countrycode": "854"},
    {"name": "Burundi", "alpha2": "BI", "countrycode": "108"},
    {"name": "Cambodia", "alpha2": "KH", "countrycode": "116"},
    {"name": "Cameroon", "alpha2": "CM", "countrycode": "120"},
    {"name": "Canada", "alpha2": "CA", "countrycode": "124"},
    {"name": "Cabo Verde", "alpha2": "CV", "countrycode": "132"},
    {"name": "Cayman Islands", "alpha2": "KY", "countrycode": "136"},
    {"name": "Central African Republic", "alpha2": "CF", "countrycode": "140"},
    {"name": "Chad", "alpha2": "TD", "countrycode": "148"},
    {"name": "Chile", "alpha2": "CL", "countrycode": "152"},
    {"name": "China", "alpha2": "CN", "countrycode": "156"},
    {"name": "Christmas Island", "alpha2": "CX", "countrycode": "162"},
    {"name": "Cocos (Keeling) Islands", "alpha2": "CC", "countrycode": "166"},
    {"name": "Colombia", "alpha2": "CO", "countrycode": "170"},
    {"name": "Comoros", "alpha2": "KM", "countrycode": "174"},
    {"name": "Congo", "alpha2": "CG", "countrycode": "178"},
    {"name": "Congo (Democratic Republic of the)", "alpha2": "CD", "countrycode": "180"},
    {"name": "Cook Islands", "alpha2": "CK", "countrycode": "184"},
    {"name": "Costa Rica", "alpha2": "CR", "countrycode": "188"},
    {"name": "Côte d'Ivoire", "alpha2": "CI", "countrycode": "384"},
    {"name": "Croatia", "alpha2": "HR", "countrycode": "191"},
    {"name": "Cuba", "alpha2": "CU", "countrycode": "192"},
    {"name": "Curaçao", "alpha2": "CW", "countrycode": "531"},
    {"name": "Cyprus", "alpha2": "CY", "countrycode": "196"},
    {"name": "Czech Republic", "alpha2": "CZ", "countrycode": "203"},
    {"name": "Denmark", "alpha2": "DK", "countrycode": "208"},
    {"name": "Djibouti", "alpha2": "DJ", "countrycode": "262"},
    {"name": "Dominica", "alpha2": "DM", "countrycode": "212"},
    {"name": "Dominican Republic", "alpha2": "DO", "countrycode": "214"},
    {"name": "Ecuador", "alpha2": "EC", "countrycode": "218"},
    {"name": "Egypt", "alpha2": "EG", "countrycode": "818"},
    {"name": "El Salvador", "alpha2": "SV", "countrycode": "222"},
    {"name": "Equatorial Guinea", "alpha2": "GQ", "countrycode": "226"},
    {"name": "Eritrea", "alpha2": "ER", "countrycode": "232"},
    {"name": "Estonia", "alpha2": "EE", "countrycode": "233"},
    {"name": "Ethiopia", "alpha2": "ET", "countrycode": "231"},
    {"name": "Falkland Islands (Malvinas)", "alpha2": "FK", "countrycode": "238"},
    {"name": "Faroe Islands", "alpha2": "FO", "countrycode": "234"},
    {"name": "Fiji", "alpha2": "FJ", "countrycode": "242"},
    {"name": "Finland", "alpha2": "FI", "countrycode": "246"},
    {"name": "France", "alpha2": "FR", "countrycode": "250"},
    {"name": "French Guiana", "alpha2": "GF", "countrycode": "254"},
    {"name": "French Polynesia", "alpha2": "PF", "countrycode": "258"},
    {"name": "French Southern Territories", "alpha2": "TF", "countrycode": "260"},
    {"name": "Gabon", "alpha2": "GA", "countrycode": "266"},
    {"name": "Gambia", "alpha2": "GM", "countrycode": "270"},
    {"name": "Georgia", "alpha2": "GE", "countrycode": "268"},
    {"name": "Germany", "alpha2": "DE", "countrycode": "276"},
    {"name": "Ghana", "alpha2": "GH", "countrycode": "288"},
    {"name": "Gibraltar", "alpha2": "GI", "countrycode": "292"},
    {"name": "Greece", "alpha2": "GR", "countrycode": "300"},
    {"name": "Greenland", "alpha2": "GL", "countrycode": "304"},
    {"name": "Grenada", "alpha2": "GD", "countrycode": "308"},
    {"name": "Guadeloupe", "alpha2": "GP", "countrycode": "312"},
    {"name": "Guam", "alpha2": "GU", "countrycode": "316"},
    {"name": "Guatemala", "alpha2": "GT", "countrycode": "320"},
    {"name": "Guernsey", "alpha2": "GG", "countrycode": "831"},
    {"name": "Guinea", "alpha2": "GN", "countrycode": "324"},
    {"name": "Guinea-Bissau", "alpha2": "GW", "countrycode": "624"},
    {"name": "Guyana", "alpha2": "GY", "countrycode": "328"},
    {"name": "Haiti", "alpha2": "HT", "countrycode": "332"},
    {"name": "Heard Island and McDonald Islands", "alpha2": "HM", "countrycode": "334"},
    {"name": "Holy See", "alpha2": "VA", "countrycode": "336"},
    {"name": "Honduras", "alpha2": "HN", "countrycode": "340"},
    {"name": "Hong Kong", "alpha2": "HK", "countrycode": "344"},
    {"name": "Hungary", "alpha2": "HU", "countrycode": "348"},
    {"name": "Iceland", "alpha2": "IS", "countrycode": "352"},
    {"name": "India", "alpha2": "IN", "countrycode": "356"},
    {"name": "Indonesia", "alpha2": "ID", "countrycode": "360"},
    {"name": "Iran (Islamic Republic of)", "alpha2": "IR", "countrycode": "364"},
    {"name": "Iraq", "alpha2": "IQ", "countrycode": "368"},
    {"name": "Ireland", "alpha2": "IE", "countrycode": "372"},
    {"name": "Isle of Man", "alpha2": "IM", "countrycode": "833"},
    {"name": "Israel", "alpha2": "IL", "countrycode": "376"},
    {"name": "Italy", "alpha2": "IT", "countrycode": "380"},
    {"name": "Jamaica", "alpha2": "JM", "countrycode": "388"},
    {"name": "Japan", "alpha2": "JP", "countrycode": "392"},
    {"name": "Jersey", "alpha2": "JE", "countrycode": "832"},
    {"name": "Jordan", "alpha2": "JO", "countrycode": "400"},
    {"name": "Kazakhstan", "alpha2": "KZ", "countrycode": "398"},
    {"name": "Kenya", "alpha2": "KE", "countrycode": "404"},
    {"name": "Kiribati", "alpha2": "KI", "countrycode": "296"},
    {"name": "Korea (Democratic People's Republic of)", "alpha2": "KP", "countrycode": "408"},
    {"name": "Korea (Republic of)", "alpha2": "KR", "countrycode": "410"},
    {"name": "Kuwait", "alpha2": "KW", "countrycode": "414"},
    {"name": "Kyrgyzstan", "alpha2": "KG", "countrycode": "417"},
    {"name": "Lao People's Democratic Republic", "alpha2": "LA", "countrycode": "418"},
    {"name": "Latvia", "alpha2": "LV", "countrycode": "428"},
    {"name": "Lebanon", "alpha2": "LB", "countrycode": "422"},
    {"name": "Lesotho", "alpha2": "LS", "countrycode": "426"},
    {"name": "Liberia", "alpha2": "LR", "countrycode": "430"},
    {"name": "Libya", "alpha2": "LY", "countrycode": "434"},
    {"name": "Liechtenstein", "alpha2": "LI", "countrycode": "438"},
    {"name": "Lithuania", "alpha2": "LT", "countrycode": "440"},
    {"name": "Luxembourg", "alpha2": "LU", "countrycode": "442"},
    {"name": "Macao", "alpha2": "MO", "countrycode": "446"},
    {"name": "Macedonia (the former Yugoslav Republic of)", "alpha2": "MK", "countrycode": "807"},
    {"name": "Madagascar", "alpha2": "MG", "countrycode": "450"},
    {"name": "Malawi", "alpha2": "MW", "countrycode": "454"},
    {"name": "Malaysia", "alpha2": "MY", "countrycode": "458"},
    {"name": "Maldives", "alpha2": "MV", "countrycode": "462"},
    {"name": "Mali", "alpha2": "ML", "countrycode": "466"},
    {"name": "Malta", "alpha2": "MT", "countrycode": "470"},
    {"name": "Marshall Islands", "alpha2": "MH", "countrycode": "584"},
    {"name": "Martinique", "alpha2": "MQ", "countrycode": "474"},
    {"name": "Mauritania", "alpha2": "MR", "countrycode": "478"},
    {"name": "Mauritius", "alpha2": "MU", "countrycode": "480"},
    {"name": "Mayotte", "alpha2": "YT", "countrycode": "175"},
    {"name": "Mexico", "alpha2": "MX", "countrycode": "484"},
    {"name": "Micronesia (Federated States of)", "alpha2": "FM", "countrycode": "583"},
    {"name": "Moldova (Republic of)", "alpha2": "MD", "countrycode": "498"},
    {"name": "Monaco", "alpha2": "MC", "countrycode": "492"},
    {"name": "Mongolia", "alpha2": "MN", "countrycode": "496"},
    {"name": "Montenegro", "alpha2": "ME", "countrycode": "499"},
    {"name": "Montserrat", "alpha2": "MS", "countrycode": "500"},
    {"name": "Morocco", "alpha2": "MA", "countrycode": "504"},
    {"name": "Mozambique", "alpha2": "MZ", "countrycode": "508"},
    {"name": "Myanmar", "alpha2": "MM", "countrycode": "104"},
    {"name": "Namibia", "alpha2": "NA", "countrycode": "516"},
    {"name": "Nauru", "alpha2": "NR", "countrycode": "520"},
    {"name": "Nepal", "alpha2": "NP", "countrycode": "524"},
    {"name": "Netherlands", "alpha2": "NL", "countrycode": "528"},
    {"name": "New Caledonia", "alpha2": "NC", "countrycode": "540"},
    {"name": "New Zealand", "alpha2": "NZ", "countrycode": "554"},
    {"name": "Nicaragua", "alpha2": "NI", "countrycode": "558"},
    {"name": "Niger", "alpha2": "NE", "countrycode": "562"},
    {"name": "Nigeria", "alpha2": "NG", "countrycode": "566"},
    {"name": "Niue", "alpha2": "NU", "countrycode": "570"},
    {"name": "Norfolk Island", "alpha2": "NF", "countrycode": "574"},
    {"name": "Northern Mariana Islands", "alpha2": "MP", "countrycode": "580"},
    {"name": "Norway", "alpha2": "NO", "countrycode": "578"},
    {"name": "Oman", "alpha2": "OM", "countrycode": "512"},
    {"name": "Pakistan", "alpha2": "PK", "countrycode": "586"},
    {"name": "Palau", "alpha2": "PW", "countrycode": "585"},
    {"name": "Palestine, State of", "alpha2": "PS", "countrycode": "275"},
    {"name": "Panama", "alpha2": "PA", "countrycode": "591"},
    {"name": "Papua New Guinea", "alpha2": "PG", "countrycode": "598"},
    {"name": "Paraguay", "alpha2": "PY", "countrycode": "600"},
    {"name": "Peru", "alpha2": "PE", "countrycode": "604"},
    {"name": "Philippines", "alpha2": "PH", "countrycode": "608"},
    {"name": "Pitcairn", "alpha2": "PN", "countrycode": "612"},
    {"name": "Poland", "alpha2": "PL", "countrycode": "616"},
    {"name": "Portugal", "alpha2": "PT", "countrycode": "620"},
    {"name": "Puerto Rico", "alpha2": "PR", "countrycode": "630"},
    {"name": "Qatar", "alpha2": "QA", "countrycode": "634"},
    {"name": "Réunion", "alpha2": "RE", "countrycode": "638"},
    {"name": "Romania", "alpha2": "RO", "countrycode": "642"},
    {"name": "Russian Federation", "alpha2": "RU", "countrycode": "643"},
    {"name": "Rwanda", "alpha2": "RW", "countrycode": "646"},
    {"name": "Saint Barthélemy", "alpha2": "BL", "countrycode": "652"},
    {"name": "Saint Helena, Ascension and Tristan da Cunha", "alpha2": "SH", "countrycode": "654"},
    {"name": "Saint Kitts and Nevis", "alpha2": "KN", "countrycode": "659"},
    {"name": "Saint Lucia", "alpha2": "LC", "countrycode": "662"},
    {"name": "Saint Martin (French part)", "alpha2": "MF", "countrycode": "663"},
    {"name": "Saint Pierre and Miquelon", "alpha2": "PM", "countrycode": "666"},
    {"name": "Saint Vincent and the Grenadines", "alpha2": "VC", "countrycode": "670"},
    {"name": "Samoa", "alpha2": "WS", "countrycode": "882"},
    {"name": "San Marino", "alpha2": "SM", "countrycode": "674"},
    {"name": "Sao Tome and Principe", "alpha2": "ST", "countrycode": "678"},
    {"name": "Saudi Arabia", "alpha2": "SA", "countrycode": "682"},
    {"name": "Senegal", "alpha2": "SN", "countrycode": "686"},
    {"name": "Serbia", "alpha2": "RS", "countrycode": "688"},
    {"name": "Seychelles", "alpha2": "SC", "countrycode": "690"},
    {"name": "Sierra Leone", "alpha2": "SL", "countrycode": "694"},
    {"name": "Singapore", "alpha2": "SG", "countrycode": "702"},
    {"name": "Sint Maarten (Dutch part)", "alpha2": "SX", "countrycode": "534"},
    {"name": "Slovakia", "alpha2": "SK", "countrycode": "703"},
    {"name": "Slovenia", "alpha2": "SI", "countrycode": "705"},
    {"name": "Solomon Islands", "alpha2": "SB", "countrycode": "090"},
    {"name": "Somalia", "alpha2": "SO", "countrycode": "706"},
    {"name": "South Africa", "alpha2": "ZA", "countrycode": "710"},
    {"name": "South Georgia and the South Sandwich Islands", "alpha2": "GS", "countrycode": "239"},
    {"name": "South Sudan", "alpha2": "SS", "countrycode": "728"},
    {"name": "Spain", "alpha2": "ES", "countrycode": "724"},
    {"name": "Sri Lanka", "alpha2": "LK", "countrycode": "144"},
    {"name": "Sudan", "alpha2": "SD", "countrycode": "729"},
    {"name": "Suriname", "alpha2": "SR", "countrycode": "740"},
    {"name": "Svalbard and Jan Mayen", "alpha2": "SJ", "countrycode": "744"},
    {"name": "Swaziland", "alpha2": "SZ", "countrycode": "748"},
    {"name": "Sweden", "alpha2": "SE", "countrycode": "752"},
    {"name": "Switzerland", "alpha2": "CH", "countrycode": "756"},
    {"name": "Syrian Arab Republic", "alpha2": "SY", "countrycode": "760"},
    {"name": "Taiwan, Province of China", "alpha2": "TW", "countrycode": "158"},
    {"name": "Tajikistan", "alpha2": "TJ", "countrycode": "762"},
    {"name": "Tanzania, United Republic of", "alpha2": "TZ", "countrycode": "834"},
    {"name": "Thailand", "alpha2": "TH", "countrycode": "764"},
    {"name": "Timor-Leste", "alpha2": "TL", "countrycode": "626"},
    {"name": "Togo", "alpha2": "TG", "countrycode": "768"},
    {"name": "Tokelau", "alpha2": "TK", "countrycode": "772"},
    {"name": "Tonga", "alpha2": "TO", "countrycode": "776"},
    {"name": "Trinidad and Tobago", "alpha2": "TT", "countrycode": "780"},
    {"name": "Tunisia", "alpha2": "TN", "countrycode": "788"},
    {"name": "Turkey", "alpha2": "TR", "countrycode": "792"},
    {"name": "Turkmenistan", "alpha2": "TM", "countrycode": "795"},
    {"name": "Turks and Caicos Islands", "alpha2": "TC", "countrycode": "796"},
    {"name": "Tuvalu", "alpha2": "TV", "countrycode": "798"},
    {"name": "Uganda", "alpha2": "UG", "countrycode": "800"},
    {"name": "Ukraine", "alpha2": "UA", "countrycode": "804"},
    {"name": "United Arab Emirates", "alpha2": "AE", "countrycode": "784"},
    {"name": "United Kingdom of Great Britain and Northern Ireland", "alpha2": "GB", "countrycode": "826"},
    {"name": "United States of America", "alpha2": "US", "countrycode": "840"},
    {"name": "United States Minor Outlying Islands", "alpha2": "UM", "countrycode": "581"},
    {"name": "Uruguay", "alpha2": "UY", "countrycode": "858"},
    {"name": "Uzbekistan", "alpha2": "UZ", "countrycode": "860"},
    {"name": "Vanuatu", "alpha2": "VU", "countrycode": "548"},
    {"name": "Venezuela (Bolivarian Republic of)", "alpha2": "VE", "countrycode": "862"},
    {"name": "Viet Nam", "alpha2": "VN", "countrycode": "704"},
    {"name": "Virgin Islands (British)", "alpha2": "VG", "countrycode": "092"},
    {"name": "Virgin Islands (U.S.)", "alpha2": "VI", "countrycode": "850"},
    {"name": "Wallis and Futuna", "alpha2": "WF", "countrycode": "876"},
    {"name": "Western Sahara", "alpha2": "EH", "countrycode": "732"},
    {"name": "Yemen", "alpha2": "YE", "countrycode": "887"},
    {"name": "Zambia", "alpha2": "ZM", "countrycode": "894"},
    {"name": "Zimbabwe", "alpha2": "ZW", "countrycode": "716"}]

// var languageCodesV1 = [
//     {"code":"AB","name":"Abkhaz","nativeName":"аҧсуа"},
//     {"code":"AA","name":"Afar","nativeName":"Afaraf"},
//     {"code":"AF","name":"Afrikaans","nativeName":"Afrikaans"},
//     {"code":"AK","name":"Akan","nativeName":"Akan"},
//     {"code":"SQ","name":"Albanian","nativeName":"Shqip"},
//     {"code":"AM","name":"Amharic","nativeName":"አማርኛ"},
//     {"code":"AR","name":"Arabic","nativeName":"العربية"},
//     {"code":"AN","name":"Aragonese","nativeName":"Aragonés"},
//     {"code":"HY","name":"Armenian","nativeName":"Հայերեն"},
//     {"code":"AS","name":"Assamese","nativeName":"অসমীয়া"},
//     {"code":"AV","name":"Avaric","nativeName":"авар мацӀ, магӀарул мацӀ"},
//     {"code":"AE","name":"Avestan","nativeName":"avesta"},
//     {"code":"AY","name":"Aymara","nativeName":"aymar aru"},
//     {"code":"AZ","name":"Azerbaijani","nativeName":"azərbaycan dili"},
//     {"code":"BM","name":"Bambara","nativeName":"bamanankan"},
//     {"code":"BA","name":"Bashkir","nativeName":"башҡорт теле"},
//     {"code":"EU","name":"Basque","nativeName":"euskara, euskera"},
//     {"code":"BE","name":"Belarusian","nativeName":"Беларуская"},
//     {"code":"BN","name":"Bengali","nativeName":"বাংলা"},
//     {"code":"BH","name":"Bihari","nativeName":"भोजपुरी"},
//     {"code":"BI","name":"Bislama","nativeName":"Bislama"},
//     {"code":"BS","name":"Bosnian","nativeName":"bosanski jezik"},
//     {"code":"BR","name":"Breton","nativeName":"brezhoneg"},
//     {"code":"BG","name":"Bulgarian","nativeName":"български език"},
//     {"code":"MY","name":"Burmese","nativeName":"ဗမာစာ"},
//     {"code":"CA","name":"Catalan; Valencian","nativeName":"Català"},
//     {"code":"CH","name":"Chamorro","nativeName":"Chamoru"},
//     {"code":"CE","name":"Chechen","nativeName":"нохчийн мотт"},
//     {"code":"NY","name":"Chichewa; Chewa; Nyanja","nativeName":"chiCheŵa, chinyanja"},
//     {"code":"ZH","name":"Chinese","nativeName":"中文 (Zhōngwén), 汉语, 漢語"},
//     {"code":"CV","name":"Chuvash","nativeName":"чӑваш чӗлхи"},
//     {"code":"KW","name":"Cornish","nativeName":"Kernewek"},
//     {"code":"CO","name":"Corsican","nativeName":"corsu, lingua corsa"},
//     {"code":"CR","name":"Cree","nativeName":"ᓀᐦᐃᔭᐍᐏᐣ"},
//     {"code":"HR","name":"Croatian","nativeName":"hrvatski"},
//     {"code":"CS","name":"Czech","nativeName":"česky, čeština"},
//     {"code":"DA","name":"Danish","nativeName":"dansk"},
//     {"code":"DV","name":"Divehi; Dhivehi; Maldivian;","nativeName":"ދިވެހި"},
//     {"code":"NL","name":"Dutch","nativeName":"Nederlands, Vlaams"},
//     {"code":"EN","name":"English","nativeName":"English"},
//     {"code":"EO","name":"Esperanto","nativeName":"Esperanto"},
//     {"code":"ET","name":"Estonian","nativeName":"eesti, eesti keel"},
//     {"code":"EE","name":"Ewe","nativeName":"Eʋegbe"},
//     {"code":"FO","name":"Faroese","nativeName":"føroyskt"},
//     {"code":"FJ","name":"Fijian","nativeName":"vosa Vakaviti"},
//     {"code":"FI","name":"Finnish","nativeName":"suomi, suomen kieli"},
//     {"code":"FR","name":"French","nativeName":"français, langue française"},
//     {"code":"FF","name":"Fula; Fulah; Pulaar; Pular","nativeName":"Fulfulde, Pulaar, Pular"},
//     {"code":"GL","name":"Galician","nativeName":"Galego"},
//     {"code":"KA","name":"Georgian","nativeName":"ქართული"},
//     {"code":"DE","name":"German","nativeName":"Deutsch"},
//     {"code":"EL","name":"Greek, Modern","nativeName":"Ελληνικά"},
//     {"code":"GN","name":"Guaraní","nativeName":"Avañeẽ"},
//     {"code":"GU","name":"Gujarati","nativeName":"ગુજરાતી"},
//     {"code":"HT","name":"Haitian; Haitian Creole","nativeName":"Kreyòl ayisyen"},
//     {"code":"HA","name":"Hausa","nativeName":"Hausa, هَوُسَ"},
//     {"code":"HE","name":"Hebrew (modern)","nativeName":"עברית"},
//     {"code":"HZ","name":"Herero","nativeName":"Otjiherero"},
//     {"code":"HI","name":"Hindi","nativeName":"हिन्दी, हिंदी"},
//     {"code":"HO","name":"Hiri Motu","nativeName":"Hiri Motu"},
//     {"code":"HU","name":"Hungarian","nativeName":"Magyar"},
//     {"code":"IA","name":"Interlingua","nativeName":"Interlingua"},
//     {"code":"ID","name":"Indonesian","nativeName":"Bahasa Indonesia"},
//     {"code":"IE","name":"Interlingue","nativeName":"Originally called Occidental; then Interlingue after WWII"},
//     {"code":"GA","name":"Irish","nativeName":"Gaeilge"},
//     {"code":"IG","name":"Igbo","nativeName":"Asụsụ Igbo"},
//     {"code":"IK","name":"Inupiaq","nativeName":"Iñupiaq, Iñupiatun"},
//     {"code":"IO","name":"Ido","nativeName":"Ido"},
//     {"code":"IS","name":"Icelandic","nativeName":"Íslenska"},
//     {"code":"IT","name":"Italian","nativeName":"Italiano"},
//     {"code":"IU","name":"Inuktitut","nativeName":"ᐃᓄᒃᑎᑐᑦ"},
//     {"code":"JA","name":"Japanese","nativeName":"日本語 (にほんご／にっぽんご)"},
//     {"code":"JV","name":"Javanese","nativeName":"basa Jawa"},
//     {"code":"KL","name":"Kalaallisut, Greenlandic","nativeName":"kalaallisut, kalaallit oqaasii"},
//     {"code":"KN","name":"Kannada","nativeName":"ಕನ್ನಡ"},
//     {"code":"KR","name":"Kanuri","nativeName":"Kanuri"},
//     {"code":"KS","name":"Kashmiri","nativeName":"कश्मीरी, كشميري‎"},
//     {"code":"KK","name":"Kazakh","nativeName":"Қазақ тілі"},
//     {"code":"KM","name":"Khmer","nativeName":"ភាសាខ្មែរ"},
//     {"code":"KI","name":"Kikuyu, Gikuyu","nativeName":"Gĩkũyũ"},
//     {"code":"RW","name":"Kinyarwanda","nativeName":"Ikinyarwanda"},
//     {"code":"KY","name":"Kirghiz, Kyrgyz","nativeName":"кыргыз тили"},
//     {"code":"KV","name":"Komi","nativeName":"коми кыв"},
//     {"code":"KG","name":"Kongo","nativeName":"KiKongo"},
//     {"code":"KO","name":"Korean","nativeName":"한국어 (韓國語), 조선말 (朝鮮語)"},
//     {"code":"KU","name":"Kurdish","nativeName":"Kurdî, كوردی‎"},
//     {"code":"KJ","name":"Kwanyama, Kuanyama","nativeName":"Kuanyama"},
//     {"code":"LA","name":"Latin","nativeName":"latine, lingua latina"},
//     {"code":"LB","name":"Luxembourgish, Letzeburgesch","nativeName":"Lëtzebuergesch"},
//     {"code":"LG","name":"Luganda","nativeName":"Luganda"},
//     {"code":"LI","name":"Limburgish, Limburgan, Limburger","nativeName":"Limburgs"},
//     {"code":"LN","name":"Lingala","nativeName":"Lingála"},
//     {"code":"LO","name":"Lao","nativeName":"ພາສາລາວ"},
//     {"code":"LT","name":"Lithuanian","nativeName":"lietuvių kalba"},
//     {"code":"LU","name":"Luba-Katanga","nativeName":""},
//     {"code":"LV","name":"Latvian","nativeName":"latviešu valoda"},
//     {"code":"GV","name":"Manx","nativeName":"Gaelg, Gailck"},
//     {"code":"MK","name":"Macedonian","nativeName":"македонски јазик"},
//     {"code":"MG","name":"Malagasy","nativeName":"Malagasy fiteny"},
//     {"code":"MS","name":"Malay","nativeName":"bahasa Melayu, بهاس ملايو‎"},
//     {"code":"ML","name":"Malayalam","nativeName":"മലയാളം"},
//     {"code":"MT","name":"Maltese","nativeName":"Malti"},
//     {"code":"MI","name":"Māori","nativeName":"te reo Māori"},
//     {"code":"MR","name":"Marathi (Marāṭhī)","nativeName":"मराठी"},
//     {"code":"MH","name":"Marshallese","nativeName":"Kajin M̧ajeļ"},
//     {"code":"MN","name":"Mongolian","nativeName":"монгол"},
//     {"code":"NA","name":"Nauru","nativeName":"Ekakairũ Naoero"},
//     {"code":"NV","name":"Navajo, Navaho","nativeName":"Diné bizaad, Dinékʼehǰí"},
//     {"code":"NB","name":"Norwegian Bokmål","nativeName":"Norsk bokmål"},
//     {"code":"ND","name":"North Ndebele","nativeName":"isiNdebele"},
//     {"code":"NE","name":"Nepali","nativeName":"नेपाली"},
//     {"code":"NG","name":"Ndonga","nativeName":"Owambo"},
//     {"code":"NN","name":"Norwegian Nynorsk","nativeName":"Norsk nynorsk"},
//     {"code":"NO","name":"Norwegian","nativeName":"Norsk"},
//     {"code":"II","name":"Nuosu","nativeName":"ꆈꌠ꒿ Nuosuhxop"},
//     {"code":"NR","name":"South Ndebele","nativeName":"isiNdebele"},
//     {"code":"OC","name":"Occitan","nativeName":"Occitan"},
//     {"code":"OJ","name":"Ojibwe, Ojibwa","nativeName":"ᐊᓂᔑᓈᐯᒧᐎᓐ"},
//     {"code":"CU","name":"Old Church Slavonic, Church Slavic, Church Slavonic, Old Bulgarian, Old Slavonic","nativeName":"ѩзыкъ словѣньскъ"},
//     {"code":"OM","name":"Oromo","nativeName":"Afaan Oromoo"},
//     {"code":"OR","name":"Oriya","nativeName":"ଓଡ଼ିଆ"},
//     {"code":"OS","name":"Ossetian, Ossetic","nativeName":"ирон æвзаг"},
//     {"code":"PA","name":"Panjabi, Punjabi","nativeName":"ਪੰਜਾਬੀ, پنجابی‎"},
//     {"code":"PI","name":"Pāli","nativeName":"पाऴि"},
//     {"code":"FA","name":"Persian","nativeName":"فارسی"},
//     {"code":"PL","name":"Polish","nativeName":"polski"},
//     {"code":"PS","name":"Pashto, Pushto","nativeName":"پښتو"},
//     {"code":"PT","name":"Portuguese","nativeName":"Português"},
//     {"code":"QU","name":"Quechua","nativeName":"Runa Simi, Kichwa"},
//     {"code":"RM","name":"Romansh","nativeName":"rumantsch grischun"},
//     {"code":"RN","name":"Kirundi","nativeName":"kiRundi"},
//     {"code":"RO","name":"Romanian, Moldavian, Moldovan","nativeName":"română"},
//     {"code":"RU","name":"Russian","nativeName":"русский язык"},
//     {"code":"SA","name":"Sanskrit (Saṁskṛta)","nativeName":"संस्कृतम्"},
//     {"code":"SC","name":"Sardinian","nativeName":"sardu"},
//     {"code":"SD","name":"Sindhi","nativeName":"सिन्धी, سنڌي، سندھی‎"},
//     {"code":"SE","name":"Northern Sami","nativeName":"Davvisámegiella"},
//     {"code":"SM","name":"Samoan","nativeName":"gagana faa Samoa"},
//     {"code":"SG","name":"Sango","nativeName":"yângâ tî sängö"},
//     {"code":"SR","name":"Serbian","nativeName":"српски језик"},
//     {"code":"GD","name":"Scottish Gaelic; Gaelic","nativeName":"Gàidhlig"},
//     {"code":"SN","name":"Shona","nativeName":"chiShona"},
//     {"code":"SI","name":"Sinhala, Sinhalese","nativeName":"සිංහල"},
//     {"code":"SK","name":"Slovak","nativeName":"slovenčina"},
//     {"code":"SL","name":"Slovene","nativeName":"slovenščina"},
//     {"code":"SO","name":"Somali","nativeName":"Soomaaliga, af Soomaali"},
//     {"code":"ST","name":"Southern Sotho","nativeName":"Sesotho"},
//     {"code":"ES","name":"Spanish; Castilian","nativeName":"español, castellano"},
//     {"code":"SU","name":"Sundanese","nativeName":"Basa Sunda"},
//     {"code":"SW","name":"Swahili","nativeName":"Kiswahili"},
//     {"code":"SS","name":"Swati","nativeName":"SiSwati"},
//     {"code":"SV","name":"Swedish","nativeName":"svenska"},
//     {"code":"TA","name":"Tamil","nativeName":"தமிழ்"},
//     {"code":"TE","name":"Telugu","nativeName":"తెలుగు"},
//     {"code":"TG","name":"Tajik","nativeName":"тоҷикӣ, toğikī, تاجیکی‎"},
//     {"code":"TH","name":"Thai","nativeName":"ไทย"},
//     {"code":"TI","name":"Tigrinya","nativeName":"ትግርኛ"},
//     {"code":"BO","name":"Tibetan Standard, Tibetan, Central","nativeName":"བོད་ཡིག"},
//     {"code":"TK","name":"Turkmen","nativeName":"Türkmen, Түркмен"},
//     {"code":"TL","name":"Tagalog","nativeName":"Wikang Tagalog, ᜏᜒᜃᜅ᜔ ᜆᜄᜎᜓᜄ᜔"},
//     {"code":"TN","name":"Tswana","nativeName":"Setswana"},
//     {"code":"TO","name":"Tonga (Tonga Islands)","nativeName":"faka Tonga"},
//     {"code":"TR","name":"Turkish","nativeName":"Türkçe"},
//     {"code":"TS","name":"Tsonga","nativeName":"Xitsonga"},
//     {"code":"TT","name":"Tatar","nativeName":"татарча, tatarça, تاتارچا‎"},
//     {"code":"TW","name":"Twi","nativeName":"Twi"},
//     {"code":"TY","name":"Tahitian","nativeName":"Reo Tahiti"},
//     {"code":"UG","name":"Uighur, Uyghur","nativeName":"Uyƣurqə, ئۇيغۇرچە‎"},
//     {"code":"UK","name":"Ukrainian","nativeName":"українська"},
//     {"code":"UR","name":"Urdu","nativeName":"اردو"},
//     {"code":"UZ","name":"Uzbek","nativeName":"zbek, Ўзбек, أۇزبېك‎"},
//     {"code":"VE","name":"Venda","nativeName":"Tshivenḓa"},
//     {"code":"VI","name":"Vietnamese","nativeName":"Tiếng Việt"},
//     {"code":"VO","name":"Volapük","nativeName":"Volapük"},
//     {"code":"WA","name":"Walloon","nativeName":"Walon"},
//     {"code":"CY","name":"Welsh","nativeName":"Cymraeg"},
//     {"code":"WO","name":"Wolof","nativeName":"Wollof"},
//     {"code":"FY","name":"Western Frisian","nativeName":"Frysk"},
//     {"code":"XH","name":"Xhosa","nativeName":"isiXhosa"},
//     {"code":"YI","name":"Yiddish","nativeName":"ייִדיש"},
//     {"code":"YO","name":"Yoruba","nativeName":"Yorùbá"},
//     {"code":"ZA","name":"Zhuang, Chuang","nativeName":"Saɯ cueŋƅ, Saw cuengh"}
// ];


var languageCodesV2 = [
    {
        "iso639v1":"en",
        "english":"English",
        "french":"anglais",
        "german":"Englisch"
    },
    {
        "iso639v1":"fr",
        "english":"French",
        "french":"francais",
        "german":"Französisch"
    },
    {
        "iso639v1":"es",
        "english":"Spanish",
        "french":"espagnol",
        "german":"Spanisch"
    },
    {
        "iso639v1":"de",
        "english":"German",
        "french":"allemand",
        "german":"Deutsch"
    },
    {
        "iso639v1":"hu",
        "english":"Hungarian",
        "french":"hungrois",
        "german":"Ungarisch"
    },
    {
        "iso639v1":"cs",
        "english":"Czech",
        "french":"tchéque",
        "german":"Tschechisch"
    },
    {
        "iso639v1":"it",
        "english":"Italian",
        "french":"italien",
        "german":"Italienisch"
    },
    {
        "iso639v1":"nl",
        "english":"Dutch",
        "french":"néerlandais",
        "german":"Niederländisch"
    },
    {
        "iso639v1":"gr",
        "english":"Greek",
        "french":"grec",
        "german":"Griechisch"
    },
    {
        "iso639v1":"hr",
        "english":"croatian",
        "french":"croate",
        "german":"Kroatisch"
    },
    {
        "iso639v1":"si",
        "english":"slovenian",
        "french":"slovène",
        "german":"Slowenisch"
    }

]

var languageCodesV3 = [
    {
        "code": "AF",
        "name": "Afghanistan"
    },
    {
        "code": "EG",
        "name": "Ägypten"
    },
    {
        "code": "AL",
        "name": "Albanien"
    },
    {
        "code": "DZ",
        "name": "Algerien"
    },
    {
        "code": "AD",
        "name": "Andorra"
    },
    {
        "code": "AO",
        "name": "Angola"
    },
    {
        "code": "AI",
        "name": "Anguilla"
    },
    {
        "code": "AQ",
        "name": "Antarktis"
    },
    {
        "code": "AG",
        "name": "Antigua und Barbuda"
    },
    {
        "code": "GQ",
        "name": "Äquatorial Guinea"
    },
    {
        "code": "AR",
        "name": "Argentinien"
    },
    {
        "code": "AM",
        "name": "Armenien"
    },
    {
        "code": "AW",
        "name": "Aruba"
    },
    {
        "code": "AZ",
        "name": "Aserbaidschan"
    },
    {
        "code": "ET",
        "name": "Äthiopien"
    },
    {
        "code": "AU",
        "name": "Australien"
    },
    {
        "code": "BS",
        "name": "Bahamas"
    },
    {
        "code": "BH",
        "name": "Bahrain"
    },
    {
        "code": "BD",
        "name": "Bangladesh"
    },
    {
        "code": "BB",
        "name": "Barbados"
    },
    {
        "code": "BE",
        "name": "Belgien"
    },
    {
        "code": "BZ",
        "name": "Belize"
    },
    {
        "code": "BJ",
        "name": "Benin"
    },
    {
        "code": "BM",
        "name": "Bermudas"
    },
    {
        "code": "BT",
        "name": "Bhutan"
    },
    {
        "code": "MM",
        "name": "Birma"
    },
    {
        "code": "BO",
        "name": "Bolivien"
    },
    {
        "code": "BA",
        "name": "Bosnien-Herzegowina"
    },
    {
        "code": "BW",
        "name": "Botswana"
    },
    {
        "code": "BV",
        "name": "Bouvet Inseln"
    },
    {
        "code": "BR",
        "name": "Brasilien"
    },
    {
        "code": "IO",
        "name": "Britisch-Indischer Ozean"
    },
    {
        "code": "BN",
        "name": "Brunei"
    },
    {
        "code": "BG",
        "name": "Bulgarien"
    },
    {
        "code": "BF",
        "name": "Burkina Faso"
    },
    {
        "code": "BI",
        "name": "Burundi"
    },
    {
        "code": "CL",
        "name": "Chile"
    },
    {
        "code": "CN",
        "name": "China"
    },
    {
        "code": "CX",
        "name": "Christmas Island"
    },
    {
        "code": "CK",
        "name": "Cook Inseln"
    },
    {
        "code": "CR",
        "name": "Costa Rica"
    },
    {
        "code": "DK",
        "name": "Dänemark"
    },
    {
        "code": "DE",
        "name": "Deutschland"
    },
    {
        "code": "DJ",
        "name": "Djibuti"
    },
    {
        "code": "DM",
        "name": "Dominika"
    },
    {
        "code": "DO",
        "name": "Dominikanische Republik"
    },
    {
        "code": "EC",
        "name": "Ecuador"
    },
    {
        "code": "SV",
        "name": "El Salvador"
    },
    {
        "code": "CI",
        "name": "Elfenbeinküste"
    },
    {
        "code": "ER",
        "name": "Eritrea"
    },
    {
        "code": "EE",
        "name": "Estland"
    },
    {
        "code": "FK",
        "name": "Falkland Inseln"
    },
    {
        "code": "FO",
        "name": "Färöer Inseln"
    },
    {
        "code": "FJ",
        "name": "Fidschi"
    },
    {
        "code": "FI",
        "name": "Finnland"
    },
    {
        "code": "FR",
        "name": "Frankreich"
    },
    {
        "code": "GF",
        "name": "französisch Guyana"
    },
    {
        "code": "PF",
        "name": "Französisch Polynesien"
    },
    {
        "code": "TF",
        "name": "Französisches Süd-Territorium"
    },
    {
        "code": "GA",
        "name": "Gabun"
    },
    {
        "code": "GM",
        "name": "Gambia"
    },
    {
        "code": "GE",
        "name": "Georgien"
    },
    {
        "code": "GH",
        "name": "Ghana"
    },
    {
        "code": "GI",
        "name": "Gibraltar"
    },
    {
        "code": "GD",
        "name": "Grenada"
    },
    {
        "code": "GR",
        "name": "Griechenland"
    },
    {
        "code": "GL",
        "name": "GrÃ¶nland"
    },
    {
        "code": "UK",
        "name": "GroÃŸbritannien"
    },
    {
        "code": "GB",
        "name": "GroÃŸbritannien (UK)"
    },
    {
        "code": "GP",
        "name": "Guadeloupe"
    },
    {
        "code": "GU",
        "name": "Guam"
    },
    {
        "code": "GT",
        "name": "Guatemala"
    },
    {
        "code": "GN",
        "name": "Guinea"
    },
    {
        "code": "GW",
        "name": "Guinea Bissau"
    },
    {
        "code": "GY",
        "name": "Guyana"
    },
    {
        "code": "HT",
        "name": "Haiti"
    },
    {
        "code": "HM",
        "name": "Heard und McDonald Islands"
    },
    {
        "code": "HN",
        "name": "Honduras"
    },
    {
        "code": "HK",
        "name": "Hong Kong"
    },
    {
        "code": "IN",
        "name": "Indien"
    },
    {
        "code": "ID",
        "name": "Indonesien"
    },
    {
        "code": "IQ",
        "name": "Irak"
    },
    {
        "code": "IR",
        "name": "Iran"
    },
    {
        "code": "IE",
        "name": "Irland"
    },
    {
        "code": "IS",
        "name": "Island"
    },
    {
        "code": "IL",
        "name": "Israel"
    },
    {
        "code": "IT",
        "name": "Italien"
    },
    {
        "code": "JM",
        "name": "Jamaika"
    },
    {
        "code": "JP",
        "name": "Japan"
    },
    {
        "code": "YE",
        "name": "Jemen"
    },
    {
        "code": "JO",
        "name": "Jordanien"
    },
    {
        "code": "YU",
        "name": "Jugoslawien"
    },
    {
        "code": "KY",
        "name": "Kaiman Inseln"
    },
    {
        "code": "KH",
        "name": "Kambodscha"
    },
    {
        "code": "CM",
        "name": "Kamerun"
    },
    {
        "code": "CA",
        "name": "Kanada"
    },
    {
        "code": "CV",
        "name": "Kap Verde"
    },
    {
        "code": "KZ",
        "name": "Kasachstan"
    },
    {
        "code": "KE",
        "name": "Kenia"
    },
    {
        "code": "KG",
        "name": "Kirgisistan"
    },
    {
        "code": "KI",
        "name": "Kiribati"
    },
    {
        "code": "CC",
        "name": "Kokosinseln"
    },
    {
        "code": "CO",
        "name": "Kolumbien"
    },
    {
        "code": "KM",
        "name": "Komoren"
    },
    {
        "code": "CG",
        "name": "Kongo"
    },
    {
        "code": "CD",
        "name": "Kongo, Demokratische Republik"
    },
    {
        "code": "HR",
        "name": "Kroatien"
    },
    {
        "code": "CU",
        "name": "Kuba"
    },
    {
        "code": "KW",
        "name": "Kuwait"
    },
    {
        "code": "LA",
        "name": "Laos"
    },
    {
        "code": "LS",
        "name": "Lesotho"
    },
    {
        "code": "LV",
        "name": "Lettland"
    },
    {
        "code": "LB",
        "name": "Libanon"
    },
    {
        "code": "LR",
        "name": "Liberia"
    },
    {
        "code": "LY",
        "name": "Libyen"
    },
    {
        "code": "LI",
        "name": "Liechtenstein"
    },
    {
        "code": "LT",
        "name": "Litauen"
    },
    {
        "code": "LU",
        "name": "Luxemburg"
    },
    {
        "code": "MO",
        "name": "Macao"
    },
    {
        "code": "MG",
        "name": "Madagaskar"
    },
    {
        "code": "MW",
        "name": "Malawi"
    },
    {
        "code": "MY",
        "name": "Malaysia"
    },
    {
        "code": "MV",
        "name": "Malediven"
    },
    {
        "code": "ML",
        "name": "Mali"
    },
    {
        "code": "MT",
        "name": "Malta"
    },
    {
        "code": "MP",
        "name": "Marianen"
    },
    {
        "code": "MA",
        "name": "Marokko"
    },
    {
        "code": "MH",
        "name": "Marshall Inseln"
    },
    {
        "code": "MQ",
        "name": "Martinique"
    },
    {
        "code": "MR",
        "name": "Mauretanien"
    },
    {
        "code": "MU",
        "name": "Mauritius"
    },
    {
        "code": "YT",
        "name": "Mayotte"
    },
    {
        "code": "MK",
        "name": "Mazedonien"
    },
    {
        "code": "MX",
        "name": "Mexiko"
    },
    {
        "code": "FM",
        "name": "Mikronesien"
    },
    {
        "code": "MZ",
        "name": "Mocambique"
    },
    {
        "code": "MD",
        "name": "Moldavien"
    },
    {
        "code": "MC",
        "name": "Monaco"
    },
    {
        "code": "MN",
        "name": "Mongolei"
    },
    {
        "code": "MS",
        "name": "Montserrat"
    },
    {
        "code": "NA",
        "name": "Namibia"
    },
    {
        "code": "NR",
        "name": "Nauru"
    },
    {
        "code": "NP",
        "name": "Nepal"
    },
    {
        "code": "NC",
        "name": "Neukaledonien"
    },
    {
        "code": "NZ",
        "name": "Neuseeland"
    },
    {
        "code": "NI",
        "name": "Nicaragua"
    },
    {
        "code": "NL",
        "name": "Niederlande"
    },
    {
        "code": "AN",
        "name": "Niederländische Antillen"
    },
    {
        "code": "NE",
        "name": "Niger"
    },
    {
        "code": "NG",
        "name": "Nigeria"
    },
    {
        "code": "NU",
        "name": "Niue"
    },
    {
        "code": "KP",
        "name": "Nord Korea"
    },
    {
        "code": "NF",
        "name": "Norfolk Inseln"
    },
    {
        "code": "NO",
        "name": "Norwegen"
    },
    {
        "code": "OM",
        "name": "Oman"
    },
    {
        "code": "AT",
        "name": "Österreich"
    },
    {
        "code": "PK",
        "name": "Pakistan"
    },
    {
        "code": "PS",
        "name": "Palästina"
    },
    {
        "code": "PW",
        "name": "Palau"
    },
    {
        "code": "PA",
        "name": "Panama"
    },
    {
        "code": "PG",
        "name": "Papua Neuguinea"
    },
    {
        "code": "PY",
        "name": "Paraguay"
    },
    {
        "code": "PE",
        "name": "Peru"
    },
    {
        "code": "PH",
        "name": "Philippinen"
    },
    {
        "code": "PN",
        "name": "Pitcairn"
    },
    {
        "code": "PL",
        "name": "Polen"
    },
    {
        "code": "PT",
        "name": "Portugal"
    },
    {
        "code": "PR",
        "name": "Puerto Rico"
    },
    {
        "code": "QA",
        "name": "Qatar"
    },
    {
        "code": "RE",
        "name": "Reunion"
    },
    {
        "code": "RW",
        "name": "Ruanda"
    },
    {
        "code": "RO",
        "name": "Rumänien"
    },
    {
        "code": "RU",
        "name": "Russland"
    },
    {
        "code": "LC",
        "name": "Saint Lucia"
    },
    {
        "code": "ZM",
        "name": "Sambia"
    },
    {
        "code": "AS",
        "name": "Samoa"
    },
    {
        "code": "WS",
        "name": "Samoa"
    },
    {
        "code": "SM",
        "name": "San Marino"
    },
    {
        "code": "ST",
        "name": "Sao Tome"
    },
    {
        "code": "SA",
        "name": "Saudi Arabien"
    },
    {
        "code": "SE",
        "name": "Schweden"
    },
    {
        "code": "CH",
        "name": "Schweiz"
    },
    {
        "code": "SN",
        "name": "Senegal"
    },
    {
        "code": "SC",
        "name": "Seychellen"
    },
    {
        "code": "SL",
        "name": "Sierra Leone"
    },
    {
        "code": "SG",
        "name": "Singapur"
    },
    {
        "code": "SK",
        "name": "Slowakei -slowakische Republik-"
    },
    {
        "code": "SI",
        "name": "Slowenien"
    },
    {
        "code": "SB",
        "name": "Solomon Inseln"
    },
    {
        "code": "SO",
        "name": "Somalia"
    },
    {
        "code": "GS",
        "name": "South Georgia, South Sandwich Isl."
    },
    {
        "code": "ES",
        "name": "Spanien"
    },
    {
        "code": "LK",
        "name": "Sri Lanka"
    },
    {
        "code": "SH",
        "name": "St. Helena"
    },
    {
        "code": "KN",
        "name": "St. Kitts Nevis Anguilla"
    },
    {
        "code": "PM",
        "name": "St. Pierre und Miquelon"
    },
    {
        "code": "VC",
        "name": "St. Vincent"
    },
    {
        "code": "KR",
        "name": "Süd Korea"
    },
    {
        "code": "ZA",
        "name": "Südafrika"
    },
    {
        "code": "SD",
        "name": "Sudan"
    },
    {
        "code": "SR",
        "name": "Surinam"
    },
    {
        "code": "SJ",
        "name": "Svalbard und Jan Mayen Islands"
    },
    {
        "code": "SZ",
        "name": "Swasiland"
    },
    {
        "code": "SY",
        "name": "Syrien"
    },
    {
        "code": "TJ",
        "name": "Tadschikistan"
    },
    {
        "code": "TW",
        "name": "Taiwan"
    },
    {
        "code": "TZ",
        "name": "Tansania"
    },
    {
        "code": "TH",
        "name": "Thailand"
    },
    {
        "code": "TP",
        "name": "Timor"
    },
    {
        "code": "TG",
        "name": "Togo"
    },
    {
        "code": "TK",
        "name": "Tokelau"
    },
    {
        "code": "TO",
        "name": "Tonga"
    },
    {
        "code": "TT",
        "name": "Trinidad Tobago"
    },
    {
        "code": "TD",
        "name": "Tschad"
    },
    {
        "code": "CZ",
        "name": "Tschechische Republik"
    },
    {
        "code": "TN",
        "name": "Tunesien"
    },
    {
        "code": "TR",
        "name": "Türkei"
    },
    {
        "code": "TM",
        "name": "Turkmenistan"
    },
    {
        "code": "TC",
        "name": "Turks und Kaikos Inseln"
    },
    {
        "code": "TV",
        "name": "Tuvalu"
    },
    {
        "code": "UG",
        "name": "Uganda"
    },
    {
        "code": "UA",
        "name": "Ukraine"
    },
    {
        "code": "HU",
        "name": "Ungarn"
    },
    {
        "code": "UY",
        "name": "Uruguay"
    },
    {
        "code": "UZ",
        "name": "Usbekistan"
    },
    {
        "code": "VU",
        "name": "Vanuatu"
    },
    {
        "code": "VA",
        "name": "Vatikan"
    },
    {
        "code": "VE",
        "name": "Venezuela"
    },
    {
        "code": "AE",
        "name": "Vereinigte Arabische Emirate"
    },
    {
        "code": "US",
        "name": "Vereinigte Staaten von Amerika"
    },
    {
        "code": "VN",
        "name": "Vietnam"
    },
    {
        "code": "VG",
        "name": "Virgin Island (Brit.)"
    },
    {
        "code": "VI",
        "name": "Virgin Island (USA)"
    },
    {
        "code": "WF",
        "name": "Wallis et Futuna"
    },
    {
        "code": "BY",
        "name": "Weissrussland"
    },
    {
        "code": "EH",
        "name": "Westsahara"
    },
    {
        "code": "CF",
        "name": "Zentralafrikanische Republik"
    },
    {
        "code": "ZW",
        "name": "Zimbabwe"
    },
    {
        "code": "CY",
        "name": "Zypern"
    }
]

angular.module('app').controller('indexCtrl', [
    '$scope',
    '$location',
    '$rootScope',
    'requestManager',
    'util',
    '$window',
    'logOutTimer',
    'cookieManager',
    'sharedMemory',
    'Notification',
    '$route',
    function ($scope, $location, $rootScope, requestManager, util, $window, logOutTimer, cookieManager, sharedMemory, Notification, $route) {
        this.$onInit = function () {
            $scope.location = $location.path();
            $scope.branding = {
                settings: [],
                statusOk: true,
                currentlyLoading: true
            };
            $scope.brandingLogo = {
                img: undefined,
                currentlyLoading: false
            };
            $scope.brandingWallpaper = {
                img: undefined,
                currentlyLoading: false
            };
            if ($scope.location === '/login' || $scope.location.startsWith('/new-password')) {
                $scope.brandingLogo.currentlyLoading = true;
                $scope.brandingWallpaper.currentlyLoading = true;
                requestManager.routes.branding.getBrandingLogo().then(
                    function (response) {
                        if (response.data !== undefined && response.data.file !== undefined && response.data.file !== null) {
                            $scope.brandingLogo.img = response.data.file;
                            $rootScope.brandingLogo = $scope.brandingLogo.img;
                        }
                        $scope.brandingLogo.currentlyLoading = false;
                    },
                    function () {
                        $scope.brandingLogo.currentlyLoading = false;
                    }
                );
                requestManager.routes.branding.getBrandingWallpaper().then(
                    function (response) {
                        if (response.data !== undefined && response.data.file !== undefined && response.data.file !== null) {
                            $scope.brandingWallpaper.img = response.data.file;
                            $scope.imageBase64 = $scope.brandingWallpaper.img;
                        }
                        $scope.brandingWallpaper.currentlyLoading = false;
                    },
                    function () {
                        $scope.brandingWallpaper.currentlyLoading = false;
                    }
                );
            }
            requestManager.routes.branding.getBranding().then(
                function (response) {
                    $scope.branding.currentlyLoading = false;
                    $scope.branding.settings = response.data;
                    if ($scope.branding.settings.length > 0) {
                        $scope.branding.isBrandingActive = $scope.branding.settings.find(setting => setting.settingCode === 'isBrandingActive');
                        if ($scope.branding.isBrandingActive.settingValue1 === '1') {
                            // $scope.branding.brandingLogo = $scope.branding.settings.find(setting => setting.settingCode === 'brandingLogo');
                            // $rootScope.brandingLogo = $scope.branding.brandingLogo.file;
                            // $scope.branding.brandingWallpaper = $scope.branding.settings.find(setting => setting.settingCode === 'brandingWallpaper');
                            // $scope.imageBase64 = $scope.branding.brandingWallpaper.file;
                            $scope.branding.brandingPrimaryColor = $scope.branding.settings.find(setting => setting.settingCode === 'brandingPrimaryColor');
                            // $scope.branding.brandingSecondaryColor = $scope.branding.settings.find(setting => setting.settingCode === 'brandingSecondaryColor');
                            $scope.branding.poweredByDebitorcloudPosition = $scope.branding.settings.find(setting => setting.settingCode === 'poweredByDebitorcloudPosition');
                        }
                    }
                    if ($scope.branding.settings.length > 0 && $scope.branding.isBrandingActive.settingValue1 === '1' && $scope.branding.brandingPrimaryColor.settingValue1) {
                        document.documentElement.style.setProperty('--primary-color', $scope.branding.brandingPrimaryColor.settingValue1);
                        const rgbValues = hexToRgb($scope.branding.brandingPrimaryColor.settingValue1);
                        let r = rgbValues.r;
                        let g = rgbValues.g;
                        let b = rgbValues.b;
                        $rootScope.brandingThemeDark = dcThemeDark(r, g, b);
                        const borderColor = generateBorderColor(r, g, b);
                        document.documentElement.style.setProperty('--primary-border-color', borderColor);
                    }
                },
                function () {
                    $scope.branding.statusOk = false;
                    $scope.branding.currentlyLoading = false;
                }
            );

            if ($scope.location !== '/login' || !$scope.location.startsWith('/new-password')) {
                initializeData();
            }
        };

        $rootScope.$on("$routeChangeStart", function (url, next) {
            // console.log($scope.location);
            // console.log(next.$$route.originalPath);
            if ($scope.location === '/login' && next.$$route.originalPath !== '/login') {
                $scope.location = next.$$route.originalPath;
                initializeData();
            }
            if ($scope.location === '/setup-wizard' && next.$$route.originalPath === '/dashboard') {
                $scope.location = '/dashboard';
            }
            if (($scope.location === '/dashboard') && next.$$route.originalPath === '/setup-wizard') {
                $scope.location = '/setup-wizard';
            }
            if (next.$$route.originalPath === '/error-page') {
                $scope.location = '/error-page';
            }
            if (($scope.location === '/error-page') && next.$$route.originalPath !== '/error-page') {
                $scope.location = next.$$route.originalPath;
            }
        });
        
        function dcThemeDark(r, g, b) {
            return 0.299 * r + 0.587 * g + 0.114 * b < 128;
        }

        function generateBorderColor(r, g, b) {
            // for dark colors -> lighter border
            // for light colors -> darker border
            if (0.299 * r + 0.587 * g + 0.114 * b < 128) {
                r += 50;
                g += 50;
                b += 50;
            } else {
                r -= 50;
                g -= 50;
                b -= 50;
            }
            return rgbToHex(r, g, b);
        }

        function hexToRgb(hex) {
            var r = parseInt(hex.substring(1, 3), 16);
            var g = parseInt(hex.substring(3, 5), 16);
            var b = parseInt(hex.substring(5, 7), 16);
            return { r, g, b };
        }

        function rgbToHex(r, g, b) {
            r = Math.max(0, Math.min(255, Math.round(r)));
            g = Math.max(0, Math.min(255, Math.round(g)));
            b = Math.max(0, Math.min(255, Math.round(b)));
            return "#" + ((r << 16) | (g << 8) | b).toString(16).padStart(6, "0");
        }

        function initializeData() {
            initVariables();
            initScope();

            setLogOutTimer();

            requestManager.routes.settings.getSettings($rootScope.authData.sessionId).then(
                function (response) {
                    if ($scope.collectiveAccount === undefined) {
                        response.data.find(setting => {
                            if (setting.settingCode === 'collectiveAccount') {
                                $scope.collectiveAccount = setting.settingValue1;
                            }
                        });
                    }
                    if ($scope.isTaxFirm === undefined) {
                        response.data.find(setting => {
                            if (setting.settingCode === 'isTaxFirm') {
                                $scope.isTaxFirm = setting.settingValue1;
                            }
                        });
                    }
                    $scope.settings.currentlyLoading = false;
                    $scope.settings.content = response.data;
                    $scope.economicYear = util.getSettingByName($scope.settings.content, 'datevConnectSource').settingValue2;
                    $scope.companyLogo = util.getSettingByName($scope.settings.content, "companyLogo");

                    $scope.imageLandscape = undefined;
                    let img = new Image();
                    img.src = $scope.companyLogo.file;
                    img.onload = function () {
                        $scope.imageLandscape = img.naturalWidth > img.naturalHeight;
                    }

                    $scope.economicYear = $scope.economicYear.substring(0, 4);
                    $scope.isActualEconomicYear = $scope.economicYear == $scope.actualYear;

                    let mailApprovalSetting = $scope.settings.content.find((setting) => {
                        return setting.settingCode === "mailApprovalActivated"
                    });
                    if (mailApprovalSetting.settingValue1 === "1") {
                        $scope.showEmailApprovalButton = true;
                    }
                },
                function (response) {
                    //Todo catch error
                }
            );

            getStatus();
        }

        function initVariables() {
            $scope.selection = {
                selectedMonth: 1,
                months: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
                selectedDay: 1,
                days: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31]
            };
            $scope.settings = {
                currentlyLoading: true,
                content: undefined
            };
            $scope.collectiveAccount = undefined;
            $scope.isTaxFirm = undefined;
            $scope.userAdmin = false;
            if ($rootScope.settings) {
                $rootScope.settings.find(setting => {
                    if (setting.settingCode === 'collectiveAccount') {
                        $scope.collectiveAccount = setting.settingValue1;
                    }
                    if (setting.settingCode === 'isTaxFirm') {
                        $scope.isTaxFirm = setting.settingValue1;
                    }
                });
            }
            if ($rootScope.authData && $rootScope.authData.isAdmin && $rootScope.authData.isAdmin === '1') {
                $scope.userAdmin = true;
            }
            $scope.economicYearResponse = {
                title: undefined,
                text: undefined,
                sent: false,
                successful: false
            };
            $scope.isActualEconomicYear = true;
            $scope.actualYear = new Date().getFullYear();
            $scope.syncStatus = undefined;
            $scope.error = false;
            $scope.expireDate = undefined;
            $scope.isEconomicYearLoading = false;
            $scope.canSyncNow = false;
            $scope.companyName = undefined;
        }

        function initScope() {
            $scope.forwardTo = forwardTo;
            $scope.openActualizeEconomicYearModal = openActualizeEconomicYearModal;
            $scope.updateDaysOfMonth = updateDaysOfMonth;
            $scope.toFixed2 = toFixed2;
            $scope.actualizeEconomicYear = actualizeEconomicYear;
            $scope.redirectToSupportPortal = redirectToSupportPortal;
            $scope.redirectToHandbook = redirectToHandbook;
            $scope.redirectToStatusPage = redirectToStatusPage;
            $scope.getTooltipMessage = getTooltipMessage;
            $scope.onBackButtonClick = onBackButtonClick;
            $scope.syncNow = syncNow;
            $scope.logout = logout;
        }

        function forwardTo(path) {
            $location.url(path);
            $scope.location = path;
        }

        function updateDaysOfMonth() {
            var maxDays = moment($scope.actualYear + '-' + $scope.selection.selectedMonth, "YYYY-MM").daysInMonth();
            $scope.selection.days = [];
            for (var i = 0; i < maxDays; i++) {
                $scope.selection.days.push(i + 1);
            }
        }

        function redirectToSupportPortal() {
            $window.open('https://support.riecken.io/help/3453743212/auth', '_blank');
        }

        function redirectToHandbook() {
            $window.open('https://hilfe.debitorcloud.com', '_blank');
        }

        function redirectToStatusPage() {
            $window.open('https://status.debitorcloud.com', '_blank');
        }

        function setLogOutTimer() {
            setInterval(function () {
                $scope.expireDate = moment(logOutTimer.readDate()).format("HH:mm");
                var now = new Date().getTime();
                var distance = logOutTimer.readDate() - now;
                var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
                var seconds = Math.floor((distance % (1000 * 60)) / 1000);
                $scope.expireDate = hours + "h " + minutes + "m " + seconds + "s";
            }, 1000);
        }

        function actualizeEconomicYear() {
            $scope.isEconomicYearLoading = true;
            setTimeout(function () {
                //do what you need here
            }, 5000);
            var newEconomicYearDate = $scope.actualYear + toFixed2($scope.selection.selectedMonth) + toFixed2($scope.selection.selectedDay);
            var body = {
                "fiscalYear": newEconomicYearDate
            }

            requestManager.routes.economicYear.actualizeDate($rootScope.authData.sessionId, body).then(
                response => {
                    $scope.economicYearResponse.sent = true;
                    $scope.economicYearResponse.successful = true;
                    $scope.economicYearResponse.text = response.data;
                    $scope.isEconomicYearLoading = false;
                },
                error => {
                    $scope.economicYearResponse.sent = true;
                    $scope.economicYearResponse.successful = false;
                    $scope.economicYearResponse.text = error.data;
                    if (error.data.includes("The current fiscal year is") && error.data.includes("you tried to change it to") && error.data.includes("It can only be changed to")) {
                        $scope.economicYearResponse.text = "Das aktuelle Wirtschaftsjahr ist " + error.data.substring(26, 31) + ", aber du hast versucht, es auf " + error.data.substring(67, 71) +
                            " zu ändern. Das ist leider nicht möglich. Die Änderung kann nur auf das Jahr " + error.data.substring(error.data.length - 4, error.data.length) + " vorgenommen werden.";
                    }
                    $scope.isEconomicYearLoading = false;
                }
            )
        }

        function toFixed2(number) {
            if (number < 10) {
                return "0" + number.toString();
            } else if (number >= 10) {
                return number.toString();
            }
        }

        function getStatus() {

            requestManager.routes.sync.getStatus($rootScope.authData.sessionId).then(
                function (response) {
                    $scope.syncStatus = response.data;
                    onUpdateDateFailed24();
                },
                function (response) {
                    // console.log(response);
                    //Todo catch error
                }
            );
        }

        function setToolBarType(type) {
            switch (type) {
                case undefined:
                    $scope.type = "NO_TOOLBAR";
                    break;
                case "NO_TOOLBAR":
                    $scope.type = "NO_TOOLBAR";
                    break;
                case "CUSTOMER_TOOLBAR":
                    $scope.type = "CUSTOMER_TOOLBAR";
                    break;
            }
        }

        function syncNow() {
            let cookie = cookieManager.readCookie();
            $scope.canSyncNow =
                moment().isAfter(moment.unix($scope.syncStatus.lastSuccessfullFinished).add(10, 'minutes')) &&
                (cookie.lastManualSyncTriggered === undefined || moment().isAfter(moment.unix(cookie.lastManualSyncTriggered).add(10, 'minutes')));
            if (!$scope.canSyncNow) {
                Notification.warning({
                    message: 'Es können höchstens alle 10 Minuten Synchronisierungen gestartet werden!',
                    delay: null,
                    closeOnClick: true,
                });
                return;
            }
            if (moment().isAfter(moment($scope.syncStatus.lastFinished).add(10, 'minutes'))) {
                cookie.lastManualSyncTriggered = moment().unix();
                cookieManager.saveCookie(cookie);
                requestManager.routes.sync.syncNow($rootScope.authData.sessionId).then(
                    function (response) {
                        $scope.canSyncNow = false;
                        Notification.success({
                            message: 'Synchronisierung wurde erfolgreich eingereiht',
                            delay: null,
                            closeOnClick: true,
                        });
                    },
                    function (error) {
                        Notification.warning({
                            message: 'Synchronisierung konnte nicht eingereiht werden. Wenden Sie sich bitte an den Support',
                            delay: null,
                            closeOnClick: true,
                        });
                    });
            } else {
                $scope.canSyncNow = false;
                Notification.warning({
                    message: 'Synchronisierung läuft bereits!',
                    delay: null,
                    closeOnClick: true,
                });
            }
        }

        function onBackButtonClick() {
            $window.history.back();
        }

        function logout() {
            $rootScope.authData.sessionId = "";
            $rootScope.authData.isLoggedIn = false;

            cookieManager.deleteCookie();
            sharedMemory.reset();
            window.localStorage.removeItem("data");
            $location.path("/login");
            window.location.reload();
        }

        function getTooltipMessage() {
            var errorSyncDate = moment($scope.syncStatus.lastFinished * 1000).format("DD.MM.YYYY");
            var errorSyncTime = moment($scope.syncStatus.lastFinished * 1000).format("HH:mm");
            var lastSuccessfulDate = moment($scope.syncStatus.lastSuccessfullFinished * 1000).format("DD.MM.YYYY");
            var lastSuccessfulTime = moment($scope.syncStatus.lastSuccessfullFinished * 1000).format("HH:mm");

            const date = new Date(moment($scope.syncStatus.lastSuccessfullFinished * 1000).format("YYYY-MM-DD") + 'T' + moment($scope.syncStatus.lastSuccessfullFinished * 1000).format("HH:mm:ss"));
            const dateNow = new Date(Date.now());

            const differenceMs = Math.abs(dateNow - date);
            const twentyFourHoursMs = 24 * 60 * 60 * 1000;

            $scope.error = differenceMs > twentyFourHoursMs;
            if (differenceMs > twentyFourHoursMs) {
                $scope.error = true;
                return `Synchronisierung am ${errorSyncDate} um ${errorSyncTime} mit Fehler. \nLetzte erfolgreiche Synchronisierung am ${lastSuccessfulDate} um ${lastSuccessfulTime}.`;
            } else {
                $scope.error = false;
                return `Die letzte Synchronisierung am ${errorSyncDate} um ${errorSyncTime} Uhr war fehlerhaft.\nDie letzte erfolgreiche Synchronisierung war am ${lastSuccessfulDate} um ${lastSuccessfulTime}. \nDies ist weniger als 24 Stunden her.\nBitte warte die nächste Synchronisation ab. Oft ist diese schon wieder erfolgreich.\nSollte die Synchronisation dann noch immer fehlschlagen, deutet dies auf ein nachhaltiges Problem mit dem DATEV System hin.`;
            }
        }

        function onUpdateDateFailed24() {
            const lastFinishedMoment = moment($scope.syncStatus.lastFinished);
            const lastSuccessfulMoment = moment($scope.syncStatus.lastSuccessfullFinished);
            const hoursSinceLastSuccessful = moment.duration(lastFinishedMoment.diff(lastSuccessfulMoment)).asHours();
            if (hoursSinceLastSuccessful >= 24) {
                jQuery("#updateFailedModal").appendTo("body").modal("show");
            }
        }

        function openActualizeEconomicYearModal() {
            jQuery("#actualizeEconomicYearModal").modal("show");
        }

    }]
);
